import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ShippingList from 'Containers/paymentOrders/shipping'
import ShippingQuoteForm from 'Containers/paymentOrders/shipping/shippingQuoteForm'
import ShippingOrderForm from 'Containers/paymentOrders/shipping/shippingOrderForm'

export default () => (
  <Switch>
    <Route exact path="/ordenes-pago/paqueteria" component={ShippingList} />
    <Route exact path="/ordenes-pago/paqueteria/cotizar" component={ShippingQuoteForm} />
    <Route exact path="/ordenes-pago/paqueteria/nuevo" component={ShippingOrderForm} />
    <Route exact path="/ordenes-pago/paqueteria/:id" component={ShippingOrderForm} />
  </Switch>
)