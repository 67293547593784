import React from 'react'
import { Output, Select, File } from 'Components/common/form'
import { Columns, Column, Divider } from 'Components/common/bulma'
const INVOICE_APPROVED= '3'

export default function Totals({
  data,
  onChangePdf,
  onChangeInvoice,
  uploadInvoice,
  uploadPdf,
  providerInvoiceStatus,
  invoice,
  invoicePdf,
  removeXML,
  removePDF,
}) {
  return (
    <div>
      <Columns>
        <Column>
          <Divider content="RESUMEN DEL PAGO" />
          <Output
            {...data.subtotal}
          />
          <Output
            {...data.IVA}
          />
          <Output
            {...data.ieps}
          />
        </Column>
        <Column>
          <Divider content="RESUMEN MONTOS PAGADOS" />
          <Output
            {...data.montoPagado}
          />
          <Output
            {...data.montoPagoTransito}
          />
          <Output
            {...data.montoPagar}
          />
        </Column>
      </Columns>
      <Columns>
        <Column>
          <Output
            {...data.total}
          />
        </Column>
        <Column>
          <Divider content="UBICACION DE LA FACTURA" />
          <Select
            {...data.estatusFacturaProveedor}
            options={providerInvoiceStatus}
          />
          <Output
            {...data.numeroFactura}
          />
          <File
            {...data.nombrePDF}
            fileName={(invoicePdf && invoicePdf.name) || data.nombrePDF.value}
            disabled={!invoicePdf && !!data.nombrePDF.value}
            file={invoicePdf}
            accept="application/pdf"
            onChange={onChangePdf}
            onClick={uploadPdf}
            fileNameStyle={{width: '180px'}}
            removeFile={removePDF}
          />
          <File
            {...data.nombreXML}
            disabled={data.estatusFacturaProveedor.value === INVOICE_APPROVED}
            accept="application/xml"
            fileName={(invoice && invoice.name) || data.nombreXML.value}
            file={invoice}
            onChange={onChangeInvoice}
            onClick={uploadInvoice}
            fileNameStyle={{width: '180px'}}
            removeFile={removeXML}
          />
          <Output
            {...data.UUID}
          />
        </Column>
      </Columns>
    </div>
  )
}
