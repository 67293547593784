import { useRef, useCallback, useState } from 'react';
import useForm from 'Hooks/useForm';
import http from 'Services/httpService'
import CountryCatalogService from 'Containers/onboarding/services/CountryCatalogService'
import { createGeneralData } from 'App/hexmodules/generalData/application/create/createGeneralData'
import { getGeneralData } from 'App/hexmodules/generalData/application/get/getGeneralData'
import { useDependencies } from 'App/DependencyContext';
import { fields } from './definition'
import { addNotification } from 'Modules/principal'
import { useDispatch } from 'react-redux'


const STATE = {
  INITIAL: 'initial',
  FETCHING_GENERAL_DATA: 'fetchingGeneralData',
  FETCHED_GENERAL_DATA: 'fetchedGeneralData',
  ERROR_FETCHING_GENERAL_DATA: 'errorFetchingGeneralData',
  SAVING_GENERAL_DATA: 'savingGeneralData',
  SAVED_GENERAL_DATA: 'savedGeneralData',
  ERROR_SAVING_GENERAL_DATA: 'errorSavingGeneralData',
};

function useGeneralData({ refresh }) {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const state = useRef(STATE.INITIAL);
  const [phoneCodes, setPhoneCodes] = useState([]);
  const [formValues, setFormValues, handleOnChangeField, isValid, getValues, showErrors] = useForm({
    fields,
  });
  const { generalDataRepository } = useDependencies()

  const initialFetch = async () => {
    if (state.current === STATE.INITIAL) {
      state.current = STATE.FETCHING_GENERAL_DATA;
      setIsLoading(true)
      const countryCatalogService = new CountryCatalogService(http);
      const phoneCodesResponse = await countryCatalogService.getCountryCatalog();
      const generalData = await getGeneralData(generalDataRepository);
      setFormValues({ ...generalData, phonecode: generalData.phonecode ? generalData.phonecode : '52' });
      setPhoneCodes(phoneCodesResponse);
      state.current = STATE.FETCHED_GENERAL_DATA;
      setIsLoading(false)
    }
  };

  initialFetch();
  const saveGeneralData = async () => {
    if (isValid()) {
      try {
        setIsLoading(true)
        state.current = STATE.SAVING_GENERAL_DATA;
        const generalData = getValues();
        await createGeneralData(generalDataRepository, generalData)
        await refresh();
        state.current = STATE.SAVED_GENERAL_DATA;
        setIsLoading(false)
        dispatch(addNotification({
          type: 'success',
          message: 'Infomación guardada correctamente',
        }))
      } catch ({ response: { data } }) {
        dispatch(addNotification({
          type: 'danger',
          message: data?.error,
        }))
        setIsLoading(false)
      }
    }
  };

  const getCurrentState = useCallback(() => {
    return state.current;
  }, []);

  return {
    phoneCodes,
    values: formValues,
    getCurrentState,
    onChangeField: handleOnChangeField,
    showErrors,
    saveGeneralData,
    isLoading,
  };
}

export default useGeneralData;