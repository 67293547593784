import React from 'react'
import { Output } from 'Components/common/form'
import { Columns, Column, Divider } from 'Components/common/bulma'

export default function Totals({
  data,
}) {
  return (
    <div>
      <Columns>
        <Column>
          <Divider content="RESUMEN DEL PAGO" />
          <Output
            {...data.subtotal}
          />
          <Output
            {...data.totalDescuentos}
          />
          <Output
            {...data.iva}
          />
          <Output
            {...data.ieps}
          />
          <Output
            {...data.totalRetenciones}
          />
          <Output
            {...data.total}
          />
        </Column>
        <Column>
          <Divider content="RESUMEN MONTOS PAGADOS" />
          <Output
            {...data.montoCobrado}
          />
          <Output
            {...data.montoPorCobrar}
          />
          <Divider content="UBICACION DE LA FACTURA" />
          <Output
            {...data.numeroFactura}
          />
          <Output
            {...data.uuid}
          />
          {!!data.descripcion_orden.value && <>
            <Divider content="DESCRIPCION ORDEN WIDGET" />
            <Output
              {...data.descripcion_orden} />
          </>}
        </Column>
      </Columns>
      <Columns>
        <Column>

        </Column>
        <Column>
        </Column>
      </Columns>
    </div>
  )
}
