
const SET_LANGUAGE = 'i18n/SET_LANGUAGE'
const INIT_LANGUAGE = 'i18n/INIT_LANGUAGE'

const initialState = {
  language: {code: 'es', flag: 'mx'},
  languages: [
    { code: 'es', flag: 'mx', label: 'Español' },
    { code: 'en', flag: 'us', label: 'Inglés' },
  ]
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return { ...state, language: action.payload }

    default:
      return state
  }
}

export const changeLanguage = (language) => {
  localStorage.setItem('lang', JSON.stringify(language))
  return {
    type: SET_LANGUAGE,
    payload: language
  }
}
export const initLanguage = () => {
  const lang = JSON.parse(localStorage.getItem('lang'))
  if(!lang) {
    return {type: INIT_LANGUAGE}
  }
  return {
    type: SET_LANGUAGE,
    payload: lang
  }
}
