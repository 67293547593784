import React from 'react'
import { Output, Check } from 'Components/common/form'
import { Columns, Column, Box } from 'Components/common/bulma'

const Perfil = ({data}) => {
    return (
        <Box>
            <Columns>
                <Column>
                    <Output
                        {...data.id}
                    />
                </Column>
                <Column>
                    <Output
                        {...data.integrado}
                    />
                </Column>
            </Columns>
            <Columns>
                <Column>
                    <Output
                        {...data.tipoCuenta}
                    />
                </Column>

                <Column>
                    <Output
                        {...data.estatus}
                    />

                </Column>
            </Columns>
            <Columns>

                <Column>
                    <Output
                        {...data.clabe}
                    />
                </Column>
                <Column>
                    <Output
                        {...data.cuentaBancaria}
                    />
                </Column>
            </Columns>
            <Columns>
                <Column>
                    <Output
                        {...data.banco}
                    />
                </Column>
                <Column>
                    <Output
                        {...data.numeroSucursal}
                    />
                </Column>
            </Columns>
            <Columns>
                <Column>
                    <Output
                        {...data.plaza}
                    />
                </Column>
                <Column>
                    <Check
                        {...data.encabezadoEstadoCuenta}
                    />
                </Column>
            </Columns>
            <Columns>
                <Column>
                    <Output
                        {...data.pagoDirectoOConBanco}
                    />
                </Column>
                <Column>
                </Column>
            </Columns>
            <Columns>
                <Column>
                <Output
                    {...data.directoBeneficiario}
                />

                </Column>

                <Column>
                    <Output
                        {...data.directoDireccion}
                    />
                </Column>
            </Columns>
            <Columns>
                <Column>
                    <Output
                        {...data.directoIbanONumeroDeCuenta}
                    />
                </Column>

                <Column>
                    <Output
                        {...data.directoSwiftCode}
                    />


                </Column>
            </Columns>
            <Columns>
                <Column>

                    <Output
                        {...data.directoVatId}
                    />
                </Column>
            
            
                <Column>
                    <Output
                        {...data.directoBanco}
                    />
                </Column>
            </Columns>
            <Columns>
                <Column>
                    <Output
                        {...data.intermediarioNombreDelBanco}
                    />
                </Column>
                <Column>
                    <Output
                        {...data.intermediarioPais}
                    />
                </Column>
            </Columns>
            <Columns>
                <Column>
                    <Output
                        {...data.intermediarioSwiftCode}
                    />
                </Column>
                <Column>
                    <Output
                        {...data.intermediarioSortCode}
                    />
                </Column>

            </Columns>
            <Columns>
                <Column>
                    <Output
                        {...data.intermediarioNombreDelBanco}
                    />
                </Column>
                <Column>
                    <Output
                        {...data.intermediarioPaisYEstado}
                    />
                </Column>
            </Columns>
            <Columns>
                <Column>
                    <Output
                        {...data.intermediarioSwiftCodeBanco}
                    />
                </Column>
                <Column>
                    <Output
                        {...data.intermediarioNombreDeLa}
                    />
                </Column>
            </Columns>
            <Columns>
                <Column>
                    <Output
                        {...data.intermediarioNumeroDeLa}
                    />
                </Column>
                <Column>
                    <Output
                        {...data.intermediarioNombreDel}
                    />
                </Column>
            </Columns>
            <Columns>
                <Column>
                    <Output
                        {...data.intermediarioCiudadYPaísDel}
                    />
                </Column>
                <Column>
                <Output
                        {...data.intermediarioNumerode}
                    />
                </Column>
            </Columns>

        </Box>
    );
};

export default Perfil;