import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from 'Components/common/bulma'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons'

function GeneralData({ isCompleted }) {
  return (
    <li>
      <NavLink isActive={(match) => match.isExact} activeClassName="button is-text is-active" to="/onboarding">Datos Generales
        <span className={isCompleted ? 'has-text-success' : 'has-text-warning'} style={{ marginLeft: '10px' }}>
          <Icon>
            <FontAwesomeIcon icon={isCompleted ? faCheck : faExclamation} />
          </Icon>
        </span>
      </NavLink>
    </li>
  );
}

function FiscalData({ isCompleted }) {
  return (
    <li>
      <NavLink activeClassName="button is-text is-active" to="/onboarding/datos-fiscales">Datos Fiscales
        <span className={isCompleted ? 'has-text-success' : 'has-text-warning'} style={{ marginLeft: '10px' }}>
          <Icon>
            <FontAwesomeIcon icon={isCompleted ? faCheck : faExclamation} />
          </Icon>
        </span>
      </NavLink>
    </li>
  );
}

function ForeignFiscalData({ isCompleted }) {
  return (
    <li>
      <NavLink activeClassName="button is-text is-active" to="/onboarding/datos-fiscales-extranjeros">Datos Fiscales Extranjeros
        <span className={isCompleted ? 'has-text-success' : 'has-text-warning'} style={{ marginLeft: '10px' }}>
          <Icon>
            <FontAwesomeIcon icon={isCompleted ? faCheck : faExclamation} />
          </Icon>
        </span>
      </NavLink>
    </li>
  );
}

function LegalEntityInfo({ isCompleted }) {
  return (
    <li>
      <NavLink activeClassName="button is-text is-active" to="/onboarding/informacion-personas-morales-empresas">Información de Personas Morales o Empresa
        <span className={isCompleted ? 'has-text-success' : 'has-text-warning'} style={{ marginLeft: '10px' }}>
          <Icon>
            <FontAwesomeIcon icon={isCompleted ? faCheck : faExclamation} />
          </Icon>
        </span>
      </NavLink>
    </li>
  );
}

function ForeignLegalEntityInfo({ isCompleted }) {
  return (
    <li>
      <NavLink activeClassName="button is-text is-active" to="/onboarding/informacion-personas-morales-empresas-extranjeras">Información de Personas Morales o Empresas Extranjeras
        <span className={isCompleted ? 'has-text-success' : 'has-text-warning'} style={{ marginLeft: '10px' }}>
          <Icon>
            <FontAwesomeIcon icon={isCompleted ? faCheck : faExclamation} />
          </Icon>
        </span>
      </NavLink>
    </li>
  );
}

function PersonalInfo({ isCompleted }) {
  return (
    <li>
      <NavLink activeClassName="button is-text is-active" to="/onboarding/informacion-personas-fisicas">Información de Personas Fisicas
        <span className={isCompleted ? 'has-text-success' : 'has-text-warning'} style={{ marginLeft: '10px' }}>
          <Icon>
            <FontAwesomeIcon icon={isCompleted ? faCheck : faExclamation} />
          </Icon>
        </span>
      </NavLink>
    </li>
  );
}

function ForeignPersonalInfo({ isCompleted }) {
  return (
    <li>
      <NavLink activeClassName="button is-text is-active" to="/onboarding/informacion-personas-fisicas-extranjeras">Información de Personas Fisicas Extranjeras
        <span className={isCompleted ? 'has-text-success' : 'has-text-warning'} style={{ marginLeft: '10px' }}>
          <Icon>
            <FontAwesomeIcon icon={isCompleted ? faCheck : faExclamation} />
          </Icon>
        </span>
      </NavLink>
    </li>
  );
}

function BankAccounts({ isCompleted }) {
  return (
    <li>
      <NavLink activeClassName="button is-text is-active" to="/onboarding/cuentas-bancarias">Cuentas Bancarias
        <span className={isCompleted ? 'has-text-success' : 'has-text-warning'} style={{ marginLeft: '10px' }}>
          <Icon>
            <FontAwesomeIcon icon={isCompleted ? faCheck : faExclamation} />
          </Icon>
        </span>
      </NavLink>
    </li>
  );
}

function ForeignBankAccounts({ isCompleted }) {
  return (
    <li>
      <NavLink activeClassName="button is-text is-active" to="/onboarding/cuentas-bancarias-extranjeros">Cuentas Bancarias Extranjeros
        <span className={isCompleted ? 'has-text-success' : 'has-text-warning'} style={{ marginLeft: '10px' }}>
          <Icon>
            <FontAwesomeIcon icon={isCompleted ? faCheck : faExclamation} />
          </Icon>
        </span>
      </NavLink>
    </li>
  );
}

function LegalRepresentativeData({ isCompleted }) {
  return (
    <li>
      <NavLink activeClassName="button is-text is-active" to="/onboarding/datos-del-representante-legal">Datos del Representante Legal
        <span className={isCompleted ? 'has-text-success' : 'has-text-warning'} style={{ marginLeft: '10px' }}>
          <Icon>
            <FontAwesomeIcon icon={isCompleted ? faCheck : faExclamation} />
          </Icon>
        </span>
      </NavLink>
    </li>
  );
}

function ForeignLegalRepresentativeData({ isCompleted }) {
  return (
    <li>
      <NavLink activeClassName="button is-text is-active" to="/onboarding/datos-del-representante-legal-extranjero">Datos del Representante Legal Extranjero
        <span className={isCompleted ? 'has-text-success' : 'has-text-warning'} style={{ marginLeft: '10px' }}>
          <Icon>
            <FontAwesomeIcon icon={isCompleted ? faCheck : faExclamation} />
          </Icon>
        </span>
      </NavLink>
    </li>
  );
}
function Beneficiaries({ isCompleted }) {
  return (
    <li>
      <NavLink activeClassName="button is-text is-active" to="/onboarding/beneficiarios">Beneficiarios
        <span className={isCompleted ? 'has-text-success' : 'has-text-warning'} style={{ marginLeft: '10px' }}>
          <Icon>
            <FontAwesomeIcon icon={isCompleted ? faCheck : faExclamation} />
          </Icon>
        </span>
      </NavLink>
    </li>
  );
}
function FinishTab({ isCompleted }) {
  return (
    <li>
      <NavLink activeClassName="button is-text is-active" to="/onboarding/enviar-a-revision">Revisión
        <span className="has-text-warning" style={{ marginLeft: '10px' }}>
          <Icon>
            <FontAwesomeIcon icon={faExclamation} />
          </Icon>
        </span>
      </NavLink>
    </li>
  );
}

export {
  GeneralData,
  FiscalData,
  ForeignFiscalData,
  LegalEntityInfo,
  ForeignLegalEntityInfo,
  PersonalInfo,
  ForeignPersonalInfo,
  BankAccounts,
  ForeignBankAccounts,
  LegalRepresentativeData,
  ForeignLegalRepresentativeData,
  Beneficiaries,
  FinishTab,
};
