import React, { useState, useCallback, useEffect } from 'react'
import { useMount, useUnmount } from 'ahooks'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import {
  getSignatureCatalogAction,
  createContractAction,
  getContractAction,
  authorizeAction,
  getContractStatusAction,
  deleteContractAction,
  uploadContract,
  resetContractAction,
  updateContractAction,
  getContractFilesAction,
} from 'Modules/contracts'
import { bindActionCreators } from 'redux'
import { Columns, Box, Column, Title, Icon, Button, Field, Control, Divider } from 'Components/common/bulma'
import { InputWithError, Switch, SelectWithError, DebounceInputWithError, TagsInputWithError } from 'Components/common/form'
import { contractFieldsDefinition, signatoriesFieldDefinition, signatoriesColDef } from './contractsColumnDefinitions'
import ActionBar from 'Components/common/actionBar';
import Show from 'Components/common/Show';
import useForm from 'Hooks/useForm'
import useArrayForm from 'Hooks/useArrayForm'
import useRemove from 'Hooks/useRemove'
import { addNotification as addNotificationAction } from 'Modules/principal'
import Loader from 'Components/common/page/loaderFloat';
import { Authorize } from 'Containers/auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPaperclip, faUpload, faTimes, faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { equals, path, gt, take, uniqBy, prop, pipe, length, includes } from 'ramda'
import { isNotNil } from 'ramda-extension'
import { v4 as uuid } from 'uuid'
import Attachments from 'Containers/paymentOrders/attachments'
import { Table } from 'Components/common'


function UserForm({
  getSignatureCatalog,
  match,
  pushNavigation,
  user,
  addNotification,
  signatureType,
  createContract,
  getContract,
  contract,
  authorize,
  getContractStatus,
  contractStatus,
  deleteContract,
  resetContract,
  updateContract,
  getContractFiles,
  files,
}) {
  const [
    contractFields, setContractFields, onChangeContractFields,
    isValidContractForm, getContractValues, showContractFormErrors,
  ] = useForm({ fields: contractFieldsDefinition })
  const [
    signatorieFields, setSignatorieFields, onChangeSignatorieFields,
    isValidSignatorieForm, getSignatorieValues, showSignatorieFormErrors,
    onRemoveSignatorie, mapSignatoriesFields,
  ] = useArrayForm({ fields: [], fieldsDefinition: signatoriesFieldDefinition })

  const [isLoading, setIsLoading] = useState(false)
  const [isAuthorizing, setIsAuthorizing] = useState(false)
  const [contractFile, setContractFile] = useState(null)
  // const [isRemovingModalShow, setIsRemovingModalShow] = useState(false)

  const [Modal, setShowModal] = useRemove({
    title: 'Eliminar contrato',
    message: '¿Desea eliminar el contrato?',
    handleOnRemove: contractId => {
      setIsLoading(true)
      deleteContract(contractId)
        .then(() => {
          addNotification({
            type: 'success',
            message: 'El contrato se eliminó correctamente'
          })
          setIsLoading(false)
          pushNavigation('/contratos')
        })
        .catch(() => {
          addNotification({
            type: 'danger',
            message: 'Ocurrió un error al eliminar el contrato'
          })
          setIsLoading(false)
        })
    },
  })

  useMount(() => {
    const { id } = match.params

    if (id) {
      getContract(id);
      getContractFiles({ id });
    }
    getSignatureCatalog();
    getContractStatus();
  })

  useUnmount(() => {
    resetContract()
  })

  useEffect(() => {
    if (user) {
      setContractFields(user)
    }
    // eslint-disable-next-line
  }, [user])

  useEffect(() => {
    if (contract) {
      setContractFields({
        ...contract,
        cuentaIntegradora: +contract.cuenta_integradora === 1 ? true : false,
        firmaElectronica: contract.firma_electronica,
        numeroFirmantes: contract.numero_firmantes,
        correoObservadores: contract.correos_observadores,
        contactoEnvio: contract.contacto_envio,
        direccionEnvio: contract.direccion_envio,
        telefonoEnvio: contract.telefono_envio,
        urlContrato: contract.url_file,
      })
      mapSignatoriesFields(contract.correos_firmantes)
    }
    // eslint-disable-next-line
  }, [contract])


  const onSave = () => {
    const isValid = isValidContractForm();
    const signatoriesValues = getSignatorieValues()
    const hasNotDuplicates = pipe(uniqBy(prop('correo')), length, equals(length(signatoriesValues)))
    const isValidSignatorie = requireSignatories ? (isValidSignatorieForm() && hasNotDuplicates(signatoriesValues)) : true;
    const hasContract = isEditing() ? true : isNotNil(contractFile)

    if (isValid && isValidSignatorie && hasContract) {
      const contractValues = getContractValues()
      const contractMarshall = {
        ...contractValues,
        cuenta_integradora: contractValues.cuentaIntegradora,
        url_file: contractValues.urlContrato,
        firma_electronica: contractValues.firmaElectronica,
        numero_firmantes: contractValues.numeroFirmantes,
        correos_firmantes: signatoriesValues,
        correos_observadores: contractValues.correoObservadores,
        contacto_envio: contractValues.contactoEnvio,
        direccion_envio: contractValues.direccionEnvio,
        telefono_envio: contractValues.telefonoEnvio,
      }
      setIsLoading(true);
      if (isEditing()) {
        updateContract(contractMarshall)
          .then(() => {
            setIsLoading(false);
            addNotification({
              type: 'success',
              message: 'Contrato actualizado correctamente',
            })
          }).catch(() => {
            addNotification({
              type: 'danger',
              message: 'Error al actualizar el contrato',
            })
            setIsLoading(false);
          });
      } else {
        createContract(contractMarshall)
          .then(({ id }) => {
            setIsLoading(false);
            addNotification({
              type: 'success',
              message: 'Contrato creado correctamente',
            })
            pushNavigation(`/contratos/${id}`)
          }).catch(() => {
            addNotification({
              type: 'danger',
              message: 'Error al crear el contrato',
            })
            setIsLoading(false);
          });
      }
    } else {
      addNotification({
        type: 'danger',
        message: 'Favor de validar los campos, si tiene firmantes asegurarse de no tener duplicados',
      })
    }
  }

  const getPermissions = useCallback(() => ({
    canSave: true,
    canDelete: isCreated(),
    canAuthorize: allowAuthorize(),
    // eslint-disable-next-line
  }), [contractFields.id.input.value])

  const handleOnCancel = () => {
    pushNavigation('/contratos')
  }

  const handleOnDelete = () => {
    setShowModal(contractFields.id.input.value)
  }

  const showAuthorizing = () => {
    setIsAuthorizing(true)
  }

  const handleOnAuthorize = (props) => {
    return authorize({
      id: contractFields.id.input.value,
      ...props,
    }).then(data => {
      addNotification({
        type: 'success',
        message: 'El contrato se autorizó correctamente'
      })
      setIsAuthorizing(false)
      return data
    })
  }

  const cancelAuthorizing = () => {
    setIsAuthorizing(false)
  }

  const isEditing = useCallback(() => !!contractFields.id.input.value, [contractFields.id.input.value])
  const isCreated = useCallback(() => equals(+contractFields.estatus.input.value, 1), [contractFields.estatus.input.value])
  const allowAuthorize = useCallback(() => includes(+contractFields.estatus.input.value, [1,9]), [contractFields.estatus.input.value])

  const filterSignatureType = (isPartnerSignatureRequired) => {
    return signatureType.filter((it) => {
      if (!isPartnerSignatureRequired && +it.id === 1) {
        return false
      }
      return true
    })
  }

  const requireSignatories = useCallback(() => [2, 3].includes(+contractFields.firmaElectronica.input.value), [contractFields.firmaElectronica.input.value])
  const showContact = useCallback(() => [1].includes(+contractFields.firmaElectronica.input.value), [contractFields.firmaElectronica.input.value])

  const makeSignatoriesArray = (length) => {
    return Array.from({ length }, () => ({ ...signatoriesFieldDefinition, id: uuid() }));
  }
  useEffect(() => {
    const hasNotSignatories = equals(0, signatorieFields.length);
    const greaterInputThan = gt(+contractFields.numeroFirmantes.input.value, signatorieFields.length);
    const greaterArrayThan = gt(signatorieFields.length, +contractFields.numeroFirmantes.input.value);

    if (hasNotSignatories) {
      setSignatorieFields(makeSignatoriesArray(+contractFields.numeroFirmantes.input.value))
      return
    }

    if (greaterInputThan) {
      const lengthDifference = +contractFields.numeroFirmantes.input.value - signatorieFields.length
      const signatoriesDiff = makeSignatoriesArray(lengthDifference)

      setSignatorieFields(signatorieFields.concat(signatoriesDiff))
    }

    if (greaterArrayThan) {

      setSignatorieFields(take(+contractFields.numeroFirmantes.input.value, signatorieFields))
    }

    // eslint-disable-next-line
  }, [contractFields.numeroFirmantes.input.value])


  const getSignatories = useCallback(() => {
    return (
      <>
        {signatorieFields.map((signatorie, idx) => (
          <Columns key={idx}>
            <Column className="is-one-quarter">
              <InputWithError
                showErrors={showSignatorieFormErrors}
                onChange={onChangeSignatorieFields}
                {...{ ...signatorie.rol, input: { ...signatorie.rol.input, id: idx } }}
              />
            </Column>
            <Column className="is-one-quarter">
              <InputWithError
                showErrors={showSignatorieFormErrors}
                onChange={onChangeSignatorieFields}
                {...{ ...signatorie.correo, input: { ...signatorie.correo.input, id: idx } }}
              />
            </Column>
            <Column>
              <Field>
                <label className="label">&nbsp;</label>
                <Control>
                  <Button className="margin-left-xs" danger outlined onClick={() => onRemoveSignatorie(signatorie)}>
                    <Icon>
                      <FontAwesomeIcon icon={faTrash} />
                    </Icon>
                  </Button>
                </Control>
              </Field>
            </Column>
          </Columns>
        ))}
      </>
    )
    // eslint-disable-next-line
  }, [signatorieFields]);

  const onChangeContractFile = (e) => {
    const getFile = path(['target', 'files', 0])
    setContractFile(getFile(e))
  }

  const onRemoveContractFile = () => {
    setContractFields({ urlContrato: '' })
    setContractFile(null)
  }

  const onUploadContractFile = () => {
    const data = new FormData()

    data.append('file', contractFile, contractFile.name)
    uploadContract(data)
      .then(({ data }) => {
        setContractFields({ urlContrato: data['uploaded_file'] })
        addNotification({
          type: 'success',
          message: '¡El contrato se adjunto correctamente!'
        })
      })
      .catch(({ response }) => {
        addNotification({
          type: 'danger',
          message: response.data.message,
        })
      })
  }

  useEffect(() => {
    const equalsSignatorie = equals(signatorieFields.length, contractFields.numeroFirmantes.input.value)
    const countSignatories = signatorieFields.length ? signatorieFields.length : ''

    if (!equalsSignatorie) {
      setContractFields({ numeroFirmantes: countSignatories })
    }
    // eslint-disable-next-line
  }, [signatorieFields.length])

  return (
    <Box style={{ position: 'relative' }}>
      {isAuthorizing && (<Authorize
        isOpen={isAuthorizing}
        authorize={handleOnAuthorize}
        cancel={cancelAuthorizing}
      />)}
      <Modal />
      <Show canShow={isLoading}>
        <Loader />
      </Show>
      <ActionBar
        onSave={onSave}
        onCancel={handleOnCancel}
        onDelete={handleOnDelete}
        onAuthorize={showAuthorizing}
        basicRole={['Ocontratos']}
        authorizeRole={['Acontratos']}
        permissions={getPermissions()}
      />
      <Columns className="is-multiline">
        <Column className="is-offset-8 is-one-quarter">
          <SelectWithError
            onChange={onChangeContractFields}
            options={contractStatus}
            showErrors={showContractFormErrors}
            {...contractFields.estatus}
          />
        </Column>
        <Column className="is-one-quarter">
          <InputWithError
            showErrors={showContractFormErrors}
            onChange={onChangeContractFields}
            {...contractFields.asunto}
          />
        </Column>
        <Column className="is-half">
          <InputWithError
            showErrors={showContractFormErrors}
            onChange={onChangeContractFields}
            {...contractFields.descripcion}
          />
        </Column>
      </Columns>
      <Columns>
        <Column className="is-one-quarter">
          <Switch
            showErrors={showContractFormErrors}
            onChange={onChangeContractFields}
            {...contractFields.cuentaIntegradora}
          />
        </Column>
      </Columns>
      <Columns className="is-multiline">
        <Column className="is-one-quarter">
          <SelectWithError
            showErrors={showContractFormErrors}
            options={filterSignatureType(contractFields.cuentaIntegradora.input.value)}
            onChange={onChangeContractFields}
            {...contractFields.firmaElectronica}
          />
        </Column>
        <Show canShow={requireSignatories()}>
          <Column className="is-one-quarter">
            <DebounceInputWithError
              showErrors={showContractFormErrors}
              onChange={onChangeContractFields}
              {...contractFields.numeroFirmantes}
            />
          </Column>
        </Show>
      </Columns>
      <Show canShow={requireSignatories()}>
        <Columns>
          <Column className="is-one-quarter">
            <Title className="is-6">Correo de los firmantes</Title>
          </Column>
        </Columns>
        {getSignatories()}
        <Columns>
          <Column className="is-half">
            <TagsInputWithError
              showErrors={showContractFormErrors}
              onChange={onChangeContractFields}
              {...contractFields.correoObservadores}
            />
          </Column>
        </Columns>
      </Show>
      <Show canShow={showContact()}>
        <Columns>
          <Column className="is-one-quarter">
            <InputWithError
              showErrors={showContractFormErrors}
              onChange={onChangeContractFields}
              {...contractFields.contactoEnvio}
            />
          </Column>
          <Column className="is-one-quarter">
            <InputWithError
              showErrors={showContractFormErrors}
              onChange={onChangeContractFields}
              {...contractFields.direccionEnvio}
            />
          </Column>
          <Column className="is-one-quarter">
            <InputWithError
              showErrors={showContractFormErrors}
              onChange={onChangeContractFields}
              {...contractFields.telefonoEnvio}
            />
          </Column>
        </Columns>
      </Show>
      <Show canShow={!isEditing()}>
      <Columns>
        <Column className="is-6">
          <label className="label">Contrato</label>
          <div className="file has-name is-right">
            <label className="file-label">
              <input className="file-input"
                type="file"
                name="file"
                onChange={onChangeContractFile}
                accept=".pdf,.doc,docx"
              />
              <span className="file-cta">
                <span className="file-icon">
                  <Icon>
                    <FontAwesomeIcon icon={faPaperclip} />
                  </Icon>
                </span>
                <span className="file-label">
                  Seleccionar contrato...
                </span>
              </span>
              <Button onClick={onUploadContractFile} disabled={!contractFile}>
                <Icon>
                  <FontAwesomeIcon icon={faUpload} />
                </Icon>
              </Button>
              <Button onClick={onRemoveContractFile} disabled={!contractFile}>
                <Icon>
                  <FontAwesomeIcon icon={faTimes} />
                </Icon>
              </Button>
              <span className="file-name" style={{ width: '200px' }}>
                {contractFile && contractFile.name}{'      '}
              </span>
            </label>
          </div>
          <div>
            {(contractFields.urlContrato.error && showContractFormErrors) && <p className="has-text-danger help">{contractFields.urlContrato.error}</p>}
          </div>
        </Column>
      </Columns>
      </Show>
      <Show canShow={contract?.firmantes}>
        <Divider content="Estado de las firmas" />
        <Table
          colsetup={signatoriesColDef}
          coldata={{data: contract?.firmantes}}
          tableClassName='table is-striped is-hoverable is-fullwidth'
          emptyTableMarkUp={<span>No hay firmantes</span>}
        />
      </Show>
      <Show canShow={isEditing()}>
        <Divider content="Contrato" />
        <Attachments
          files={files}
        />
      </Show>
    </Box>
  )
}

const mapStateToProps = ({ catalogos, principal, contracts, auth }) => ({
  estatusPersona: catalogos.estatusPersona,
  permissions: principal.inventoryPermission,
  signatureType: contracts.signatureType,
  contract: contracts.contract,
  contractStatus: contracts.contractStatus,
  files: contracts.files,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getSignatureCatalog: getSignatureCatalogAction,
  pushNavigation: push,
  addNotification: addNotificationAction,
  createContract: createContractAction,
  getContract: getContractAction,
  authorize: authorizeAction,
  getContractStatus: getContractStatusAction,
  deleteContract: deleteContractAction,
  resetContract: resetContractAction,
  updateContract: updateContractAction,
  getContractFiles: getContractFilesAction,
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(UserForm)
