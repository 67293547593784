import React from 'react';
import { Box, Columns, Column, Button } from 'Components/common/bulma'
import File from 'Components/common/form/FileWithErrors'
import usePersonalInfo from './usePersonalInfo';
import Loader from 'Components/common/page/loaderFloat';
import Show from 'Components/common/Show';


const PersonalInfo = ({ refresh }) => {
  const { values, showErrors, onChangeField, savePersonalInfo, isLoading } = usePersonalInfo({ refresh });

  return (
    <Box style={{ position: 'relative' }}>
      <Show canShow={isLoading}>
        <Loader />
      </Show>
      <Columns className="is-multiline">
        <Column className="is-half">
          <File
            onChange={onChangeField}
            showErrors={showErrors}
            {...values.taxSituation}
          />
        </Column>
        <Column className="is-half">
          <File
            onChange={onChangeField}
            showErrors={showErrors}
            {...values.addressProof}

          />
        </Column>
        <Column className="is-half">
          <File
            onChange={onChangeField}
            showErrors={showErrors}
            {...values.id}

          />
        </Column>
        <Column className="is-half">
          <File
            onChange={onChangeField}
            showErrors={showErrors}
            {...values.curp}

          />
        </Column>
      </Columns>
      <Columns className="is-multiline">
        <Column className="is-full">
          <Button className="is-primary"
            onClick={savePersonalInfo}
          >Guardar</Button>
        </Column>
      </Columns>
    </Box>
  );
};

export default PersonalInfo;
