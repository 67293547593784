import React, { Component } from 'react'
import { Field, Control, Icon, Button, File } from 'Components/common/bulma'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload, faTimes, faPaperclip, faSpinner, faCheck } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'

export default class CustomFile extends Component {
  static propTypes = {
    label: PropTypes.string,
    isUploading: PropTypes.bool,
  }

  state = {
    randKey: Date.now()
  }

  removeFile = () => {
    this.props.removeFile()
    this.setState({
      randKey: Date.now()
    })
  }

  render() {
    const {
      label,
      file,
      fileName,
      selectLabel,
      onClick,
      onChange,
      fileNameStyle,
      value,
      disabled,
      removeFile,
      isUploading,
      errors,
      showErrors,
      isUploaded = false,
      ...rest } = this.props

    return (
      <Field>
        <label className="label">{label}</label>
        <Control>
          <File name right>
            <File.Label>
              <File.Input
                key={this.state.randKey}
                disabled={disabled}
                {...rest}
                onChange={onChange}
              />
              <File.Cta>
                <File.Icon>
                  <FontAwesomeIcon icon={faPaperclip} />
                </File.Icon>
                <File.Label as='span'>
                  {selectLabel}
                </File.Label>
              </File.Cta>
              <File.Name style={fileNameStyle}>
                {fileName}
              </File.Name>
            </File.Label>
            <Button type="button" onClick={onClick} disabled={!file || disabled || isUploading}>
              <Icon>
                {isUploaded && <FontAwesomeIcon icon={faCheck} className="has-text-success" />}
                {isUploading && <FontAwesomeIcon className="fa-spin" icon={faSpinner} />}
                {(!isUploading && !isUploaded) && <FontAwesomeIcon icon={faUpload} />}
              </Icon>
            </Button>
            <Button type="button" onClick={this.removeFile} disabled={!file || disabled}>
              <Icon>
                <FontAwesomeIcon icon={faTimes} />
              </Icon>
            </Button>
          </File>
        </Control>
        {(errors && !!errors.length && showErrors) && <p className="has-text-danger help">{errors[0].message}</p>}
      </Field>
    )
  }
}
