import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { Box, Icon, Columns, Column } from 'Components/common/bulma'
import Table from 'Components/common/table'
import { getProcesingList as getList, deleteOrder } from 'Modules/paymentOrders/walletGasoline'
import { setOrigin } from 'Modules/paymentOrders/providers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { procesingMonederoGasolinaColData } from './data'
import { Button } from 'Components/common/bulma'
import { addNotification } from 'Modules/principal'

export class ListaContainer extends Component {
  state = {
    showModal: false,
    order: {},
  }

  onEdit = ({ idOrdenPago }) => {
    const { push, setOrigin } = this.props;
    setOrigin('procesingWalletGasoline')
    push(`/ordenes-pago/monederos-gasolina/${idOrdenPago}`);
  }

  toggleModal = (order) => {
    this.setState(state => ({
      showModal: !state.showModal,
      order,
    }))
  }

  deleteOrder = () => {
    this.props.deleteOrder(this.state.order.id)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'El monedero se eliminó correctamente'
        })
        this.setState({
          showModal: false,
        })
      })
      .catch(() => {
        this.props.addNotification({
          type: 'danger',
          message: 'Ocurrió un error al eliminar el monedero'
        })
        this.setState({
          showModal: false,
        })
      })
  }

  customColAccions = (row) => (
    <Fragment >
      <Button small className="margin-left-xs" primary outlined onClick={() => this.onEdit(row)}>
        <Icon>
          <FontAwesomeIcon icon={faEdit} />
        </Icon>
      </Button>
    </Fragment>
  )

  render() {
    const { list } = this.props

    return (
      <Box>
        <Columns>
          <Column>
            <Table
              filterable
              colsetup={procesingMonederoGasolinaColData}
              coldata={list}
              tableClassName='table is-striped is-hoverable is-fullwidth'
              emptyTableMarkUp={<span>No hay registros</span>}
              customCol={{
                customColName: 'actions',
                renderFunc: this.customColAccions
              }}
              refresh={this.props.getList}
            />
          </Column>
        </Columns>
      </Box>
    )
  }
}

const mapStateToProps = ({ paymentOrders }) => ({
  list: paymentOrders.walletGasoline.procesingList,
  isFetching: paymentOrders.walletGasoline.isFetchingWalletGasoline
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getList,
  push,
  deleteOrder,
  addNotification,
  setOrigin,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ListaContainer)
