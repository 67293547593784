import React, { Fragment, PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Columns, Column } from 'Components/common/bulma'
import Can from 'Containers/auth/Can'
import { Balance, Logs, Operaciones, Movimientos } from 'Components/home'
import { getBalance, getMovements, getLogs, getOperaciones } from 'Modules/home'

const labelsOperaciones = {
  autorizarOrdenesDeIngreso: { label: 'Autorizar Ordenes de Ingreso', link: '/ordenes-ingreso/pendientes' },
  autorizarOrdenesDePago: { label: 'Autorizar Ordenes de Pago', link: '/ordenes-pago/pendientes' },
  autorizarOrdenesDeTraspaso: { label: 'Autorizar Ordenes de Traspaso', link: '/ordenes-traspaso/pendientes' },
  autorizarOrdenesDePrestamo: { label: 'Autorizar Ordenes de Préstamo', link: '/ordenes-prestamo/autorizar' },
  autorizarAmoritzacionPrestamo: { label: 'Autorizar Ordenes de Amortización de Préstamo' },
  facturasPendientesProveedor: { label: 'Factura Pendientes de Proveedores', link: '/ordenes-pago/proveedores/facturas-pendientes' },
  facturasRechazadasProveedor: { label: 'Facturas Rechazadas Proveedor', link: '/ordenes-pago/facturas-rechazadas' },
  pagosIASEnProceso: { label: 'Pagos IAS en proceso', link: '/ordenes-pago/asimilados-salarios/en-proceso' },
  pagosIASPagadas: { label: 'Pagos IAS pagadas', link: '/ordenes-pago/asimilados-salarios/pagados' },
  pagosIASRechazadas: { label: 'Pagos IAS rechazadas', link: '/ordenes-pago/asimilados-salarios/rechazados' },
  pagosMonederoGasPagadas: { label: 'Pagos Monederos Gas Pagadas', link: '/ordenes-pago/monederos-gasolina/pagadas' },
  pagosMonederoGasEnProceso: { label: 'Pagos Monederos Gas en Proceso', link: '/ordenes-pago/monederos-gasolina/en-proceso' },
  pagosMonederoGasRechazado: { label: 'Pagos Monederos Gas Rechazadas', link: '/ordenes-pago/monederos-gasolina/rechazadas' },
  pagosMonederoGastosPagados: { label: 'Pagos Monederos Gastos Pagados', link: '/ordenes-pago/monederos-gastos/pagadas' },
  pagosMonederoGastosEnProceso: { label: 'Pagos Monederos Gastos En Proceso', link: '/ordenes-pago/monederos-gastos/en-proceso' },
  pagosMonederoGastosRechazados: { label: 'Pagos Monederos Gastos Rechazados', link: '/ordenes-pago/monederos-gastos/rechazadas' },
  ordenesIngresoPendientesCobro: { label: 'Ordenes de Ingreso Pendientes de Cobro', link: '/ordenes-ingreso/pendientes-cobro' },
  conciliacionesPendintes: { label: 'Conciliaciones Pendientes', link: '/conciliaciones' },
  ordenesPagoPendientesCobro: { label: 'Ordenes de Pago Pendientes de Pago', link: '/ordenes-pago/pendientes-pago' },
}
class Home extends PureComponent {
  state = {
    tabActive: 'MXN'
  }
  componentDidMount() {
    this.props.getBalance()
    this.props.getMovements()
    this.props.getLogs()
    this.props.getOperaciones()
  }
  selectTab = (tab) => {
    this.setState({
      tabActive: tab
    })
  }

  render() {
    const { balance, movimientos, logs, operaciones, permissions, } = this.props
    return (<Fragment>
      <section className="info-tiles">
        <div className="tile is-ancestor">
          <Can validate={['Creportes']} >
            <Balance currency="MXN" {...balance.MXN} />
          </Can>
          {+permissions.cuentaEnDolares === 1 && <Can validate={['Creportes']}>
            {balance.USD && (<Balance currency="USD" {...balance.USD} />)}
          </Can>}
        </div>
      </section>
      <section>
        <Columns>
          <Column className="is-12">
            <Can validate={['Creportes']}>
              <Movimientos movimientos={movimientos} tabActive={this.state.tabActive} selectTab={this.selectTab} />
            </Can>
          </Column>
        </Columns>
        <Columns>
          <Column className="is-6">
            <Can validate={['Creportes']}>
              <Operaciones operaciones={operaciones} labels={labelsOperaciones} />
            </Can>
          </Column>
          <Column className="is-6">
            <Can validate={['Creportes']}>
              <Logs logs={logs} />
            </Can>
          </Column>
        </Columns>
      </section>
    </Fragment>)
  }
}

const mapStateToProps = ({ home, principal }) => ({
  balance: home.balance,
  movimientos: home.movimientos,
  logs: home.logs,
  operaciones: home.operaciones,
  permissions: principal.inventoryPermission,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getBalance,
      getMovements,
      getLogs,
      getOperaciones,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Home)
