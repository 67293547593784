import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AuthorizeTas from './authorize'
import AuthorizeTfa from './authorizeTfa'

export { default as Recuperar } from './recuperar'
export { default as Login } from './login'
export { default as Can } from './Can'
export { default as ValidarToken } from './ValidarToken'
export { default as CambiarContrasena } from './CambiarContrasena'

const AuthorizeComponent = ({
  auth,
  ...rest
}) => {
  return auth.tfa ? <AuthorizeTfa {...rest} /> : <AuthorizeTas {...rest} />;
}

const mapStateToProps = ({ auth, principal }) => ({
  randomKey: auth.randomKey,
  auth: principal.authentication,
})

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch)

const Authorize = connect(mapStateToProps, mapDispatchToProps)(AuthorizeComponent)

export { Authorize }