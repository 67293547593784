import React from 'react'
import { Output, Check } from 'Components/common/form'
import { Columns, Column, Box } from 'Components/common/bulma'


const Perfil = ({ data }) => {
  return (
    <Box>
      <Columns>
        <Column>
          <Output
            {...data.id}
          />
        </Column>
        <Column>
          <Output
            {...data.integrado}
          />
        </Column>
      </Columns>
      <Columns>
        <Column>
        </Column>
        <Column>
          <Output
            {...data.estatus}
          />
        </Column>
      </Columns>
      <Columns>
        <Column>
          <Output
            {...data.numeroInstrumento}
          />
        </Column>
        <Column>
          <Output
            {...data.numeroNotaria}
          />
        </Column>
      </Columns>
      <Columns>
        <Column>
          <Output
            {...data.nombreNotario}
          />
        </Column>
        <Column>
          <Output
            {...data.fechaInstrumento}
          />
        </Column>
      </Columns>
      <Columns>
        <Column>
          <Check
            {...data.actaConstitutiva}
          />
        </Column>
        <Column>

        </Column>
      </Columns>
      <Columns>
        <Column>
          <Output
            {...data.numeroRegistroPublico}
          />
        </Column>
        <Column>
          <Output
            {...data.fechaRegistroPublico}
          />
        </Column>
      </Columns>
      <Columns>
        <Column>
          <Check
            {...data.registroPublicoTramite}
          />
        </Column>
      </Columns>
      <Columns>
        <Column>
          <Output
            {...data.principalAccionista}
          />
        </Column>
        <Column>
          <Output
            {...data.segundoAccionista}
          />
        </Column>
      </Columns>
      <Columns>
        <Column>
          <Output
            {...data.numeroEmpleados}
          />
        </Column>
        <Column>
          <Output
            {...data.capitalSocial}
          />
        </Column>
      </Columns>
    </Box>
  );
};

export default Perfil;