import React from 'react'
import { Box, Table } from 'Components/common/bulma'

const Movimientos = ({ logs }) => {


  return (
    <Box>
      <Table striped className="is-hoverable is-fullwidth" style={{marginBottom: 0}}>
        <Table.Head>
          <Table.Tr>
            <Table.Th>Fecha</Table.Th>
            <Table.Th className="has-text-right">Quien</Table.Th>
            <Table.Th className="has-text-right">Que hizo</Table.Th>
          </Table.Tr>
        </Table.Head>
      </Table>
      <div style={{maxHeight: '200px', overflowY: 'auto'}}>
      <Table striped className="is-hoverable is-fullwidth">
        <Table.Body>
          {logs.map((it, key) => {
            return (<Table.Tr key={key}>
              <Table.Td>
                {it.fechaHora}
              </Table.Td>
              <Table.Td className="has-text-right has-text-weight-bold">
                {it.quien}
              </Table.Td>
              <Table.Td className="has-text-right has-text-weight-bold">
                {it.queHizo}
              </Table.Td>
            </Table.Tr>)
          })}
        </Table.Body>
      </Table>
      </div>
    </Box>
  )
}

export default Movimientos