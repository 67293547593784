import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Message, Button, Delete } from 'Components/common/bulma'

const ModalRemove = ({
  isDeleting,
  title,
  toggleModal,
  confirmText,
  resource,
  deleteResource,
  actionButtonLabel = 'Eliminar',
  cancelButtonLabel = 'Cancelar',
  type = 'danger',
  children,
}) => {
  return (
    <Modal isActive={isDeleting}>
      <Message
        danger={['danger'].includes(type)}
        success={['success'].includes(type)}
        primary={['primary'].includes(type)}
        warning={['warning'].includes(type)}
      >
        <Message.Header>
          <p>{title}</p>
          <Delete onClick={toggleModal} />
        </Message.Header>
        <Message.Body className="has-text-centered">
          {confirmText}
          <br />
          <strong>{resource}</strong>
          <br />
          {children}
          <div className="margin-top-lg">
            <Button
              danger={['danger'].includes(type)}
              success={['success'].includes(type)}
              primary={['primary'].includes(type)}
              warning={['warning'].includes(type)}
              onClick={deleteResource}>{actionButtonLabel}</Button>
            <Button default className="margin-left-sm" onClick={toggleModal}>
              {cancelButtonLabel}
            </Button>
          </div>
        </Message.Body>
      </Message>
    </Modal>
  )
}

ModalRemove.propTypes = {
  isDeleting: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  confirmText: PropTypes.string.isRequired,
  resource: PropTypes.string,
  deleteResource: PropTypes.func.isRequired,
}

export default ModalRemove

