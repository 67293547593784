import React, { Fragment } from 'react'
import { Columns, Column } from 'Components/common/bulma'
import { Input, Select } from 'Components/common/form'

export default ({
  data,
  onChange,
  showErrors,
  estatusPersona,
}) => {
  return (
    <Fragment>
      <Columns>
        <Column>
          <Select
            onChange={onChange}
            options={estatusPersona}
            showErrors={showErrors}
            valueProperty="id"
            valueLabel="estatus"
            {...data.estatus}
          />
          <Select
            onChange={onChange}
            options={[
              { value: 'Autorizado', label: 'Autorizado' },
              { value: 'Pedido', label: 'Pedido' },
              { value: 'Emitido', label: 'Emitido' },
              { value: 'Entregado', label: 'Entregado' },
            ]}
            showErrors={showErrors}
            {...data.estatusEdenRed}
          />
        </Column>
        <Column>
        </Column>
      </Columns>
      <Columns>
        <Column>
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...data.numero}
          />
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...data.noTarjeta}
          />
        </Column>
        <Column>
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...data.centroGastos}
          />
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...data.email}
          />
        </Column>
      </Columns>
    </Fragment>
  )
}
