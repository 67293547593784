import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { isAllow } from 'Modules/catalogs/walletExpense'
import { Can } from 'Containers/auth';
import QuieroPrestarImg from '../../styles/images/Quiero-Prestar.png';
import HeRecibidoPrestamoImg from '../../styles/images/HeRecibido-Prestamo.png';
import AmortizacionCobroImg from '../../styles/images/Amortizacion-Cobro.png';
import AmortizacionPagoImg from '../../styles/images/Amortizacion-Pago.png';


export class Catalogs extends Component {

  render() {
    return (
      <section className="info-tiles">
        <div className="tile is-ancestor has-text-centered">
          <Can validate={['OpresAcreedoras', 'ApresAcreedoras']}>
            <div className="tile is-parent">
              <Link className="tile is-child box" to="/ordenes-prestamo/acreedoras">
                <p className="title has-text-grey-dark">
                  <img className="icons" src={QuieroPrestarImg} alt="catalogo de proveedores" />
                </p>
                <p className="subtitle">Quiero prestar (Acreedoras)</p>
              </Link>
            </div>
          </Can>
          <Can validate={['OpresDeudoras', 'ApresDeudoras']}>
            <div className="tile is-parent">
              <Link className="tile is-child box" to="/ordenes-prestamo/deudoras">
                <p className="title has-text-grey-dark">
                  <img className="icons" src={HeRecibidoPrestamoImg} alt="catalogo de proveedores" />
                </p>
                <p className="subtitle">He recibido un Préstamo (Deudoras)</p>
              </Link>
            </div>
          </Can>
          <Can validate={['OpresAmortizacionCobroPrestamo', 'ApresAmortizacionCobroPrestamo']}>
            <div className="tile is-parent">
              <Link className="tile is-child box" to="/ordenes-prestamo/amortizacion-cobro">
                <p className="title has-text-grey-dark">
                  <img className="icons" src={AmortizacionCobroImg} alt="catalogo de proveedores" />
                </p>
                <p className="subtitle">Amortización cobro Préstamos</p>
              </Link>
            </div>
          </Can>
          <Can validate={['OpresAmortizacionPagoPrestamo', 'ApresAmortizacionPagoPrestamo']}>
            <div className="tile is-parent">
              <Link className="tile is-child box" to="/ordenes-prestamo/amortizacion-pago">
                <p className="title has-text-grey-dark">
                  <img className="icons" src={AmortizacionPagoImg} alt="catalogo de proveedores" />
                </p>
                <p className="subtitle">Amortización pago Préstamos</p>
              </Link>
            </div>
          </Can>
        </div>
      </section>
    )
  }
}

const mapStateToProps = ({ catalogs }) => ({
  walletExpenseAllow: catalogs.walletExpense.isAllow

})

const mapDispatchToProps = dispatch => bindActionCreators({
  isAllow,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Catalogs)
export { default as LoanOrdersCreditor } from './creditor'
export { default as LoanOrdersDebtor } from './debtor'
export { default as LoanOrdersCreditorForm } from './creditor/form'
export { default as LoanOrdersDebtorForm } from './debtor/form'
export { default as LoanOrdersChargeAmortization } from './chargeAmortization'
export { default as LoanOrdersPaymentAmortization } from './paymentAmortization'
export { default as LoanOrdersChargeAmortizationForm } from './chargeAmortization/form'
export { default as LoanOrdersPaymentAmortizationForm } from './paymentAmortization/form'
export { default as AuthorizeLoanOrders } from './authorizeLoanOrders'