import moment from 'moment'

export const debtor = {
  id: {
    name: 'id',
    value: '',
  },
  estatusOrden: {
    label: 'Estatus Orden',
    name: 'estatus',
    value: '1',
    disabled: true,
    valueProperty: 'id',
    valueLabel: 'estatus',
  },
  prestamista: {
    label: 'Prestamista',
    name: 'prestamista',
    value: '',
    valueProperty: 'id',
    valueLabel: 'nombreORazonSocial',
    trigger: 'onChangeLender',
    validation: 'required',
  },
  tipoDePersona: {
    label: 'Tipo de persona',
    name: 'tipoDePersona',
    value: '',
    disabled: true,
    valueProperty: 'id',
    valueLabel: 'tipoPersona',
  },
  nombreORazonSocial: {
    name: 'nombreORazonSocial',
    label: 'Nombre',
    value: '',
    disabled: true,
  },
  rfc: {
    name: 'rfc',
    label: 'RFC',
    value: '',
    disabled: true,
  },
  correoElectronico: {
    name: 'correoElectronico',
    label: 'Correo Electrónico',
    value: '',
    disabled: true,
  },
  cuentaParaAbonoORetiro: {
    name: 'cuentaParaAbonoORetiro',
    label: 'Cuenta para Abono o Retiro',
    options: [
      {value: 'MXN', label: 'MXN'},
      {value: 'USD', label: 'USD'},
    ],
    value: 'MXN',
    validation: 'required',
  },
  tasaAnual: {
    name: 'tasaAnual',
    label: 'Tasa Anual del Préstamo',
    value: '',
    tooltip: 'Es la tasa de interés anualizada que se pagará al otorgar el préstamo, es de carácter informativo.',
    validation: 'required',
  },
  plazo: {
    name: 'plazo',
    label: 'Plazo',
    value: '1',
    tooltip: 'El plazo expresado en meses del retorno total o parcial del préstamo, es de carácter informativo.',
    validation: 'required',
  },
  frecuencia: {
    name: 'frecuencia',
    label: 'Frecuencia',
    value: '1',
    tooltip: 'Periodicidad de pago del préstamo, es de carácter informativo.',
    validation: 'required',
    valueProperty: 'id',
    valueLabel: 'frecuencia',
  },
  monto: {
    name: 'monto',
    label: 'Monto del préstamo',
    value: '',
    pattern: 'number',
    validation: 'required',
  },
  fechaPago: {
    name: 'fechaPago',
    label: 'Fecha de liquidación de Préstamo',
    value: '',
    tooltip: 'Fecha en la que se liquidará el préstamo',
    validation: 'required',
    dataMarshaller: val => moment(val).format('YYYY-MM-DD'),
    minDate: moment().toDate(),
  },
  archivo: {
    name: 'archivo',
    label: 'Contrato Préstamo',
    value: '',
    selectLabel: 'Adjuntar',
  },
  mutuo: {
    name: 'mutuo',
    label: 'Mutuo',
    value: '',
    selectLabel: 'Adjuntar',
  },
  montoDepositar: {
    name: 'montoDepositar',
    label: 'Monto por Depositar',
    value: '',
  },
  montoDepositarTransito: {
    label: 'Monto por depositar en Tránsito',
    name: 'montoDepositarTransito',
    value: '',
  },
  montoDepositado: {
    label: 'Monto Depositado',
    name: 'montoDepositado',
    value: '',
  },
  divisaMonedaTercero: {
    same: 'cuentaParaAbonoORetiro',
  },
  cuentaClabeSTPAsignada: {
    label: 'Cuenta Clabe STP Asignada',
    name: 'cuentaClabeSTPAsignada',
    value: '',
  },
  claveDePagoReferenciado: {
    name: 'claveDePagoReferenciado',
    label: 'Convenio CIE Bancomer Número 1372726',
    value: ''
  }
}

export const debtorCols =
  [
    {
      title: 'ID',
      datakey: 'id',
      sortable: true,
      width: '10%',
    },
    {
      title: 'Estatus Orden',
      datakey: 'estatus',
      sortable: true,
      width: '10%',
    },
    {
      title: 'RFC',
      datakey: 'RFC',
      sortable: true,
      width: '15%',
    },
    {
      title: 'Nombre o Razón Social',
      datakey: 'nombreORazonSocial',
      sortable: true,
      width: '40%',
    },
    {
      title: 'Monto',
      datakey: 'monto',
      format: '$ 0,0.00',
      sortable: true,
      width: '15%',
    },
    {
      title: 'Acciones',
      custom: 'actions',
      width: '10%',
    }
  ];

export const addressData = {
  direccion: {
    value: '',
    transform: 'stringToBool',
    trigger: 'onChangeDireccion',
    name: 'direccion',
    label: 'Dirección',
    disabled: true,
  },
  pais: {
    name: 'pais',
    value: '',
    validation: 'requiredIf:direccion',
    label: 'País',
    disabled: true
  },
  codigoPostal: {
    value: '',
    trigger: 'onChangeCp',
    pattern: 'number',
    name: 'codigoPostal',
    label: 'Código postal',
    disabled: true,
  },
  entidadFederativa: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'entidadFederativa',
    label: 'Entidad Federativa/Estado/Provincia',
    disabled: true,
  },
  municipio: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'municipio',
    label: 'Municipio',
    disabled: true,
  },
  colonia: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'colonia',
    label: 'Colonia',
    disabled: true,
  },
  calle: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'calle',
    label: 'Calle',
    disabled: true,
  },
  exterior: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'exterior',
    label: 'Exterior',
    disabled: true,
  },
  interior: {
    value: '',
    name: 'interior',
    label: 'Interior',
    disabled: true,
  },
}

export const amortizationCols = [
  {
    title: 'Id',
    datakey: 'id'
  },
  {
    title: 'Estatus Orden',
    datakey: 'estatusOrden',
  },
  {
    title: 'Monto',
    datakey: 'monto',
  },
  {
    title: 'Monto Depositado',
    datakey: 'montoDepositado',
  },
  {
    title: 'Monto por Depositar',
    datakey: 'montoPorDepositar'
  },
  {
    title: 'Acciones',
    custom: 'actions',
  }
]

export const paymentCols = [
  {
    title: 'Id',
    datakey: 'id'
  },
  {
    title: 'Fecha Transacción',
    datakey: 'fechaTransaccion'
  },
  {
    title: 'Estatus',
    datakey: 'estatusTexto'
  },
  {
    title: 'Cargos',
    datakey: 'cargos'
  },
  {
    title: 'Clave de rastreo',
    datakey: 'claveRastreo'
  },
  {
    title: 'Referencia Banco',
    datakey: 'referenciaBanco'
  },
]