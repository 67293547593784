import { hasPermission } from './util'
export const modules = [
  // {
  //   label: 'Integrados',
  //   module: 'integrados',
  //   permission: ['Oalta'],
  // },
  {
    label: 'Catálogos',
    module: 'catalogos',
    permission: ['Oalta'],
  },
  {
    label: 'Órdenes Ingreso',
    module: 'ordenes-ingreso',
    permission: ['Ofacturación', 'Afacturación', 'Odepósitos', 'Adepósitos'],
  },
  {
    label: 'Órdenes Pago',
    module: 'ordenes-pago',
    permission: ['Opago de proveedores', 'Apago de proveedores', 'Oretiros', 'Aretiros'],
  },
  {
    label: 'Órdenes Préstamos',
    module: 'ordenes-prestamo',
    permission: [
      'OpresAcreedoras',
      'OpresDeudoras',
      'OpresAmortizacionCobroPrestamo',
      'OpresAmortizacionPagoPrestamo',
      'ApresAcreedoras',
      'ApresDeudoras',
      'ApresAmortizacionCobroPrestamo',
      'ApresAmortizacionPagoPrestamo',
    ],
    partnerPermission: 'prestamos',
  },
  {
    label: 'Órdenes Traspasos',
    module: 'ordenes-traspaso',
    permission: ['Otraspaso', 'Atraspaso'],
    partnerPermission: 'traspasos',
  },
  {
    label: 'Contratos',
    module: 'contratos',
    permission: ['Acontratos', 'Ocontratos'],
    partnerPermission: 'pos',
  },
  {
    label: 'Conciliaciones',
    module: 'conciliaciones',
    permission: ['Oconciliaicones'],
    partnerPermission: 'conciliaciones',
  },
  {
    label: 'Reportes',
    module: 'reportes',
    permission: ['Creportes'],
  },
  // {
  //   label: 'Contabilidad',
  //   module: 'contabilidad',
  //   permission: ['Ccontabilidad'],
  // },
  // {
  //   label: 'Aclaraciones',
  //   module: 'aclaraciones',
  //   permission: ['Oaclaraciones'],
  // },
  // {
  //   label: 'Usuarios',
  //   module: 'usuarios',
  //   permission: ['Ausuario'],
  // },
  {
    label: 'Inventarios',
    module: 'inventarios',
    permission: ['Cinventarios'],
    partnerPermission: 'inventarios',
  },
  {
    label: 'Widget de pago',
    module: 'widget',
    permission: ['Oalta'],
    partnerPermission: 'widgetPago',
  },
]
export const getMenu = (permission, partnerPermission) => {
  return modules.filter(it => {
    return hasPermission(it.permission, permission)
  })
}