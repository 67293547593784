import React, { Component } from 'react'
import { getDataInput, getValues, validateData, mapPropsToState, validator, getClabeData, makeString } from 'App/helpers'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { ActionBar } from 'Components/common'
import {
  Box, SubTitle, Divider, Columns, Column,
  Tabs,
  Button,
  Icon,
  Modal,
  Message,
  Delete,
} from 'Components/common/bulma'
import { Select } from 'Components/common/form'
import { default as Table } from 'Components/common/table'
import { GeneralSection, AddressSection, ContactSection } from 'Components/catalogs/providers'
import AccountCreate from 'Components/catalogos/accounts/create'
import { addNotification } from 'Modules/principal'
import {
  generalData, addressData, contactData, accountData, accountColDef
} from './providerData'
import { Trans } from '@lingui/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import classnames from 'classnames'
import { NACIONAL, EXTRANJERO } from '../../../constants'
import CifLoader from '../components/cifLoader'


import {
  getEstatusPersona,
  getTipoPersona,
  getDireccion,
  getPaises,
  getEntidades,
  getBanks,
  getRegimenFiscalAction,
} from 'Modules/catalogos'
import {
  endEditProvider,
  updateProvider,
  getProvider,
  createProvider,
  getProviderAccounts,
  createAccount,
  deleteProvider,
  deleteAccount,
} from 'Modules/catalogs/providers'
const tagsInput = require('bulma-tagsinput')


const TIPO_PERSONA_VENTA_MOSTRADOR = 6
const RFC_EXTRANJERO = 'XEXX010101000'
const RFC_VENTA_MOSTRADOR = 'XAXX010101000'
const PAIS_MEXICO = '141'
const CADENA_VACIA = ''
const LONGITUD_CODIGO_POSTAL = 5

export class Proveedores extends Component {
  state = {
    generalData,
    addressData,
    contactData,
    showErrors: false,
    provider: undefined,
    accountTab: 'list',
    accountData,
    showAccountErrors: false,
  }
  componentDidMount() {
    this.props.getEstatusPersona()
    this.props.getTipoPersona()
    this.props.getPaises()
    this.props.getEntidades()
    this.props.getRegimenFiscal()
    this.emailTagged = new tagsInput('[type="tags"]', { delimiter: ';', duplicates: false })
    this.emailTagged.reset()
    this.emailTagged.element.addEventListener('change', e => {
      this.onChangeInput(e, 'contactData')
    })

    const id = this.props.match.params.id
    if (id && (id !== this.state.provider)) {
      this.props.getProvider(id)
      this.props.getProviderAccounts(id)
    }
  }
  componentWillUnmount() {
    this.props.endEditProvider()
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.provider.id && nextProps.provider.id !== this.state.provider) {
      const generalData = mapPropsToState(this.state.generalData, nextProps.provider)
      const addressData = mapPropsToState(this.state.addressData, nextProps.provider)
      const contactData = mapPropsToState(this.state.contactData, nextProps.provider)
      const cp = addressData.codigoPostal.value
      const direccion = addressData.direccion
      direccion.value = cp ? (cp !== "0") : false;

      this.setState({
        provider: nextProps.provider.id,
        generalData,
        addressData: { ...addressData, direccion },
        contactData,
      }, () => {
        this.emailTagged.addTag(contactData.correoElectronico.value);
        this.initNacionalExtranjero(generalData.proveedorNacionalOExtranjero)
        // this.onChangeTipoPersona(generalData.tipoDePersona)
      })
    }
  }
  onChangeInput = ({ target }, section) => {
    const { name } = target
    const data = getDataInput(target, this.state[section])
    const field = data[name]

    this.setState({
      [section]: data,
      showErrors: false,
      showAccountErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }
  onChangeNacionalExtranjero = (field) => {
    const { generalData, addressData } = this.state

    if (field.value === EXTRANJERO) {
      generalData.tipoDePersona.value = 4
      addressData.direccion.value = true
      generalData.rfc.value = RFC_EXTRANJERO
      generalData.tipoDePersona.disabled = true
      generalData.rfc.disabled = true
      addressData.pais.disabled = false
    } else if (field.value === NACIONAL) {
      generalData.tipoDePersona.value = 1
      generalData.rfc.value = CADENA_VACIA
      addressData.pais.value = PAIS_MEXICO
      generalData.rfc.disabled = false
      generalData.tipoDePersona.disabled = false
      addressData.pais.disabled = true
      this.onChangeCp(addressData.codigoPostal)
    }
    this.setState({
      generalData,
      addressData,
    })
  }
  initNacionalExtranjero = (field) => {
    const { generalData, addressData } = this.state

    if (field.value === EXTRANJERO) {
      generalData.tipoDePersona.disabled = true
      generalData.rfc.disabled = true
      addressData.pais.disabled = false
    } else if (field.value === NACIONAL) {
      generalData.rfc.disabled = false
      generalData.tipoDePersona.disabled = false
      addressData.pais.disabled = true
    }
    this.setState({
      generalData,
      addressData,
    })
  }
  onChangeCp = ({ value }) => {
    const { generalData, addressData } = this.state
    if (value.length === LONGITUD_CODIGO_POSTAL
      && generalData.proveedorNacionalOExtranjero.value === NACIONAL) {
      this.props.getDireccion(value).then((response) => {
        if (response.length) {
          const { estado, municipio, asentamiento } = response[0]

          addressData.entidadFederativa.value = estado
          addressData.municipio.value = municipio
          addressData.colonia.value = asentamiento

          this.setState({
            addressData,
            generalData,
          })
        }
      })
    }
  }
  onChangeDireccion = ({ value }) => {
    const data = addressData;

    data.direccion.value = value
    if (!value) {
      this.setState({
        addressData: data
      })
    }
  }
  onChangeTipoPersona = ({ value }) => {
    const { generalData } = this.state

    if (+value === TIPO_PERSONA_VENTA_MOSTRADOR) {
      generalData.rfc.value = RFC_VENTA_MOSTRADOR
      generalData.rfc.disabled = true
    } else {
      generalData.rfc.value = ''
      generalData.rfc.disabled = false
    }
    this.setState({
      generalData,
    })
  }
  createProvider = (callback) => {
    const { data: addressData, hasErrors: hasDireccionError } = validateData(this.state.addressData)
    const { data: generalData, hasErrors: hasGeneralError } = validateData(this.state.generalData)
    const { data: contactData, hasErrors: hascontactoError } = validateData(this.state.contactData)

    if (hasDireccionError || hasGeneralError || hascontactoError) {
      this.setState({
        addressData,
        generalData,
        contactData,
        showErrors: true,
      })
      this.props.addNotification({
        type: 'danger',
        message: '¡Favor de validar algunos campos!'
      })
      return
    }
    const direccionValues = getValues(this.state.addressData)
    const generalValues = getValues(this.state.generalData)
    const contactoValues = getValues(this.state.contactData)

    const { updateProvider, createProvider } = this.props

    const action = this.state.provider ? updateProvider : createProvider
    const id = this.props.match.params.id
    action({
      ...direccionValues,
      ...contactoValues,
      ...generalValues, id: this.state.provider
    }).then(({ data }) => {
      this.props.addNotification({
        type: 'success',
        message: 'El proveedor se creó/actualizó correctamente'
      })
      id || this.props.push(`/catalogos/proveedores/${data.id}`)
      typeof callback === 'function' && callback()
    }).catch((data) => {
      this.props.addNotification({
        type: 'danger',
        message: 'Upssss, Ocurrió un error'
      })
    })
  }
  onCancel = () => {
    this.props.push('/catalogos/proveedores')
  }
  changeAccountTab = (view) => {
    const provider = this.state.provider
    if (!provider && view === 'new') {
      this.createProvider(() => {
        this.setState({
          accountTab: view
        })
      })
      return
    }

    this.setState({
      accountTab: view
    })
  }
  onChangeClabe = (field) => {
    console.log('se dispara')
    if (field.value.length === 18 && validator(field.value, field.validation, this.state.accountData).valid) {
      const { place, account } = getClabeData(field.value)
      this.props.getBanks(field.value)
        .then(({ clabe }) => {
          const accountData = mapPropsToState(this.state.accountData, { banco: clabe, plaza: place, cuentaBancaria: account })
          this.setState({ accountData })
        })
    }
  }
  createAccount = () => {
    const { data, hasErrors } = validateData(this.state.accountData)

    if (hasErrors) {
      this.setState({
        accountData: data,
        showAccountErrors: true
      })
      this.props.addNotification({
        type: 'danger',
        message: '¡Favor de validar los campos!'
      })
      return
    }
    this.props.createAccount(this.state.provider, getValues(this.state.accountData))
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'La cuenta se agregó correctamente'
        })
        this.setState({
          accountData: accountData
        })
        this.props.getProviderAccounts(this.state.provider)
          .then(() => {
            this.setState({
              accountTab: 'list',
            })
          })
      })
  }
  getPermissions = () => {
    const { provider } = this.state
    return {
      canDelete: !!provider,
      canSave: true,
    }
  }
  toggleModal = () => {
    this.setState((state) => ({
      showModal: !state.showModal,
    }))
  }
  deleteProvider = () => {
    this.props.deleteProvider(this.state.provider)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'El proveedor se eliminó correctamente'
        })
        this.props.push('/catalogos/proveedores')
      })
  }
  cancelAccount = () => {
    this.setState({
      accountTab: 'list',
      accountData: accountData,
    })
  }
  toggleAccountModal = (account) => {
    this.setState(state => ({
      showAccountModal: !state.showAccountModal,
      selectedAccount: account,
    }))
  }
  deleteAccount = () => {
    this.props.deleteAccount(this.state.provider, this.state.selectedAccount.id)
      .then(() => {
        this.setState({ selectedAccount: {}, showAccountModal: false })
        this.props.addNotification({
          type: 'success',
          message: 'La cuenta se eliminó correctamente'
        })
      })
  }

  onCifLoaded = (data) => {

    const isPhisical = data.rfc.length === 13
    const makeFullName = makeString`${'nombre'} ${'apellidoPaterno'} ${'apellidoMaterno'}`;
    const name = isPhisical ? makeFullName(data) : data.denominacionORazonSocial

    const generalData = mapPropsToState(this.state.generalData, {
      rfc: data.rfc,
      nombreORazonSocial: name,
      uriCsf: data.url,
      proveedorNacionalOExtranjero: NACIONAL,
      tipoDePersona: isPhisical ? 2 : 1,
    })

    const addressData = mapPropsToState(this.state.addressData, {
      direccion: true,
      pais: 141,
      entidadFederativa: data.entidadFederativa,
      municipio: data.municipioODelegacion,
      colonia: data.colonia,
      calle: data.nombreDeLaVialidad,
      exterior: data.numeroExterior,
      interior: data.numeroInterior,
      codigoPostal: data.cp,
    })


    const contactData = mapPropsToState(this.state.contactData, {
      correoElectronico: data.correoElectronico,
    })

    this.setState({ generalData, addressData, contactData }, () => {
      this.emailTagged.addTag(contactData.correoElectronico.value);
    })
  }
  customColActions = (row) => (
    <Button danger outlined onClick={() => this.toggleAccountModal(row)}>
      <Icon>
        <FontAwesomeIcon icon={faTrash} />
      </Icon>
    </Button>
  )
  render() {
    const { paises, tipoPersona, estatusPersona, entidades, accounts, provider } = this.props
    return (
      <Box >
        <Modal isActive={this.state.showModal}>
          <Message danger>
            <Message.Header>
              <p>Eliminar proveedor</p>
              <Delete onClick={this.toggleModal} />
            </Message.Header>
            <Message.Body className="has-text-centered">
              ¿Esta seguro de querer eliminar el proveedor?
              <br />
              <strong>{provider.nombreORazonSocial}</strong>
              <div className="margin-top-lg">
                <Button danger onClick={this.deleteProvider}>Eliminar</Button>
                <Button default className="margin-left-sm" onClick={this.toggleModal}>
                  Cancelar
                </Button>
              </div>
            </Message.Body>
          </Message>
        </Modal>
        <Modal isActive={this.state.showAccountModal}>
          <Message danger>
            <Message.Header>
              <p>Eliminar cuenta</p>
              <Delete onClick={this.toggleAccountModal} />
            </Message.Header>
            <Message.Body className="has-text-centered">
              ¿Esta seguro de querer eliminar la cuenta seleccionada?
              <div className="margin-top-lg">
                <Button danger onClick={this.deleteAccount}>Eliminar</Button>
                <Button default className="margin-left-sm" onClick={this.toggleAccountModal}>
                  Cancelar
                </Button>
              </div>
            </Message.Body>
          </Message>
        </Modal>
        <SubTitle className="has-text-centered" is='3'>Catálogo de proveedores</SubTitle>
        <ActionBar
          permissions={this.getPermissions()}
          onSave={this.createProvider}
          onCancel={this.onCancel}
          onDelete={this.toggleModal}
          basicRole={['Oalta']}
        />
        <form>
          <Divider content="DATOS GENERALES" />
          <Columns>
            <Column className="is-half">
              <CifLoader
                onCifLoaded={this.onCifLoaded}
              />
            </Column>
          </Columns>
          <GeneralSection
            data={this.state.generalData}
            estatusPersona={estatusPersona}
            tipoPersona={tipoPersona}
            onChange={(e) => this.onChangeInput(e, 'generalData')}
            showErrors={this.state.showErrors}
          />
          <Divider content="DIRECCIÓN" />
          <Columns>
            <Column className="is-6">
              <Select
                onChange={(e) => this.onChangeInput(e, 'addressData')}
                options={[
                  { value: false, label: 'Sin Dirección' },
                  { value: true, label: 'Con Dirección' },
                ]}
                showErrors={this.state.showErrors}
                {...this.state.addressData.direccion}
              />
            </Column>
          </Columns>
          {this.state.addressData.direccion.value && (
            <AddressSection
              data={this.state.addressData}
              onChange={e => this.onChangeInput(e, 'addressData')}
              paises={paises}
              entidades={entidades}
              extranjero={this.state.generalData.proveedorNacionalOExtranjero.value === EXTRANJERO}
              showErrors={this.state.showErrors}
            />)}
          <Divider content="DATOS DE CONTACTO" />
          <ContactSection
            data={this.state.contactData}
            onChange={(e) => this.onChangeInput(e, 'contactData')}
            showErrors={this.state.showErrors}
          />
        </form>
        <Divider content="CUENTAS BANCARIAS" />
        <Tabs>
          <ul>
            <li className={classnames({ 'is-active': this.state.accountTab === 'list' })}>
              <Button className="is-text" onClick={() => this.changeAccountTab('list')}><Trans>Cuentas</Trans></Button></li>
            <li>
              <Button success outlined onClick={() => this.changeAccountTab('new')}>
                <Icon>
                  <FontAwesomeIcon icon={faPlus} />
                </Icon>
                <Trans render="span">Agregar cuenta bancaria</Trans>
              </Button>
            </li>
          </ul>
        </Tabs>
        {this.state.accountTab === 'list' && (
          <Table
            colsetup={accountColDef}
            coldata={accounts}
            tableClassName='table is-striped is-hoverable is-fullwidth'
            emptyTableMarkUp={(<span>Aun no hay cuentas</span>)}
            customCol={{
              customColName: 'acciones',
              renderFunc: this.customColActions
            }}
            initialFetch={false}
            refresh={(page) => this.props.getProviderAccounts(this.state.provider, page)}
          />
        )
        }
        {this.state.accountTab === 'new' && (
          <AccountCreate
            data={this.state.accountData}
            estatusPersona={estatusPersona}
            onChange={e => this.onChangeInput(e, 'accountData')}
            showErrors={this.state.showAccountErrors}
            onSave={this.createAccount}
            onCancel={this.cancelAccount}
          />)}
      </Box>
    )
  }
}

const mapStateToProps = ({ catalogos, catalogs }) => ({
  estatusPersona: catalogos.estatusPersona,
  tipoPersona: catalogos.tipoPersona,
  direccion: catalogos.direccion,
  paises: catalogos.paises,
  entidades: catalogos.entidades,
  provider: catalogs.providers.provider,
  accounts: catalogs.providers.accounts,
  isFetching: catalogs.providers.isFetchingProviders,
  regimenFiscal: catalogos.regimenFiscal,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getEstatusPersona,
  getTipoPersona,
  getDireccion,
  getPaises,
  createProvider,
  addNotification,
  getEntidades,
  push,
  getProvider,
  updateProvider,
  endEditProvider,
  getProviderAccounts,
  createAccount,
  getBanks,
  deleteProvider,
  deleteAccount,
  getRegimenFiscal: getRegimenFiscalAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Proveedores)
