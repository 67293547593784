import { useRef, useCallback, useState } from 'react';

import useForm from 'Hooks/useForm';
import { useDependencies } from 'App/DependencyContext';
import { fields } from './definition'
import { getPersonalInfo } from 'App/hexmodules/personalInfo/application/get/getPersonalInfo';
import { createPersonalInfo } from 'App/hexmodules/personalInfo/application/create/createPersonalInfo';
import { updatePersonalInfo } from 'App/hexmodules/personalInfo/application/update/updatePersonalInfo';
import { addNotification } from 'Modules/principal'
import { useDispatch } from 'react-redux'


const STATE = {
  INITIAL: 'initial',
  FETCHING_PERSONAL_INFO: 'fetchingPersonalInfo',
  FETCHED_PERSONAL_INFO: 'fetchedPersonalInfo',
  ERROR_FETCHING_PERSONAL_INFO: 'errorFetchingPersonalInfo',
  SAVING_PERSONAL_INFO: 'savingPersonalInfo',
  SAVED_PERSONAL_INFO: 'savedPersonalInfo',
  ERROR_SAVING_PERSONAL_INFO: 'errorSavingPersonalInfo',
};

function usePersonalInfo({ refresh }) {
  const state = useRef(STATE.INITIAL);
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [formValues, setFormValues, handleOnChangeField, isValid, getValues, showErrors] = useForm({
    fields,
  });

  const { personalInfoRepository } = useDependencies()

  const initialFetch = async () => {
    if (state.current === STATE.INITIAL) {
      setIsLoading(true)
      state.current = STATE.FETCHING_PERSONAL_INFO;
      const personalInfo = await getPersonalInfo(personalInfoRepository);
      setFormValues(personalInfo);
      setIsLoading(false)
      state.current = STATE.FETCHED_PERSONAL_INFO;
    }
  };

  initialFetch();
  const savePersonalInfo = async () => {
    if (isValid()) {
      try {
        setIsLoading(true)
        state.current = STATE.SAVING_PERSONAL_INFO;
        const personalInfo = getValues();

        if (personalInfo.id_personal_data) {
          await updatePersonalInfo(personalInfoRepository, personalInfo)
        } else {
          await createPersonalInfo(personalInfoRepository, personalInfo)
        }
        await refresh();
        state.current = STATE.SAVED_PERSONAL_INFO;
        setIsLoading(false)
        dispatch(addNotification({
          type: 'success',
          message: 'Infomación guardada correctamente',
        }))
      } catch ({ response: { data } }) {
        setIsLoading(false)
        dispatch(addNotification({
          type: 'danger',
          message: data?.error,
        }))
      }
    }
  };

  const getCurrentState = useCallback(() => {
    return state.current;
  }, []);


  return {
    values: formValues,
    getCurrentState,
    onChangeField: handleOnChangeField,
    showErrors,
    savePersonalInfo,
    isLoading,
  };
}

export default usePersonalInfo;
