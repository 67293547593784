import { useRef, useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid'
import { append, isEmpty, nth, pathOr, propOr } from 'ramda'

import useArrayForm from 'Hooks/useArrayForm';

import { createLegalRepresentative } from 'App/hexmodules/legalRepresentative/application/create/createLegalRepresentative'
import { getLegalRepresentative } from 'App/hexmodules/legalRepresentative/application/get/getLegalRepresentative'
import { updateLegalRepresentative } from 'App/hexmodules/legalRepresentative/application/update/updateLegalRepresentative'
import { deleteLegalRepresentative } from 'App/hexmodules/legalRepresentative/application/delete/deleteLegalRepresentative'
import { useDependencies } from 'App/DependencyContext';
import { fields } from './definition'
import { addNotification } from 'Modules/principal'
import { useDispatch } from 'react-redux'

const STATE = {
  INITIAL: 'initial',
  FETCHING_LEGAL_REPRESENTATIVE: 'fetchingLegalRepresentative',
  FETCHED_LEGAL_REPRESENTATIVE: 'fetchedLegalRepresentative',
  ERROR_FETCHING_LEGAL_REPRESENTATIVE: 'errorFetchingLegalRepresentative',
  SAVING_LEGAL_REPRESENTATIVE: 'savingLegalRepresentative',
  SAVED_LEGAL_REPRESENTATIVE: 'savedLegalRepresentative',
  ERROR_SAVING_LEGAL_REPRESENTATIVE: 'errorSavingLegalRepresentative',
};

function useLegalRepresentative({ refresh }) {
  const state = useRef(STATE.INITIAL);
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const [formValues, setFormValues, handleOnChangeField, isValid, , showErrors, onRemove, , getFormValues, mapNewFields] = useArrayForm(
    {
      fields: [{ ...fields, id: uuid() }],
      fieldsDefinition: fields,
    });

  const { legalRepresentativeRepository } = useDependencies()

  const initialFetch = async () => {
    if (state.current === STATE.INITIAL) {
      setIsLoading(true)
      state.current = STATE.FETCHING_LEGAL_REPRESENTATIVE;
      const legalRepresentative = await getLegalRepresentative(legalRepresentativeRepository);
      if (!isEmpty(legalRepresentative)) {
        const newFields = legalRepresentative.map(it => ({ ...it, idDocument: it.id, id: it.id_legal }))
        mapNewFields(newFields)
      }
      state.current = STATE.FETCHED_LEGAL_REPRESENTATIVE;
      setIsLoading(false)
    }
  };

  initialFetch();
  const saveLegalRepresentative = async (idx) => {
    if (isValid()) {
      try {
        setIsLoading(true)
        state.current = STATE.SAVING_LEGAL_REPRESENTATIVE;
        const legalRepresentatives = getFormValues();
        const currentLegalRepresentative = nth(idx)(legalRepresentatives)
        const legalRepresentativeId = propOr('', 'id_legal', currentLegalRepresentative)

        if (legalRepresentativeId) {
          await updateLegalRepresentative(legalRepresentativeRepository, { ...currentLegalRepresentative, id: currentLegalRepresentative.idDocument }, legalRepresentativeId)
        } else {
          await createLegalRepresentative(legalRepresentativeRepository, { ...currentLegalRepresentative, id: currentLegalRepresentative.idDocument })
        }
        await refresh();
        state.current = STATE.SAVED_LEGAL_REPRESENTATIVE;
        dispatch(addNotification({
          type: 'success',
          message: 'Infomación guardada correctamente',
        }))
        setIsLoading(false)
      } catch ({ response: { data } }) {
        dispatch(addNotification({
          type: 'danger',
          message: data?.error,
        }))
        setIsLoading(false)
      }

    }
  };

  const getCurrentState = useCallback(() => {
    return state.current;
  }, []);

  const addLegalRepresentative = () => {
    setFormValues(append({ ...fields, id: uuid() }, formValues))
  }

  const onRemoveItem = async (item) => {
    try {
      setIsLoading(true)

      const legalRepresentativeId = pathOr('', ['id_legal', 'input', 'value'], item)
      if (legalRepresentativeId) {
        await deleteLegalRepresentative(legalRepresentativeRepository, legalRepresentativeId)
        const legalRepresentative = await getLegalRepresentative(legalRepresentativeRepository);
        if (!isEmpty(legalRepresentative)) {
          const newFields = legalRepresentative.map(it => ({ ...it, idDocument: it.id, id: it.id_legal }))
          mapNewFields(newFields)
        } else {
          setFormValues([{ ...fields, id: uuid() }])
        }
      } else {
        onRemove(item)
      }
      setIsLoading(false)
      dispatch(addNotification({
        type: 'success',
        message: 'Registro eliminado correctamente',
      }))
    } catch ({ response: { data } }) {
      setIsLoading(false)
      dispatch(addNotification({
        type: 'danger',
        message: data?.error,
      }))
    }
  }

  return {
    values: formValues,
    getCurrentState,
    onChangeField: handleOnChangeField,
    showErrors,
    addLegalRepresentative,
    saveLegalRepresentative,
    onRemove: onRemoveItem,
    isLoading,
  };
}

export default useLegalRepresentative;
