import React from 'react';
import { Box, Columns, Column, Button } from 'Components/common/bulma'
import useLegalRepresentative from './useLegalRepresentative';
import InputWithError from 'Components/common/form/InputWithError'
import SelectWithError from 'Components/common/form/SelectWithError'
import File from 'Components/common/form/FileWithErrors'
import Loader from 'Components/common/page/loaderFloat';
import Show from 'Components/common/Show';


const LegalRepresentativeData = ({ refresh }) => {
  const { values, onChangeField, saveLegalRepresentative, onRemove, addLegalRepresentative, showErrors, isLoading } = useLegalRepresentative({ refresh })

  return (
    <Box style={{ position: 'relative' }}>
      <Show canShow={isLoading}>
        <Loader />
      </Show>
      <Columns className="is-multiline">
        <Column className="is-half is-offset-half has-text-right">
          <Button className="is-primary" onClick={addLegalRepresentative}>Agregar representante legal</Button>
        </Column>
      </Columns>
      {values.map((legalRepresentative, idx) => <Box><Columns className="is-multiline">

        <Column className="is-half">
          <InputWithError
            onChange={onChangeField}
            showErrors={showErrors}
            {...{ ...legalRepresentative.name, input: { ...legalRepresentative.name.input, id: idx } }}
          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onChangeField}
            showErrors={showErrors}
            {...{ ...legalRepresentative.lastName, input: { ...legalRepresentative.lastName.input, id: idx } }}

          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onChangeField}
            showErrors={showErrors}
            {...{ ...legalRepresentative.motherLastName, input: { ...legalRepresentative.motherLastName.input, id: idx } }}


          />
        </Column>
        <Column className="is-half">
          <SelectWithError
            onChange={onChangeField}
            showErrors={showErrors}
            {...{ ...legalRepresentative.gender, input: { ...legalRepresentative.gender.input, id: idx } }}
          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onChangeField}
            showErrors={showErrors}
            {...{ ...legalRepresentative.rfc, input: { ...legalRepresentative.rfc.input, id: idx } }}

          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onChangeField}
            showErrors={showErrors}
            {...{ ...legalRepresentative.curp, input: { ...legalRepresentative.curp.input, id: idx } }}

          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onChangeField}
            showErrors={showErrors}
            {...{ ...legalRepresentative.email, input: { ...legalRepresentative.email.input, id: idx } }}

          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onChangeField}
            showErrors={showErrors}
            {...{ ...legalRepresentative.phone, input: { ...legalRepresentative.phone.input, id: idx } }}

          />
        </Column>
        <Column className="is-half">
          <File
            onChange={onChangeField}
            showErrors={showErrors}
            {...{ ...legalRepresentative.idDocument, input: { ...legalRepresentative.idDocument.input, id: idx } }}

          />
        </Column>
        <Column className="is-half">
          <File
            onChange={onChangeField}
            showErrors={showErrors}
            {...{ ...legalRepresentative.taxSituation, input: { ...legalRepresentative.taxSituation.input, id: idx } }}

          />
        </Column>
        <Column className="is-half">
          <File
            onChange={onChangeField}
            showErrors={showErrors}
            {...{ ...legalRepresentative.document_curp, input: { ...legalRepresentative.document_curp.input, id: idx } }}
          />
        </Column>
        <Column className="is-half">
          <File
            onChange={onChangeField}
            showErrors={showErrors}
            {...{ ...legalRepresentative.attorneyPower, input: { ...legalRepresentative.attorneyPower.input, id: idx } }}
          />
        </Column>
        <Column className="is-full has-text-right">
          <Button className="is-primary mr-4" onClick={() => saveLegalRepresentative(idx)}>Guardar</Button>
          <Button className="is-warning" onClick={() => onRemove(legalRepresentative)}>Eliminar</Button>
        </Column>
      </Columns>
      </Box>)}
    </Box >
  );
};

export default LegalRepresentativeData;
