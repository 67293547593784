import React, { useState, useRef, useEffect } from 'react'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function ActionMenu({ children }) {
  const container = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutside = (event) => {
    if (container.current && !container.current.contains(event.target)) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, []);

  return (
    <div style={{ position: 'relative' }} ref={container} className="action-menu">
      <div onClick={() => setIsOpen(!isOpen)} style={{ textAlign: 'right', float: 'right', width: '15px' }}>
        <FontAwesomeIcon icon={faEllipsisV} />
      </div>
      <div>
        {isOpen && <div style={{ position: 'absolute', zIndex: 999, textAlign: 'right'}}>
          {children}
        </div>}
      </div>
    </div>
  )
}
