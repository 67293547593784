import moment from 'moment'
import numeral from 'numeral'

const limitHour = moment({ hour: 16, minute: 5 })
const minDate = moment() < limitHour ? moment({ hour: 0 }) : moment({ hour: 0 }).add(1, 'day')

export const retreatsList =
  [
    {
      title: 'ID',
      datakey: 'id',
      sortable: true,
      width: '10%',
    },
    {
      title: 'Estatus Orden',
      datakey: 'estatus',
      sortable: true,
      width: '20%',
    },
    {
      title: 'Nombre o Razón Social',
      datakey: 'nombreRazonSocial',
      sortable: true,
      width: '40%',
    },
    {
      title: 'Total',
      datakey: 'Total',
      format: '$ 0,0.00',
      sortable: true,
      width: '20%',
    },
    {
      title: 'Acciones',
      custom: 'actions',
      width: '10%',
    }
  ]

export const retreat = {
  estatusOrden: {
    name: 'estatusOrden',
    label: 'Estatus order',
    disabled: true,
    value: '1',
    valueProperty: 'id',
    valueLabel: 'estatus',
  },
  tipoDePersona: {
    value: '',
    name: 'tipoDePersona',
    label: 'Tipo de Persona',
    disabled: true,
    valueProperty: 'id',
    valueLabel: 'tipoPersona',
  },
  nombreRazonSocial: {
    value: '',
    name: 'nombreRazonSocial',
    label: 'Nombre o razón social',
    disabled: true,
  },
  divisaMoneda: {
    value: 'MXN',
    name: 'divisaMoneda',
    label: 'Divisa Moneda',
  },
  cuentaRetiro: {
    value: '',
    name: 'cuentaRetiro',
    label: 'Cuenta de Retiro',
    options: [{value:'MXN', label: 'MXN'},{value:'USD', label:'USD'}],
    validation: 'required',
  },
  metodoPago: {
    name: 'metodoPago',
    label: 'Método de pago',
    valueProperty: 'id',
    valueLabel: 'metodo',
    disabled: true,
    value: '1',
  },
  referencia: {
    name: 'referencia',
    label: 'Referencia',
    validation: 'required',
    value: moment().format('DDMMYY'),
    maxLength: '7',
    pattern: 'number',
  },
  cuentaClabeBeneficiario: {
    name: 'cuentaClabeBeneficiario',
    label: 'Cuenta Clabe Beneficiario',
    validation: 'required',
    value: '',
  },
  montoRetira: {
    name: 'montoRetira',
    label: 'Monto a Retirar',
    validation: 'required',
    value: '',
    pattern: 'number',
  },
}

export const addressData = {
  direccion: {
    value: '',
    transform: 'stringToBool',
    trigger: 'onChangeDireccion',
    name: 'direccion',
    label: 'Dirección',
    disabled: true,
  },
  pais: {
    name: 'pais',
    value: '',
    validation: 'requiredIf:direccion',
    label: 'País',
    disabled: true
  },
  codigoPostal: {
    value: '',
    trigger: 'onChangeCp',
    pattern: 'number',
    name: 'codigoPostal',
    label: 'Código postal',
    disabled: true,
  },
  entidadFederativa: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'entidadFederativa',
    label: 'Entidad Federativa/Estado/Provincia',
    disabled: true,
  },
  municipio: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'municipio',
    label: 'Municipio',
    disabled: true,
  },
  colonia: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'colonia',
    label: 'Colonia',
    disabled: true,
  },
  calle: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'calle',
    label: 'Calle',
    disabled: true,
  },
  exterior: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'exterior',
    label: 'Exterior',
    disabled: true,
  },
  interior: {
    value: '',
    name: 'interior',
    label: 'Interior',
    disabled: true,
  },
}

export const paymentTable = [
  {
    title: 'id',
    datakey: 'id',
  },
  {
    title: 'Fecha Transacción',
    datakey: 'fechaTransaccion',
  },
  {
    title: 'Estatus',
    datakey: 'estatusTexto',
  },
  {
    title: 'Cargo',
    datakey: 'cargo',
  },
  {
    title: 'Clave Rastreo',
    datakey: 'claveRastreo',
  },
  {
    title: 'Referencia de Banco',
    datakey: 'referenciaBanco',
  },
  {
    title: 'Acciones',
    custom: 'acciones',
  }
]

export const installment = {
  divisaMoneda: {
    name: 'divisaMoneda',
    label: 'Moneda pago',
  },
  cargo: {
    label: 'Cargos',
    name: 'cargo',
    validation: 'required|greaterThan:0',
    pattern: 'number',
    value: 0,
    trigger: 'updateClabe',
    format: (value) => numeral(value).format('$ 0,0.00'),
    disabled: true,
  },
  fechaTransaccion: {
    label: 'Fecha de Transacción',
    name: 'fechaTransaccion',
    validation: `required|gtOrEqDate:${minDate.format('YYYY-MM-DD')}`,
    value: undefined,
  },
  estatus: {
    label: 'Estatus',
    name: 'estatus',
  },
  idOrden: {
    label: 'Orden Pago',
    name: 'idOrden',
  },
  referencia: {
    label: 'Referencia Banco',
    name: 'referencia',
  },
  metodoPago: {
    label: 'Metódo de pago',
    name: 'metodoPago',
  },
  cuentaMXN: {
    label: 'Cuenta MXN',
    name: 'cuentaMXN',
  },
  claveRastreo: {
    label: 'Clave de rastreo',
    name: 'claveRastreo',
  },
  typePaymentProvider: {
    label: 'Tipo de Pago',
    name: 'typePaymentProvider',
  },
  cuentaClabeColaborador: {
    value: '',
    name: 'cuentaClabeColaborador',
    label: 'Cuenta Clabe Colaborador',
  },
}

export const totals = {
  subtotal: {
    name: 'subtotal',
    label: 'Subtotal',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  IVA: {
    name: 'IVA',
    label: 'IVA',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  ieps: {
    name: 'ieps',
    label: 'IEPS',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  montoPagado: {
    name: 'montoPagado',
    label: 'Monto Pagado',
    value: 0,
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  montoPagoTransito: {
    name: 'montoPagoTransito',
    label: 'Monto Pago Tránsito',
    value: 0,
    format: (value) => numeral(Math.round(+value * 100) / 100).format('$ 0,0.00'),
    align: 'right',
  },
  montoPagar: {
    name: 'montoPagar',
    label: 'Monto por Pagar',
    value: 0,
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  estatusFacturaProveedor: {
    name: 'estatusFactura',
    label: 'Estatus Factura Proveedor',
    valueProperty: 'id',
    valueLabel: 'estatus',
    disabled: true,
    value: '3',
  },
  total: {
    name: 'total',
    label: 'Total',
    value: 0,
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
}