import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Header } from 'Components/common'
import { logout } from 'Modules/auth'
import { changeLanguage } from 'Modules/i18n'
import { bindActionCreators } from 'redux'

class HeaderContainer extends Component {
  state = {
    showMenu: false,
  }
  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu
    })
  }
  render() {
    return <Header
      {...this.props}
      showMenu={this.state.showMenu}
      toggleMenu={this.toggleMenu}
    />
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.authenticated,
  languages: state.i18n.languages,
  language: state.i18n.language,
  menu: state.principal.menu,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  logout,
  changeLanguage,
}, dispatch)


export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer)
