export const TIPO_PERSONA_MORAL = 1
export const NACIONAL = 'Nacional (MX)'
export const EXTRANJERO = 'Extranjero'
export const CONVENIO_CIE_BBVA = 'Convenio CIE BBVA Bancomer'
export const DIRECTO = 'Directo'
export const INTERMEDIARIO = 'Intermediario'
export const MXN = 'MXN'
export const USD = 'USD'
export const TRANSFER_PAYMENT_METHOD = 1
export const CIE_PAYMENT_METHOD = 3

/** Provider account */
