import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ActionBar, Table, ModalRemove } from 'Components/common'
import { mapPropsToState, getDataInput, validateData, getValues } from 'App/helpers'
import {
  addressData,
  order,
  totals,
  conceptData,
  conceptsCol,
  paymentTable,
  paymentData,
  installment,
} from './data'
import {
  Box,
  Divider,
  Columns,
  Column,
  Button,
  Modal,
  Message,
  Delete,
  Icon,
  Field,
  Control,
} from 'Components/common/bulma'
import {
  Select,
  Output,
  Input,
} from 'Components/common/form'

import {
  getPaises,
  getEntidades,
  getRealPaymentMethod,
  getPaymentEstatus,
  getTipoPersona,
  getProviderInvoiceStatus,
} from 'Modules/catalogos'

import {
  getProviders,
  clearProvidersList,
} from 'Modules/catalogs/providers'
import {
  getCollaborators,
  getCollaborator,
  getAccounts,
  resetCollaborator,
  endEditCollaborator,
} from 'Modules/catalogs/collaborators'

import Totals from 'Components/paymentOrders/refund/Totals'
import Concept from 'Components/paymentOrders/refund/Concept'
import { uploadInvoice, cleanOrigin } from 'Modules/paymentOrders/providers'
import { v4 as uuid } from 'uuid'
import { addNotification } from 'Modules/principal'
import { add, prop, reduce, reject, propEq, find, equals } from 'ramda'
import {
  faTrash,
  faEdit,
  faKey,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  createOrder,
  getOrder,
  endOrderEdit,
  updateOrder,
  deleteOrder,
  addInstallment,
  getInstallments,
  deleteInstallment,
  authorizeInstallment,
} from 'Modules/paymentOrders/refund'
import Installment from 'Components/paymentOrders/refund/Installment'

import { Authorize, Can } from 'Containers/auth';
import { push } from 'connected-react-router'
import Attachments from 'Containers/paymentOrders/attachments'
import {
  getAttachments,
  endOrderEdit as endOrderEditProviders,
} from 'Modules/paymentOrders/providers'
import Autosuggest from 'react-autosuggest'


import moment from 'moment'

const AUTHORIZE_PERMISSION = ['Apago de proveedores']

const STATUS_CREATED = '1'
const STATUS_CANCELED = '3'
const STATUS_REFUSED = '4'
const STATUS_PARTIAL_AUTHORIZED = '9'

const renderSuggestion = suggestion => (
  <div>
    {suggestion.nombreConcatenado}
  </div>
)

export class Form extends Component {
  state = {
    addressData,
    order,
    collaborator: undefined,
    totals,
    isAddingConcept: false,
    conceptos: {
      data: [],
    },
    showErrors: false,
    paymentData,
    isRemovingInstallment: false,
    collaborators: [],
    value: '',
  }

  componentDidMount() {
    const {
      getPaises,
      getEntidades,
      getRealPaymentMethod,
      getPaymentEstatus,
      getTipoPersona,
      getProviderInvoiceStatus,
      getCollaborators,
      getAttachments,
      getInstallments,
    } = this.props

    getPaises()
    getEntidades()
    getRealPaymentMethod()
    getPaymentEstatus()
    getTipoPersona()
    getProviderInvoiceStatus()
    getCollaborators({ size: 5000 })

    const id = this.props.match.params.id
    if (id && (id !== this.state.order)) {
      this.props.getOrder(id)
      getInstallments(id)
      getAttachments(id)
    }
  }

  getSuggestionValue = suggestion => {
    this.onChangeInput({
      target: {
        name: 'idColaborador',
        value: suggestion.id,
      }
    }, 'order')
    return suggestion.nombreConcatenado;
  }

  getSuggestions = value => {
    if (!value) {
      return []
    }
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : this.props.collaborators.data.filter(col => {
      return col.nombreConcatenado.toLowerCase().indexOf(inputValue) !== -1
    }
    );
  };

  onSuggestionSelected = event => {
    event.stopPropagation();
    event.preventDefault();
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      collaborators: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      collaborators: []
    });
  };

  componentWillUnmount() {
    const { cleanOrigin } = this.props;
    this.props.endOrderEdit()
    this.props.endEditCollaborator()
    this.props.endOrderEditProviders()
    cleanOrigin();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.collaborator.id && this.props.collaborator !== nextProps.collaborator) {
      this.setState({
        order: mapPropsToState(this.state.order, nextProps.collaborator),
        value: nextProps.collaborator.nombreConcatenado,
      })
    }

    if (nextProps.order.id && nextProps.order.id !== this.props.order.id) {
      const order = mapPropsToState(this.state.order, nextProps.order)
      const totals = mapPropsToState(this.state.totals, nextProps.order)

      this.props.getCollaborator(nextProps.order.idColaborador)
      this.props.getAccounts(nextProps.order.idColaborador)

      this.setState({
        order,
        totals,
        conceptos: {
          data: nextProps.order.conceptos
        }
      })
    }
  }

  getPermissions = () => {
    return {
      canDelete: true,
      canSave: true,
    }
  }

  onChangeInput = ({ target }, section) => {
    const { name } = target
    const data = getDataInput(target, this.state[section])
    const field = data[name]

    this.setState({
      [section]: data,
      showErrors: false,
      showAccountErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }

  onChangeCollaborator = () => {
    const { value } = this.state.order.idColaborador

    if (value) {
      this.props.getAccounts(value)
      this.props.getCollaborator(value)
    } else {
      this.onChangeInput({
        target: {
          name: 'cuentaClabeColaborador',
          value: '',
        }
      }, 'order')
      this.props.resetCollaborator()
    }
  }

  createOrder = cb => {
    const { data, hasErrors } = validateData(this.state.order)

    if (hasErrors || this.state.conceptos.data.length === 0) {
      this.setState({
        order: data,
        showErrors: true,
      })
      this.props.addNotification({
        type: 'danger',
        message: '¡Favor de validar algunos campos y que tengas conceptos!'
      })
      return false
    }
    const orderData = getValues(this.state.order)
    const totalData = getValues(this.state.totals)
    const id = this.props.order.id

    if (id) {
      this.props.updateOrder({ id, ...orderData, ...totalData, conceptos: this.state.conceptos.data })
        .then(data => {
          this.props.addNotification({
            type: 'success',
            message: '¡La orden se actualizó correctamente!'
          })

          window.location.reload()

        })
        .catch(({ response: { data } }) => {
          this.props.addNotification({
            type: 'danger',
            message: data.message
          })
        })
    } else {
      this.props.createOrder({ ...orderData, ...totalData, conceptos: this.state.conceptos.data })
        .then(({ id }) => {
          this.props.addNotification({
            type: 'success',
            message: '¡Orden creada exitosamente!'
          })
          this.props.push(`/ordenes-pago/reembolsos/${id}`);
          cb && cb();
        })
        .catch(({ response: { data } }) => {
          this.props.addNotification({
            type: 'danger',
            message: data.message
          })
        });
    }
  }

  addingConcept = () => {
    this.setState({
      isAddingConcept: true,
    })
  }

  updateTotals = () => {
    const totals = reduce((acc, cur) => {
      return {
        total: add(prop('total')(acc), prop('total')(cur)),
        subtotal: add(prop('subtotal')(acc), prop('subtotal')(cur)),
        IVA: add(prop('IVA')(acc), prop('iva')(cur)),
        ieps: add(prop('ieps')(acc), prop('ieps')(cur)),
        netoDepositar: add(prop('netoDepositar')(acc), prop('netoDepositar')(cur)),
        ISRRetenido: add(prop('netoDepositar')(acc), prop('isrRetenido')(cur)),
        IVARetenido: add(prop('netoDepositar')(acc), prop('ivaRetenido')(cur)),
      }
    },
      {
        total: 0,
        subtotal: 0,
        IVA: 0,
        ieps: 0,
        netoDepositar: 0,
        ISRRetenido: 0,
        IVARetenido: 0,
      }
    )(this.state.conceptos.data)
    this.setState(({ totals: totalsState }) => ({ totals: mapPropsToState(totalsState, { ...totals, montoPagar: totals.total }) }))
  }

  addConcept = concept => {
    const conceptos = this.state.conceptos;
    conceptos.data.push({ ...concept, id: uuid() })
    this.setState({
      conceptos
    }, () => {
      this.closeConceptModal()
      this.updateTotals()
      if (this.props.order.id) {
        this.props.getAttachments(this.props.order.id)
      }
    })
  }

  closeConceptModal = () => {
    this.setState({
      isAddingConcept: false
    })
  }

  customColActions = (row, idx) => (
    <Button danger outlined
      onClick={() => this.openConfirmRemoveConceptModal(row)}
      disabled={this.props.order.estatusOrden !== STATUS_CREATED}
    >
      <Icon>
        <FontAwesomeIcon icon={faTrash} />
      </Icon>
    </Button>
  )

  installmentActions = (row, idx) => (
    <div>
      <Button outlined
        primary
        onClick={() => this.openInstallmentModal(row)}>
        <Icon>
          <FontAwesomeIcon icon={faEdit} />
        </Icon>
      </Button>
      <Can validate={AUTHORIZE_PERMISSION}>
        <Button danger outlined className="margin-left-xs"
          disabled={!(row.estatus === STATUS_CREATED || row.estatus === STATUS_PARTIAL_AUTHORIZED)}
          onClick={() => this.onAuthorize(row)}>
          <Icon>
            <FontAwesomeIcon icon={faKey} />
          </Icon>
        </Button>
      </Can>
      <Button danger outlined className="margin-left-xs"
        disabled={(row.estatus !== STATUS_CREATED)}
        onClick={() => this.openConfirmRemoveInstallment(row)}>
        <Icon>
          <FontAwesomeIcon icon={faTrash} />
        </Icon>
      </Button>
    </div>
  )

  onCancel = () => {
    const { push, origin } = this.props
    const isFromPending = equals('pending')(origin)
    const isFromPendingToPay = equals('pendingToPay')
    const isFromPendingInvoice = equals('pendingInvoice')
    const isFromRejectedInvoice = equals('rejectedInvoice')

    if (isFromPending) {
      push('/ordenes-pago/pendientes')
      return
    }
    if (isFromPendingToPay(origin)) {
      push('/ordenes-pago/pendientes-pago')
      return
    }

    if (isFromPendingInvoice(origin)) {
      push('/ordenes-pago/proveedores/facturas-pendientes')
      return
    }

    if (isFromRejectedInvoice(origin)) {
      push('/ordenes-pago/facturas-rechazadas')
      return
    }
    push('/ordenes-pago/reembolsos')
  }

  openConfirmRemoveConceptModal = (conceptRemoving) => this.setState({
    conceptRemoving,
    isRemovingConcept: true
  })

  removeConcept = () => {
    const { conceptos } = this.state
    if (this.state.conceptRemoving.id) {
      conceptos.data = reject(propEq('id', this.state.conceptRemoving.id))(conceptos.data)
    } else {
      conceptos.data = reject(propEq('idConcepto', this.state.conceptRemoving.idConcepto))(conceptos.data)
    }
    this.setState({
      conceptos,
      isRemovingConcept: false,
    }, this.updateTotals)
  }

  removeOrder = () => {
    this.props.deleteOrder(this.props.order.id)
      .then((response) => {
        this.props.addNotification({
          type: 'success',
          message: 'La órden de pago se eliminó correctamente'
        })
        this.setState({
          isRemovingOrder: false,
        })
        this.setState({
          order: mapPropsToState(this.state.order, { estatusOrden: response.estatusOrden })
        })
      })
      .catch(({ response: { data } }) => {
        this.props.addNotification({
          type: 'danger',
          message: data.message
        })
      })
  }

  closeConfirmRemoveOrder = () => this.setState({
    isRemovingOrder: false
  })

  openConfirmRemoveOrder = () => this.setState({
    isRemovingOrder: true,
  })

  closeConfirmRemoveConceptModal = () => this.setState({
    conceptRemoving: undefined,
    isRemovingConcept: false
  })

  openInstallmentModal = ({ id, estatus, estatusTexto, cargo, fechaTransaccion } = {}) => {
    const getValue = prop('value')
    const getId = prop('id')
    const limitHour = moment({ hour: 16, minute: 5 })
    const minDate = moment() < limitHour ? moment({ hour: 0 }) : moment({ hour: 0 }).add(1, 'day')
    const method = find(propEq('id', this.props.order.metodoPago))(this.props.realPaymentMethod)
    const clabe = find(propEq('id', this.props.order.cuentaClabeColaborador))(this.props.collaboratorAccounts.data)

    if (this.props.order.id) {
      this.setState({
        isAddingInstallment: true,
        installment: id,
        orderData: {
          id,
          divisaMoneda: getValue(this.state.paymentData.divisaMoneda),
          status: estatus,
          estatus: estatusTexto,
          cargo: id ? cargo : getValue(this.state.totals.montoPagar),
          idOrden: getId(this.props.order),
          referencia: getValue(this.state.paymentData.referencia),
          fechaTransaccion: id ? new Date(fechaTransaccion) : minDate.toDate(),
          metodoPago: prop('metodo')(method),
          integrado: this.props.partner.idIntegrado,
          cuentaMxn: prop('clabe')(clabe)
        }
      })
    } else {
      this.createOrder(undefined, () => {
        this.setState({
          isAddingInstallment: true,
          installment: id,
          orderData: {
            id,
            divisaMoneda: getValue(this.state.paymentData.divisaMoneda),
            status: estatus,
            estatus: estatusTexto,
            cargo: id ? cargo : getValue(this.state.totals.montoPagar),
            idOrden: getId(this.props.order),
            referencia: getValue(this.state.paymentData.referencia),
            fechaTransaccion: id ? new Date(fechaTransaccion) : minDate.toDate(),
            metodoPago: prop('metodo')(method),
            integrado: this.props.partner.idIntegrado,
            cuentaMxn: prop('clabe')(clabe)
          }
        })
      })
    }
  }

  closeInstallmentModal = () => this.setState({
    isAddingInstallment: false,
    installment: undefined
  })

  openConfirmRemoveInstallment = ({ id }) => this.setState({
    isRemovingInstallment: true,
    installment: id,
  })

  closeConfirmRemoveInstallment = () => this.setState({
    isRemovingInstallment: false,
  })

  addInstallment = installment => {
    this.props.addInstallment(this.props.order.id, {
      ...installment,
      fechaTransaccion: moment(installment.fechaTransaccion).format('YYYY-MM-DD')
    })
      .then(() => {
        this.closeInstallmentModal();
        this.props.addNotification({
          type: 'success',
          message: '¡El pago se agregó correctamente!'
        })
      })
      .catch(({ response: { data } }) => {
        this.props.addNotification({
          type: 'danger',
          message: data.message
        })
      })
  }

  onAuthorize = ({ id }) => {
    this.setState({
      isAddingInstallment: false,
      showAuth: true,
      installment: id,
    })
  }

  cancelAuthorize = () => {
    this.setState({
      showAuth: false,
    })
  }

  authorizeInstallment = props => {
    return this.props.authorizeInstallment({
      idOrder: this.state.order.id,
      idInstallment: this.state.installment,
      ...props,
    }).then(data => {
      this.props.addNotification({
        type: 'success',
        message: 'El pago se autorizó correctamente'
      })
      this.setState({
        showAuth: false,
        totals: mapPropsToState(this.state.totals, data),
      })
    })

  }

  removeInstallment = () => {
    this.props.deleteInstallment(this.props.order.id, this.state.installment)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'El pago se eliminó correctamente'
        })
        this.setState({
          isRemovingInstallment: false,
        })
      })
      .catch(({ response: { data } }) => {
        this.props.addNotification({
          type: 'danger',
          message: data.message
        })
      })
  }

  authorizeInstallment = props => {
    return this.props.authorizeInstallment({
      idOrder: this.props.order.id,
      idInstallment: this.state.installment,
      ...props,
    }).then(data => {
      this.props.addNotification({
        type: 'success',
        message: 'El pago se autorizó correctamente'
      })
      this.setState({
        showAuth: false,
        totals: mapPropsToState(this.state.totals, data),
        order: mapPropsToState(this.state.order, data),
      })
    })
  }

  onChangeUnit = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    })
    if (method === 'type') {
      this.onChangeInput({
        target: {
          name: 'idColaborador',
          value: '',
        }
      }, 'order')
    }
  }

  render() {
    const { collaborators } = this.state
    const {
      realPaymentMethod,
      collaboratorAccounts,
      paymentEstatus,
      invoiceProviderStatus,
      providers,
      uploadInvoice,
      addNotification,
    } = this.props

    const {
      metodoPago,
      cuentaRetiro,
      referencia,
      divisaMoneda,
      cuentaClabeColaborador,
      estatusOrden,
      idColaborador,

    } = this.state.order
    return (
      <Box>
        <ModalRemove
          title="Eliminar pago"
          isDeleting={this.state.isRemovingInstallment}
          confirmText="¿Esta seguro de querer eliminar el pago?"
          toggleModal={this.closeConfirmRemoveInstallment}
          resource={this.state.installment}
          deleteResource={this.removeInstallment}
        />
        {this.state.showAuth && (<Authorize
          isOpen={this.state.showAuth}
          authorize={this.authorizeInstallment}
          cancel={this.cancelAuthorize}
        />)}
        <Modal isActive={this.state.isRemovingOrder}>
          <Message danger>
            <Message.Header>
              <p>Eliminar Orden de pago</p>
              <Delete onClick={this.closeConfirmRemoveOrder} />
            </Message.Header>
            <Message.Body className="has-text-centered">
              ¿Esta seguro de querer eliminar la orden de pago?
              <div className="margin-top-lg">
                <Button danger onClick={this.removeOrder}>Eliminar</Button>
                <Button default className="margin-left-sm" onClick={this.closeConfirmRemoveOrder}>
                  Cancelar
                </Button>
              </div>
            </Message.Body>
          </Message>
        </Modal>
        <Modal isActive={this.state.isRemovingConcept}>
          <Message danger>
            <Message.Header>
              <p>Eliminar Concepto</p>
              <Delete onClick={this.closeConfirmRemoveConceptModal} />
            </Message.Header>
            <Message.Body className="has-text-centered">
              ¿Esta seguro de querer eliminar el concepto?
              <div className="margin-top-lg">
                <Button danger onClick={this.removeConcept}>Eliminar</Button>
                <Button default className="margin-left-sm" onClick={this.closeConfirmRemoveConceptModal}>
                  Cancelar
                </Button>
              </div>
            </Message.Body>
          </Message>
        </Modal>
        <ActionBar
          permissions={this.getPermissions()}
          onSave={() => this.createOrder()}
          onDelete={this.openConfirmRemoveOrder}
          onCancel={this.onCancel}
          onAuthorize={this.onAuthorize}
          basicRole={['Opago de proveedores']}
          authorizeRole={['Apago de proveedores']}
        />
        <Columns className="is-multiline">
          <Column className="is-half">
          </Column>
          <Column className="is-half">
            <Select
              options={paymentEstatus}
              {...estatusOrden}
              onChange={e => this.onChangeInput(e, 'order')}
              showErrors={this.state.showErrors}
            />
          </Column>
          <Column className="is-half">
          </Column>
          <Column>
            <Field>
              <label className="label">{idColaborador.label}</label>
              <Control>
                <Autosuggest
                  suggestions={collaborators}
                  inputProps={{
                    value: this.state.value,
                    onChange: this.onChangeUnit,
                    className: 'input',
                    placeholder: idColaborador.placeholder,
                  }}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  getSuggestionValue={this.getSuggestionValue}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  renderSuggestion={renderSuggestion}
                  onSuggestionSelected={this.onSuggestionSelected}
                />
              </Control>
              {(idColaborador.errors && !!idColaborador.errors.length && this.state.showErrors) &&
                <p className="has-text-danger help">{idColaborador.errors[0].message}</p>}
            </Field>
          </Column>
          <Column className="is-half">
          </Column>
        </Columns>
        <Divider
          content="DATOS PARA PAGO"
        />
        <Columns className="is-multiline">
          <Column className="is-half">
            <Select
              options={realPaymentMethod}
              {...metodoPago}
              onChange={e => this.onChangeInput(e, 'order')}
              showErrors={this.state.showErrors}
            />
          </Column>
          <Column className="is-half">
            <Output
              {...cuentaRetiro}
              onChange={e => this.onChangeInput(e, 'order')}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...referencia}
              onChange={e => this.onChangeInput(e, 'order')}
              showErrors={this.state.showErrors}
            />
          </Column>
          <Column className="is-half">
            <Output
              {...divisaMoneda}
              onChange={e => this.onChangeInput(e, 'order')}
            />
          </Column>
          <Column className="is-half">
            <Select
              options={collaboratorAccounts.data}
              {...cuentaClabeColaborador}
              onChange={e => this.onChangeInput(e, 'order')}
              showErrors={this.state.showErrors}
            />
          </Column>
        </Columns>
        <Divider content="CONCEPTOS PAGOS" />
        <Columns>
          <Column>
            <Button
              disabled={this.props.installments.data.length || estatusOrden.value === STATUS_CANCELED || estatusOrden.value === STATUS_REFUSED}
              style={{ float: 'right' }}
              onClick={this.addingConcept}
            >
              Agregar Concepto
            </Button>
          </Column>
        </Columns>
        <Modal isActive={this.state.isAddingConcept}>
          <Message default>
            <Message.Header>
              <p>Agregar concepto</p>
              <Delete onClick={this.closeConceptModal} />
            </Message.Header>
            <Message.Body>
              {
                this.state.isAddingConcept &&
                <Concept
                  providers={providers.data}
                  fetchProviders={this.props.getProviders}
                  resetProviders={this.props.resetProviders}
                  dataDef={conceptData}
                  uploadInvoice={uploadInvoice}
                  addConcept={this.addConcept}
                  addNotification={addNotification}
                  closeConceptModal={this.closeConceptModal}
                  partnerRfc={this.props.partner.rfc}
                />
              }
            </Message.Body>
          </Message>
        </Modal>
        <Table
          colsetup={conceptsCol}
          coldata={this.state.conceptos}
          tableClassName='table is-striped is-hoverable is-fullwidth'
          emptyTableMarkUp={(<span>Aun no hay conceptos</span>)}
          customCol={{
            customColName: 'acciones',
            renderFunc: this.customColActions
          }}
        />
        <Divider
          content="PAGOS"
        />
        <Columns>
          <Column >
            <Button
              disabled={this.state.totals.montoPagar.value < 1 || estatusOrden.value === STATUS_CANCELED || estatusOrden.value === STATUS_REFUSED}
              className="is-pulled-right" onClick={() => this.openInstallmentModal({})}>Agregar Pago</Button>
          </Column>
        </Columns>
        <Column>
          <Modal isActive={this.state.isAddingInstallment}>
            <Message>
              <Message.Header>
                Agregar pago
                <Delete onClick={this.closeInstallmentModal} />
              </Message.Header>
              <Message.Body>
                {this.state.isAddingInstallment && <Installment
                  orderData={this.state.orderData}
                  addNotification={addNotification}
                  addInstallment={this.addInstallment}
                  closeInstallmentModal={this.closeInstallmentModal}
                  paymentEstatus={paymentEstatus}
                  authorize={this.onAuthorize}
                  realPaymentMethod={realPaymentMethod}
                  installment={installment}
                />}
              </Message.Body>
            </Message>
          </Modal>
        </Column>
        <Columns>
          <Column>
            {<Table
              colsetup={paymentTable}
              coldata={this.props.installments}
              emptyTableMarkUp={(<span>Aun no hay pagos</span>)}
              customCol={{
                customColName: 'acciones',
                renderFunc: this.installmentActions
              }}
            />}
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Totals
              data={this.state.totals}
              providerInvoiceStatus={invoiceProviderStatus}
            />
          </Column>
        </Columns>
        <Attachments
          files={this.props.attachments}
        />
      </Box>
    )
  }
}

const mapStateToProps = ({ catalogos, catalogs, paymentOrders, principal }) => ({
  realPaymentMethod: catalogos.realPaymentMethod,
  collaboratorAccounts: catalogs.collaborators.accounts,
  collaborators: catalogs.collaborators.collaborators,
  paymentEstatus: catalogos.paymentEstatus,
  paises: catalogos.paises,
  entidades: catalogos.entidades,
  collaborator: catalogs.collaborators.collaborator,
  tipoPersona: catalogos.tipoPersona,
  providers: catalogs.providers.providers,
  order: paymentOrders.refund.order,
  installments: paymentOrders.refund.installments,
  attachments: paymentOrders.providers.attachments,
  partner: { ...principal.profile, ...principal.integrated },
  origin: paymentOrders.providers.origin,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getPaises,
  getEntidades,
  getRealPaymentMethod,
  getPaymentEstatus,
  getTipoPersona,
  getProviderInvoiceStatus,
  getCollaborators,
  getCollaborator,
  getAccounts,
  getProviders,
  uploadInvoice,
  addNotification,
  createOrder,
  push,
  getAttachments,
  getOrder,
  endOrderEdit,
  updateOrder,
  deleteOrder,
  addInstallment,
  getInstallments,
  deleteInstallment,
  authorizeInstallment,
  resetCollaborator,
  endEditCollaborator,
  endOrderEditProviders,
  resetProviders: clearProvidersList,
  cleanOrigin,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Form)
