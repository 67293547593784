import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { Box, Modal, Message, Delete, Columns, Column } from 'Components/common/bulma'
import Table from 'Components/common/table'
import {
  getList,
  setAdvance,
} from 'Modules/incomeOrders/advancePayment'
import { advancePaymentList } from './data'
import { Button } from 'Components/common/bulma'
import { addNotification } from 'Modules/principal'


export class ListaContainer extends Component {
  state = {
    showModal: false,
    advance: {},
  }

  onEdit = (id) => {
    const { push } = this.props;
    push(`/catalogos/monederos-gasolina/${id}`);
  }

  createInvoice = advance => {
    const { idOrden } = advance

    if (idOrden) {
      this.setState(state => ({
        showModal: true,
        advance,
      }))
      return
    }
    this.props.setAdvance(advance)
    this.props.push('/ordenes-ingreso/facturas/nuevo')
  }

  closeModal = () => {
    this.setState({
      showModal: false
    })
  }

  customColAccions = advance => (
    <Fragment >
      <Button small className="margin-left-xs" primary outlined onClick={() => this.createInvoice(advance)}>
        Emitir Factura
      </Button>
    </Fragment>
  )

  render() {
    const { list } = this.props

    return (
      <Box>
        <Modal isActive={this.state.showModal}>
          <Message primary>
            <Message.Header>
              <p>No se puede crear factura</p>
              <Delete onClick={this.closeModal} />
            </Message.Header>
            <Message.Body className="has-text-centered">
              El anticipo <strong>{this.state.advance.id}</strong> ya tiene una orden relacionada con una factura.
              <div className="margin-top-lg">
                <Button primary className="margin-left-sm" onClick={this.closeModal}>
                  Aceptar
              </Button>
              </div>
            </Message.Body>
          </Message>
        </Modal>
        <Columns>
          <Column>
            <Table
              filterable
              colsetup={advancePaymentList}
              coldata={list}
              className='is-striped is-hoverable is-size-7'
              emptyTableMarkUp={<span>No se encontraron registros</span>}
              customCol={{
                customColName: 'actions',
                renderFunc: this.customColAccions
              }}
              refresh={this.props.getList}
            />
          </Column>
        </Columns>
      </Box>
    )
  }
}

const mapStateToProps = ({ incomeOrders }) => ({
  list: incomeOrders.advancePayment.list,
  isFetching: incomeOrders.advancePayment.isFetchingAdvancedPayment
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getList,
  push,
  addNotification,
  setAdvance,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ListaContainer)
