import React, { Fragment } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Menu from 'Containers/common/menu'
import Principal from 'Containers/common/principal'
import { CambiarContrasena } from 'Containers/auth'
import { Columns, Column } from 'Components/common/bulma'

export const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  changePassword,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {

      if (!isAuthenticated) {
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }

      return (<Fragment>
        <Principal />
        {isAuthenticated && changePassword === false && (
          <Columns>
            <Column className="is-2">
              <Menu location={props.location} />
            </Column>
            <Column className="is-10">
              <Component {...props} >
              </Component>
            </Column>
          </Columns>
        )}
        {changePassword && (
          <Fragment>
            <h5 className="title is-6 has-text-centered margin-top-lg">Para empezar a utilizar el sistema es necesario cambiar su contraseña</h5>
            <CambiarContrasena />
          </Fragment>
        )}

      </Fragment>)
    }
    }
  />
)

const mapStateToProps = ({ auth, principal }) => ({
  isAuthenticated: auth.authenticated,
  changePassword: principal.changePassword,
})

export default connect(mapStateToProps, undefined)(PrivateRoute)
