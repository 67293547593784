export const pendingPaymentOrders =
  [
    {
      title: 'Id',
      datakey: 'id',
      width: '5%',
    },
    {
      title: 'Tipo de Pago',
      datakey: 'tipoPago',
      width: '10%',
    },
    {
      title: 'Estatus',
      datakey: 'estatus',
      width: '10%',
    },
    {
      title: 'Nombre o Razón Social',
      datakey: 'nombreRazonSocial',
      width: '10%',
    },
    {
      title: 'Monto a Pagar',
      datakey: 'montoPorPagar',
      format: '$ 0,0.00',
      width: '10%',
    },
    {
      title: 'Acciones',
      custom: 'actions',
      width: '5%',
      align: 'center',
    }
  ];

export const rejectedInvoicePaymentOrders =
  [
    {
      title: 'Id',
      datakey: 'id',
      width: '5%',
    },
    {
      title: 'Tipo de Pago',
      datakey: 'tipoPago',
      width: '10%',
    },
    {
      title: 'Estatus',
      datakey: 'estatus',
      width: '10%',
    },
    {
      title: 'Nombre o Razón Social',
      datakey: 'nombreRazonSocial',
      width: '10%',
    },
    {
      title: 'Total',
      datakey: 'total',
      format: '$ 0,0.00',
      width: '10%',
    },
    {
      title: 'Acciones',
      custom: 'actions',
      width: '5%',
      align: 'center',
    }
  ];

export const pendingInvoicePaymentOrders =
  [
    {
      title: 'Id',
      datakey: 'id',
      width: '5%',
    },
    {
      title: 'Tipo de Pago',
      datakey: 'tipoPago',
      width: '10%',
    },
    {
      title: 'Estatus',
      datakey: 'estatus',
      width: '10%',
    },
    {
      title: 'Nombre o Razón Social',
      datakey: 'nombreRazonSocial',
      width: '10%',
    },
    {
      title: 'Total',
      datakey: 'total',
      format: '$ 0,0.00',
      width: '10%',
    },
    {
      title: 'Acciones',
      custom: 'actions',
      width: '5%',
      align: 'center',
    }
  ];

export const pendingToPayPaymentOrders =
  [
    {
      title: 'Id',
      datakey: 'id',
      width: '5%',
    },
    {
      title: 'Tipo de Pago',
      datakey: 'tipoPago',
      width: '10%',
    },
    {
      title: 'Estatus',
      datakey: 'estatus',
      width: '10%',
    },
    {
      title: 'Nombre o Razón Social',
      datakey: 'nombreRazonSocial',
      width: '10%',
    },
    {
      title: 'Total',
      datakey: 'total',
      format: '$ 0,0.00',
      width: '10%',
    },
    {
      title: 'Acciones',
      custom: 'actions',
      width: '5%',
      align: 'center',
    }
  ];