import axios from 'axios'
import { getHeaders } from 'Modules/util'
import { map, assoc, prop, converge, concat, identity, compose} from 'ramda'

const url = process.env.REACT_APP_API

export const GET_LIST = 'incomeOrders/advance/GET_LIST'
export const SET_ADVANCE = 'incomerOrders/advance/SET_ADVANCE'
export const RESET_ADVANCE = 'incomerOrders/advance/RESET_ADVANCE'
export const IS_FETCHING_ADVANCED_PAYMENTS = 'incomerOrders/advance/IS_FETCHING_PROVIDERS'
export const IS_FETCHING_ADVANCED_PAYMENTS_COMPLETE = 'incomerOrders/advance/IS_FETCHING_PROVIDERS_COMPLETE'

const initialState = {
  list: {
    data: [],
  },
  order: {},
  relatedUiid: [],
  installments: {
    data: [],
  },
  complementaryInstallments: {
    data: [],
  },
  advance: undefined,
  isFetchingAdvancedPayment: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_ADVANCE:
    case SET_ADVANCE:
    case GET_LIST:
      return { ...state, ...action.payload }
    case IS_FETCHING_ADVANCED_PAYMENTS:
       return { ...state, isFetchingAdvancedPayment: true }
    case IS_FETCHING_ADVANCED_PAYMENTS_COMPLETE:
       return { ...state, isFetchingAdvancedPayment: false }
    default:
      return state
  }
}

const concatFolio = converge(concat, [prop('serie'), prop('folio')])
const getConcatedFolio = map(converge(assoc('serieFolio'), [concatFolio, identity]))

export const isFetchingAdvancedPayment = () => ({
  type: IS_FETCHING_ADVANCED_PAYMENTS
})

export const isFetchingAdvancedPaymentComplete = () => ({
  type: IS_FETCHING_ADVANCED_PAYMENTS_COMPLETE
})

export const getList = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingAdvancedPayment())
    return axios.post(`${url}/advance-payment`, params, { headers })
      .then(({ data }) => {
        dispatch(isFetchingAdvancedPaymentComplete())
        dispatch({
          type: GET_LIST,
          payload: {list: {...data, data:  compose(getConcatedFolio, prop('data'))(data)}},
        })
      })
  }
}

export const setAdvance = advance => ({
  type: SET_ADVANCE,
  payload: {advance}
})

export const resetAdvance = () => ({
  type: RESET_ADVANCE,
  payload: {advance: undefined}
})