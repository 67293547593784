import { cond, always, T, F, anyPass, pipe, equals, path } from 'ramda';
import { isEmpty } from 'App/helpers'
import { isNumeric, isNilOrEmptyString } from 'ramda-extension'
import { toNumber } from 'ramda-adjunct';

const toCheck = pipe(toNumber, equals(1))

export const fields = {
  certificateNumber: {
    input: {
      value: '',
      name: 'certificateNumber',
    },
    label: 'Número de Acta o Instrumento',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  certificateDate: {
    input: {
      value: '',
      name: 'certificateDate',
    },
    label: 'Fecha de Instrumento',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  certificateNotaryName: {
    input: {
      value: '',
      name: 'certificateNotaryName',
    },
    label: 'Nombre del Notario o Corredor Público',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  certificateNotaryNumber: {
    input: {
      value: '',
      name: 'certificateNotaryNumber',
    },
    label: 'Número de Notaría o Correduría',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  certificateNotaryState: {
    input: {
      value: '',
      name: 'certificateNotaryState',
    },
    label: 'Ciudad o Estado radica la Notaría o Correduría',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  publicRegistryProcess: {
    input: {
      value: false,
      name: 'publicRegistryProcess',
    },
    label: 'Registro Público en Trámite',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
    unmarshaller: toCheck,
  },
  publicRegistryNumber: {
    input: {
      value: '',
      name: 'publicRegistryNumber',
    },
    label: 'Número Registro público',
    validate: (a, b, c) => {
      const isPublicRegistryInProcess = path(['publicRegistryProcess', 'input', 'value'], c)

      if (isPublicRegistryInProcess) {
        return F(a)
      } else {
        return cond([
          [isEmpty, always({ error: 'Este campo es requerido' })],
          [T, F]
        ])(a)
      }
    }
  },
  publicRegistryDate: {
    input: {
      value: '',
      name: 'publicRegistryDate',
    },
    label: 'Fecha de Registro público',
    validate: (a, b, c) => {
      const isPublicRegistryInProcess = path(['publicRegistryProcess', 'input', 'value'], c)

      if (isPublicRegistryInProcess) {
        return F(a)
      } else {
        return cond([
          [isEmpty, always({ error: 'Este campo es requerido' })],
          [T, F]
        ])(a)
      }
    }
  },
  publicRegistryState: {
    input: {
      value: '',
      name: 'publicRegistryState',
    },
    label: 'Entidad Federativa donde se realizó el registro.',
    validate: (a, b, c) => {
      const isPublicRegistryInProcess = path(['publicRegistryProcess', 'input', 'value'], c)

      if (isPublicRegistryInProcess) {
        return F(a)
      } else {
        return cond([
          [isEmpty, always({ error: 'Este campo es requerido' })],
          [T, F]
        ])(a)
      }
    }
  },
  shareholderFirst: {
    input: {
      value: '',
      name: 'shareholderFirst',
    },
    label: 'Principal Accionista',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  shareholderSecond: {
    input: {
      value: '',
      name: 'shareholderSecond',
    },
    label: 'Segundo Accionista',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  capital: {
    input: {
      value: '',
      name: 'capital',
    },
    label: 'Capital social en Pesos Mexicanos.',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
    pattern: anyPass([isNumeric, isNilOrEmptyString]),
  },
  employeeNumber: {
    input: {
      value: '',
      name: 'employeeNumber',
    },
    label: 'Número de Empleados',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
    pattern: anyPass([isNumeric, isNilOrEmptyString]),
  },
  taxSituation: {
    input: {
      value: '',
      name: 'taxSituation',
      accept: 'application/pdf',
    },
    label: 'Constancia de Situación Fiscal',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  addressProof: {
    input: {
      value: '',
      name: 'addressProof',
      accept: 'application/pdf',
    },
    label: 'Comprobante de Domicilio',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  constitutiveAct: {
    input: {
      value: '',
      name: 'constitutiveAct',
      accept: 'application/pdf',
    },
    label: 'Acta Constitutiva',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  publicRegistry: {
    input: {
      value: '',
      name: 'publicRegistry',
      accept: 'application/pdf',
    },
    label: 'Registro Publico de la Propiedad o Comercio',
  },
};
