import numeral from 'numeral'
import moment from 'moment'


const limitHour = moment({ hour: 16, minute: 5 })
const minDate = moment() < limitHour ? moment({ hour: 0 }) : moment({ hour: 0 }).add(1, 'day')

export const order = {
  idColaborador: {
    name: 'idColaborador',
    label: 'Colaborador',
    value: '',
    valueProperty: 'id',
    valueLabel: 'nombreConcatenado',
    trigger: 'onChangeCollaborator',
    validation: 'required',
    placeholder: 'Buscar colaborador...'
  },
  estatusOrden: {
    name: 'estatusOrden',
    label: 'Estatus order',
    disabled: true,
    value: '1',
    valueProperty: 'id',
    valueLabel: 'estatus',
  },
  metodoPago: {
    name: 'metodoPago',
    label: 'Método de pago',
    valueProperty: 'id',
    valueLabel: 'metodo',
    disabled: true,
    value: '1',
  },
  divisaMoneda: {
    value: 'MXN',
    name: 'divisaMoneda',
    label: 'Divisa Moneda',
  },
  referencia: {
    name: 'referencia',
    label: 'Referencia',
    validation: 'required',
    value: moment().format('DDMMYY'),
    maxLength: '7',
    pattern: 'number',
  },
  cuentaRetiro: {
    value: 'MXN',
    name: 'cuentaRetiro',
    label: 'Cuenta de Retiro',
  },
  tipoCambio: {
    name: 'tipoCambio',
    label: 'Tipo de Cambio',
  },
  cuentaClabeColaborador: {
    valueProperty: 'id',
    valueLabel: 'clabe',
    name: 'cuentaClabeColaborador',
    label: 'Cuenta Clabe Colaborador',
    validation: 'required',
    value: '',
  },
  tipoDePersona: {
    value: '2',
    name: 'tipoDePersona',
    label: 'Tipo de Persona',
    disabled: true,
    valueProperty: 'id',
    valueLabel: 'tipoPersona',
  },
  nombreORazonSocial: {
    value: '',
    name: 'nombreORazonSocial',
    label: 'Nombre o razón social',
    disabled: true,
  },
  rfc: {
    value: '',
    name: 'rfc',
    label: 'RFC',
    disabled: true,
  },
  correoElectronico: {
    value: '',
    name: 'correoElectronico',
    label: 'Correo Electrónico',
    disabled: true,
  }
}

export const refundCols =
  [
    {
      title: 'ID',
      datakey: 'id',
      sortable: true,
      width: '10%',
    },
    {
      title: 'Estatus Orden',
      datakey: 'estatus',
      sortable: true,
      width: '20%',
    },
    {
      title: 'Nombre o Razón Social',
      datakey: 'nombreRazonSocial',
      sortable: true,
      width: '40%',
    },
    {
      title: 'Total',
      datakey: 'Total',
      format: '$ 0,0.00',
      sortable: true,
      width: '20%',
    },
    {
      title: 'Acciones',
      custom: 'actions',
      width: '10%',
    }
  ];


export const addressData = {
  direccion: {
    value: '',
    transform: 'stringToBool',
    trigger: 'onChangeDireccion',
    name: 'direccion',
    label: 'Dirección',
    disabled: true,
  },
  pais: {
    name: 'pais',
    value: '',
    validation: 'requiredIf:direccion',
    label: 'País',
    disabled: true
  },
  codigoPostal: {
    value: '',
    trigger: 'onChangeCp',
    pattern: 'number',
    name: 'codigoPostal',
    label: 'Código postal',
    disabled: true,
  },
  entidadFederativa: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'entidadFederativa',
    label: 'Entidad Federativa/Estado/Provincia',
    disabled: true,
  },
  municipio: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'municipio',
    label: 'Municipio',
    disabled: true,
  },
  colonia: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'colonia',
    label: 'Colonia',
    disabled: true,
  },
  calle: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'calle',
    label: 'Calle',
    disabled: true,
  },
  exterior: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'exterior',
    label: 'Exterior',
    disabled: true,
  },
  interior: {
    value: '',
    name: 'interior',
    label: 'Interior',
    disabled: true,
  },
}

export const totals = {
  subtotal: {
    name: 'subtotal',
    label: 'Subtotal',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  IVA: {
    name: 'IVA',
    label: 'IVA',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  ieps: {
    name: 'ieps',
    label: 'IEPS',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  montoPagado: {
    name: 'montoPagado',
    label: 'Monto Pagado',
    value: 0,
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  montoPagoTransito: {
    name: 'montoPagoTransito',
    label: 'Monto Pago Tránsito',
    value: 0,
    format: (value) => numeral(Math.round(+value * 100) / 100).format('$ 0,0.00'),
    align: 'right',
  },
  montoPagar: {
    name: 'montoPagar',
    label: 'Monto por Pagar',
    value: 0,
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  estatusFacturaProveedor: {
    name: 'estatusFactura',
    label: 'Estatus Factura Proveedor',
    valueProperty: 'id',
    valueLabel: 'estatus',
    disabled: true,
    value: '3',
  },
  total: {
    name: 'total',
    label: 'Total',
    value: 0,
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  ISRRetenido: {
    name: 'ISRRetenido',
    label: 'ISR Retenido',
    value: 0,
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  IVARetenido: {
    name: 'IVARetenido',
    label: 'IVA Retenido',
    value: 0,
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  netoDepositar: {
    name: 'netoDepositar',
    label: 'Neto a depositar',
    value: 0,
    format: (value) => numeral(value).format('$ 0,0.00', Math.floor),
    align: 'right',
  },
}

export const conceptData = {
  idProveedor: {
    name: 'idProveedor',
    label: 'Proveedor',
    value: '',
    validation: 'required',
    valueLabel: 'nombreORazonSocial',
    valueProperty: 'id',
    trigger: 'onChangeProvider',
    required: true,
  },
  facturaMx: {
    name: 'facturaMx',
    label: '¿Cuenta con Factura Mexicana?',
    value: false,
    trigger: 'onChangeFacturaMx',
  },
  razonSocial: {
    name: 'razonSocial',
    label: 'Nombre o Razón Social',
    value: '',
    validation: 'required',
    disabled: true,
  },
  rfc: {
    name: 'rfc',
    label: 'RFC',
    value: '',
    validation: 'required',
    disabled: true,
  },
  unitario: {
    name: 'unitario',
    label: 'Unitario',
    value: '',
    validation: 'required',
    pattern: 'number',
    disabled: true,
  },
  total: {
    name: 'total',
    label: 'Total',
    value: '',
    format: val => numeral(val).format('$ 0,0.00'),
    align: 'right',
    validation: 'required',
  },
  iva: {
    name: 'iva',
    label: 'Iva',
    value: '',
    trigger: 'updateCalculations',
    pattern: 'number',
    validation: 'required',
  },
  ieps: {
    name: 'ieps',
    label: 'IEPS',
    value: '',
    trigger: 'updateCalculations',
    pattern: 'number',
    validation: 'number',
    dataMarshaller: val => !val ? 0 : val,
  },
  subtotal: {
    name: 'subtotal',
    label: 'Subtotal',
    value: '',
    trigger: 'updateCalculations',
    pattern: 'number',
    validation: 'required',
  },
  fileNamePdf: {
    name: 'fileNamePdf',
    value: '',
    validation: 'upload',
    label: 'Seleccionar factura Pdf',
    accept: 'application/pdf',
  },
  fileNameXml: {
    label: 'Seleccionar factura Xml',
    name: 'fileNameXml',
    value: '',
    validation: 'requiredIf:facturaMx',
    accept: 'application/xml',
  },
  noFactura: {
    label: 'Número de factura',
    name: 'noFactura',
    value: '',
    validation: 'required',
  },
  uuid: {
    name: 'uuid',
    label: 'UUID',
    value: '',
    validation: '',
    disabled: true,
  },
  claveProdServ: {
    name: 'claveProdServ',
    label: 'Clave producto servicio',
    value: '',
  },
  isrRetenido: {
    name: 'isrRetenido',
    label: 'ISR Retenido',
    value: '',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  ivaRetenido: {
    name: 'ivaRetenido',
    label: 'IVA Retenido',
    value: '',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  netoDepositar: {
    name: 'netoDepositar',
    label: 'Neto a depositar',
    value: '',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
}

export const conceptsCol = [
  {
    title: 'Proveedor',
    datakey: 'razonSocial',
  },
  {
    title: 'RFC',
    datakey: 'rfc',
  },
  {
    title: 'Factura',
    datakey: 'noFactura',
  },
  {
    title: 'Subtotal',
    datakey: 'subtotal',
    format: '$ 0,0.00',
  },
  {
    title: 'IVA',
    datakey: 'iva',
    format: '$ 0,0.00',
  },
  {
    title: 'Total',
    datakey: 'total',
    format: '$ 0,0.00',
  },
  {
    title: 'Acciones',
    custom: 'acciones',
  }
]

export const paymentTable = [
  {
    title: 'id',
    datakey: 'id',
  },
  {
    title: 'Fecha Transacción',
    datakey: 'fechaTransaccion',
  },
  {
    title: 'Estatus',
    datakey: 'estatusTexto',
  },
  {
    title: 'Cargo',
    datakey: 'cargo',
  },
  {
    title: 'Clave Rastreo',
    datakey: 'claveRastreo',
  },
  {
    title: 'Referencia de Banco',
    datakey: 'referenciaBanco',
  },
  {
    title: 'Acciones',
    custom: 'acciones',
  }
]

export const paymentData = {
  metodoPago: {
    value: '',
    name: 'metodoPago',
    label: 'Método de pago',
    valueProperty: 'id',
    valueLabel: 'metodo',
    trigger: 'onChangePaymentMethod',
    transform: 'stringToNumber',
    validation: 'required',
  },
  divisaMoneda: {
    value: 'MXN',
    name: 'divisaMoneda',
    label: 'Moneda Abono Proveedor',
    options: [
      { id: 'MXN', label: 'MXN' },
      { id: 'USD', label: 'USD' }
    ],
    valueProperty: 'id',
    valueLabel: 'label',
    validation: 'required',
  },
  referencia: {
    value: moment().format('DDMMYY'),
    name: 'referencia',
    label: 'Referencia',
    maxLength: '7',
    pattern: 'number',
  },
  retirementAccount: {
    value: '',
    name: 'retirementAccount',
    label: 'Cuenta de retiro',
  },
  exchange: {
    name: 'exchange',
    label: 'Tipo de cambio',
    value: '1',
  },
  cuentaClabeColaborador: {
    value: '',
    name: 'cuentaClabeColaborador',
    label: 'Cuenta Clabe Colaborador',
  },
  cuentaRetiro: {
    value: '',
    name: 'cuentaRetiro',
    label: 'Cuenta Retiro',
    same: 'divisaMoneda'
  },
  fechaTransaccion: {
    name: 'fechaTransaccion',
    label: 'Fecha Transacción',
    value: ''
  }
}

export const installment = {
  divisaMoneda: {
    name: 'divisaMoneda',
    label: 'Moneda pago',
  },
  cargo: {
    label: 'Cargos',
    name: 'cargo',
    validation: 'required|greaterThan:0',
    pattern: 'number',
    value: 0,
    trigger: 'updateClabe',
    format: (value) => numeral(value).format('$ 0,0.00'),
  },
  fechaTransaccion: {
    label: 'Fecha de Transacción',
    name: 'fechaTransaccion',
    validation: `required|gtOrEqDate:${minDate.format('YYYY-MM-DD')}`,
    value: undefined,
  },
  estatus: {
    label: 'Estatus',
    name: 'estatus',
  },
  idOrden: {
    label: 'Orden Pago',
    name: 'idOrden',
  },
  referencia: {
    label: 'Referencia Banco',
    name: 'referencia',
  },
  metodoPago: {
    label: 'Metódo de pago',
    name: 'metodoPago',
  },
  cuentaMxn: {
    label: 'Cuenta MXN',
    name: 'cuentaMxn',
  },
  claveRastreo: {
    label: 'Clave de rastreo',
    name: 'claveRastreo',
  },
  typePaymentProvider: {
    label: 'Tipo de Pago',
    name: 'typePaymentProvider',
  },
  cuentaClabeColaborador: {
    value: '',
    name: 'cuentaClabeColaborador',
    label: 'Cuenta Clabe Colaborador',
  },
}