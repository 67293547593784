import axios from 'axios'
import { getHeaders } from './util'

const url = process.env.REACT_APP_API
export const GET_REPORT = 'reports/GET_REPORT'

const initialState = {
  reportData: {
    data: []
  }
}

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case GET_REPORT:
      return { ...state, ...payload }

    default:
      return state
  }
}


export const getReport = (report, filters, params) => {
  return dispatch => {
    return axios.post(`${url}/report/${report}`, {
      size: 15,
      sortType: '',
      dateStart: '',
      dateEnd: '',
      ...filters,
      ...params,

    }, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: GET_REPORT,
          payload: { reportData: data }
        })
        return data.data
      })
  }
}
export const downloadReport = (report, filters, page) => {
  return dispatch => {
    return axios.post(`${url}/report/${report}`, {
      size: 15,
      sortType: '',
      dateStart: '',
      dateEnd: '',
      ...filters,
      page,

    }, { headers: getHeaders() })
      .then(({ data }) => {
        return data.data
      })
  }
}
