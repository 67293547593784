import React, { Component } from 'react'
import { Box, Columns, Column, Control, Field, Button, Divider } from 'Components/common/bulma'
import { Input, Select, Output } from 'Components/common/form'
import Autosuggest from 'react-autosuggest'
import { conceptData } from 'Containers/incomeOrders/creditNote/dataPartial'
import { getDataInput, mapPropsToState, validateData, getValues } from 'App/helpers'
import { multiply, find, propEq, propOr } from 'ramda'

/* eslint-disable */
function debounce(a, b, c) { var d, e; return function () { function h() { d = null, c || (e = a.apply(f, g)) } var f = this, g = arguments; return clearTimeout(d), d = setTimeout(h, b), c && !d && (e = a.apply(f, g)), e } }
/* eslint-enable */

const renderSuggestion = suggestion => (
  <div>
    {suggestion.nombre}
  </div>
)
const renderSuggestionProduct = suggestion => (
  <div>
    {suggestion.concatenado}
  </div>
)

class Concept extends Component {
  state = {
    value: '',
    valueProduct: '',
    data: conceptData,
    showErrors: false,
    query: '',
  }

  componentDidMount() {
    this.props.getUnits()
    this.setState({
      data: mapPropsToState(this.state.data, this.props.concept),
      valueProduct: this.props.concept.claveProductoServicioSAT,
      value: this.props.concept.unidad,
    })
  }

  componentWillReceiveProps(nextProps) {
    let data;
    if (this.props.product !== nextProps.product) {
      const { claveProductoServicioSAT, unidad } = nextProps.product
      data = mapPropsToState(this.state.data, { ...nextProps.product, tasaIVA: nextProps.product.tasaIVA, tasaIEPS: nextProps.product.tasaIEPS })
      this.setState({ data, value: unidad || '' })
      this.props.getProductSat(claveProductoServicioSAT)
    }

    if (this.props.productSat !== nextProps.productSat) {
      const { concatenado } = nextProps.productSat
      this.setState({
        valueProduct: concatenado
      })
    }
  }

  getSuggestionValue = suggestion => {
    this.onChangeInput({
      target: {
        name: 'unidad',
        value: suggestion.nombre,
      }
    })
    return suggestion.nombre;
  }

  getSuggestionValueProduct = suggestion => {
    this.onChangeInput({
      target: {
        name: 'claveProductoServicioSAT',
        value: suggestion.id,
      }
    })
    return suggestion.concatenado;
  }

  getSuggestionValueFinder = suggestion => {
    console.log('suggestion: ', suggestion.id);
    this.onChangeInput({
      target: {
        name: 'idProductoServicio',
        value: suggestion.id,
      }
    })
    return `${propOr('', 'identificador')(suggestion)} - ${propOr('', 'concepto')(suggestion)}`;
  }

  onChangeInput = ({ target }) => {
    const { name } = target
    const data = getDataInput(target, this.state.data)
    console.log('data value: ', data);
    const field = data[name]

    this.setState({
      data,
      showErrors: false,
      showAccountErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }

  onChangeUnit = (event, { newValue, method }) => {

    this.setState({
      value: newValue
    })
    if (method === 'type') {
      this.onChangeInput({
        target: {
          name: 'unidad',
          value: '',
        }
      })
    }
  }
  onChangeClave = (event, { newValue, method }) => {

    this.setState({
      valueProduct: newValue
    })
    if (method === 'type') {
      this.onChangeInput({
        target: {
          name: 'claveProductoServicioSAT',
          value: '',
        }
      })
    }
  }

  onChangeQuery = (event, { newValue, method }) => {
    this.setState({
      query: newValue,
    })
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.props.getUnits(value)
  }

  onSuggestionsClearRequested = () => {
    this.props.clearUnits()
  }

  onSuggestionsClearFinder = () => {
    this.props.clearFinder()
  }

  onSuggestionsFetchRequestedProduct = debounce(({ value }) => {
    this.props.getProductService(value)
  }, 200)

  onSuggestionsFetchRequestedFinder = debounce(({ value }) => {
    this.props.findProducts(value)
  }, 200)

  onSuggestionsClearRequestedProduct = () => {
    this.props.clearProductService()
  }

  onSuggestionSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) {
    event.stopPropagation();
    event.preventDefault();
  }

  onSuggestionProductSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    this.props.getProduct(suggestion.id)
    event.stopPropagation();
    event.preventDefault();
  }

  getSubtotal = () => {
    const { cantidad: { value: qty }, unitario: { value: price } } = this.state.data
    return multiply(+qty, +price)
  }

  getIepsCalculado = (subtotal) => {
    const { tasaIEPS: { value: iepsId } } = this.state.data
    const { iepsRates } = this.props
    const ieps = find(propEq('id', iepsId))(iepsRates)

    if (!ieps) {
      return 0
    }
    return subtotal * ieps.IEPS
  }

  getIvaCalculado = (subtotal, iepsC) => {
    const { tasaIVA: { value: ivaId }, tipoCalculo: { value: calculo } } = this.state.data
    const { ivaRates } = this.props
    const iva = find(propEq('id', ivaId))(ivaRates)

    const amount = calculo === 1 ? subtotal : (subtotal + iepsC)

    if (!iva) {
      return 0
    }

    return multiply(amount, +iva.IVA)
  }
  getTotalCalculado = (subtotal, iepsC, ivaC) => {

    return subtotal + iepsC + ivaC
  }
  updateCalculations = () => {
    const subtotal = this.getSubtotal()
    const ieps = this.getIepsCalculado(subtotal)
    const iva = this.getIvaCalculado(subtotal, ieps)
    const total = this.getTotalCalculado(subtotal,
      ieps,
      iva,
    )
    const data = mapPropsToState(this.state.data, {
      subtotal,
      ieps,
      iva,
      total,
    })
    this.setState({ data })
  }

  addConcept = (addProduct) => {
    const { data, hasErrors } = validateData(this.state.data)
    console.log('data: ', data);
    if (hasErrors) {
      this.setState({
        data,
        showErrors: true,
      })
      this.props.addNotification({
        type: 'danger',
        message: '¡Favor de validar algunos campos!'
      })
      return
    }
    this.props.addConcept(getValues(this.state.data), addProduct)
  }
  render() {
    const { data, showErrors } = this.state
    const { units, ivaRates, iepsRates, closeConceptModal,
      productService } = this.props
    return (
      <Box>
        <Divider content="CONCEPTO" />
        <Columns>
          <Column className="is-one-quarter">
            <Input
              {...data.cantidad}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column>
            <Input
              {...data.concepto}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
        </Columns>
        <Columns>
          <Column className="is-half">
            <Input
              {...data.identificador}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Field>
              <label className="label">{data.unidad.label}</label>
              <Control>
                <Autosuggest
                  suggestions={units}
                  inputProps={{
                    value: this.state.value,
                    onChange: this.onChangeUnit,
                    className: 'input',
                    placeholder: data.unidad.placeholder,
                  }}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  getSuggestionValue={this.getSuggestionValue}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  renderSuggestion={renderSuggestion}
                  onSuggestionSelected={this.onSuggestionSelected}
                />
              </Control>
              {(data.unidad.errors && !!data.unidad.errors.length && this.state.showErrors) &&
                <p className="has-text-danger help">{data.unidad.errors[0].message}</p>}
            </Field>
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Field>
              <label className="label">{data.claveProductoServicioSAT.label}</label>
              <Control>
                <Autosuggest
                  suggestions={productService}
                  inputProps={{
                    value: this.state.valueProduct,
                    onChange: this.onChangeClave,
                    className: 'input',
                    placeholder: data.claveProductoServicioSAT.placeholder,
                  }}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequestedProduct}
                  getSuggestionValue={this.getSuggestionValueProduct}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedProduct}
                  renderSuggestion={renderSuggestionProduct}
                  onSuggestionSelected={this.onSuggestionSelected}
                />
              </Control>
              {(data.unidad.errors && !!data.unidad.errors.length && this.state.showErrors) &&
                <p className="has-text-danger help">{data.unidad.errors[0].message}</p>}
            </Field>
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Input
              {...data.unitario}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column>
            <Output
              {...data.subtotal}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
        </Columns>
        <Columns>
        </Columns>
        <Columns>
          <Column>
            <Select
              options={iepsRates}
              {...data.tasaIEPS}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column>
            <Output
              {...data.ieps}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Select
              options={ivaRates}
              {...data.tasaIVA}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column>
            <Output
              {...data.iva}
              onChange={this.onChangeInput}
            />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Output
              {...data.total}
              onChange={this.onChangeInput}
            />
          </Column>
          <Column>
            <Select
              hideEmpty={true}
              {...data.tipoCalculo}
              onChange={this.onChangeInput}
            />
          </Column>
        </Columns>
        <Columns>
          <Column className="has-text-right">
            <Button default className="margin-left-sm" onClick={() => this.addConcept()}>Actualizar</Button>
            <Button default className="margin-left-sm" onClick={closeConceptModal}>
              Cancelar
            </Button>
          </Column>
        </Columns>
      </Box>
    )

  }
}

export default Concept