import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { addNotification, getAccountsInfo, getNoInterestConfiguration } from 'Modules/principal'
import { push } from 'connected-react-router'
import {
  reject, propEq, reduce, add, prop, omit, clone, filter, equals, keys, join, forEachObjIndexed,
} from 'ramda'
import Concept from 'Components/incomeOrders/creditNote/ConceptPartial'
import Totals from 'Components/incomeOrders/creditNote/Totals'
import { AddressSection } from 'Components/incomeOrders/invoice/AddressSection'
import {
  generalData,
  addressData,
  conceptsCol,
  totals,
} from './dataPartial'
import {
  getDataInput, mapPropsToState, getValues, validateData,
} from 'App/helpers'
import {
  Box,
  SubTitle,
  Divider,
  Columns,
  Column,
  Button,
  Modal,
  Message,
  Delete,
  Icon,
} from 'Components/common/bulma'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEdit,
} from '@fortawesome/free-solid-svg-icons'
import {
  Select,
  Input,
  Date,
} from 'Components/common/form'
import {
  getOrder,
  endOrderEdit,
  getRelatedUiid,
  getInstallments,
  getComplementaryInstallments,
  authorizeOrder,
  updateOrder,
  deleteOrder,
  findProducts,
  clearFinder,
  getProduct,
  addProduct,
  getAttachments,
  getMitLink,
  getStripeLink,
  cleanOrigin,
  getOptions,
} from 'Modules/incomeOrders/invoice'
import { cancelOrderPartialAmountAction } from 'Modules/incomeOrders/creditNote'
import {
  resetAdvance,
} from 'Modules/incomeOrders/advancePayment'
import {
  getClients,
  getClient,
} from 'Modules/catalogs/clients'
import { ActionBar, Table } from 'Components/common'
import {
  getInvoiceStatus,
  getPaises,
  getEntidades,
  getTipoPersona,
  getPaymentMethod,
  getCurrencies,
  getUsoCfdi,
  getInvoiceUnits,
  clearInvoiceUnits,
  getIvaRates,
  getIepsRates,
  getProductService,
  clearProductService,
  getTipoRelacion,
  getProductSat,
} from 'Modules/catalogos'
import numeral from 'numeral'

const STATUS_CREATED = '1'
const PERSON_TYPE_FOREIGN = '4'
const PARTIAL_PAYMENTS = 'Pago en Parcialidades'
const CURRENCY_TYPE_MXN = 'MXN'

export class Form extends Component {
  state = {
    showErrors: false,
    generalData,
    addressData,
    order: {},
    conceptos: {
      data: []
    },
    isAddingConcept: false,
    totals,
    installments: {
      data: []
    },
    noInterest: [],
    selectedNoInterest: {},
    isCanceling: false,
    concept: {},
  }
  componentDidMount() {
    let generalData
    const {
      getClients,
      getTipoPersona,
      getPaymentMethod,
      getCurrencies,
      getUsoCfdi,
      getPaises,
      getEntidades,
      getInvoiceStatus,
      getTipoRelacion,
      getIvaRates,
      getIepsRates,
      getInstallments,
      getComplementaryInstallments,
      getAccountsInfo,
      advance,
      getAttachments,
      getNoInterestConfiguration,
      getOptions,
    } = this.props
    const { id } = this.props.order

    getClients({ size: 1000 })
    getTipoPersona()
    getPaymentMethod()
    getCurrencies()
    getUsoCfdi()
    getPaises()
    getEntidades()
    getInvoiceStatus()
    getTipoRelacion()
    getIvaRates()
    getIepsRates()
    getAccountsInfo()
    getNoInterestConfiguration()

    if (id) {
      getOptions(id)
      getInstallments(id)
      getComplementaryInstallments(id)
      getAttachments(id)
    } else {
      if (advance) {
        generalData = clone(this.state.generalData);
        generalData.tipoRelacion.value = 7;
        generalData.tipoRelacion.disabled = true;
        generalData.uuidRelacionado.value = advance.uuid;
        generalData.uuidRelacionado.disabled = true;

        this.setState({
          generalData
        })
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { client, order, noInterest } = nextProps
    console.log(order);
    const { client: curClient, noInterest: curNoInterest } = this.props
    const { id, ...dataClient } = client
    if (client !== curClient) {
      this.setState({
        generalData: mapPropsToState(this.state.generalData, dataClient),
        addressData: mapPropsToState(this.state.addressData, dataClient),
      })
    }

    if (!this.state.order.cliente) {
      if (order.uuidRelacionado) {
        this.props.getRelatedUiid(order.cliente, order.tipoRelacion)
      }
      this.setState({
        generalData: mapPropsToState(this.state.generalData, { ...order, tipoRelacion: 4, uuidRelacionado: order.uuid }),
        addressData: mapPropsToState(this.state.addressData, order),
        totals: mapPropsToState(this.state.totals, order),
        conceptos: {
          data: order.conceptos
        },
        order,
      }, () => {
        this.onChangePaymentMethod({ value: this.state.generalData.formaPago.value })
      })
    }
    if (noInterest !== curNoInterest) {
      this.setState({
        noInterest: noInterest.filter(it => it.plazo !== 'C').map(it => ({ ...it, allow: true }))
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.totals.total.value !== this.state.totals.total.value) {
      this.setState({ selectedNoInterest: {} })
    }
  }
  openConceptModal = concept => this.setState({ isAddingConcept: true, concept })
  closeConceptModal = () => this.setState({ isAddingConcept: false })
  getPermissions = () => {
    return {
      canSave: true,
    }
  }

  onChangeInput = ({ target }, section) => {
    const { name } = target
    const data = getDataInput(target, this.state[section])
    const field = data[name]

    this.setState({
      [section]: data,
      showErrors: false,
      showAccountErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }

  getClient = ({ value }) => {
    if (value) {
      this.props.getClient(value)
    }
  }

  onChangeCurrency = ({ value }) => {
    const generalData = this.state.generalData

    if (value === CURRENCY_TYPE_MXN) {
      generalData.tipoCambio.value = 1
      generalData.tipoCambio.disabled = true
    } else {
      generalData.tipoCambio.value = ''
      generalData.tipoCambio.disabled = false
    }

    this.setState({
      generalData
    })
  }

  onChangePaymentMethod = ({ value }) => {
    let generalData;
    if (value === PARTIAL_PAYMENTS) {
      generalData = mapPropsToState(this.state.generalData, { metodoPago: '10' })
      generalData.metodoPago.disabled = true
    } else {
      generalData = this.state.generalData
      generalData.metodoPago.disabled = false
    }
    this.setState({
      generalData
    })
  }

  onChangeRelationshipType = ({ value }) => {
    const { cliente: { value: clientId } } = this.state.generalData

    this.setState({ generalData: mapPropsToState(this.state.generalData, { uuidRelacionado: '' }) })
    if (clientId && value) {
      this.props.getRelatedUiid(clientId, value)
    }
  }

  createOrder = (event, cb) => {
    const { data: generalData, hasErrors: hasErrorsGeneralData } = validateData(this.state.generalData)
    const { advance, addNotification } = this.props

    if (hasErrorsGeneralData || !this.state.conceptos.data.length) {
      this.setState({
        generalData,
        showErrors: true,
      })
      this.props.addNotification({
        type: 'danger',
        message: '¡Favor de validar algunos campos y que tengas conceptos!'
      })
      return
    }

    if (!!advance && (Math.round(+this.state.totals.total.value) !== Math.round(+advance.total))) {
      this.props.addNotification({
        type: 'danger',
        message: `¡El total del anticipo de pago ${numeral(advance.total).format('$ 0,0.00')} es diferente al total de la factura ${numeral(this.state.totals.total.value).format('$ 0,0.00')}!`
      })
      return
    }

    const payload = {
      id: this.state.order.id,
      ...getValues(this.state.generalData),
      ...getValues(this.state.addressData),
      conceptos: this.state.conceptos.data,
      idAdelanto: advance ? advance.id : undefined,
    }
    this.props.cancelOrderPartialAmount(payload)
      .then(() => {
        addNotification({
          type: 'success',
          message: 'La orden se actualizó correctamente en las próximas horas se cancelará y se emitirá la nueva factura'
        })
        this.props.onCancel(true)
      })
      .catch(({ response: { data } }) => {
        let message = '';
        forEachObjIndexed((it) => { message = `${message} ${message ? ';' : ''} ${it[0]}` }, data.campos)
        addNotification({
          type: 'danger',
          message: message
        })
      })
  }

  onCancel = () => {

    this.props.onCancel()
  }

  onRemove = () => {

  }

  addConcept = (concept, addProduct) => {
    const conceptos = this.state.conceptos.data.map(it => {
      if (it.idConcepto === concept.idConcepto) {
        return concept
      }
      return it
    });

    this.setState({
      conceptos: { data: conceptos },
    }, () => {
      this.closeConceptModal()
      this.updateTotals()
    })
  }

  conceptActions = (row) => (
    <Button primary outlined
      onClick={() => this.openConceptModal(row)}
      disabled={this.state.order.id && this.state.order.estatusOrder === STATUS_CREATED}
    >
      <Icon>
        <FontAwesomeIcon icon={faEdit} />
      </Icon>
    </Button>
  )

  openConfirmRemoveConceptModal = (conceptRemoving) => this.setState({
    conceptRemoving,
    isRemovingConcept: true
  })

  closeConfirmRemoveConceptModal = () => this.setState({
    conceptRemoving: undefined,
    isRemovingConcept: false
  })

  removeConcept = () => {
    const { conceptos } = this.state
    if (this.state.conceptRemoving.id) {
      conceptos.data = reject(propEq('id', this.state.conceptRemoving.id))(conceptos.data)
    } else {
      conceptos.data = reject(propEq('idConcepto', this.state.conceptRemoving.idConcepto))(conceptos.data)
    }
    this.setState({
      conceptos,
      isRemovingConcept: false,
    }, this.updateTotals)
  }

  updateTotals = () => {
    const totals = reduce((acc, cur) => {
      return {
        total: add(prop('total')(acc), prop('total')(cur)),
        montoPorCobrar: add(prop('montoPorCobrar')(acc), prop('total')(cur)),
        subtotal: add(prop('subtotal')(acc), prop('subtotal')(cur)),
        iva: add(prop('iva')(acc), prop('iva')(cur)),
        ieps: add(prop('ieps')(acc), prop('ieps')(cur)),
      }
    },
      {
        total: 0,
        subtotal: 0,
        iva: 0,
        ieps: 0,
        montoPorCobrar: 0,
      }
    )(this.state.conceptos.data)
    this.setState(({ totals: totalsState }) => ({ totals: mapPropsToState(totalsState, totals) }))
  }

  onAuthorize = () => {
    this.setState({
      showAuth: true,
    })
  }

  cancelAuthorize = () => {
    this.setState({
      showAuth: false,
    })
  }

  authorizeOrder = (data) => {
    return this.props.authorizeOrder(
      this.state.order.id,
      data
    ).then(data => {
      this.props.addNotification({
        type: 'success',
        message: 'La órden se autorizó correctamente'
      })
      this.setState({
        showAuth: false,
        generalData: mapPropsToState(this.state.generalData, data)
      })
    })
  }

  removeOrder = () => {
    this.props.deleteOrder(this.state.order.id)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'La órden de ingreso se eliminó correctamente'
        })
        this.props.push('/ordenes-ingreso/facturas')
      })
      .catch(({ response: { data } }) => {
        this.props.addNotification({
          type: 'danger',
          message: data.message
        })
      })
  }

  openConfirmRemoveOrder = () => this.setState({
    isRemovingOrder: true,
  })

  closeConfirmRemoveOrder = () => this.setState({
    isRemovingOrder: false
  })

  getPaymentLink = () => {
    const selected = filter(equals(true))(this.state.selectedNoInterest)
    const promotions = keys(selected)
    this.props.getMitLink({
      reference: this.props.order.clavePagoReferencia,
      monto: this.props.order.total,
      orderId: this.props.order.id,
      clientId: this.props.order.cliente,
      promotions: join(',')(promotions),
    })
  }

  getStripeLink = () => {
    this.props.getStripeLink({
      reference: this.props.order.clavePagoReferencia,
      amount: this.props.order.total,
      orderId: this.props.order.id,
      clientId: this.props.order.cliente,
      currency: this.props.order.divisaMoneda,
    })

  }

  handleNoInterest = ({ target: { name, checked } }) => {
    this.setState(({ selectedNoInterest }) => ({
      selectedNoInterest: { ...selectedNoInterest, [name]: checked }
    }))
  }

  handleOnCancelInvoice = () => {
    this.setState({
      isCanceling: true,
    })
  }

  handleOnClose = () => {
    this.setState({
      isCanceling: false,
    })
  }

  render() {
    const { paises, entidades,
      paymentMethod,
      currencies,
      usoCfdi,
      ivaRates,
      iepsRates,
      invoiceUnits,
      getInvoiceUnits,
      clearInvoiceUnits,
      getProductService,
      clearProductService,
      productService,
      tipoRelacion,
      relatedUiid,
      findProducts,
      products,
      clearFinder,
      getProduct,
      product,
      productSat,
      getProductSat,
    } = this.props
    const { showErrors, generalData } = this.state

    return (
      <Box style={{ position: 'relative' }}>
        <Modal isActive={this.state.isRemovingOrder}>
          <Message danger>
            <Message.Header>
              <p>Eliminar Orden de ingreso</p>
              <Delete onClick={this.closeConfirmRemoveOrder} />
            </Message.Header>
            <Message.Body className="has-text-centered">
              ¿Esta seguro de querer eliminar la orden de ingreso?
              <div className="margin-top-lg">
                <Button danger onClick={this.removeOrder}>Eliminar</Button>
                <Button default className="margin-left-sm" onClick={this.closeConfirmRemoveOrder}>
                  Cancelar
                </Button>
              </div>
            </Message.Body>
          </Message>
        </Modal>
        <Modal isActive={this.state.isAddingConcept}>
          <Message default>
            <Message.Header>
              <p>Editar concepto</p>
              <Delete onClick={this.closeConceptModal} />
            </Message.Header>
            <Message.Body>
              {this.state.isAddingConcept && <Concept
                getUnits={getInvoiceUnits}
                units={invoiceUnits}
                ivaRates={ivaRates}
                iepsRates={iepsRates}
                addNotification={addNotification}
                addConcept={this.addConcept}
                closeConceptModal={this.closeConceptModal}
                clearUnits={clearInvoiceUnits}
                getProductService={getProductService}
                clearProductService={clearProductService}
                productService={productService}
                findProducts={findProducts}
                products={products}
                clearFinder={clearFinder}
                getProduct={getProduct}
                product={product}
                productSat={productSat}
                getProductSat={getProductSat}
                concept={this.state.concept}
              />}
            </Message.Body>
          </Message>
        </Modal>
        <SubTitle className="has-text-centered" is='6'>
          Favor de Capturar los siguientes datos para poder cancelar y emitir la nueva factura,
          el monto total de la orden debe de ser igual o mayor {numeral(this.props.montoEfectivo.Monto_Cobrado_Efectivo).format('$ 0,0.00')}, para la emisión de la nueva factura.
        </SubTitle>
        <ActionBar
          onCancel={this.onCancel}
          permissions={this.getPermissions()}
          onSave={this.createOrder}
          basicRole={['Ofacturación', 'Afacturación']}
        />
        <Divider content="DATOS GENERALES" />
        <Columns>
          <Column className="is-6">
            <Date
              {...generalData.fechaProgramacionEmisionFactura}
              onChange={e => this.onChangeInput(e, 'generalData')}
            />
          </Column>
          <Column className="is-6">
          </Column>
        </Columns>
        <Divider content="GENERALES DE LA FACTURA" />
        <Columns>
          <Column className="is-6">
            <Input
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.nombreORazonSocial}
            />
          </Column>
        </Columns>
        <Columns>
          <Column className="is-6">
            <Input
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.rfc}
            />
          </Column>
          <Column className="is-6">
            <Input
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.correoElectronico}
            />
          </Column>
        </Columns>
        <AddressSection
          data={this.state.addressData}
          onChange={e => this.onChangeInput(e, 'addressData')}
          paises={paises}
          entidades={entidades}
          extranjero={false}
          showErrors={this.state.showErrors}
        />
        <Columns>
          <Column className="is-6">
            {this.state.generalData.tipoDePersona === PERSON_TYPE_FOREIGN && <Input
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.numRegIdTrib}
            />}
          </Column>
          <Column className="is-6">
          </Column>
        </Columns>
        <Columns>
          <Column className="is-6">
            <Select
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.formaPago}
              tooltip="Por disposición Oficial, ‘Pago en Parcialidades o Diferido’, se utiliza cuando el cliente a la fecha de emisión de la factura no ha realizado el pago, ’ Pago en Una sola Exhibición’ se utiliza cuando el cliente ya ha realizado el pago antes de la emisión de la factura."
            />
          </Column>
          <Column className="is-6">
            <Select
              options={paymentMethod}
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.metodoPago}
              tooltip="Por disposición oficial si el Método de Pago es ‘Pago en Parcialidades’, la Forma de Pago deberá ser ‘Por definir’"
            />
          </Column>
        </Columns>
        {(+this.state.generalData.metodoPago.value > 1 && +this.state.generalData.metodoPago.value < 10) &&
          <Columns>
            <Column className="is-6">
              <Input
                onChange={e => this.onChangeInput(e, 'generalData')}
                showErrors={showErrors}
                {...generalData.ultimos4Digitos}
              />
            </Column>
            <Column className="is-6">
            </Column>
          </Columns>
        }
        <Columns>
          <Column className="is-6">
            <Select
              options={usoCfdi}
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.UsoCFDISAT}
              tooltip="Este campo deberá ser preguntado al cliente, en caso de no saber deberá de poner ‘Por definir’"
            />
          </Column>
          <Column className="is-6">
            <Select
              options={currencies}
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.divisaMoneda}
              tooltip="Moneda en la que se emitirá la factura."
            />
          </Column>
        </Columns>
        <Columns>
          <Column className="is-6">
            <Input
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.tipoCambio}
              tooltip="Si la moneda es diferente a MXN, deberán especificar el tipo de cambio de la moneda especificada vs MXN, al tipo de cambio del día."
            />
          </Column>
          <Column className="is-6">
            <Select
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.cuentaCobranza}
              tooltip="En qué cuenta quiere recibir el cobro de la factura, se sugiere que si la Moneda Divisa de la Factura es MXN la cuenta de cobranza sea MXN, si la Moneda Divisa es otra la cuenta de cobranza sea USD."
            />
          </Column>
        </Columns>
        <Columns>
          <Column className="is-6">
            <Select
              options={tipoRelacion}
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.tipoRelacion}
              tooltip="Se ocupa cuando la factura a realizar esté relacionada con facturas, anticipos o notas de crédito previamente timbradas, se necesitará el número de UUID o Folio Fiscal del documento previo."
            />
          </Column>
          <Column className="is-6">
            {!!generalData.uuidRelacionado.value && <Input
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...omit(['valueProperty', 'valueLabel'], generalData.uuidRelacionado)}
              tooltip="Favor de Poner el UUID o Folio de la factura fiscal anterior, se mostrarán las posibles relaciones a la factura actual, según la opción elegida."
            />}
            {!!generalData.uuidRelacionado.value || <Select
              options={relatedUiid}
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.uuidRelacionado}
              tooltip="Favor de Poner el UUID o Folio de la factura fiscal anterior, se mostrarán las posibles relaciones a la factura actual, según la opción elegida."
            />}
          </Column>
        </Columns>

        <Divider content="CONCEPTOS" />
        <Table
          colsetup={conceptsCol}
          coldata={this.state.conceptos}
          tableClassName='table is-striped is-hoverable is-fullwidth'
          emptyTableMarkUp={(<span>Aun no hay conceptos</span>)}
          customCol={{
            customColName: 'acciones',
            renderFunc: this.conceptActions
          }}
        />
        <Totals
          data={this.state.totals}
        />
      </Box >
    )
  }
}

const mapStateToProps = ({ catalogs, catalogos, incomeOrders, principal }) => ({
  clients: catalogs.clients.clients,
  client: catalogs.clients.client,
  paises: catalogos.paises,
  entidades: catalogos.entidades,
  invoiceStatus: catalogos.invoiceStatus,
  tipoPersona: catalogos.tipoPersona,
  paymentMethod: catalogos.paymentMethod,
  order: incomeOrders.invoice.order,
  currencies: catalogos.currencies,
  usoCfdi: catalogos.usoCfdi,
  invoiceUnits: catalogos.invoiceUnits,
  productService: catalogos.productService,
  ivaRates: reject(propEq('id', '4'))(catalogos.ivaRates),
  iepsRates: catalogos.iepsRates,
  tipoRelacion: catalogos.tipoRelacion,
  relatedUiid: incomeOrders.invoice.relatedUiid,
  installments: incomeOrders.invoice.installments,
  complementaryInstallments: incomeOrders.invoice.complementaryInstallments,
  accountsInfo: principal.accountsInfo,
  products: incomeOrders.invoice.products,
  product: incomeOrders.invoice.product,
  productSat: catalogos.productSat,
  reference: incomeOrders.invoice.reference,
  advance: incomeOrders.advancePayment.advance,
  attachments: incomeOrders.invoice.attachments,
  isFetching: incomeOrders.invoice.isFetching,
  mitLink: incomeOrders.invoice.mitLink,
  noInterest: principal.noInterest,
  origin: incomeOrders.invoice.origin,
  options: incomeOrders.invoice.options,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getOrder,
  getClients,
  getClient,
  getEntidades,
  getPaises,
  getInvoiceStatus,
  addNotification,
  getTipoPersona,
  getPaymentMethod,
  cancelOrderPartialAmount: cancelOrderPartialAmountAction,
  getCurrencies,
  getUsoCfdi,
  push,
  getInvoiceUnits,
  getIvaRates,
  getIepsRates,
  clearInvoiceUnits,
  getProductService,
  clearProductService,
  getTipoRelacion,
  endOrderEdit,
  getRelatedUiid,
  getInstallments,
  getComplementaryInstallments,
  authorizeOrder,
  updateOrder,
  deleteOrder,
  getAccountsInfo,
  findProducts,
  clearFinder,
  getProduct,
  getProductSat,
  addProduct,
  resetAdvance,
  getAttachments,
  getMitLink,
  getNoInterestConfiguration,
  getStripeLink,
  cleanOrigin,
  getOptions,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Form)