import React, { useState, useEffect, useRef } from 'react'
import { Control, Field } from 'Components/common/bulma'
import Autosuggest from 'react-autosuggest'

export default function AutoComplete({
  label,
  placeholder,
  errors,
  showErrors,
  name,
  initialInputValue = '',
  listKey = "id",
  suggestions,
  onSelect,
  property,
  onFetchRequested,
  onClearRequested,
  onChange,
  disabled,
}) {
  const [localValue, setLocalValue] = useState('');
  const timer = useRef(null);

  useEffect(() => {
    setLocalValue(initialInputValue)
  }, [initialInputValue])

  const onLocalChange = (event, { newValue, method }) => {
    setLocalValue(newValue)

    if (!newValue) {
      onChange({
        target: {
          name: name,
          value: ''
        }
      })
    }
  }

  const renderSuggestion = suggestion => (
    <div>
      {suggestion[property]}
    </div>
  )

  const fetchRequested = ({ value }) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => onFetchRequested({ query: value }), 500)
  }

  const getSuggestionValue = suggestion => suggestion[property];

  const onSuggestionSelect = (e, { suggestion }) => {
    onChange({
      target: {
        name: name,
        value: suggestion[listKey],
        suggestion,
      }
    })
  }

  return (
    <Field>
      <label className="label">{label}</label>
      <Control>
        <Autosuggest
          suggestions={suggestions}
          inputProps={{
            value: localValue,
            onChange: onLocalChange,
            className: 'input',
            placeholder,
            disabled,
          }}
          onSuggestionsClearRequested={onClearRequested}
          getSuggestionValue={getSuggestionValue}
          onSuggestionsFetchRequested={fetchRequested}
          renderSuggestion={renderSuggestion}
          onSuggestionSelected={onSuggestionSelect}
        />
      </Control>
      {(errors && !!errors.length && showErrors) &&
        <p className="has-text-danger help">{errors[0].message}</p>}
    </Field>
  )
}
