import axios from 'axios'
import { getHeaders } from './util'

const url = process.env.REACT_APP_API

export const GET_USERS = 'USERS/GET_USERS'
export const GET_USER = 'USERS/GET_USER'
export const CREATE_USER_SUCCESS = 'USERS/CREATE_USER_SUCCESS'
export const UPDATE_USER_SUCCESS = 'USERS/UPDATE_USER_SUCCESS'
export const DELETE_USER_SUCCESS = 'USERS/DELETE_USER_SUCCESS'
export const RESET_USER = 'USERS/RESET_USER'
export const RESET_USER_PASSWORD_SUCCESS = 'USERS/RESET_USER_PASSWORD_SUCCESS'
export const USER_CARD_ASIGNMENT_SUCCESS = 'USERS/USER_CARD_ASIGNMENT_SUCCESS'
export const USER_REVOKE_CARD_SUCCESS = 'USERS/USER_REVOKE_CARD_SUCCESS'
export const USER_REVOKE_TFA = 'USERS/USER_REVOKE_TFA'

const initialState = {
  users: { data: [] },
  user: undefined,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_USERS:
    case GET_USER:
    case CREATE_USER_SUCCESS:
    case DELETE_USER_SUCCESS:
    case UPDATE_USER_SUCCESS:
      return { ...state, ...payload }
    case USER_REVOKE_TFA:
      return { ...state, user: {...state.user, ...payload } }
    case RESET_USER:
      return initialState

    default:
      return state
  }
}

export const getUsersAction = (params = {}) => {
  return dispatch => {
    return axios.get(`${url}/user`, { headers: getHeaders(), params })
      .then(({ data }) => {
        dispatch({
          type: GET_USERS,
          payload: { users: data }
        })
      })
  }
}
export const getUserAction = (id) => {
  return dispatch => {
    return axios.get(`${url}/user/${id}`, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: GET_USER,
          payload: { user: data }
        })
      })
  }
}

export const createUserAction = (user) => {
  return dispatch => {
    return axios.post(`${url}/user`, user, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: CREATE_USER_SUCCESS,
          payload: { user: data }
        })
        return data
      })
  }
}

export const updateUserAction = (user) => {
  return dispatch => {
    return axios.put(`${url}/user/${user.id}`, user, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: UPDATE_USER_SUCCESS,
          payload: { user: data }
        })
        return data
      })
  }
}

export const deleteUserAction = (userId) => {
  return dispatch => {
    return axios.delete(`${url}/user/${userId}`, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: DELETE_USER_SUCCESS,
          payload: { user: data }
        })
      })
  }
}
export const resetPasswordUserAction = (userId) => {
  return dispatch => {
    return axios.get(`${url}/user/reset/${userId}`, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: RESET_USER_PASSWORD_SUCCESS,
        })
      })
  }
}
export const asignCardAction = ({userId, ...assignment}) => {
  return dispatch => {
    return axios.post(`${url}/user/asign-card/${userId}`, assignment, { headers: getHeaders() })
      .then(({data}) => {
        dispatch({
          type: USER_CARD_ASIGNMENT_SUCCESS,
        })
        return data
      })
  }
}

export const revokeCardAction = ({userId, ...revokment}) => {
  return dispatch => {
    return axios.post(`${url}/user/revoke-card/${userId}`, revokment,{ headers: getHeaders() })
      .then(({data}) => {
        dispatch({
          type: USER_REVOKE_CARD_SUCCESS,
        })
        return data
      })
  }
}

export const revokeTfaAction = ({userId}) => {
  return dispatch => {
    return axios.delete(`${url}/user/revoke-tfa/${userId}`,{ headers: getHeaders() })
      .then(({data}) => {
        dispatch({
          type: USER_REVOKE_TFA,
          payload: data,
        })
        return data
      })
  }
}

export const resetUserAction = () => ({
  type: RESET_USER,
})

