import moment from 'moment'
import numeral from 'numeral'
import { propOr } from 'ramda'

export const legalRepresentativeCols = [
  {
    title: 'Estatus',
    datakey: 'estatus'
  },
  {
    title: 'Apellido Paterno',
    datakey: 'apellidoPaterno'
  },
  {
    title: 'Apellido Materno',
    datakey: 'apellidoMaterno'
  },
  {
    title: 'Nombre',
    datakey: 'nombre'
  },
  {
    title: 'Correo Electrónico',
    datakey: 'email'
  },
  {
    title: 'Teléfono',
    datakey: 'telefono'
  },
  {
    title: 'Acciones',
    custom: 'actions',
  }
]

export const cuentasBancarias = [
  {
    title: 'Tipo de cuenta',
    datakey: 'tipoCuenta'
  },
  {
    title: 'Estatus',
    datakey: 'estatus'
  },
  {
    title: 'Clabe',
    datakey: 'clabe'
  },
  {
    title: 'Pago Directo o con banco intermediario',
    datakey: 'pagoDirectoOConBancoIntermediario'
  },
  {
    title: ' Directo Swift Code',
    datakey: 'directoSwiftCode'
  },
  {
    title: ' Intermediario Swift Code',
    datakey: 'intermediarioSwiftCode'
  },
  {
    title: 'Acciones',
    custom: 'actions',
  }

]
export const legalRepresentative = {
  id: {
    label: 'Id RL',
    name: 'id',
  },
  integrado: {
    label: 'Id Integrado',
    name: 'integrado',
  },
  estatus: {
    label: 'Estatus',
    name: 'estatus',
  },
  apellidoPaterno: {
    label: 'Apellido Paterno',
    name: 'apellidoPaterno',
  },
  apellidoMaterno: {
    label: 'Apellido Materno',
    name: 'apellidoMaterno',
  },
  nombre: {
    label: 'Nombre',
    name: 'nombre',
  },
  genero: {
    label: 'Género',
    name: 'genero',
  },
  rfc: {
    label: 'RFC',
    name: 'rfc',
  },
  curp: {
    label: 'CURP',
    name: 'curp',
  },
  email: {
    label: 'Correo Electrónico',
    name: 'email',
  },
  telefono: {
    label: 'Teléfono',
    name: 'telefono',
  },
  poderTitulosCredito: {
    label: 'Poder con Dominio',
    name: 'poderTitulosCredito',
    dataUnmarshaller: val => val ? !!(+val): false,
  },


}
export const personaMoral = {
  id: {
    label: 'Id PM',
    name: 'id',
  },
  integrado: {
    label: 'Id Integrado',
    name: 'integrado',
  },
  estatus: {
    label: 'Estatus',
    name: 'estatus',
  },
  numeroInstrumento: {
    label: 'Número de Instrumento',
    name: 'numeroInstrumento',
  },
  numeroNotaria: {
    label: 'Número de Notaria',
    name: 'numeroNotaria',
  },
  nombreNotario: {
    label: 'Nombre del Notario',
    name: 'nombreNotario',
  },
  fechaInstrumento: {
    label: 'Fecha de Instrumento',
    name: 'fechaInstrumento',
    format: val => moment(val).format('DD/MM/YYYY'),
  },
  actaConstitutiva: {
    label: 'Acta Constitutiva',
    name: 'actaConstitutiva',
    dataUnmarshaller: val => val ? !!(+val): false,
  },
  numeroRegistroPublico: {
    label: 'Número Registro público',
    name: 'numeroRegistroPublico',
  },
  fechaRegistroPublico: {
    label: 'Fecha de Registro público',
    name: 'fechaRegistroPublico',
  },
  registroPublicoTramite: {
    label: 'Registro Público en trámite',
    name: 'registroPublicoTramite',
    dataUnmarshaller: val => val ? !!(+val): false,
  },
  principalAccionista: {
    label: 'Principal Accionista',
    name: 'principalAccionista',
  },
  segundoAccionista: {
    label: 'Segundo Accionista',
    name: 'segundoAccionista',
  },
  numeroEmpleados: {
    label: 'Número de Empleados',
    name: 'numeroEmpleados',
  },
  capitalSocial: {
    label: 'Capital Social',
    name: 'capitalSocial',
    format: val => numeral(val).format('$ 00,00.00'),
  },
}
export const detalleCuenta = {
  id: {
    label: 'Id Banco',
    name: 'id'
  },
  integrado: {
    label: 'Id Integrado',
    name: 'integrado'
  },
  tipoCuenta: {
    label: 'Tipo de Cuenta',
    name: 'tipoCuenta'
  },
  estatus: {
    label: 'Estatus',
    name: 'estatus'
  },
  clabe: {
    label: 'Clabe',
    name: 'clabe'
  },
  cuentaBancaria: {
    label: 'Cuenta Bancaria',
    name: 'cuentaBancaria'
  },
  banco: {
    label: 'Banco',
    name: 'banco'
  },
  numeroSucursal: {
    label: 'Número Sucursal',
    name: 'numeroSucursal'
  },
  plaza: {
    label: 'Plaza',
    name: 'plaza'
  },
  encabezadoEstadoCuenta: {
    label: 'Encabezado Estado de Cuenta',
    dataUnmarshaller: val => val ? !!(+val): false,
  },
  pagoDirectoOConBanco: {
    label: 'Pago directo o con banco',
    name: 'pagoDirectoOConBanco'
  },
  directoBeneficiario: {
    label: 'Directo Beneficiario',
    name: 'directoBeneficiario'
  },
  directoDireccion: {
    label: 'Directo Dirección',
    name: 'directoDireccion'
  },
  directoIbanONumeroDeCuenta: {
    label: 'Directo IBAN o número de cuenta',
    name: 'directoIbanONumeroDeCuenta'
  },
  directoSwiftCode: {
    label: 'Directo Swift Code',
    name: 'directoSwiftCode'
  },
  directoVatId: {
    label: 'Directo VAT ID',
    name: 'directoVatId'
  },
  directoBanco: {
    label: 'Directo Banco',
    name: 'directoBanco'
  },
  intermediarioNombreDelBanco: {
    label: 'Intermediario Nombre del Banco',
    name: 'intermediarioNombreDelBanco',
  },
  intermediarioPais: {
    label: 'Intermediario País',
    name: 'intermediarioPais'
  },
  intermediarioSwiftCode: {
    label: 'Intermediario Swift Code',
    name: 'intermediarioSwiftCode'
  },
  intermediarioSortCode: {
    label: 'Intermediario Sort Code',
    name: 'intermediarioSortCode'
  },

  intermediarioPaisYEstado: {
    label: 'Intermediario País y Estado',
    name: 'intermediarioPaisYEstado'
  },
  intermediarioSwiftCodeBanco: {
    label: 'Intermediario Swift Code Banco',
    name: 'intermediarioSwiftCodeBanco'
  },
  intermediarioNombreDeLa: {
    label: 'Intermediario Nombre de la',
    name: 'intermediarioNombreDeLa'
  },
  intermediarioNumeroDeLa: {
    label: 'Intermediario Número de la',
    name: 'intermediarioNumeroDeLa'
  },
  intermediarioNombreDel: {
    label: 'Intermediario Nombre del',
    name: 'intermediarioNombreDel'
  },
  intermediarioCiudadYPaísDel: {
    label: 'Intermediario Ciudad y País del',
    name: 'intermediarioCiudadYPaísDel'
  },
  intermediarioNumerode: {
    label: 'Intermediario Número de',
    name: 'intermediarioNumerode'
  },
}
export const documentos = {
  id: {
    label: 'Id',
    name: 'id'
  },
  nombreRazonSocial: {
    label: 'Nombre o Razón Social',
    name: 'nombreRazonSocial'
  },
  identificacion_oficial: {
    label: 'Identificación Oficial',
    name: 'identificacion_oficial',
    dataUnmarshaller: val => val ? !!(+val): false,
  },
  actaConstitutiva: {
    label: 'Acta Constitutiva',
    name: 'actaConstitutiva',
    dataUnmarshaller: val => val ? !!(+val): false,
  },
  inscripcionRFC: {
    label: 'Inscripción al RFC',
    name: 'inscripcionRFC',
    dataUnmarshaller: val => val ? !!(+val): false,
  },
  contratoFirmado: {
    label: 'Contrato Firmado',
    name: 'contratoFirmado',
    dataUnmarshaller: val => val ? !!(+val): false,
  },
  comprobanteDomicilio: {
    label: 'Comprobante de Domicilio',
    name: 'comprobanteDomicilio',
    dataUnmarshaller: val => val ? !!(+val): false,
  },
  entregaTituloSocios: {
    label: 'Entrega Título Socios',
    name: 'entregaTituloSocios',
    dataUnmarshaller: val => val ? !!(+val): false,
  },
  fechaAdmision: {
    label: 'Fecha de Admisión',
    name: 'fechaAdmision',
    format: val => moment(val).format('DD/MM/YYYY')
  },
  fechaContrato: {
    label: 'Fecha de Contrato',
    name: 'fechaContrato'
  },

}
export const cuentasAsignadas = {
  id: {
    label: 'Id',
    name: 'id'
  },
  nombreRazonSocial: {
    label: 'Nombre o Razón Social',
    name: 'nombreRazonSocial'
  },
  cuentaClabeStp: {
    label: 'Cuenta CLABE STP',
    name: 'cuantaClabeStp'
  },
  pagosReferenciados: {
    label: 'Referencia convenio CIE',
    name: 'pagosReferenciados'
  },
  cuentaMXN: {
    label: 'Cuenta MXN',
    name: 'cuentaMXN'
  },
  cuentaUSD: {
    label: 'Cuenta USD',
    name: 'cuentaUSD'
  },
  cuentaPaypal: {
    label: 'Cuenta paypal',
    name: 'cuentaPaypal'
  },
  correoPaypal: {
    label: 'Correo paypal',
    name: 'correoPaypal'
  },
  referenciaPaypal: {
    label: 'Referencia paypal',
    name: 'referenciaPaypal'
  },
  terminalPuntoVenta: {
    label: 'Terminal Punto de Venta',
    name: 'terminalPuntoVenta'
  },
  ligaMercadoPago: {
    label: 'Liga MercadoPago',
    name: 'LigaMercadoPago'
  },
  qrMercadoPago: {
    label: 'QR MercadoPago',
  },


}
export const comisiones = {
  comisionFacturaCobranza: {
    label: 'Comisión Facturación  y Cobranza',
    name: 'comisionFacturaCobranza',
    format: val => numeral(Number(val)).format('0.00 %'),
  },
  comisionDeposito: {
    label: 'Comisión Depósito',
    name: 'comisionDeposito',
    format: val => numeral(Number(val)).format('0.00 %'),
  },
  comisionPrestamo: {
    label: 'Comisión Prestamo',
    name: 'comisionPrestamo',
    format: val => numeral(Number(val)).format('0.00 %'),
  },
  comisionSPEI: {
    label: 'Comisión SPEI',
    name: 'comisionSPEI',
    format: val => numeral(Number(val)).format('$ 0,00.00'),
  },
  comisionCheque: {
    label: 'Comisión Cheque',
    name: 'comisionCheque',
    format: val => numeral(Number(val)).format('$ 0,00.00'),
  },
  comisionEmisionFacturaTimbre: {
    label: 'Comisión Emisión Factura (timbre)',
    name: 'comisionEmisionFacturaTimbre',
    format: val => numeral(Number(val)).format('$ 0,00.00'),
  },
  comisionIAS: {
    label: 'Comisión IAS',
    name: 'comisiónIAS',
    format: val => numeral(Number(val)).format('0.00 %')
  },
  comisionTransferenciaUSD: {
    label: 'Comisión Transferencia USD',
    name: 'comisionTransferenciaUSD',
    format: val => numeral(Number(val)).format('$ 0,00.00'),
  },
  comisionEmisionMonederoGasolina: {
    label: 'Comisión Emisión Monedero Gasolina',
    name: 'comisionEmisionMonederoGasolina',
    format: val => numeral(Number(val)).format('$ 0,00.00'),
  },
  comisionPorEmisionTarjetas: {
    label: 'Comisión por Emisión de  Tarjetas',
    name: 'comisionPorEmisionTarjetas',
    format: val => numeral(Number(val)).format('$ 0,00.00'),
  },
  comisionCIEInternetPracticaja: {
    label: 'Comisión CIE Internet-Practicaja',
    name: 'comisionCIEInternetPracticaja',
    format: val => numeral(Number(val)).format('$ 0,00.00'),
  },
  comisionCIECaja: {
    label: 'Comisión CIE Caja',
    format: val => numeral(Number(val)).format('$ 0,00.00'),
  },

}

export const general = {
  id: {
    label: 'Id',
    name: 'id',
  },
  estatus: {
    label: 'Estatus',
    name: 'estatus',
  },
  serieTitulo: {
    label: 'Serie Título',
    name: 'serieTitulo',
  },
  numeroTitulo: {
    label: 'Número Título',
    name: 'numeroTitulo'
  },
  tipoPersona: {
    label: 'Tipo de Persona',
    name: 'tipoPersona',
    dataUnmarshaller: propOr(undefined, 'tipoPersona')
  },
  nombreRazonSocial: {
    label: 'Nombre o Razón Social',
    name: 'nombreRazonSocial'
  },
  rfc: {
    label: 'RFC',
    name: 'rfc'
  },
  paginaWeb: {
    label: 'Página WEB',
    name: 'paginaWeb'
  },
  cp: {
    label: 'Código Postal',
    name: 'cp'
  },
  pais: {
    label: 'País',
    name: 'pais'
  },
  entidadFederativa: {
    label: 'Entidad Federativa',
    name: 'entidadFederativa'
  },
  municipio: {
    label: 'Municipio',
    name: 'municipio'
  },
  colonia: {
    label: 'Colonia',
    name: 'colonia'
  },
  calle: {
    label: 'Calle',
    name: 'calle'
  },
  numeroExterior: {
    label: 'Número Exterior',
    name: 'numeroExterior'
  },
  numeroInterior: {
    label: 'Número Interior',
    name: 'numeroInterior'
  },
  telefono: {
    label: 'Teléfono',
    name: 'telefono'
  },
  extension: {
    label: 'Extensión',
    name: 'extension'
  },

}