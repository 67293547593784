import React, { useState, Fragment } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Input,
  Control,
  Field,
  Column,
  Columns,
  Notification,
  Delete,
  Title,
} from 'Components/common/bulma'
import { InputWithError } from 'Components/common/form'
import useForm from '../../hooks/useForm'

const Login = ({ data = {}, onChangeInput,
  login, loginError, randomKey, refreshRandomKey, completions, openTerms, openNotice,
  showErrors,
  hideLoginError,
  submitting,
  step = 0,
  prelogin,
  setLogin,
  loginWay,
  loginWithTas,
  sendQrEmail,
  loginTop,
  enableTotp,
}) => {
  const initialCardFields = {
    card: {
      input: {
        value: '',
        name: 'card',
      },
      validation: 'required|isLength:3',
      pattern: 'number',
    },
  };
  const initialTotpFields = {
    totp: {
      input: {
        value: '',
        name: 'totp',
      },
      label: 'Codigo totp',
      validation: 'required|isLength:3',
      pattern: 'number',
    },
  };
  const [
    // eslint-disable-next-line
    , , ,
    isValidCardForm, getCardValues,
  ] = useForm({ fields: initialCardFields });
  const [
    // eslint-disable-next-line
    totpFields, setTotpFields, onToptFieldChange,
    isValidToptForm, getToptValues, showToptErrors
  ] = useForm({ fields: initialTotpFields });
  const [isEmailSent, setIsEmailSent] = useState(false);
  const handleOnLogin = () => {
    if (loginWay === 'tas') {
      if (isValidCardForm()) {

        loginWithTas({ ...getCardValues(), 'verification-token': prelogin['verification-token'] });
      }
      return;
    }

    login();
  }

  const handleOnSend = () => {
    sendQrEmail({ 'verification-token': prelogin['verification-token'] })
      .then(() => {
        setIsEmailSent(true);
      })
      .catch(() => {
        setIsEmailSent(true);
      });
  }

  const handleOnEnable = () => {
    if (isValidToptForm()) {
      enableTotp({ ...getToptValues(), verificationToken: prelogin['verification-token'] });
    }
  }

  const handleOnLoginTotp = () => {
    if (isValidToptForm()) {
      loginTop({ ...getToptValues(), verificationToken: prelogin['verification-token'] });
    }
  }

  return (
    <Fragment>
      <Title is="5">Bienvenido al sistema de administración</Title>
      <form>
        {step === 0 && <><Field>
          <Control>
            <label>Número de Tarjeta:</label>
            <Input
              className="is-medium"
              name="usuario"
              value={data.usuario.value}
              onChange={onChangeInput}
              maxLength="16"
              type="text"
              placeholder="Tarjeta"
              autoFocus=""
              disabled={submitting}
            />
            {(!data.usuario.valid && showErrors) && (
              <p className="help is-danger">
                {data.usuario.errors[0].message}
              </p>
            )}
          </Control>
        </Field>

          <Field>
            <Control>
              <label>Contraseña:</label>
              <Input
                className="is-medium"
                name="password"
                value={data.password.value}
                onChange={onChangeInput}
                type="password"
                placeholder="Contraseña"
                disabled={submitting}
              />
              {(!data.password.valid && showErrors) && (
                <p className="help is-danger">
                  {data.password.errors[0].message}
                </p>
              )}
            </Control>
          </Field>
          <Field>
            <Control>
              <input name="terminos" type="checkbox" id="cbx" style={{ display: 'none' }} onChange={onChangeInput} />
              <label htmlFor="cbx" className="check">
                <svg width="18px" height="18px" viewBox="0 0 18 18">
                  <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                  <polyline points="1 9 7 14 15 4"></polyline>
                </svg>
              </label>
              <Button onClick={openTerms} className="is-text has-text-danger margin-left-md">Acepto Términos y Condiciones</Button>
              {(!data.terminos.valid && showErrors) && (
                <p className="help is-danger">
                  Debe aceptar los términos y condiciones
                </p>
              )}
            </Control>
          </Field>
        </>
        }

        {(prelogin.enable_tfa === false && !loginWay) && <p><Button type="button" primary onClick={handleOnSend}>Habilitar acceso por OTP</Button></p>}
        {prelogin.enable_tfa && !loginWay && <p><Button type="button" onClick={() => setLogin('totp')}>Login por OTP</Button></p>}
        {(prelogin.enable_tas && !prelogin.enable_tfa && !loginWay) && <p><Button type="button" onClick={() => setLogin('tas')}>Login por tarjeta</Button></p>}

        {isEmailSent && <div>
          <h3>Para activar su acceso por otp ingrese el codigo</h3>
          <Field>
            <Control>
              <InputWithError
                onChange={onToptFieldChange}
                {...totpFields.totp}
              />
              {(!totpFields.totp.valid && showToptErrors) && (
                <p className="help is-danger">
                  {totpFields.totp.error}
                </p>
              )}
            </Control>
          </Field>
          <Button type="button" onClick={handleOnEnable}>Activar Totp</Button>
        </div>}
        {loginWay === 'totp' && <div>
          <h3>Ingrese el codigo</h3>
          <Field>
            <Control>
              <InputWithError
                onChange={onToptFieldChange}
                {...totpFields.totp}
              />
              {(!totpFields.totp.valid && showToptErrors) && (
                <p className="help is-danger">
                  {totpFields.totp.error}
                </p>
              )}
            </Control>
          </Field>
          <Button type="button" onClick={handleOnLoginTotp}>Activar Totp</Button>
        </div>}

        {/* <Field>
          <Control>
            <label >Acesso seguro <strong><em>{randomKey.randomCard}:</em></strong></label>
          </Control>
        </Field>
          <Field className="is-horizontal">
            <div className="field-body">
              <Field className="padding-left-md">
                <Control>
                  <CountDown
                    key={completions}
                    seconds={60}
                    color="red"
                    alpha={0.9}
                    size={35}
                    onComplete={refreshRandomKey} />
                </Control>
              </Field>
              <Field>
                <Control>
                  <Input
                    name="card"
                    value={data.card.value}
                    onChange={onChangeInput}
                    maxLength="3"
                    type="text"
                    placeholder="Token"
                    disabled={submitting}
                    {...data.card}
                  />
                  {(!data.card.valid && showErrors) && (
                    <p className="help is-danger">
                      {data.card.error}
                    </p>
                  )}
                </Control>
              </Field>
            </div>
          </Field> */}

        {loginError && (
          <Notification danger>
            <Delete onClick={hideLoginError} />
            Tu usuario y/o contraseña no son válidos.
          </Notification>
        )}
        {(step === 0 || loginWay) && <Button
          type="button"
          onClick={handleOnLogin}
          disabled={submitting}
          loading={submitting}
          className="is-block is-black is-fullwidth is-medium is-rounded">
           {step === 0 ? 'Continuar' : 'Entrar'}
        </Button>}
      </form>
      <Columns className="is-mobile">
      </Columns>
      <Columns>
        <Column>
          <Link className="has-text-black" to="recuperar-contrasena">Recuperar contraseña</Link>
        </Column>
      </Columns>
      <Button onClick={openNotice} className="has-text-black margin-h-sm is-text ">Aviso de privacidad</Button>
      <h6 className="has-text-black margin-h-sm">Integradora Labor y Transporte, S.A de C.V</h6>
    </Fragment>
  )
}

export default Login
