import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Box, Button, SubTitle, Divider, Columns, Column, Field, Icon } from 'Components/common/bulma'
import { Select } from 'Components/common/form'
import { getReport, downloadReport } from 'Modules/reports'
import { bindActionCreators } from 'redux'
import { columns } from './data'
import { columnsDownload } from './dataDownload'
import Table from 'Components/common/table'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { pluck, prop } from 'ramda'
import saveAs from 'file-saver'
import XLSX from 'xlsx';

export class Reportes extends Component {
  static propTypes = {
    prop: PropTypes
  }

  state = {
    report: undefined,
    dateStart: undefined,
    dateEnd: undefined,
    downloading: false,
  }

  onChange = ({ target, ...event }) => {
    const index = event.nativeEvent.target.selectedIndex;
    const { value } = target
    this.setState({
      report: value,
      reportName: event.nativeEvent.target[index].text
    })
    this.props.getReport(value)
  }

  changeFilter = (value, field) => {
    this.setState({
      [field]: value
    })
  }

  refresh = (params) => {
    const filters = {
      dateStart: this.state.dateStart ? moment(this.state.dateStart).format('YYYY-MM-DD hh:mm:ss') : '',
      dateEnd: this.state.dateEnd ? moment(this.state.dateEnd).format('YYYY-MM-DD hh:mm:ss') : ''
    }

    if (this.state.report) {
      return this.props.getReport(this.state.report, filters, params)
    } else {
      return new Promise((resolve, reject) => {
        resolve(true)
      });
    }
  }

  cleanFilter = () => {
    this.setState({
      dateStart: undefined,
      dateEnd: undefined,
    }, () => this.refresh({}))
  }

  download = () => {
    this.setState({
      downloading: true,
    })
    const filters = {
      dateStart: this.state.dateStart ? moment(this.state.dateStart).format('YYYY-MM-DD hh:mm:ss') : '',
      dateEnd: this.state.dateEnd ? moment(this.state.dateEnd).format('YYYY-MM-DD hh:mm:ss') : '',
      size: '0'
    }
    this.props.downloadReport(this.state.report, filters)
      .then(data => {
        this.mapToDownload(data)
      })
  }
  mapToDownload = (data) => {
    let parsed = []
    parsed.push(pluck('title')(columnsDownload[this.state.report].columns))

    const keys = pluck('datakey')(columnsDownload[this.state.report].columns);
    const format = pluck('format')(columnsDownload[this.state.report].columns);

    data.forEach(it => {
      parsed.push(keys.map((key, idx) => {
        return prop(key)(it) ? (format[idx] ? format[idx](prop(key)(it)) : prop(key)(it)) : ''
      }))
    })
    var worksheet = XLSX.utils.aoa_to_sheet(parsed);
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Reporte");

    var wopts = { bookType: 'xlsx', bookSST: false, type: 'array' };

    var wbout = XLSX.write(workbook, wopts);

    /* the saveAs call downloads a file on the local machine */
    saveAs(new Blob([wbout], { type: "application/octet-stream" }), this.state.reportName + '.xlsx');

    // download(parsed, this.state.report, 'text/csv')
  }

  render() {
    const { reportData } = this.props
    return (
      <Box>
        <SubTitle className="has-text-centered" is='3'>Reportes </SubTitle>
        <Columns>
          <Column className="is-half">
            <Select
              onChange={this.onChange}
              label="Seleccione el reporte que quiere descargar"
              options={[
                { id: 'movesMXN', label: 'Movimientos MXN' },
                { id: 'movesUSD', label: 'Movimientos USD' },
                { id: 'incomeOrder', label: 'Ordenes de Ingreso' },
                { id: 'paymentOrders', label: 'Ordenes de Pago' },
                { id: 'transfers', label: 'Ordenes de Traspaso' },
                { id: 'loanOrders', label: 'Ordenes de Préstamo' },
                { id: 'accountsReceivable', label: 'Cuentas por Cobrar' },
                { id: 'accountsPayable', label: 'Cuentas por Pagar' },
                { id: 'gasolineConciliationTOKA', label: 'Movimientos de Monederos de Gasolina' }
              ]}
              valueProperty="id"
              valueLabel="label"
            />
          </Column>
        </Columns>
        <Divider content="Filtros" />
        <Columns>
          <Column className="is-two-fifths">
            <Field className="is-horizontal">
              <div className="field-label">
                <label className="label">Fecha Inicio:</label>
              </div>
              <div className="field-body">
                <DatePicker
                  className="input"
                  dateFormat="dd/MM/yyyy"
                  maxDate={this.state.dateEnd ? moment(this.state.dateEnd).add('-1', 'days').toDate() : undefined}
                  selected={this.state.dateStart ? moment(this.state.dateStart).toDate() : undefined}
                  onChange={e => this.changeFilter(e, 'dateStart')}
                />
              </div>
            </Field>
          </Column>
          <Column className="is-two-fifths">
            <Field className="is-horizontal">
              <div className="field-label">
                <label className="label">Fecha Fin:</label>
              </div>
              <div className="field-body">
                <DatePicker
                  className="input"
                  dateFormat="dd/MM/yyyy"
                  minDate={this.state.dateStart ? moment(this.state.dateStart).add('1', 'days').toDate() : undefined}
                  selected={this.state.dateEnd ? moment(this.state.dateEnd).toDate() : undefined}
                  onChange={e => this.changeFilter(e, 'dateEnd')}
                />
              </div>
            </Field>
          </Column>
          <Column>
            <Button
              disabled={!this.state.report || !this.state.dateStart || !this.state.dateEnd}
              onClick={() => this.refresh({})}>Filtrar</Button>
            <Button
              disabled={!this.state.dateStart && !this.state.dateEnd}
              onClick={this.cleanFilter}>Limpiar</Button>
          </Column>
        </Columns>
        <Divider content="Reporte" />
        <Columns>
          <Column className="has-text-right">
            <Button
              disabled={!reportData.data.length}
              onClick={this.download}>
              <Icon>
                <FontAwesomeIcon icon={faDownload} />
              </Icon>
            </Button>
          </Column>
        </Columns>
        <Table
          className="is-size-7"
          style={{ minWidth: columns[this.state.report] ? columns[this.state.report].width : '' }}
          id="table-to-xls"
          colsetup={columns[this.state.report] ? columns[this.state.report].columns : []}
          coldata={reportData}
          tableClassName='table is-striped is-hoverable is-fullwidth'
          emptyTableMarkUp={<span>No hay registros</span>}
          refresh={this.refresh}
        />
      </Box>
    )
  }
}

const mapStateToProps = ({ reports }) => ({
  reportData: reports.reportData
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getReport,
  downloadReport,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Reportes)
