import React from 'react'
import { Output, Check } from 'Components/common/form'
import { Columns, Column, Box } from 'Components/common/bulma'


const Perfil = ({ data }) => {
  return (
    <Box>
      <Columns>
        <Column>
          <Output
            {...data.id}
          />
        </Column>
        <Column>
          <Output
            {...data.integrado}
          />
        </Column>
      </Columns>
      <Columns>
        <Column>
        </Column>
        <Column>
          <Output
            {...data.estatus}
          />
        </Column>
      </Columns>
      <Columns>
        <Column>
          <Output
            {...data.apellidoPaterno}
          />
        </Column>
        <Column>
          <Output
            {...data.apellidoMaterno}
          />
        </Column>
      </Columns>
      <Columns>
        <Column>
          <Output
            {...data.nombre}
          />
        </Column>
        <Column>
          <Output
            {...data.genero}
          />
        </Column>
      </Columns>
      <Columns>
        <Column>
          <Output
            {...data.rfc}
          />
        </Column>
        <Column>
          <Output
            {...data.curp}
          />
        </Column>
      </Columns>
      <Columns>
        <Column>
          <Output
            {...data.email}
          />
        </Column>


        <Column>
          <Output
            {...data.telefono}
          />

        </Column>


      </Columns>
      <Columns>
        <Column>

          <Check
            {...data.poderTitulosCredito}
          />
        </Column>
        <Column>
        </Column>
      </Columns>


    </Box>
  );
};

export default Perfil;