import moment from 'moment'
import numeral from 'numeral'
const limitHour = moment({ hour: 16, minute: 5 })
const minDate = moment() < limitHour ? moment({ hour: 0 }) : moment({ hour: 0 }).add(1, 'day')


export const walletGasoline = {
  provider: {
    name: 'provider',
    label: 'Proveedor',
    disabled: true,
    value: '1',
  },
  estatusOrden: {
    name: 'estatusOrden',
    label: 'Estatus order',
    disabled: true,
    value: '1',
    valueProperty: 'id',
    valueLabel: 'estatus',
  },
  metodoPago: {
    name: 'metodoPago',
    label: 'Método de pago',
    valueProperty: 'id',
    valueLabel: 'metodo',
    disabled: true,
    value: '1',
  },
  divisaMoneda: {
    value: 'MXN',
    name: 'divisaMoneda',
    label: 'Divisa Moneda',
  },
  referencia: {
    value: 8177,
    name: 'referencia',
    label: 'Referencia',
    disabled: true,
  },
  cuentaRetiro: {
    value: 'MXN',
    name: 'cuentaRetiro',
    label: 'Cuenta de Retiro',
  },
  tipoCambio: {
    name: 'tipoCambio',
    label: 'Tipo de Cambio',
  },
  cuentaClabeBeneficiario: {
    valueProperty: 'id',
    valueLabel: 'clabe',
    name: 'cuentaClabeBeneficiario',
    label: 'Cuenta Clabe Beneficiario',
    validation: 'required',
    value: '21',
    disabled: true,
  },
  tipoDePersona: {
    value: '',
    name: 'tipoDePersona',
    label: 'Tipo de Persona',
    disabled: true,
    valueProperty: 'id',
    valueLabel: 'tipoPersona',
  },
  nombreORazonSocial: {
    value: '',
    name: 'nombreORazonSocial',
    label: 'Nombre o razón social',
    disabled: true,
  },
  rfc: {
    value: '',
    name: 'rfc',
    label: 'RFC',
    disabled: true,
  },
  correoElectronico: {
    value: '',
    name: 'correoElectronico',
    label: 'Correo Electrónico',
    disabled: true,
  }
}

export const addressData = {
  direccion: {
    value: '',
    transform: 'stringToBool',
    trigger: 'onChangeDireccion',
    name: 'direccion',
    label: 'Dirección',
    disabled: true,
  },
  pais: {
    name: 'pais',
    value: '',
    validation: 'requiredIf:direccion',
    label: 'País',
    disabled: true
  },
  codigoPostal: {
    value: '',
    trigger: 'onChangeCp',
    pattern: 'number',
    name: 'codigoPostal',
    label: 'Código postal',
    disabled: true,
  },
  entidadFederativa: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'entidadFederativa',
    label: 'Entidad Federativa/Estado/Provincia',
    disabled: true,
  },
  municipio: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'municipio',
    label: 'Municipio',
    disabled: true,
  },
  colonia: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'colonia',
    label: 'Colonia',
    disabled: true,
  },
  calle: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'calle',
    label: 'Calle',
    disabled: true,
  },
  exterior: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'exterior',
    label: 'Exterior',
    disabled: true,
  },
  interior: {
    value: '',
    name: 'interior',
    label: 'Interior',
    disabled: true,
  },
}

export const paymentTable = [
  {
    title: 'id',
    datakey: 'id',
  },
  {
    title: 'Fecha Transacción',
    datakey: 'fechaTransaccion',
  },
  {
    title: 'Estatus',
    datakey: 'estatusTexto',
  },
  {
    title: 'Cargo',
    datakey: 'cargo',
  },
  {
    title: 'Clave Rastreo',
    datakey: 'claveRastreo',
  },
  {
    title: 'Referencia de Banco',
    datakey: 'referenciaBanco',
  },
  {
    title: 'Acciones',
    custom: 'acciones',
  }
]

export const concept = {
  monederoGasolina: {
    value: '',
    name: 'monederoGasolina',
    label: 'Monedero de Gasolina',
    validation: 'required',
    valueProperty: 'id',
    valueLabel: 'noTarjeta',
  },
  subtotal: {
    name: 'subtotal',
    label: 'Neto a transferir',
    pattern: 'number',
    value: '',
    transform: 'stringToNumber',
    validation: 'required',
  }
}

export const conceptColumns = [
  {
    title: 'Monedero Gasolina',
    datakey: 'tarjeta',
  },
  {
    title: 'Subtotal',
    datakey: 'subtotal',
    format: (value) => numeral(value).format('$ 0,0.00'),
  },
  {
    title: 'Acciones',
    custom: 'acciones',
  }
]

export const installment = {
  divisaMoneda: {
    name: 'divisaMoneda',
    label: 'Moneda pago',
    value: 'MXN',
  },
  cargo: {
    label: 'Cargos',
    name: 'cargo',
    validation: 'required|greaterThan:0',
    pattern: 'number',
    value: 0,
    disabled: true,
  },
  fechaTransaccion: {
    label: 'Fecha de Transacción',
    name: 'fechaTransaccion',
    validation: `required|gtOrEqDate:${minDate.format('YYYY-MM-DD')}`,
    value: minDate.toDate(),
  },
  estatus: {
    label: 'Estatus',
    name: 'estatus',
    disabled: true,
    valueProperty: 'id',
    valueLabel: 'estatus',
    value: '1',
  },
  idOrden: {
    label: 'Orden Pago',
    name: 'idOrden',
    value: '',
  },
  referencia: {
    label: 'Referencia Banco',
    name: 'referencia',
    value: '',
  },
  metodoPago: {
    label: 'Metódo de pago',
    name: 'metodoPago',
    value: '',
    valueProperty: 'id',
    valueLabel: 'metodo',
    disabled: true,
  },
  cuentaMxn: {
    label: 'Cuenta MXN',
    name: 'cuentaMxn',
    value: '',
    disabled: true,
    valueProperty: 'id',
    valueLabel: 'clabe',
  },
  claveRastreo: {
    label: 'Clave de rastreo',
    name: 'claveRastreo',
    value: '',
  },
  typePaymentProvider: {
    label: 'Tipo de Pago',
    name: 'typePaymentProvider',
    value: '',
  }
}

export const totals = {
  subtotal: {
    name: 'subtotal',
    label: 'Subtotal',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  IVA: {
    name: 'IVA',
    label: 'IVA',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  ieps: {
    name: 'ieps',
    label: 'IEPS',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  montoPagado: {
    name: 'montoPagado',
    label: 'Monto Pagado',
    value: 0,
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  montoPagoTransito: {
    name: 'montoPagoTransito',
    label: 'Monto Pago Tránsito',
    value: 0,
    format: (value) => numeral(Math.round(+value * 100) / 100).format('$ 0,0.00'),
    align: 'right',
  },
  montoPagar: {
    name: 'montoPagar',
    label: 'Monto por Pagar',
    value: 0,
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  estatusFacturaProveedor: {
    name: 'estatusFactura',
    label: 'Estatus Factura Proveedor',
    valueProperty: 'id',
    valueLabel: 'estatus',
    disabled: true,
    value: '3',
  },
  total: {
    name: 'total',
    label: 'Total',
    value: 0,
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
}

export const monederoGasolinaColData =
  [
    {
      title: 'ID',
      datakey: 'id',
      sortable: true,
      width: '10%',
    },
    {
      title: 'Estatus Orden',
      datakey: 'estatusTexto',
      sortable: true,
      width: '20%',
    },
    {
      title: 'Nombre o Razón Social',
      datakey: 'nombreRazonSocial',
      sortable: true,
      width: '40%',
    },
    {
      title: 'Total',
      datakey: 'total',
      format: '$ 0,0.00',
      sortable: true,
      width: '20%',
    },
    {
      title: 'Acciones',
      custom: 'actions',
      width: '10%',
    }
  ];
export const paidMonederoGasolinaColData =
  [
    {
      title: 'Id Orden Pago',
      datakey: 'idOrdenPago',
      sortable: true,
      width: '10%',
    },
    {
      title: 'Monedero de Gasolina',
      datakey: 'monederoGasolina',
      sortable: true,
      width: '20%',
    },
    {
      title: 'Neto a depositar',
      datakey: 'netoDepositar',
      format: '$ 0,0.00',
      sortable: true,
      width: '10%',
    },
    {
      title: 'Acciones',
      custom: 'actions',
      width: '10%',
      align: 'center',
    }
  ];
export const procesingMonederoGasolinaColData =
  [
    {
      title: 'Id Orden Pago',
      datakey: 'idOrdenPago',
      sortable: true,
      width: '10%',
    },
    {
      title: 'Monedero de Gasolina',
      datakey: 'monederoGasolina',
      sortable: true,
      width: '20%',
    },
    {
      title: 'Neto a depositar',
      datakey: 'netoDepositar',
      format: '$ 0,0.00',
      sortable: true,
      width: '10%',
    },
    {
      title: 'Acciones',
      custom: 'actions',
      width: '10%',
      align: 'center',
    }
  ];
export const rejectedMonederoGasolinaColData =
  [
    {
      title: 'Id Orden Pago',
      datakey: 'idOrdenPago',
      sortable: true,
      width: '10%',
    },
    {
      title: 'Monedero de Gasolina',
      datakey: 'monederoGasolina',
      sortable: true,
      width: '20%',
    },
    {
      title: 'Neto a depositar',
      datakey: 'netoDepositar',
      format: '$ 0,0.00',
      sortable: true,
      width: '10%',
    },
    {
      title: 'Acciones',
      custom: 'actions',
      width: '10%',
      align: 'center',
    }
  ];