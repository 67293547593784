import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store'
import App from 'Containers/app'
import { initAuth } from 'Modules/auth'
import { initLanguage } from 'Modules/i18n'

import 'sanitize.css/sanitize.css'
import './styles/index.scss'
import 'bulma-divider/dist/css/bulma-divider.min.css'
import 'bulma-tagsinput/dist/css/bulma-tagsinput.min.css'
import 'bulma-tagsinput/dist/js/bulma-tagsinput.min.js'
import 'bulma-tooltip/dist/css/bulma-tooltip.min.css'
import 'bulma-switch/dist/css/bulma-switch.min.css'
import 'react-datepicker/dist/react-datepicker.css'

require('promise.prototype.finally')
const target = document.querySelector('#root')
store.dispatch(initAuth())
store.dispatch(initLanguage())

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  target
)
