import React, { useEffect, useState, useCallback } from 'react'
import { Box, Button, Columns, Column, Icon, SubTitle } from 'Components/common/bulma'
import { default as WidgetForm } from './ConfigurationWidget'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import Table from 'Components/common/table'
import { getWidgetUser, addWidgetUser, getWidgetList, deleteWidget, cleanWidget } from 'Modules/widget'
import { widgetListColumns } from './constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { ModalRemove } from 'Components/common'
import { saveAs } from 'file-saver'
import { getDocument } from 'Modules/principal'
import { faFilePdf, } from '@fortawesome/free-solid-svg-icons'
import Loader from 'Components/common/page/loader';


function WidgetList({ push, widgetUser, getWidgetUser, addWidgetUser, getWidgetList, widgetList,
  deleteWidget,
  getDocument,
  cleanWidget,
}) {
  const [isWidgetDeleting, setIsWidgetDeleting] = useState(false)
  const [widgetRemoving, setWidgetRemoving] = useState(undefined)
  const [isLoading, setIsloading] = useState(true)

  useEffect(() => {
    if (!widgetUser) {
      getWidgetUser()
        .then(() => {
          setIsloading(false)
        })
    }
  }, [getWidgetUser, widgetUser])

  useEffect(() => {
    cleanWidget()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchWidgetList = useCallback((params) => {
    return getWidgetList(params)
  }, [getWidgetList])

  const onCreate = () => {
    push('/widget/nuevo')
  }

  const onActivateWidget = () => {
    addWidgetUser()
  }

  const closeModal = () => {
    setIsWidgetDeleting(false)
  }

  const removeWidget = id => {
    deleteWidget(id)
      .then(() => {
        setWidgetRemoving(undefined)
        setIsWidgetDeleting(false)
      })
  }

  const setRemovingWidget = ({ id }) => {
    setWidgetRemoving(id)
    setIsWidgetDeleting(true)
  }

  const downloadFile = ({ path, basename }) => {
    getDocument(path)
      .then(response => {
        saveAs(new Blob([response.data]), basename)
      })
  }

  const renderCell = row =>
  (<Button small className="margin-left-xs" danger outlined onClick={() => setRemovingWidget(row)}>
    <Icon>
      <FontAwesomeIcon icon={faTrash} />
    </Icon>
  </Button>)

  return (
    <Box>
      <ModalRemove
        isDeleting={isWidgetDeleting}
        title="Eliminar widget"
        toggleModal={closeModal}
        confirmText="¿Esta seguro de querer eliminar el widget?"
        resource={widgetRemoving}
        deleteResource={() => removeWidget(widgetRemoving)}
      />
      {(!widgetUser && !isLoading) && <Columns>
        <Column className="has-text-centered">
          <Button
            className="is-medium"
            onClick={onActivateWidget}>
            Activar el uso de Widgets de pago
          </Button>
        </Column>
      </Columns>}
      {(!widgetUser && isLoading) && <Loader />}
      {(widgetUser && widgetUser.id) && <Columns className="is-multiline">
        <Column className="is-half">
          <Button onClick={() => downloadFile({
            path: 'estaticos/ayuda_widget/manual_integracion_widget.pdf',
            basename: 'manual_integracion_widget.pdf'
          })}>
            <Icon className="is-size-3">
              <FontAwesomeIcon icon={faFilePdf} />
            </Icon>
            <SubTitle is='7'>Manual de implementacion</SubTitle>
          </Button>
        </Column>
        <Column className="is-half">
          <Button className="is-pulled-right"
            onClick={onCreate}> Crear widget </Button>
        </Column>
        <Column className="isFull">
          <Table
            colsetup={widgetListColumns}
            coldata={widgetList}
            tableClassName='table is-striped is-hoverable is-fullwidth'
            emptyTableMarkUp={<span>No hay registros</span>}
            customCol={{
              customColName: 'actions',
              renderFunc: renderCell,
            }}
            refresh={fetchWidgetList}
          />
        </Column>
      </Columns>}
    </Box>
  )
}

const mapStateToProps = ({ widget }) => ({
  widgetUser: widget.widgetUser,
  widgetList: widget.widgetList,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  push,
  getWidgetUser,
  addWidgetUser,
  getWidgetList,
  deleteWidget,
  getDocument,
  cleanWidget,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(WidgetList)

export { WidgetForm }
