import OnboardingFileRepository from '../domain/onboardingFileRepository';
import httpService from '../../../services/httpService';

export default class RemoteOnboardingFileRepository extends OnboardingFileRepository {
  async upload(onboardingFile) {
    const formData = new FormData();
    formData.append("file", onboardingFile.content, onboardingFile.name);

    const { data } = await httpService.post('/onboarding/upload', formData)
    return data.data
  }
}
