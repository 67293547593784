import React, { Fragment } from 'react'
import { Columns, Column } from '../../common/bulma';
import { Select, Input } from '../../common/form';
import { getLastyears } from '../../../helpers';

const lastTenyears = getLastyears(10);

const DatosVehiculo = ({ onChange, showErrors, monederoGasolinaData }) => {
  const { marca, modelo, version, color } = monederoGasolinaData;

  return (
    <Fragment >
      <Columns>
        <Column>
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...marca}
          />
        </Column>
        <Column>
          <Select
            onChange={onChange}
            options={lastTenyears}
            showErrors={showErrors}
            {...modelo}
          />
        </Column>
      </Columns>
      <Columns>
        <Column>
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...version}
          />
        </Column>
        <Column>
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...color}
          />
        </Column>
      </Columns>
    </Fragment>
  )
}

export default DatosVehiculo;
