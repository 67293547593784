import GeneralDataRepository from '../domain/generalDataRepository';
import httpService from '../../../services/httpService';

export default class RemoteGeneralDataRepository extends GeneralDataRepository {
  async save(generalData) {
    const { data } = await httpService.post('/onboarding/general-data', generalData)
    return data
  }
  async get() {
    const { data } = await httpService.get('/onboarding/general-data')
    return data.data
  }
}
