import React, { useState, useEffect } from 'react'
import { Button, Field } from 'Components/common/bulma'
import { Input } from 'Components/common/form'
import { ModalRemove, InformationModal } from 'Components/common'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createCreditAndPartialRefundAction } from 'Modules/incomeOrders/creditNote'
import { addNotification as addNotificationAction } from 'Modules/principal';
import { validator } from 'App/helpers'

import numeral from 'numeral'

function CancelOrder({
  createCreditAndRefundAction,
  order,
  options = {},
  addNotification,
  onClose,
}) {
  const [monto, setMonto] = useState(0)
  const [limit, setLimit] = useState(0)
  const [montoErrors, setMontoErrors] = useState([])
  const [clabe, setClabe] = useState('')
  const [clabeErrors, setClabeErrors] = useState([])
  const [isConfirming, setIsConfirming] = useState(false)
  const [isCanceled, setIsCanceled] = useState(false)
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    const collectedAmount = options.Generar_Nota_de_Crédito_y_Devolver_Fondos_al_Cliente_monto_cobrado_parcial.Monto_Cobrado_Efectivo
    setLimit(collectedAmount)
  }, [options])

  const confirmCancel = () => {
    const validatedClabe = validator(clabe, 'isLength:18')
    console.log(validatedClabe);
    if (+monto > +limit) {
      setMontoErrors([{
        message: 'El monto debe ser menor al monto por cobrar de la factura.'
      }])
    }
    if (!validatedClabe.valid) {
      setClabeErrors(validatedClabe.errors)
    }
    if ((+monto > +limit) || !validatedClabe.valid) {
      setShowError(true)
      return
    }

    setIsConfirming(true)
  }

  const closeConfirm = () => {
    setIsConfirming(false)
  }

  const handleOnCancel = () => {
    setIsConfirming(false)
    createCreditAndRefundAction({ clabe, monto: +monto }).then(() => {
      setIsCanceled(true)
    }).catch(({ response: { data } }) => {
      addNotification({
        type: 'danger',
        message: data.message,
      })
    })
  }

  const closeCanceledModal = () => {
    setIsCanceled(false)
    onClose(true)
  }

  const handleOnChangeMonto = ({ target: { value } }) => {
    const validatedMonto = validator(value, 'number')
    setShowError(false)
    setMontoErrors([])
    if (validatedMonto.valid) {
      setMonto(value)
    }
  }
  const handleOnChangeClabe = ({ target: { value } }) => {
    const validatedClabe = validator(value, 'number')
    setShowError(false)
    setClabeErrors([])
    if (validatedClabe.valid) {
      setClabe(value)
    }
  }
  return (
    <>
      <InformationModal
        isOpen={isCanceled}
        closeModal={closeCanceledModal}
        title="Nota de crédito emitida"
        content="Petición recibida, en las próximas horas se emitirá la nota de crédito."
      />
      <ModalRemove
        isDeleting={isConfirming}
        title="Emitir nota de crédito"
        toggleModal={closeConfirm}
        confirmText="Se procederá a crear la nota de crédito"
        resource={order.id}
        deleteResource={handleOnCancel}
        actionButtonLabel="Emitir"
        cancelButtonLabel="Regresar"
      />
      <div>
        <div>Se emitirá una Nota de Crédito y se devolverán los fondos indicados al cliente por transferencia electrónica, se deberá capturar la cuenta clabe del cliente para su retorno y el importe a devolver. El importe a devolver debe ser menor a:
          {numeral(limit).format('$ 0,0.00')}
        </div>
        <div>
          <Field className="is-horizontal">
            <Input
              label="Monto Impuestos Incluidos"
              value={monto}
              onChange={handleOnChangeMonto}
              errors={montoErrors}
              showErrors={showError}
            />
          </Field>
          <Field className="is-horizontal">
            <Input
              label="Cuenta Clabe para retorno al cliente"
              value={clabe}
              onChange={handleOnChangeClabe}
              errors={clabeErrors}
              showErrors={showError}
              maxLength={18}
            />
          </Field>
          <Field>
            <Button onClick={confirmCancel}>
              Generar nota de crédito
            </Button>
          </Field>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ incomeOrders: { invoice } }) => ({
  order: invoice.order,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  createCreditAndRefundAction: createCreditAndPartialRefundAction,
  addNotification: addNotificationAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CancelOrder)
