import React from 'react';
import { Columns, Column, Box, Divider } from '../../common/bulma';
import { Select } from '../../common/form';
import DatosVehiculo from './datosDelVehiculo';
import CentroCostoToka from './centroDeCostoToka';
import SolicitudMonederoToka from './solicitudDeMonederoToka';
import './catalogoMonederoGasolina.css';
import LoaderHOC from 'Components/HOC/LoaderHOC';

const CatalogoMonederoGasolina = ({ onChange, showErrors, estatusPersona, monederoGasolinaData }) => {
  const { estatus, estatusEdenred } = monederoGasolinaData;

  const commonProps = {
    monederoGasolinaData,
    onChange,
    showErrors
  }

  return (
    <Box>
      <form className='catalogoMonederoGasolina'>
        <Columns>
          <Column className='hide'>
            <Select
              onChange={onChange}
              options={[]}
              showErrors={showErrors}
            />
          </Column>
          <Column>
            <Select
              onChange={onChange}
              options={estatusPersona}
              showErrors={showErrors}
              valueProperty="id"
              valueLabel="estatus"
              {...estatus}
            />
          </Column>
          <Column>
            <Select
              onChange={onChange}
              // options={[]} // hardcoded in monederoGasolinaData.js
              showErrors={showErrors}
              {...estatusEdenred}
            />
          </Column>
        </Columns>
        <Divider content="Datos del Vehiculo" />
        <DatosVehiculo
          {...commonProps}
        />
        <Divider content="Centro de Costo Toka" />
        <CentroCostoToka
          {...commonProps}
        />
        <Divider content="Solicitud de Monedero Toka" />
        <SolicitudMonederoToka
          showErrors={showErrors}
          onChange={onChange}
          data={monederoGasolinaData}
        />
      </form>
    </Box>
  );
}

export default LoaderHOC(CatalogoMonederoGasolina);
