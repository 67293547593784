import React from 'react'
import { Columns, Column } from 'Components/common/bulma'
import { Input } from 'Components/common/form'

const ClabeAccount = ({ data, onChange, showErrors }) => {
  return (
    <section>
      <Columns>
        <Column>
        <Input
            onChange={onChange}
            showErrors={showErrors}
            {...data.cuentaClabeSTPAsignada}
          />
        </Column>
        <Column>
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...data.banco}
          />
        </Column>
      </Columns>
      <Columns>
        <Column className="is-half">
        <Input
            onChange={onChange}
            showErrors={showErrors}
            {...data.convenioCIEBancomer}
          />
        </Column>
      </Columns>
    </section>
  );
};

export default ClabeAccount