import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { Box, Icon, Modal, Message, Delete, Columns, Column } from 'Components/common/bulma'
import Table from 'Components/common/table'
import { getMonederoGasolina, deleteWallet } from 'Modules/catalogos'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Trans } from '@lingui/macro'
import { monederoGasolinaColData } from './colSetup'
import { Button } from 'Components/common/bulma'
import { addNotification } from 'Modules/principal'


export class ListaContainer extends Component {
  state = {
    showModal: false,
    wallet: {},
  }

  onEdit = (id) => {
    const { push } = this.props;
    push(`/catalogos/monederos-gasolina/${id}`);
  }

  emptyTableMarkUp = () => (
    <span>No hay datos disponibles</span>
  )

  toggleModal = (wallet) => {
    this.setState(state => ({
      showModal: !state.showModal,
      wallet,
    }))
  }

  deleteWallet = () => {
    this.props.deleteWallet(this.state.wallet.id)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'El monedero se eliminó correctamente'
        })
        this.setState({
          showModal: false,
        })
      })
  }

  customColAccions = (row) => (
    <Fragment >
      <Button small primary outlined onClick={() => this.onEdit(row.id)}>
        <Icon>
          <FontAwesomeIcon icon={faEdit} />
        </Icon>
      </Button>
      <Button small className="margin-left-xs" danger outlined onClick={() => this.toggleModal(row)}>
        <Icon>
          <FontAwesomeIcon icon={faTrash} />
        </Icon>
      </Button>
    </Fragment>
  )

  render() {
    const { monederoGasolina } = this.props

    return (
      <Box>
        <Modal isActive={this.state.showModal}>
          <Message danger>
            <Message.Header>
              <p>Eliminar monedero de gastos</p>
              <Delete onClick={this.toggleModal} />
            </Message.Header>
            <Message.Body className="has-text-centered">
              ¿Esta seguro de querer eliminar el monedero?
            <br />
              <strong>{this.state.wallet.id}</strong>
              <div className="margin-top-lg">
                <Button danger onClick={this.deleteWallet}>Eliminar</Button>
                <Button default className="margin-left-sm" onClick={this.toggleModal}>
                  Cancelar
              </Button>
              </div>
            </Message.Body>
          </Message>
        </Modal>
        <Columns>
          <Column>
            <Link className="button is-success is-outlined is-pulled-right" to="/catalogos/monederos-gasolina/nuevo">
              <Icon>
                <FontAwesomeIcon icon={faPlus} />
              </Icon>
              <Trans render="span">Agregar Monedero Gasolina</Trans>
            </Link>
          </Column>
        </Columns>

        <Columns>
          <Column>
            <Table
              colsetup={monederoGasolinaColData}
              coldata={monederoGasolina}
              filterable
              tableClassName='table is-striped is-hoverable is-fullwidth'
              emptyTableMarkUp={this.emptyTableMarkUp()}
              customCol={{
                customColName: 'acciones',
                renderFunc: this.customColAccions
              }}
              refresh={this.props.getMonederoGasolina}
            />
          </Column>
        </Columns>

      </Box>
    )
  }
}

const mapStateToProps = ({ catalogos }) => ({
  monederoGasolina: catalogos.monederoGasolina,
  wallet: catalogos.wallet,
  isFetching: catalogos.isFetchingGas
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getMonederoGasolina,
  push,
  deleteWallet,
  addNotification,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ListaContainer)
