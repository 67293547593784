import React, { useState, useEffect, useCallback } from 'react'
import TableHeader from './tableHeader'
import TableFilter from './tableFilter'
import TableRow from './tableRow'
import { Table as BTable, Button } from 'Components/common/bulma'
import classNames from 'classnames'

const Table = ({ colsetup,
  coldata,
  className = '',
  emptyTableMarkUp,
  customCol,
  refresh,
  id,
  filterable,
  style,
  initialFetch = true,
}) => {
  const [sortType, setSortType] = useState('DESC')
  const [orderBy, setOrderBy] = useState(undefined)
  const [queryInput, setQueryInput] = useState('')
  const [query, setQuery] = useState(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const { data, ...meta } = coldata

  const fetchData = useCallback((params) => {
    if (refresh && initialFetch) {
      refresh(params)
        .finally(setIsLoading.bind(false))
    } else {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFetch])

  useEffect(() => {
    fetchData({})
  }, [fetchData])

  const sort = (field) => {
    setIsLoading(true)
    let direction = 'ASC';
    if (field === orderBy) {
      direction = sortType === 'ASC' ? 'DESC' : 'ASC';
    }

    fetchData({
      page: meta.current_page,
      orderBy: field,
      sortType: direction,
      query,
    })
    setSortType(direction)
    setOrderBy(field)
  }

  const paginate = (page) => {
    setIsLoading(true)
    fetchData({
      page,
      orderBy,
      sortType,
      query
    })
  }

  const handleInput = ({ target: { value } }) => {
    setQueryInput(value)
  }

  const filter = () => {
    if (!queryInput) {
      return
    }
    setIsLoading(true)
    fetchData({
      page: meta.current_page,
      orderBy,
      sortType,
      query: queryInput,
    })
    setQuery(queryInput)
  }
  const cleanQuery = () => {
    if (!queryInput) {
      return
    }
    setIsLoading(true)
    fetchData({
      page: meta.current_page,
      orderBy,
      sortType,
      query: '',
    })
    setQueryInput('')
    setQuery('')
  }

  const tableBody = coldata.data.map((row, i) =>
  (
    <TableRow
      key={`table-row-${i}`}
      colsetup={colsetup}
      row={row}
      customCol={customCol}
    />
  ));

  return (
    <div className='table' style={{ overflow: 'scroll' }}>
      {filterable && <TableFilter
        handleInput={handleInput}
        queryInput={queryInput}
        filter={filter}
        cleanQuery={cleanQuery}
      />}
      <table className={classNames('table is-fullwidth is-striped', className)}
        id={id}
        style={style}
      >
        <thead style={{ display: 'table', width: '100%' }}>
          <TableHeader
            colsetup={colsetup}
            sort={sort}
            meta={{ orderBy, sortType }}
          />
        </thead>
        <tbody className={isLoading ? 'loader-table' : ''} style={{ position: 'relative', display: 'table', width: '100%' }}>
          {tableBody}
          {!!coldata.data.length || (
            <BTable.Tr>
              <BTable.Td colSpan={colsetup.length + 1} className="has-text-centered">
                {emptyTableMarkUp}
              </BTable.Td>
            </BTable.Tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={colsetup.length + 1}>
              <nav className="pagination is-small" aria-label="pagination">
                <Button
                  onClick={() => paginate(meta.current_page - 1)}
                  className="pagination-previous"
                  disabled={meta.current_page === 1}>
                  Anterior
                  </Button>
                <Button className="pagination-next" disabled={meta.current_page === meta.last_page}
                  onClick={() => paginate(meta.current_page + 1)}
                >Siguiente</Button>
                <ul className="pagination-list">
                  <li>
                    <span>Página</span>
                  </li>
                  <li>
                    <Button className="pagination-link" aria-label="Goto page 1">{meta.current_page || 0}</Button>
                  </li>
                  <li>
                    <span>de</span>
                  </li>
                  <li>
                    <Button className="pagination-link" aria-label="Goto page 1">{meta.last_page || 0}</Button>
                  </li>
                </ul>
              </nav>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default Table;
