import ForeignPersonalInfoRepository from "../domain/foreignPersonalInfoRepository";
import httpService from '../../../services/httpService';


export default class RemoteForeignPersonalInfoRepository extends ForeignPersonalInfoRepository {
  async get() {
    const { data } = await httpService.get('onboarding/personal-data/foreign')
    return data.data
  }
  async save(foreignPersonalInfo) {
    return await httpService.post('onboarding/personal-data/foreign', foreignPersonalInfo)
  }
  async update(foreignPersonalInfo) {
    return await httpService.put('onboarding/personal-data/foreign', foreignPersonalInfo)
  }
}


