import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { Box, SubTitle, Divider, Columns, Column, Button, Icon, Modal, Message, Delete, } from 'Components/common/bulma'
import { ActionBar, Table, ModalRemove } from 'Components/common'
import { Select, } from 'Components/common/form'
import {
  generalData, conceptsCol, addressData, paymentData,
  totals, conceptData,
  installmentsCol,
} from './data'
import {
  getDataInput, mapPropsToState, getValues, validateData,
} from 'App/helpers'
import { getProviders, getProvider, getProviderAccounts } from 'Modules/catalogs/providers'
import { getCollaborators, getAccounts } from 'Modules/catalogs/collaborators'

import {
  deleteInstallment,
  authorizeInstallment,
  getInstallments,
  endOrderEdit,
  updateOrder,
  deleteOrder,
  addInstallment,
  getOrder,
  createOrder,
  getRandomProvider,
  getCommission,
  getAttachments,
} from 'Modules/paymentOrders/salaries'
import { cleanOrigin } from 'Modules/paymentOrders/providers'
import {
  getTypePaymentProviders,
  getPaymentEstatus,
  getPaises,
  getEntidades,
  getRealPaymentMethod,
  getProviderInvoiceStatus,
  clearInvoiceUnits,
  getIvaRates,
} from 'Modules/catalogos'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrash,
  faKey,
  faEdit,
} from '@fortawesome/free-solid-svg-icons'
import { AddressSection, } from 'Components/catalogs/providers'
import PaymentData from 'Components/paymentOrders/receiptFee/PaymentData'
import Totals from 'Components/paymentOrders/salaries/Totals'
import Concept from 'Components/paymentOrders/salaries/Concept'
import Installment from 'Components/paymentOrders/salaries/Installment'
import { prop, propEq, find, reduce, reject, add, equals } from 'ramda'
import { addNotification } from 'Modules/principal'
import { v4 as uuid } from 'uuid'
import moment from 'moment'
import { Authorize, Can } from 'Containers/auth'
import Attachments from 'Containers/paymentOrders/attachments'

const STATUS_CREATED = '1'
const STATUS_PARTIAL_AUTHORIZED = '9'
const INVOICE_APPROVED = '3'
const AUTHORIZE_PERMISSION = ['Apago de proveedores']

export class Form extends Component {

  state = {
    receipt: undefined,
    showErrors: false,
    generalData,
    addressData,
    paymentData,
    conceptos: {
      data: []
    },
    totals,
    isAddingConcept: false,
    conceptData,
    order: {},
    isRemovingConcept: false,
    isAddingInstallment: false,
    isRemovingInstallment: false,
    showAuth: false,
  }

  componentDidMount() {
    const { getInstallments, getRandomProvider, getCollaborators, getCommission, getAttachments } = this.props
    // this.props.getProviders({ size: 1000 })
    this.props.getTypePaymentProviders()
    this.props.getPaymentEstatus()
    this.props.getPaises()
    this.props.getEntidades()
    this.props.getRealPaymentMethod()
    this.props.getProviderInvoiceStatus()
    this.props.getIvaRates()
    getCollaborators({ size: 5000 })
    getCommission()

    const id = this.props.match.params.id
    if (id && (id !== this.state.order)) {
      this.props.getOrder(id)
      getInstallments(id)
      getAttachments(id)
    } else {
      getRandomProvider()
    }

  }
  componentWillReceiveProps(nextProps) {

    if (nextProps.provider !== this.props.provider) {
      const addressData = mapPropsToState(this.state.addressData, nextProps.provider)
      const generalData = mapPropsToState(this.state.generalData, { idProveedor: nextProps.provider.id })

      this.props.getProviderAccounts(nextProps.provider.id, { size: 1000 })

      this.setState({
        addressData,
        generalData,
      })
    }

    if (nextProps.order.id && nextProps.order.id !== this.state.order.id) {
      const generalData = mapPropsToState(this.state.generalData, nextProps.order)
      const addressData = mapPropsToState(this.state.addressData, nextProps.order)
      const paymentData = mapPropsToState(this.state.paymentData, nextProps.order)
      const totals = mapPropsToState(this.state.totals, nextProps.order)

      this.props.getProviderAccounts(nextProps.order.idProveedor, { size: 1000 })
      this.setState({
        order: nextProps.order,
        generalData,
        addressData,
        paymentData,
        totals,
        conceptos: {
          data: nextProps.order.conceptos
        }
      })
    }
  }
  componentWillUnmount() {
    this.props.endOrderEdit()
    this.props.cleanOrigin()
  }

  getPermissions = () => {
    return {
      canAuthorize: false,
      canDelete: (this.state.order && this.state.order.id && this.state.order.estatusOrden === STATUS_CREATED),
      canSave: true,
    }
  }

  onChangeInput = ({ target }, section) => {
    const { name } = target
    const data = getDataInput(target, this.state[section])
    const field = data[name]

    this.setState({
      [section]: data,
      showErrors: false,
      showAccountErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }

  customColActions = (row, idx) => (
    <Button danger outlined
      onClick={() => this.openConfirmRemoveConceptModal(row)}
      disabled={this.state.loadedFromInvoice || this.props.installments.data.length}>
      <Icon>
        <FontAwesomeIcon icon={faTrash} />
      </Icon>
    </Button>
  )

  installmentActions = (row, idx) => (
    <div>
      <Button outlined
        primary
        onClick={() => this.openInstallmentModal(row)}>
        <Icon>
          <FontAwesomeIcon icon={faEdit} />
        </Icon>
      </Button>
      <Can validate={AUTHORIZE_PERMISSION}>
        <Button danger outlined className="margin-left-xs"
          disabled={!(row.estatus === STATUS_CREATED || row.estatus === STATUS_PARTIAL_AUTHORIZED)}
          onClick={() => this.onAuthorize(row)}>
          <Icon>
            <FontAwesomeIcon icon={faKey} />
          </Icon>
        </Button>
      </Can>
      <Button danger outlined className="margin-left-xs"
        disabled={(row.estatus !== STATUS_CREATED)}
        onClick={() => this.openConfirmRemoveInstallment(row)}>
        <Icon>
          <FontAwesomeIcon icon={faTrash} />
        </Icon>
      </Button>
    </div>
  )

  createOrder = (event, cb) => {
    const { data: generalData, hasErrors: hasErrorsGeneralData } = validateData(this.state.generalData)
    const { data: paymentData, hasErrors: hasErrorsPaymentData } = validateData(this.state.paymentData)

    if (hasErrorsGeneralData || hasErrorsPaymentData || !this.state.conceptos.data.length) {
      this.setState({
        generalData,
        paymentData,
        showErrors: true,
      })
      this.props.addNotification({
        type: 'danger',
        message: '¡Favor de validar algunos campos y que tengas conceptos!'
      })
      return
    }
    const payload = {
      id: this.state.order.id,
      ...getValues(this.state.generalData),
      ...getValues(this.state.addressData),
      ...getValues(this.state.paymentData),
      ...getValues(this.state.totals),
      conceptos: this.state.conceptos.data,
    }
    if (this.state.order.id) {
      this.props.updateOrder(payload)
        .then((data) => {
          this.props.addNotification({
            type: 'success',
            message: '¡La orden se actualizó correctamente!'
          })
          const generalData = mapPropsToState(this.state.generalData, data)
          const addressData = mapPropsToState(this.state.addressData, data)
          const paymentData = mapPropsToState(this.state.paymentData, data)
          const totals = mapPropsToState(this.state.totals, data)

          this.setState({
            order: data,
            generalData,
            addressData,
            paymentData,
            totals,
          })
        })
        .catch(({ response: { data } }) => {
          this.props.addNotification({
            type: 'danger',
            message: data.message
          })
        })
    } else {
      this.props.createOrder(payload)
        .then(data => {
          this.props.addNotification({
            type: 'success',
            message: '¡La orden se creó correctamente!'
          })
          this.props.push(`/ordenes-pago/asimilados-salarios/${data.id}`)
          cb && cb()
        })
        .catch(({ response: { data } }) => {
          this.props.addNotification({
            type: 'danger',
            message: data.message
          })
        })
    }
  }

  onCancel = () => {
    const { push, origin } = this.props
    const isFromPending = equals('pending')(origin)
    const isFromProcesingSalaries = equals('procesingSalaries')
    const isFromPaidSalaries = equals('paidSalaries')
    const isFromRejectedSalaries = equals('rejectedSalaries')
    const isFromPendingToPay = equals('pendingToPay')

    if (isFromPending) {
      push('/ordenes-pago/pendientes')
      return
    }

    if (isFromProcesingSalaries(origin)) {
      push('/ordenes-pago/asimilados-salarios/en-proceso')
      return
    }

    if (isFromRejectedSalaries(origin)) {
      push('/ordenes-pago/asimilados-salarios/rechazados')
      return
    }

    if (isFromPaidSalaries(origin)) {
      push('/ordenes-pago/asimilados-salarios/pagados')
      return
    }
    if (isFromPendingToPay(origin)) {
      push('/ordenes-pago/pendientes-pago')
      return
    }

    push('/ordenes-pago/asimilados-salarios')
  }

  updateTotals = () => {
    const totals = reduce((acc, cur) => {
      return {
        total: add(prop('total')(acc), prop('total')(cur)),
        montoPagar: add(prop('total')(acc), prop('total')(cur)),
        subtotal: add(prop('subtotal')(acc), prop('subtotal')(cur)),
        IVA: add(prop('IVA')(acc), prop('iva')(cur)),
        netoDepositar: add(prop('netoDepositar')(acc), prop('netoPagar')(cur)),
      }
    },
      {
        total: 0,
        subtotal: 0,
        IVA: 0,
        montoPagar: 0,
        netoDepositar: 0,
      }
    )(this.state.conceptos.data)
    this.setState(({ totals: totalsState }) => ({ totals: mapPropsToState(totalsState, totals) }))
  }

  openConceptModal = () => this.setState({ isAddingConcept: true })

  closeConceptModal = () => this.setState({ isAddingConcept: false })

  openConfirmRemoveConceptModal = (conceptRemoving) => this.setState({
    conceptRemoving,
    isRemovingConcept: true
  })

  closeConfirmRemoveConceptModal = () => this.setState({
    conceptRemoving: undefined,
    isRemovingConcept: false
  })

  openConfirmRemoveOrder = () => this.setState({
    isRemovingOrder: true,
  })

  closeConfirmRemoveOrder = () => this.setState({
    isRemovingOrder: false
  })

  openInstallmentModal = ({ id, estatus, estatusTexto, cargo, fechaTransaccion } = {}) => {
    const getValue = prop('value')
    const getId = prop('id')
    const limitHour = moment({ hour: 16, minute: 5 })
    const minDate = moment() < limitHour ? moment({ hour: 0 }) : moment({ hour: 0 }).add(1, 'day')
    const method = find(propEq('id', this.props.order.metodoPago))(this.props.realPaymentMethod)
    const clabe = find(propEq('id', this.props.order.cuentaClabeBeneficiario))(this.props.accounts.data)

    if (this.state.order.id) {
      this.setState({
        isAddingInstallment: true,
        installment: id,
        orderData: {
          id,
          divisaMoneda: getValue(this.state.paymentData.divisaMoneda),
          status: estatus,
          estatus: estatusTexto,
          cargo: id ? cargo : getValue(this.state.totals.montoPagar),
          idOrden: getId(this.state.order),
          referencia: getValue(this.state.paymentData.referencia),
          fechaTransaccion: id ? new Date(fechaTransaccion) : minDate.toDate(),
          metodoPago: prop('metodo')(method),
          integrado: this.props.partner,
          provider: getValue(this.state.generalData.idProveedor),
          cuentaMxn: prop('clabe')(clabe)
        }
      })
    } else {
      this.createOrder(undefined, () => {
        this.setState({
          isAddingInstallment: true,
          installment: id,
          orderData: {
            id,
            divisaMoneda: getValue(this.state.paymentData.divisaMoneda),
            status: estatus,
            estatus: estatusTexto,
            cargo: id ? cargo : getValue(this.state.totals.montoPagar),
            idOrden: getId(this.state.order),
            referencia: getValue(this.state.paymentData.referencia),
            fechaTransaccion: id ? new Date(fechaTransaccion) : minDate.toDate(),
            metodoPago: prop('metodo')(method),
            integrado: this.props.partner,
            provider: getValue(this.state.generalData.idProveedor),
            cuentaMxn: prop('clabe')(clabe)
          }
        })
      })
    }
  }

  closeInstallmentModal = () => this.setState({
    isAddingInstallment: false,
    installment: undefined
  })

  openConfirmRemoveInstallment = ({ id }) => this.setState({
    isRemovingInstallment: true,
    installment: id,
  })

  closeConfirmRemoveInstallment = () => this.setState({
    isRemovingInstallment: false,
  })

  addConcept = (concept) => {
    const conceptos = this.state.conceptos;
    conceptos.data.push({ ...concept, id: uuid() })
    this.setState({
      conceptos
    }, () => {
      this.closeConceptModal()
      this.updateTotals()
    })
  }

  removeConcept = () => {
    const { conceptos } = this.state
    if (this.state.conceptRemoving.id) {
      conceptos.data = reject(propEq('id', this.state.conceptRemoving.id))(conceptos.data)
    } else {
      conceptos.data = reject(propEq('idConcepto', this.state.conceptRemoving.idConcepto))(conceptos.data)
    }
    this.setState({
      conceptos,
      isRemovingConcept: false,
    }, this.updateTotals)
  }

  addInstallment = (installment) => {

    this.props.addInstallment(this.state.order.id, {
      ...installment,
      fechaTransaccion: moment(installment.fechaTransaccion).format('YYYY-MM-DD')
    })
      .then(() => {
        this.closeInstallmentModal();
        this.props.addNotification({
          type: 'success',
          message: '¡El pago se agregó correctamente!'
        })
      })
      .catch(({ response: { data } }) => {
        this.props.addNotification({
          type: 'danger',
          message: data.message
        })
      })
  }

  removeOrder = () => {
    this.props.deleteOrder(this.state.order.id)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'La órden de pago se eliminó correctamente'
        })
        this.setState({
          isRemovingOrder: false,
        })
      })
      .catch(({ response: { data } }) => {
        this.props.addNotification({
          type: 'danger',
          message: data.message
        })
      })
  }

  removeInstallment = () => {
    this.props.deleteInstallment(this.state.order.id, this.state.installment)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'El pago se eliminó correctamente'
        })
        this.setState({
          isRemovingInstallment: false,
        })
      })
      .catch(({ response: { data } }) => {
        this.props.addNotification({
          type: 'danger',
          message: data.message
        })
      })
  }

  onAuthorize = ({ id }) => {
    this.setState({
      isAddingInstallment: false,
      showAuth: true,
      installment: id,
    })
  }

  cancelAuthorize = () => {
    this.setState({
      showAuth: false,
    })
  }

  authorizeInstallment = props => {
    return this.props.authorizeInstallment({
      idOrder: this.state.order.id,
      idInstallment: this.state.installment,
      ...props,
    }).then(data => {
      this.props.addNotification({
        type: 'success',
        message: 'El pago se autorizó correctamente'
      })
      this.setState({
        showAuth: false,
        totals: mapPropsToState(this.state.totals, data),
        generalData: mapPropsToState(this.state.generalData, data)
      })
    })

  }

  render() {
    const {
      paymentEstatus,
      paises,
      entidades,
      realPaymentMethod,
      accounts,
      providerInvoiceStatus,
      ivaRates,
      installments,
      collaborators,
      collaboratorsAccounts,
      getCollaboratorsAccounts,
      comission,
      provider,
      attachments
    } = this.props
    const { generalData: { estatusOrden }, totals: { estatusFacturaProveedor }, order } = this.state
    const disableFields = (!!estatusOrden.value && estatusOrden.value !== STATUS_CREATED) || !!installments.data.length || estatusFacturaProveedor.value === INVOICE_APPROVED
    return (
      <Box >
        <ModalRemove
          title="Eliminar pago"
          isDeleting={this.state.isRemovingInstallment}
          confirmText="¿Esta seguro de querer eliminar el pago?"
          toggleModal={this.closeConfirmRemoveInstallment}
          resource={this.state.installment}
          deleteResource={this.removeInstallment}
        />
        {this.state.showAuth && (<Authorize
          isOpen={this.state.showAuth}
          authorize={this.authorizeInstallment}
          cancel={this.cancelAuthorize}
        />)}
        <Modal isActive={this.state.isAddingConcept}>
          <Message default>
            <Message.Header>
              <p>Agregar concepto</p>
              <Delete onClick={this.closeConceptModal} />
            </Message.Header>
            <Message.Body>
              {
                this.state.isAddingConcept &&
                <Concept
                  addNotification={addNotification}
                  addConcept={this.addConcept}
                  closeConceptModal={this.closeConceptModal}
                  collaborators={collaborators.data}
                  getCollaboratorsAccounts={getCollaboratorsAccounts}
                  collaboratorsAccounts={collaboratorsAccounts.data}
                  comission={comission}
                  ivaRates={ivaRates}
                />
              }
            </Message.Body>
          </Message>
        </Modal>
        <Modal isActive={this.state.isAddingInstallment}>
          <Message default>
            <Message.Header>
              <p>Agregar Pago</p>
              <Delete onClick={this.closeInstallmentModal} />
            </Message.Header>
            <Message.Body>
              {
                this.state.isAddingInstallment &&
                <Installment
                  orderData={this.state.orderData}
                  addNotification={addNotification}
                  addInstallment={this.addInstallment}
                  closeInstallmentModal={this.closeInstallmentModal}
                  authorize={this.onAuthorize}
                />
              }
            </Message.Body>
          </Message>
        </Modal>
        <Modal isActive={this.state.isRemovingConcept}>
          <Message danger>
            <Message.Header>
              <p>Eliminar Concepto</p>
              <Delete onClick={this.closeConfirmRemoveConceptModal} />
            </Message.Header>
            <Message.Body className="has-text-centered">
              ¿Esta seguro de querer eliminar el concepto?
              <div className="margin-top-lg">
                <Button danger onClick={this.removeConcept}>Eliminar</Button>
                <Button default className="margin-left-sm" onClick={this.closeConfirmRemoveConceptModal}>
                  Cancelar
                </Button>
              </div>
            </Message.Body>
          </Message>
        </Modal>
        <Modal isActive={this.state.isRemovingOrder}>
          <Message danger>
            <Message.Header>
              <p>Eliminar Orden de pago</p>
              <Delete onClick={this.closeConfirmRemoveOrder} />
            </Message.Header>
            <Message.Body className="has-text-centered">
              ¿Esta seguro de querer eliminar la orden de pago?
              <div className="margin-top-lg">
                <Button danger onClick={this.removeOrder}>Eliminar</Button>
                <Button default className="margin-left-sm" onClick={this.closeConfirmRemoveOrder}>
                  Cancelar
                </Button>
              </div>
            </Message.Body>
          </Message>
        </Modal>
        <ActionBar
          permissions={this.getPermissions()}
          onSave={this.createOrder}
          onCancel={this.onCancel}
          onDelete={this.openConfirmRemoveOrder}
          basicRole={['Opago de proveedores']}
          authorizeRole={['Apago de proveedores']}
        />
        <SubTitle className="has-text-centered" is='3'>Convenios Integrados</SubTitle>
        <Divider content="DATOS GENERALES" />
        <Columns>
          <Column className="is-6">
            <Select
              onChange={(e) => this.onChangeInput(e, 'generalData')}
              options={paymentEstatus}
              showErrors={this.state.showErrors}
              {...this.state.generalData.estatusOrden}
            />
          </Column>
          <Column className="is-6">
            <Select
              disabled={disableFields}
              onChange={(e) => this.onChangeInput(e, 'generalData')}
              options={provider ? [].concat([provider]) : (order.id ? [{ id: order.idProveedor, nombreORazonSocial: order.nombreRazonSocial }] : [])}
              showErrors={this.state.showErrors}
              {...this.state.generalData.idProveedor}
            />
          </Column>
        </Columns>
        <Divider content="GENERALES DE LA FACTURA" />
        <AddressSection
          data={this.state.addressData}
          onChange={e => this.onChangeInput(e, 'addressData')}
          paises={paises}
          entidades={entidades}
          extranjero={false}
          showErrors={this.state.showErrors}
        />
        <Divider content="DATOS PARA PAGO" />
        <PaymentData
          data={this.state.paymentData}
          onChange={e => this.onChangeInput(e, 'paymentData')}
          showErrors={this.state.showErrors}
          paymentMethod={realPaymentMethod}
          accounts={accounts.data}
          disable={disableFields}
        />
        <Divider content="CONCEPTOS PAGOS" />
        <Columns>
          <Column>
            <Button
              disabled={this.state.loadedFromInvoice || installments.data.length}
              style={{ float: 'right' }}
              onClick={this.openConceptModal}
            >
              Agregar Concepto
            </Button>
          </Column>
        </Columns>
        <Table
          colsetup={conceptsCol}
          coldata={this.state.conceptos}
          tableClassName='table is-striped is-hoverable is-fullwidth'
          emptyTableMarkUp={(<span>Aun no hay conceptos</span>)}
          customCol={{
            customColName: 'acciones',
            renderFunc: this.customColActions
          }}
        />
        <Divider content="PAGOS" />
        <Columns>
          <Column>
            <Button
              success
              style={{ float: 'right' }}
              onClick={() => this.openInstallmentModal()}
              disabled={this.state.totals.montoPagar.value < 1}
            >
              Agregar pagos
            </Button>
          </Column>
        </Columns>
        <Table
          colsetup={installmentsCol}
          coldata={installments}
          tableClassName='table is-striped is-hoverable is-fullwidth'
          emptyTableMarkUp={(<span>Aun no hay pagos</span>)}
          customCol={{
            customColName: 'acciones',
            renderFunc: this.installmentActions
          }}
        />
        <Totals
          data={this.state.totals}
          showErrors={this.state.showErrors}
          providerInvoiceStatus={providerInvoiceStatus}
          folio={this.state.invoiceXml && this.state.invoiceXml['@Folio']}
        />
        <Attachments
          files={attachments}
        />
      </Box>
    )
  }
}

const mapStateToProps = ({ catalogs, catalogos, paymentOrders, principal, }) => ({
  typePaymentProviders: catalogos.typePaymentProviders,
  paymentEstatus: catalogos.paymentEstatus,
  paises: catalogos.paises,
  entidades: catalogos.entidades,
  realPaymentMethod: catalogos.realPaymentMethod,
  accounts: catalogs.providers.accounts,
  providerInvoiceStatus: catalogos.providerInvoiceStatus,
  ivaRates: catalogos.ivaRates,
  iepsRates: catalogos.iepsRates,
  order: paymentOrders.salaries.order,
  installments: paymentOrders.salaries.installments,
  partner: principal.profile.idIntegrado,
  provider: paymentOrders.salaries.randomProvider,
  collaborators: catalogs.collaborators.collaborators,
  collaboratorsAccounts: catalogs.collaborators.accounts,
  comission: paymentOrders.salaries.comission,
  attachments: paymentOrders.salaries.attachments,
  isFetching: paymentOrders.salaries.isFetchingSalaries,
  origin: paymentOrders.providers.origin,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getProviders,
  getTypePaymentProviders,
  getPaymentEstatus,
  getEntidades,
  getPaises,
  getProvider,
  getRealPaymentMethod,
  getProviderAccounts,
  createOrder,
  getProviderInvoiceStatus,
  addNotification,
  push,
  getIvaRates,
  getOrder,
  getInstallments,
  addInstallment,
  deleteOrder,
  endOrderEdit,
  authorizeInstallment,
  deleteInstallment,
  updateOrder,
  clearInvoiceUnits,
  getRandomProvider,
  getCollaborators,
  getCollaboratorsAccounts: getAccounts,
  getCommission,
  getAttachments,
  cleanOrigin,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Form)
