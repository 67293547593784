import { cond, always, T, F, or, converge } from 'ramda';
import { isEmpty, isLength, isString } from 'App/helpers'
import { isNumeric } from 'ramda-extension'

export const fields = {
  name: {
    input: {
      value: '',
      name: 'name',
    },
    label: 'Nombre o Razón Social',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  description: {
    input: {
      value: '',
      name: 'description',

    },
    label: 'Descripción o Actividad o Giro del Negocio o la Empresa',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  web: {
    input: {
      value: '',
      name: 'web',

    },
    label: 'Página Web',
  },
  phonecode: {
    input: {
      value: '52',
      name: 'phonecode',
    },
    label: 'Código de País (lada)',
    valueLabel: 'phone_code',
    valueProperty: 'phone_code',
    hideEmpty: false,
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  telephone: {
    input: {
      value: '',
      name: 'telephone',
      maxLength: 10,
    },
    label: 'Teléfono',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [isString, always({ error: 'El formato de telefono es incorrecto' })],
      [isLength(10), always({ error: 'El telefono debe ser de 10 caracteres' })],
      [T, F]
    ]),
    pattern: converge(or, [isNumeric, isEmpty])
  },
};