import axios from 'axios'
import { getHeaders } from './util'
import { CHANGE_PASSWORD } from './auth'
import { v4 as uuid } from 'uuid'
import { getMenu } from './initialData'



const url = process.env.REACT_APP_API
export const GET_PROFILE_REQUEST = 'principal/GET_PROFILE_REQUEST'
export const GET_PROFILE = 'principal/GET_PROFILE'
export const ADD_NOTIFICATION = 'principal/ADD_NOTIFICATION'
export const DISMISS_NOTIFICATION = 'principal/DISMISS_NOTIFICATION'
export const GET_ACCOUNT_INFO = 'principal/GET_ACCOUNT_INFO'
export const GET_PARTNER_PROFILE = 'principal/GET_PARTNER_PROFILE'
export const GET_DOCUMENTS = 'principal/GET_DOCUMENTS'
export const GET_ACCOUNT = 'principal/GET_ACCOUNT'
export const GET_LEGAL_REPRESENTATIVE = 'principal/GET_LEGAL_REPRESENTATIVE'
export const GET_INVENTORY_PERMISSIONS = 'principal/GET_INVENTORY_PERMISSIONS'
export const GET_NO_INTEREST_CONFIGURATION = 'principal/GET_NO_INTEREST_CONFIGURATION'

const initialState = {
  profile: {},
  permission: [],
  changePassword: false,
  notifications: [],
  menu: [],
  accountsInfo: {},
  partnerProfile: {
    generales: {
      rfc: '',
      tipoPersona: {}
    }
  },
  documents: [],
  account: undefined,
  legalRepresentative: undefined,
  inventoryPermission: {inventarios: undefined},
  integrated: {
    tipoPersona: {},
    accounts: [],
  },
  noInterest: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LEGAL_REPRESENTATIVE:
    case GET_ACCOUNT:
    case GET_DOCUMENTS:
    case GET_INVENTORY_PERMISSIONS:
    case GET_ACCOUNT_INFO:
    case GET_NO_INTEREST_CONFIGURATION:
      return { ...state, ...action.payload }
    case GET_PROFILE:
      return { ...state, ...action.payload, menu: getMenu(action.payload.permission) }
    case CHANGE_PASSWORD:
      return { ...state, changePassword: false }
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.concat([action.payload]) }
    case DISMISS_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(it => it.uuid !== action.payload) }
    case GET_PARTNER_PROFILE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export const getProfile = () => {
  return dispatch => {
    dispatch({ type: GET_PROFILE_REQUEST })
    return axios.get(`${url}/profile`, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: GET_PROFILE,
          payload: data
        })
      })
  }
}

export const addNotification = (notification) => {
  const notUuid = uuid()
  return dispatch => {
    setTimeout(()=>{
      dispatch({
        type: DISMISS_NOTIFICATION,
        payload: notUuid
      })
    }, 3000)

    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
      ...notification,
      uuid: notUuid,
    }})
  }
}

export const addSuccessNotificationAction = (message) => {
  const notUuid = uuid()
  return dispatch => {
    setTimeout(()=>{
      dispatch({
        type: DISMISS_NOTIFICATION,
        payload: notUuid
      })
    }, 3000)

    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        type: 'success',
        message,
        uuid: notUuid,
    }})
  }
}

export const getAccountsInfo = () => {
  return dispatch => {
    return axios.get(`${url}/integrating-accounts`, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: GET_ACCOUNT_INFO,
          payload: {accountsInfo: data}
        })
      })
  }
}
export const getPartnerProfile = () => {
  return dispatch => {
    return axios.get(`${url}/integrated-profile`, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: GET_PARTNER_PROFILE,
          payload: {partnerProfile: data}
        })
      })
  }
}
export const getDocuments = () => {
  return dispatch => {
    return axios.get(`${url}/integrated-profile-docs/docs/`, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: GET_DOCUMENTS,
          payload: {documents: data}
        })
      })
  }
}

export const getDocument = path => {
  return () => {
    return axios.post(`${url}/storage/file`, {path}, { headers: getHeaders(), responseType: 'blob' })
  }
}

export const getAccount = id => {
  return dispatch => {
    return axios.get(`${url}/integrated-profile/bankAccounts/${id}`, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: GET_ACCOUNT,
          payload: {account: data}
        })
      })
  }
}

export const getLegalRepresentative = id => {
  return dispatch => {
    return axios.get(`${url}/integrated-profile/legalRepresentative/${id}`, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: GET_LEGAL_REPRESENTATIVE,
          payload: {legalRepresentative: data}
        })
      })
  }
}

export const getInventoryPermission = () => {
  return dispatch => {
    return axios.get(`${url}/permissions`, {headers: getHeaders()})
      .then(({ data }) => {
        dispatch({
          type: GET_INVENTORY_PERMISSIONS,
          payload: {inventoryPermission: data},
        })
      })
  }
}

export const getNoInterestConfiguration = () => {
  return dispatch => {
    return axios.get(`${url}/pagos/no-interest-configuration`, {headers: getHeaders()})
      .then(({ data }) => {
        dispatch({
          type: GET_NO_INTEREST_CONFIGURATION,
          payload: {noInterest: data},
        })
      })
  }
}
