import LegalRepresentativeRepository from "../domain/legalRepresentativeRepository";
import httpService from '../../../services/httpService';


export default class RemoteLegalRepresentativeRepository extends LegalRepresentativeRepository {
  // Implement all the methods here
  async get() {
    const { data } = await httpService.get('/onboarding/legal-representative');

    return data.data
  }

  async save(legalRepresentative) {
    return httpService.post('/onboarding/legal-representative', legalRepresentative);
  }

  async update(legalRepresentative, legalRepresentativeId) {
    return httpService.put(`/onboarding/legal-representative/${legalRepresentativeId}`, legalRepresentative);

  }

  async delete(legalRepresentativeId) {
    return httpService.delete(`/onboarding/legal-representative/${legalRepresentativeId}`);
  }
}
