import moment from 'moment'
import {
  prop, pipe, path, find, propEq,
  ifElse, propOr,
} from 'ramda'
import numeral from 'numeral'
import { toNumber } from 'ramda-adjunct'

const limitHour = moment({ hour: 16, minute: 5 })
const minDate = moment() < limitHour ? moment({ hour: 0 }) : moment({ hour: 0 }).add(1, 'day')

export const generalData = {
  typePaymentProvider: {
    value: '',
    name: 'typePaymentProvider',
    label: 'Tipo de pago',
    valueProperty: 'id',
    valueLabel: 'tipoPago',
  },
  idProveedor: {
    value: '',
    validation: 'required',
    name: 'idProveedor',
    label: 'Proveedor',
    valueProperty: 'id',
    valueLabel: 'nombreORazonSocial',
    trigger: 'getProvider',
  },
  invoice: {
    value: '',
    vame: 'invoice',
    label: 'Cargar Factura',
  },
  estatusOrden: {
    value: '',
    name: 'estatusOrden',
    label: 'Estatus',
    valueProperty: 'id',
    valueLabel: 'estatus',
    disabled: true,
  },
}
export const conceptsCol = [
  {
    title: 'Concepto',
    datakey: 'descripcion',
  },
  {
    title: 'Subtotal',
    datakey: 'subtotalCalculado',
    format: '$ 0,0.00',
  },
  {
    title: 'IVA',
    datakey: 'ivaCalculado',
    format: '$ 0,0.00',
  },
  {
    title: 'Total',
    datakey: 'totalCalculado',
    format: '$ 0,0.00',
  },
  {
    title: 'Acciones',
    custom: 'acciones',
  }
]
export const addressData = {
  direccion: {
    value: '',
    transform: 'stringToBool',
    trigger: 'onChangeDireccion',
    name: 'direccion',
    label: 'Dirección',
    disabled: true,
  },
  pais: {
    name: 'pais',
    value: '',
    validation: 'requiredIf:direccion',
    label: 'País',
    disabled: true
  },
  codigoPostal: {
    value: '',
    trigger: 'onChangeCp',
    pattern: 'number',
    name: 'codigoPostal',
    label: 'Código postal',
    disabled: true,
  },
  entidadFederativa: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'entidadFederativa',
    label: 'Entidad Federativa/Estado/Provincia',
    disabled: true,
  },
  municipio: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'municipio',
    label: 'Municipio',
    disabled: true,
  },
  colonia: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'colonia',
    label: 'Colonia',
    disabled: true,
  },
  calle: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'calle',
    label: 'Calle',
    disabled: true,
  },
  exterior: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'exterior',
    label: 'Exterior',
    disabled: true,
  },
  interior: {
    value: '',
    name: 'interior',
    label: 'Interior',
    disabled: true,
  },
}
export const paymentData = {
  metodoPago: {
    value: '',
    name: 'metodoPago',
    label: 'Método de pago',
    valueProperty: 'id',
    valueLabel: 'metodo',
    trigger: 'onChangePaymentMethod',
    transform: 'stringToNumber',
    validation: 'required',
  },
  divisaMoneda: {
    value: '',
    name: 'divisaMoneda',
    label: 'Moneda Abono Proveedor',
    options: [
      { id: 'MXN', label: 'MXN' },
      { id: 'USD', label: 'USD' }
    ],
    valueProperty: 'id',
    valueLabel: 'label',
    validation: 'required',
  },
  referencia: {
    value: moment().format('DDMMYY'),
    name: 'referencia',
    label: 'Referencia',
    maxLength: '7',
    pattern: 'number',
  },
  retirementAccount: {
    value: '',
    name: 'retirementAccount',
    label: 'Cuenta de retiro',
  },
  exchange: {
    name: 'exchange',
    label: 'Tipo de cambio',
    value: '1',
  },
  cuentaClabeBeneficiario: {
    value: '',
    name: 'cuentaClabeBeneficiario',
    label: 'Cuenta Clabe Beneficiario',
    valueProperty: 'id',
    validation: 'requiredIfNotCustomNumber:cuentaClabeBeneficiario',
    depends: 'metodoPago',
    dependsValue: 2,
  },
  cuentaRetiro: {
    value: '',
    name: 'cuentaRetiro',
    label: 'Cuenta Retiro',
    same: 'divisaMoneda'
  }
}

export const conceptCommand = {
  descripcion: prop('@Descripcion'),
  unidad: prop('@Unidad'),
  valorUnitario: prop('@ValorUnitario'),
  isrRetenido: obj => {
    const retenido = path(['cfdi:Impuestos', 'cfdi:Retenciones', 'cfdi:Retencion'])(obj)
    let totalRetenido
    if (Array.isArray(retenido)) {
      totalRetenido = retenido.reduce((acc, cur) => {
        return acc += Number.parseFloat(prop('@Importe')(cur))
      }, 0)

    } else {
      totalRetenido = Number.parseFloat(prop('@Importe')(retenido))
    }
    return totalRetenido
  },
  ivaRetenido: obj => {
    const translados = path(['cfdi:Impuestos', 'cfdi:Traslados', 'cfdi:Traslado'])(obj)
    let totalTransladado;
    if (Array.isArray(translados)) {
      totalTransladado = translados.reduce((acc, cur) => {
        return acc += Number.parseFloat(prop('@Importe')(cur))
      }, 0)
      totalTransladado = Math.round(totalTransladado * 100) / 100
    } else {
      totalTransladado = Math.round(Number.parseFloat(prop('@Importe')(translados)) * 100) / 100
    }
    return totalTransladado
  },
  netoDepositar: obj => {
    const retenido = path(['cfdi:Impuestos', 'cfdi:Retenciones', 'cfdi:Retencion'])(obj)
    const translados = path(['cfdi:Impuestos', 'cfdi:Traslados', 'cfdi:Traslado'])(obj)
    const subtotal = Number.parseFloat(prop('@Importe')(obj))
    let totalRetenido;
    let totalTransladado;

    if (Array.isArray(retenido)) {
      totalRetenido = retenido.reduce((acc, cur) => {
        return acc += Number.parseFloat(prop('@Importe')(cur))
      }, 0)

    } else {
      totalRetenido = Number.parseFloat(prop('@Importe')(retenido))
    }
    if (Array.isArray(translados)) {
      totalTransladado = translados.reduce((acc, cur) => {
        return acc += Number.parseFloat(prop('@Importe')(cur))
      }, 0)
      totalTransladado = Math.round(totalTransladado * 100) / 100
    } else {
      totalTransladado = Math.round(Number.parseFloat(prop('@Importe')(translados)) * 100) / 100
    }
    return Number.parseInt(((subtotal + totalTransladado) - totalRetenido) * 100, 10) / 100
  },
  totalCalculado: (obj) => {
    const translados = path(['cfdi:Impuestos', 'cfdi:Traslados', 'cfdi:Traslado'])(obj)
    const descuento = pipe(propOr(0, '@Descuento'), Number.parseFloat)(obj)
    const importe = pipe(prop('@Importe'), Number.parseFloat)(obj)
    const subtotal = importe - descuento;
    let totalTransladado;

    if (Array.isArray(translados)) {
      totalTransladado = translados.reduce((acc, cur) => {
        return acc += Number.parseFloat(prop('@Importe')(cur))
      }, 0)
    } else {
      totalTransladado = Number.parseFloat(prop('@Importe')(translados))
    }
    return Math.round((subtotal + totalTransladado) * 100) / 100
  },
  ivaCalculado: (obj) => {
    let translados = path(['cfdi:Impuestos', 'cfdi:Traslados', 'cfdi:Traslado'])(obj)
    let iva
    if (Array.isArray(translados)) {
      iva = pipe(find(propEq('@Impuesto', '002')))(translados)
    } else {
      iva = ifElse(propEq('@Impuesto', '002'), () => translados, () => undefined)(translados)
    }
    return iva ? Number.parseFloat(prop('@Importe')(iva)) : 0
  },
  iepsCalculado: (obj) => {
    let translados = path(['cfdi:Impuestos', 'cfdi:Traslados', 'cfdi:Traslado'])(obj)
    let impuesto
    const IEPS = '003'
    if (Array.isArray(translados)) {
      impuesto = pipe(find(propEq('@Impuesto', IEPS)))(translados)
    } else {
      impuesto = ifElse(propEq('@Impuesto', IEPS), () => translados, () => undefined)(translados)
    }
    return impuesto ? Number.parseFloat(prop('@Importe')(impuesto)) : 0
  },
  subtotalCalculado: obj => {
    const importe = pipe(prop('@Importe'), Number.parseFloat)(obj);
    const descuento = pipe(propOr(0, '@Descuento'), Number.parseFloat)(obj);
    return importe - descuento;
  },
  cantidad: pipe(prop('@Cantidad'), Number.parseInt),
  claveProdServ: prop('@ClaveProdServ'),
}

export const totals = {
  subtotal: {
    name: 'subtotal',
    label: 'Subtotal',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  IVA: {
    name: 'IVA',
    label: 'IVA',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  ieps: {
    name: 'ieps',
    label: 'IEPS',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  montoPagado: {
    name: 'montoPagado',
    label: 'Monto Pagado',
    value: 0,
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  montoPagoTransito: {
    name: 'montoPagoTransito',
    label: 'Monto Pago Tránsito',
    value: 0,
    format: (value) => numeral(Math.round(+value * 100) / 100).format('$ 0,0.00'),
    align: 'right',
  },
  montoPagar: {
    name: 'montoPagar',
    label: 'Monto por Pagar',
    value: 0,
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  estatusFacturaProveedor: {
    name: 'estatusFactura',
    label: 'Estatus Factura Proveedor',
    valueProperty: 'id',
    valueLabel: 'estatus',
    disabled: true,
  },
  numeroFactura: {
    name: 'numeroFactura',
    label: 'Numero de factura',
  },
  UUID: {
    name: 'UUID',
    label: 'UUID',
    disabled: true,
  },
  total: {
    name: 'total',
    label: 'Total',
    value: 0,
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  ISRRetenido: {
    name: 'ISRRetenido',
    label: 'ISR Retenido',
    value: 0,
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  IVARetenido: {
    name: 'IVARetenido',
    label: 'IVA Retenido',
    value: 0,
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  netoDepositar: {
    name: 'netoDepositar',
    label: 'Neto a depositar',
    value: 0,
    format: (value) => numeral(value).format('$ 0,0.00', Math.floor),
    align: 'right',
  },
  nombrePDF: {
    name: 'facturaPdf',
    label: 'Adjuntar factura PDF',
    selectLabel: 'Adjuntar Factura PDF',
    value: '',
  },
  nombreXML: {
    name: 'Factura Xml',
    label: 'Adjuntar factura Xml',
    selectLabel: 'Adjuntar Factura Xml',
    value: '',
  },
  flagPDF: {
    name: 'flagPDF',
    value: false,
    label: '',
  },
  flagXML: {
    value: false,
    name: 'flagXML',
  }
}

export const conceptData = {
  descripcion: {
    name: 'descripcion',
    label: 'Concepto',
    value: '',
    validation: 'required',
  },
  unidad: {
    name: 'unidad',
    label: 'Unidad',
    value: '',
    validation: 'required',
    placeholder: 'Buscar unidad...',
  },
  valorUnitario: {
    name: 'valorUnitario',
    label: 'Unitario',
    value: '',
    validation: 'required',
    pattern: 'number',
    trigger: 'updateCalculations',
  },
  isrRetenido: {
    name: 'isrRetenido',
    label: 'ISR Retenido',
    value: '',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
    validation: 'orIvaRetenido',
    trigger: 'updateCalculations',
    pattern: 'number',
    dataMarshaller: toNumber,
  },
  ivaRetenido: {
    name: 'ivaRetenido',
    label: 'IVA Retenido',
    value: '',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
    validation: 'orIsrRetenido',
    trigger: 'updateCalculations',
    pattern: 'number',
    dataMarshaller: toNumber,
  },
  netoDepositar: {
    name: 'netoDepositar',
    label: 'Neto a depositar',
    value: '',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  totalCalculado: {
    name: 'totalCalculado',
    label: 'Total Calculado',
    value: '',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  ivaCalculado: {
    name: 'ivaCalculado',
    label: 'IVA Calculado',
    value: '',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  iepsCalculado: {
    name: 'iepsCalculado',
    label: 'IEPS Calculado',
    value: '',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  subtotalCalculado: {
    name: 'isrRetenido',
    label: 'Subtotal Calculado',
    value: '',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  cantidad: {
    name: 'cantidad',
    label: 'Cantidad',
    pattern: 'number',
    value: '',
    validation: 'required',
    trigger: 'updateCalculations',
  },
  tasaIeps: {
    name: 'tasaIeps',
    label: 'Tasa IEPS',
    value: '',
    // validation: 'required',
    valueProperty: 'id',
    valueLabel: 'tipo',
    trigger: 'updateCalculations',
  },
  tasaIva: {
    name: 'tasaIva',
    label: 'Tasa IVA',
    value: '',
    validation: 'required',
    valueProperty: 'id',
    valueLabel: 'tipo',
    trigger: 'updateCalculations',
  },
  tipoCalculo: {
    name: 'tipoCalculo',
    label: 'Tipo de Cálculo',
    value: 1,
    validation: 'required',
    transform: 'stringToNumber',
    trigger: 'updateCalculations',
    options: [
      {
        value: 1,
        label: 'El IVA Grava sobre Subtotal'
      },
      {
        value: 2,
        label: 'El IVA Grava sobre Subtotal + IEPS'
      },
    ],
  }
}
export const paymentOrderReceiptFee =
  [
    {
      title: 'ID',
      datakey: 'id',
      sortable: true,
      width: '10%',
    },
    {
      title: 'Estatus Orden',
      datakey: 'estatusTexto',
      sortable: true,
      width: '20%',
    },
    {
      title: 'Nombre o Razón Social',
      datakey: 'nombreRazonSocial',
      sortable: true,
      width: '40%',
    },
    {
      title: 'Total',
      datakey: 'Total',
      format: '$ 0,0.00',
      sortable: true,
      width: '20%',
    },
    {
      title: 'Acciones',
      custom: 'actions',
      width: '10%',
    }
  ];

export const installment = {
  divisaMoneda: {
    name: 'divisaMoneda',
    label: 'Moneda pago',
  },
  cargo: {
    label: 'Cargos',
    name: 'cargo',
    validation: 'required|greaterThan:0',
    pattern: 'number',
    value: 0,
    trigger: 'updateClabe',
    format: (value) => numeral(value).format('$ 0,0.00'),
  },
  fechaTransaccion: {
    label: 'Fecha de Transacción',
    name: 'fechaTransaccion',
    validation: `required|gtOrEqDate:${minDate.format('YYYY-MM-DD')}`,
    value: undefined,
  },
  estatus: {
    label: 'Estatus',
    name: 'estatus',
  },
  idOrden: {
    label: 'Orden Pago',
    name: 'idOrden',
  },
  referencia: {
    label: 'Referencia Banco',
    name: 'referencia',
  },
  metodoPago: {
    label: 'Metódo de pago',
    name: 'metodoPago',
  },
  cuentaMxn: {
    label: 'Cuenta MXN',
    name: 'cuentaMxn',
  },
  claveRastreo: {
    label: 'Clave de rastreo',
    name: 'claveRastreo',
  },
  typePaymentProvider: {
    label: 'Tipo de Pago',
    name: 'typePaymentProvider',
  }
}

export const installmentsCol = [
  {
    title: 'Id',
    datakey: 'id',
  },
  {
    title: 'Fecha de transacción',
    datakey: 'fechaTransaccion',
    // format: 'dd/MM/YY',
  },
  {
    title: 'Estatus',
    datakey: 'estatusTexto',
  },
  {
    title: 'Cargo',
    datakey: 'cargo',
    format: '$ 0,0.00',
  },
  {
    title: 'Clave de Rastreo',
    datakey: 'claveRastreo',
  },
  {
    title: 'Referencia del banco',
    datakey: 'referenciaBanco'
  },
  {
    title: 'Acciones',
    custom: 'acciones',
  }
]