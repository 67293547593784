import React from 'react'
import { Output } from 'Components/common/form'
import { Columns, Column, Divider } from 'Components/common/bulma'

export default function Totals({
  data,
}) {
  return (
    <div>
      <Columns>
        <Column>
          <Divider content="RESUMEN DEL PAGO" />
          <Output
            {...data.subtotal}
          />
          <Output
            {...data.iva}
          />
          <Output
            {...data.ieps}
          />
        </Column>
        <Column>
          <Divider content="RESUMEN MONTOS PAGADOS" />
          <Output
            {...data.montoCobrado}
          />
          <Output
            {...data.montoPorCobrar}
          />
        </Column>
      </Columns>
      <Columns>
        <Column>
          <Output
            {...data.total}
          />
        </Column>
      </Columns>
    </div>
  )
}
