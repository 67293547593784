import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { Box, SubTitle, Divider, Columns, Column, Button, Icon, Modal, Message, Delete, } from 'Components/common/bulma'
import { ActionBar, Table, ModalRemove } from 'Components/common'
import { Select, Autocomplete } from 'Components/common/form'
import {
  generalData, conceptsCol, addressData, paymentData, conceptCommand, totals, conceptData,
  installmentsCol,
} from './data'
import {
  getDataInput, mapPropsToState, getValues, validateData,
  transformData,
} from 'App/helpers'
import {
  getProviders, getProvider, getProviderAccounts, clearProvidersList,
  clearProvider,
} from 'Modules/catalogs/providers'
import {
  uploadInvoice,
  createOrder,
  getOrder,
  getInstallments,
  addInstallment,
  deleteOrder,
  endOrderEdit,
  authorizeInstallment,
  deleteInstallment,
  updateOrder,
  getAttachments,
} from 'Modules/paymentOrders/receiptFee'
import { cleanOrigin } from 'Modules/paymentOrders/providers'
import {
  getTypePaymentProviders,
  getPaymentEstatus,
  getPaises,
  getEntidades,
  getRealPaymentMethod,
  getProviderInvoiceStatus,
  getInvoiceUnits,
  clearInvoiceUnits,
  getIvaRates,
  getIepsRates,
} from 'Modules/catalogos'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrash, faPaperclip, faUpload, faTimes, faKey,
  faEdit,
} from '@fortawesome/free-solid-svg-icons'
import { AddressSection, } from 'Components/catalogs/providers'
import PaymentData from 'Components/paymentOrders/receiptFee/PaymentData'
import Totals from 'Components/paymentOrders/receiptFee/Totals'
import Concept from 'Components/paymentOrders/receiptFee/Concept'
import Installment from 'Components/paymentOrders/receiptFee/Installment'
import { map, prop, path, pipe, propEq, ifElse, find, reduce, reject, add, equals } from 'ramda'
import { addNotification } from 'Modules/principal'
import { v4 as uuid } from 'uuid'
import moment from 'moment'
import { Authorize, Can } from 'Containers/auth'
import Attachments from '../attachments';

const IVA_CODE = '002'
const IEPS_CODE = '003'
const ISR_CODE = '001'
const STATUS_CREATED = '1'
const STATUS_PARTIAL_AUTHORIZED = '9'
const INVOICE_APPROVED = '3'
const AUTHORIZE_PERMISSION = ['Apago de proveedores']

const getTotalFromInvoice = (xml) => {
  const subtotal = pipe(prop('@SubTotal'), Number.parseFloat)(xml)
  const taxes = pipe(path([
    'cfdi:Impuestos',
    '@TotalImpuestosTrasladados',
  ]), Number.parseFloat)(xml)

  return subtotal + taxes
}

const getRetenidoFromInvoice = (xml, impuesto) => {
  const impuestos = path([
    'cfdi:Impuestos',
    'cfdi:Retenciones',
    'cfdi:Retencion',
  ])(xml)

  return findImpuestoValue(impuestos, impuesto)
}

const getSubtotalFromInvoice = (xml) => {

  return pipe(prop('@SubTotal'), Number.parseFloat)(xml)
}

const getTrasladadoFromInvoice = (xml, impuesto) => {
  const impuestos = path([
    'cfdi:Impuestos',
    'cfdi:Traslados',
    'cfdi:Traslado',
  ])(xml)

  return findImpuestoValue(impuestos, impuesto)
}

const findImpuestoValue = (impuestos, code) => {
  if (!impuestos) {
    return 0
  }
  if (Array.isArray(impuestos)) {
    return pipe(find(propEq('@Impuesto', code)), prop('@Importe'))(impuestos)
  } else {
    return ifElse(propEq('@Impuesto', code), () => prop('@Importe')(impuestos), () => 0)(impuestos)
  }
}

export class Form extends Component {

  state = {
    receipt: undefined,
    showErrors: false,
    generalData,
    addressData,
    paymentData,
    conceptos: {
      data: []
    },
    totals,
    loadedFromInvoice: false,
    isAddingConcept: false,
    conceptData,
    nombreXML: '',
    nombrePdf: '',
    order: {},
    isRemovingConcept: false,
    isAddingInstallment: false,
    isRemovingInstallment: false,
    showAuth: false,
  }

  componentDidMount() {
    const { getInstallments, getAttachments } = this.props
    this.props.getTypePaymentProviders()
    this.props.getPaymentEstatus()
    this.props.getPaises()
    this.props.getEntidades()
    this.props.getRealPaymentMethod()
    this.props.getProviderInvoiceStatus()
    this.props.getIvaRates()
    this.props.getIepsRates()

    const id = this.props.match.params.id
    if (id && (id !== this.state.order)) {
      this.props.getOrder(id)
      getInstallments(id)
      getAttachments(id)
    }

  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.provider.id !== this.props.provider.id) {
      const addressData = mapPropsToState(this.state.addressData, nextProps.provider)
      this.setState({
        addressData,
      })
    }

    if (nextProps.order.id && nextProps.order.id !== this.state.order.id) {
      const generalData = mapPropsToState(this.state.generalData, nextProps.order)
      const addressData = mapPropsToState(this.state.addressData, nextProps.order)
      const paymentData = mapPropsToState(this.state.paymentData, nextProps.order)
      const totals = mapPropsToState(this.state.totals, nextProps.order)

      this.props.getProviderAccounts(nextProps.order.idProveedor, { size: 1000 })
      this.setState({
        loadedFromInvoice: !!nextProps.order.nombreXML,
        order: nextProps.order,
        generalData,
        addressData,
        paymentData,
        totals,
        conceptos: {
          data: nextProps.order.conceptos
        }
      })
    }
  }
  componentWillUnmount() {
    const { cleanOrigin } = this.props;
    this.props.endOrderEdit()
    this.props.clearProvider()
    cleanOrigin()
  }

  getPermissions = () => {
    return {
      canAuthorize: false,
      canDelete: (this.state.order && this.state.order.id && this.state.order.estatusOrden === STATUS_CREATED),
      canSave: true,
    }
  }
  onChangeInput = ({ target }, section) => {
    const { name } = target
    const data = getDataInput(target, this.state[section])
    const field = data[name]

    this.setState({
      [section]: data,
      showErrors: false,
      showAccountErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }
  customColActions = (row, idx) => (
    <Button danger outlined
      onClick={() => this.openConfirmRemoveConceptModal(row)}
      disabled={this.state.loadedFromInvoice || this.props.installments.data.length}>
      <Icon>
        <FontAwesomeIcon icon={faTrash} />
      </Icon>
    </Button>
  )
  installmentActions = (row, idx) => (
    <div>
      <Button outlined
        primary
        onClick={() => this.openInstallmentModal(row)}>
        <Icon>
          <FontAwesomeIcon icon={faEdit} />
        </Icon>
      </Button>
      <Can validate={AUTHORIZE_PERMISSION}>
        <Button danger outlined className="margin-left-xs"
          disabled={!(row.estatus === STATUS_CREATED || row.estatus === STATUS_PARTIAL_AUTHORIZED)}
          onClick={() => this.onAuthorize(row)}>
          <Icon>
            <FontAwesomeIcon icon={faKey} />
          </Icon>
        </Button>
      </Can>
      <Button danger outlined className="margin-left-xs"
        disabled={(row.estatus !== STATUS_CREATED)}
        onClick={() => this.openConfirmRemoveInstallment(row)}>
        <Icon>
          <FontAwesomeIcon icon={faTrash} />
        </Icon>
      </Button>
    </div>
  )
  getProvider = ({ value }) => {
    this.setState({
      paymentData: mapPropsToState(this.state.paymentData, {
        metodoPago: '',
        divisaMoneda: '',
        cuentaClabeBeneficiario: '',
      })
    })
    this.props.getProvider(value)
      .then(({ id }) => {
        this.props.getProviderAccounts(id, { size: 1000 })
      })
  }
  changeInvoice = ({ target }) => {
    this.setState({
      invoice: target.files[0]
    })
  }
  uploadInvoice = () => {
    let data = new FormData()

    data.append('file', this.state.invoice, this.state.invoice.name)
    this.props.uploadInvoice(data)
      .then((data) => {
        this.setState({
          invoiceXml: JSON.parse(data.dataXML),
          nombreXML: data.nombreArchivo,
          loadedFromInvoice: true,
        }, () => {
          const { invoiceXml, totals: totalsState } = this.state
          const conceptos = path(['cfdi:Conceptos', 'cfdi:Concepto'])(invoiceXml)
          const totals = mapPropsToState(totalsState, {
            total: getTotalFromInvoice(invoiceXml),
            montoPagar: getTotalFromInvoice(invoiceXml) - this.state.totals.montoPagoTransito.value,
            netoDepositar: prop('@Total')(invoiceXml),
            ISRRetenido: getRetenidoFromInvoice(invoiceXml, ISR_CODE),
            IVARetenido: getRetenidoFromInvoice(invoiceXml, IVA_CODE),
            subtotal: getSubtotalFromInvoice(invoiceXml),
            IVA: getTrasladadoFromInvoice(invoiceXml, IVA_CODE),
            ieps: getTrasladadoFromInvoice(invoiceXml, IEPS_CODE),
            UUID: path([
              'cfdi:Complemento',
              'tfd:TimbreFiscalDigital',
              '@UUID'
            ])(invoiceXml),
            nombreXML: data.nombreArchivo,
            flagXML: true,
          })
          let concept = []
          const con = map(it => {
            concept.push(transformData(conceptCommand, it))
          })(Array.isArray(conceptos) ? conceptos : [conceptos])
          this.setState({
            conceptos: { data: concept },
            con,
            totals,
          })
          this.props.addNotification({
            type: 'success',
            message: '¡La factura se adjunto correctamente!'
          })
        })
      })
      .catch(({ response: { data } }) => {
        this.props.addNotification({
          type: 'danger',
          message: data.message,
        })
      })
  }

  removeInvoice = () => {
    const data = {
      invoiceXml: '',
      nombreXML: '',
      invoice: {},
      totals: mapPropsToState(this.state.totals, { nombreXML: '' }),
      loadedFromInvoice: false,
    }
    if (!this.props.order.id) {
      data.conceptos = {
        data: []
      }
    }
    this.setState(data)
    this.invoiceInput.value = null
  }
  createOrder = (event, cb) => {
    const { data: generalData, hasErrors: hasErrorsGeneralData } = validateData(this.state.generalData)
    const { data: paymentData, hasErrors: hasErrorsPaymentData } = validateData(this.state.paymentData)

    if (hasErrorsGeneralData || hasErrorsPaymentData || !this.state.conceptos.data.length) {
      this.setState({
        generalData,
        paymentData,
        showErrors: true,
      })
      this.props.addNotification({
        type: 'danger',
        message: '¡Favor de validar algunos campos y que tengas conceptos!'
      })
      return
    }
    const payload = {
      id: this.state.order.id,
      ...getValues(this.state.generalData),
      ...getValues(this.state.addressData),
      ...getValues(this.state.paymentData),
      ...getValues(this.state.totals),
      conceptos: this.state.conceptos.data,
      // nombrePDF: this.state.nombrePdf,
      // nombreXML: this.state.nombreXML,
    }
    if (this.state.order.id) {
      this.props.updateOrder(payload)
        .then((data) => {
          this.props.addNotification({
            type: 'success',
            message: '¡La orden se actualizó correctamente!'
          })

          window.location.reload()

        })
        .catch(({ response: { data } }) => {
          this.props.addNotification({
            type: 'danger',
            message: data.message
          })
        })
    } else {
      this.props.createOrder(payload)
        .then(data => {
          this.props.addNotification({
            type: 'success',
            message: '¡La orden se creó correctamente!'
          })

          this.props.push(`/ordenes-pago/recibo-honorarios/${data.id}`)
          cb && cb()
        })
        .catch(({ response: { data } }) => {
          this.props.addNotification({
            type: 'danger',
            message: data.message
          })
        })
    }
  }

  onChangePdf = ({ target }) => {
    this.setState({
      invoicePdf: target.files[0]
    })
  }
  uploadPdf = () => {
    let data = new FormData()
    data.append('file', this.state.invoicePdf, this.state.invoicePdf.name)
    this.props.uploadInvoice(data)
      .then(({ nombreArchivo }) => {
        this.setState({
          nombrePdf: nombreArchivo,
          totals: mapPropsToState(this.state.totals, {
            nombrePDF: nombreArchivo,
            flagPDF: true,
          }),
        })
        this.props.addNotification({
          type: 'success',
          message: '¡La factura en pdf se adjunto correctamente!'
        })
      })
      .catch(({ response: { data } }) => {
        this.props.addNotification({
          type: 'danger',
          message: data.message,
        })
      })
  }

  onCancel = () => {
    const { push, origin } = this.props
    const isFromPending = equals('pending')(origin)
    const isFromPendingToPay = equals('pendingToPay')
    const isFromPendingInvoice = equals('pendingInvoice')
    const isFromRejectedInvoice = equals('rejectedInvoice')

    if (isFromPending) {
      push('/ordenes-pago/pendientes')
      return
    }
    if (isFromPendingToPay(origin)) {
      push('/ordenes-pago/pendientes-pago')
      return
    }
    if (isFromPendingInvoice(origin)) {
      push('/ordenes-pago/proveedores/facturas-pendientes')
      return
    }

    if (isFromRejectedInvoice(origin)) {
      push('/ordenes-pago/facturas-rechazadas')
      return
    }

    push('/ordenes-pago/recibo-honorarios')
  }

  updateTotals = () => {
    const totals = reduce((acc, cur) => {
      return {
        total: add(prop('total')(acc), prop('totalCalculado')(cur)),
        montoPagar: add(prop('total')(acc), prop('totalCalculado')(cur)),
        netoDepositar: add(prop('netoDepositar')(acc), prop('netoDepositar')(cur)),
        ISRRetenido: add(prop('netoDepositar')(acc), prop('isrRetenido')(cur)),
        IVARetenido: add(prop('netoDepositar')(acc), prop('ivaRetenido')(cur)),
        subtotal: add(prop('subtotal')(acc), prop('subtotalCalculado')(cur)),
        iva: add(prop('iva')(acc), prop('ivaCalculado')(cur)),
        ieps: add(prop('ieps')(acc), prop('iepsCalculado')(cur)),
      }
    },
      {
        total: 0,
        montoPagar: 0,
        netoDepositar: 0,
        ISRRetenido: 0,
        IVARetenido: 0,
        subtotal: 0,
        iva: 0,
        ieps: 0,
      }
    )(this.state.conceptos.data)
    this.setState(({ totals: totalsState }) => ({ totals: mapPropsToState(totalsState, totals) }))
  }
  openConceptModal = () => this.setState({ isAddingConcept: true })
  closeConceptModal = () => this.setState({ isAddingConcept: false })
  openConfirmRemoveConceptModal = (conceptRemoving) => this.setState({
    conceptRemoving,
    isRemovingConcept: true
  })
  closeConfirmRemoveConceptModal = () => this.setState({
    conceptRemoving: undefined,
    isRemovingConcept: false
  })
  openConfirmRemoveOrder = () => this.setState({
    isRemovingOrder: true,
  })
  closeConfirmRemoveOrder = () => this.setState({
    isRemovingOrder: false
  })
  openInstallmentModal = ({ id, estatus, estatusTexto, cargo, fechaTransaccion } = {}) => {
    const getValue = prop('value')
    const getId = prop('id')
    const limitHour = moment({ hour: 16, minute: 5 })
    const minDate = moment() < limitHour ? moment({ hour: 0 }) : moment({ hour: 0 }).add(1, 'day')
    const method = find(propEq('id', this.props.order.metodoPago))(this.props.realPaymentMethod)
    const clabe = find(propEq('id', this.props.order.cuentaClabeBeneficiario))(this.props.accounts.data)

    if (this.state.order.id) {
      this.setState({
        isAddingInstallment: true,
        installment: id,
        orderData: {
          id,
          divisaMoneda: getValue(this.state.paymentData.divisaMoneda),
          status: estatus,
          estatus: estatusTexto,
          cargo: id ? cargo : getValue(this.state.totals.montoPagar),
          idOrden: getId(this.state.order),
          referencia: getValue(this.state.paymentData.referencia),
          fechaTransaccion: id ? new Date(fechaTransaccion) : minDate.toDate(),
          metodoPago: prop('metodo')(method),
          integrado: this.props.partner,
          provider: getValue(this.state.generalData.idProveedor),
          cuentaMxn: prop('clabe')(clabe)
        }
      })
    } else {
      this.createOrder(undefined, () => {
        this.setState({
          isAddingInstallment: true,
          installment: id,
          orderData: {
            id,
            divisaMoneda: getValue(this.state.paymentData.divisaMoneda),
            status: estatus,
            estatus: estatusTexto,
            cargo: id ? cargo : getValue(this.state.totals.montoPagar),
            idOrden: getId(this.state.order),
            referencia: getValue(this.state.paymentData.referencia),
            fechaTransaccion: id ? new Date(fechaTransaccion) : minDate.toDate(),
            metodoPago: prop('metodo')(method),
            integrado: this.props.partner,
            provider: getValue(this.state.generalData.idProveedor),
            cuentaMxn: prop('clabe')(clabe)
          }
        })
      })
    }
  }
  closeInstallmentModal = () => this.setState({
    isAddingInstallment: false,
    installment: undefined
  })

  openConfirmRemoveInstallment = ({ id }) => this.setState({
    isRemovingInstallment: true,
    installment: id,
  })

  closeConfirmRemoveInstallment = () => this.setState({
    isRemovingInstallment: false,
  })

  addConcept = (concept) => {
    const conceptos = this.state.conceptos;
    conceptos.data.push({ ...concept, id: uuid() })
    this.setState({
      conceptos
    }, () => {
      this.closeConceptModal()
      this.updateTotals()
    })
  }

  removeConcept = () => {
    const { conceptos } = this.state
    if (this.state.conceptRemoving.id) {
      conceptos.data = reject(propEq('id', this.state.conceptRemoving.id))(conceptos.data)
    } else {
      conceptos.data = reject(propEq('idConcepto', this.state.conceptRemoving.idConcepto))(conceptos.data)
    }
    this.setState({
      conceptos,
      isRemovingConcept: false,
    }, this.updateTotals)
  }

  addInstallment = (installment) => {

    this.props.addInstallment(this.state.order.id, {
      ...installment,
      fechaTransaccion: moment(installment.fechaTransaccion).format('YYYY-MM-DD')
    })
      .then(() => {
        this.closeInstallmentModal();
        this.props.addNotification({
          type: 'success',
          message: '¡El pago se agregó correctamente!'
        })
      })
      .catch(({ response: { data } }) => {
        this.props.addNotification({
          type: 'danger',
          message: data.message
        })
      })
  }

  removeOrder = () => {
    this.props.deleteOrder(this.state.order.id)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'La órden de pago se eliminó correctamente'
        })
        this.setState({
          isRemovingOrder: false,
        })
      })
      .catch(({ response: { data } }) => {
        this.props.addNotification({
          type: 'danger',
          message: data.message
        })
      })
  }

  removeInstallment = () => {
    this.props.deleteInstallment(this.state.order.id, this.state.installment)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'El pago se eliminó correctamente'
        })
        this.setState({
          isRemovingInstallment: false,
        })
      })
      .catch(({ response: { data } }) => {
        this.props.addNotification({
          type: 'danger',
          message: data.message
        })
      })
  }

  onAuthorize = ({ id }) => {
    this.setState({
      isAddingInstallment: false,
      showAuth: true,
      installment: id,
    })
  }

  cancelAuthorize = () => {
    this.setState({
      showAuth: false,
    })
  }

  authorizeInstallment = props => {
    return this.props.authorizeInstallment({
      idOrder: this.state.order.id,
      idInstallment: this.state.installment,
      ...props,
    }).then(data => {
      this.props.addNotification({
        type: 'success',
        message: 'El pago se autorizó correctamente'
      })
      this.setState({
        showAuth: false,
        totals: mapPropsToState(this.state.totals, data),
        generalData: mapPropsToState(this.state.generalData, data)
      })
    })

  }
  removeInvoicePdf = () => {
    this.setState({
      nombrePDF: '',
      invoicePdf: undefined,
      totals: mapPropsToState(this.state.totals, { nombrePDF: '' })
    })
  }
  render() {
    const {
      providers,
      paymentEstatus,
      paises,
      entidades,
      realPaymentMethod,
      accounts,
      providerInvoiceStatus,
      getInvoiceUnits,
      invoiceUnits,
      ivaRates,
      iepsRates,
      installments,
      clearInvoiceUnits,
      attachments,
    } = this.props
    const { generalData: { estatusOrden }, totals: { estatusFacturaProveedor } } = this.state
    const disableFields = (!!estatusOrden.value && estatusOrden.value !== STATUS_CREATED) || !!installments.data.length || estatusFacturaProveedor.value === INVOICE_APPROVED
    return (
      <Box >
        <ModalRemove
          title="Eliminar pago"
          isDeleting={this.state.isRemovingInstallment}
          confirmText="¿Esta seguro de querer eliminar el pago?"
          toggleModal={this.closeConfirmRemoveInstallment}
          resource={this.state.installment}
          deleteResource={this.removeInstallment}
        />
        {this.state.showAuth && (<Authorize
          isOpen={this.state.showAuth}
          authorize={this.authorizeInstallment}
          cancel={this.cancelAuthorize}
        />)}
        <Modal isActive={this.state.isAddingConcept}>
          <Message default>
            <Message.Header>
              <p>Agregar concepto</p>
              <Delete onClick={this.closeConceptModal} />
            </Message.Header>
            <Message.Body>
              {this.state.isAddingConcept && <Concept
                getUnits={getInvoiceUnits}
                units={invoiceUnits}
                ivaRates={ivaRates}
                iepsRates={iepsRates}
                addNotification={addNotification}
                addConcept={this.addConcept}
                closeConceptModal={this.closeConceptModal}
                clearUnits={clearInvoiceUnits}
              />}
            </Message.Body>
          </Message>
        </Modal>
        <Modal isActive={this.state.isAddingInstallment}>
          <Message default>
            <Message.Header>
              <p>Agregar Pago</p>
              <Delete onClick={this.closeInstallmentModal} />
            </Message.Header>
            <Message.Body>
              {
                this.state.isAddingInstallment &&
                <Installment
                  orderData={this.state.orderData}
                  addNotification={addNotification}
                  addInstallment={this.addInstallment}
                  closeInstallmentModal={this.closeInstallmentModal}
                  authorize={this.onAuthorize}
                />
              }
            </Message.Body>
          </Message>
        </Modal>
        <Modal isActive={this.state.isRemovingConcept}>
          <Message danger>
            <Message.Header>
              <p>Eliminar Concepto</p>
              <Delete onClick={this.closeConfirmRemoveConceptModal} />
            </Message.Header>
            <Message.Body className="has-text-centered">
              ¿Esta seguro de querer eliminar el concepto?
              <div className="margin-top-lg">
                <Button danger onClick={this.removeConcept}>Eliminar</Button>
                <Button default className="margin-left-sm" onClick={this.closeConfirmRemoveConceptModal}>
                  Cancelar
                </Button>
              </div>
            </Message.Body>
          </Message>
        </Modal>
        <Modal isActive={this.state.isRemovingOrder}>
          <Message danger>
            <Message.Header>
              <p>Eliminar Orden de pago</p>
              <Delete onClick={this.closeConfirmRemoveOrder} />
            </Message.Header>
            <Message.Body className="has-text-centered">
              ¿Esta seguro de querer eliminar la orden de pago?
              <div className="margin-top-lg">
                <Button danger onClick={this.removeOrder}>Eliminar</Button>
                <Button default className="margin-left-sm" onClick={this.closeConfirmRemoveOrder}>
                  Cancelar
                </Button>
              </div>
            </Message.Body>
          </Message>
        </Modal>
        <ActionBar
          permissions={this.getPermissions()}
          onSave={this.createOrder}
          onCancel={this.onCancel}
          onDelete={this.openConfirmRemoveOrder}
          basicRole={['Opago de proveedores']}
          authorizeRole={['Apago de proveedores']}
        />
        <SubTitle className="has-text-centered" is='3'>Proveedor Con Retenciones</SubTitle>
        <Divider content="DATOS GENERALES" />
        <Columns>
          <Column className="is-6">
            <Select
              onChange={(e) => this.onChangeInput(e, 'generalData')}
              options={paymentEstatus}
              showErrors={this.state.showErrors}
              {...this.state.generalData.estatusOrden}
            />
          </Column>
          <Column className="is-6">
            <Autocomplete
              suggestions={providers.data}
              showErrors={this.state.showErrors}
              property="nombreORazonSocial"
              initialInputValue={this.props.order ? this.props.order.nombreRazonSocial : ''}
              onFetchRequested={this.props.getProviders}
              onClearRequested={this.props.clearProvidersList}
              onChange={(e) => this.onChangeInput(e, 'generalData')}
              disabled={disableFields}
              {...this.state.generalData.idProveedor}

            />
          </Column>
        </Columns>
        <Divider content="GENERALES DE LA FACTURA" />
        <Columns>
          <Column className="is-6">
            <div className="file has-name is-right">
              <label className="file-label">
                <input className="file-input"
                  ref={(input) => { this.invoiceInput = input; }}
                  type="file"
                  name="file"
                  onChange={this.changeInvoice}
                  accept="application/xml"
                  disabled={this.state.order.id} />
                <span className="file-cta">
                  <span className="file-icon">
                    <Icon>
                      <FontAwesomeIcon icon={faPaperclip} />
                    </Icon>
                  </span>
                  <span className="file-label">
                    Seleccionar factura...
                  </span>
                </span>
                <Button onClick={this.uploadInvoice} disabled={!this.state.invoice || this.state.order.id}>
                  <Icon>
                    <FontAwesomeIcon icon={faUpload} />
                  </Icon>
                </Button>
                <Button onClick={this.removeInvoice} disabled={!this.state.invoice || this.state.order.id}>
                  <Icon>
                    <FontAwesomeIcon icon={faTimes} />
                  </Icon>
                </Button>
                <span className="file-name" style={{ width: '200px' }}>
                  {this.state.invoice && this.state.invoice.name}{'      '}
                </span>
              </label>
            </div>
          </Column>
          <Column className="is-6">
          </Column>
        </Columns>
        <AddressSection
          data={this.state.addressData}
          onChange={e => this.onChangeInput(e, 'addressData')}
          paises={paises}
          entidades={entidades}
          extranjero={false}
          showErrors={this.state.showErrors}
        />
        <Divider content="DATOS PARA PAGO" />
        <PaymentData
          data={this.state.paymentData}
          onChange={e => this.onChangeInput(e, 'paymentData')}
          showErrors={this.state.showErrors}
          paymentMethod={realPaymentMethod}
          accounts={accounts.data}
          disable={disableFields}
        />
        <Divider content="CONCEPTOS PAGOS" />
        <Columns>
          <Column>
            <Button
              disabled={this.state.loadedFromInvoice || installments.data.length}
              style={{ float: 'right' }}
              onClick={this.openConceptModal}
            >
              Agregar Concepto
            </Button>
          </Column>
        </Columns>
        <Table
          colsetup={conceptsCol}
          coldata={this.state.conceptos}
          tableClassName='table is-striped is-hoverable is-fullwidth'
          emptyTableMarkUp={(<span>Aun no hay conceptos</span>)}
          customCol={{
            customColName: 'acciones',
            renderFunc: this.customColActions
          }}
        />
        <Divider content="PAGOS" />
        <Columns>
          <Column>
            <Button
              success
              disabled={this.state.totals.montoPagar.value < 1}
              style={{ float: 'right' }}
              onClick={() => this.openInstallmentModal()}
            >
              Agregar pagos
            </Button>
          </Column>
        </Columns>
        <Table
          colsetup={installmentsCol}
          coldata={installments}
          tableClassName='table is-striped is-hoverable is-fullwidth'
          emptyTableMarkUp={(<span>Aun no hay pagos</span>)}
          customCol={{
            customColName: 'acciones',
            renderFunc: this.installmentActions
          }}
        />
        <Totals
          data={this.state.totals}
          onChangeInvoice={this.changeInvoice}
          onChangePdf={this.onChangePdf}
          showErrors={this.state.showErrors}
          uploadPdf={this.uploadPdf}
          uploadInvoice={this.uploadInvoice}
          providerInvoiceStatus={providerInvoiceStatus}
          invoicePdf={this.state.invoicePdf}
          invoice={this.state.invoice}
          folio={this.state.invoiceXml && this.state.invoiceXml['@Folio']}
          removeXML={this.removeInvoice}
          removePDF={this.removeInvoicePdf}
        />
        <Attachments
          files={attachments}
        />
      </Box>
    )
  }
}

const mapStateToProps = ({ catalogs, catalogos, paymentOrders, principal }) => ({
  providers: catalogs.providers.providers,
  provider: catalogs.providers.provider,
  typePaymentProviders: catalogos.typePaymentProviders,
  paymentEstatus: catalogos.paymentEstatus,
  paises: catalogos.paises,
  entidades: catalogos.entidades,
  invoice: paymentOrders.receiptFee.invoice,
  realPaymentMethod: catalogos.realPaymentMethod,
  accounts: catalogs.providers.accounts,
  providerInvoiceStatus: catalogos.providerInvoiceStatus,
  invoiceUnits: catalogos.invoiceUnits,
  ivaRates: reject(propEq('id', '4'))(catalogos.ivaRates),
  iepsRates: catalogos.iepsRates,
  order: paymentOrders.receiptFee.order,
  installments: paymentOrders.receiptFee.installments,
  partner: principal.profile.idIntegrado,
  attachments: paymentOrders.receiptFee.attachments,
  isFetching: paymentOrders.receiptFee.isFetchingReceiptFee,
  origin: paymentOrders.providers.origin,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getProviders,
  getTypePaymentProviders,
  getPaymentEstatus,
  getEntidades,
  getPaises,
  getProvider,
  uploadInvoice,
  getRealPaymentMethod,
  getProviderAccounts,
  createOrder,
  getProviderInvoiceStatus,
  addNotification,
  push,
  getInvoiceUnits,
  getIvaRates,
  getIepsRates,
  getOrder,
  getInstallments,
  addInstallment,
  deleteOrder,
  endOrderEdit,
  authorizeInstallment,
  deleteInstallment,
  updateOrder,
  clearInvoiceUnits,
  getAttachments,
  clearProvidersList,
  clearProvider,
  cleanOrigin,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Form)
