import React, { Fragment } from 'react'
import { Notification, Columns, Column } from 'Components/common/bulma'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

const Notificaciones = ({ notifications }) => {
  return (
    <Fragment>
      {notifications.map(({uuid, message, type}) => (
        <Notification key={uuid} success={type ==='success'} danger={type === 'danger'} className="mensaje-notificacion" >
          <Columns>
            <Column className="is-3">
              {type === 'success' && (<FontAwesomeIcon icon={faCheck} size="3x"/>)}
              {type === 'danger' && (<FontAwesomeIcon icon={faTimes} size="3x"/>)}
            </Column>
            <Column>
              {message}
            </Column>
          </Columns>
        </Notification>
        ))}
    </Fragment>
  );
};

export default Notificaciones;