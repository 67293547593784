import React from 'react'
import { Field, Input, Control, Icon } from 'Components/common/bulma'
import { Trans } from '@lingui/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'

const InputComponent = ({
  label,
  name,
  value,
  onChange,
  disabled = false,
  type = 'text',
  placeholder = '',
  errors,
  showErrors,
  className,
  depends,
  dependsValue,
  valid,
  dataMarshaller,
  dataUnmarshaller,
  tooltip,
  ...props
}) => {
  return (
    <Field>
      <label className="label"><Trans id={label}></Trans>
        {tooltip && <Icon className="tooltip is-tooltip-multiline is-tooltip-info has-text-danger" data-tooltip={tooltip} >
          <FontAwesomeIcon icon={faInfoCircle} />
        </Icon>}
      </label>
      <Control>
        <Input
          className={className}
          type={type}
          name={name}
          placeholder={placeholder || label}
          value={value}
          onChange={onChange}
          disabled={disabled}
          {...props} />
      </Control>
      {(errors && !!errors.length && showErrors) && <p className="has-text-danger help">{errors[0].message}</p>}
    </Field>
  )
}

export default React.memo(InputComponent);
