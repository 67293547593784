import { useRef, useCallback, useState } from 'react';
import { getOnboardingFlow } from 'App/hexmodules/onboardingFlow/application/get/getOnboardingFlow'
import { useDependencies } from 'App/DependencyContext';


const STATE = {
  INITIAL: 'initial',
  FETCHING_GENERAL_DATA: 'fetchingGeneralData',
  FETCHED_GENERAL_DATA: 'fetchedGeneralData',
  ERROR_FETCHING_GENERAL_DATA: 'errorFetchingGeneralData',
  SAVING_GENERAL_DATA: 'savingGeneralData',
  SAVED_GENERAL_DATA: 'savedGeneralData',
  ERROR_SAVING_GENERAL_DATA: 'errorSavingGeneralData',
};

function useOnboarding() {
  const state = useRef(STATE.INITIAL);
  const [onboardingFlow, setOnboardingFlow] = useState({})
  const { onboardingFlowRepository } = useDependencies()

  const fetchFlow = async () => {
    const { data } = await getOnboardingFlow(onboardingFlowRepository);
    setOnboardingFlow(data.data)
  }

  const initialFetch = async () => {
    if (state.current === STATE.INITIAL) {
      state.current = STATE.FETCHING_GENERAL_DATA;
      await fetchFlow();
      state.current = STATE.FETCHED_GENERAL_DATA;
    }
  };

  initialFetch();


  const getCurrentState = useCallback(() => {
    return state.current;
  }, []);

  return {
    getCurrentState,
    onboardingFlow,
    refreshFlow: fetchFlow,
  };
}

export default useOnboarding;