import http from '../../../services/httpService';
import BankAccountRepository from '../domain/bankAccountRepository';

export default class RemoteBankAccountRepository extends BankAccountRepository {
  async get() {
    try {
      const { data } = await http.get(`/onboarding/bank-account`);
      return data.data;
    } catch (error) {
      console.error(`Error retrieving bank account with id:`, error);
      throw error;
    }
  }

  async save(bankAccount) {
    try {
      const response = await http.post('/onboarding/bank-account', bankAccount);
      return response.data;
    } catch (error) {
      console.error('Error creating bank account:', error);
      throw error;
    }
  }

  async update(bankAccount, bankAccountId) {
    try {
      const response = await http.put(`/onboarding/bank-account/${bankAccountId}`, bankAccount);
      return response.data;
    } catch (error) {
      console.error(`Error updating bank account with id :`, error);
      throw error;
    }
  }

  async delete(bankAccountId) {
    try {
      await http.delete(`/onboarding/bank-account/${bankAccountId}`);
    } catch (error) {
      console.error(`Error deleting bank account with id :`, error);
      throw error;
    }
  }
}
