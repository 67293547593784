import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Message, Button, Delete } from 'Components/common/bulma'

const InformationModal = ({
  isOpen,
  title,
  closeModal,
  content,
}) => {
  return (
    <Modal isActive={isOpen}>
      <Message success>
        <Message.Header>
          <p>{title}</p>
          <Delete onClick={closeModal} />
        </Message.Header>
        <Message.Body className="has-text-centered">
          {content}
          <br />
          <div className="margin-top-lg">
            <Button success onClick={closeModal}>Aceptar</Button>
          </div>
        </Message.Body>
      </Message>
    </Modal>
  )
}

InformationModal.propTypes = {
  isDeleting: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  confirmText: PropTypes.string.isRequired,
  resource: PropTypes.string,
  deleteResource: PropTypes.func.isRequired,
}

export default InformationModal

