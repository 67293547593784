import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router'
import { clone } from 'ramda'
import ActionBar from '../../../components/common/actionBar';
import { Modal, Button, Message, Delete } from 'Components/common/bulma'
import CatalogoMonederoGasolina from '../../../components/catalogos/catalogoMonederoGasolina';
import monederoGasolinaData from './monederoGasolinaData';
import { getEstatusPersona, getSingleMonederoGasolina, crearMonederoGasolina, updateMonederoGasolina, deleteWallet, endEditWallet } from 'Modules/catalogos'
import { getDataInput, validateData, getValues, mapPropsToState  } from '../../../helpers';
import { addNotification } from 'Modules/principal';

class MonederoGasolina extends Component {
  state = {
    monederoGasolinaData: clone(monederoGasolinaData),
    showErrors: false,
    showModal: false,
    wallet: undefined,
  }
  componentDidMount() {
    const { getEstatusPersona, getSingleMonederoGasolina } = this.props;

    getEstatusPersona();
    const id = this.props.match.params.id;
    if (id) {
      getSingleMonederoGasolina(id)
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.wallet.id && nextProps.wallet.id !== this.state.wallet) {
      const monederoGasolinaData = mapPropsToState(this.state.monederoGasolinaData, nextProps.wallet)

      this.setState({
        wallet: nextProps.wallet.id,
        monederoGasolinaData,
      })
    }
  }
  componentWillUnmount() {
    this.props.endEditWallet()
  }
  submitMonederoGasolina = () => {
    const { crearMonederoGasolina, updateMonederoGasolina } = this.props;

    const { data: monederoGasolinaData, hasErrors } = validateData(this.state.monederoGasolinaData);

    if (hasErrors) {
      this.setState({
        monederoGasolinaData,
        showErrors: true,
      })
      return
    }
    const monederoGasolinaValues = getValues(this.state.monederoGasolinaData);

    if (this.state.wallet) {
      updateMonederoGasolina({ id: this.state.wallet, ...monederoGasolinaValues, reposicion: true});
    } else {
      crearMonederoGasolina({ ...monederoGasolinaValues});
    }
  }

  onChangeInput = ({ target }) => {
    const { name } = target;
    const monederoGasolinaData = getDataInput(target, this.state.monederoGasolinaData)
    const field = monederoGasolinaData[name]

    this.setState({
      monederoGasolinaData,
      showErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }

  onCancel = () => {
    const { push } = this.props;
    push(`/catalogos/monederos-gasolina/`);
  }

  getPermissions = () => {
    return {
      canDelete: !!this.state.wallet,
      canSave: true
    }
  }
  toggleModal = () => {
    this.setState(state => ({
      showModal: !state.showModal,
    }))
  }
  deleteWallet = () => {
    this.props.deleteWallet(this.state.wallet)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'El monedero se eliminó correctamente'
        })
        this.props.push(`/catalogos/monederos-gasolina`)
      })
  }
  render() {
    return (
      <Fragment >
        <Modal isActive={this.state.showModal}>
          <Message danger>
            <Message.Header>
              <p>Eliminar monedero de gastos</p>
              <Delete onClick={this.toggleModal} />
            </Message.Header>
            <Message.Body className="has-text-centered">
              ¿Esta seguro de querer eliminar el monedero?
            <br />
              <strong>{this.props.wallet.target}</strong>
              <div className="margin-top-lg">
                <Button danger onClick={this.deleteWallet}>Eliminar</Button>
                <Button default className="margin-left-sm" onClick={this.toggleModal}>
                  Cancelar
              </Button>
              </div>
            </Message.Body>
          </Message>
        </Modal>
        <ActionBar
          onSave={this.submitMonederoGasolina}
          onCancel={this.onCancel}
          onDelete={this.toggleModal}
          basicRole={['Oalta']}
          permissions={this.getPermissions()}
        />
        <CatalogoMonederoGasolina
          {...this.props}
          {...this.state}
          onChange={this.onChangeInput}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ catalogos, }) => ({
  estatusPersona: catalogos.estatusPersona,
  wallet: catalogos.wallet,
  isFetching: catalogos.isFetchingGas
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getEstatusPersona,
  crearMonederoGasolina,
  updateMonederoGasolina,
  getSingleMonederoGasolina,
  push,
  deleteWallet,
  addNotification,
  endEditWallet,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MonederoGasolina)
