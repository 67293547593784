
export const product = {
  estatus: {
    name: 'estatus',
    value: '',
    label: 'Estatus',
    disabled: true,
  },
  id: {
    value: '',
  },
  concepto: {
    name: 'concepto',
    label: 'Concepto',
    value: '',
    validation: 'required',
  },
  unidad: {
    name: 'unidad',
    label: 'Unidad',
    value: '',
    validation: 'required',
    placeholder: 'Buscar unidad...',
  },
  unitario: {
    name: 'unitario',
    label: 'Unitario',
    value: '',
    validation: 'required',
    pattern: 'number',
  },
  tasaIeps: {
    name: 'tasaIeps',
    label: 'Tasa IEPS',
    value: '',
    // validation: 'required',
    valueProperty: 'id',
    valueLabel: 'tipo',
  },
  tasaIva: {
    name: 'tasaIva',
    label: 'Tasa IVA',
    value: '',
    validation: 'required',
    valueProperty: 'id',
    valueLabel: 'tipo',
  },
  claveProductoServicioSAT: {
    name: 'claveProductoServicioSAT',
    label: 'Clave Producto Servicio',
    value: '',
    validation: 'required',
    valueProperty: 'id',
    valueLabel: 'tipo',
    placeholder: 'Buscar producto...'
  },
  identificador: {
    name: 'identificador',
    label: 'Identificador',
    maxLength: 11,
    value: '',
    validation: 'required',
  },
  tasaIVA: {
    same: 'tasaIva',
  },
  tasaIEPS: {
    same: 'tasaIeps',
  },
  manejaInventario: {
    name: 'manejaInventario',
    value: '',
    label: 'Maneja Inventario',
    dataUnmarshaller: val => val === '1' ? true : false,
  },
  objetoImp: {
    name: 'objetoImp',
    value: '',
    label: 'Objeto de Impuesto',
    valueProperty: 'id',
    valueLabel: 'descripcion',
    validation: 'required',
  },
  pedimentoAduana: {
    name: 'pedimentoAduana',
    value: '',
    label: 'Pedimento Aduana',
    validation: 'aduana',
    maxLength: '21'
  },
  unitarioDescuento: {
    name: 'unitarioDescuento',
    value: '',
    label: 'Descuento Unitario',
  },
  tasaRetIVA: {
    name: 'tasaRetIVA',
    value: '',
    label: 'Retención IVA',
  },
  tasaRetISR: {
    name: 'tasaRetISR',
    value: '',
    label: 'Retención ISR',
  },
  tasaRetIEPS: {
    name: 'tasaRetIEPS',
    value: '',
    label: 'Retención IEPS',
  }
}

export const productList = [
  {
    title: 'Concepto',
    datakey: 'concepto',
    sortable: true,
    width: '45%',
  },
  {
    title: 'Identificador',
    datakey: 'identificador',
    sortable: true,
    width: '45%',
  },
  {
    title: 'Acciones',
    custom: 'actions',
    width: '10%',
  },
]