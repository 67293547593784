import {
  NACIONAL,
  EXTRANJERO,
  DIRECTO,
  INTERMEDIARIO,
  CONVENIO_CIE_BBVA,
} from 'App/constants'

export const collaboratorData = {
  apellidoPaterno: {
    value: '',
    name: 'apellidoPaterno',
    label: 'Apellido Paterno',
    validation: 'required',
  },
  apellidoMaterno: {
    value: '',
    name: 'apellidoMaterno',
    label: 'Apellido Materno',
    validation: 'required',
  },
  nombres: {
    value: '',
    name: 'nombres',
    label: 'Nombre(s)',
    validation: 'required',
  },
  rfc: {
    value: '',
    name: 'rfc',
    label: 'RFC',
    validation: 'required|rfc|isLength:13',
  },
  curp: {
    value: '',
    name: 'curp',
    label: 'CURP',
    validation: 'required|isLength:18|curp',
    className: 'is-uppercase',
    maxLength: 18,
  },
  correoElectronico: {
    value: '',
    name: 'correoElectronico',
    label: 'Correo Electrónico',
    validation: 'required|email',
  },
  estatus: {
    value: '1',
    name: 'estatus',
    label: 'Estatus',
    validation: 'required',
    disabled: true,
  },
  uriCsf: {
    value: '',
    name: 'uriCsf',
    label: '',
  }
}

export const accountColDef = [
  {
    title: 'Estatus',
    datakey: 'estatus'
  },
  {
    title: 'Tipo Cuenta',
    datakey: 'tipoCuenta',
  },
  {
    title: 'Clabe',
    datakey: 'clabe'
  },
  {
    title: 'Banco',
    datakey: 'banco',
  },
  {
    title: 'Acciones',
    custom: 'acciones'
  }
]
export const accountData = {
  tipoCuenta: {
    value: '',
    name: 'tipoCuenta',
    label: 'Tipo de Cuenta',
    options: [
      { value: 'Nacional (MX)', label: 'Nacional (MX)' },
    ]
  },
  clabe: {
    value: '',
    name: 'clabe',
    label: 'Clabe',
    pattern: 'number',
    trigger: 'onChangeClabe',
    validation: 'requiredIfCustom:clabe|isLength:18|clabe',
    depends: 'tipoCuenta',
    dependsValue: NACIONAL,
    maxLength: 18,
  },
  cuentaBancaria: {
    value: '',
    name: 'cuentaBancaria',
    label: 'Cuenta Bancaria',
    disabled: true,
  },
  banco: {
    value: '',
    name: 'banco',
    label: 'Banco',
    disabled: true,
  },
  numeroSucursal: {
    value: '',
    name: 'numeroSucursal',
    label: 'Número Sucursal',
    pattern: 'number',
  },
  plaza: {
    value: '',
    name: 'plaza',
    label: 'Plaza',
    disabled: true,
  },
  pagoDirectoOconBancoIntermediario: {
    value: '',
    name: 'pagoDirectoOconBancoIntermediario',
    label: 'Pago directo o intermediario',
    validation: 'requiredIfCustom:pagoDirectoOconBancoIntermediario',
    depends: 'tipoCuenta',
    dependsValue: EXTRANJERO,
  },
  directoBeneficiario: {
    value: '',
    name: 'directoBeneficiario',
    label: 'Beneficiario',
    validation: 'requiredIfCustom:directoBeneficiario',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: DIRECTO,
  },
  directoDireccion: {
    value: '',
    name: 'directoDireccion',
    label: 'Direccion',
    validation: 'requiredIfCustom:directoDireccion',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: DIRECTO,
  },
  directoIBAN: {
    value: '',
    name: 'directoIBAN',
    label: 'IBAN',
    validation: 'requiredIfCustom:directoIBAN',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: DIRECTO,
  },
  directoSwiftCode: {
    value: '',
    name: 'directoSwiftCode',
    label: 'Swift Code',
    validation: 'requiredIfCustom:directoSwiftCode',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: DIRECTO,
  },
  directoVATID: {
    value: '',
    name: 'directoVATID',
    label: 'VAT ID',
  },
  directoBanco: {
    value: '',
    name: 'directoBanco',
    label: 'Banco',
  },
  interNombreBancoIntermediario: {
    value: '',
    name: 'interNombreBancoIntermediario',
    label: 'Nombre del Banco Intermediario',
    validation: 'requiredIfCustom:interNombreBancoIntermediario',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: INTERMEDIARIO,
  },
  interPais: {
    value: '',
    name: 'interPais',
    label: 'País',
    validation: 'requiredIfCustom:interPais',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: INTERMEDIARIO,
  },
  interSwiftCode: {
    value: '',
    name: 'interSwiftCode',
    label: 'Swift Code del Banco Intermediario',
    validation: 'requiredIfCustom:interPais',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: INTERMEDIARIO,
  },
  interSortCode: {
    value: '',
    name: 'interSortCode',
    label: 'Sort Code',
    validation: 'requiredIfCustom:interSortCode',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: INTERMEDIARIO,
  },
  interNombreBancoReceptor: {
    value: '',
    name: 'interNombreBancoReceptor',
    label: 'Nombre del Banco Receptor',
    validation: 'requiredIfCustom:interNombreBancoReceptor',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: INTERMEDIARIO,
  },
  interPaisEstado: {
    value: '',
    name: 'interPaisEstado',
    label: 'País y Estado',
    validation: 'requiredIfCustom:interPaisEstado',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: INTERMEDIARIO,
  },
  interSwiftCodeBanco: {
    value: '',
    name: 'interSwiftCodeBanco',
    label: 'Swift Code Banco Receptor',
    validation: 'requiredIfCustom:interSwiftCodeBanco',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: INTERMEDIARIO,
  },
  interNombreCuentaBancoReceptor: {
    value: '',
    name: 'interNombreCuentaBancoReceptor',
    label: 'Nombre de la Cuenta Banco Receptor',
    validation: 'requiredIfCustom:interNombreCuentaBancoReceptor',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: INTERMEDIARIO,
  },
  interNumeroCuentaBancoReceptor: {
    value: '',
    name: 'interNumeroCuentaBancoReceptor',
    label: 'Número de cuenta de Banco Receptor',
    pattern: 'number',
    validation: 'requiredIfCustom:interNumeroCuentaBancoReceptor',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: INTERMEDIARIO,
  },
  interBankInstructions: {
    value: '',
    name: 'interBankInstructions',
    label: 'Intermediary Bank Instructions (Fo...',
  },
  interNombreBeneficiario: {
    value: '',
    name: 'interNombreBeneficiario',
    label: 'Nombre del beneficiario',
    validation: 'requiredIfCustom:interNombreBeneficiario',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: INTERMEDIARIO,
  },
  interCiudadPaisBeneficiario: {
    value: '',
    name: 'interCiudadPaisBeneficiario',
    label: 'Ciudad y País del Beneficiario',
    validation: 'requiredIfCustom:interCiudadPaisBeneficiario',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: INTERMEDIARIO,
  },
  interCuentaBeneficiario: {
    value: '',
    name: 'interCuentaBeneficiario',
    label: 'Número de Cuenta del Beneficiario',
    validation: 'requiredIfCustom:interCuentaBeneficiario',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: INTERMEDIARIO,
  },
  encabezadoEstadoCuenta: {
    value: '',
    name: 'encabezadoEstadoCuenta',
    label: 'Encabezado Estado de Cuenta',
    radioValue: 'encabezadoEstadoCuenta',
  },
  estatus: {
    value: '',
    name: 'estatus',
    label: 'Estatus',
    validation: 'required',
  },
  concatenado: {
    value: '',
    name: 'concatenado',
    label: '',
  },
  convenioBBVA: {
    value: '',
    name: 'convenioBBVA',
    label: 'Convenio CIE BBVA Bancomer',
    validation: 'requiredIfCustom:convenioBBVA',
    depends: 'tipoCuenta',
    dependsValue: CONVENIO_CIE_BBVA,
  },
  referencia: {
    value: '',
    name: 'referencia',
    label: 'Referencia',
    validation: 'requiredIfCustom:referencia',
    depends: 'tipoCuenta',
    dependsValue: CONVENIO_CIE_BBVA,
  },
  concepto: {
    value: '',
    name: 'concepto',
    label: 'Concepto',
  },
  concatenado2: {
    value: '',
    name: 'concatenado2',
    label: '',
  },
  comisionista: {
    value: '',
    name: 'comisionista',
    label: '',
  },
}

export const colDef = [
  {
    title: 'Estatus',
    datakey: 'estatus',
    sortable: true,
    width: '10%',
  },
  {
    title: 'Apellido P',
    datakey: 'apellidoPaterno',
    sortable: true,
    width: '20%',
  },
  {
    title: 'Apellido M',
    datakey: 'apellidoMaterno',
    sortable: true,
    width: '20%',
  },
  {
    title: 'Nombre',
    datakey: 'nombres',
    sortable: true,
    width: '20%',
  },
  {
    title: 'Rfc',
    datakey: 'rfc',
    sortable: true,
    width: '20%',
  },
  {
    title: 'Acciones',
    custom: 'action',
    width: '10%',
  },
]

export const addressData = {
  direccion: {
    value: '',
    transform: 'stringToBool',
    trigger: 'onChangeDireccion',
    name: 'direccion',
    label: 'Dirección',
  },
  pais: {
    name: 'pais',
    value: '141',
    validation: 'requiredIf:direccion',
    label: 'País',
  },
  cp: {
    value: '',
    trigger: 'onChangeCp',
    pattern: 'number',
    name: 'cp',
    label: 'Código postal',
    validation: 'required|minLength:5|greaterThan:0',

  },
  entidadFederativa: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'entidadFederativa',
    label: 'Entidad Federativa/Estado/Provincia',
  },
  municipio: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'municipio',
    label: 'Municipio',
  },
  colonia: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'colonia',
    label: 'Colonia',
  },
  calle: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'calle',
    label: 'Calle',
  },
  exterior: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'exterior',
    label: 'Exterior',
  },
  interior: {
    value: '',
    name: 'interior',
    label: 'Interior',
  },
}

export const regimenFiscalReceptor = {
  regimenFiscalReceptor: {
    value: '',
    name: 'regimenFiscalReceptor',
    label: 'Régimen Fiscal Receptor',
    valueProperty: 'id',
    valueLabel: 'descripcion',
    validation: 'required',
  }
}