import React from 'react'
import classnames from 'classnames'
import numeral from 'numeral'
import { is } from 'ramda'

const TableRow = ({ colsetup, row, customCol }) => {
  return (
    <tr>
      {colsetup.map((column, i) => {

        const value = column.custom ? (column.custom === customCol.customColName && customCol.renderFunc(row, i)) : row[column.datakey]
        return (
          <td
            key={i}
            width={column.width ?? '10%'}
            className={classnames({
              'has-text-centered': column.custom === 'acciones',
              'has-text-right': column.align ? false : !!column.format
            })}
            align={column.align}>
            {
              !!column.format && (is(Function, column.format) ? column.format(value) : <strong>{numeral(value).format(column.format)}</strong>)
            }
            {
              !!column.format || (column.render ? column.render(value) : value)
            }
          </td>
        )
      }
      )}
    </tr>
  );
};

export default TableRow;
