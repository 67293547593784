import { cond, always, T, F, converge, or, pipe, not } from 'ramda';
import { isEmpty, lessThan } from 'App/helpers'
import { isNumeric } from 'ramda-extension'
import moment from 'moment'

export const fields = {
  id: {
    input: {
      value: '',
      name: 'id',
    },
  },
  beneficiary_name: {
    input: {
      value: '',
      name: 'beneficiary_name',
    },
    label: 'Nombre Completo',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  relationship: {
    input: {
      value: '',
      name: 'relationship',
    },
    label: 'Parentesco',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  date_birth: {
    input: {
      value: '',
      name: 'date_birth',
    },
    label: 'Fecha de nacimiento',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
    unmarshaller: value => value ? new Date(value) : undefined,
    marshall: (value) => value ? moment(value).format('YYYY-MM-DD') : undefined,
  },
  percent: {
    input: {
      value: '',
      name: 'percent',
    },
    label: 'Porcentaje',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [lessThan(100), always({ error: 'Este campo debe ser menor a 100' })],
      [T, F]
    ]),
    pattern: cond([
      [pipe(converge(or, [isNumeric, isEmpty]), not), F],
      [pipe(lessThan(100)), F],
      [T, T]
    ])
  },
  beneficiary_type: {
    input: {
      value: '',
      name: 'beneficiary_type',
    },
    label: 'Tipo',
  },

};
