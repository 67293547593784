import React, { Fragment, useState } from 'react'
import CountDown from 'react-countdown-clock'
import {
  Button,
  Control,
  Field,
  Notification,
  Delete,
} from 'Components/common/bulma'
import { InputWithError } from 'Components/common/form'
import useForm from '../../hooks/useForm'
import { anyPass } from 'ramda'
import { isNumeric, isNilOrEmptyString } from 'ramda-extension'

const Login = ({
  loginError,
  randomKey,
  refreshRandomKey,
  completions,
  hideLoginError,
  submitting,
  prelogin,
  loginWithTas,
}) => {
  const initialCardFields = {
    card: {
      input: {
        value: '',
        name: 'card',
        maxLength: 3,
      },
      validation: 'required|isLength:3',
      pattern: anyPass([isNumeric, isNilOrEmptyString]),
    },
  };
  const [
    // eslint-disable-next-line
    cardFields, setCardFields, onCardFieldChange, isValidCardForm, getCardValues, showCardErrors
  ] = useForm({ fields: initialCardFields });
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState({})

  const handleOnLogin = () => {
    loginWithTas({ ...getCardValues(), 'verification-token': prelogin['verification-token'] })
    .catch((error) => {
      setHasError(true)
      setErrorMessage(error.response.data)
    });
  }

  const hideError = () => {
    setHasError(false)
  }

  return (
    <Fragment>
      <form>
        <Field>
          <Control>
            <label >Acesso seguro <strong><em>{randomKey.randomCard}:</em></strong></label>
          </Control>
        </Field>
          <Field className="is-horizontal">
            <div className="field-body">
              <Field className="padding-left-md">
                <Control>
                  <CountDown
                    key={completions}
                    seconds={60}
                    color="red"
                    alpha={0.9}
                    size={35}
                    onComplete={refreshRandomKey} />
                </Control>
              </Field>
              <Field>
                <Control>
                  <InputWithError
                    onChange={onCardFieldChange}
                    {...cardFields.card}
                  />
                  {(!cardFields.card.valid && showCardErrors) && (
                    <p className="help is-danger">
                      {cardFields.card.error}
                    </p>
                  )}
                </Control>
              </Field>
            </div>
          </Field>

        {hasError && (
          <Notification danger>
            <Delete onClick={hideError} />
            {errorMessage.message}
          </Notification>
        )}
        <Button
          type="button"
          onClick={handleOnLogin}
          disabled={submitting}
          loading={submitting}
          className="is-block is-black is-fullwidth is-medium">
          Entrar
        </Button>
      </form>
    </Fragment>
  )
}

export default Login
