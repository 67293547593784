import React, { useState } from 'react'
import { Button } from 'Components/common/bulma'
import { ModalRemove, InformationModal } from 'Components/common'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createCreditNoteAction } from 'Modules/incomeOrders/creditNote'
import numeral from 'numeral'
import { addNotification as addNotificationAction } from 'Modules/principal';

function CancelOrder({
  createCreditNote,
  order,
  options,
  addNotification,
  onClose,
}) {
  const [isConfirming, setIsConfirming] = useState(false)
  const [isCanceled, setIsCanceled] = useState(false)
  const confirmCancel = () => {
    setIsConfirming(true)
  }

  const closeConfirm = () => {
    setIsConfirming(false)
  }

  const handleOnCancel = () => {
    setIsConfirming(false)
    createCreditNote().then(() => {
      setIsCanceled(true)
    }).catch(({ response: { data } }) => {
      addNotification({
        type: 'danger',
        message: data.message,
      })
    })
  }

  const closeCanceledModal = () => {
    setIsCanceled(false)
    onClose(true)
  }

  return (
    <>
      <InformationModal
        isOpen={isCanceled}
        closeModal={closeCanceledModal}
        title="Orden cancelada"
        content="Petición recibida, en las próximas horas se emitirá la nota de crédito."
      />
      <ModalRemove
        isDeleting={isConfirming}
        title="Cancelar Orden"
        toggleModal={closeConfirm}
        confirmText="Se procederá a crear la nota de crédito"
        resource={order.id}
        deleteResource={handleOnCancel}
        actionButtonLabel="Emitir"
        cancelButtonLabel="Regresar"
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        Se Emitirá la nota de crédito por el siguiente monto  {numeral(options?.Generar_Nota_de_Credito_para_cancelar_Monto_por_cobrar?.Monto_Por_Cobrar).format('$ 0,0.00')} para cancelar el monto por cobrar de la factura.
        <Button onClick={confirmCancel}>
          Generar nota de crédito
        </Button>
      </div>
    </>
  )
}

const mapStateToProps = ({ incomeOrders: { invoice } }) => ({
  order: invoice.order,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  createCreditNote: createCreditNoteAction,
  addNotification: addNotificationAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CancelOrder)
