import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { Box, Icon, Column, Columns, Modal, Button, Message, Delete } from 'Components/common/bulma'
import { default as Table } from 'Components/common/table'
import {
  getCollaborators,
  deleteCollaborator,
} from 'Modules/catalogs/collaborators'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'
import { Trans } from '@lingui/macro'
import { colDef } from './data'

export class List extends Component {
  state = {
    showModal: false,
    collaborator: {}
  }

  onEdit = ({ id }) => {
    this.props.push(`/catalogos/colaboradores/${id}`)
  }

  toggleModal = (collaborator) => {
    this.setState(state => ({
      showModal: !state.showModal,
      collaborator,
    }))
  }

  deleteCollaborator = () => {
    this.props.deleteCollaborator(this.state.collaborator.id)
      .then(() => {
        this.setState(() => ({
          showModal: false
        }))
      })
  }

  customColActions = (row) => (
    <div>
      <Button small primary outlined onClick={() => this.onEdit(row)}>
        <Icon>
          <FontAwesomeIcon icon={faEdit} />
        </Icon>
      </Button>
      <Button small danger outlined onClick={() => this.toggleModal(row)} className="margin-left-xs">
        <Icon>
          <FontAwesomeIcon icon={faTrash} />
        </Icon>
      </Button>
    </div>
  )

  render() {
    const { collaborators } = this.props
    return (
      <Box>
        <Modal isActive={this.state.showModal}>
          <Message danger>
            <Message.Header>
              <p>Eliminar colaborador</p>
              <Delete onClick={this.toggleModal} />
            </Message.Header>
            <Message.Body className="has-text-centered">
              ¿Esta seguro de querer eliminar el colaborador?
            <br />
              <strong>{this.state.collaborator.nombreConcatenado}</strong>
              <div className="margin-top-lg">
                <Button danger onClick={this.deleteCollaborator}>Eliminar</Button>
                <Button default className="margin-left-sm" onClick={this.toggleModal}>
                  Cancelar
              </Button>
              </div>
            </Message.Body>
          </Message>
        </Modal>
        <Columns>
          <Column className="has-text-right">
            <Link className="button is-primary is-outlined" to="/catalogos/colaboradores/nuevo">
              <Icon>
                <FontAwesomeIcon icon={faPlus} />
              </Icon>
              <Trans render="span">Agregar Colaborador</Trans>
            </Link>
          </Column>
        </Columns>
        <Table
          filterable={true}
          colsetup={colDef}
          coldata={collaborators}
          tableClassName='table is-striped is-hoverable is-fullwidth'
          emptyTableMarkUp={(<span>No se encontraron colaboradores</span>)}
          customCol={{
            customColName: 'action',
            renderFunc: this.customColActions
          }}
          refresh={this.props.getCollaborators}
        />
      </Box>
    )
  }
}

const mapStateToProps = ({ catalogs }) => ({
  collaborators: catalogs.collaborators.collaborators,
  isFetchingCollaborators: catalogs.collaborators.isFetchingCollaborators
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getCollaborators,
  push,
  deleteCollaborator,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(List)
