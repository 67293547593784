import React, { useState } from 'react'
import { Box, Columns, Column, Icon, Button } from 'Components/common/bulma'
import { Table } from 'Components/common'
import contractsColumnsDefinitions from './contractsColumnDefinitions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Trans } from '@lingui/macro'
import { Link } from 'react-router-dom'
import { getContractsAction, deleteContractAction } from 'Modules/contracts'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ActionMenu, ModalRemove } from 'Components/common'
import Loader from 'Components/common/page/loaderFloat';
import { push as pushAction } from 'connected-react-router';
import Show from 'Components/common/Show';
import { addNotification as addNotificationAction } from 'Modules/principal'


function Index({
  getContracts,
  contracts,
  push,
  deleteContract,
  addNotification,
}) {
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [contractWillRemove, setContractWillRemove] = useState(undefined)

  const onEdit = ({ id }) => {
    push(`/contratos/${id}`)
  }

  const onRemove = ({ id }) => {
    setShowModal(true)
    setContractWillRemove(id)
  }

  const handleOnDelete = () => {
    setIsLoading(true)
    deleteContract(contractWillRemove)
      .then(() => {
        setContractWillRemove(undefined)
        addNotification({
          type: 'success',
          message: 'El contracto se eliminó correctamente'
        })
        getContracts()
          .then(() => {
            setIsLoading(false)
          })
      }).catch(() => addNotification({
        type: 'danger',
        message: 'Ocurrió un error al eliminar el contrato'
      }))
    setShowModal(false)
  }
  const customColActions = (row, id, fetchData) => (<div style={{ width: '78px' }}>
    <ActionMenu>
      <Button small primary outlined onClick={() => onEdit(row)} >
        <Icon>
          <FontAwesomeIcon icon={faEdit} />
        </Icon>
      </Button>
      {+row.estatus === 1 && <Button small className="margin-left-xs" danger outlined onClick={() => onRemove(row, fetchData)}>
        <Icon>
          <FontAwesomeIcon icon={faTrash} />
        </Icon>
      </Button>}
    </ActionMenu>
  </div>)

  return (
    <Box style={{ position: 'relative' }}>
      <Show canShow={isLoading}>
        <Loader />
      </Show>
      <ModalRemove
        isDeleting={showModal}
        title="Eliminar contrato"
        toggleModal={() => setShowModal(false)}
        confirmText="¿Desea eliminar el contrato?"
        resource=""
        deleteResource={handleOnDelete}
      />
      <Columns>
        <Column>
          <Link className="button is-success is-outlined is-pulled-right" to="/contratos/nuevo">
            <Icon>
              <FontAwesomeIcon icon={faPlus} />
            </Icon>
            <Trans render="span">Agregar contrato</Trans>
          </Link>
        </Column>
      </Columns>
      <Table
        colsetup={contractsColumnsDefinitions}
        coldata={contracts}
        tableClassName='table is-striped is-hoverable is-fullwidth'
        emptyTableMarkUp={<span>No hay registros</span>}
        customCol={{
          customColName: 'actions',
          renderFunc: customColActions
        }}
        initialFetch
        refresh={getContracts}
      />
    </Box>
  )
}

const mapStateToProps = ({ contracts }) => ({
  contracts: contracts.contracts,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getContracts: getContractsAction,
  push: pushAction,
  deleteContract: deleteContractAction,
  addNotification: addNotificationAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Index)