import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getAccount, getLegalRepresentative, getPartnerProfile } from 'Modules/principal'
import { Tabs } from 'Components/common/bulma'
import { Error404 } from 'Components/auth'

import GeneralData from './components/GeneralData';
import FiscalData from './components/FiscalData';
import ForeignFiscalData from './components/ForeignFiscalData';
import LegalEntityInfo from './components/LegalEntityInfo';
import ForeignLegalEntityInfo from './components/ForeignLegalEntityInfo';
import PersonalInfo from './components/PersonalInfo';
import ForeignPersonalInfo from './components/ForeignPersonalInfo';
import BankAccounts from './components/BankAccounts';
import ForeignBankAccounts from './components/ForeignBankAccounts';
import LegalRepresentativeData from './components/LegalRepresentativeData';
import ForeignLegalRepresentativeData from './components/ForeignLegalRepresentativeData';
import Beneficiaries from './components/Beneficiaries';
import Finish from './components/Finish';

import {
  GeneralData as GeneralDataLink,
  FiscalData as FiscalDataLink,
  ForeignFiscalData as ForeignFiscalDataLink,
  LegalEntityInfo as LegalEntityInfoLink,
  ForeignLegalEntityInfo as ForeignLegalEntityInfoLink,
  PersonalInfo as PersonalInfoLink,
  ForeignPersonalInfo as ForeignPersonalInfoLink,
  BankAccounts as BankAccountsLink,
  ForeignBankAccounts as ForeignBankAccountsLink,
  LegalRepresentativeData as LegalRepresentativeDataLink,
  ForeignLegalRepresentativeData as ForeignLegalRepresentativeDataLink,
  Beneficiaries as BeneficiariesLink,
  FinishTab as FinishLink,
} from './navLinks';
import useOnboarding from './useOnboarding'


import { cond, equals, pipe, pathOr } from 'ramda';
import { toNumber } from 'ramda-adjunct';

const isLegalEntity = pipe(toNumber, equals(1))
const isNaturalPerson = pipe(toNumber, equals(2))
const isForeign = pipe(toNumber, equals(4))
const isForeignLegalEntity = pipe(toNumber, equals(7))

const LinkMoralEmpresa = (flow = {}) => {
  return () => (
    <>
      <GeneralDataLink isCompleted={flow.datosGeneralesCompletados} />
      <FiscalDataLink isCompleted={flow.datosFiscalesCompletados} />
      <LegalEntityInfoLink isCompleted={flow.datosEmpresaCompletados} />
      <BankAccountsLink isCompleted={flow.cuentaBancariaCompletada} />
      <LegalRepresentativeDataLink isCompleted={flow.representanteLegalCompletado} />
      <FinishLink isCompleted={flow.formulariosCompletos} />
    </>
  );
}

const LinkPersonaFisica = (flow = {}) => {
  return () => (
    <>
      <GeneralDataLink isCompleted={flow.datosGeneralesCompletados} />
      <FiscalDataLink isCompleted={flow.datosFiscalesCompletados} />
      <PersonalInfoLink isCompleted={flow.datosPersonalesCompletados} />
      <BankAccountsLink isCompleted={flow.cuentaBancariaCompletada} />
      <BeneficiariesLink isCompleted={flow.beneficiarioCompletado} />
      <FinishLink isCompleted={flow.formulariosCompletos} />
    </>
  );
};

const LinkExtranjeroFisica = (flow = {}) => {
  return () => (
    <>
      <GeneralDataLink isCompleted={flow.datosGeneralesCompletados} />
      <ForeignFiscalDataLink isCompleted={flow.esExtranjero ? flow.datosFiscalesCompletados : false} />
      <ForeignPersonalInfoLink isCompleted={flow.esExtranjero ? flow.datosPersonalesCompletados : false} />
      <ForeignBankAccountsLink isCompleted={flow.esExtranjero ? flow.cuentaBancariaCompletada : false} />
      <BeneficiariesLink isCompleted={flow.beneficiarioCompletado} />
      <FinishLink isCompleted={flow.formulariosCompletos} />

    </>
  );
};

const LinkExtranjeroMoral = (flow = {}) => {
  return () => (
    <>
      <GeneralDataLink isCompleted={flow.datosGeneralesCompletados} />
      <ForeignFiscalDataLink isCompleted={flow.esExtranjero ? flow.datosFiscalesCompletados : false} />
      <ForeignLegalEntityInfoLink isCompleted={flow.esExtranjero ? flow.datosEmpresaCompletados : false} />
      <ForeignBankAccountsLink isCompleted={flow.esExtranjero ? flow.cuentaBancariaCompletada : false} />
      <ForeignLegalRepresentativeDataLink isCompleted={flow.esExtranjero ? flow.representanteLegalCompletado : false} />
      <FinishLink isCompleted={flow.formulariosCompletos} />
    </>
  );
};

const RouteMoralEmpresa = (refreshFlow, onboardingFlow) => {
  return () => (
    <Switch>
      <Route exact path="/onboarding" component={() => <GeneralData refresh={refreshFlow} />} />
      <Route path="/onboarding/datos-fiscales" component={() => <FiscalData refresh={refreshFlow} />} />
      <Route path="/onboarding/informacion-personas-morales-empresas" component={() => <LegalEntityInfo refresh={refreshFlow} />} />
      <Route path="/onboarding/cuentas-bancarias" component={() => <BankAccounts refresh={refreshFlow} />} />
      <Route path="/onboarding/datos-del-representante-legal" component={() => <LegalRepresentativeData refresh={refreshFlow} />} />
      <Route path="/onboarding/enviar-a-revision" component={() => <Finish onboardingFlow={onboardingFlow} />} />
      <Route component={Error404} />
    </Switch>
  );
};

// modificar la funcion para que los componentes reciban una propiedad adicional llamada onboardingFlow
const RoutePersonaFisica = (refreshFlow, onboardingFlow) => {
  return () => (
    <Switch>
      <Route exact path="/onboarding" component={() => <GeneralData refresh={refreshFlow} />} />
      <Route path="/onboarding/datos-fiscales" component={() => <FiscalData refresh={refreshFlow} />} />
      <Route path="/onboarding/informacion-personas-fisicas" component={() => <PersonalInfo refresh={refreshFlow} />} />
      <Route path="/onboarding/cuentas-bancarias" component={() => <BankAccounts refresh={refreshFlow} />} />
      <Route path="/onboarding/datos-del-representante-legal" component={() => <LegalRepresentativeData refresh={refreshFlow} />} />
      <Route path="/onboarding/beneficiarios" component={() => <Beneficiaries refresh={refreshFlow} />} />
      <Route path="/onboarding/enviar-a-revision" component={() => <Finish onboardingFlow={onboardingFlow} />} />
      <Route component={Error404} />
    </Switch>
  );
};

const RouteExtranjeroFisica = (refreshFlow, onboardingFlow) => {
  return () => (
    <Switch>
      <Route exact path="/onboarding" component={() => <GeneralData refresh={refreshFlow} />} />
      <Route path="/onboarding/datos-fiscales-extranjeros" component={() => <ForeignFiscalData refresh={refreshFlow} />} />
      <Route path="/onboarding/informacion-personas-fisicas-extranjeras" component={() => <ForeignPersonalInfo refresh={refreshFlow} />} />
      <Route path="/onboarding/cuentas-bancarias-extranjeros" component={() => <ForeignBankAccounts refresh={refreshFlow} />} />
      <Route path="/onboarding/beneficiarios" component={() => <Beneficiaries refresh={refreshFlow} />} />
      <Route path="/onboarding/enviar-a-revision" component={() => <Finish onboardingFlow={onboardingFlow} />} />
      <Route component={Error404} />
    </Switch>
  );
};

const RouteExtranjeroMoral = (refreshFlow, onboardingFlow) => {
  return () => (
    <Switch>
      <Route exact path="/onboarding" component={() => <GeneralData refresh={refreshFlow} />} />
      <Route path="/onboarding/datos-fiscales-extranjeros" component={() => <ForeignFiscalData refresh={refreshFlow} />} />
      <Route path="/onboarding/cuentas-bancarias-extranjeros" component={() => <ForeignBankAccounts refresh={refreshFlow} />} />
      <Route path="/onboarding/informacion-personas-morales-empresas-extranjeras" component={() => <ForeignLegalEntityInfo refresh={refreshFlow} />} />
      <Route path="/onboarding/datos-del-representante-legal-extranjero" component={() => <ForeignLegalRepresentativeData refresh={refreshFlow} />} />
      <Route path="/onboarding/enviar-a-revision" component={() => <Finish onboardingFlow={onboardingFlow} />} />
      <Route component={Error404} />
    </Switch>
  );
};

const LinkFactory = (personType, flow) => {

  return cond([
    [isLegalEntity, () => LinkMoralEmpresa(flow)],
    [isNaturalPerson, () => LinkPersonaFisica(flow)],
    [isForeign, () => LinkExtranjeroFisica(flow)],
    [isForeignLegalEntity, () => LinkExtranjeroMoral(flow)],
  ])(personType);
}

const RouteFactory = (personType, refreshFlow, onboardingFlow) => {
  return cond([
    [isLegalEntity, () => RouteMoralEmpresa(refreshFlow, onboardingFlow)],
    [isNaturalPerson, () => RoutePersonaFisica(refreshFlow, onboardingFlow)],
    [isForeign, () => RouteExtranjeroFisica(refreshFlow, onboardingFlow)],
    [isForeignLegalEntity, () => RouteExtranjeroMoral(refreshFlow, onboardingFlow)],
  ])(personType);
}



const PartnerProfile = ({ partnerProfile }) => {
  const { onboardingFlow, refreshFlow } = useOnboarding()

  const personType = pathOr(undefined, ['tipoPersona', 'id'], partnerProfile)
  if (!personType) {
    return <></>
  }
  const LinksDraw = LinkFactory(personType, onboardingFlow)
  const RouteDraw = RouteFactory(personType, refreshFlow, onboardingFlow)

  return (
    <div>
      <Tabs style={{ marginBottom: '10px' }}>
        <ul style={{ marginBottom: '15px' }}>
          <LinksDraw />
        </ul>
      </Tabs>
      <RouteDraw />
    </div>
  );
};

const mapStateToProps = ({ principal }) => ({
  partnerProfile: principal.integrated,
  account: principal.account,
  legalRepresentative: principal.legalRepresentative,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getAccount,
  getLegalRepresentative,
  getPartnerProfile,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(PartnerProfile)
