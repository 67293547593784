import React, { memo } from 'react'
import { Columns, Column } from 'Components/common/bulma'
import { InputWithError } from 'Components/common/form'

function Address({ addressFields, onChange, showErrors }) {
  console.log('si', addressFields)
  return (
    <Columns className="is-multiline">
      <Column className="is-half">
        <InputWithError
          {...addressFields.name}
          onChange={onChange}
          showErrors={showErrors}
        />
      </Column>
      <Column className="is-half">
        <InputWithError
          {...addressFields.company}
          onChange={onChange}
          showErrors={showErrors}
        />
      </Column>
      <Column className="is-half">
        <InputWithError
          {...addressFields.email}
          onChange={onChange}
          showErrors={showErrors}
        />
      </Column>
      <Column className="is-half">
        <InputWithError
          {...addressFields.phone}
          onChange={onChange}
          showErrors={showErrors}
        />
      </Column>
      <Column className="is-half">
        <InputWithError
          {...addressFields.address1}
          onChange={onChange}
          showErrors={showErrors}
        />
      </Column>
      <Column className="is-half">
        <InputWithError
          {...addressFields.zip}
          onChange={onChange}
          showErrors={showErrors}
        />
      </Column>
      <Column className="is-half">
        <InputWithError
          {...addressFields.address2}
          onChange={onChange}
          showErrors={showErrors}
        />
      </Column>
      <Column className="is-half">
        <InputWithError
          {...addressFields.city}
          onChange={onChange}
          showErrors={showErrors}
        />
      </Column>
      <Column className="is-half">
        <InputWithError
          {...addressFields.province}
          onChange={onChange}
          showErrors={showErrors}
        />
      </Column>
    </Columns>
  )
}

export default memo(Address)
