import { useRef, useCallback, useState } from 'react';

import useForm from 'Hooks/useForm';
import { createForeignFiscalData } from 'App/hexmodules/foreignFiscalData/application/create/createForeignFiscalData'
import { getForeignFiscalData } from 'App/hexmodules/foreignFiscalData/application/get/getForeignFiscalData'
import { useDependencies } from 'App/DependencyContext';
import { fields, addressDefinition } from './definition'
import { getAllCountries } from 'App/hexmodules/country/application/getAll/getAllCountries'
import { getAllTaxRegime } from 'App/hexmodules/taxRegime/application/getAll/getAllTaxRegime'
import { addNotification } from 'Modules/principal'
import { useDispatch } from 'react-redux'

const STATE = {
  INITIAL: 'initial',
  FETCHING_FOREIGN_FISCAL_DATA: 'fetchingForeignFiscalData',
  FETCHED_FOREIGN_FISCAL_DATA: 'fetchedForeignFiscalData',
  ERROR_FETCHING_FOREIGN_FISCAL_DATA: 'errorFetchingForeignFiscalData',
  SAVING_FOREIGN_FISCAL_DATA: 'savingForeignFiscalData',
  SAVED_FOREIGN_FISCAL_DATA: 'savedForeignFiscalData',
  ERROR_SAVING_FOREIGN_FISCAL_DATA: 'errorSavingForeignFiscalData',
};
function useForeignFiscalData({ refresh }) {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const state = useRef(STATE.INITIAL);
  const [countries, setCountries] = useState([])
  const [allTaxRegime, setAllTaxRegime] = useState([])

  const [formValues, setFormValues, handleOnChangeField, isValid, getValues, showErrors] = useForm({
    fields,
  });
  const [addressValues, mapAddressValues, handleOnAddressChangeField, isAddressValid, getAddressValues, showAddressError] = useForm({
    fields: addressDefinition,
  });

  const { foreignFiscalDataRepository } = useDependencies()
  const { countryRepository, taxRegimeRepository } = useDependencies();

  const initialFetch = async () => {
    if (state.current === STATE.INITIAL) {
      setIsLoading(true)
      state.current = STATE.FETCHING_FOREIGN_FISCAL_DATA;
      const foreignFiscalData = await getForeignFiscalData(foreignFiscalDataRepository);
      const countries = await getAllCountries(countryRepository);
      const allTaxRegimeResult = await getAllTaxRegime(taxRegimeRepository);
      setCountries(countries)
      setFormValues(foreignFiscalData);
      mapAddressValues(foreignFiscalData);
      setAllTaxRegime(allTaxRegimeResult)
      state.current = STATE.FETCHED_FOREIGN_FISCAL_DATA;
      setIsLoading(false)
    }
  };

  initialFetch();

  const saveForeignFiscalData = async () => {
    const isFormValid = isValid()
    const isAddressFormValid = isAddressValid()

    if (isFormValid && isAddressFormValid) {
      setIsLoading(true)
      try {
        state.current = STATE.SAVING_FOREIGN_FISCAL_DATA;
        const foreignFiscalData = getValues();
        foreignFiscalData.address = getAddressValues();
        await createForeignFiscalData(foreignFiscalDataRepository, foreignFiscalData)
        await refresh();
        state.current = STATE.SAVED_FOREIGN_FISCAL_DATA;
        setIsLoading(false)
        dispatch(addNotification({
          type: 'success',
          message: 'Infomación guardada correctamente',
        }))
      } catch ({ response: { data } }) {
        dispatch(addNotification({
          type: 'danger',
          message: data?.error,
        }))
        setIsLoading(false)
      }
    }
  };

  const getCurrentState = useCallback(() => {
    return state.current;
  }, []);

  return {
    values: formValues,
    getCurrentState,
    onChangeField: handleOnChangeField,
    showErrors,
    saveForeignFiscalData,
    addressValues,
    countries,
    allTaxRegime,
    isLoading,
    handleOnAddressChangeField,
    showAddressError,
  };
}

export default useForeignFiscalData;
