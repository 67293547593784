import { useState, useMemo } from 'react'
import {
  ifElse, propEq, prop, lensPath,
  set, map, path, propOr,
  converge, call, always, mergeDeepRight, identity,
  pipe, any, values, omit, mapObjIndexed, curryN, __, isNil, T,
  pathOr,
} from 'ramda'


const getValue = (target) => {
  const getValueFromInputType = ifElse(propEq('type', 'checkbox'), prop('checked'), prop('value'))
  return getValueFromInputType(target)
}

const getName = prop('name')

const useForm = ({ fields, initialValid = true, callbacks = {} }) => {
  const [fieldsValue, setFieldsValue] = useState(fields);
  const [showErrors, setShowErrors] = useState(false);

  const handleOnChangeField = ({ target }) => {
    const name = getName(target);
    const valueLens = lensPath([name, 'input', 'value'])
    const getPattern = pipe(prop(name), propOr(T, 'pattern'))

    const newValue = getValue(target)
    const pattern = getPattern(fieldsValue)
    const isMatch = pattern(newValue);

    if (isMatch) {
      const setNewFieldsValue = set(valueLens, newValue)
      setFieldsValue(setNewFieldsValue)
      const chain = propOr(() => { }, name)(callbacks);

      chain(newValue)
    }
    setShowErrors(false)
  }

  const getValues = () => {
    const marshall = converge(call, [propOr(identity, 'marshall'), pathOr('', ['input', 'value'])]);
    return map(pipe(marshall))(fieldsValue)
  }

  const isValid = () => {
    const getValidator = (field, key, form) => {
      const validator = propOr(always(always({ error: undefined })), 'validate')(field);
      const curryValidator = curryN(3, validator)

      return curryValidator(__, key, form)
    }
    const getFieldValue = path(['input', 'value'])

    const validate = converge(call, [getValidator, getFieldValue])
    const mergeWithErrors = converge(mergeDeepRight, [omit(['error']), validate])
    const fieldIsValid = pipe(prop('error'), val => !!val)
    const mapErrors = map(fieldIsValid)

    let hasErrors = false;

    const newValues = mapObjIndexed(mergeWithErrors)(fieldsValue)
    const mappedErrors = mapErrors(newValues)

    hasErrors = any(identity, values(mappedErrors))

    setShowErrors(hasErrors)
    setFieldsValue(newValues)

    return !hasErrors
  }

  const mapFields = (values) => {

    const valueLens = lensPath(['input', 'value'])
    const getUnmarshaller = propOr(identity, 'unmarshaller')
    const setNewFieldsValue = set(valueLens)

    const unmarshall = (field, key) => {
      const getValue = propOr(undefined, key)
      const unmarshaller = getUnmarshaller(field)
      const value = getValue(values)
      let newFieldValue = field;

      if (isNil(value)) {
        return field
      }

      if (unmarshaller) {
        newFieldValue = setNewFieldsValue(unmarshaller(value), field)
      }

      return newFieldValue
    }
    setFieldsValue(mapObjIndexed(unmarshall))
  }

  return [
    fieldsValue,
    mapFields,
    // eslint-disable-next-line
    useMemo(() => handleOnChangeField, []),
    isValid,
    getValues,
    showErrors,
  ];
}

export default useForm
