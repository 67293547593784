import React from 'react'
import { Field, Control } from 'Components/common/bulma'

export default function Output({ label, value, format, align = 'left', style }) {
  return (
    <Field>
      <label className="label">{label}</label>
      <Control>
        <output className="input" style={style}>
          <span className={'has-text-' + align} style={{width: '100%'}}>
            {format ? format(value): value}
           </span>
        </output>
      </Control>
    </Field>
  )
}
