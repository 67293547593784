import moment from 'moment'
import numeral from 'numeral'

const limitHour = moment({ hour: 16, minute: 5 })
const minDate = moment() < limitHour ? moment({ hour: 0 }) : moment({ hour: 0 }).add(1, 'day')

export const inventoryList =
  [
    {
      title: 'Identificador',
      datakey: 'identificador',
      width: '10%',
    },
    {
      title: 'Producto',
      datakey: 'concepto',
      width: '24%',
    },
    {
      title: 'Fecha',
      datakey: 'fecha',
      width: '7%',
      format: data => moment(data).format('DD/MM/YYYY')
    },
    {
      title: 'Entrada',
      datakey: 'entrada',
      width: '5%',
    },
    {
      title: 'Salida',
      datakey: 'salida',
      width: '5%',
    },
    {
      title: 'Saldo',
      datakey: 'saldo',
      width: '5%',
    },
    {
      title: 'Subtotal',
      datakey: 'subtotal',
      format: '$ 0,0.00',
      width: '8%',
    },
    {
      title: 'IVA',
      datakey: 'iva',
      format: '$ 0,0.00',
      width: '8%',
    },
    {
      title: 'IEPS',
      datakey: 'ieps',
      format: '$ 0,0.00',
      width: '8%',
    },
    {
      title: 'Total',
      datakey: 'total',
      format: '$ 0,0.00',
      width: '8%',
    },
    {
      title: 'Divisa',
      datakey: 'divisa',
      width: '5%',
    },
    {
      title: 'Acciones',
      custom: 'actions',
      width: '7%',
    }
  ]

export const filter = {
  product: {
    name: 'product',
    label: 'Producto o servicio',
    valueLabel: 'label',
    valueProperty: 'producto',
    trigger: 'updateDateLimits',
    validation: 'required',
    value: '',
  },
  fechaInicio: {
    name: 'fechaInicio',
    label: 'Fecha inicio',
    value: undefined,
    dataMarshaller: val => val ? moment(val).format('YYYY-MM-DD'): undefined,
  },
  fechaFin: {
    name: 'fechaFin',
    label: 'Fecha fin',
    value: undefined,
    dataMarshaller: val => val ? moment(val).format('YYYY-MM-DD'): undefined,
  },
  idProducto: {
    same: 'product',
  }
}

export const inventory = {
  estatus: {
    name: 'estatus',
    label: 'Estatus',
    disabled: true,
    value: '1',
    valueProperty: 'id',
    valueLabel: 'estatus',
  },
  fecha: {
    name: 'fecha  ',
    label: 'Fecha',
    // validation: 'required',
    value: moment().toDate(),
  },
  idProductoServicio: {
    name: 'idProductoServicio',
    label: 'Clave Producto',
    value: '',
    validation: 'required',
    valueProperty: 'id',
    valueLabel: 'tipo',
    placeholder: 'Buscar producto...',
    trigger: 'getProductOrService',
  },
  identificador: {
    name: 'identificador',
    label: 'Identificador',
    maxLength: 11,
    value: '',
    validation: 'required',
    disabled: true,
  },
  inventoryType: {
    value: 'Entrada',
    name: 'inventoryType',
    label: 'Tipo de inventario',
    options: [
      { value: 'Entrada', label: 'Entrada' },
      { value: 'Salida', label: 'Salida' },
    ],
    hideEmpty: true,
  },
  entrada: {
    value: 0,
    name: 'entrada',
    label: 'Entrada',
    trigger: 'updateSubtotal',
  },
  salida: {
    value: 0,
    name: 'salida',
    label: 'Salida',
    trigger: 'updateSubtotal',
  },
  unitario: {
    value: '',
    name: 'unitario',
    label: 'Valor unitario',
    validation: 'required',
    trigger: 'updateSubtotal',
  },
  subtotal: {
    value: '',
    name: 'subtotal',
    label: 'Subtotal',
    disabled: true,
  },
  ieps: {
    value: '',
    name: 'ieps',
    label: 'IEPS',
    trigger: 'updateSubtotal',
  },
  iva: {
    value: '',
    name: 'iva',
    label: 'IVA',
    trigger: 'updateSubtotal',
  },
  total: {
    value: '',
    name: 'total',
    label: 'Total',
    disabled: true,
  },
  divisa: {
    value: '',
    name: 'divisa',
    label: 'Divisa',
    options: [
      { id: 'MXN', label: 'MXN' },
      { id: 'USD', label: 'USD' }
    ],
    valueProperty: 'id',
    valueLabel: 'label',
    validation: 'required',
  },
  ordenPago: {
    value: '',
    name: 'idOrdenPago',
    label: 'Cargar desde Orden de pago'
  },
  ordenIngreso: {
    value: '',
    name: 'idOrdenIngreso',
    label: 'Cargar desde Orden de ingreso'
  },
  saldo: {
    value: '',
    name: 'saldo',
    label: 'Saldo',
  },
}

export const addressData = {
  direccion: {
    value: '',
    transform: 'stringToBool',
    trigger: 'onChangeDireccion',
    name: 'direccion',
    label: 'Dirección',
    disabled: true,
  },
  pais: {
    name: 'pais',
    value: '',
    validation: 'requiredIf:direccion',
    label: 'País',
    disabled: true
  },
  codigoPostal: {
    value: '',
    trigger: 'onChangeCp',
    pattern: 'number',
    name: 'codigoPostal',
    label: 'Código postal',
    disabled: true,
  },
  entidadFederativa: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'entidadFederativa',
    label: 'Entidad Federativa/Estado/Provincia',
    disabled: true,
  },
  municipio: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'municipio',
    label: 'Municipio',
    disabled: true,
  },
  colonia: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'colonia',
    label: 'Colonia',
    disabled: true,
  },
  calle: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'calle',
    label: 'Calle',
    disabled: true,
  },
  exterior: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'exterior',
    label: 'Exterior',
    disabled: true,
  },
  interior: {
    value: '',
    name: 'interior',
    label: 'Interior',
    disabled: true,
  },
}

export const paymentTable = [
  {
    title: 'id',
    datakey: 'id',
  },
  {
    title: 'Fecha Transacción',
    datakey: 'fechaTransaccion',
  },
  {
    title: 'Estatus',
    datakey: 'estatusTexto',
  },
  {
    title: 'Cargo',
    datakey: 'cargo',
  },
  {
    title: 'Clave Rastreo',
    datakey: 'claveRastreo',
  },
  {
    title: 'Referencia de Banco',
    datakey: 'referenciaBanco',
  },
  {
    title: 'Acciones',
    custom: 'acciones',
  }
]

export const installment = {
  divisaMoneda: {
    name: 'divisaMoneda',
    label: 'Moneda pago',
  },
  cargo: {
    label: 'Cargos',
    name: 'cargo',
    validation: 'required|greaterThan:0',
    pattern: 'number',
    value: 0,
    trigger: 'updateClabe',
    format: (value) => numeral(value).format('$ 0,0.00'),
  },
  fechaTransaccion: {
    label: 'Fecha de Transacción',
    name: 'fechaTransaccion',
    validation: `required|gtOrEqDate:${minDate.format('YYYY-MM-DD')}`,
    value: undefined,
  },
  estatus: {
    label: 'Estatus',
    name: 'estatus',
  },
  idOrden: {
    label: 'Orden Pago',
    name: 'idOrden',
  },
  referencia: {
    label: 'Referencia Banco',
    name: 'referencia',
  },
  metodoPago: {
    label: 'Metódo de pago',
    name: 'metodoPago',
  },
  cuentaMXN: {
    label: 'Cuenta MXN',
    name: 'cuentaMXN',
  },
  claveRastreo: {
    label: 'Clave de rastreo',
    name: 'claveRastreo',
  },
  typePaymentProvider: {
    label: 'Tipo de Pago',
    name: 'typePaymentProvider',
  },
  cuentaClabeColaborador: {
    value: '',
    name: 'cuentaClabeColaborador',
    label: 'Cuenta Clabe Colaborador',
  },
}

export const totals = {
  subtotal: {
    name: 'subtotal',
    label: 'Subtotal',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  IVA: {
    name: 'IVA',
    label: 'IVA',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  ieps: {
    name: 'ieps',
    label: 'IEPS',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  montoPagado: {
    name: 'montoPagado',
    label: 'Monto Pagado',
    value: 0,
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  montoPagoTransito: {
    name: 'montoPagoTransito',
    label: 'Monto Pago Tránsito',
    value: 0,
    format: (value) => numeral(Math.round(+value * 100) / 100).format('$ 0,0.00'),
    align: 'right',
  },
  montoPagar: {
    name: 'montoPagar',
    label: 'Monto por Pagar',
    value: 0,
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  estatusFacturaProveedor: {
    name: 'estatusFactura',
    label: 'Estatus Factura Proveedor',
    valueProperty: 'id',
    valueLabel: 'estatus',
    disabled: true,
    value: '3',
  },
  total: {
    name: 'total',
    label: 'Total',
    value: 0,
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
}