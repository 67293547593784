import React from 'react';
import { Delete } from 'Components/common/bulma'
import { Collapse } from 'Components/common'
import CancelOrderMontoCobrar from './cancelOrderMontoCobrar'
import CreditNoteMontoTotal from './CreditNoteMontoTotal'
import CreditNotePartialAmount from './CreditNotePartialAmount'
import CreditNoteRefund from './CreditNoteRefund'
import CreditNotePartialRefund from './CreditNotePartialRefund'
import CancelOrder from './cancelOrder'
import CancelOrderNoRelationship from './cancelOrderNoRelationship'
import CancelOrderGlobalInvoice from './cancelOrderGlobalInvoice'
import NewInvoice from './NewInvoice'
import NewInvoicePartial from './NewInvoicePartial'

export default function Collapsibles({ options, onClose }) {

  return (
    <div className="credit-note-form">
      <div style={{ textAlign: 'right', height: '40px' }}>
        <Delete onClick={onClose} />
      </div>
      {options.cancelar_orden_de_ingreso_motivo_03_no_se_llevo_a_cabo_la_operacion && <Collapse title="Cancelar orden de Ingreso No se llevó acabo la operación">
        <CancelOrder onClose={onClose} />
      </Collapse>}
      {options.Cancelar_Orden_de_Ingreso_Motivo_01_Comprobante_emitido_con_errores_con_relacion && <Collapse title="Cancelar Orden de Ingreso Comprobante emitido con errores con relación">
        <NewInvoice onCancel={onClose} />
      </Collapse>}
      {options.Cancelar_Orden_de_Ingreso_Monto_Cobrado_Parcial && <Collapse title="Cancelar Orden de Ingreso Monto Cobrado Parcial">
        <NewInvoicePartial onCancel={onClose} montoEfectivo={options.Cancelar_Orden_de_Ingreso_Monto_Cobrado_Parcial} />
      </Collapse>}
      {options.Generar_Nota_de_Credito_para_cancelar_Monto_por_cobrar && <Collapse title="Generar Nota de Crédito para cancelar Monto por cobrar">
        <CancelOrderMontoCobrar options={options} onClose={onClose} />
      </Collapse>}
      {options.Generar_Nota_de_Credito_por_el_Monto_Total && <Collapse title="Generar Nota de Credito por el Monto Total">
        <CreditNoteMontoTotal onClose={onClose} />
      </Collapse>}
      {options.Generar_Nota_de_Credito_por_el_Monto_Especificado && <Collapse title="Generar Nota de Credito por el Monto Especificado">
        <CreditNotePartialAmount options={options} onClose={onClose} />
      </Collapse>}
      {options.Generar_Nota_de_Credito_y_Devolver_Fondos_al_Cliente_monto_cobrado_total && <Collapse title="Generar Nota de Crédito y Devolver Fondos al Cliente monto cobrado total">
        <CreditNoteRefund options={options} onClose={onClose} />
      </Collapse>}
      {options.Generar_Nota_de_Crédito_y_Devolver_Fondos_al_Cliente_monto_cobrado_parcial && <Collapse title="Generar Nota de Crédito y Devolver Fondos al Cliente monto cobrado parcial">
        <CreditNotePartialRefund options={options} onClose={onClose} />
      </Collapse>}
      {options.Cancelar_Orden_de_Ingreso_Motivo_02_Comprobante_emitido_con_errores_sin_relacion && <Collapse title="Cancelar orden de Ingreso Comprobante emitido con errores sin relación">
        <CancelOrderNoRelationship onClose={onClose} />
      </Collapse>}
      {options.Cancelar_Orden_de_Ingreso_Motivo_04_Operacion_nominativa_relacionada_en_una_factura_global && <Collapse title="Cancelar orden de Ingreso Operación nominativa relacionada en un factura global">
        <CancelOrderGlobalInvoice onClose={onClose} />
      </Collapse>}
    </div>
  );
}
