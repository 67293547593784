import { cond, T, always, F, anyPass, ifElse, identity, } from 'ramda'
import { isEmpty, isNotEmail, } from 'App/helpers'
import { isNumeric, isNilOrEmptyString } from 'ramda-extension'
import { toNumber } from 'ramda-adjunct'
import numeral from 'numeral'

export const shippingColumns = [
  {
    title: 'Id',
    datakey: 'id',
    width: '5%',
  },
  {
    title: 'Fecha',
    datakey: 'date',
    width: '8%',
  },
  {
    title: 'Paqueteria',
    datakey: 'provider',
    width: '6%',
  },
  {
    title: 'Precio',
    datakey: 'totalPricing',
    width: '5%',
    format: val => numeral(val).format('$ 0,0.00')
  },
  {
    title: 'Destinatario',
    datakey: 'receiver',
    width: '15%',
  },
  {
    title: 'Rastreo',
    datakey: 'tracking',
    width: '12%',
  },
  {
    title: 'Estatus',
    datakey: 'statusName',
    width: '6%',
  },
  {
    title: 'Acciones',
    datakey: 'actions',
    custom: 'actions',
    width: '8%',
  },
];

export const address = {
  name: {
    input: {
      name: 'name',
      value: '',
    },
    label: 'Nombre',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  company: {
    input: {
      name: 'company',
      value: '',
    },
    label: 'Compañia',
    marshall: ifElse(isNilOrEmptyString, always(undefined), identity)
  },
  email: {
    input: {
      name: 'email',
      value: '',
    },
    label: 'Correo electrónico',
    validate: cond([
      [isEmpty, T],
      [isNotEmail, always({ error: '¡El formato de correo es incorrecto!' })],
      [T, F]
    ]),
    marshall: ifElse(isNilOrEmptyString, always(undefined), identity)
  },
  phone: {
    input: {
      name: 'phone',
      value: '',
    },
    label: 'Teléfono',
    marshall: ifElse(isNilOrEmptyString, always(undefined), identity),
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  address1: {
    input: {
      name: 'address1',
      value: '',
    },
    label: 'Calle y número',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  zip: {
    input: {
      name: 'zip',
      value: '',
      maxLength: 5,
    },
    label: 'Código Postal',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  address2: {
    input: {
      name: 'address2',
      value: '',
    },
    label: 'Colonia',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  city: {
    input: {
      name: 'city',
      value: '',
    },
    label: 'Municipio',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  province: {
    input: {
      name: 'province',
      value: '',
    },
    label: 'Estado o Entidad Federativa',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  country: {
    input: {
      name: 'country',
      value: 'MX',
    },
    label: 'Pais',
  },

};

export const receiver = {
  name: {
    input: {
      name: 'name',
      value: '',
      disabled: true,
    },
    label: 'Nombre',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  company: {
    input: {
      name: 'company',
      value: '',
      disabled: true,
    },
    label: 'Compañia',
    marshall: ifElse(isNilOrEmptyString, always(undefined), identity)
  },
  email: {
    input: {
      name: 'email',
      value: '',
      disabled: true,
    },
    label: 'Correo electrónico',
    validate: cond([
      [isEmpty, T],
      [isNotEmail, always({ error: '¡El formato de correo es incorrecto!' })],
      [T, F]
    ]),
    marshall: ifElse(isNilOrEmptyString, always(undefined), identity)
  },
  phone: {
    input: {
      name: 'phone',
      value: '',
      disabled: true,
    },
    label: 'Teléfono',
    marshall: ifElse(isNilOrEmptyString, always(undefined), identity),
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  address1: {
    input: {
      name: 'address1',
      value: '',
      disabled: true,
    },
    label: 'Calle y número',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  zip: {
    input: {
      name: 'zip',
      value: '',
      maxLength: 5,
      disabled: true,
    },
    label: 'Código Postal',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  address2: {
    input: {
      name: 'address2',
      value: '',
      disabled: true,
    },
    label: 'Colonia',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  city: {
    input: {
      name: 'city',
      value: '',
      disabled: true,
    },
    label: 'Municipio',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  province: {
    input: {
      name: 'province',
      value: '',
      disabled: true,
    },
    label: 'Estado o Entidad Federativa',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  country: {
    input: {
      name: 'country',
      value: 'MX',
      disabled: true,
    },
    label: 'Pais',
  },

};

export const reference = {
  reference: {
    input: {
      name: 'reference',
      value: '',
      maxLength: 25,
    },
    label: 'Referencia',
    marshall: ifElse(isNilOrEmptyString, always(undefined), identity)
  },
  contents: {
    input: {
      name: 'contents',
      value: '',
    },
    label: 'Contenido del paquete',
    marshall: ifElse(isNilOrEmptyString, always(undefined), identity)
  },

};

export const notes = {
  category: {
    input: {
      name: 'category',
      value: '',
    },
    label: 'Categoria',
    marshall: always(undefined),
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  subcategory: {
    input: {
      name: 'subcategory',
      value: '',
    },
    label: 'Subcategoria',
    marshall: always(undefined),
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  consignment_note_class_code: {
    input: {
      name: 'consignment_note_class_code',
      value: '',
    },
    label: 'Clave del producto para carta porte',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  consignment_note_packaging_code: {
    input: {
      name: 'consignment_note_packaging_code',
      value: '',
    },
    label: 'Tipo de embalaje',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  }
}

export const parcels = {
  distance_unit: {
    input: {
      name: 'distance_unit',
      value: 'CM',
    },
    label: 'distance_unit',
  },
  mass_unit: {
    input: {
      name: 'mass_unit',
      value: 'KG',
    },
    label: 'mass_unit',
  },
  weight: {
    input: {
      name: 'weight',
      value: '',
    },
    label: 'Peso (Kg)',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
    pattern: anyPass([isNumeric, isNilOrEmptyString]),
    marshall: toNumber,
  },
  length: {
    input: {
      name: 'length',
      value: '',
    },
    label: 'Largo (Cm)',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
    pattern: anyPass([isNumeric, isNilOrEmptyString]),
    marshall: toNumber,
  },
  width: {
    input: {
      name: 'width',
      value: '',
    },
    label: 'Ancho (Cm)',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
    pattern: anyPass([isNumeric, isNilOrEmptyString]),
    marshall: toNumber,
  },
  height: {
    input: {
      name: 'height',
      value: '',
    },
    label: 'Alto (Cm)',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
    pattern: anyPass([isNumeric, isNilOrEmptyString]),
    marshall: toNumber,
  },

};

