import React, { Component } from 'react'
import { getDataInput, getValues, validateData, mapPropsToState, validator, getClabeData } from 'App/helpers'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { ActionBar, ModalRemove } from 'Components/common'
import { default as Accounts } from 'Components/catalogos/accounts'
import {
  Box, SubTitle, Divider, Columns, Column,
  Button,
  Icon,
} from 'Components/common/bulma'
import { Select } from 'Components/common/form'
import { AddressSection, ContactSection } from 'Components/catalogs/providers'
import { GeneralSection } from 'Components/catalogs/lenders'
import { addNotification } from 'Modules/principal'
import {
  generalData, addressData, contactData, accountData,
  metodoPago,
  clabeAccount,
  accountColDef,
} from './lendersConfig'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { NACIONAL, EXTRANJERO } from '../../../constants'
import {
  ClabeAccount,
} from 'Components/catalogs/clients'
import {
  getEstatusPersona,
  getTipoPersona,
  getDireccion,
  getPaises,
  getEntidades,
  getBanks,
} from 'Modules/catalogos'
import {
  endEditResource,
  getResource,
  createResource,
  deleteResource,
  getAccounts,
  createAccount,
  deleteAccount,
  updateResource,
} from 'Modules/catalogs/lenders'

const tagsInput = require('bulma-tagsinput')


const TIPO_PERSONA_VENTA_MOSTRADOR = 6
const RFC_EXTRANJERO = 'XEXX010101000'
const RFC_VENTA_MOSTRADOR = 'XAXX010101000'
const PAIS_MEXICO = '141'
const CADENA_VACIA = ''
const LONGITUD_CODIGO_POSTAL = 5

export class Lenders extends Component {
  state = {
    generalData,
    addressData,
    contactData,
    clabeAccount,
    showErrors: false,
    resource: undefined,
    accountTab: 'list',
    accountData,
    showAccountErrors: false,
    showModal: false,
  }
  componentDidMount() {
    this.props.getEstatusPersona()
    this.props.getTipoPersona()
    this.props.getPaises()
    this.props.getEntidades()
    this.emailTagged = new tagsInput('[type="tags"]', { delimiter: ';', duplicates: false })
    this.emailTagged.reset()
    this.emailTagged.element.addEventListener('change', e => {
      this.onChangeInput(e, 'contactData')
    })

    const id = this.props.match.params.id
    if (id && (id !== this.state.resource)) {
      this.props.getResource(id)
    }
  }
  componentWillUnmount() {
    this.props.endEditResource()
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.resource.id && nextProps.resource.id !== this.state.resource) {
      const generalData = mapPropsToState(this.state.generalData, nextProps.resource)
      const addressData = mapPropsToState(this.state.addressData, nextProps.resource)
      const contactData = mapPropsToState(this.state.contactData, nextProps.resource)
      const clabeAccount = mapPropsToState(this.state.clabeAccount, nextProps.resource)
      const cp = addressData.codigoPostal.value
      const direccion = addressData.direccion
      direccion.value = cp ? (cp !== "0") : false;

      this.setState({
        resource: nextProps.resource.id,
        generalData,
        addressData: { ...addressData, direccion },
        contactData,
        metodoPago,
        clabeAccount
      }, () => {
        this.emailTagged.addTag(contactData.correoElectronico.value);
        this.initNacionalExtranjero(generalData.prestamistaNacionalOExtranjeros)
        // this.onChangeTipoPersona(generalData.tipoDePersona)
      })
    }
  }
  onChangeInput = ({ target }, section) => {
    const { name } = target
    const data = getDataInput(target, this.state[section])
    const field = data[name]

    this.setState({
      [section]: data,
      showErrors: false,
      showAccountErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }
  onChangeNacionalExtranjero = (field) => {
    const { generalData, addressData } = this.state

    if (field.value === EXTRANJERO) {
      generalData.tipoDePersona.value = 4
      addressData.direccion.value = true
      generalData.rfc.value = RFC_EXTRANJERO
      generalData.tipoDePersona.disabled = true
      generalData.rfc.disabled = true
      addressData.pais.disabled = false
    } else if (field.value === NACIONAL) {
      generalData.tipoDePersona.value = 1
      generalData.rfc.value = CADENA_VACIA
      addressData.pais.value = PAIS_MEXICO
      generalData.rfc.disabled = false
      generalData.tipoDePersona.disabled = false
      addressData.pais.disabled = true
      this.onChangeCp(addressData.codigoPostal)
    }
    this.setState({
      generalData,
      addressData,
    })
  }
  initNacionalExtranjero = (field) => {
    const { generalData, addressData } = this.state

    if (field.value === EXTRANJERO) {
      generalData.tipoDePersona.disabled = true
      generalData.rfc.disabled = true
      addressData.pais.disabled = false
    } else if (field.value === NACIONAL) {
      generalData.rfc.disabled = false
      generalData.tipoDePersona.disabled = false
      addressData.pais.disabled = true
    }
    this.setState({
      generalData,
      addressData,
    })
  }
  onChangeCp = ({ value }) => {
    const { generalData, addressData } = this.state
    if (value.length === LONGITUD_CODIGO_POSTAL
      && generalData.prestamistaNacionalOExtranjeros.value === NACIONAL) {
      this.props.getDireccion(value).then((response) => {
        if (response.length) {
          const { estado, municipio, asentamiento } = response[0]

          addressData.entidadFederativa.value = estado
          addressData.municipio.value = municipio
          addressData.colonia.value = asentamiento

          this.setState({
            addressData,
            generalData,
          })
        }
      })
    }
  }
  onChangeDireccion = ({ value }) => {
    const data = addressData;

    data.direccion.value = value
    if (!value) {
      this.setState({
        addressData: data
      })
    }
  }
  onChangeTipoPersona = ({ value }) => {
    const { generalData } = this.state

    if (+value === TIPO_PERSONA_VENTA_MOSTRADOR) {
      generalData.rfc.value = RFC_VENTA_MOSTRADOR
      generalData.rfc.disabled = true
    } else {
      generalData.rfc.value = ''
      generalData.rfc.disabled = false
    }
    this.setState({
      generalData,
    })
  }
  create = (callback) => {
    const { data: addressData, hasErrors: hasDireccionError } = validateData(this.state.addressData)
    const { data: generalData, hasErrors: hasGeneralError } = validateData(this.state.generalData)
    const { data: contactData, hasErrors: hascontactoError } = validateData(this.state.contactData)

    if (hasDireccionError || hasGeneralError || hascontactoError) {
      this.setState({
        addressData,
        generalData,
        contactData,
        showErrors: true,
      })
      this.props.addNotification({
        type: 'danger',
        message: '¡Favor de validar algunos campos!'
      })
      return
    }
    const direccionValues = getValues(this.state.addressData)
    const generalValues = getValues(this.state.generalData)
    const contactoValues = getValues(this.state.contactData)

    const { updateResource, createResource } = this.props

    const action = this.state.resource ? updateResource : createResource
    const id = this.props.match.params.id
    action({
      ...direccionValues,
      ...contactoValues,
      ...generalValues,
      id: this.state.resource
    }).then((data) => {
      this.props.addNotification({
        type: 'success',
        message: 'El prestamista se creó/actualizó correctamente'
      })
      id || this.props.push(`/catalogos/prestamistas/${data.id}`)
      typeof callback === 'function' && callback()
    }).catch((data) => {
      this.props.addNotification({
        type: 'danger',
        message: 'Upssss, Ocurrió un error'
      })
    })
  }
  onCancel = () => {
    this.props.push('/catalogos/prestamistas')
  }
  getPermissions = () => {
    const { resource } = this.state
    return {
      canDelete: !!resource,
      canSave: true,
    }
  }
  toggleModal = () => {
    this.setState((state) => ({
      showModal: !state.showModal,
    }))
  }
  delete = () => {
    this.props.deleteResource(this.state.resource)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'El proveedor se eliminó correctamente'
        })
        this.props.push('/catalogos/prestamistas')
      })
  }
  customColActions = (row) => (
    <Button danger outlined onClick={() => this.toggleAccountModal(row)}>
      <Icon>
        <FontAwesomeIcon icon={faTrash} />
      </Icon>
    </Button>
  )
  onChangeClabe = (field) => {
    if (field.value.length === 18 && validator(field.value, field.validation, this.state.accountData).valid) {
      const { place, account } = getClabeData(field.value)
      this.props.getBanks(field.value)
        .then(({ clabe }) => {
          const accountData = mapPropsToState(this.state.accountData, { banco: clabe, plaza: place, cuentaBancaria: account })
          this.setState({ accountData })
        })
    }
  }
  updateAccountData = (accountData, cb) => {
    this.setState({ accountData }, cb)
    if (cb) {
      this.props.addNotification({
        type: 'danger',
        message: '¡Favor de validar los campos!'
      })
    }
  }
  render() {
    const { paises, tipoPersona, estatusPersona, entidades, resource, accounts, createAccount, deleteAccount, getAccounts } = this.props
    return (
      <Box >
        <ModalRemove
          isDeleting={this.state.showModal}
          title="Eliminar prestamista"
          toggleModal={this.toggleModal}
          confirmText="¿Esta seguro de querer eliminar el prestamista?"
          resource={resource.name}
          deleteResource={this.delete}
        />
        <SubTitle className="has-text-centered" is='3'>Catálogo de prestamistas</SubTitle>
        <ActionBar
          permissions={this.getPermissions()}
          onSave={this.create}
          onCancel={this.onCancel}
          onDelete={this.toggleModal}
          basicRole={['Oalta']}
        />
        <Divider content="DATOS GENERALES" />
        <GeneralSection
          data={this.state.generalData}
          estatusPersona={estatusPersona}
          tipoPersona={tipoPersona}
          onChange={(e) => this.onChangeInput(e, 'generalData')}
          showErrors={this.state.showErrors}
        />
        <Divider content="DIRECCIÓN" />
        <Columns>
          <Column className="is-6">
            <Select
              onChange={(e) => this.onChangeInput(e, 'addressData')}
              options={[
                { value: false, label: 'Sin Dirección' },
                { value: true, label: 'Con Dirección' },
              ]}
              showErrors={this.state.showErrors}
              {...this.state.addressData.direccion}
            />
          </Column>
        </Columns>
        {this.state.addressData.direccion.value && (
          <AddressSection
            data={this.state.addressData}
            onChange={e => this.onChangeInput(e, 'addressData')}
            paises={paises}
            entidades={entidades}
            extranjero={this.state.generalData.prestamistaNacionalOExtranjeros.value === EXTRANJERO}
            showErrors={this.state.showErrors}
          />)}
        <Divider content="DATOS DE CONTACTO" />
        <ContactSection
          data={this.state.contactData}
          onChange={e => this.onChangeInput(e, 'contactData')}
          showErrors={this.state.showErrors}
        />
        <Divider content="CUENTAS CLABE Y CONVENIO CIE PARA PAGO" />
        <ClabeAccount
          data={this.state.clabeAccount}
          onChange={e => this.onChangeInput(e, 'clabeAccount')}
          showErrors={this.state.showErrors}
        />
        <Divider content="CUENTAS BANCARIAS" />
        <Accounts
          resource={this.state.resource}
          accounts={accounts}
          accountColDef={accountColDef}
          accountData={this.state.accountData}
          onChangeInput={this.onChangeInput}
          estatusPersona={estatusPersona}
          createResource={this.create}
          updateAccountData={this.updateAccountData}
          confData={accountData}
          getAccounts={getAccounts}
          createAccount={createAccount}
          deleteAccount={deleteAccount}
        />
      </Box>
    )
  }
}

const mapStateToProps = ({ catalogos, catalogs }) => ({
  estatusPersona: catalogos.estatusPersona,
  tipoPersona: catalogos.tipoPersona,
  direccion: catalogos.direccion,
  paises: catalogos.paises,
  entidades: catalogos.entidades,
  resource: catalogs.lenders.resource,
  accounts: catalogs.lenders.accounts,
  isFetching: catalogs.lenders.isFetchingLenders
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getEstatusPersona,
  getTipoPersona,
  getDireccion,
  getPaises,
  addNotification,
  getEntidades,
  push,
  endEditResource,
  createAccount,
  getBanks,
  deleteAccount,
  getResource,
  getAccounts,
  createResource,
  deleteResource,
  updateResource,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Lenders)
