import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Usuarios, { UserForm } from 'Containers/users'

export default () => (
  <Switch>
    <Route exact path="/usuarios" component={Usuarios} />
    <Route exact path="/usuarios/nuevo" component={UserForm} />
    <Route exact path="/usuarios/:id" component={UserForm} />
  </Switch>
)
