import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { Box, Icon, Columns, Column, Button } from 'Components/common/bulma'
import { Table, ModalRemove } from 'Components/common'
import {
  getInventory,
  getUniqueProducts,
  updateFilters,
  removeInventory,
} from 'Modules/inventory'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Trans } from '@lingui/macro'
import {
  inventoryList,
  filter,
} from './data'
import { addNotification } from 'Modules/principal'
import { Select, Date as DatePicker } from 'Components/common/form'
import { getDataInput, validateData, mapPropsToState, getValues } from 'App/helpers'
import { propEq } from 'ramda'
import { default as InventoryForm } from './form'
import moment from 'moment'

class ListaContainer extends Component {
  state = {
    showModal: false,
    inventory: {},
    data: filter,
    minDate: undefined,
    maxDate: undefined,
    showErrors: false,
  }

  componentDidMount() {
    this.props.getUniqueProducts();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.filters.product) {
      this.setState({
        data: mapPropsToState(this.state.data, nextProps.filters)
      }, () => {
        this.updateDateLimits({ value: nextProps.filters.product })
      })
    }
  }

  onEdit = ({ id }) => {
    const { push } = this.props;
    push(`/inventarios/${id}`);
  }

  updateDateLimits = ({ value }) => {
    const product = this.props.uniqueProducts.find(propEq('producto', value))

    if (!product) {
      return false
    }
    this.setState({
      minDate: moment(product.fechas.inicio).toDate(),
      maxDate: moment(product.fechas.final).toDate(),
    })
  }

  filterData = params => {
    const { product, fechaInicio, fechaFin } = this.state.data
    const { data, hasErrors } = validateData(this.state.data)

    if (hasErrors) {
      this.setState({
        data,
        showErrors: true,
      })

      return new Promise((resolve, reject) => {resolve(true)})
    }

    const values = getValues(this.state.data)
    const filters = {
      product: product.value,
      fechaInicio: fechaInicio.value,
      fechaFin: fechaFin.value
    }

    this.props.updateFilters(filters)
    return this.props.getInventory({
      ...params,
      ...values
    })
  }

  onChangeInput = ({ target }) => {
    const { name } = target
    const data = getDataInput(target, this.state.data)
    const field = data[name]

    this.setState({
      data,
      showErrors: false,
      showAccountErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }

  toggleModal = inventory => {
    this.setState(state => ({
      showModal: !state.showModal,
      inventory,
    }))
  }

  onChangeInput = ({ target }) => {
    const { name } = target
    const data = getDataInput(target, this.state.data)
    const field = data[name]

    this.setState({
      data,
      showErrors: false,
      showAccountErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }

  deleteOrder = () => {
    this.props.removeInventory(this.state.inventory.id)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'El inventario se eliminó correctamente'
        })
        this.setState({
          showModal: false,
        })
        this.filterData()
      })
  }

  customColAccions = row => (
    <Fragment >
      <Button small className="margin-left-xs" primary outlined onClick={() => this.onEdit(row)}>
        <Icon>
          <FontAwesomeIcon icon={faEdit} />
        </Icon>
      </Button>
      <Button small className="margin-left-xs" danger outlined onClick={() => this.toggleModal(row)}>
        <Icon>
          <FontAwesomeIcon icon={faTrash} />
        </Icon>
      </Button>
    </Fragment>
  )

  render() {
    const {
      inventory,
      uniqueProducts,
      permissions,
    } = this.props
    const {
      product,
      fechaFin,
      fechaInicio,
    } = this.state.data

    return (
      <Box>
        <ModalRemove
          isDeleting={this.state.showModal}
          title="Eliminar inventario"
          toggleModal={this.toggleModal}
          confirmText="¿Esta seguro de querer eliminar el inventario?"
          resource={this.state.inventory.id}
          deleteResource={this.deleteOrder}
        />
        {permissions.tipoEntrada === '2' && <Columns>
          <Column>
            <Link className="button is-success is-outlined is-pulled-right" to="/inventarios/nuevo">
              <Icon>
                <FontAwesomeIcon icon={faPlus} />
              </Icon>
              <Trans render="span">Agregar inventario</Trans>
            </Link>
          </Column>
        </Columns>}
        <Columns>
          <Column>
            <Select
              options={uniqueProducts}
              {...product}
              onChange={this.onChangeInput}
              showErrors={this.state.showErrors}
            />
          </Column>
          <Column>
            <DatePicker
              {...fechaInicio}
              onChange={this.onChangeInput}
              minDate={this.state.minDate}
              maxDate={this.state.maxDate}
              openToDate={this.state.minDate}
            />
          </Column>
          <Column>
            <DatePicker
              {...fechaFin}
              onChange={this.onChangeInput}
              minDate={this.state.minDate}
              maxDate={this.state.maxDate}
              openToDate={this.state.maxDate}
            />
          </Column>
          <Column>
            <br />
            <Button primary outlined
              onClick={e => this.filterData()}
            >Buscar</Button>
          </Column>
        </Columns>
        <Columns>
          <Column className="is-full">
            <Table
              style={{width: '150%'}}
              initialFetch={false}
              colsetup={inventoryList}
              coldata={inventory}
              tableClassName='table is-striped is-hoverable is-fullwidth'
              emptyTableMarkUp={<span>No hay registros</span>}
              customCol={{
                customColName: 'actions',
                renderFunc: this.customColAccions
              }}
              refresh={this.filterData}
            />
          </Column>
        </Columns>
      </Box>
    )
  }
}

const mapStateToProps = ({ principal, inventory: { uniqueProducts, inventory, filters } }) => ({
  uniqueProducts,
  inventory,
  permissions: principal.inventoryPermission,
  filters,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getInventory,
  getUniqueProducts,
  push,
  addNotification,
  updateFilters,
  removeInventory
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ListaContainer)
export {
  InventoryForm
}