import React from 'react';
import { Box, Columns, Column, Button } from 'Components/common/bulma'
import File from 'Components/common/form/FileWithErrors'
import InputWithError from 'Components/common/form/InputWithError'
import Loader from 'Components/common/page/loaderFloat';
import Show from 'Components/common/Show';
import useForeignBankAccounts from './useForeignBankAccounts';


const PersonalInfo = ({ refresh }) => {
  const { values, onChangeField, saveBankAccount, onRemove, addAccount, showErrors, isLoading } = useForeignBankAccounts({ refresh })

  return (
    <Box style={{ position: 'relative' }}>
      <Show canShow={isLoading}>
        <Loader />
      </Show>
      <Columns className="is-multiline">
        <Column className="is-half is-offset-half has-text-right">
          <Button className="is-primary" onClick={addAccount}>Agregar cuenta</Button>
        </Column>
      </Columns>
      {values.map((item, idx) => <Columns className="is-multiline">
        <Column className="is-half">
          <InputWithError
            onChange={onChangeField}
            showErrors={showErrors}
            {...{ ...item.beneficiaryName, input: { ...item.beneficiaryName.input, id: idx } }}
          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onChangeField}
            showErrors={showErrors}
            {...{ ...item.beneficiaryAddress, input: { ...item.beneficiaryAddress.input, id: idx } }}

          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onChangeField}
            showErrors={showErrors}
            {...{ ...item.swiftCode, input: { ...item.swiftCode.input, id: idx } }}

          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onChangeField}
            showErrors={showErrors}
            {...{ ...item.accountNumber, input: { ...item.accountNumber.input, id: idx } }}

          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onChangeField}
            showErrors={showErrors}
            {...{ ...item.iban, input: { ...item.iban.input, id: idx } }}

          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onChangeField}
            showErrors={showErrors}
            {...{ ...item.bankNameAddress, input: { ...item.bankNameAddress.input, id: idx } }}

          />
        </Column>
        <Column className="is-half">
          <File
            onChange={onChangeField}
            showErrors={showErrors}
            {...{ ...item.accountStatement, input: { ...item.accountStatement.input, id: idx } }}
          />
        </Column>
        <Column className="is-full has-text-right">
          <Button className="is-primary mr-4" onClick={() => saveBankAccount(idx)}>Guardar</Button>
          <Button className="is-warning" onClick={() => onRemove(item)}>Eliminar Cuenta</Button>
        </Column>
      </Columns>)}
    </Box>
  );
};

export default PersonalInfo;
