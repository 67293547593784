class CifLoader {
  constructor(httpService) {
    this.http = httpService;
  }
  async loadCif(data) {
    return this.http.post('/read-cif/qr', data)
  }
}

export default CifLoader
