import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { Box, Icon, Columns, Column } from 'Components/common/bulma'
import Table from 'Components/common/table'
import { getPendingCollectionList as getList, deleteOrder, setOrigin } from 'Modules/incomeOrders/invoice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { pendingCollectionIncomeOrders } from './colSetup'
import { Button } from 'Components/common/bulma'
import { addNotification } from 'Modules/principal'

export class ListaContainer extends Component {
  state = {
    showModal: false,
    order: {},
  }

  onEdit = ({ id, tipoIngreso }) => {
    const { push, setOrigin } = this.props;
    console.log(setOrigin);
    setOrigin('pendingCollection')
    if (tipoIngreso === 'Facturación') {
      push(`/ordenes-ingreso/facturas/${id}`);
      return
    }
    push(`/ordenes-ingreso/depositos/${id}`);
  }

  toggleModal = (order) => {
    this.setState(state => ({
      showModal: !state.showModal,
      order,
    }))
  }

  deleteOrder = () => {
    const { id } = this.state.order
    this.props.deleteOrder(id)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'La órden se eliminó correctamente'
        })
        this.setState({
          showModal: false,
        })
        this.props.getList(this.props.list.current_page)
      })
      .catch(() => {
        this.props.addNotification({
          type: 'danger',
          message: 'Ocurrió un error al tratar de eliminar la órden'
        })
        this.setState({
          showModal: false,
        })
      })
  }

  customColAccions = (row) => (
    <Button small primary outlined onClick={() => this.onEdit(row)}>
      <Icon>
        <FontAwesomeIcon icon={faEdit} />
      </Icon>
    </Button>
  )

  render() {
    const { list } = this.props

    return (
      <Box>
        <Columns>
          <Column>
            <Table
              filterable
              colsetup={pendingCollectionIncomeOrders}
              coldata={list}
              tableClassName='table is-striped is-hoverable is-fullwidth'
              emptyTableMarkUp={<span>No hay registros</span>}
              customCol={{
                customColName: 'actions',
                renderFunc: this.customColAccions
              }}
              refresh={this.props.getList}
            />
          </Column>
        </Columns>
      </Box>
    )
  }
}

const mapStateToProps = ({ incomeOrders }) => ({
  list: incomeOrders.invoice.pendingCollectionList,
  isFetching: incomeOrders.invoice.isFetching
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getList,
  push,
  deleteOrder,
  addNotification,
  setOrigin,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ListaContainer)
