import ax from 'axios'
import jwtDecode from 'jwt-decode'
import { push } from 'connected-react-router'
import { getHeaders } from './util'
import { addNotification } from 'Modules/principal'

const url = process.env.REACT_APP_API

export const INIT_AUTH = 'auth/INIT_AUTH'
export const SET_TOKEN = 'auth/SET_TOKEN'
export const REFRESHING_TOKEN = 'auth/REFRESHING_TOKEN'
export const REFRESHING_TOKEN_SUCCESS = 'auth/REFRESHING_TOKEN_SUCCESS'
export const PRE_LOGIN = 'auth/PRE_LOGIN'
export const LOGIN = 'auth/LOGIN'
export const LOGOUT = 'auth/LOGOUT'
export const GET_RANDOM_KEY = 'auth/GET_RANDOM_KEY'
export const GET_LEGAL = 'auth/GET_LEGAL'
export const GET_RECOVERY_TOKEN = 'auth/GET_RECOVERY_TOKEN'
export const UPDATE_PASSWORD = 'auth/UPDATE_PASSWORD'
export const CHANGE_PASSWORD = 'auth/CHANGE_PASSWORD'
export const PRELOGIN = 'auth/PRELOGIN'

const initialState = {
  authenticated: false,
  randomKey: {},
  legal: {},
  isRefreshingToken: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload.token,
        authenticated: action.payload.authenticated,
      }
    case PRE_LOGIN:
      return {
        ...state,
        token: action.payload.token,
        authenticated: false,
      }
    case LOGIN:
      return {
        ...state,
        token: action.payload.token,
        authenticated: true,
        datosUsario: action.payload.datosUsario
      }
    case GET_RANDOM_KEY:
      return {
        ...state,
        randomKey: action.payload
      }
    case GET_LEGAL:
      return {
        ...state,
        legal: action.payload
      }
    case GET_RECOVERY_TOKEN:
      return {
        ...state,
        recovery: action.payload
      }
    case UPDATE_PASSWORD:
      return {
        ...state,
        recovery: action.payload
      }
    case REFRESHING_TOKEN:
      return {
        ...state,
        isRefreshingToken: action.payload
      }
    case REFRESHING_TOKEN_SUCCESS:
      return {
        ...state,
        isRefreshingToken: false,
        authenticated: true,
      }
    case PRELOGIN:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export const initAuth = () => {
  const token = getTokenDecoded()
  const tokenIsExpired = token.exp < (Date.now() / 1000);

  if (token && tokenIsExpired) {
    localStorage.removeItem('auth')
  }
  return {
    type: SET_TOKEN,
    payload: { authenticated: (token && !tokenIsExpired) }
  }
}

export const login = (params) => {
  return (dispatch, getState) => {
    const {auth} = getState();
    return ax.post(`${url}/login/tas`, {...params, tokenRandom: auth.randomKey.tokenRandom})
      .then(({ data }) => {
        localStorage.setItem('auth', JSON.stringify(data));
        dispatch({
          type: LOGIN,
          payload: data
        });
        dispatch(push('/'));
      })
  }
}
export const loginWithTop = (params) => {
  return (dispatch, getState) => {
    const { auth: { prelogin } } = getState()

    return ax.post(`${url}/login/tfa`, {
      'verification-token': prelogin['verification-token'],
      'token_2fa': params.totp,
    })
      .then(({ data }) => {
        localStorage.setItem('auth', JSON.stringify(data));
        dispatch({
          type: LOGIN,
          payload: data
        });
        dispatch(push('/'));
      })
  }
}

export const preLogin = params => {
  return dispatch => {
    return ax.post(`${url}/login`, params)
      .then((data) => {
        dispatch({
          type: PRELOGIN,
          payload: {prelogin: data.data}
        })
        return data
      })
  }
}

export const logout = () => {
  return dispatch => {
    // localStorage.removeItem('auth');
    dispatch({
      type: LOGOUT
    })
    dispatch(push('/login'));
  }
}
export const getRandomKey = () => {
  return dispatch => {
    return ax.get(`${url}/random`)
      .then(({ data }) => {
        dispatch({
          type: GET_RANDOM_KEY,
          payload: data
        })
      })
  }
}
export const getLegal = () => {
  return dispatch => {
    return ax.get(`${url}/legal/1`)
      .then(({ data }) => {
        dispatch({
          type: GET_LEGAL,
          payload: data
        })
      })
  }
}
export const getRecoveryToken = (params) => {
  return dispatch => {
    return ax.post(`${url}/reset`, params)
      .then(({ data }) => {
        dispatch({
          type: GET_RECOVERY_TOKEN,
          payload: data
        })
      })
  }
}
export const validateToken = (token) => {
  return ax.get(`${url}/reset/${token}`)
}
export const updatePassword = (params) => {
  return dispatch => {
    return ax.post(`${url}/changePassword`, params)
      .then(() => {
        dispatch({
          type: UPDATE_PASSWORD
        })
        dispatch(addNotification({
          type: 'success',
          message: 'La contraseña se cambió correctamente'
        })
        )
      })

  }
}
export const changePassword = (params) => {
  const headers = getHeaders()
  return dispatch => {
    return ax.post(`${url}/user/changePassword`, params, { headers })
      .then(() => {
        dispatch({
          type: CHANGE_PASSWORD
        })
        dispatch(addNotification({
          type: 'success',
          message: 'La contraseña se cambió correctamente'
        }))
      })
  }
}

export const getTokenDecoded = () => {
  const token = localStorage.getItem('auth')
  const tokenDecoded = token ? JSON.parse(token) : '';
  if (tokenDecoded) {
    return jwtDecode(tokenDecoded.jwt);
  }
  return ''
}

export const refreshTokenFetch = () => {
  const headers = getHeaders()
  return ax.post(`${url}/refresh`, {}, { headers })
}

export const refreshTokenSuccess = (token) => {
  localStorage.setItem('auth', JSON.stringify(token))
  return { type: REFRESHING_TOKEN_SUCCESS, payload: {} }
}

export const sendQrEmail = (params) => {
  return ax.post(`${url}/tfa/qr/send/`, params, {})
}

export const getQr = (token) => {
  return ax.get(`${url}/tfa/qr/verify/${token}`)
}

export const enableTotp = (params) => {
  return (dispatch, getState) => {
    const { auth: { prelogin } } = getState()
  return ax.post(`${url}/tfa/enable`, {
      'verification-token': prelogin['verification-token'],
      'token_2fa': params.totp,
    }).then(({data}) => {
      localStorage.setItem('auth', JSON.stringify(data));
      dispatch({
        type: LOGIN,
        payload: data
      });
      dispatch(push('/'));
    })
  } 
}

