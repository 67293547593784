import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import {
  Box,
  Columns,
  Column,
  SubTitle,
  Button,
  Icon,
} from 'Components/common/bulma'
import {
  Output,
} from 'Components/common/form'
import {
  transaction,
} from './data'
import {
  getTransaction
} from 'Modules/conciliations'
import { getDataInput, mapPropsToState } from 'App/helpers'
import { ActionBar } from 'Components/common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

class TransactionForm extends Component {
  state = {
    transaction,
  }

  componentDidMount() {
    const { getTransaction } = this.props
    const { id } = this.props.match.params

    if (id) {
      getTransaction(id)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.transaction !== nextProps.transaction) {
      this.setState({
        transaction: mapPropsToState(this.state.transaction, nextProps.transaction)
      })
    }
  }

  onChangeInput = ({ target }) => {
    const { name } = target
    const data = getDataInput(target, this.state.data)
    const field = data[name]

    this.setState({
      data,
      showErrors: false,
      showAccountErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }

  onBack = () => {
    this.props.push('/conciliaciones')
  }

  render() {
    const {
      fecha,
      tipoPago,
      tipoPago2,
      referencia,
      concepto,
      monto,
    } = this.state.transaction
    return (
      <Box>
        <ActionBar
          permissions={[]}
          customButton={<Button
            onClick={this.onBack}
          >
            <Icon>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Icon>
            <span>Regresar</span>
          </Button>}
        />
        <SubTitle className="has-text-centered">Detalle de la Transacción</SubTitle>
        <Columns className="is-multiline">
          <Column className="is-half">
            <Output
              {...fecha}
            />
          </Column>
          <Column className="is-half">
            <Output
              {...tipoPago}
            />
          </Column>
          <Column className="is-half">
            <Output
              {...tipoPago2}
            />
          </Column>
          <Column className="is-half">
            <Output
              {...referencia}
            />
          </Column>
          <Column className="is-half">
            <Output
              {...concepto}
            />
          </Column>
          <Column className="is-half">
            <Output
              {...monto}
            />
          </Column>
        </Columns>
      </Box>
    )
  }
}

const mapStateToProps = ({ conciliations }) => ({
  transaction: conciliations.transaction,

})

const mapDispatchToProps = dispatch => bindActionCreators({
  getTransaction,
  push,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TransactionForm)
