import React from 'react'
import { Field, Control } from 'Components/common/bulma'
import { Trans } from '@lingui/macro'
import DatePicker from 'react-datepicker'
import moment from 'moment'

export default ({
  label,
  name,
  value,
  onChange,
  disabled = false,
  type = 'text',
  placeholder = '',
  errors,
  showErrors,
  className,
  depends,
  dependsValue,
  minDate,
  ...props
}) => {
  return (
    <Field>
      <label className="label"><Trans id={label}></Trans></label>
      <Control>
        <DatePicker
          className="input"
          disabled={ disabled }
          dateFormat="dd/MM/yyyy"
          minDate={ minDate }
          selected={ value ? moment(value).toDate() : undefined }
          onChange={ (date) => onChange({ target: { value: date, name } } ) }
          {...props }
        />
      </Control>
      {(errors && !!errors.length && showErrors) && <p className="has-text-danger help">{errors[0].message}</p>}
    </Field>
  )
}