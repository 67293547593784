import React, { Fragment } from 'react'
import { Columns, Column } from 'Components/common/bulma'
import { Check } from 'Components/common/form'
import MultiRadio from '../../common/form/MultiRadio';

const SolicitudMonederoToka = ({ onChange, data, showErrors }) => {
  const { reposicion, combustible } = data;

  return (
    <Fragment >
      <Columns>
        <Column className='solicitudMonederoToka'>
         <MultiRadio
          showErrors={showErrors}
          onChange={onChange}
          {...combustible}
         />
        </Column>
        <Column>
          <Check
            {...reposicion}
            onChange={onChange}
          />
        </Column>
      </Columns>
    </Fragment>
  )
}

export default SolicitudMonederoToka
