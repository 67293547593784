import axios from 'axios'
import { getHeaders } from './util'
import { addNotification } from './principal';

const url = process.env.REACT_APP_API

const GET_ESTATUS_PERSONA = 'catalogos/GET_ESTATUS_PERSONA'
const GET_TIPO_PERSONA = 'catalogos/GET_TIPO_PERSONA'
const GET_DIRECCION = 'catalogos/GET_DIRECCION'
const GET_PAISES = 'catalogos/GET_PAISES'
const GET_ENTIDADES = 'catalogos/GET_ENTIDADES'
const GET_BANKS = 'catalogos/GET_BANKS'
const GET_MONEDERO_GASOLINA = 'catalogos/GET_MONEDERO_GASOLINA'
const GET_GASOLINE_WALLET = 'catalogos/GET_GASOLINE_WALLET'
const DELETE_WALLET = 'catalogos/DELETE_WALLET'
const END_EDIT_WALLET = 'catalogos/END_EDIT_WALLET'
const GET_PAYMENT_METHOD = 'catalogs/GET_PAYMENT_METHOD'
const GET_CFDI_USETYPE = 'catalogs/GET_CFDI_USETYPE'
const GET_TYPE_PAYMENT_PROVIDERS = 'catalogs/GET_TYPE_PAYMENT_PROVIDERS'
const GET_PAYMENT_ESTATUS = 'catalogs/GET_PAYMENT_ESTATUS'
const GET_REAL_PAYMENT_METHOD = 'catalogs/GET_REAL_PAYMENT_METHOD'
const GET_PROVIDER_INVOICE_STATUS = 'catalogs/GET_PROVIDER_INVOICE_STATUS'
const GET_INVOICE_UNITS = 'catalogs/GET_INVOICE_UNITS'
const GET_IVA_RATES = 'catalogs/GET_IVA_RATES'
const GET_IEPS_RATES = 'catalogs/GET_IEPS_RATES'
const CLEAR_INVOICE_UNITS = 'catalogos/CLEAR_INVOICE_UNITS'
const GET_CURRENCIES = 'catalogos/GET_CURRENCIES'
const GET_USO_CFDI = 'catalogos/GET_USO_CFDI'
const GET_PRODUCT_SERVICE = 'catalogos/GET_PRODUCT_SERVICE'
const CLEAR_PRODUCT_SERVICE = 'catalogos/CLEAR_PRODUCT_SERVICE'
const GET_TIPO_RELACION= 'catalogos/GET_TIPO_RELACION'
const GET_PRODUCT_SAT= 'catalogos/GET_PRODUCT_SAT'
const IS_FETCHING_GAS = 'catalogos/IS_FETCHING_GAS '
const IS_FETCHING_GAS_COMPLETE= 'catalogos/IS_FETCHING_STOP_GAS'
const GET_EXCHANGE_RATE= 'catalogos/GET_EXCHANGE_RATE'
const GET_FRECUENCY= 'catalogos/FRECUENCY'
const GET_INVOICE_STATUS = 'catalogos/GET_INVOICE_STATUS'
const GET_REGIMEN_FISCAL = 'catalogos/GET_REGIMEN_FISCAL'
const GET_TAX_OBJECT = 'catalogos/GET_TAX_OBJECT'
const GET_EXPORTATION = 'catalogos/GET_EXPORTATION'
const GET_PERIODICIDAD = 'catalogos/GET_PERIODICIDAD'
const GET_MESES = 'catalogos/GET_MESES'

const initialState = {
  estatusPersona: [],
  tipoPersona: [],
  direccion: {},
  paises: [],
  entidades: [],
  banks: [],
  monederoGasolina: {
    data: [],
  },
  wallet: {},
  paymentMethod: [],
  cfdiUseType: [],
  typePaymentProviders: [],
  paymentEstatus: [],
  realPaymentMethod: [],
  providerInvoiceStatus: [],
  invoiceUnits: [],
  ivaRates: [],
  iepsRates: [],
  currencies: [],
  productService: [],
  usoCfdi: [],
  tipoRelacion: [],
  productSat: {},
  isFetchingGas: false,
  exchangeRate: {},
  frecuency: [],
  invoiceStatus: [],
  regimenFiscal: [],
  taxObject: [],
  exportation: [],
  periodicidad: [],
  meses: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ESTATUS_PERSONA:
    case GET_DIRECCION:
    case GET_TIPO_PERSONA:
    case GET_PAISES:
    case GET_ENTIDADES:
    case GET_MONEDERO_GASOLINA:
    case GET_GASOLINE_WALLET:
    case GET_BANKS:
    case GET_CFDI_USETYPE:
    case GET_PAYMENT_METHOD:
    case GET_TYPE_PAYMENT_PROVIDERS:
    case GET_PAYMENT_ESTATUS:
    case GET_REAL_PAYMENT_METHOD:
    case GET_PROVIDER_INVOICE_STATUS:
    case GET_INVOICE_UNITS:
    case GET_PRODUCT_SERVICE:
    case GET_IVA_RATES:
    case GET_CURRENCIES:
    case GET_IEPS_RATES:
    case GET_USO_CFDI:
    case GET_PRODUCT_SAT:
    case GET_TIPO_RELACION:
    case GET_EXCHANGE_RATE:
    case GET_INVOICE_STATUS:
    case GET_FRECUENCY:
    case GET_REGIMEN_FISCAL:
    case GET_TAX_OBJECT:
    case GET_EXPORTATION:
    case GET_PERIODICIDAD:
    case GET_MESES:
      return { ...state, ...action.payload }
    case DELETE_WALLET:
      const { data, ...meta } = state.list
      return { ...state, list: { data: data.filter(it => it.id !== action.payload), ...meta, last_page: Math.ceil(data.length / meta.per_page) } }
    case END_EDIT_WALLET:
      return { ...state, wallet: {} }
    case CLEAR_PRODUCT_SERVICE:
      return { ...state, productService: []}
    case CLEAR_INVOICE_UNITS:
      return { ...state, invoiceUnits: []}
    case IS_FETCHING_GAS:
        return { ...state, isFetchingGas: true }
    case IS_FETCHING_GAS_COMPLETE:
        return { ...state, isFetchingGas: false }
    default:
      return state
  }
}

export const isFetchingGas= () => ({
  type: IS_FETCHING_GAS
})

export const isFetchingGasComplete = () => ({
  type: IS_FETCHING_GAS_COMPLETE
})

export const getEstatusPersona = () => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/estatusPersona`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_ESTATUS_PERSONA,
          payload: { estatusPersona: data }
        })
      })
  }
}
export const getTipoPersona = () => {
  return dispatch => {
    return axios.get(`${url}/tipoPersona`)
      .then(({ data }) => {
        dispatch({
          type: GET_TIPO_PERSONA,
          payload: { tipoPersona: data }
        })
      })
  }
}
export const getDireccion = (cp) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/cp/${cp}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_DIRECCION,
          payload: { direccion: data }
        })
        return data
      })
  }
}
export const getPaises = () => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/paises`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_PAISES,
          payload: { paises: data }
        })
      })
  }
}
export const getEntidades = () => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/entidades`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_ENTIDADES,
          payload: { entidades: data }
        })
      })
  }
}
export const getPaymentMethod = () => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/invoice-payment-method`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_PAYMENT_METHOD,
          payload: { paymentMethod: data }
        })
      })
  }
}
export const getCfdiUsetype = () => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/cfdi`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_CFDI_USETYPE,
          payload: { cfdiUseType: data }
        })
      })
  }
}

export const getBanks = (clabe) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/bank/search/${clabe}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_BANKS,
          payload: { banks: data }
        })
        return data
      })
  }
}

export const getMonederoGasolina = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingGas())
    return axios.post(`${url}/wallet-gasoline/search`, params, { headers })
      .then(({ data }) => {
        dispatch(isFetchingGasComplete())
        dispatch({
          type: GET_MONEDERO_GASOLINA,
          payload: { monederoGasolina: data }
        })
      })
  }
}

export const getSingleMonederoGasolina = (id) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/wallet-gasoline/${id}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_GASOLINE_WALLET,
          payload: { wallet: data }
        })
        return data;
      })
  }
}

const crearMonederoGasolinaSuccess = () => (dispatch) => {
  dispatch(isFetchingGasComplete())
  dispatch(addNotification({
    type: 'success',
    message: 'Gasolina Success'
  })
  );
}

export const crearMonederoGasolina = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingGas())
    return axios.post(`${url}/wallet-gasoline`, params, { headers })
      .then(() => {
        dispatch(crearMonederoGasolinaSuccess());
      }).catch(({ response: { data } }) => {
        dispatch(addNotification({
          type: 'danger',
          message: JSON.stringify(data)
        }));
      });
  }
}

export const updateMonederoGasolina = ({ id, ...params }) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingGas())
    return axios.put(`${url}/wallet-gasoline/${id}`, params, { headers })
      .then(() => {
        dispatch(isFetchingGasComplete())
        dispatch(addNotification({
          type: 'success',
          message: 'Gasolina Actualizada!'
        }));
      }).catch(({ response: { data } }) => {
        dispatch(addNotification({
          type: 'danger',
          message: JSON.stringify(data)
        }));
      });
  }
}
export const deleteWallet = (walletId) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/wallet-gasoline/${walletId}`, { headers })
      .then(() => {
        dispatch({
          type: DELETE_WALLET,
          payload: walletId,
        })
      })
  }
}

export const endEditWallet = () => ({
  type: END_EDIT_WALLET
})
export const getTypePaymentProviders = () => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/type-payment-providers-thirdparties/`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_TYPE_PAYMENT_PROVIDERS,
          payload: { typePaymentProviders: data },
        })
      })
  }
}
export const getPaymentEstatus = () => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/payment-status/`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_PAYMENT_ESTATUS,
          payload: { paymentEstatus: data },
        })
      })
  }
}
export const getRealPaymentMethod = () => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/payment-method/`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_REAL_PAYMENT_METHOD,
          payload: { realPaymentMethod: data },
        })
      })
  }
}
export const getProviderInvoiceStatus = () => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/invoice-status-payments/`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_PROVIDER_INVOICE_STATUS,
          payload: { providerInvoiceStatus: data },
        })
      })
  }
}
export const getInvoiceUnits = (query) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/unit-key/search/`, { query }, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_INVOICE_UNITS,
          payload: { invoiceUnits: data },
        })
      })
  }
}
export const getProductService   = (query) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/product-service-sat/search/`, { query }, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_PRODUCT_SERVICE,
          payload: { productService: data },
        })
      })
  }
}
export const getProductSat = (id) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/product-service-sat/${id}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_PRODUCT_SAT,
          payload: { productSat: data },
        })
      })
  }
}

export const clearInvoiceUnits = () => ({
  type: CLEAR_INVOICE_UNITS
})
export const clearProductService = () => ({
  type: CLEAR_PRODUCT_SERVICE
})
export const getIvaRates = () => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/iva/`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_IVA_RATES,
          payload: { ivaRates: data },
        })
      })
  }
}
export const getIepsRates = () => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/ieps/`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_IEPS_RATES,
          payload: { iepsRates: data },
        })
      })
  }
}
export const getCurrencies = () => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/monedas/`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_CURRENCIES,
          payload: { currencies: data },
        })
      })
  }
}
export const getUsoCfdi = () => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/cfdi/`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_USO_CFDI,
          payload: { usoCfdi: data },
        })
      })
  }
}
export const getTipoRelacion = () => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/tipoRelacion/`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_USO_CFDI,
          payload: { tipoRelacion: data },
        })
      })
  }
}

export const getExchangeRate = () => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/tipoCambio/`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_EXCHANGE_RATE,
          payload: { exchangeRate: data },
        })
      })
  }
}

export const getFrecuency = () => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/frecuenciaPrestamo`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_FRECUENCY,
          payload: { frecuency: data },
        })
      })
  }
}

export const getInvoiceStatus = () => {
  const headers = getHeaders()

  return dispatch => {
    return axios.get(`${url}/estatusFacturacion`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_INVOICE_STATUS,
          payload: { invoiceStatus: data}
        })
      })
  }
}

export const getRegimenFiscalAction = () => {
  const headers = getHeaders()

  return dispatch => {
    return axios.get(`${url}/regimenFiscal`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_REGIMEN_FISCAL,
          payload: { regimenFiscal: data}
        })
      })
  }
}

export const getTaxObjectAction = () => {
  const headers = getHeaders()

  return dispatch => {
    return axios.get(`${url}/taxobject`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_TAX_OBJECT,
          payload: { taxObject: data}
        })
      })
  }
}

export const getExportationAction = () => {
  const headers = getHeaders()

  return dispatch => {
    return axios.get(`${url}/exportacion`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_EXPORTATION,
          payload: { exportation: data}
        })
      })
  }
}

export const getPeriodicidadAction = () => {
  const headers = getHeaders()

  return dispatch => {
    return axios.get(`${url}/periodicidad`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_PERIODICIDAD,
          payload: { periodicidad: data}
        })
      })
  }
}

export const getMesesAction = () => {
  const headers = getHeaders()

  return dispatch => {
    return axios.get(`${url}/meses`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_MESES,
          payload: { meses: data}
        })
      })
  }
}
