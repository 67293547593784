import React from 'react'
import { Button } from 'Components/common/bulma'

function OtpForm({
  handleOnLoginTas,
  handleOnSendQr,
}) {

  return (
    <div >
      <Button
        className="is-fullwidth margin-h-md is-black"
        medium
        type="button"
        onClick={handleOnLoginTas}>
        Acceso por tarjeta
      </Button>
      <Button className="is-fullwidth margin-h-md is-black"
        medium type="button"
        onClick={handleOnSendQr}>
        Activar acceso de dos factores
      </Button>
    </div>
  )
}

export default OtpForm
