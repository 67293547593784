import React, { useState, useCallback, useEffect } from 'react'
import { useMount, useUnmount } from 'ahooks'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import {
  getUserAction, createUserAction, resetUserAction,
  updateUserAction, deleteUserAction, resetPasswordUserAction,
  asignCardAction, revokeCardAction, revokeTfaAction,
} from 'Modules/users'
import { bindActionCreators } from 'redux'
import { Columns, Column, Box, Divider, Button, Field, Control } from 'Components/common/bulma'
import { SelectWithError, InputWithError, CheckWithError as Check } from 'Components/common/form'
import { userFieldsDefinition, initialRevokmentFields } from './userDefinitions'
import ActionBar from 'Components/common/actionBar';
import Show from 'Components/common/Show';
import { prop, pipe, equals } from 'ramda'
import useForm from 'Hooks/useForm'
import useRemove from 'Hooks/useRemove'
import { addNotification as addNotificationAction, addSuccessNotificationAction } from 'Modules/principal'
import Loader from 'Components/common/page/loaderFloat';

function UserForm({
  getUser,
  match,
  estatusPersona,
  permissions,
  pushNavigation,
  createUser,
  user,
  resetUser,
  addNotification,
  updateUser,
  deleteUser,
  resetPasswordUser,
  asignCard,
  revokeCard,
  addSuccessNotification,
  revokeTfa,
}) {
  const [
    userFields, setUserFields, onUserFieldChange,
    isValidUserForm, getUserValues, showErrors
  ] = useForm({ fields: userFieldsDefinition, initialValid: true })
  /* eslint-disable */
  const [
    revokmentFields, setRevokmentFields, onRevokmentFieldChange,
    isValidRevokmentForm, getRevokmentoValues, showRevokmentErrors
  ] = useForm({ fields: initialRevokmentFields, initialValid: true })

  const [revoke, setRevoke] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [Modal, setShowModal] = useRemove({
    title: 'Eliminar usuario',
    message: '¿Desea eliminar al Usuario y revocar su tarjeta de acceso seguro?',
    handleOnRemove: user => {
      setIsLoading(true)
      deleteUser(user)
        .then(() => {
          addNotification({
            type: 'success',
            message: 'El usuario se eliminó correctamente'
          })
          setIsLoading(false)
          pushNavigation('/usuarios')
        })
        .catch(() => {
          addNotification({
            type: 'danger',
            message: 'Ocurrió un error al eliminar el usuario'
          })
          setIsLoading(false)
        })
    },
  })

  const [ModalReset, setShowModalReset] = useRemove({
    title: 'Cambiar contraseña de usuario',
    message: '¿Estás seguro de cambiar la contraseña al Usuario?, el Usuario recibirá un correo con la liga para efectuar el cambio',
    type: 'default',
    actionButtonLabel: 'Enviar',
    handleOnRemove: () => {
      setIsLoading(true)
      resetPasswordUser(userFields.id.input.value)
        .then(() => {
          addNotification({
            type: 'success',
            message: 'El correo de cambio de contraseña se envió correctamente.'
          })
          setIsLoading(false)
        })
        .catch(() => {
          addNotification({
            type: 'danger',
            message: 'Ocurrió un error al enviar el correo de cambio de contraseña.',
          })
          setIsLoading(false)
        })
    },
  })

  const [ModalRevokeTfa, setShowModalRevokeTfa] = useRemove({
    title: 'Revocar Doble factor de autenticación',
    message: '¿Estás seguro de revocar el doble factor al Usuario?, el Usuario tendra que activar nuevamente el doble factor',
    type: 'default',
    actionButtonLabel: 'Revocar',
    handleOnRemove: () => {
      setIsLoading(true)
      revokeTfa({ userId: userFields.id.input.value })
        .then(() => {
          addNotification({
            type: 'success',
            message: 'El doble factor se revocó correctamente.'
          })
          setIsLoading(false)
        })
        .catch(() => {
          addNotification({
            type: 'danger',
            message: 'Ocurrió un error al revocar el doble factor.',
          })
          setIsLoading(false)
        })
    },
  })

  useMount(() => {
    const { id } = match.params

    if (id) {
      getUser(id);
    }
  })

  useUnmount(() => {
    resetUser()
  })

  useEffect(() => {
    if (user) {
      setUserFields(user)
    }
    // eslint-disable-next-line
  }, [user])

  const handleOnChangeRevoke = ({ target: { checked } }) => {
    setRevoke(checked)
  }

  const onSave = () => {
    try {
      if (!isValidUserForm()) {
        const validationError = new Error('ValidationError');
        validationError.name = 'ValidationError'
        throw validationError;
      }
      const userValues = getUserValues()
      setIsLoading(true);
      if (isEditing()) {
        updateUser(userValues)
          .then(() => {
            setIsLoading(false);
            addSuccessNotification('Usuario actualizado correctamente');
          }).catch(() => {
            addNotification({
              type: 'danger',
              message: 'Error al actualizar el usuario',
            })
            setIsLoading(false);
          });
      } else {
        createUser(userValues)
          .then(({ id }) => {
            setIsLoading(false);
            addNotification({
              type: 'success',
              message: 'Usuario creado correctamente',
            })
            pushNavigation(`/usuarios/${id}`)
          }).catch(() => {
            addNotification({
              type: 'danger',
              message: 'Error al crear el usuario',
            })
            setIsLoading(false);
          });
      }

    } catch (error) {
      if (error.name === 'ValidationError') {
        addNotification({
          type: 'danger',
          message: 'Favor de validar los campos',
        })
      }
    }

  }

  const getPermissions = useCallback(() => ({
    canSave: true,
    canDelete: isEditing(),
    // eslint-disable-next-line
  }), [userFields.id.input.value])

  const handleOnCancel = () => {
    pushNavigation('/usuarios')
  }

  const handleOnDelete = () => {
    setShowModal(userFields.id.input.value)
  }

  const handleOnAssign = async () => {
    if (isValidAssigmentForm()) {
      if (userFields.user.input.value) {
        addNotification({
          type: 'danger',
          message: 'No se puede asignar un Acceso Seguro, dado que el Usuario ya cuenta con Uno, favor de revocar el Acceso Seguro antes de Asignar uno nuevo',
        })

        return
      }
      try {
        setIsLoading(true);
        const user = await asignCard({ userId: userFields.id.input.value, ...getAssigmentValues() });
        setUserFields(user)
        addNotification({
          type: 'success',
          message: 'La tarjeta se asignó correctamente',
        })
        setIsLoading(false);
      } catch (error) {
        addNotification({
          type: 'danger',
          message: 'Ocurrió un error al asignar una tarjeta, favor de verificar que el usuario no tenga una tarjeta asignada',
        })
        setIsLoading(false);
      }
    } else {
      addNotification({
        type: 'danger',
        message: 'Favor de validar los campos',
      })
    }
  }

  const handleOnRevoke = async () => {
    if (isValidRevokmentForm()) {
      if (!userFields.user.input.value) {
        addNotification({
          type: 'danger',
          message: 'No se puede revocar un Acceso Seguro, dado que el Usuario no cuenta con un Acceso Seguro',
        })
        return
      }
      try {
        setIsLoading(true);
        const response = await revokeCard({ userId: userFields.id.input.value, ...getRevokmentoValues() });
        setUserFields(response)
        addNotification({
          type: 'success',
          message: 'La tarjeta se revocó correctamente',
        })
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        addNotification({
          type: 'danger',
          message: 'Ocurrió un error al revocar una tarjeta, favor de verificar que el usuario tenga una tarjeta asignada',
        })
      }

    } else {
      addNotification({
        type: 'danger',
        message: 'Favor de validar los campos',
      })
    }

  }

  const hasPermission = (section, permissions) => pipe(prop(section), (value) => +value, equals(1))(permissions);

  const allowInvoice = useCallback(() => hasPermission('facturacion', permissions),
    [permissions]);
  const allowDeposits = useCallback(() => hasPermission('depositos', permissions),
    [permissions])
  const allowProviderPayment = useCallback(() => hasPermission('pagoAproveedores', permissions),
    [permissions])
  const allowRetirement = useCallback(() => hasPermission('retiros', permissions),
    [permissions])
  const allowTransfer = useCallback(() => hasPermission('traspasos', permissions),
    [permissions])
  const allowCredits = useCallback(() => hasPermission('prestamos', permissions),
    [permissions])
  const allowInventory = useCallback(() => hasPermission('inventarios', permissions),
    [permissions])

  const isEditing = useCallback(() => !!userFields.id.input.value, [userFields.id.input.value])

  return (
    <Box style={{ position: 'relative' }}>
      <Modal />
      <ModalReset />
      <ModalRevokeTfa />
      <Show canShow={isLoading}>
        <Loader />
      </Show>
      <ActionBar
        onSave={onSave}
        onCancel={handleOnCancel}
        onDelete={handleOnDelete}
        basicRole={['Oalta']}
        permissions={getPermissions()}
      />
      <Columns>
        <Column className="is-half">
          <InputWithError
            onChange={onUserFieldChange}
            {...userFields.creationDate}
          />
        </Column>
        <Column className="is-half">
          <SelectWithError
            onChange={onUserFieldChange}
            options={estatusPersona}
            showErrors={showErrors}
            {...userFields.statusId}
          />
        </Column>
      </Columns>
      <Divider content="DATOS DEL USUARIO" />
      <Columns className="is-multiline">
        <Column className="is-half">
          <InputWithError
            onChange={onUserFieldChange}
            showErrors={showErrors}
            {...userFields.lastName}
            disabled={isEditing()}
          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onUserFieldChange}
            showErrors={showErrors}
            {...userFields.secondLastName}
            disabled={isEditing()}
          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onUserFieldChange}
            showErrors={showErrors}
            {...userFields.name}
            disabled={isEditing()}
          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onUserFieldChange}
            showErrors={showErrors}
            {...userFields.email}
          />
        </Column>
      </Columns>
      <Divider content="Tarjeta Acceso Seguro" />
      <Columns className="is-multiline">
        <Column className="is-half">
          <InputWithError
            onChange={onUserFieldChange}
            showErrors={showErrors}
            {...userFields.user}
          />
        </Column>
      </Columns>
      <Show canShow={isEditing()}>
        <Divider content="Revocación de tarjeta" />
        <Columns className="is-multiline">
          <Column className="is-full">
            <div className="field">
              <input id="revocarTarjeta" type="checkbox" name="revocarTarjeta"
                className="switch" checked={revoke} value={revoke} onChange={handleOnChangeRevoke} />
              <label htmlFor="revocarTarjeta">Revocar tarjeta</label>
            </div>
          </Column>
        </Columns>
      </Show>
      <Show canShow={revoke}>
        <Columns className="is-multiline">
          <Column className="is-half">
            <SelectWithError
              onChange={onRevokmentFieldChange}
              showErrors={showRevokmentErrors}
              {...revokmentFields.revokmentReason}
            />
          </Column>
          <Column className="is-half">
            <Field>
              <label className="label">&nbsp;</label>
              <Control>
                <Button onClick={handleOnRevoke} danger>Revocar tarjeta</Button>
              </Control>
            </Field>
          </Column>
        </Columns>
      </Show>
      <Show canShow={isEditing()}>
        <Columns>
          <Column className="is-half">
            <Button onClick={setShowModalReset}>Cambiar contraseña</Button>
            <Show canShow={user?.hasTfa}>
              <Button style={{ marginLeft: '10px' }} onClick={setShowModalRevokeTfa}>Revocar Doble Factor de Autenticación</Button>
            </Show>
          </Column>
        </Columns>
      </Show>
      <Divider content="Permisos" />
      <Columns>
        <Column className="is-two-fifths">
          Privilegios
        </Column>
        <Column className="has-text-centered">
          Operador
        </Column>
        <Column className="has-text-centered">
          Autorizador
        </Column>
        <Column className="has-text-centered">
          Consulta
        </Column>
      </Columns>
      <Columns>
        <Show canShow={allowInvoice()}>
          <Column className="is-two-fifths">
            Orden de Ingreso Tipo Facturación
          </Column>
          <Column>
            <Check
              controlClass="has-text-centered"
              onChange={onUserFieldChange}
              showErrors={showErrors}
              {...userFields.oFacturacion}
            />
          </Column>
          <Column>
            <Check
              controlClass="has-text-centered"
              onChange={onUserFieldChange}
              showErrors={showErrors}
              {...userFields.aFacturacion}
            />
          </Column>
          <Column></Column>
        </Show>
      </Columns>
      <Columns>
        <Show canShow={allowDeposits()}>
          <Column className="is-two-fifths">
            Orden de Ingreso Tipo Depósitos
          </Column>
          <Column>
            <Check
              controlClass="has-text-centered"
              onChange={onUserFieldChange}
              showErrors={showErrors}
              {...userFields.oDepositos}
            />
          </Column>
          <Column>
            <Check
              controlClass="has-text-centered"
              onChange={onUserFieldChange}
              showErrors={showErrors}
              {...userFields.aDepositos}
            />
          </Column>
          <Column>
          </Column>
        </Show>
      </Columns>
      <Columns>
        <Show canShow={allowProviderPayment()}>
          <Column className="is-two-fifths">
            Órdenes de Pago Tipo Distintas a Retiros
          </Column>
          <Column>
            <Check
              controlClass="has-text-centered"
              onChange={onUserFieldChange}
              showErrors={showErrors}
              {...userFields.oPagoProveedores}
            />
          </Column>
          <Column>
            <Check
              controlClass="has-text-centered"
              onChange={onUserFieldChange}
              showErrors={showErrors}
              {...userFields.aPagoProveedores}
            />
          </Column>
          <Column>
          </Column>
        </Show>
      </Columns>
      <Columns>
        <Show canShow={allowRetirement()}>
          <Column className="is-two-fifths">
            Órdenes de Pago Tipo Retiros
          </Column>
          <Column>
            <Check
              controlClass="has-text-centered"
              onChange={onUserFieldChange}
              showErrors={showErrors}
              {...userFields.oRetiros}
            />
          </Column>
          <Column>
            <Check
              controlClass="has-text-centered"
              onChange={onUserFieldChange}
              showErrors={showErrors}
              {...userFields.aRetiros}
            />
          </Column>
          <Column>
          </Column>
        </Show>
      </Columns>
      <Columns>
        <Show canShow={allowTransfer()}>
          <Column className="is-two-fifths">
            Órdenes de Traspaso
          </Column>
          <Column>
            <Check
              controlClass="has-text-centered"
              onChange={onUserFieldChange}
              showErrors={showErrors}
              {...userFields.oTraspaso}
            />
          </Column>
          <Column>
            <Check
              controlClass="has-text-centered"
              onChange={onUserFieldChange}
              showErrors={showErrors}
              {...userFields.aTraspaso}
            />
          </Column>
          <Column>
          </Column>
        </Show>
      </Columns>
      <Columns>
        <Show canShow={allowCredits()}>
          <Column className="is-two-fifths">
            Órdenes de Préstamo Tipo: Acreedoras
          </Column>
          <Column>
            <Check
              controlClass="has-text-centered"
              onChange={onUserFieldChange}
              showErrors={showErrors}
              {...userFields.oPresAcreedoras}
            />
          </Column>
          <Column>
            <Check
              controlClass="has-text-centered"
              onChange={onUserFieldChange}
              showErrors={showErrors}
              {...userFields.aPresAcreedoras}
            />
          </Column>
          <Column>
          </Column>
        </Show>
      </Columns>
      <Columns>
        <Show canShow={allowCredits()}>
          <Column className="is-two-fifths">
            Órdenes de Préstamo Tipo: Deudoras
          </Column>
          <Column>
            <Check
              controlClass="has-text-centered"
              onChange={onUserFieldChange}
              showErrors={showErrors}
              {...userFields.oPresDeudoras}
            />
          </Column>
          <Column>
            <Check
              controlClass="has-text-centered"
              onChange={onUserFieldChange}
              showErrors={showErrors}
              {...userFields.aPresDeudoras}
            />
          </Column>
          <Column>
          </Column>
        </Show>
      </Columns>
      <Columns>
        <Show canShow={allowCredits()}>
          <Column className="is-two-fifths">
            Órdenes de Préstamo Tipo: Amortización Cobro Préstamo
          </Column>
          <Column>
            <Check
              controlClass="has-text-centered"
              onChange={onUserFieldChange}
              showErrors={showErrors}
              {...userFields.oPresAmortizacionCobroPrestamo}
            />
          </Column>
          <Column>
            <Check
              controlClass="has-text-centered"
              onChange={onUserFieldChange}
              showErrors={showErrors}
              {...userFields.aPresAmortizacionCobroPrestamo}
            />
          </Column>
          <Column>
          </Column>
        </Show>
      </Columns>
      <Columns>
        <Show canShow={allowCredits()}>
          <Column className="is-two-fifths">
            Órdenes de Préstamo Tipo: Amortización Pago Préstamo
          </Column>
          <Column>
            <Check
              controlClass="has-text-centered"
              onChange={onUserFieldChange}
              showErrors={showErrors}
              {...userFields.oPresAmortizacionPagoPrestamo}
            />
          </Column>
          <Column>
            <Check
              controlClass="has-text-centered"
              onChange={onUserFieldChange}
              showErrors={showErrors}
              {...userFields.aPresAmortizacionPagoPrestamo}
            />
          </Column>
          <Column>
          </Column>
        </Show>
      </Columns>
      <Columns>
        <Column className="is-two-fifths">
          Alta en Catálogos
        </Column>
        <Column className="is-one-fifth has-text-centered">
          <Check
            controlClass="has-text-centered"
            onChange={onUserFieldChange}
            showErrors={showErrors}
            {...userFields.oAlta}
          />
        </Column>
        <Column className="is-one-fifth">
        </Column>
        <Column className="is-one-fifth">
        </Column>
      </Columns>
      <Columns>
        <Column className="is-two-fifths">
          Conciliar
        </Column>
        <Column className="is-one-fifth">
          <Check
            controlClass="has-text-centered"
            onChange={onUserFieldChange}
            showErrors={showErrors}
            {...userFields.oConciliaciones}
          />
        </Column>
        <Column className="is-one-fifth">
        </Column>
        <Column className="is-one-fifth">
        </Column>
      </Columns>
      <Columns>
        <Show canShow={allowInventory()}>
          <Column className="is-two-fifths">
            Inventarios
          </Column>
          <Column className="is-one-fifth">
            <Check
              controlClass="has-text-centered"
              onChange={onUserFieldChange}
              showErrors={showErrors}
              {...userFields.cInventarios}
            />
          </Column>
          <Column className="is-one-fifth">
          </Column>
          <Column className="is-one-fifth">
          </Column>
        </Show>
      </Columns>
      <Columns>
        <Column className="is-two-fifths">
          Usuarios
        </Column>
        <Column className="is-one-fifth">
        </Column>
        <Column className="is-one-fifth">
          <Check
            controlClass="has-text-centered"
            onChange={onUserFieldChange}
            showErrors={showErrors}
            {...userFields.aUsuario}
          />
        </Column>
        <Column className="is-one-fifth">
        </Column>
      </Columns>
      <Columns>
        <Column className="is-two-fifths">
          Saldos y Reportes
        </Column>
        <Column className="is-one-fifth">
        </Column>
        <Column className="is-one-fifth">
        </Column>
        <Column className="is-one-fifth">
          <Check
            controlClass="has-text-centered"
            onChange={onUserFieldChange}
            showErrors={showErrors}
            {...userFields.cReportes}
          />
        </Column>
      </Columns>
      <Columns>
        <Column className="is-two-fifths">
          Contratos
        </Column>
        <Column className="is-one-fifth">
          <Check
            controlClass="has-text-centered"
            onChange={onUserFieldChange}
            showErrors={showErrors}
            {...userFields.oContratos}
          />
        </Column>
        <Column className="is-one-fifth">
          <Check
            controlClass="has-text-centered"
            onChange={onUserFieldChange}
            showErrors={showErrors}
            {...userFields.aContratos}
          />
        </Column>
        <Column className="is-one-fifth">
        </Column>
      </Columns>
    </Box>
  )
}

const mapStateToProps = ({ catalogos, users, principal }) => ({
  estatusPersona: catalogos.estatusPersona,
  user: users.user,
  permissions: principal.inventoryPermission,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getUser: getUserAction,
  pushNavigation: push,
  createUser: createUserAction,
  resetUser: resetUserAction,
  addNotification: addNotificationAction,
  updateUser: updateUserAction,
  deleteUser: deleteUserAction,
  resetPasswordUser: resetPasswordUserAction,
  asignCard: asignCardAction,
  revokeCard: revokeCardAction,
  addSuccessNotification: addSuccessNotificationAction,
  revokeTfa: revokeTfaAction,
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(UserForm)
