import React, { useState } from 'react'
import File from 'Components/common/form/File'
import http from 'App/services/httpService';
import CifLoader from 'App/services/cifLoader'

const cif = new CifLoader(http);

export default function CifComponent({ onCifLoaded }) {
  const [file, setFile] = useState(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  const onChange = ({ target }) => {
    setFile(target.files[0])
  }

  const onUpload = () => {
    setIsLoading(true)
    let data = new FormData()

    data.append('file', file, file.name)
    cif.loadCif(data)
      .then(({ data }) => {
        onCifLoaded(data)
        setIsLoading(false)
        setIsLoaded(true)
      })
  }

  const removeFile = () => {
    setFile(undefined)
    setIsLoading(false)

    setIsLoaded(false)
  }

  return (
    <div>
      <File
        name="cif"
        label="Adjuntar CIF PDF"
        selectLabel="Adjuntar CIF PDF"
        value=""
        fileName={(file && file.name)}
        file={file}
        accept="application/pdf"
        onChange={onChange}
        onClick={onUpload}
        removeFile={removeFile}
        isUploading={isLoading}
        isUploaded={isLoaded}
      />
    </div>
  )
}
