import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Box,
  SubTitle,
  Message,
  Delete,
  Button,
  Modal,
  Tabs,
  Icon,
  Divider,
  Columns,
  Column,
} from 'Components/common/bulma'
import { default as Table } from 'Components/common/table'
import { Trans } from '@lingui/macro'
import { default as ActionBar } from 'Components/common/actionBar'
import { default as CollaboratorCreate } from 'Components/catalogs/collaborators/Create'
import { collaboratorData, accountColDef, accountData, addressData, regimenFiscalReceptor } from './data'
import AccountCreate from 'Components/catalogos/accounts/create'
import {
  createCollaborator,
  endEditCollaborator,
  getCollaborator,
  updateCollaborator,
  deleteCollaborator,
  getAccounts,
  deleteAccount,
  createAccount,
} from 'Modules/catalogs/collaborators'
import {
  getBanks,
  getDireccion,
} from 'Modules/catalogos'
import { getDataInput, getValues, validateData, mapPropsToState, validator, getClabeData } from '../../../helpers'
import { addNotification } from 'Modules/principal'
import { push } from 'connected-react-router'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import AddressSection from 'Components/catalogs/collaborators/addressSection'
import {
  getPaises,
  getEntidades,
  getRegimenFiscalAction,
} from 'Modules/catalogos'
import { Select } from 'Components/common/form'
import { includes, flip, cond, pipe, equals, T, always, filter, prop, F, find, propEq } from 'ramda'
import { toNumber } from 'ramda-adjunct';


const LONGITUD_CODIGO_POSTAL = 5


export class Create extends Component {
  state = {
    collaboratorData,
    showErrors: false,
    collaborator: undefined,
    accountTab: 'list',
    accoun: {},
    showAccountModal: false,
    accountData,
    addressData,
    regimenFiscalReceptor,
  }
  componentDidMount() {
    const id = this.props.match.params.id
    this.props.getPaises()
    this.props.getEntidades()
    this.props.getRegimenFiscal()
    if (id && (id !== this.state.collaborator)) {
      this.props.getCollaborator(id)
      this.props.getAccounts(id)
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.collaborator.id && nextProps.collaborator.id !== this.state.collaborator) {
      const collaboratorData = mapPropsToState(this.state.collaboratorData, nextProps.collaborator)
      const addressData = mapPropsToState(this.state.addressData, nextProps.collaborator)
      const regimenFiscalReceptor = mapPropsToState(this.state.regimenFiscalReceptor, nextProps.collaborator)

      this.setState({
        collaborator: nextProps.collaborator.id,
        collaboratorData,
        addressData,
        regimenFiscalReceptor,
      })
    }

  }
  componentWillUnmount() {
    this.props.endEditCollaborator()
  }
  onChangeInput = ({ target }, section) => {
    const { name } = target
    const data = getDataInput(target, this.state[section])
    const field = data[name]

    this.setState({
      [section]: data,
      showErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }
  onCancel = () => {
    this.props.push('/catalogos/colaboradores')
  }
  createCollaborator = (cb) => {
    const { data, hasErrors } = validateData(this.state.collaboratorData)
    const { data: addressData, hasAddressDataErrors } = validateData(this.state.addressData)
    const { data: regimenFiscalReceptor, hasRegimenDataErrors } = validateData(this.state.regimenFiscalReceptor)

    if (hasErrors || hasAddressDataErrors || hasRegimenDataErrors) {
      this.setState({
        collaboratorData: data,
        showErrors: true,
        addressData,
        regimenFiscalReceptor,
      })
      this.props.addNotification({
        type: 'danger',
        message: '¡Favor de validar los campos!'
      })
      return
    }
    const values = getValues(this.state.collaboratorData)
    const addressValues = getValues(this.state.addressData)
    const regimenFiscalReceptorValues = getValues(this.state.regimenFiscalReceptor)
    if (this.state.collaborator) {
      this.props.updateCollaborator({ ...values, id: this.state.collaborator, ...addressValues, ...regimenFiscalReceptorValues })
        .then(() => {
          this.props.addNotification({
            type: 'success',
            message: 'El colaborador se actualizó correctamente'
          })
        })
        .catch(({ response: { data } }) => {
          this.props.addNotification({
            type: 'danger',
            message: JSON.stringify(data)
          })
        })
    } else {
      this.props.createCollaborator({ ...values, ...addressValues, ...regimenFiscalReceptorValues })
        .then(({ id }) => {
          this.props.addNotification({
            type: 'success',
            message: 'El colaborador se dia de alta correctamente'
          })
          this.props.push(`/catalogos/colaboradores/${id}`)
          typeof cb === 'function' && cb()
        })
        .catch(({ response: { data } }) => {
          this.props.addNotification({
            type: 'danger',
            message: JSON.stringify(data)
          })
        })
    }
  }
  getPermissions = () => {
    return {
      canDelete: !!this.state.collaborator,
      canSave: true
    }
  }
  toggleModal = () => {
    this.setState(state => ({
      showModal: !state.showModal,
    }))
  }
  toggleAccountModal = (account) => {
    this.setState(state => ({
      showAccountModal: !state.showAccountModal,
      account,
    }))
  }
  deleteCollaborator = () => {
    this.props.deleteCollaborator(this.state.collaborator)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'El colaborador se eliminó correctamente'
        })
        this.props.push(`/catalogos/colaboradores`)
      })
  }
  deleteAccount = () => {
    this.props.deleteAccount(this.state.collaborator, this.state.account.id)
      .then(() => {
        this.setState({
          showAccountModal: false,
          account: {},
        })
        this.props.addNotification({
          type: 'success',
          message: 'La cuenta se eliminó correctamente'
        })
      })
  }
  createAccount = () => {
    const { data, hasErrors } = validateData(this.state.accountData)

    if (hasErrors) {
      this.setState({
        accountData: data,
        showAccountErrors: true
      })
      this.props.addNotification({
        type: 'danger',
        message: '¡Favor de validar los campos!'
      })
      return
    }
    this.props.createAccount(this.state.collaborator, getValues(this.state.accountData))
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'La cuenta se agregó correctamente'
        })
        this.setState({
          accountData: accountData
        })
        this.props.getAccounts(this.state.collaborator)
          .then(() => {
            this.setState({
              accountTab: 'list',
            })
          })
      })
  }
  onChangeClabe = (field) => {
    if (field.value.length === 18 && validator(field.value, field.validation, this.state.accountData).valid) {
      const { place, account } = getClabeData(field.value)
      this.props.getBanks(field.value)
        .then(({ clabe }) => {
          const accountData = mapPropsToState(this.state.accountData, { banco: clabe, plaza: place, cuentaBancaria: account })
          this.setState({ accountData })
        })
    }
  }
  changeAccountTab = (view) => {
    const collaborator = this.state.collaborator

    if (!collaborator && view === 'new') {
      this.createCollaborator(() => {
        this.setState({
          accountTab: view
        })
      })
      return
    }
    this.setState({
      accountTab: view
    })
  }
  customColActions = (row) => (
    <Button danger outlined onClick={() => this.toggleAccountModal(row)}>
      <Icon>
        <FontAwesomeIcon icon={faTrash} />
      </Icon>
    </Button>
  )

  onCifLoaded = (data) => {
    const regimen = find(propEq('descripcionCif', data.regimen))(this.props.regimenFiscal)

    const collaboratorData = mapPropsToState(this.state.collaboratorData, {
      rfc: data.rfc,
      nombreORazonSocial: data.denominacionORazonSocial,
      uriCsf: data.url,
      apellidoPaterno: data.apellidoPaterno,
      apellidoMaterno: data.apellidoMaterno,
      nombres: data.nombre,
      curp: data.curp,
      correoElectronico: data.correoElectronico,
    })

    const addressData = mapPropsToState(this.state.addressData, {
      direccion: true,
      pais: 141,
      entidadFederativa: data.entidadFederativa,
      municipio: data.municipioODelegacion,
      colonia: data.colonia,
      calle: data.nombreDeLaVialidad,
      exterior: data.numeroExterior,
      interior: data.numeroInterior,
      cp: data.cp,
    })

    const regimenFiscalReceptor = mapPropsToState(this.state.regimenFiscalReceptor, {
      regimenFiscalReceptor: regimen?.id,
    })

    this.setState({ collaboratorData, addressData, regimenFiscalReceptor })
  }

  onChangeCp = ({ value }) => {
    const { addressData } = this.state
    if (value.length === LONGITUD_CODIGO_POSTAL) {
      this.props.getDireccion(value).then((response) => {
        if (response.length) {
          const { estado, municipio, asentamiento } = response[0]

          addressData.entidadFederativa.value = estado
          addressData.municipio.value = municipio
          addressData.colonia.value = asentamiento

          this.setState({
            addressData,
          })
        }
      })
    }
  }

  filterRegimenFiscal = (tipoPersona, regimenFiscal) => {
    const invertedIncludes = flip(includes)
    if (!tipoPersona) {
      return
    }

    const filterCond = cond([
      [invertedIncludes([4, 6, 7]), always(filter(pipe(prop('id'), toNumber, invertedIncludes([12, 20]))))],
      [equals(1), always(filter(pipe(prop('moral'), toNumber, equals(1))))],
      [equals(2), always(filter(pipe(prop('fisica'), toNumber, equals(1))))],
      [T, always(filter(F))]
    ])(tipoPersona)

    return filterCond(regimenFiscal)
  }

  render() {
    const { estatusPersona, accounts, paises, entidades } = this.props
    return (
      <Box>
        <Modal isActive={this.state.showModal}>
          <Message danger>
            <Message.Header>
              <p>Eliminar colaborador</p>
              <Delete onClick={this.toggleModal} />
            </Message.Header>
            <Message.Body className="has-text-centered">
              ¿Esta seguro de querer eliminar el colaborador?
              <br />
              <strong>{this.props.collaborator.nombreConcatenado}</strong>
              <div className="margin-top-lg">
                <Button danger onClick={this.deleteCollaborator}>Eliminar</Button>
                <Button default className="margin-left-sm" onClick={this.toggleModal}>
                  Cancelar
                </Button>
              </div>
            </Message.Body>
          </Message>
        </Modal>
        <Modal isActive={this.state.showAccountModal}>
          <Message danger>
            <Message.Header>
              <p>Eliminar cuenta</p>
              <Delete onClick={this.toggleAccountModal} />
            </Message.Header>
            <Message.Body className="has-text-centered">
              ¿Esta seguro de querer eliminar la cuenta seleccionada?
              <div className="margin-top-lg">
                <Button danger onClick={this.deleteAccount}>Eliminar</Button>
                <Button default className="margin-left-sm" onClick={this.toggleAccountModal}>
                  Cancelar
                </Button>
              </div>
            </Message.Body>
          </Message>
        </Modal>
        <SubTitle className="has-text-centered" is='3'>
          <Trans>Catálogo de colaboradores</Trans>
        </SubTitle>
        <ActionBar
          permissions={this.getPermissions()}
          onCancel={this.onCancel}
          onSave={this.createCollaborator}
          onDelete={this.toggleModal}
          basicRole={['Oalta']}
        />
        <CollaboratorCreate
          estatusPersona={estatusPersona}
          data={this.state.collaboratorData}
          onChange={e => this.onChangeInput(e, 'collaboratorData')}
          showErrors={this.state.showErrors}
          onCifLoaded={this.onCifLoaded}
        />
        <Divider content="DIRECCIÓN" />
        <AddressSection
          data={this.state.addressData}
          onChange={e => this.onChangeInput(e, 'addressData')}
          paises={paises}
          entidades={entidades}
          showErrors={this.state.showErrors}
        />
        <Divider content="REGIMEN FISCAL RECEPTOR" />
        <Columns>
          <Column className="is-half">
            <Select
              onChange={(e) => this.onChangeInput(e, 'regimenFiscalReceptor')}
              options={this.filterRegimenFiscal(2, this.props.regimenFiscal)}
              showErrors={this.state.showErrors}
              {...this.state.regimenFiscalReceptor.regimenFiscalReceptor}
            />

          </Column>
        </Columns>
        <Tabs>
          <ul>
            <li className={classnames({ 'is-active': this.state.accountTab === 'list' })}>
              <Button className="is-text" onClick={() => this.changeAccountTab('list')}><Trans>Cuentas</Trans></Button></li>
            <li>
              <Button success outlined onClick={() => this.changeAccountTab('new')}>
                <Icon>
                  <FontAwesomeIcon icon={faPlus} />
                </Icon>
                <Trans render="span">Agregar cuenta bancaria</Trans>
              </Button>
            </li>
          </ul>
        </Tabs>
        {this.state.accountTab === 'list' && (
          <Table
            colsetup={accountColDef}
            coldata={accounts}
            tableClassName='table is-striped is-hoverable is-fullwidth'
            emptyTableMarkUp={(<span>Aun no hay cuentas</span>)}
            customCol={{
              customColName: 'acciones',
              renderFunc: this.customColActions
            }}
            initialFetch={false}
            refresh={(page) => this.props.getAccounts(this.state.collaborator, page)}
          />
        )}
        {this.state.accountTab === 'new' && (
          <AccountCreate
            data={this.state.accountData}
            estatusPersona={estatusPersona}
            onChange={e => this.onChangeInput(e, 'accountData')}
            showErrors={this.state.showAccountErrors}
            onSave={this.createAccount}
            onCancel={this.cancelAccount}
          />)}
      </Box>
    )
  }
}

const mapStateToProps = ({ catalogos, catalogs }) => ({
  estatusPersona: catalogos.estatusPersona,
  collaborator: catalogs.collaborators.collaborator,
  accounts: catalogs.collaborators.accounts,
  isFetching: catalogs.collaborators.isFetchingCollaborators,
  paises: catalogos.paises,
  entidades: catalogos.entidades,
  direccion: catalogos.direccion,
  regimenFiscal: catalogos.regimenFiscal,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  createCollaborator,
  addNotification,
  push,
  endEditCollaborator,
  getCollaborator,
  updateCollaborator,
  deleteCollaborator,
  getAccounts,
  deleteAccount,
  createAccount,
  getBanks,
  getPaises,
  getEntidades,
  getDireccion,
  getRegimenFiscal: getRegimenFiscalAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Create)
