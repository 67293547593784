import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import { Box, Button, Columns, Column, Icon } from 'Components/common/bulma'
import { Check, Input } from 'Components/common/form'
import { ActionBar } from 'Components/common'
import {
  getTransaction,
  getClientOrders,
  getConciliation,
  getMoneyLenderOrders,
  getOrders,
  conciliate,
} from 'Modules/conciliations'
import numeral from 'numeral'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt, faCheckDouble } from '@fortawesome/free-solid-svg-icons'
import { addNotification } from 'Modules/principal'
import moment from 'moment'

export class ConciliateForm extends Component {
  state = {
    selectedOrders: 0,
    orders: [],
    currency: '',
    isSameCurrency: true,
    selectedAmount: 0,
    montoTomar: 0,
    showError: false,
    isConciliating: false,
  }

  componentDidMount() {
    const { id, idAgregator } = this.props.match.params
    const { getTransaction, getConciliation } = this.props


    getTransaction(idAgregator)
    getConciliation(id)
  }

  componentWillReceiveProps(nextProps) {
    const { getClientOrders, getMoneyLenderOrders, getOrders } = this.props
    if (this.props.conciliation !== nextProps.conciliation && nextProps.conciliation.cliente) {
      getClientOrders(nextProps.conciliation.idConciliacion)
    }

    if (this.props.conciliation !== nextProps.conciliation && nextProps.conciliation.prestamista) {
      getMoneyLenderOrders(nextProps.conciliation.idConciliacion)
    }

    if (this.props.conciliation !== nextProps.conciliation && (!nextProps.conciliation.prestamista &&
      !nextProps.conciliation.cliente)) {
      getOrders(nextProps.conciliation.idConciliacion)
    }

    if (this.props.clientOrders !== nextProps.clientOrders) {
      this.setState({
        orders: nextProps.clientOrders.map(it => ({
          ...it,
          isSelected: false,
          isSelectable: true,
          showInput: false,
          montoTomar: 0,
          montoTomartransaccion: 0,
        }))
      })
    }
  }

  onBack = () => {
    this.props.push('/conciliaciones')
  }

  onChangeCheck = ({ target: { checked } }, idx) => {
    let { orders, selectedOrders, currency, isSameCurrency, type, client } = this.state
    const { moneda: currencyConciliation } = this.props.conciliation

    const isFirst = checked && !selectedOrders
    const isUnselectedLast = !checked && (selectedOrders === 1)

    orders[idx].showInput = false

    if (isFirst) {
      currency = orders[idx].divisaMoneda;
      isSameCurrency = orders[idx].divisaMoneda === currencyConciliation;
      type = orders[idx].type;
      client = orders[idx].nombreRazonSocial;
    }

    if (isUnselectedLast) {
      currency = '';
      isSameCurrency = true;
    }

    if (orders[idx].isSelectable) {
      orders[idx].isSelected = checked
      if (checked) {
        orders[idx].montoTomar = orders[idx].montoPorCobrar
        orders[idx].montoTomartransaccion = this.props.conciliation.monto
      } else {
        orders[idx].montoTomar = 0
        orders[idx].montoTomartransaccion = 0
      }
    }

    this.setState({
      orders,
      currency,
      isSameCurrency,
      showError: false,
      type,
      client
    }, () => this.updateInfo(idx))
  }

  validateOrder = () => {

  }

  updateInfo = idx => {
    let { orders, selectedAmount, isSameCurrency } = this.state
    const { monto } = this.props.conciliation

    const data = this.state.orders.reduce((acc, it) => {
      if (it.isSelected) {
        acc.selectedOrders += 1;
        acc.selectedAmount += Number(isSameCurrency ? it.montoPorCobrar : it.montoTomar);
        acc.montoTomar += Number(isSameCurrency ? it.montoTomar : it.montoTomartransaccion);
      }

      return acc
    }, {
      selectedOrders: 0,
      selectedAmount: 0,
      montoTomar: 0
    })
    const exceed = data.selectedAmount >= Number(monto)

    if (isSameCurrency && (data.selectedAmount - Number(monto)) > .1 && orders[idx].isSelected) {
      orders[idx].showInput = true
      orders[idx].montoTomar = Number(monto) - selectedAmount
      data.montoTomar = data.montoTomar + orders[idx].montoTomar - orders[idx].montoPorCobrar
    }

    orders = orders.map(it => {
      let isSelectable = (exceed && !it.isSelected)
      it.isSelectable = !isSelectable

      if (data.selectedOrders) {
        if (this.state.isSameCurrency && it.isSelectable) {
          it.isSelectable = (it.divisaMoneda === this.props.conciliation.moneda)
        } else if (!this.state.isSameCurrency && it.isSelectable) {
          it.isSelectable = (it.divisaMoneda !== this.props.conciliation.moneda)
        }

        if ((this.state.client !== it.nombreRazonSocial) && it.isSelectable) {
          it.isSelectable = false
        }

        console.log('this.state.type !== it.typ: ', this.state.type, it.type);
        if ((this.state.type !== it.type) && it.isSelectable) {
          it.isSelectable = false
        }
      }

      return it
    })

    this.setState({ ...data, orders })
  }

  onChangeInput = (value, idx) => {
    this.setState(({ orders, isSameCurrency }) => {
      orders[idx].montoTomar = Number(value)
      return ({
        orders,
        montoTomar: orders.reduce((prev, next) => {
          if (next.isSelected) {
            return (prev + Number(isSameCurrency ? next.montoTomar: next.montoTomartransaccion))
          }
          return prev
        }, 0),
        showError: false
      })
    })
  }

  conciliate = () => {
    const { montoTomar } = this.state
    const { monto } = this.props.conciliation

    if (!((+montoTomar + .1) >= +monto && +monto >= (+montoTomar - .1))) {
      this.setState({
        showError: true
      })
      return
    }

    this.setState({isConciliating: true})
    const orders = this.state.orders.filter(it => it.isSelected).map(it => ({
      idOrden: it.id,
      montoTomar: it.montoTomar,
      montoTomartransaccion: this.state.isSameCurrency ? undefined : it.montoTomartransaccion,
      tipo: it.type
    }))
    this.props.conciliate(orders, this.props.match.params.id)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'La conciliación se ha hecho con éxito'
        })
        this.props.push('/conciliaciones')
      })
      .catch(({ response: { data } }) => {
        this.props.addNotification({
          type: 'danger',
          message: data.message
        })
        this.setState({isConciliating: false})
      })
  }

  onSelectOrder = (value, idx) => {
    this.setState(({orders, isSameCurrency}) => {

      orders[idx].isPartial = value
      if (!value) {
        orders[idx].montoTomar = orders[idx].montoPorCobrar
        orders[idx].montoTomartransaccion = this.props.conciliation.monto
      }
      return ({
        orders,
        montoTomar: orders.reduce((prev, next) => {
          if (next.isSelected) {
            return (prev + Number(isSameCurrency ? next.montoTomar: next.montoTomartransaccion))
          }
          return prev
          }, 0),
        })
    })
  }

  onChangeTransaccion = (value, idx) => {
    this.setState(({ orders, isSameCurrency }) => {
      orders[idx].montoTomartransaccion = Number(value)
      return ({
        orders,
        montoTomar: orders.reduce((prev, next) => {
          if (next.isSelected) {
            return (prev + Number(isSameCurrency ? next.montoTomar: next.montoTomartransaccion))
          }
          return prev
        }, 0),
        showError: false
      })
    })
  }

  render() {
    const { orders, isSameCurrency } = this.state
    const { conciliation } = this.props
    return (
      <Box>
        <ActionBar
          customButton={<Button onClick={this.onBack}>Atrás</Button>}
          basicRole={['Oconciliaicones']}
          permissions={[]}
        />
        <Box>
          <article className="media">
            <div className="media-left has-text-info" style={{ width: '100px', height: '70px' }}>
              <Icon style={{ marginTop: '40px', marginLeft: '30px' }}>
                <FontAwesomeIcon icon={faExchangeAlt} size="3x" />
              </Icon>
            </div>
            <div className="media-content">
              <div className="content">
                <h6 className="subtitle is-5 has-text-grey" style={{ fontWeight: 600 }}>DEPOSITO EN BANCO
                  <small className="has-text-grey"> #{conciliation.idConciliacion}</small></h6>
                <h7 className="subtitle is-6">{conciliation.nombreOrazonSocial}</h7>
                <h6 className="subtitle is-3" style={{ fontWeight: 700 }}>
                  {numeral(conciliation.monto).format('$ 0,0.00')}
                </h6>
                <p>Moneda:  {conciliation.moneda}</p>
                <p >Clabe: {conciliation.clabe}</p>
                <p >Referencia: {conciliation.referencia}</p>
              </div>
            </div>
          </article>
        </Box>
        <h6 className="subtitle is-6 has-text-grey" style={{ fontWeight: 900 }}>ORDENES</h6>
        <Columns>
          <Column className="is-8">
            {
              orders.map((order, idx) => <Order key={idx} order={{ ...order, key: idx }} onChange={val => this.onChangeCheck(val, idx)}
                onChangeInput={this.onChangeInput}
                isSameCurrency={this.state.isSameCurrency}
                onSelectOrder={this.onSelectOrder}
                onChangeTransaccion={this.onChangeTransaccion}
                monedaConciliacion={this.props.conciliation.moneda}
              />)
            }
          </Column>
          <Column className="is-4">
            <Box>
              <p>Misma moneda: <strong>{isSameCurrency ? 'Si' : 'No'}</strong></p>
              <p>Ordenes seleccionadas: <strong>{this.state.selectedOrders}</strong></p>
              {this.state.isSameCurrency && <p className="title is-6"><small className="has-text-left">Sumatoria monto por cobrar de las órdenes: </small>{numeral(this.state.selectedAmount).format('$ 0,0.00')}</p>}
              <p className="title is-4"><small className="has-text-left">Monto a abonar: </small><br></br>{numeral(this.state.montoTomar).format('$ 0,0.00')}</p>
              {this.state.isSameCurrency && <p className="title is-6"><small className="has-text-left">Monto por cobrar de las órdenes despues de abonar: </small>
                {numeral(this.state.selectedAmount - this.state.montoTomar).format('$ 0,0.00')}
              </p>}
              {this.state.showError && <span className="has-text-danger">Favor de revisar los montos</span>}
            </Box>
            <Button
              onClick={this.conciliate} info className="is-large is-pulled-right"
              disabled={this.state.isConciliating}
              loading={this.state.isConciliating}
              >
              <Icon >
                <FontAwesomeIcon icon={faCheckDouble} />
              </Icon>
              <span>Conciliar</span>
            </Button>
          </Column>
        </Columns>
      </Box>
    )
  }
}

const Order = ({ order, onChange, onChangeInput, isSameCurrency, onSelectOrder, onChangeTransaccion, monedaConciliacion }) => {
  return (<Box className={classnames('conciliate-order', {
    selected: order.isSelected, disabled: !order.isSelectable,
    selectable: order.isSelectable
  })}>
    <article className="media">
      <div className="media-left">
        <Check
          id={`order-${order.key}`}
          name={`order-${order.key}`}
          onChange={onChange}
          value={order.isSelected}
          className="is-large is-white"
          disabled={!order.isSelectable}
        />
      </div>
      <div className="media-content">
        <div className="content">
          <p className="title is-5"><small>Monto por cobrar: </small>{numeral(order.montoPorCobrar).format('$ 0,0.00')}</p>
          <p className="subtitle is-7">{order.nombreRazonSocial}</p>
          <p>
            <strong className="has-text-grey">#{order.id}</strong>
            <br />
            <span>Fecha: {order.fecha ? moment(order.fecha).format('DD/MM/YYYY') : ''}</span>
            <br />
            <span className="subtitle is-6">Total: {numeral(order.total).format('$ 0,0.00')} {order.divisaMoneda}</span>
          </p>
          <p>Tipo de orden: {order.tipoOrden}</p>
          {order.tipoOrden === 'Facturación' && <p>Factura: {order.numeroFactura}</p>}
          {(order.showInput && isSameCurrency) && <p>
            <Input
              onChange={({ target: { value } }) => onChangeInput(value, order.key)}
              value={order.montoTomar}
              name={`order-${order.key}`}
              label="Monto a abonar a la orden"
              type="number"
              max={order.montoPorCobrar}
            />
          </p>}
          {(!isSameCurrency && order.isSelectable) && <div>Si la transacción corresponde a más ordenes, prende el switch</div>}
          {(!isSameCurrency && order.isSelectable && order.isSelected) && <div className="field">
            <input id={`isPartial${order.key}`} type="checkbox" name={`isPartial${order.key}`}
              className="switch" value={order.isSelected} onChange={(e) => onSelectOrder(e.target.checked, order.key)} />
            <label htmlFor={`isPartial${order.key}`}>{order.isSelected}</label>
          </div>}
          {(!isSameCurrency && order.isSelectable && order.isPartial) && <p>
            <Input
              onChange={({ target: { value } }) => onChangeInput(value, order.key)}
              value={order.montoTomar}
              name={`order-${order.key}`}
              label={`¿Cúal es el monto a abonar de la orden en ${order.divisaMoneda}?`}
              max={order.montoPorCobrar}
            />
          </p>}
          {(!isSameCurrency && order.isSelectable && order.isPartial) && <p>
            <Input
              onChange={({ target: { value } }) => onChangeTransaccion(value, order.key)}
              value={order.montoTomartransaccion}
              name={`order-${order.key}`}
              label={`¿A cuantos ${monedaConciliacion} corresponde el depósito en bancos?`}
            />
          </p>}
          {(!isSameCurrency && order.isSelected && order.isPartial) &&
            <p>Tipo de cambio: <strong>{order.divisaMoneda === 'MXN' ? numeral(+order.montoTomar / +order.montoTomartransaccion).format('0.00') : numeral(+order.montoTomartransaccion / +order.montoTomar).format('0.00')}</strong></p>}
        </div>
      </div>
    </article>
  </Box>)
}

const mapStateToProps = ({ conciliations }) => ({
  conciliation: conciliations.conciliation,
  clientOrders: conciliations.clientOrders,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  push,
  getTransaction,
  getClientOrders,
  getConciliation,
  getMoneyLenderOrders,
  getOrders,
  conciliate,
  addNotification,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ConciliateForm)
