import * as React from "react"

const RocketComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        fill: "#cff09e",
      }}
      d="m334.658 213.481.109-10.729c0-2.982 73.661 82.489 73.661 82.489a75.764 75.764 0 0 1 19.132 50.335v88.174c0 5.974-4.842 10.823-10.823 10.823-5.975 0-10.818-4.849-10.818-10.823v-28.352l-68.527-66.497c-1.831-2.059-2.62-4.632-2.627-7.189h-.103v-108.23h-.004v-.001z"
    />
    <path
      style={{
        fill: "#507c5c",
      }}
      d="M416.737 448.488c-13.638 0-24.733-11.097-24.733-24.737v-22.466l-64.301-62.397a14.108 14.108 0 0 1-.708-.74c-3.418-3.843-5.512-8.725-6.021-13.947a13.878 13.878 0 0 1-.225-2.488V213.914a13.78 13.78 0 0 1-.006-.575l.109-10.699c.063-7.632 6.333-13.878 13.979-13.878 7.005 0 9.732 3.01 22.838 17.473 6.256 6.905 14.781 16.51 25.337 28.553 17.368 19.812 34.672 39.875 35.896 41.293a89.605 89.605 0 0 1 22.576 59.497v88.174c-.001 13.639-11.1 24.736-24.741 24.736zm-68.116-128.082 65.024 63.101v-47.93a61.793 61.793 0 0 0-15.759-41.249c-15.079-17.493-34.461-39.756-49.31-56.508v82.203c.02.125.032.255.045.383z"
    />
    <path
      style={{
        fill: "#cff09e",
      }}
      d="m177.342 213.481-.107-10.729c0-2.982-73.657 82.489-73.657 82.489a75.736 75.736 0 0 0-19.139 50.335v88.174c0 5.974 4.842 10.823 10.823 10.823 5.975 0 10.825-4.849 10.825-10.823v-28.352l68.52-66.497c1.831-2.059 2.629-4.632 2.629-7.189h.102v-108.23h.006v-.001z"
    />
    <path
      style={{
        fill: "#507c5c",
      }}
      d="M95.261 448.488c-13.641 0-24.738-11.097-24.738-24.737v-88.174a89.57 89.57 0 0 1 22.582-59.497 7499.21 7499.21 0 0 1 35.894-41.293c10.556-12.041 19.081-21.648 25.335-28.553 13.105-14.463 15.831-17.473 22.838-17.473 7.684 0 13.979 6.306 13.979 13.992l.104 10.591c.003.175.001.351-.004.526v107.841c0 .845-.075 1.673-.22 2.476-.504 5.221-2.599 10.108-6.027 13.96-.227.256-.462.502-.708.739l-64.295 62.399v22.466c-.001 13.64-11.099 24.737-24.74 24.737zm68.158-210.666c-14.849 16.753-34.226 39.011-49.303 56.504-.047.054-.093.109-.142.161a61.774 61.774 0 0 0-15.624 41.087v47.935l65.027-63.108c.011-.125.025-.25.039-.374v-82.205h.003z"
    />
    <path
      style={{
        fill: "#507c5c",
      }}
      d="M308.437 396.354H203.562c-22.131 0-40.137-16.67-40.137-37.163V139.057c0-10.571 3.052-20.994 8.828-30.142l61.046-96.738C238.102 4.557 246.586.003 255.99 0h.008c9.404 0 17.889 4.552 22.701 12.177l61.04 96.734c5.775 9.134 8.832 19.561 8.832 30.139 0 7.684-6.231 13.915-13.915 13.915s-13.915-6.231-13.915-13.915c0-5.306-1.567-10.588-4.531-15.276l-60.212-95.421-60.212 95.415c-2.965 4.696-4.532 9.983-4.532 15.287v220.133c0 5.06 5.636 9.333 12.307 9.333h104.875c6.67 0 12.305-4.273 12.305-9.333V203.122c0-7.684 6.231-13.915 13.915-13.915 7.684 0 13.915 6.231 13.915 13.915V359.19c.001 20.492-18.002 37.164-40.134 37.164z"
    />
    <path
      style={{
        fill: "#cff09e",
      }}
      d="M299.525 436.717c0 24.038-43.525 61.366-43.525 61.366s-43.525-37.328-43.525-61.366c0-24.039 19.487-43.525 43.525-43.525s43.525 19.487 43.525 43.525z"
    />
    <path
      style={{
        fill: "#507c5c",
      }}
      d="M256 512a13.885 13.885 0 0 1-9.059-3.352c-8.081-6.928-48.383-42.962-48.383-71.929 0-31.674 25.768-57.441 57.441-57.441s57.441 25.768 57.441 57.441c0 28.967-40.303 65.001-48.383 71.929A13.874 13.874 0 0 1 256 512zm0-104.893c-16.327 0-29.61 13.283-29.61 29.61 0 9.429 15.194 28.292 29.61 42.445 14.416-14.153 29.61-33.017 29.61-42.445 0-16.326-13.283-29.61-29.61-29.61zM256 221.213c-26.855 0-48.703-21.848-48.703-48.703s21.848-48.703 48.703-48.703c7.684 0 13.915 6.231 13.915 13.915s-6.231 13.915-13.915 13.915c-11.509 0-20.873 9.364-20.873 20.873s9.364 20.873 20.873 20.873 20.873-9.364 20.873-20.873c0-7.684 6.231-13.915 13.915-13.915 7.684 0 13.915 6.231 13.915 13.915 0 26.854-21.848 48.703-48.703 48.703z"
    />
    <path
      style={{
        fill: "#cff09e",
      }}
      d="M177.342 303.776v55.414c0 12.842 11.739 23.248 26.22 23.248h104.875c14.482 0 26.22-10.404 26.22-23.248v-55.414H177.342z"
    />
    <path
      style={{
        fill: "#507c5c",
      }}
      d="M308.437 396.354H203.562c-22.131 0-40.137-16.67-40.137-37.163v-55.413c0-7.684 6.231-13.915 13.915-13.915h157.318c7.684 0 13.915 6.231 13.915 13.915v55.413c.001 20.491-18.004 37.163-40.136 37.163zm-117.182-78.662v41.498c0 5.06 5.636 9.333 12.307 9.333h104.875c6.671 0 12.307-4.273 12.307-9.333v-41.498H191.255z"
    />
  </svg>
)

export default RocketComponent
