// importa el repositorio que se encuentra el carpeta de domain
// y hereda de la clase OnboardingReviewRepository para crear el RemoteOnboardingReviewRepository

import OnboardingReviewRepository from '../domain/onboardingReviewRepository';
import httpService from 'Services/httpService';

export default class RemoteOnboardingReviewRepository extends OnboardingReviewRepository {
  get() {
    return httpService.get('/onboarding/send-to-validate');
  }
}