import React, { Component } from 'react'
import { Box, Columns, Column, Control, Field, Button } from 'Components/common/bulma'
import { Input, Select, Output } from 'Components/common/form'
import Autosuggest from 'react-autosuggest'
import { conceptData } from 'Containers/paymentOrders/providers/data'
import { getDataInput, mapPropsToState, validateData, getValues } from 'App/helpers'
import { multiply, find, propEq } from 'ramda'


const renderSuggestion = suggestion => (
  <div>
    {suggestion.nombre}
  </div>
)

class Concept extends Component {
  state = {
    value: '',
    data: conceptData,
    showErrors: false,
  }
  componentDidMount() {
    this.props.getUnits()
  }
  getSuggestionValue = suggestion => {
    this.onChangeInput({
      target: {
        name: 'unidad',
        value: suggestion.id,
      }
    })
    return suggestion.nombre;
  }

  onChangeInput = ({ target }) => {
    const { name } = target
    const data = getDataInput(target, this.state.data)
    const field = data[name]

    this.setState({
      data,
      showErrors: false,
      showAccountErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }

  onChangeUnit = (event, { newValue, method }) => {

    this.setState({
      value: newValue
    })
    if (method === 'type') {
      this.onChangeInput({
        target: {
          name: 'unidad',
          value: '',
        }
      })
    }
  }
  onSuggestionsFetchRequested = ({ value }) => {
    this.props.getUnits(value)
  }
  onSuggestionsClearRequested = () => {
    this.props.clearUnits()
  }
  onSuggestionSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) {
   event.stopPropagation();
   event.preventDefault();
  }
  getSubtotal = () => {
    const { cantidad: { value: qty }, valorUnitario: { value: price } } = this.state.data
    return multiply(+qty, +price)
  }
  getIepsCalculado = (subtotal) => {
    const { tasaIeps: { value: iepsId } } = this.state.data
    const { iepsRates } = this.props
    const ieps = find(propEq('id', iepsId))(iepsRates)

    if (!ieps) {
      return 0
    }
    return subtotal * ieps.IEPS
  }
  getIvaCalculado = (subtotal, iepsC) => {
    const { tasaIva: { value: ivaId }, tipoCalculo: { value: calculo } } = this.state.data
    const { ivaRates } = this.props
    const iva = find(propEq('id', ivaId))(ivaRates)

    const amount = calculo === 1 ? subtotal : (subtotal + iepsC)

    if (!iva) {
      return 0
    }

    return multiply(amount, +iva.IVA)
  }
  getTotalCalculado = (subtotal, iepsC, ivaC) => {

    return subtotal + iepsC + ivaC
  }
  updateCalculations = () => {
    const subtotalCalculado = this.getSubtotal()
    const iepsCalculado = this.getIepsCalculado(subtotalCalculado)
    const ivaCalculado = this.getIvaCalculado(subtotalCalculado, iepsCalculado)
    const totalCalculado = this.getTotalCalculado(subtotalCalculado,
      iepsCalculado,
      ivaCalculado,
    )
    const data = mapPropsToState(this.state.data, {
      subtotalCalculado,
      iepsCalculado,
      ivaCalculado,
      totalCalculado,
    })
    this.setState({ data })
  }

  addConcept = () => {
    const { data, hasErrors } = validateData(this.state.data)
    if (hasErrors) {
      this.setState({
        data,
        showErrors: true,
      })
      this.props.addNotification({
        type: 'danger',
        message: '¡Favor de validar algunos campos!'
      })
      return
    }
    this.props.addConcept(getValues(this.state.data))
  }
  render() {
    const { data, showErrors } = this.state
    const { units, ivaRates, iepsRates, closeConceptModal } = this.props
    return (
      <Box>
        <Columns>
          <Column className="is-one-quarter">
            <Input
              {...data.cantidad}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column>
            <Input
              {...data.descripcion}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Field>
              <label className="label">{data.unidad.label}</label>
              <Control>
                <Autosuggest
                  suggestions={units}
                  inputProps={{
                    value: this.state.value,
                    onChange: this.onChangeUnit,
                    className: 'input',
                    placeholder: data.unidad.placeholder,
                  }}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  getSuggestionValue={this.getSuggestionValue}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  renderSuggestion={renderSuggestion}
                  onSuggestionSelected={this.onSuggestionSelected}
                />
              </Control>
              {(data.unidad.errors && !!data.unidad.errors.length && this.state.showErrors) &&
                <p className="has-text-danger help">{data.unidad.errors[0].message}</p>}
            </Field>
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Input
              {...data.valorUnitario}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column>
            <Output
              {...data.subtotalCalculado}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
        </Columns>
        <Columns>
        </Columns>
        <Columns>
          <Column>
            <Select
              options={iepsRates}
              {...data.tasaIeps}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column>
            <Output
              {...data.iepsCalculado}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Select
              options={ivaRates}
              {...data.tasaIva}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column>
            <Output
              {...data.ivaCalculado}
              onChange={this.onChangeInput}
            />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Output
              {...data.totalCalculado}
              onChange={this.onChangeInput}
            />
          </Column>
          <Column>
            <Select
              hideEmpty={true}
              {...data.tipoCalculo}
              onChange={this.onChangeInput}
            />
          </Column>
        </Columns>
        <Columns>
          <Column className="has-text-right">
            <Button default onClick={this.addConcept}>Agregar</Button>
            <Button default className="margin-left-sm" onClick={closeConceptModal}>
              Cancelar
                </Button>
          </Column>
        </Columns>
      </Box>
    )

  }
}

export default Concept