import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { isAllow } from 'Modules/catalogs/walletExpense'
import catalogoProveedorImg from '../../styles/images/Catalogo-Proveedores.png';
import catalogoColaboradorImg from '../../styles/images/Catalogo-Colaboradores.png';
import catalogoMonederoImg from '../../styles/images/Catalogo-Monedero.png';
import catalogoGasolinaImg from '../../styles/images/Catalogo-Gasolina.png';
import catalogoClientesImg from '../../styles/images/Catalogo-Clientes.png';
import catalogoPrestamistasImg from '../../styles/images/Catalogo-Prestamistas.png';
import catalogoProductosServiciosImg from '../../styles/images/Catalogo-Productos.png';

export class Catalogs extends Component {
  componentDidMount() {
    this.props.isAllow()
  }

  render() {
    const { walletExpenseAllow } = this.props
    return (
      <section className="info-tiles multiline">
        <div className="tile is-ancestor has-text-centered">
          <div className="tile is-parent">
            <Link className="tile is-child box" to="/catalogos/proveedores">
              <p className="title has-text-grey-dark">
                <img className="icons" src={catalogoProveedorImg} alt="catalogo de proveedores" />
              </p>
              <p className="subtitle">Proveedores</p>
            </Link>
          </div>
          <div className="tile is-parent">
            <Link className="tile is-child box cat-prov" to="/catalogos/colaboradores">
              <p className="title has-text-grey-dark">
                <img className="icons" src={catalogoColaboradorImg} alt="catalogo de proveedores" />
              </p>
              <p className="subtitle">Colaboradores</p>
            </Link>
          </div>
          {walletExpenseAllow &&  (<div className="tile is-parent">
            <Link className="tile is-child box" to="/catalogos/monederos-gastos">
              <p className="title has-text-grey-dark">
                <img className="icons" src={catalogoMonederoImg} alt="catalogo de proveedores" />
              </p>
              <p className="subtitle">Monederos de Gastos</p>
            </Link>
          </div>)}
          <div className="tile is-parent">
            <Link className="tile is-child box" to="/catalogos/monederos-gasolina">
              <p className="title has-text-grey-dark">
                <img className="icons" src={catalogoGasolinaImg} alt="catalogo de proveedores" />
              </p>
              <p className="subtitle">Monederos de Gasolina</p>
            </Link>
          </div>
          </div>
        <div className="tile is-ancestor has-text-centered">
          <div className="tile is-parent">
            <Link className="tile is-child box" to="/catalogos/clientes">
              <p className="title has-text-grey-dark">
                <img className="icons" src={catalogoClientesImg} alt="catalogo de proveedores" />
              </p>
              <p className="subtitle">Clientes</p>
            </Link>
          </div>
          <div className="tile is-parent">
            <Link className="tile is-child box" to="/catalogos/prestamistas">
              <p className="title has-text-grey-dark">
                <img className="icons" src={catalogoPrestamistasImg} alt="catalogo de proveedores" />
              </p>
              <p className="subtitle">Prestamistas</p>
            </Link>
          </div>
          <div className="tile is-parent">
            <Link className="tile is-child box" to="/catalogos/productos-servicios">
              <p className="title has-text-grey-dark">
                <img className="icons" src={catalogoProductosServiciosImg} alt="catalogo de proveedores" />
              </p>
              <p className="subtitle">Productos y servicios</p>
            </Link>
          </div>
          <div className="tile is-parent">
          </div>
        </div>
      </section>
    )
  }
}

const mapStateToProps = ({catalogs}) => ({
  walletExpenseAllow: catalogs.walletExpense.isAllow

})

const mapDispatchToProps = dispatch => bindActionCreators({
  isAllow,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Catalogs)
