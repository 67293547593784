import React from 'react'
import { Output } from 'Components/common/form'
import { Columns, Column, Box } from 'Components/common/bulma'



const General = ({data}) => {
    return (
        <Box>
            <Columns>
                <Column>
                    <Output
                        {...data.id}
                    />
                </Column>
                <Column>
                    <Output
                        {...data.estatus}
                    />
                </Column>
            </Columns>
            <Columns>
                <Column>
                    <Output
                        {...data.serieTitulo}
                    />
                </Column>

                <Column>
                    <Output
                        {...data.numeroTitulo}
                    />

                </Column>
            </Columns>
            <Columns>

                <Column>
                    <Output
                        {...data.tipoPersona}
                    />
                </Column>
                <Column>
                    <Output
                        {...data.nombreRazonSocial}
                    />
                </Column>
            </Columns>
            <Columns>
                <Column>
                    <Output
                        {...data.rfc}
                    />
                </Column>
                <Column>
                    <Output
                        {...data.paginaWeb}
                    />
                </Column>
            </Columns>
            <Columns>
                <Column>
                    <Output
                        {...data.cp}
                    />
                </Column>
                <Column>
                    <Output
                        {...data.pais}
                    />
                </Column>
            </Columns>
            <Columns>
                <Column>
                    <Output
                        {...data.entidadFederativa}
                    />
                </Column>
                <Column>
                    <Output
                        {...data.municipio}
                    />
                </Column>
            </Columns>
            <Columns>
                <Column>
                    <Output
                        {...data.colonia}
                    />
                </Column>
                <Column>
                    <Output
                        {...data.calle}
                    />
                </Column>
            </Columns>
            <Columns>
                <Column>
                    <Output
                        {...data.numeroExterior}
                    />


                </Column>
                <Column>

                    <Output
                        {...data.numeroInterior}
                    />
                </Column>
            </Columns>
            <Columns>
                <Column>
                    <Output
                        {...data.telefono}
                    />
                </Column>
                <Column>
                    <Output
                        {...data.extension}
                    />
                </Column>
            </Columns>
        </Box>
    );
};

export default General