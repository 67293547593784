import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMount } from 'ahooks'
import { getQr } from 'Modules/auth'

function TotpQr() {
  const { token } = useParams()
  const [qr, setQr] = useState('')
  const [error, setError] = useState(false)

  useMount(() => {
    getQr(token)
    .then(({data}) => {
      setQr(data)
    })
    .catch(() => {
      setError(true)
    })
  })

  return (
    <div style={{textAlign: 'center'}}>
      {error || <p>Escanea este código en tu app Google Authenticator o Authy</p>}
      {error && <p>El qr de activación ha expirado favor de reenviar el email</p>}
      {error || <img alt="qr para activar totp" src={qr}></img>}
    </div>
  )
}

export default TotpQr
