import React, { useState } from 'react'
import { Icon, Message } from 'Components/common/bulma'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'

function Collapse({
  title = '',
  children,
}) {
  const [collapse, toggleCollapse] = useState(false)

  const handleToggle = () => {
    toggleCollapse(!collapse)
  }

  return (
    <Message>
      <Message.Header
        style={{ cursor: 'pointer' }}
        onClick={handleToggle}>
        {title}
        <Icon>
          {!!collapse || <FontAwesomeIcon icon={faCaretDown} />}
          {collapse && <FontAwesomeIcon icon={faCaretUp} />}
        </Icon>
      </Message.Header>
      {collapse && <Message.Body>
        {children}
      </Message.Body>
      }
    </Message>
  )
}

export default Collapse
