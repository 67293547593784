import React, { useState, useEffect } from 'react'
import { Button, Field } from 'Components/common/bulma'
import { Input } from 'Components/common/form'
import { ModalRemove, InformationModal } from 'Components/common'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createCreditAndRefundAction } from 'Modules/incomeOrders/creditNote'
import numeral from 'numeral'
import { validator } from 'App/helpers'
import { addNotification as addNotificationAction } from 'Modules/principal';

function CancelOrder({
  createCreditAndRefund,
  order,
  options,
  addNotification,
  onClose,
}) {
  const [limit, setLimit] = useState(0)
  const [clabe, setClabe] = useState('')
  const [clabeErrors, setClabeErrors] = useState([])
  const [isConfirming, setIsConfirming] = useState(false)
  const [isCanceled, setIsCanceled] = useState(false)
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    const collectedAmount = options.Generar_Nota_de_Credito_y_Devolver_Fondos_al_Cliente_monto_cobrado_total.Monto_Cobrado_Efectivo
    setLimit(collectedAmount)
  }, [options])

  const confirmCancel = () => {
    const validatedClabe = validator(clabe, 'isLength:18')

    if (!validatedClabe.valid) {
      setClabeErrors(validatedClabe.errors)
      setShowError(true)
      return
    }
    setIsConfirming(true)
  }

  const closeConfirm = () => {
    setIsConfirming(false)
  }

  const handleOnCancel = () => {
    setIsConfirming(false)
    createCreditAndRefund({ clabe, monto: +limit }).then(() => {
      setIsCanceled(true)
    }).catch(({ response: { data } }) => {
      addNotification({
        type: 'danger',
        message: data.message,
      })
    })
  }

  const closeCanceledModal = () => {
    setIsCanceled(false)
    onClose(true)
  }

  const handleOnChange = ({ target: { value } }) => {
    const validatedClabe = validator(value, 'number')
    setShowError(false)
    setClabeErrors([])
    if (validatedClabe.valid) {
      setClabe(value)
    }
  }
  return (
    <>
      <InformationModal
        isOpen={isCanceled}
        closeModal={closeCanceledModal}
        title="Nota de crédito emitida"
        content="Petición recibida, en las próximas horas se emitirá la nota de crédito."
      />
      <ModalRemove
        isDeleting={isConfirming}
        title="Emitir nota de crédito"
        toggleModal={closeConfirm}
        confirmText="Se procederá a crear la nota de crédito"
        resource={order.id}
        deleteResource={handleOnCancel}
        actionButtonLabel="Emitir"
        cancelButtonLabel="Regresar"
      />
      <div>
        <div>Se emitirá una Nota de Crédito por el monto total de la factura y se devolverán los fondos cobrados al cliente por transferencia electrónica, se deberá capturar la cuenta clabe del cliente para su retorno. Monto a pagar al cliente:
          {numeral(limit).format('$ 0,0.00')}
        </div>
        <div>
          <Field className="is-horizontal">
            <Input
              label="Cuenta Clabe para retorno al cliente"
              value={clabe}
              onChange={handleOnChange}
              errors={clabeErrors}
              maxLength={18}
              showErrors={showError}
            />
          </Field>
          <Field>
            <Button onClick={confirmCancel}>
              Generar nota de crédito
            </Button>
          </Field>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ incomeOrders: { invoice } }) => ({
  order: invoice.order,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  addNotification: addNotificationAction,
  createCreditAndRefund: createCreditAndRefundAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CancelOrder)
