import moment from 'moment'

const limitHour = moment({ hour: 16, minute: 5 })
const minDate = moment() < limitHour ? moment({ hour: 0 }) : moment({ hour: 0 }).add(1, 'day')

export const paymentAmortizationCols =
  [
    {
      title: 'ID',
      datakey: 'id',
      sortable: true,
      width: '10%',
    },
    {
      title: 'Estatus Orden',
      datakey: 'estatus',
      sortable: true,
      width: '10%',
    },
    {
      title: 'RFC',
      datakey: 'RFC',
      sortable: true,
      width: '15%',
    },
    {
      title: 'Nombre o Razón Social',
      datakey: 'nombreORazonSocial',
      sortable: true,
      width: '40%',
    },
    {
      title: 'Monto',
      datakey: 'monto',
      format: '$ 0,0.00',
      sortable: true,
      width: '15%',
    },
    {
      title: 'Acciones',
      custom: 'actions',
      width: '10%',
    }
  ];

export const addressData = {
  direccion: {
    value: '',
    transform: 'stringToBool',
    trigger: 'onChangeDireccion',
    name: 'direccion',
    label: 'Dirección',
    disabled: true,
  },
  pais: {
    name: 'pais',
    value: '',
    validation: 'requiredIf:direccion',
    label: 'País',
    disabled: true
  },
  codigoPostal: {
    value: '',
    trigger: 'onChangeCp',
    pattern: 'number',
    name: 'codigoPostal',
    label: 'Código postal',
    disabled: true,
  },
  entidadFederativa: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'entidadFederativa',
    label: 'Entidad Federativa/Estado/Provincia',
    disabled: true,
  },
  municipio: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'municipio',
    label: 'Municipio',
    disabled: true,
  },
  colonia: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'colonia',
    label: 'Colonia',
    disabled: true,
  },
  calle: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'calle',
    label: 'Calle',
    disabled: true,
  },
  exterior: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'exterior',
    label: 'Exterior',
    disabled: true,
  },
  interior: {
    value: '',
    name: 'interior',
    label: 'Interior',
    disabled: true,
  },
}

export const amortization = {
  id: {
    name: 'id',
    value: '',
  },
  estatusOrden: {
    label: 'Estatus Orden',
    name: 'estatus',
    value: '1',
    disabled: true,
    valueProperty: 'id',
    valueLabel: 'estatus',
  },
  prestamista: {
    label: 'Prestamista',
    name: 'prestamista',
    value: '',
    valueProperty: 'id',
    valueLabel: 'nombreORazonSocial',
    trigger: 'onChangeLender',
    validation: 'required',
    disabled: true,
  },
  tipoDePersona: {
    label: 'Tipo de persona',
    name: 'tipoDePersona',
    value: '',
    disabled: true,
    valueProperty: 'id',
    valueLabel: 'tipoPersona',
  },
  nombreORazonSocial: {
    name: 'nombreORazonSocial',
    label: 'Nombre',
    value: '',
    disabled: true,
  },
  rfc: {
    name: 'rfc',
    label: 'RFC',
    value: '',
    disabled: true,
  },
  correoElectronico: {
    name: 'correoElectronico',
    label: 'Correo Electrónico',
    value: '',
    disabled: true,
  },
  cuentaParaAbonoORetiro: {
    name: 'cuentaParaAbonoORetiro',
    label: 'Cuenta para Abono o Retiro',
    options: [
      { value: 'MXN', label: 'MXN' },
      { value: 'USD', label: 'USD' },
    ],
    value: 'MXN',
    validation: 'required',
  },
  monto: {
    name: 'monto',
    label: 'Monto del préstamo',
    value: '',
    pattern: 'number',
    validation: 'required',
  },
  mutuo: {
    name: 'mutuo',
    label: 'Mutuo',
    value: '',
    selectLabel: 'Adjuntar',
  },
  montoDepositar: {
    name: 'montoDepositar',
    label: 'Monto por Depositar',
    value: '',
  },
  montoDepositarTransito: {
    label: 'Monto por depositar en Tránsito',
    name: 'montoDepositarTransito',
    value: '',
  },
  montoDepositado: {
    label: 'Monto Depositado',
    name: 'montoDepositado',
    value: '',
  },
  cuentaClabeSTPAsignada: {
    label: 'Cuenta Clabe STP Asignada',
    name: 'cuentaClabeSTPAsignada',
    value: '',
  },
  claveDePagoReferenciado: {
    name: 'claveDePagoReferenciado',
    label: 'Convenio CIE Bancomer Número 1372726',
    value: ''
  },
  montoAmortizar: {
    name: 'montoAmortizar',
    label: 'Monto por Amortizar',
    value: '',
    disabled: true,
  },
  pagoRelacionadoMutuo: {
    name: 'pagoRelacionadoMutuo',
    value: '',
  },
  metodoPago: {
    name: 'metodoPago',
    label: 'Método de Pago',
    value: '',
    validation: 'required',
    valueProperty: 'id',
    valueLabel: 'metodo',
    trigger: 'onChangeMetodoPago',
  },
  cuentaMXN: {
    name: 'cuentaMXN',
    label: 'Cuenta Beneficiario',
    value: '',
    validation: 'requiredIfNotCustom:cuentaMXN',
    depends: 'metodoPago',
    dependsValue: '2',
  },
  referencia: {
    name: 'referencia',
    label: 'Referencia',
    value: moment().format('DDMMYY'),
    validation: 'required',
    maxLength: 7,
    pattern: 'number'
  },
  fechaProgramacionPago: {
    name: 'fechaProgramacionPago',
    label: 'Fecha de Programación de Pago',
    validation: `required|gtOrEqDate:${minDate.format('YYYY-MM-DD')}`,
    dataMarshaller: val => moment(val).format('YYYY-MM-DD'),
    value: minDate.toDate(),
    minDate: minDate.toDate(),
  },
}

export const paymentCols = [
  {
    title: 'Id',
    datakey: 'id'
  },
  {
    title: 'Fecha Transacción',
    datakey: 'fechaTransaccion'
  },
  {
    title: 'Estatus',
    datakey: 'estatusTexto'
  },
  {
    title: 'Cargos',
    datakey: 'cargos'
  },
  {
    title: 'Clave de rastreo',
    datakey: 'claveRastreo'
  },
  {
    title: 'Referencia Banco',
    datakey: 'referenciaBanco'
  },
]