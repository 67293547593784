import React, { useState } from 'react'
import { Button } from 'Components/common/bulma'
import { ModalRemove, InformationModal } from 'Components/common'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { cancelInvoiceOrderGlobalInvoiceAction } from 'Modules/incomeOrders/creditNote'
import { addNotification as addNotificationAction } from 'Modules/principal';

function CancelOrder({
  cancelInvoiceOrderGlobalInvoice,
  order,
  addNotification,
  onClose,
}) {
  const [isConfirming, setIsConfirming] = useState(false)
  const [isCanceled, setIsCanceled] = useState(false)

  const confirmCancel = () => {
    setIsConfirming(true)
  }

  const closeConfirm = () => {
    setIsConfirming(false)
  }

  const handleOnCancel = () => {
    setIsConfirming(false)
    cancelInvoiceOrderGlobalInvoice().then(() => {
      setIsCanceled(true)
    }).catch(({ response: { data } }) => {
      addNotification({
        type: 'danger',
        message: data.message,
      })
    })
  }

  const closeCanceledModal = () => {
    setIsCanceled(false)
    onClose(true)
  }

  return (
    <>
      <InformationModal
        isOpen={isCanceled}
        closeModal={closeCanceledModal}
        title="Orden cancelada"
        content="Petición recibida, en las próximas horas se cancelará tanto la factura como la orden."
      />
      <ModalRemove
        isDeleting={isConfirming}
        title="Cancelar Orden"
        toggleModal={closeConfirm}
        confirmText="¿Esta seguro de querer cancelar la Orden?"
        resource={order.id}
        deleteResource={handleOnCancel}
        actionButtonLabel="Cancelar"
        cancelButtonLabel="Regresar"
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        Se cancelará la factura y la orden de Ingreso, después deberás de emitir dos nuevas ordenes de ingreso la primera para generar la venta nominativa, la segunda para generar la venta global.
        <Button onClick={confirmCancel}>
          Cancelar
        </Button>
      </div>
    </>
  )
}

const mapStateToProps = ({ incomeOrders: { invoice } }) => ({
  order: invoice.order,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelInvoiceOrderGlobalInvoice: cancelInvoiceOrderGlobalInvoiceAction,
  addNotification: addNotificationAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CancelOrder)
