import React, { useState } from 'react';
import { Field, Control, Icon, Button, File } from 'Components/common/bulma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTimes, faPaperclip, faSpinner, faCheck } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useDependencies } from 'App/DependencyContext';

const FileWithErrors = ({
  label,
  input,
  onChange,
  error,
  showErrors,
  id,
}) => {
  const [randKey, setRandKey] = useState(Date.now());
  const [file, setFile] = useState(null);
  const [isUploading, setIsLoading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);

  const { onboardingFileRepository } = useDependencies();

  const onRemoveFile = () => {
    setFile(null);
    setRandKey(Date.now());
    setIsUploaded(false)
  };

  const onChangeFile = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  }

  const onUpload = async () => {
    if (file) {
      setIsLoading(true)
      const uploadedFile = await onboardingFileRepository.upload({ content: file, name: file.name });
      setFile(null);
      setIsLoading(false)
      setIsUploaded(true)
      onChange({ target: { value: uploadedFile.name, name: input.name, id: input.id } });
    }
  }

  return (
    <Field>
      <label className="label">{label}</label>
      <Control>
        <File name right>
          <File.Label>
            <File.Input
              key={randKey}
              name={input.name}
              accept={input.accept}
              onChange={onChangeFile}
            />
            <File.Cta>
              <File.Icon>
                <FontAwesomeIcon icon={faPaperclip} />
              </File.Icon>
              <File.Label as='span'>
              </File.Label>
            </File.Cta>
            <File.Name>
              {file?.name || input.value}
            </File.Name>
          </File.Label>
          <Button type="button" onClick={onUpload} disabled={!file || isUploading}>
            <Icon>
              {isUploaded && <FontAwesomeIcon icon={faCheck} className="has-text-success" />}
              {isUploading && <FontAwesomeIcon className="fa-spin" icon={faSpinner} />}
              {(!isUploading && !isUploaded) && <FontAwesomeIcon icon={faUpload} />}
            </Icon>
          </Button>
          <Button type="button" onClick={onRemoveFile} disabled={!file}>
            <Icon>
              <FontAwesomeIcon icon={faTimes} />
            </Icon>
          </Button>
        </File>
      </Control>
      {(error && showErrors) && <p className="has-text-danger help">{error}</p>}
    </Field>
  );
};

FileWithErrors.propTypes = {
  label: PropTypes.string,
  isUploading: PropTypes.bool,
};

export default FileWithErrors;
