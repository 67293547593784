import {
  getTokenDecoded, refreshTokenFetch, refreshTokenSuccess,
  REFRESHING_TOKEN, LOGOUT
} from 'Modules/auth'

let postponedActions = [];
export default store => next => action => {
  const token = getTokenDecoded()
  const state = store.getState()
  const isRefreshing = state.auth.isRefreshingToken
  const tokenIsExpired = token.exp < ((Date.now() / 1000) + 1800)
  const actionIsAsync = typeof action === 'function'

  if (state.auth.authenticated && token && token.exp < (Date.now() / 1000) && action.type !== LOGOUT) {
    store.dispatch({ type: LOGOUT })
    return next(action)
  } else if (actionIsAsync && token && !isRefreshing && tokenIsExpired && state.auth.authenticated) {
    postponedActions.push(action)
    store.dispatch({ type: REFRESHING_TOKEN, payload: true })
    refreshTokenFetch().then(({ data }) => {
      store.dispatch(refreshTokenSuccess(data))
      postponedActions.map(it => next(it))
      postponedActions = []
    })
      .catch(() => {
        postponedActions = []
        store.dispatch({ type: REFRESHING_TOKEN, payload: false })
        store.dispatch({ type: LOGOUT })
      })
    return next(action)
  } else if (actionIsAsync && token && isRefreshing) {
    postponedActions.push(action)
  } else {
    return next(action)
  }
}