import numeral from 'numeral'
export const columns = {
    movesMXN: {
        width: '150%', columns: [
            {
                title: 'Cuenta',
                datakey: 'cuenta',
                width: '10%',
            },
            {
                title: 'Fecha',
                datakey: 'fecha',
                width: '10%',
            },
            {
                title: 'Concepto',
                datakey: 'concepto',
                width: '10%',
            },
            {
                title: 'Referencia Transacción',
                datakey: 'referenciaTransaccion',
                width: '10%',
            },
            {
                title: 'Abono Real',
                datakey: 'abonoReal',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '10%',
            },
            {
                title: 'Cargo Real',
                datakey: 'cargoReal',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '10%',
            },
            {
                title: 'Saldo Real',
                datakey: 'saldoReal',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '10%',
            },

        ],
    },
    movesUSD: {
        width: '200%', columns: [
            {
                title: 'Cuenta',
                datakey: 'cuenta',
                width: '10%',
            },
            {
                title: 'Fecha',
                datakey: 'fecha',
                width: '10%',
            },
            {
                title: 'Concepto',
                datakey: 'concepto',
                width: '10%',
            },
            {
                title: 'Referencia Transacción',
                datakey: 'referenciaTransaccion',
                width: '10%',
            },
            {
                title: 'Abono Real',
                datakey: 'abonoReal',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '10%',
            },
            {
                title: 'Cargo Real',
                datakey: 'cargoReal',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '10%',
            },
            {
                title: 'Saldo Real',
                datakey: 'saldoReal',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '10%',
            },
        ],
    },
    incomeOrder: {
        width: '150%', columns: [
            {
                title: 'Id',
                datakey: 'id',
                width: '4%',
            },
            {
                title: 'FechaHoraCaptura',
                datakey: 'fechaHoraCaptura',
                width: '6%',
            },
            {
                title: 'Estatus Orden',
                datakey: 'estatusOrden',
                width: '5%',
            },
            {
                title: 'Tipo de Ingreso',
                datakey: 'tipoIngreso',
                width: '5%',
            },
            {
                title: 'Nombre o Razón Social',
                datakey: 'nombrRazonSocial',
                width: '11%',
            },
            {
                title: 'RFC',
                datakey: 'RFC',
                width: '7%',
            },
            {
                title: 'Cuenta Clabe Asignada',
                datakey: 'cuentaClabeSTPAsignada',
                width: '8%',
            },
            {
                title: 'Clave de Pago Referenciado',
                datakey: 'clavePagoReferenciado',
                width: '5%',
            },
            {
                title: 'Divisa Monera',
                datakey: 'divisaMoneda',
                width: '5%',
            },
            {
                title: 'Subtotal',
                datakey: 'subtotal',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'IVA',
                datakey: 'IVA',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'IEPS',
                datakey: 'IEPS',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'Total',
                datakey: 'total',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'Monto Cobrado',
                datakey: 'montoCobrado',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'Monto por Cobrar',
                datakey: 'montoPorCobrar',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'Numero de Factura',
                datakey: 'numeroFactura',
                width: '4%',
            },
            {
                title: 'UUID',
                datakey: 'UUID',
                width: '10%',
            },
        ],
    },
    paymentOrders: {
        width: '200%', columns: [
            {
                title: 'Id',
                datakey: 'id',
                width: '4%',
            },
            {
                title: 'FechaHoraCaptura',
                datakey: 'fechaHoraCaptura',
                width: '5%',
            },
            {
                title: 'Estatus Orden',
                datakey: 'estatusOrden',
                width: '4%',
            },
            {
                title: 'Tipo de Pago',
                datakey: 'tipoPago',
                width: '4%',
            },
            {
                title: 'Nombre o Razón Social',
                datakey: 'nombrRazonSocial',
                width: '9%',
            },
            {
                title: 'RFC',
                datakey: 'RFC',
                width: '6%',
            },
            {
                title: 'Método de Pago',
                datakey: 'metodoPago',
                width: '5%',
            },
            {
                title: 'Divisa Moneda',
                datakey: 'divisaMoneda',
                width: '4%',
            },
            {
                title: 'Cuenta de Retiro',
                datakey: 'cuentaRetiro',
                width: '4%',
            },
            {
                title: 'Subtotal',
                datakey: 'subtotal',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '4%',
            },
            {
                title: 'IVA',
                datakey: 'IVA',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '4%',
            },
            {
                title: 'IEPS',
                datakey: 'IEPS',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '4%',
            },
            {
                title: 'Total',
                datakey: 'total',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '4%',
            },
            {
                title: 'ISR Retendio',
                datakey: 'ISRRetenido',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '4%',
            },
            {
                title: 'IVA Retenido',
                datakey: 'IVARetenido',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '4%',
            },
            {
                title: 'Neto a Depositar',
                datakey: 'netoADepositar',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '4%',
            },
            {
                title: 'Monto Pagado',
                datakey: 'montoPagado',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '4%',
            },
            {
                title: 'Monto Pago Tránsito',
                datakey: 'montoPagoTransito',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '4%',
            },
            {
                title: 'Monto Por Pagar',
                datakey: 'montoPorPagar',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '4%',
            },
            {
                title: 'Estatus Factura Proveedor',
                datakey: 'estatusFacturaProveedor',
                width: '4%',
            },
            {
                title: 'Número de Factura',
                datakey: 'numeroFactura',
                width: '4%',
            },
            {
                title: 'UUID',
                datakey: 'UUID',
                width: '7%',
            },
        ],
    },
    transfers: {
        width: '150%', columns: [
            {
                title: 'Id',
                datakey: 'id',
                width: '5%',
            },
            {
                title: 'Fecha de la Transacción',
                datakey: 'fecha',
                width: '10%',
            },
            {
                title: 'Estatus',
                datakey: 'estatus',
                width: '10%',
            },
            {
                title: 'Método de Pago',
                datakey: 'metodoPago',
                width: '10%',
            },
            {
                title: 'Monda Divisa',
                datakey: 'monedaDivisa',
                width: '5%',
            },
            {
                title: 'Cargos',
                datakey: 'cargos',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '10%',
            },
            {
                title: 'Tipo de Cambio',
                datakey: 'tipoCambio',
                width: '10%',
            },
            {
                title: 'Moneda Origen',
                datakey: 'monedaOrigen',
                width: '10%',
            },
            {
                title: 'Cargo Cuenta Destino',
                datakey: 'cargoCuentaDestino',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '10%',
            },
            {
                title: 'Referencia Bancaria',
                datakey: 'referenciaBanco',
                width: '10%',
            },
            {
                title: 'Clave de Rastreo',
                datakey: 'claveRastreo',
                width: '10%',
            },
        ],
    },
    loanOrders: {
        width: '150%', columns: [
            {
                title: 'Id',
                datakey: 'Id',
                width: '5%',
            },
            {
                title: 'FechaHoraCaptura',
                datakey: 'FechaHoraCaptura',
                width: '10%',
            },
            {
                title: 'Estatus Orden',
                datakey: 'estatusPago',
                width: '5%',
            },
            {
                title: 'Tipo de Préstamo',
                datakey: 'tipoPrestamo',
                width: '10%',
            },
            {
                title: 'Nombre o Razón Social',
                datakey: 'nombreRazonSocial',
                width: '10%',
            },
            {
                title: 'RFC',
                datakey: 'RFC',
                width: '10%',
            },
            {
                title: 'Divisa Moneda',
                datakey: 'divisaMoneda',
                width: '5%',
            },
            {
                title: 'Cuenta para Abono Tercero',
                datakey: 'cuentaParaAbonoTercero',
                width: '5%',
            },
            {
                title: 'Tasa Anual',
                datakey: 'tasaAnual',
                width: '5%',
            },
            {
                title: 'Plazo',
                datakey: 'plazo',
                width: '5%',
            },
            {
                title: 'Frecuencia Plazo',
                datakey: 'frecuenciaPlazo',
                width: '5%',
            },
            {
                title: 'MONTO',
                datakey: 'monto',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '10%',
            },
            {
                title: 'Monto Depositado',
                datakey: 'montoDepositado',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '10%',
            },
            {
                title: 'Monto por depositar',
                datakey: 'montoPorDepositar',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '10%',
            },
        ],
    },
    accountsReceivable: {
        width: '200%', columns: [
            {
                title: 'Id',
                datakey: 'id',
                width: '5%',
            },
            {
                title: 'HoraFechaCaptura',
                datakey: 'fechaHoraCaptura',
                width: '5%',
            },
            {
                title: 'Estatus Orden',
                datakey: 'estatusOrden',
                width: '5%',
            },
            {
                title: 'Tipo de Ingreso',
                datakey: 'tipoIngreso',
                width: '5%',
            },
            {
                title: 'Nombre o Razón Social',
                datakey: 'nombrRazonSocial',
                width: '10%',
            },
            {
                title: 'RFC',
                datakey: 'RFC',
                width: '5%',
            },
            {
                title: 'Cuenta Clabe Asignada',
                datakey: 'cuentaClabeSTPAsignada',
                width: '10%',
            },
            {
                title: 'Clave de Pago Referenciado',
                datakey: 'clavePagoReferenciado',
                width: '5%',
            },
            {
                title: 'Divisa Moneda',
                datakey: 'divisaMoneda',
                width: '5%',
            },
            {
                title: 'Subtotal',
                datakey: 'subtotal',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'IVA',
                datakey: 'IVA',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'IEPS',
                datakey: 'IEPS',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'Total',
                datakey: 'total',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'Monto Cobrado',
                datakey: 'montoCobrado',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'Monto por Cobrar',
                datakey: 'montoPorCobrar',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'Numero de Factura',
                datakey: 'numeroFactura',
                width: '5%',
            },
            {
                title: 'UUID',
                datakey: 'UUID',
                width: '5%',
            },
        ],
    },
    accountsPayable: {
        width: '200%', columns: [
            {
                title: 'Id',
                datakey: 'id',
                width: '3%',
            },
            {
                title: 'FechaHoraCaptura',
                datakey: 'fechaHoraCaptura',
                width: '5%',
            },
            {
                title: 'Estatus Orden',
                datakey: 'estatusOrden',
                width: '3%',
            },
            {
                title: 'Tipo de Pago',
                datakey: 'tipoPago',
                width: '5%',
            },
            {
                title: 'Nombre o Razón Social',
                datakey: 'nombrRazonSocial',
                width: '10%',
            },
            {
                title: 'RFC',
                datakey: 'RFC',
                width: '5%',
            },
            {
                title: 'Método de Pago',
                datakey: 'metodoPago',
                width: '5%',
            },
            {
                title: 'Divisa Moneda',
                datakey: 'divisaMoneda',
                width: '3%',
            },
            {
                title: 'Cuenta de Retiro',
                datakey: 'cuentaRetiro',
                width: '3%',
            },
            {
                title: 'Subtotal',
                datakey: 'subtotal',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'IVA',
                datakey: 'IVA',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'IEPS',
                datakey: 'IEPS',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'Total',
                datakey: 'total',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'ISR Retendio',
                datakey: 'ISRRetenido',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'IVA Retenido',
                datakey: 'IVARetenido',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'Neto a Depositar',
                datakey: 'netoADepositar',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'Monto Pagado',
                datakey: 'montoPagado',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'Monto Pago Tránsito',
                datakey: 'montoPagoTransito',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'Monto Por Pagar',
                datakey: 'montoPorPagar',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'Estatus Factura Proveedor',
                datakey: 'estatusFacturaProveedor',
                width: '5%',
            },
            {
                title: 'Número de Factura',
                datakey: 'numeroFactura',
                width: '5%',
            },
        ],
    },
    gasolineConciliationTOKA: {
        width: '150%', columns: [
            {
                title: 'TARJETA',
                datakey: 'tarjeta',
                width: '10%',
            },
            {
                title: 'FECHA MOVIMIENTO',
                datakey: 'fechaMovimiento',
                width: '5%',
            },
            {
                title: 'Nombre Gasolinera',
                datakey: 'nombreGasolinera',
                width: '5%',
            },
            {
                title: 'Id Gasolinera',
                datakey: 'nombreGasolinera',
                width: '5%',
            },
            {
                title: 'RFC',
                datakey: 'RFC',
                width: '5%',
            },
            {
                title: 'Ciudad',
                datakey: '"ciudad "',
                width: '5%',
            },
            {
                title: 'Autorización',
                datakey: 'autorizacion',
                width: '5%',
            },
            {
                title: 'Importe',
                datakey: 'importe',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'IVA',
                datakey: 'IVA',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'IEPS',
                datakey: 'IEPS',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'Consumo',
                datakey: 'consumo',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'TAX',
                datakey: 'tax',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'Precio Gas',
                datakey: 'precioGas',
                format: it => numeral(Number(it).toFixed(6)).format('$ 0,000.00'),
                width: '5%',
            },
            {
                title: 'Producto',
                datakey: 'producto',
                width: '5%',
            },
            {
                title: 'Litros',
                datakey: 'litros',
                width: '5%',
            },
            {
                title: 'FechaX',
                datakey: 'fechaX',
                width: '5%',
            },
            {
                title: 'Concepto',
                datakey: 'concepto',
                width: '5%',
            },
        ],
    },
}