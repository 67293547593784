import React from 'react'
import { Columns, Column } from 'Components/common/bulma'
import { InputWithError, SelectWithError } from 'Components/common/form'

const AddressSection = ({
  data,
  onChange,
  showErrors,
  countries,
  states,
}) => {
  return (
    <section>
      <Columns className="is-multiline">
        <Column className="is-half">
          <SelectWithError
            onChange={onChange}
            options={countries}
            showErrors={showErrors}
            {...data.country}
          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onChange}
            {...data.zipCode}
            maxLength="5"
            showErrors={showErrors}
          />
        </Column>
        <Column className="is-half">
          <InputWithError
            showErrors={showErrors}
            {...data.state}
          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onChange}
            showErrors={showErrors}
            {...data.municipality}
          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onChange}
            showErrors={showErrors}
            {...data.neighborhood}
          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onChange}
            showErrors={showErrors}
            {...data.street}
          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onChange}
            showErrors={showErrors}
            {...data.number}
          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onChange}
            showErrors={showErrors}
            {...data.interiorNumber}
          />
        </Column>
      </Columns>
    </section>
  )
}

export default AddressSection