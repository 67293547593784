import React, { Component, Fragment } from 'react'
import { Route, Switch, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getAccount, getLegalRepresentative, getPartnerProfile } from 'Modules/principal'
import { Tabs } from 'Components/common/bulma'
import Table from 'Components/common/table'
import { Button, Icon, Columns, Column } from 'Components/common/bulma'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import {
  General,
  Comision,
  Documentos,
  CuentasAsignadas,
  CuentasBancarias,
  DetalleCuenta,
  RepLegal,
  PersonaMoral,
} from 'Components/perfil'
import {
  general,
  cuentasAsignadas,
  comisiones,
  documentos,
  cuentasBancarias,
  detalleCuenta,
  personaMoral,
  legalRepresentative,
  legalRepresentativeCols,
} from './data'
import { mapPropsToState } from 'App/helpers'

const PERSON_TYPE = '2'
export class PartnerProfile extends Component {
  state = {
    curTab: '',
    showingAccount: false,
  }
  componentDidMount() {
    this.props.getPartnerProfile()
  }

  accountActions = account => {
    return (
      <Fragment >
        <Button primary outlined onClick={() => this.onSelectAccount(account)}>
          <Icon>
            <FontAwesomeIcon icon={faEdit} />
          </Icon>
        </Button>
      </Fragment>
    )
  }

  legalRepresentativeActions = legalRepresentative => {
    return (
      <Fragment >
        <Button primary outlined onClick={() => this.onSelectLegalRepresentative(legalRepresentative)}>
          <Icon>
            <FontAwesomeIcon icon={faEdit} />
          </Icon>
        </Button>
      </Fragment>
    )
  }

  onSelectLegalRepresentative = ({id}) => {
    this.props.getLegalRepresentative(id)
    this.setState({
      showingLegalRepresentative: true,
    })
  }
  onSelectAccount = ({ idCuentaBancaria }) => {
    this.props.getAccount(idCuentaBancaria)
    this.setState({
      showingAccount: true,
    })
  }

  closeAccountView = () => this.setState({
    showingAccount: false,
  })


  closeLegalRepresentative = () => this.setState({ showingLegalRepresentative: false })

  render() {
    const {
      generales,
      comisiones: dataComisiones,
      documentos: dataDocumentos,
      cuentasAsignadas: dataCuentasAsignadas,
      cuentasBancariasIntegrado,
      personalMoral: dataPersonaMoral,
      representanteLegal,
    } = this.props.partnerProfile

    return (
      <div>
        <Tabs style={{marginBottom: '10px'}}>
          <ul style={{marginBottom: '15px'}}>
            <li>
              <NavLink activeClassName="button is-text is-active" to="/integrado/generales">Generales</NavLink>
            </li>
            <li>
              <NavLink activeClassName="button is-text is-active" to="/integrado/comision">Comision</NavLink>
            </li>
            <li>
              <NavLink activeClassName="button is-text is-active" to="/integrado/cuentas-asignadas">Cuentas Asignadas</NavLink>
            </li>
            <li>
              <NavLink activeClassName="button is-text is-active" to="/integrado/documentos">Documentos</NavLink>
            </li>
            <li>
              <NavLink activeClassName="button is-text is-active" to="/integrado/cuentas-bancarias">Cuentas bancarias integrado</NavLink>
            </li>
            {
              generales && generales.tipoPersona.id === PERSON_TYPE &&
              <li>
                <NavLink activeClassName="button is-text is-active" to="/integrado/persona-moral">Personal Moral</NavLink>
              </li>
            }
            {
              generales && generales.tipoPersona.id  === PERSON_TYPE &&
              <li>
                <NavLink activeClassName="button is-text is-active" to="/integrado/representante-legal">Representante Legal</NavLink>
              </li>
            }
          </ul>
        </Tabs>
        <Switch>
          <Route path="/integrado/comision" render={() => <Comision
            data={mapPropsToState(comisiones, dataComisiones || {})}
          />} />
          <Route path="/integrado/cuentas-asignadas" render={() => <CuentasAsignadas
            data={mapPropsToState(cuentasAsignadas, dataCuentasAsignadas || {})}
          />} />
          <Route path="/integrado/documentos" render={() => <Documentos
            data={mapPropsToState(documentos, dataDocumentos || {})}
          />} />
          <Route path="/integrado/cuentas-bancarias" render={() => {
            return (
              <Fragment>
                {
                  this.state.showingAccount || <CuentasBancarias
                    colsetup={cuentasBancarias}
                    coldata={{ data: cuentasBancariasIntegrado || [] }}
                    customCol={{
                      customColName: 'actions',
                      renderFunc: this.accountActions,
                    }}
                  />
                }
                {
                  this.state.showingAccount &&
                  <Columns className="is-multiline">
                    <Column className="is-full">
                      <Button default className="is-pulled-right" onClick={this.closeAccountView}>
                        Regresar a lista de cuentas
                      </Button>
                    </Column>
                    <Column className="is-full">
                      <DetalleCuenta
                        data={mapPropsToState(detalleCuenta, this.props.account || {})}
                      />
                    </Column>
                  </Columns>
                }
              </Fragment>
            )
          }} />
          {(generales && generales.tipoPersona.id  === PERSON_TYPE) && <Route path="/integrado/persona-moral" render={() => <PersonaMoral
            data={mapPropsToState(personaMoral, dataPersonaMoral || {})}
          />} />
          }
          {generales && generales.tipoPersona.id  === PERSON_TYPE &&
            <Route path="/integrado/representante-legal" render={() => {
              return (
                <Fragment>
                  {this.state.showingLegalRepresentative || <Table
                    colsetup={legalRepresentativeCols}
                    coldata={{ data: representanteLegal || [] }}
                    customCol={{
                      customColName: 'actions',
                      renderFunc: this.legalRepresentativeActions,
                    }}
                  />}
                  {this.state.showingLegalRepresentative &&
                    <Columns className="is-multiline">
                      <Column className="is-full">
                        <Button className="is-pulled-right" onClick={this.closeLegalRepresentative}>
                          Regresar a lista de representantes
                        </Button>
                      </Column>
                      <Column className="is-full">
                        <RepLegal
                          data={mapPropsToState(legalRepresentative, this.props.legalRepresentative || {})}
                        />
                      </Column>
                    </Columns>
                  }
                </Fragment>
              )
            }
            } />
          }
          <Route render={() => <General
            data={mapPropsToState(general, generales || {})}
          />} />
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = ({ principal }) => ({
  partnerProfile: principal.partnerProfile,
  account: principal.account,
  legalRepresentative: principal.legalRepresentative,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getAccount,
  getLegalRepresentative,
  getPartnerProfile,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(PartnerProfile)