import axios from 'axios'

const url = process.env.REACT_APP_API

const initialState = {
  referal: undefined,
}

const CREATE_USER = 'TRIAL/CREATE_USER'
const GET_REFERRAL = 'TRIAL/GET_REFERRAL'
export default (state = initialState, { type, payload }) => {
  switch (type) {

  case CREATE_USER:
    return { ...state, ...payload }

  default:
    return state
  }
}

export const createUserAction = (user) => {
  return dispatch => {
    return axios.post(`${url}/onboarding`, user)
      .then(({ data }) => {
        dispatch({
          type: CREATE_USER,
          payload: { user: data }
        })
      })
  }
}

export const getReferral = (hash) => {
  return dispatch => {
    return axios.get(`${url}/referral/${hash}`)
      .then(({ data }) => {
        dispatch({
          type: GET_REFERRAL,
          payload: { referal: data }
        })
      })
  }
}