import React from 'react'
import Radio from './Radio'

const MultiRadio = ({ multi, showErrors, errors, ...props }) => {
  return (
    <ul>
      {multi.map((it, idx) => (
        <li key={idx}>
          <Radio
            {...it}
            {...props}
          />
        </li>
      ))}
    {(errors && !!errors.length && showErrors) && <li><p className="has-text-danger help">{errors[0].message}</p></li>}
    </ul>
  )
}

export default MultiRadio
