import React, { Component } from 'react'
import { Tabs, Button, Icon } from 'Components/common/bulma'
import { Table, ModalRemove } from 'Components/common'
import { default as AccountCreate } from './create'
import classnames from 'classnames'
import { Trans } from '@lingui/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { getValues, validateData } from 'App/helpers'

export default class Accounts extends Component {
  state = {
    viewMode: 'list',
    showErrors: false,
    showModal: false,
    account: undefined,
  }

  changeAccountTab = (view) => {
    if (view === 'new' && !this.props.resource) {
      this.props.createResource(() => this.setState({
        viewMode: view
      }))
      return
    }

    this.setState({
      viewMode: view
    })
  }

  toggleModal = ({ id }) => {
    this.setState(({ showModal }) => ({
      account: id,
      showModal: !showModal
    }))
  }

  customColActions = (row) => (
    <Button danger outlined onClick={() => this.toggleModal(row)}>
      <Icon>
        <FontAwesomeIcon icon={faTrash} />
      </Icon>
    </Button>
  )

  onChangeInput = e => {
    this.setState({
      showErrors: false
    })
    this.props.onChangeInput(e, 'accountData')
  }

  createAccount = () => {
    const { data, hasErrors } = validateData(this.props.accountData)

    if (hasErrors) {
      this.props.updateAccountData(
        data,
        () =>
          this.setState({
            showErrors: true
          })
      )
      return
    }
    this.props.createAccount(this.props.resource, getValues(this.props.accountData))
      .then(() => {
        this.props.updateAccountData(this.props.confData)
        this.props.getAccounts(this.props.resource)
          .then(() => {
            this.setState({
              viewMode: 'list',
            })
          })
      })
  }

  cancelAccount = () => {
    this.setState({
      viewMode: 'list',
    })
    this.props.updateAccountData(this.props.confData)
  }

  delete = () => {
    this.props.deleteAccount(this.props.resource, this.state.account)
      .then(() => {
        this.setState({ showModal: false })
      })
  }

  render() {
    const { accountColDef, accounts, estatusPersona, accountData, getAccounts } = this.props
    return (
      <div>
        <ModalRemove
          isDeleting={this.state.showModal}
          title="Eliminar cuenta de banco del prestamista"
          toggleModal={this.toggleModal}
          confirmText="¿Esta seguro de querer eliminar la cuenta seleccionada?"
          resource=""
          deleteResource={this.delete}
        />
        <Tabs>
          <ul>
            <li className={classnames({ 'is-active': this.state.viewMode === 'list' })}>
              <Button onClick={() => this.changeAccountTab('list')}><Trans>Cuentas</Trans></Button></li>
            <li>
              <Button success outlined onClick={() => this.changeAccountTab('new')}>
                <Icon>
                  <FontAwesomeIcon icon={faPlus} />
                </Icon>
                <Trans render="span">Agregar cuenta bancaria</Trans>
              </Button>
            </li>
          </ul>
        </Tabs>
        {this.state.viewMode === 'list' && (
          <Table
            colsetup={accountColDef}
            coldata={accounts}
            tableClassName='table is-striped is-hoverable is-fullwidth'
            emptyTableMarkUp={(<span>Aun no hay cuentas</span>)}
            customCol={{
              customColName: 'acciones',
              renderFunc: this.customColActions
            }}
            initialFetch={!!this.props.resource}
            refresh={(params) => getAccounts(this.props.resource, params)}
          />
        )}
        {this.state.viewMode === 'new' && (
          <AccountCreate
            data={accountData}
            estatusPersona={estatusPersona}
            onChange={this.onChangeInput}
            showErrors={this.state.showErrors}
            onSave={this.createAccount}
            onCancel={this.cancelAccount}
          />)}
      </div>
    )
  }
}
