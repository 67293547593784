import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faEdit, faSave, faTrash, faKey } from '@fortawesome/free-solid-svg-icons'
import { Button, Icon, Columns, Column } from 'Components/common/bulma'
import { Can } from 'Containers/auth'
import PropTypes from 'prop-types'


const ActionBar = ({
  onDelete,
  onEdit,
  onSave,
  onCancel,
  onAuthorize,
  permissions = {},
  basicRole = [],
  authorizeRole = [],
  customButton,
  labels = {
    delete: 'Eliminar',
    cancel: 'Cancelar',
  }
}) => {
  return (
    <Columns>
      <Column className="action-bar has-text-right">
        {customButton}
        {permissions.canDelete && (
          <Can validate={basicRole}>
            <Button onClick={onDelete} danger>
              <Icon>
                <FontAwesomeIcon icon={faTrash} />
              </Icon>
              <span>{labels.delete}</span>
            </Button>
          </Can>
        )}
        {permissions.canEdit && (
          <Can validate={basicRole}>
            <Button onClick={onEdit} className="margin-left-xs">
              <Icon>
                <FontAwesomeIcon icon={faEdit} />
              </Icon>
              <span>Editar</span>
            </Button>
          </Can>)}
        {permissions.canAuthorize && (
          <Can validate={authorizeRole}>
            <Button onClick={onAuthorize} className="margin-left-xs">
              <Icon>
                <FontAwesomeIcon icon={faKey} />
              </Icon>
              <span>Autorizar</span>
            </Button>
          </Can>)}
        {permissions.canSave && (
          <Can validate={basicRole}>
            <Button onClick={onSave} className="margin-left-xs">
              <Icon>
                <FontAwesomeIcon icon={faSave} />
              </Icon>
              <span>Guardar</span>
            </Button>
          </Can>
        )}
        {!!onCancel && <Button onClick={onCancel} className="margin-left-xs">
          <Icon>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Icon>
          <span>{labels.cancel}</span>
        </Button>}
      </Column>
    </Columns>
  )
}

ActionBar.propTypes = {
  permissions: PropTypes.object.isRequired,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
}

export default ActionBar