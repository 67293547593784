export const walletData = {
  estatus: {
    value: '',
    name: 'estatus',
    label: 'Estatus',
    validation: 'required',
  },
  estatusEdenRed: {
    value: '',
    name: 'estatusEdenRed',
    label: 'Estatus Edenred',
    disabled: true,
  },
  email: {
    disabled: true,
    name: 'email',
    label: 'Email',
    value: '',
  },
  noTarjeta: {
    name: 'noTarjeta',
    label: 'No de Tarjeta',
    disabled: true,
    value: '',
  },
  numero: {
    name: 'numero',
    label: 'Número',
    disabled: true,
    value: '',
  },
  centroGastos: {
    name: 'centroGastos',
    label: 'Centro de costos',
    disabled: true,
    value: '',
  }
}

export const colDef = [
  {
    title: 'Estatus',
    datakey: 'estatus',
    sortable: true,
    width: '10%',
  },
  {
    title: 'Número',
    datakey: 'numero',
    sortable: true,
    width: '30%',
  },
  {
    title: 'No de Tarjeta',
    datakey: 'noTarjeta',
    sortable: true,
    width: '30%',
  },
  {
    title: 'Email',
    datakey: 'email',
    sortable: true,
    width: '20%',
  },
  {
    title: 'Acciones',
    custom: 'actions',
    width: '10%',
  },
]