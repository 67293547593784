import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from 'Containers/home'
import { Error404 } from 'Components/auth'
import { Login, Recuperar, ValidarToken, CambiarContrasena } from 'Containers/auth'
import {
    CollaboratorsList,
    CollaboratorsCreate,
} from 'Containers/catalogs/collaborators'
import {
    WalletExpenseList,
    WalletExpenseForm,
} from 'Containers/catalogs/walletExpense'
import {
    ClientList,
    ClientForm,
} from 'Containers/catalogs/clients'
import {
    LenderList,
    LenderForm,
} from 'Containers/catalogs/lenders'
import PaymentOrders, {
    PendingPaymentOrders,
    RejectedInvoicesPaymentOrders,
    PendingToPayPaymentOrders,
    PendingInvoicesPaymentOrders,
    PaymentOrdersProvider,
    PaymentOrdersSalaries,
    ProcesingPaymentOrdersSalaries,
    PaidPaymentOrdersSalaries,
    RejectedPaymentOrdersSalaries,
    PaymentOrdersWalletExpense,
    PaidPaymentOrdersWalletExpense,
    ProcesingPaymentOrdersWalletExpense,
    RejectedPaymentOrdersWalletExpense,
    PaymentOrdersWalletGasoline,
    PaidPaymentOrdersWalletGasoline,
    ProcesingPaymentOrdersWalletGasoline,
    RejectedPaymentOrdersWalletGasoline,
    PaymentOrdersReceiptFee,
    PaymentOrdersRetreats,
    PaymentOrdersRefund,
    PaymentOrdersProviderForm,
    PaymentOrdersSalariesForm,
    PaymentOrdersWalletExpenseForm,
    PaymentOrdersWalletGasolineForm,
    PaymentOrdersReceiptFeeForm,
    PaymentOrdersRefundForm,
} from 'Containers/paymentOrders'

import IncomeOrders, {
    IncomeOrdersInvoice,
    IncomeOrdersDeposits,
    IncomeOrdersInvoiceForm,
    IncomeOrdersDepositsForm,
    IncomeOrdersAdvancePayment,
} from 'Containers/incomeOrders'
import PendingIncomeOrders from 'Containers/incomeOrders/pendingOrders'
import PendingCollectionIncomeOrders from 'Containers/incomeOrders/pendingCollectionIncomeOrders'
import CollectionCard from 'Containers/incomeOrders/CollectionCard'
import LoanOrders, {
    AuthorizeLoanOrders,
    LoanOrdersCreditor,
    LoanOrdersCreditorForm,
    LoanOrdersDebtor,
    LoanOrdersDebtorForm,
    LoanOrdersPaymentAmortization,
    LoanOrdersPaymentAmortizationForm,
    LoanOrdersChargeAmortization,
    LoanOrdersChargeAmortizationForm,
} from 'Containers/loanOrders'
import { default as Reports } from 'Containers/reports'
import { RecuperarEnviado } from 'Components/auth'
import PartnerProfile from 'Containers/partnerProfile'
import Catalogos from 'Containers/catalogs'
import { List, Form } from 'Containers/catalogs/providers'
import { MonederoGasolinaLista, FormMonederoGasolina } from 'Containers/catalogos/monederoGasolina'
import PrivateRoute from 'Containers/privateroute'
import Documents from 'Containers/integradora/documents'
import PaymentOrdersRetreatsRoutes from './paymentOrdersRetreatsRoutes'
import ProductServicesRoutes from './productServicesRoutes'
import InventoryRoutes from './inventoryRoutes'
import TransferRoutes from './transferRoutes'
import ConciliationsRoutes from './conciliationsRoutes'
import UsersRoutes from './usersRoutes'
import WidgetRoutes from './widgetRoutes'
import TotpQr from 'Containers/totpqr'
import ContractRoutes from './contractRoutes'
import Trial from 'Containers/trial'
import TrialSuccess from 'Components/trialSuccess'
import ShippingRoutes from './shippingRoutes'
import Onboarding from './onboardingRoutes'

export const AppRoutes = () =>
    <Switch>
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute exact path="/catalogos" component={Catalogos} />
        <PrivateRoute exact path="/catalogos/proveedores" component={List} />
        <PrivateRoute exact path="/catalogos/proveedores/nuevo" component={Form} />
        <PrivateRoute exact path="/catalogos/proveedores/:id" component={Form} />

        <PrivateRoute exact path="/catalogos/colaboradores" component={CollaboratorsList} />
        <PrivateRoute exact path="/catalogos/colaboradores/nuevo" component={CollaboratorsCreate} />
        <PrivateRoute exact path="/catalogos/colaboradores/:id" component={CollaboratorsCreate} />

        <PrivateRoute exact path="/catalogos/proveedores/editar" component={Form} />

        <PrivateRoute exact path="/catalogos/monederos-gastos" component={WalletExpenseList} />
        <PrivateRoute exact path="/catalogos/monederos-gastos/nuevo" component={WalletExpenseForm} />
        <PrivateRoute exact path="/catalogos/monederos-gastos/:id" component={WalletExpenseForm} />

        <PrivateRoute exact path="/catalogos/monederos-gasolina" component={MonederoGasolinaLista} />
        <PrivateRoute exact path="/catalogos/monederos-gasolina/nuevo" component={FormMonederoGasolina} />
        <PrivateRoute exact path="/catalogos/monederos-gasolina/:id" component={FormMonederoGasolina} />

        <PrivateRoute exact path="/catalogos/clientes" component={ClientList} />
        <PrivateRoute exact path="/catalogos/clientes/nuevo" component={ClientForm} />
        <PrivateRoute exact path="/catalogos/clientes/:id" component={ClientForm} />

        <PrivateRoute exact path="/catalogos/prestamistas" component={LenderList} />
        <PrivateRoute exact path="/catalogos/prestamistas/nuevo" component={LenderForm} />
        <PrivateRoute exact path="/catalogos/prestamistas/:id" component={LenderForm} />

        <PrivateRoute exact path="/ordenes-pago" component={PaymentOrders} />
        <PrivateRoute exact path="/ordenes-pago/pendientes" component={PendingPaymentOrders} />
        <PrivateRoute exact path="/ordenes-pago/facturas-rechazadas" component={RejectedInvoicesPaymentOrders} />
        <PrivateRoute exact path="/ordenes-pago/pendientes-pago" component={PendingToPayPaymentOrders} />
        <PrivateRoute exact path="/ordenes-pago/proveedores/facturas-pendientes" component={PendingInvoicesPaymentOrders} />
        <PrivateRoute exact path="/ordenes-pago/proveedores" component={PaymentOrdersProvider} />
        <PrivateRoute exact path="/ordenes-pago/asimilados-salarios" component={PaymentOrdersSalaries} />
        <PrivateRoute exact path="/ordenes-pago/asimilados-salarios/en-proceso" component={ProcesingPaymentOrdersSalaries} />
        <PrivateRoute exact path="/ordenes-pago/asimilados-salarios/pagados" component={PaidPaymentOrdersSalaries} />
        <PrivateRoute exact path="/ordenes-pago/asimilados-salarios/rechazados" component={RejectedPaymentOrdersSalaries} />
        <PrivateRoute exact path="/ordenes-pago/monederos-gastos" component={PaymentOrdersWalletExpense} />
        <PrivateRoute exact path="/ordenes-pago/monederos-gastos/en-proceso" component={ProcesingPaymentOrdersWalletExpense} />
        <PrivateRoute exact path="/ordenes-pago/monederos-gastos/pagadas" component={PaidPaymentOrdersWalletExpense} />
        <PrivateRoute exact path="/ordenes-pago/monederos-gastos/rechazadas" component={RejectedPaymentOrdersWalletExpense} />
        <PrivateRoute exact path="/ordenes-pago/monederos-gasolina" component={PaymentOrdersWalletGasoline} />
        <PrivateRoute exact path="/ordenes-pago/monederos-gasolina/pagadas" component={PaidPaymentOrdersWalletGasoline} />
        <PrivateRoute exact path="/ordenes-pago/monederos-gasolina/en-proceso" component={ProcesingPaymentOrdersWalletGasoline} />
        <PrivateRoute exact path="/ordenes-pago/monederos-gasolina/rechazadas" component={RejectedPaymentOrdersWalletGasoline} />

        <PrivateRoute exact path="/ordenes-pago/recibo-honorarios" component={PaymentOrdersReceiptFee} />
        <PrivateRoute exact path="/ordenes-pago/retiros" component={PaymentOrdersRetreats} />
        <PrivateRoute exact path="/ordenes-pago/reembolsos" component={PaymentOrdersRefund} />
        <PrivateRoute exact path="/ordenes-pago/proveedores/nuevo" component={PaymentOrdersProviderForm} />
        <PrivateRoute exact path="/ordenes-pago/proveedores/:id" component={PaymentOrdersProviderForm} />
        <PrivateRoute exact path="/ordenes-pago/asimilados-salarios/nuevo" component={PaymentOrdersSalariesForm} />
        <PrivateRoute exact path="/ordenes-pago/asimilados-salarios/:id" component={PaymentOrdersSalariesForm} />
        <PrivateRoute exact path="/ordenes-pago/monederos-gastos/nuevo" component={PaymentOrdersWalletExpenseForm} />
        <PrivateRoute exact path="/ordenes-pago/monederos-gastos/:id" component={PaymentOrdersWalletExpenseForm} />
        <PrivateRoute exact path="/ordenes-pago/monederos-gasolina/nuevo" component={PaymentOrdersWalletGasolineForm} />
        <PrivateRoute exact path="/ordenes-pago/monederos-gasolina/:id" component={PaymentOrdersWalletGasolineForm} />
        <PrivateRoute exact path="/ordenes-pago/recibo-honorarios/nuevo" component={PaymentOrdersReceiptFeeForm} />
        <PrivateRoute exact path="/ordenes-pago/recibo-honorarios/:id" component={PaymentOrdersReceiptFeeForm} />
        <PrivateRoute exact path="/ordenes-pago/reembolsos/nuevo" component={PaymentOrdersRefundForm} />
        <PrivateRoute exact path="/ordenes-pago/reembolsos/:id" component={PaymentOrdersRefundForm} />

        <PrivateRoute exact path="/ordenes-ingreso" component={IncomeOrders} />
        <PrivateRoute exact path="/ordenes-ingreso/pendientes" component={PendingIncomeOrders} />
        <PrivateRoute exact path="/ordenes-ingreso/pendientes-cobro" component={PendingCollectionIncomeOrders} />
        <PrivateRoute exact path="/ordenes-ingreso/facturas" component={IncomeOrdersInvoice} />
        <PrivateRoute exact path="/ordenes-ingreso/depositos" component={IncomeOrdersDeposits} />
        <PrivateRoute exact path="/ordenes-ingreso/facturas/nuevo" component={IncomeOrdersInvoiceForm} />
        <PrivateRoute exact path="/ordenes-ingreso/facturas/:id" component={IncomeOrdersInvoiceForm} />
        <PrivateRoute exact path="/ordenes-ingreso/depositos/nuevo" component={IncomeOrdersDepositsForm} />
        <PrivateRoute exact path="/ordenes-ingreso/depositos/:id" component={IncomeOrdersDepositsForm} />
        <PrivateRoute exact path="/ordenes-ingreso/anticipo-venta" component={IncomeOrdersAdvancePayment} />
        <PrivateRoute exact path="/ordenes-ingreso/cobro-tarjetas" component={CollectionCard} />

        <PrivateRoute exact path="/ordenes-prestamo" component={LoanOrders} />
        <PrivateRoute exact path="/ordenes-prestamo/autorizar" component={AuthorizeLoanOrders} />

        <PrivateRoute exact path="/ordenes-prestamo/acreedoras" component={LoanOrdersCreditor} />
        <PrivateRoute exact path="/ordenes-prestamo/deudoras" component={LoanOrdersDebtor} />
        <PrivateRoute exact path="/ordenes-prestamo/acreedoras/nuevo" component={LoanOrdersCreditorForm} />
        <PrivateRoute exact path="/ordenes-prestamo/acreedoras/:id" component={LoanOrdersCreditorForm} />
        <PrivateRoute exact path="/ordenes-prestamo/deudoras/nuevo" component={LoanOrdersDebtorForm} />
        <PrivateRoute exact path="/ordenes-prestamo/deudoras/:id" component={LoanOrdersDebtorForm} />
        <PrivateRoute exact path="/ordenes-prestamo/amortizacion-cobro" component={LoanOrdersChargeAmortization} />
        <PrivateRoute exact path="/ordenes-prestamo/amortizacion-pago" component={LoanOrdersPaymentAmortization} />
        <PrivateRoute exact path="/ordenes-prestamo/amortizacion-cobro/nuevo" component={LoanOrdersChargeAmortizationForm} />
        <PrivateRoute exact path="/ordenes-prestamo/amortizacion-cobro/:id" component={LoanOrdersChargeAmortizationForm} />
        <PrivateRoute exact path="/ordenes-prestamo/amortizacion-pago/nuevo" component={LoanOrdersPaymentAmortizationForm} />
        <PrivateRoute exact path="/ordenes-prestamo/amortizacion-pago/:id" component={LoanOrdersPaymentAmortizationForm} />

        <PrivateRoute path="/ordenes-traspaso" component={TransferRoutes} />

        <PrivateRoute exact path="/reportes" component={Reports} />

        <PrivateRoute exact path="/perfil/cambiar-contrasena" component={CambiarContrasena} />
        <PrivateRoute path="/integrado" component={PartnerProfile} />
        <PrivateRoute path="/integradora/archivos" component={Documents} />

        <PrivateRoute path="/ordenes-pago/retiros" component={PaymentOrdersRetreatsRoutes} />
        <PrivateRoute path="/catalogos/productos-servicios" component={ProductServicesRoutes} />
        <PrivateRoute path="/inventarios" component={InventoryRoutes} />
        <PrivateRoute path="/conciliaciones" component={ConciliationsRoutes} />
        <PrivateRoute path="/contratos" component={ContractRoutes} />
        <PrivateRoute path="/usuarios" component={UsersRoutes} />
        <PrivateRoute path="/ordenes-pago/paqueteria" component={ShippingRoutes} />
        <PrivateRoute path="/onboarding" component={Onboarding} />

        <PrivateRoute path="/widget" component={WidgetRoutes} />

        <Route exact path="/login" component={Login} />
        <Route exact path="/recuperar-contrasena" component={Recuperar} />
        <Route exact path="/recuperar-enviado" component={RecuperarEnviado} />
        <Route exact path="/recuperar/:token" component={ValidarToken} />
        <Route exact path="/totp/:token" component={TotpQr} />
        <Route exact path="/preregistro/exitoso" component={TrialSuccess} />
        <Route exact path="/preregistro/:token" component={Trial} />
        <Route component={Error404} />
    </Switch>