import React from 'react'
import { Link } from 'react-router-dom'

const Menu = ({ items, children }) => {
  return (
    <aside className="menu">
      <p className="menu-label">
        Menú
      </p>
      <ul className="menu-list has-text-grey">
        {children}
        {items.map((it, id) => <li style={{ height: '60px', fontWeight: 600 }} key={id}><Link to={`/${it.module}`}>{it.label}</Link></li>)}
      </ul>
    </aside>
  )
}

export default Menu