import React from 'react'
import { Columns, Column } from 'Components/common/bulma'
import { Input, Select } from 'Components/common/form'

const PaymentMethod = ({ data, onChange, showErrors, cfdiUseType, paymentMethod }) => {
  return (
    <section>
      <Columns>
        <Column>
        <Select
            onChange={onChange}
            options={paymentMethod}
            showErrors={showErrors}
            {...data.metodoPago}
          />
        </Column>
        <Column>
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...data.cuatroDigitosCuentaBancaria}
          />
        </Column>
      </Columns>
      <Columns>
        <Column className="is-half">
          <Select
            onChange={onChange}
            options={cfdiUseType}
            showErrors={showErrors}
            {...data.tipoUsoCFDI}
          />
        </Column>
      </Columns>
    </section>
  );
};

export default PaymentMethod