import { reject, map, find, propEq } from 'ramda'
import stringHelper from 'underscore.string'

export const getBreadCrumb = (path, menu) => {
    const splitedPath = path.split('/')
    const cleanedEmptyPath = reject(it => {
        return !it
      }, splitedPath)
    const routes = [];
    let count = 0;
    return map(it => {
        routes.push(it)
        count ++
        return find(propEq('module', it) , menu) ? {...find(propEq('module', it) , menu), route: `/${it}`}
            : ({label: stringHelper(it).capitalize().value(), module: it ,route: `/${routes.join('/')}`, isLast: count === cleanedEmptyPath.length})
    }, cleanedEmptyPath)
}