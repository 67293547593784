import React, { Fragment } from 'react'
import { Columns, Column } from '../../common/bulma';
import { Input } from '../../common/form';

const CentroCostoToka = ({ onChange, showErrors, monederoGasolinaData }) => {
  const { identificador, noTarjeta, claveCentroCostos, descripcionCentroCosto } = monederoGasolinaData;

  return (
    <Fragment >
      <Columns>
        <Column>
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...identificador}
          />
        </Column>
        <Column>
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...noTarjeta}
          />
        </Column>
      </Columns>
      <Columns>
        <Column>
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...claveCentroCostos}
          />
        </Column>
        <Column>
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...descripcionCentroCosto}
          />
        </Column>
      </Columns>
    </Fragment>
  )
}

export default CentroCostoToka;
