import React, { Fragment } from 'react'
import { Columns, Column, Divider, Button } from 'Components/common/bulma'
import { Select, Input, Check } from 'Components/common/form'
import { Trans } from '@lingui/macro'
import {
  NACIONAL,
  EXTRANJERO,
  CONVENIO_CIE_BBVA,
  DIRECTO,
  INTERMEDIARIO,
} from 'App/constants'


const Create = ({ data, onChange, showErrors, estatusPersona, onSave, onCancel }) => {
  const tipoCuenta = data.tipoCuenta.value
  const directoOIntermediario = data.pagoDirectoOconBancoIntermediario.value
  return (
    <div>
      <Columns>
        <Column className="is-6">
          <Select
            onChange={onChange}
            showErrors={showErrors}
            {...data.tipoCuenta}
          />
        </Column>
        <Column className="is-6">
          <Select
            onChange={onChange}
            options={estatusPersona}
            showErrors={showErrors}
            valueProperty="id"
            valueLabel="estatus"
            {...data.estatus}
          />
        </Column>

      </Columns>
      {tipoCuenta === NACIONAL && (
        <Fragment>
          <Divider content="BANCOS NACIONALES" />
          <Columns>
            <Column className="is-6">
              <Input
                onChange={onChange}
                showErrors={showErrors}
                {...data.clabe}
              />
              <Input
                onChange={onChange}
                showErrors={showErrors}
                {...data.banco}
              />
              <Input
                onChange={onChange}
                showErrors={showErrors}
                {...data.plaza}
              />
            </Column>
            <Column className="is-6">
              <Input
                onChange={onChange}
                showErrors={showErrors}
                {...data.cuentaBancaria}
              />
              <Input
                onChange={onChange}
                showErrors={showErrors}
                {...data.numeroSucursal}
              />
              <Check
                onChange={onChange}
                showErrors={showErrors}
                {...data.encabezadoEstadoCuenta}
              />
            </Column>
          </Columns>
        </Fragment>
      )}
      {tipoCuenta === CONVENIO_CIE_BBVA && (
        <Fragment>
          <Divider content="CONVENIO CIE BBVA BANCOMER" />
          <Columns>
            <Column className="is-6">
              <Input
                onChange={onChange}
                showErrors={showErrors}
                {...data.convenioBBVA}
              />
              <Input
                onChange={onChange}
                showErrors={showErrors}
                {...data.concepto}
              />
            </Column>
            <Column className="is-6">
              <Input
                onChange={onChange}
                showErrors={showErrors}
                {...data.referencia}
              />
            </Column>

          </Columns>
        </Fragment>)}

      {tipoCuenta === EXTRANJERO && (
        <Fragment>
          <Divider content="BANCOS NACIONALES O INTERNACIONALES USD" />
          <Columns>
            <Column className="is-6">
              <Select
                onChange={onChange}
                options={[
                  { value: 'Directo', label: 'Directo' },
                  { value: 'Intermediario', label: 'Intermediario' },
                ]}
                showErrors={showErrors}
                {...data.pagoDirectoOconBancoIntermediario}
              />
            </Column>
          </Columns>
          {directoOIntermediario === DIRECTO && (
            <Fragment>
              <Divider content="DIRECTOS" />
              <Columns>
                <Column className="is-6">
                  <Input
                    onChange={onChange}
                    showErrors={showErrors}
                    {...data.directoBeneficiario}
                  />
                  <Input
                    onChange={onChange}
                    showErrors={showErrors}
                    {...data.directoIBAN}
                  />
                  <Input
                    onChange={onChange}
                    showErrors={showErrors}
                    {...data.directoVATID}
                  />
                </Column>
                <Column className="is-6">
                  <Input
                    onChange={onChange}
                    showErrors={showErrors}
                    {...data.directoDireccion}
                  />
                  <Input
                    onChange={onChange}
                    showErrors={showErrors}
                    {...data.directoSwiftCode}
                  />
                  <Input
                    onChange={onChange}
                    showErrors={showErrors}
                    {...data.directoBanco}
                  />
                </Column>
              </Columns>
            </Fragment>
          )}
          {directoOIntermediario === INTERMEDIARIO && (
            <Fragment>
              <Divider content="INTERMEDIARIO" />
              <Columns>
                <Column className="is-6">
                  <Input
                    onChange={onChange}
                    showErrors={showErrors}
                    {...data.interNombreBancoIntermediario}
                  />
                  <Input
                    onChange={onChange}
                    showErrors={showErrors}
                    {...data.interSwiftCode}
                  />
                  <Input
                    onChange={onChange}
                    showErrors={showErrors}
                    {...data.interNombreBancoReceptor}
                  />
                  <Input
                    onChange={onChange}
                    showErrors={showErrors}
                    {...data.interSwiftCodeBanco}
                  />
                  <Input
                    onChange={onChange}
                    showErrors={showErrors}
                    {...data.interNumeroCuentaBancoReceptor}
                  />
                  <Input
                    onChange={onChange}
                    showErrors={showErrors}
                    {...data.interNombreCuentaBancoReceptor}
                  />
                  <Input
                    onChange={onChange}
                    showErrors={showErrors}
                    {...data.interBankInstructions}
                  />
                </Column>
                <Column className="is-6">
                  <Input
                    onChange={onChange}
                    showErrors={showErrors}
                    {...data.interPais}
                  />
                  <Input
                    onChange={onChange}
                    showErrors={showErrors}
                    {...data.interSortCode}
                  />
                  <Input
                    onChange={onChange}
                    showErrors={showErrors}
                    {...data.interPaisEstado}
                  />
                  <Input
                    onChange={onChange}
                    showErrors={showErrors}
                    {...data.interCuentaBeneficiario}
                  />
                  <Input
                    onChange={onChange}
                    showErrors={showErrors}
                    {...data.interCiudadPaisBeneficiario}
                  />
                  <Input
                    onChange={onChange}
                    showErrors={showErrors}
                    {...data.interNombreBeneficiario}
                  />
                </Column>
              </Columns>
            </Fragment>)}
        </Fragment>
      )}
    <Columns>
    <Column className="has-text-right">
            <Button primary onClick={onSave}>
              <Trans>Guardar</Trans>
            </Button>
            <Button default onClick={onCancel} className="margin-left-sm">
              <Trans>Cancelar</Trans>
            </Button>
    </Column>
    </Columns>
    </div>
  )
}

export default Create