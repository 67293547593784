import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import { Box, Columns, Column, Divider } from 'Components/common/bulma'
import { ActionBar, Table, ModalRemove } from 'Components/common'
import { addNotification } from 'Modules/principal'
import {
  creditor,
  addressData,
  amortizationCols,
  paymentCols,
} from './data'
import { AddressSection, } from 'Components/catalogs/providers'
import {
  getResources,
  getResource,
  getAccounts,
  clearResources,
  clearLender
} from 'Modules/catalogs/lenders'
import {
  getPaises,
  getEntidades,
  getTipoPersona,
  getFrecuency,
  getRealPaymentMethod,
} from 'Modules/catalogos'
import { mapPropsToState, getDataInput, getValues, validateData } from 'App/helpers'
import { Select, Date, File, Output, Input, Autocomplete } from 'Components/common/form'
import {
  getPaymentEstatus,
} from 'Modules/catalogos'
import {
  createOrder,
  updateOrder,
  getOrder,
  authorizeOrder,
  endOrderEdit,
  uploadContract,
  getAttachments,
  deleteOrder,
  cleanOrigin,
} from 'Modules/loanOrders/creditor'
import Attachments from 'Containers/paymentOrders/attachments'
import { Authorize } from 'Containers/auth'
import { propEq, equals } from 'ramda'

export class Form extends Component {
  state = {
    addressData,
    order: creditor,
    contratoPrestamo: undefined,
    mutuo: undefined,
    showErrors: false,
  }

  componentDidMount() {
    const { getPaises, getEntidades, getResources,
      getPaymentEstatus,
      getTipoPersona,
      getFrecuency,
      getRealPaymentMethod,
    } = this.props

    getPaises()
    getEntidades()
    getResources({ page: 1, size: 1000 })
    getPaymentEstatus()
    getTipoPersona()
    getFrecuency()
    getRealPaymentMethod()

    const id = this.props.match.params.id
    if (id && (id !== this.state.order.id.value)) {
      this.props.getOrder(id)
      this.props.getAttachments(id)
    }
  }

  componentWillReceiveProps(nextProps) {

    if (this.props.lender !== nextProps.lender) {
      this.setState({
        order: mapPropsToState(this.state.order, nextProps.lender),
        addressData: mapPropsToState(this.state.addressData, nextProps.lender)
      })
    }
    if (this.props.order !== nextProps.order) {
      this.setState({
        order: mapPropsToState(this.state.order, nextProps.order),
        addressData: mapPropsToState(this.state.addressData, nextProps.order)
      })

      if (nextProps.order.prestamista && !nextProps.order.lenderAccounts) {
        this.props.getAccounts(nextProps.order.prestamista, 1, 1000)
      }
    }
  }

  componentWillUnmount() {
    this.props.endOrderEdit()
    this.props.clearLender();
    this.props.cleanOrigin();
  }

  onChangeInput = ({ target }) => {
    const { name } = target;
    const order = getDataInput(target, this.state.order)
    const field = order[name]

    this.setState({
      order,
      showErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }

  onChangeLender = ({ value }) => {
    if (value) {
      this.props.getResource(value)
      this.props.getAccounts(value, 1, 1000)
    }
  }

  getPermissions = () => {
    return ({
      canSave: true,
      canDelete: !!(this.props.order && this.props.order.id && this.props.order.estatusOrden === '1'),
      canAuthorize: !!(this.props.order && this.props.order.id && (+this.props.order.estatusOrden === 1 || +this.props.order.estatusOrden === 8))
    })
  }

  onCancel = () => {
    const { push, origin } = this.props
    const isFromPending = equals('authorize')(origin)

    if (isFromPending) {
      push('/ordenes-prestamo/autorizar');
      return
    }
    push('/ordenes-prestamo/acreedoras');
  }

  openAuthizeOrderModal = () => {
    this.setState({
      showAuth: true
    })
  }

  closeAuthorizeOrderModal = () => {
    this.setState({
      showAuth: false
    })
  }

  onAuthorizeOrder = props => {
    const { id } = this.props.order
    return this.props.authorizeOrder({
      id,
      ...props,
    })
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: '¡Orden autorizada correctamente!'
        })
        this.setState({
          showAuth: false
        })
      })
  }

  getAccounts = () => {
    const { value: metodoPago } = this.state.order.metodoPago
    const { value: moneda } = this.state.order.cuentaParaAbonoORetiro

    if (metodoPago && moneda === 'USD' && +metodoPago === 1) {
      return this.props.lenderAccounts.data.filter(propEq('tipoCuenta', 'Extranjero')).map(it => {
        if (it.pagoDirectoOconBancoIntermediario === 'Intermediario') {
          return { value: it.idCuentaBancaria, label: it.interSwiftCode }
        } else {
          return { value: it.id, label: it.directoSwiftCode }
        }
      })
    }
    if ((metodoPago && +metodoPago !== 2 && moneda === 'MXN') && this.props.lenderAccounts.data.length) {
      if (+metodoPago === 1) {
        return this.props.lenderAccounts.data.filter(propEq('tipoCuenta', 'Nacional (MX)')).filter(it => { return !!it.clabe }).map(it => {
          return { value: it.id, label: it.clabe }
        })
      }

      if (+metodoPago === 3) {
        return this.props.lenderAccounts.data.filter(propEq('tipoCuenta', 'Nacional (MX)')).filter(it => { return !!it.pagoDirectoOconBancoIntermediario }).map(it => {
          return { value: it.id, label: it.cuentaBancaria }
        })
      }
    }
    return []
  }

  createOrder = () => {
    const { data, hasErrors } = validateData(this.state.order)
    if (hasErrors) {
      this.setState({
        order: data,
        showErrors: true,
      })
      this.props.addNotification({
        type: 'danger',
        message: '¡Favor de validar algunos campos!'
      })
      return
    }

    const order = getValues(this.state.order)
    const { order: orderProp } = this.props

    if (orderProp && orderProp.id) {
      this.props.updateOrder(order)
        .then(() => {
          this.props.addNotification({
            type: 'success',
            message: '¡Orden actualizada con éxito!'
          })
        })
        .catch(({ response: { data } }) => {
          this.props.addNotification({
            type: 'danger',
            message: JSON.stringify(data.message)
          })
        })
    } else {
      this.props.createOrder(order)
        .then((data) => {
          this.props.addNotification({
            type: 'success',
            message: '¡Orden creada con éxito!'
          })
          this.props.push(`/ordenes-prestamo/acreedoras/${data.id}`)
        })
        .catch(({ response: { data } }) => {
          this.props.addNotification({
            type: 'danger',
            message: JSON.stringify(data.message)
          })
        })
    }
  }

  onChangeMetodoPago = ({ value }) => {

    if (+value === 2) {
      this.setState({
        order: mapPropsToState(this.state.order, { cuentaMXN: undefined })
      })
      return
    }
  }

  uploadContract = () => {
    let data = new FormData()

    data.append('file', this.state.contract, this.state.contract.name)
    this.props.uploadContract(data)
      .then(data => {
        this.setState({
          order: mapPropsToState(this.state.order, { archivo: data.nombreArchivo })
        }, () => {
          this.props.addNotification({
            type: 'success',
            message: '¡El contrato se adjunto correctamente!'
          })
        })
      })
      .catch(({ response: { data } }) => {
        this.props.addNotification({
          type: 'danger',
          message: data.message,
        })
      })
  }

  onChangeContract = ({ target }) => {
    this.setState({
      contract: target.files[0]
    })
  }

  removeContract = () => {
    const data = {
      contract: {},
    }
    this.setState(data)
  }

  removeOrder = () => {
    this.props.deleteOrder(this.props.order.id)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'La órden de prestamo se eliminó correctamente'
        })
        this.props.push('/ordenes-prestamo/acreedoras')
      })
      .catch(({ response: { data } }) => {
        this.props.addNotification({
          type: 'danger',
          message: data.message
        })
      })
  }

  onRemoveOrder = () => {
    this.setState({
      showConfirmRemoveModal: true
    })
  }

  render() {
    const { paises, entidades,
      lenders,
      paymentEstatus,
      tipoPersona,
      frecuency,
      realPaymentMethod,
    } = this.props
    const {
      estatusOrden,
      prestamista,
      tipoDePersona,
      nombreORazonSocial,
      rfc,
      correoElectronico,
      cuentaParaAbonoORetiro,
      tasaAnual,
      plazo,
      frecuencia,
      monto,
      fechaPago,
      metodoPago,
      cuentaMXN,
      referencia,
      fechaProgramacionPago,
      archivo,
      montoDepositar,
      montoDepositarTransito,
      montoDepositado,
    } = this.state.order
    return (
      <Box>
        <ModalRemove
          isDeleting={this.state.showConfirmRemoveModal}
          title="Eliminar orden de préstamo"
          toggleModal={this.hideConfirmRemoveModal}
          confirmText="¿Esta seguro de querer la orden de préstamo?"
          resource=""
          deleteResource={this.removeOrder}
        />
        {this.state.showAuth && (<Authorize
          isOpen={this.state.showAuth}
          authorize={this.onAuthorizeOrder}
          cancel={this.closeAuthorizeOrderModal}
        />)}
        <ActionBar
          basicRole={['OpresAcreedoras']}
          onCancel={this.onCancel}
          onSave={this.createOrder}
          permissions={this.getPermissions()}
          authorizeRole={['ApresAcreedoras']}
          onAuthorize={this.openAuthizeOrderModal}
          onDelete={this.onRemoveOrder}
        />
        <Divider content="GENERALES DE LA FACTURA" />
        <Columns className="is-multiline">
          <Column className="is-half">
            <Select
              {...estatusOrden}
              onChange={this.onChangeInput}
              options={paymentEstatus}
              showErrors={this.state.showErrors}
            />
          </Column>
          <Column className="is-half">
            <Autocomplete
              suggestions={lenders}
              property="nombreORazonSocial"
              showErrors={this.state.showErrors}
              onFetchRequested={this.props.getResources}
              onClearRequested={this.props.clearResources}
              onChange={this.onChangeInput}
              initialInputValue={this.props.order ? this.props.order.nombreORazonSocial : ''}
              {...prestamista}
            />
          </Column>
        </Columns>
        <Divider content="PRESTAMISTA" />
        <Columns className="is-multiline">
          <Column className="is-half">
            <Select
              options={tipoPersona}
              showErrors={this.state.showErrors}
              {...tipoDePersona}
              onChange={this.onChangeInput}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...nombreORazonSocial}
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...rfc}
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...correoElectronico}
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
            />
          </Column>
        </Columns>
        <AddressSection
          data={this.state.addressData}
          onChange={e => this.onChangeInput(e, 'addressData')}
          paises={paises}
          entidades={entidades}
          extranjero={false}
          showErrors={this.state.showErrors}
        />
        <Divider content="DATOS FINANCIEROS DEL PRÉSTAMO" />
        <Columns className="is-multiline">
          <Column className="is-half">
            <Select
              {...cuentaParaAbonoORetiro}
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...tasaAnual}
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...plazo}
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
            />
          </Column>
          <Column className="is-half">
            <Select
              options={frecuency}
              {...frecuencia}
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...monto}
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
            />
          </Column>
          <Column className="is-half">
            <Date
              showErrors={this.state.showErrors}
              {...fechaPago}
              onChange={this.onChangeInput}
            />
          </Column>
        </Columns>
        <Divider content="INFORMACIÓN DE LA TRANSACCIÓN" />
        <Columns className="is-multiline">
          <Column className="is-half">
            <Select
              options={realPaymentMethod}
              {...metodoPago}
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
            />
          </Column>
          <Column className="is-half">
            <Select
              {...cuentaMXN}
              options={this.getAccounts()}
              disabled={+metodoPago.value === 2}
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...referencia}
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
            />
          </Column>
          <Column className="is-half">
            <Date
              {...fechaProgramacionPago}
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
            />
          </Column>
        </Columns>
        <Divider content="CONTRATOS" />
        <Columns className="is-multiline">
          <Column className="is-half">
            <File
              {...archivo}
              fileName={(this.state.contract && this.state.contract.name) || archivo.value}
              disabled={false}
              file={this.state.contract}
              accept="application/pdf"
              onChange={this.onChangeContract}
              onClick={this.uploadContract}
              removeFile={this.removeContract}
            />
          </Column>
        </Columns>
        <Divider content="PAGOS" />
        <Table
          colsetup={paymentCols}
          coldata={{ data: this.props.order ? this.props.order.pagos : [] }}
        />
        <Divider content="RESUMEN DEL PAGO" />
        <Columns className="is-multiline">
          <Column className="is-half">
            <Output
              {...montoDepositar}
            />
          </Column>
          <Column className="is-half">
            <Output
              {...montoDepositarTransito}
            />
          </Column>
          <Column className="is-half">
            <Output
              {...montoDepositado}
            />
          </Column>
        </Columns>
        <Divider content="ORDENES CON LAS AMORTIZACIONES RELACIONADAS A LA ORDEN" />
        <Table
          colsetup={amortizationCols}
          coldata={{ data: [] }}
        />

        <Attachments
          files={this.props.attachments}
        />
      </Box>
    )
  }
}

const mapStateToProps = ({ catalogos: {
  paises,
  entidades,
  paymentEstatus,
  tipoPersona,
  frecuency,
  realPaymentMethod,
}, catalogs: { lenders }, loanOrders }) => ({
  paises,
  entidades,
  paymentEstatus,
  lenders: lenders.resources.data,
  lender: lenders.resource,
  lenderAccounts: lenders.accounts,
  order: loanOrders.creditor.order,
  tipoPersona,
  frecuency,
  realPaymentMethod,
  attachments: loanOrders.creditor.attachments,
  origin: loanOrders.creditor.origin,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  push,
  getPaises,
  getEntidades,
  getResources,
  getResource,
  getPaymentEstatus,
  getTipoPersona,
  getFrecuency,
  createOrder,
  addNotification,
  getRealPaymentMethod,
  updateOrder,
  getOrder,
  authorizeOrder,
  endOrderEdit,
  uploadContract,
  getAccounts,
  getAttachments,
  deleteOrder,
  clearResources,
  clearLender,
  cleanOrigin,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Form)
