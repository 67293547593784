import React from 'react'
import { Link } from 'react-router-dom'
import { Column } from 'Components/common/bulma'

const RecuperarEnviado = () => {
    return (
        <section className="hero is-large is-default is-bold">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-vcentered">
                        <div className="column is-6 is-offset-1 landing-caption">
                            <h1 className="title is-2 is-bold is-spaced">
                                Recuperación de contraseña
                            </h1>
                            <h2 className="subtitle is-5 is-muted has-text-justify"> Se ha enviando un enlace a su correo electrónico con las instrucciones para recuperar la contraseña,
                              asociado a la tarjeta. </h2>
                            <Link to="/login" className="button is-danger ">Regresar al inicio</Link>
                        </div>
                        <Column className="is-6">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 485.638 485.638" style={{ enableBackground: "new 0 0 485.638 485.638" }} xmlSpace="preserve" width="300px" height="300px">
                                <g>
                                    <path d="M472.689,156.996L328.642,12.949c-17.266-17.266-45.353-17.266-62.616,0L12.948,266.024   c-17.264,17.266-17.264,45.353,0,62.617L156.995,472.69c17.266,17.264,45.354,17.265,62.617,0l253.076-253.076   C489.954,202.348,489.952,174.26,472.689,156.996z M183.546,455.21c-2.064-0.707-4.031-1.745-5.68-3.393L33.819,307.771   c-0.039-0.041-0.048-0.092-0.09-0.133l149.816-0.001V455.21z M42.601,278.118L278.122,42.596l0.002,229.085   c-0.002,3.444-2.998,6.442-6.443,6.442L42.601,278.118z M213.06,437.498v-129.86h58.623c9.609,0,18.64-3.741,25.427-10.528   c6.788-6.788,10.527-15.817,10.528-25.428V213.06l129.854,0.005L213.06,437.498z M307.637,183.545V33.729   c0.04,0.041,0.093,0.05,0.134,0.091l144.047,144.047c1.646,1.647,2.685,3.613,3.388,5.683L307.637,183.545z" fill="#D80027" />
                                    <path d="M14.382,62.57h151.154c7.941,0,14.381-6.438,14.381-14.38c0-7.942-6.439-14.381-14.381-14.381H14.382   c-7.943,0-14.381,6.438-14.381,14.381C0.001,56.132,6.439,62.57,14.382,62.57z" fill="#D80027" />
                                    <path d="M14.382,119.065h94.784c7.942,0,14.38-6.438,14.38-14.381c0-7.941-6.438-14.38-14.38-14.38H14.382   c-7.943,0-14.381,6.438-14.381,14.38C0.001,112.627,6.439,119.065,14.382,119.065z" fill="#D80027" />
                                    <path d="M14.382,173.849h61.197c7.941,0,14.381-6.438,14.381-14.381s-6.439-14.381-14.381-14.381H14.382   c-7.943,0-14.381,6.439-14.381,14.381S6.439,173.849,14.382,173.849z" fill="#D80027" />
                                    <path d="M75.579,438.278H14.382c-7.943,0-14.381,6.439-14.381,14.381c0,7.942,6.438,14.381,14.381,14.381h61.197   c7.941,0,14.381-6.438,14.381-14.381C89.96,444.717,83.521,438.278,75.579,438.278z" fill="#D80027" />
                                </g>
                            </svg>
                        </Column>
                    </div>
                </div>
            </div>

        </section >
    )
}

export default RecuperarEnviado