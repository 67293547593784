import React, { memo } from 'react'
import { Columns, Column } from 'Components/common/bulma'
import { InputWithError } from 'Components/common/form'


function Reference({ referenceFields, onChange, showErrors }) {
  return (
    <Columns className="is-multiline">
      <Column className="is-half">
        <InputWithError
          {...referenceFields.reference}
          onChange={onChange}
          showErrors={showErrors}
        />
      </Column>
      <Column className="is-half">
        <InputWithError
          {...referenceFields.contents}
          onChange={onChange}
          showErrors={showErrors}
        />
      </Column>
    </Columns>
  )
}

export default memo(Reference)
