import axios from "axios"
import { getHeaders } from '../util'

const url = process.env.REACT_APP_API

export const GET_WALLETS = 'walletExpense/GET_WALLETS'
export const GET_WALLET = 'walletExpense/GET_WALLET'
export const CREATE_WALLET = 'walletExpense/CREATE_WALLET'
export const UPDATE_WALLET = 'walletExpense/UPDATE_WALLET'
export const DELETE_WALLET = 'walletExpense/DELETE_WALLET'
export const END_EDIT_WALLET = 'walletExpense/END_EDIT_WALLET'
export const WALLET_IS_VALID = 'walletExpense/WALLET_IS_VALID'
export const AUTHORIZE_WALLET = 'walletExpense/AUTHORIZE_WALLET'
const IS_FETCHING_WALLETS = 'walletExpense/IS_FETCHING_WALLETS'
const IS_FETCHING_WALLETS_COMPLETE = 'walletExpense/IS_FETCHING_WALLETS_COMPLETE'

const initialState = {
  wallets: {
    data: []
  },
  wallet: {},
  isAllow: false,
  isFetchingWallets: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_WALLET:
    case GET_WALLET:
    case GET_WALLETS:
      return { ...state, ...action.payload }
    case END_EDIT_WALLET:
      return { ...state, wallet: {} }
    case DELETE_WALLET:
      const {data, ...meta} = state.wallets
      return { ...state, wallets: {data: data.filter(it => it.id !== action.payload), ...meta, last_page: Math.ceil(data.length/meta.per_page)}}
    case WALLET_IS_VALID:
      return { ...state, isAllow: action.payload }
    case IS_FETCHING_WALLETS:
      return { ...state, isFetchingWallets: true }
    case IS_FETCHING_WALLETS_COMPLETE:
      return { ...state, isFetchingWallets: false }
    default:
      return state
  }
}

export const isFetchingWallets = () => ({
  type: IS_FETCHING_WALLETS
})

export const isFetchingWalletsComplete = () => ({
  type: IS_FETCHING_WALLETS_COMPLETE
})


export const getWallets = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingWallets());
    return axios.post(`${url}/wallet-expenses/search`, params, { headers })
      .then(({ data }) => {
        dispatch(isFetchingWalletsComplete());
        dispatch({
          type: GET_WALLETS,
          payload: { wallets: data }
        })

      })
  }
}
export const createWallet = (wallet) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingWallets());
    return axios.post(`${url}/wallet-expenses`, wallet, { headers })
      .then(({ data }) => {
        dispatch(isFetchingWalletsComplete());
        dispatch({
          type: CREATE_WALLET,
          payload: { wallet: data }
        })
        return data
      })
  }
}
export const getWallet = (walletId) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/wallet-expenses/${walletId}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_WALLET,
          payload: { wallet: data }
        })

      })
  }
}
export const updateWallet = ({ id, ...wallet }) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingWallets());
    return axios.put(`${url}/wallet-expenses/${id}`, wallet, { headers })
      .then(({ data }) => {
        dispatch(isFetchingWalletsComplete());
        dispatch({
          type: UPDATE_WALLET,
        })

      })
  }
}
export const deleteWallet = (walletId) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/wallet-expenses/${walletId}`, { headers })
      .then(() => {
        dispatch({
          type: DELETE_WALLET,
          payload: walletId,
        })
      })
  }
}
export const endEditWallet = () => ({
  type: END_EDIT_WALLET
})

export const isAllow = () => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/wallet-expenses/register/isValid`, { headers })
      .then(() => {
        dispatch({
          type: WALLET_IS_VALID,
          payload: true
        })
      })
      .catch(() => dispatch({
        type: WALLET_IS_VALID,
        payload: false
      }))
  }
}
export const authorize = (id, payload) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/wallet-expenses/${id}/authorize`, payload,{ headers })
      .then(() => {
        dispatch({
          type: AUTHORIZE_WALLET,
          payload: true
        })
      })
  }
}

