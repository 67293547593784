import ForeignLegalRepresentativeDataRepository from "../domain/foreignLegalRepresentativeDataRepository";
import httpService from '../../../services/httpService';


export default class RemoteForeignLegalRepresentativeDataRepository extends ForeignLegalRepresentativeDataRepository {
  async get() {
    const { data } = await httpService.get('/onboarding/legal-representative/foreign');

    return data.data
  }

  async save(foreignLegalRepresentativeData) {
    return httpService.post('/onboarding/legal-representative/foreign', foreignLegalRepresentativeData);
  }

  async update(foreignLegalRepresentativeData, foreignLegalRepresentativeDataId) {
    return httpService.put(`/onboarding/legal-representative/foreign/${foreignLegalRepresentativeDataId}`, foreignLegalRepresentativeData);

  }

  async delete(foreignLegalRepresentativeDataId) {
    return httpService.delete(`/onboarding/legal-representative/foreign/${foreignLegalRepresentativeDataId}`);
  }
}
