import React, { Component } from 'react'
import { validator, validateData, getValues } from '../../helpers'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { Recuperar } from 'Components/auth'
import { getRecoveryToken, getRandomKey } from 'Modules/auth'

export class RecuperarContainer extends Component {
  state = {
    data: {
      tarjeta: {
        value: '',
        validation: 'required|isLength:16',
        pattern: 'number',
      },
    },
    error: {},
    completions: 1,
    showErrors: false,
    loginError: false,
    submitting: false
  }
  componentDidMount() {
    this.props.getRandomKey();
  }
  onChangeInput = ({ target }) => {
    const { value, name } = target
    let { data } = this.state, validateField = true

    validateField = data[name].pattern ? validator(value, data[name].pattern).valid : true
    data[name].value = validateField ? value : this.state.data[name].value

    this.setState({
      data: data,
      showErrors: false,
      loginError: false
    })
  }

  getRecoveryToken = () => {
    const { hasErrors, data } = validateData(this.state.data)
    if (hasErrors) {
      this.setState({ showErrors: true, data })
      return
    }
    this.setState({ submitting: true }, () => {
      const { tokenRandom } = this.props.randomKey
      this.props.getRecoveryToken({ ...getValues(this.state.data), tokenRandom })
        .then(() => {
          this.props.push('/recuperar-enviado')
        }).catch(() => this.setState({ loginError: true, submitting: false }))
    })
  }
  refreshRandomKey = () => {
    const completions = this.state.completions + 1
    this.props.getRandomKey()
      .then(() => {
        this.setState({
          completions: completions
        })
      })
  }
  closeServerError = () => this.setState({ loginError: false })
  render() {
    return (
      <Recuperar
        completions={this.state.completions}
        refreshRandomKey={this.refreshRandomKey}
        randomKey={this.props.randomKey}
        data={this.state.data}
        getRecoveryToken={this.getRecoveryToken}
        onChangeInput={this.onChangeInput}
        showErrors={this.state.showErrors}
        loginError={this.state.loginError}
        hideLoginError={this.closeServerError}
        submitting={this.state.submitting}
      />
    );
  }
}

const mapStateToProps = state => ({
  randomKey: state.auth.randomKey
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getRandomKey,
      getRecoveryToken,
      push
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RecuperarContainer)
