import { cond, always, T, F, } from 'ramda';
import { isEmpty, isRfcInvalid, isNotEmail } from 'App/helpers'

export const fields = {
  id_legal: {
    input: {
      value: '',
      name: 'id_legal',
    },
    label: 'id_legal',
  },
  name: {
    input: {
      value: '',
      name: 'name',
    },
    label: 'Nombre',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  lastName: {
    input: {
      value: '',
      name: 'lastName',
    },
    label: 'Apellido Paterno',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  motherLastName: {
    input: {
      value: '',
      name: 'motherLastName',
    },
    label: 'Apellido Materno',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  gender: {
    input: {
      value: '',
      name: 'gender',
    },
    label: 'Género',
    options: [{ label: 'Femenino', value: 'Femenino' }, { label: 'Masculino', value: 'Masculino' }],
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  rfc: {
    input: {
      value: 'XEXX010101000',
      name: 'rfc',
      maxLength: 13,
      disabled: true,
    },
    label: 'RFC',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [isRfcInvalid, always({ error: 'El formato del RFC es incorrecto' })],
      [T, F]
    ]),
    unmarshall: val => val ? val : 'XEXX010101XXXXXX00',
  },
  curp: {
    input: {
      value: 'XEXX010101XXXXXX00',
      name: 'curp',
      maxLength: 18,
      disabled: true,
    },
    label: 'CURP',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
    unmarshall: val => val ? val : 'XEXX010101XXXXXX00',
  },
  email: {
    input: {
      value: '',
      name: 'email',
    },
    label: 'Correo Electrónico',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [isNotEmail, always({ error: 'El formato del correo electrónico es incorrecto' })],
      [T, F]
    ]),
  },
  phone: {
    input: {
      value: '',
      name: 'phone',
    },
    label: 'Teléfono',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  idDocument: {
    input: {
      value: '',
      name: 'idDocument',
      accept: 'application/pdf',
    },
    label: 'Identificación Oficial (Pasaporte o residencia en México)',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  attorneyPower: {
    input: {
      value: '',
      name: 'attorneyPower',
      accept: 'application/pdf',
    },
    label: 'Poder Notarial con Actos de Dominio o documento que acreedite que tiene la facultad para firmar contratos a favor de su empresa.',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },

};
