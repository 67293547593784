import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Box, SubTitle, Modal, Button, Message, Delete } from 'Components/common/bulma'
import { default as ActionBar } from 'Components/common/actionBar'
import { default as WalletExpenseForm } from 'Components/catalogs/walletExpense/Form'
import { Trans } from '@lingui/macro'
import { walletData } from './data'
import { createWallet, getWallet, updateWallet, deleteWallet, authorize } from 'Modules/catalogs/walletExpense'
import { getDataInput, getValues, validateData, mapPropsToState } from '../../../helpers'
import { addNotification } from 'Modules/principal'
import { push } from 'connected-react-router'
import { Authorize } from 'Containers/auth'

export class Form extends Component {
  state = {
    walletData,
    wallet: undefined,
    showModal: false,
    showAuth: false,
  }
  componentDidMount() {
    const id = this.props.match.params.id
    if (id && (id !== this.state.wallet)) {
      this.props.getWallet(id)
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.wallet.id && nextProps.wallet.id !== this.state.wallet) {
      const walletData = mapPropsToState(this.state.walletData, nextProps.wallet)

      this.setState({
        wallet: nextProps.wallet.id,
        walletData,
      })
    }
  }
  onChangeInput = ({ target }, section) => {
    const { name } = target
    const data = getDataInput(target, this.state[section])
    const field = data[name]

    this.setState({
      [section]: data,
      showErrors: false,
      showAccountErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }
  onCancel = () => {
    this.props.push('/catalogos/monederos-gastos')
  }
  createWallet = () => {
    const { data, hasErrors } = validateData(this.state.walletData)

    if (hasErrors) {
      this.setState({
        walletData: data,
        showErrors: true
      })
      this.props.addNotification({
        type: 'danger',
        message: '¡Favor de validar los campos!'
      })
      return
    }
    if (this.state.wallet) {
      this.props.updateWallet(getValues(this.state.walletData))
        .then(() => {
          this.props.addNotification({
            type: 'success',
            message: 'El monedero se actualizó correctamente'
          })
        })
        .catch(({ response: { data } }) => {
          this.props.addNotification({
            type: 'danger',
            message: JSON.stringify(data)
          })
        })
    } else {
      this.props.createWallet(getValues(this.state.walletData))
        .then(({ id }) => {
          this.props.addNotification({
            type: 'success',
            message: 'El monedero se agregó correctamente'
          })
          this.props.push(`/catalogos/monederos-gastos/${id}`)
        })
        .catch(({ response: { data } }) => {
          this.props.addNotification({
            type: 'danger',
            message: JSON.stringify(data)
          })
        })
    }
  }
  getPermissions = () => {
    return {
      canAuthorize: !!this.state.wallet && !this.props.wallet.estatusEdenRed,
      canDelete: !!this.state.wallet,
      canSave: true
    }
  }
  onAuthorize = ({ id }) => {
    this.setState({
      showAuth: true,
    })
  }
  cancelAuthorize = () => {
    this.setState({
      showAuth: false,
    })
  }
  authorize = (data) => {
    return this.props.authorize(this.state.wallet, data)
  }
  toggleModal = () => {
    this.setState(state => ({
      showModal: !state.showModal,
    }))
  }
  deleteWallet = () => {
    this.props.deleteWallet(this.state.wallet)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'El monedero se eliminó correctamente'
        })
        this.props.push(`/catalogos/monederos-gastos`)
      })
  }
  render() {
    const { estatusPersona } = this.props
    return (
      <Box>
        {this.state.showAuth && (<Authorize
          isOpen={this.state.showAuth}
          authorize={this.authorize}
          cancel={this.cancelAuthorize}
        />)}
        <Modal isActive={this.state.showModal}>
          <Message danger>
            <Message.Header>
              <p>Eliminar monedero de gastos</p>
              <Delete onClick={this.toggleModal} />
            </Message.Header>
            <Message.Body className="has-text-centered">
              ¿Esta seguro de querer eliminar el monedero?
              <br />
              <strong>{this.props.wallet.target}</strong>
              <div className="margin-top-lg">
                <Button danger onClick={this.deleteWallet}>Eliminar</Button>
                <Button default className="margin-left-sm" onClick={this.toggleModal}>
                  Cancelar
                </Button>
              </div>
            </Message.Body>
          </Message>
        </Modal>
        <SubTitle className="has-text-centered" is='3'>
          <Trans>Catálogo de monederos de gastos</Trans>
        </SubTitle>
        <ActionBar
          permissions={this.getPermissions()}
          onSave={this.createWallet}
          onDelete={this.toggleModal}
          onCancel={this.onCancel}
          onAuthorize={this.onAuthorize}
          basicRole={['Oalta']}
          authorizeRole={['Apago de proveedores']}
        />
        <WalletExpenseForm
          data={this.state.walletData}
          estatusPersona={estatusPersona}
          onChange={(e) => this.onChangeInput(e, 'walletData')}
          showErrors={this.state.showErrors}
        />
      </Box>
    )
  }
}

const mapStateToProps = ({ catalogos, catalogs }) => ({
  estatusPersona: catalogos.estatusPersona,
  wallet: catalogs.walletExpense.wallet,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  createWallet,
  addNotification,
  push,
  getWallet,
  updateWallet,
  deleteWallet,
  authorize,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Form)
