import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { getWallets, deleteWallet } from 'Modules/catalogs/walletExpense'
import { Box, Icon, Column, Columns, Modal, Message, Button, Delete, } from 'Components/common/bulma'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Trans } from '@lingui/macro'
import { addNotification } from 'Modules/principal'
import { default as Table } from 'Components/common/table'
import { colDef } from './data'


export class List extends Component {
  state = {
    showModal: false,
    wallet: {},
  }

  onEdit = ({ id }) => {
    this.props.push(`/catalogos/monederos-gastos/${id}`)
  }

  toggleModal = (wallet) => {
    this.setState(state => ({
      showModal: !state.showModal,
      wallet: wallet,
    }))
  }

  deleteWallet = () => {
    this.props.deleteWallet(this.state.wallet.id)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'El monedero se eliminó correctamente'
        })
        this.setState(state => ({
          showModal: !state.showModal
        }))
      })
  }
  customColActions = (row) => (
    <div>
      <Button small primary outlined onClick={() => this.onEdit(row)}>
        <Icon>
          <FontAwesomeIcon icon={faEdit} />
        </Icon>
      </Button>
      <Button small className="margin-left-xs" danger outlined onClick={() => this.toggleModal(row)}>
        <Icon>
          <FontAwesomeIcon icon={faTrash} />
        </Icon>
      </Button>
    </div>
  )

  render() {
    const { wallets } = this.props
    return (
      <Box>
        <Modal isActive={this.state.showModal}>
          <Message danger>
            <Message.Header>
              <p>Eliminar monedero de gastos</p>
              <Delete onClick={this.toggleModal} />
            </Message.Header>
            <Message.Body className="has-text-centered">
              ¿Esta seguro de querer eliminar el monedero?
            <br />
              <strong>{this.state.wallet.noTarjeta}</strong>
              <div className="margin-top-lg">
                <Button danger onClick={this.deleteWallet}>Eliminar</Button>
                <Button default className="margin-left-sm" onClick={this.toggleModal}>
                  Cancelar
              </Button>
              </div>
            </Message.Body>
          </Message>
        </Modal>
        <Columns>
          <Column className="has-text-right">
            <Link className="button is-primary is-outlined" to="/catalogos/monederos-gastos/nuevo">
              <Icon>
                <FontAwesomeIcon icon={faPlus} />
              </Icon>
              <Trans render="span">Agregar monedero de gastos</Trans>
            </Link>
          </Column>
        </Columns>
        <Table
          filterable
          colsetup={colDef}
          coldata={wallets}
          tableClassName='table is-striped is-hoverable is-fullwidth'
          emptyTableMarkUp={(<span>No se encontraron monederos</span>)}
          customCol={{
            customColName: 'actions',
            renderFunc: this.customColActions
          }}
          refresh={this.props.getWallets}
        />
      </Box>
    )
  }
}

const mapStateToProps = ({ catalogs }) => ({
  wallets: catalogs.walletExpense.wallets,
  isFetchingWallets: catalogs.walletExpense.isFetchingWallets
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getWallets,
  push,
  deleteWallet,
  addNotification,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(List)
