import { useRef, useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid'
import { append, isEmpty, pipe, sum, equals, pluck } from 'ramda'

import useArrayForm from 'Hooks/useArrayForm';

import { createBeneficiaries } from 'App/hexmodules/beneficiaries/application/create/createBeneficiaries'
import { getBeneficiaries } from 'App/hexmodules/beneficiaries/application/get/getBeneficiaries'
import { updateBeneficiaries } from 'App/hexmodules/beneficiaries/application/update/updateBeneficiaries'
import { useDependencies } from 'App/DependencyContext';
import { fields } from './definition'
import { addNotification } from 'Modules/principal'
import { useDispatch } from 'react-redux'

const STATE = {
  INITIAL: 'initial',
  FETCHING_BENEFICIARY: 'fetchingBeneficiary',
  FETCHED_BENEFICIARY: 'fetchedBeneficiary',
  ERROR_FETCHING_BENEFICIARY: 'errorFetchingBeneficiary',
  SAVING_BENEFICIARY: 'savingBeneficiary',
  SAVED_BENEFICIARY: 'savedBeneficiary',
  ERROR_SAVING_BENEFICIARY: 'errorSavingBeneficiary',
};

const validatePercentaje = pipe(
  pluck('percent'),
  sum,
  equals(100),
);

const addBeneficiaryType = (beneficiaries, type) => {
  return beneficiaries.map(it => ({ ...it, beneficiary_type: type }))
}
function useBeneficiaries({ refresh }) {
  const state = useRef(STATE.INITIAL);
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [percentageError, setPercentageError] = useState(0)

  const [formValues, setFormValues, handleOnChangeField, isValid, , showErrors, onRemove, , getFormValues, mapNewFields] = useArrayForm(
    {
      fields: [{ ...fields, id: uuid() }],
      fieldsDefinition: fields,
    });

  const [formValues2, setFormValues2, handleOnChangeField2, isValid2, , showErrors2, onRemove2, , getFormValues2, mapNewFields2] = useArrayForm(
    {
      fields: [{ ...fields, id: uuid() }],
      fieldsDefinition: fields,
    });

  const [formValues3, setFormValues3, handleOnChangeField3, isValid3, , showErrors3, onRemove3, , getFormValues3, mapNewFields3] = useArrayForm(
    {
      fields: [{ ...fields, id: uuid() }],
      fieldsDefinition: fields,
    });

  const { beneficiariesRepository } = useDependencies()

  const initialFetch = async () => {
    if (state.current === STATE.INITIAL) {
      setIsLoading(true)
      state.current = STATE.FETCHING_BENEFICIARY;
      const beneficiaries = await getBeneficiaries(beneficiariesRepository);
      if (!isEmpty(beneficiaries)) {
        setIsUpdate(true)
        mapNewFields(beneficiaries.filter(it => +it.beneficiary_type === 1))
        mapNewFields2(beneficiaries.filter(it => +it.beneficiary_type === 2))
        mapNewFields3(beneficiaries.filter(it => +it.beneficiary_type === 3))
      }
      state.current = STATE.FETCHED_BENEFICIARY;
      setIsLoading(false)
    }
  };

  initialFetch();
  const saveBeneficiaries = async (idx) => {
    const isValidBen = isValid()
    const isValidBen2 = isValid2()
    const isValidBen3 = isValid3()
    if (isValidBen && isValidBen2 && isValidBen3) {
      try {
        state.current = STATE.SAVING_BENEFICIARY;
        const beneficiaries = getFormValues();
        const beneficiaries2 = getFormValues2();
        const beneficiaries3 = getFormValues3();
        const isValidPercentaje = validatePercentaje(beneficiaries)
        const isValidPercentaje2 = validatePercentaje(beneficiaries2)
        const isValidPercentaje3 = validatePercentaje(beneficiaries3)

        if (!isValidPercentaje || !isValidPercentaje2 || !isValidPercentaje3) {
          const errorCode = (isValidPercentaje ? 1 : 3) * (isValidPercentaje2 ? 1 : 7) * (isValidPercentaje3 ? 1 : 11)
          setPercentageError(errorCode)
          return
        }
        setIsLoading(true)

        if (isUpdate) {
          await updateBeneficiaries(beneficiariesRepository, { beneficiaries: addBeneficiaryType(beneficiaries, 1).concat(addBeneficiaryType(beneficiaries2, 2)).concat(addBeneficiaryType(beneficiaries3, 3)) })
        } else {
          await createBeneficiaries(beneficiariesRepository, { beneficiaries: addBeneficiaryType(beneficiaries, 1).concat(addBeneficiaryType(beneficiaries2, 2)).concat(addBeneficiaryType(beneficiaries3, 3)) })
        }
        await refresh();

        state.current = STATE.SAVED_BENEFICIARY;
        setIsLoading(false)
        dispatch(addNotification({
          type: 'success',
          message: 'Infomación guardada correctamente',
        }))
      } catch ({ response: { data } }) {
        setIsLoading(false)
        dispatch(addNotification({
          type: 'danger',
          message: data?.error,
        }))
      }

    } else {
      dispatch(addNotification({
        type: 'warning',
        message: 'Validar que todos los campos esté correctos',
      }))
    }
  };

  const getCurrentState = useCallback(() => {
    return state.current;
  }, []);

  const addBeneficiary = () => {
    setFormValues(append({ ...fields, id: uuid() }, formValues))
  }

  const addBeneficiary2 = () => {
    setFormValues2(append({ ...fields, id: uuid() }, formValues2))
  }

  const addBeneficiary3 = () => {
    setFormValues3(append({ ...fields, id: uuid() }, formValues3))
  }

  return {
    saveBeneficiaries,
    getCurrentState,
    values: formValues,
    onChangeField: handleOnChangeField,
    showErrors,
    addBeneficiary,
    onRemove,
    values2: formValues2,
    onChangeField2: handleOnChangeField2,
    showErrors2,
    addBeneficiary2: addBeneficiary2,
    onRemove2,
    values3: formValues3,
    onChangeField3: handleOnChangeField3,
    showErrors3,
    addBeneficiary3: addBeneficiary3,
    onRemove3,
    isLoading,
    percentageError,
  };
}

export default useBeneficiaries;
