import React, { Component } from 'react'
import { Box, Columns, Column, Button, Field, Control } from 'Components/common/bulma'
import { Input, Select, Output } from 'Components/common/form'
import { conceptData } from 'Containers/paymentOrders/salaries/data'
import { getDataInput, mapPropsToState, validateData, getValues } from 'App/helpers'
import { multiply, find, propEq } from 'ramda'
import Autosuggest from 'react-autosuggest'

const renderSuggestion = suggestion => (
  <div>
    {suggestion.nombreConcatenado}
  </div>
)
class Concept extends Component {
  state = {
    collaborators: [],
    value: '',
    data: conceptData,
    showErrors: false,
  }

  getSuggestionValue = suggestion => {
    this.onChangeInput({
      target: {
        name: 'idColaborador',
        value: suggestion.id,
      }
    })
    return suggestion.nombreConcatenado;
  }

  getSuggestions = value => {
    if(!value) {
      return []
    }
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : this.props.collaborators.filter(col =>
      {
      return col.nombreConcatenado.toLowerCase().indexOf(inputValue) !== -1
    }
    );
  };

  onChangeInput = ({ target }) => {
    const { name } = target
    const data = getDataInput(target, this.state.data)
    const field = data[name]

    this.setState({
      collaborators: [],
      data,
      showErrors: false,
      showAccountErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }

  onChangeUnit = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    })
    if (method === 'type') {
      this.onChangeInput({
        target: {
          name: 'idColaborador',
          value: '',
        }
      })
    }
  }
  changeCollaborator = field => {
    if(!field.value) {
      return
    }
    this.props.getCollaboratorsAccounts(field.value, 1)

    const collaborator = find(propEq('id', field.value))(this.props.collaborators)
    const data = mapPropsToState(this.state.data, {colaborador: collaborator.nombreConcatenado})

    this.setState({ data })
  }

  changeCuentaCollaborator = field => {
    const cuenta = find(propEq('id', field.value))(this.props.collaboratorsAccounts)
    const data = mapPropsToState(this.state.data, {cuentaColaborador: cuenta.clabe})

    this.setState({ data })
  }

  onSuggestionSelected = event => {
    event.stopPropagation();
    event.preventDefault();
  }

  getSubtotal = (total, ivaRate) => {

    return total / (1 + (+ivaRate))
  }

  getIva = (subtotal, ivaRate) => {

    return multiply(subtotal, +ivaRate)
  }

  getTotal = (netoPagar, comission) => {

    return netoPagar / (1 - comission)
  }

  updateCalculations = () => {
    const { netoPagar:{value: neto} } = this.state.data
    const { comission, ivaRates } = this.props
    const ivaRate = find(propEq('id', '2'))(ivaRates)

    const total = this.getTotal(neto, comission.comision)
    const subtotal = this.getSubtotal(total, ivaRate.IVA)

    const data = mapPropsToState(this.state.data, {
      total,
      subtotal,
      iva: this.getIva(subtotal, ivaRate.IVA),
    })
    this.setState({ data })
  }

  addConcept = () => {
    const { data, hasErrors } = validateData(this.state.data)
    if (hasErrors) {
      this.setState({
        data,
        showErrors: true,
      })
      this.props.addNotification({
        type: 'danger',
        message: '¡Favor de validar algunos campos!'
      })
      return
    }
    this.props.addConcept(getValues(this.state.data))
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      collaborators: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      collaborators: []
    });
  };

  render() {
    const { data, showErrors, collaborators } = this.state
    const { closeConceptModal, collaboratorsAccounts } = this.props
    return (
      <Box>
        <Columns>
          <Column>
          <Field>
              <label className="label">{data.idColaborador.label}</label>
              <Control>
          <Autosuggest
                  suggestions={collaborators}
                  inputProps={{
                    value: this.state.value,
                    onChange: this.onChangeUnit,
                    className: 'input',
                    placeholder: data.idColaborador.placeholder,
                  }}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  getSuggestionValue={this.getSuggestionValue}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  renderSuggestion={renderSuggestion}
                  onSuggestionSelected={this.onSuggestionSelected}
                />
            </Control>
              {(data.idColaborador.errors && !!data.idColaborador.errors.length && this.state.showErrors) &&
                <p className="has-text-danger help">{data.idColaborador.errors[0].message}</p>}
            </Field>
          </Column>
          <Column>
            <Select
              {...data.idCuentaColaborador}
              options={collaboratorsAccounts}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Input
              {...data.netoPagar}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column>
          </Column>
        </Columns>
        <Columns>
        </Columns>
        <Columns>
          <Column>
            <Output
              {...data.subtotal}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column>
            <Output
              {...data.iva}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Output
              {...data.total}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column>
          </Column>
        </Columns>
        <Columns>
          <Column className="has-text-right">
            <Button default onClick={this.addConcept}>Agregar</Button>
            <Button default className="margin-left-sm" onClick={closeConceptModal}>
              Cancelar
                </Button>
          </Column>
        </Columns>
      </Box>
    )

  }
}

export default Concept