import React, { useState, useCallback } from 'react'
import useForm from 'Hooks/useForm'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { Box, Divider, Columns, Column, Icon } from 'Components/common/bulma'
import Show from 'Components/common/Show'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Address from 'Components/paymentOrders/shipping/address'
import { receiver } from './shippingDefinition'
import http from 'App/services/httpService';
import ShippingService from 'App/services/shippingService';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useMount } from 'ahooks'
import ActionBar from 'Components/common/actionBar';
import { push } from 'connected-react-router'
import { Authorize } from 'Containers/auth'


const shippingService = new ShippingService(http);

function ShippingQuoteForm({
  match,
  pushNavigation,
}) {
  const [order, setOrder] = useState({})
  const [receiverFields, setReceiverFields] = useForm({ fields: receiver })
  const [showAuth, setShowAuth] = useState(false)

  useMount(async () => {
    const { id } = match.params
    const order = await shippingService.getShippingOrder(id)

    setReceiverFields(order.receiver)
    setOrder(order)
  })

  const handleOnCancel = () => {
    pushNavigation('/ordenes-pago/paqueteria')
  }

  const getPermissions = useCallback(() => ({
    canDelete: true,
    canAuthorize: +order.status === 1,
    // eslint-disable-next-line
  }), [order.status])

  const handleOnCancelAuthorization = () => {
    setShowAuth(false)
  }

  const handleOnShowAuthorize = () => {
    setShowAuth(true)
  }

  const handleOnAuthorize = (props) => {
    return shippingService.authorizeOrder({
      id: order.id,
      ...props,
    })
      .then(({ data }) => {
        setShowAuth(false)
        setOrder(data)
      })
  }

  return (
    <Box>
      <ActionBar
        onSave={() => { }}
        onCancel={handleOnCancel}
        onDelete={() => { }}
        basicRole={['Opago de proveedores']}
        authorizeRole={['Apago de proveedores']}
        permissions={getPermissions()}
        onAuthorize={handleOnShowAuthorize}
      />
      <Show canShow={showAuth}>
        <Authorize
          isOpen={showAuth}
          authorize={handleOnAuthorize}
          cancel={handleOnCancelAuthorization}
        />
      </Show>
      <Columns>
        <Column>
          <span>Estatus: <strong>{order.statusName}</strong></span>
        </Column>
        <Column>
          <span>Estatus cancelacion: <strong>{order.cancelationStatus ?? '--'}</strong></span>
        </Column>
      </Columns>
      {(+order.status === 10) && <Columns>
        <Column>
          <span>Motivo de rechazo: <strong>{order.rejectionReason}</strong></span>
        </Column>
      </Columns>}
      <Columns className="is-multiline">
        <Column className="is-half">
          <Box>
            <Columns>
              <Column>
                <h5 className="title is-5">{order.provider}</h5>
                <h5 className="subtitle is-6">{order.service_level_name}</h5>
              </Column>
              <Column>
                <h5 className="title is-4">$ {order.total_pricing}</h5>
              </Column>
            </Columns>
          </Box>
        </Column>
        {+order.status === 2 && <><Column className="has-text-centered is-half">

          {order.label_url &&
            <a href={order.label_url} target="_blank" rel="noopener noreferrer">
              <Icon className="is-size-1">
                <FontAwesomeIcon icon={faFilePdf} />
              </Icon>
              <p>Descargar guia</p>
            </a>
          }
        </Column>
          <Column className="is-half">
            <Box>
              <Columns>
                <Column>
                  <h5 className="title is-5">Tracking Number:</h5>
                </Column>
                <Column>
                  <h5 className="title is-4">{order.tracking}</h5>
                </Column>
              </Columns>
            </Box>
          </Column>
        </>}

      </Columns>

      <Divider content="Datos de la persona que recibe" />
      <Column className="is-half">

      </Column>
      <Address
        addressFields={receiverFields}
      />
    </Box>
  )
}

const mapStateToProps = ({ users }) => ({
})

const mapDispatchToProps = dispatch => bindActionCreators({
  pushNavigation: push,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ShippingQuoteForm)
