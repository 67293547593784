import ForeignFiscalDataRepository from '../domain/foreignFiscalDataRepository';
import httpService from '../../../services/httpService';
import { spreadProp } from 'ramda-adjunct';

export default class RemoteForeignFiscalDataRepository extends ForeignFiscalDataRepository {
  async get() {
    const { data: { data } } = await httpService.get(`/onboarding/tax-data/foreign`);
    return data;
  }

  async save(foreignFiscalData) {
    return await httpService.post(`/onboarding/tax-data/foreign`, spreadProp('address', { ...foreignFiscalData }));
  }
}
