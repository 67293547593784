import React from 'react'
import { Field, Control } from 'Components/common/bulma'
import { Trans } from '@lingui/macro'
import DatePicker from 'react-datepicker'
import moment from 'moment'

export default ({
  label,
  onChange,
  disabled = false,
  error,
  showErrors,
  minDate,
  input = {},
}) => {
  console.log(error, showErrors)
  return (
    <Field>
      <label className="label"><Trans id={label}></Trans></label>
      <Control>
        <DatePicker
          className="input"
          disabled={disabled}
          dateFormat="dd/MM/yyyy"
          minDate={minDate}
          selected={input.value ? moment(input.value).toDate() : undefined}
          onChange={(date) => {
            console.log(date)
            onChange({ target: { value: date, name: input.name, id: input.id } })
          }}
          {...input}
          placeholderText={input.placeholder ? input.placeholder : label}
        />
      </Control>
      {(error && showErrors) && <p className="has-text-danger help">{error}</p>}
    </Field>
  )
}