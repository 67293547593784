import React from 'react'
import {
  Button,
  Input,
  Control,
  Field,
  Box,
  Column,
  Columns,
  Notification,
  Delete,
} from 'Components/common/bulma'

const CambiarContrasena = ({ data = {}, onChangeInput, submitError, showErrors,
  resetError,
  submitting,
  onSubmit,
}) => {
  return (
    <Column className="is-4 is-offset-4">
      <Box>
        <h6 className="subtitle">Cambiar contraseña</h6>
        <form>
          <Field>
            <Control>
              <label>Nueva contraseña:</label>
              <Input
                name="password"
                value={data.password.value}
                onChange={onChangeInput}
                type="password"
                placeholder="Contraseña"
                disabled={submitting}
                minLength="6"
              />
              {(!data.password.valid && showErrors) && (
                <p className="help is-danger">
                  {data.password.errors[0].message}
                </p>
              )}
            </Control>
          </Field><Field>
            <Control>
              <label>Repetir nueva contraseña:</label>
              <Input
                name="passwordMatch"
                value={data.passwordMatch.value}
                onChange={onChangeInput}
                type="password"
                placeholder="Repetir Contraseña"
                disabled={submitting}
                minLength="6"
              />
              {(!data.passwordMatch.valid && showErrors) && (
                <p className="help is-danger">
                  {data.passwordMatch.errors[0].message}
                </p>
              )}
            </Control>
          </Field>
          {submitError && (
            <Notification danger>
              <Delete onClick={resetError} />
              Ha ocurrido un error al intentar cambiar la contraseña, favor de solicitar un nuevo token.
            </Notification>
          )}
          <Button
            type="button"
            onClick={onSubmit}
            disabled={submitting}
            loading={submitting}
            className="is-block is-danger is-fullwidth">
            Cambiar contraseña
                </Button>
        </form>
        <Columns className="is-mobile">
          <Column className="margin-h-md">
            <div className="logo" style={{margin: 'auto'}}>
            </div>
          </Column>
        </Columns>
        <h6 className="has-text-danger margin-h-md has-text-centered">Integradora Labor y Transporte, S.A de C.V</h6>
      </Box>
    </Column>
  )
}

export default CambiarContrasena
