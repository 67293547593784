import http from '../../../services/httpService';
import BeneficiaryRepository from '../domain/beneficiariesRepository';

export default class RemoteBeneficiaryRepository extends BeneficiaryRepository {
  async get() {
    try {
      const { data } = await http.get('/onboarding/beneficiary');
      return data.data;
    } catch (error) {
      console.error('Error retrieving beneficiary:', error);
      throw error;
    }
  }

  async save(beneficiary) {
    try {
      const response = await http.post('/onboarding/beneficiary', beneficiary);
      return response.data;
    } catch (error) {
      console.error('Error creating beneficiary:', error);
      throw error;
    }
  }

  async update(beneficiary) {
    try {
      const response = await http.put(`/onboarding/beneficiary`, beneficiary);
      return response.data;
    } catch (error) {
      console.error('Error updating beneficiary:', error);
      throw error;
    }
  }

  async delete(beneficiaryId) {
    try {
      await http.delete(`/onboarding/beneficiary/${beneficiaryId}`);
    } catch (error) {
      console.error('Error deleting beneficiary:', error);
      throw error;
    }
  }
}
