import { spreadProp } from 'ramda-adjunct'
import FiscalDataRepository from '../domain/fiscalDataRepository';
import httpService from '../../../services/httpService';

export default class RemoteFiscalDataRepository extends FiscalDataRepository {
  async get() {

    const { data: { data } } = await httpService.get(`/onboarding/tax-data`);
    return data
  }
  async save(fiscalData) {
    return await httpService.post(`/onboarding/tax-data`, spreadProp('address', { ...fiscalData }))
  }
}
