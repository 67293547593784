import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Box, Card, Columns, Button, Column, Media,  SubTitle, Icon } from 'Components/common/bulma'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faFilePdf, faFileImage, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { getDocument } from 'Modules/principal'
import { bindActionCreators } from 'redux';
import { saveAs } from 'file-saver'


library.add(faDownload, faFilePdf, faFileImage, faFileAlt)

const icons  = {
  'pdf': 'file-pdf',
  'jpg': 'file-image',
};

export class Documents extends Component {
  downloadFile = ({path, basename}) => {
    this.props.getDocument(path)
      .then(response => {
        saveAs(new Blob([response.data]), basename)
      })
  }

  render() {
    const { files } = this.props
    return (
      <Box>
        <Columns className="is-multiline">
          {
            files.map(it => {
                const icon = icons[it.extension] ? icons[it.extension] : 'file-alt'
                return (<Column className="is-one-third">
                <Card>
                  <Card.Content>
                    <Media>
                      <Media.Left >
                        <Icon className="is-size-3">
                          <FontAwesomeIcon icon={icon} />
                        </Icon>
                      </Media.Left>
                      <Media.Content>
                        <SubTitle is='7'>{it.basename}</SubTitle>
                        <Button small onClick={() => this.downloadFile(it)}>
                          <Icon small>
                            <FontAwesomeIcon icon={faDownload} />
                          </Icon>
                          <span>
                            Descargar
                            </span>
                        </Button>
                      </Media.Content>
                    </Media>
                  </Card.Content>
                </Card>
              </Column>)}
            )
          }
        </Columns>
      </Box >
    )
  }
}

const mapStateToProps = ({ principal }) => ({
  files: principal.documents,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getDocument,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Documents)
