import React, { Component } from 'react'
import { Box, Columns, Column, Button } from 'Components/common/bulma'
import { Input, Output } from 'Components/common/form'
import { getDataInput, mapPropsToState, validateData, getValues } from 'App/helpers'
import {
  multiply, find, propEq, pipe, prop, path, ifElse, equals, filter, reduce, add, useWith as ramdaWith, identity,
  propOr,
} from 'ramda'
import PropTypes from 'prop-types'
import { File } from 'Components/common/form'
import Autocomplete from 'Components/common/form/Autocomplete';
import { toNumber } from 'ramda-adjunct'

const IVA_CODE = '002'
const IEPS_CODE = '003'
const ISR_CODE = '001'
const VALID_RFC = 'ILT160601KT7'

const getSubtotalFromInvoice = xml => {

  const subtotal = pipe(prop('@SubTotal'), Number.parseFloat)(xml)
  const descuento = pipe(propOr(0, '@Descuento'), Number.parseFloat)(xml)

  return subtotal - descuento;
}

const getUuidFromInvoice = xml => {

  const getUuid = path([
    'cfdi:Complemento',
    'tfd:TimbreFiscalDigital',
    '@UUID',
  ])

  return getUuid(xml)
}

const getInvoiceNumber = xml => {
  const serie = prop('@Serie')(xml)
  return `${serie ? serie : ''}${prop('@Folio')(xml)}`
}

const getTrasladadoFromInvoice = (xml, taxCode) => {
  const taxes = path([
    'cfdi:Impuestos',
    'cfdi:Traslados',
    'cfdi:Traslado',
  ])(xml)


  return findImpuestoValue(taxes, taxCode)
}

const findImpuestoValue = (impuestos, code) => {
  if (!impuestos) {
    return 0
  }
  if (Array.isArray(impuestos)) {
    const taxFound = pipe(filter(propEq('@Impuesto', code)), reduce(ramdaWith(add, [identity, pipe(prop('@Importe'), toNumber)]), 0))(impuestos)

    return taxFound ?? 0;
  } else {
    return ifElse(propEq('@Impuesto', code), () => propOr(0, '@Importe')(impuestos), () => 0)(impuestos)
  }
}


const getRetenidoFromInvoice = (xml, impuesto) => {
  const impuestos = path([
    'cfdi:Impuestos',
    'cfdi:Retenciones',
    'cfdi:Retencion',
  ])(xml)

  return findImpuestoValue(impuestos, impuesto)
}

const getIssuingRfc = xml => {
  const getRfc = path([
    'cfdi:Emisor',
    '@Rfc',
  ])

  return getRfc(xml)
}

const getReceiverRfc = xml => {
  const getRfc = path([
    'cfdi:Receptor',
    '@Rfc',
  ])

  return getRfc(xml)
}

const getClaveProductoServicioFromInvoice = xml => {
  return path(['cfdi:Conceptos', 'cfdi:Concepto', '@ClaveProdServ'])(xml)
}

class Concept extends Component {
  static propTypes = {
    providers: PropTypes.array.isRequired,
    dataDef: PropTypes.any.isRequired,
    fetchProviders: PropTypes.func.isRequired,
    resetProviders: PropTypes.func.isRequired,
  }

  state = {
    value: '',
    data: this.props.dataDef,
    showErrors: false,
    invoice: undefined,
    invoicePdf: undefined,
    xml: undefined,
    successUploadedInvoice: false,
    successUploadedPdfInvoice: false,
    errorUploadedInvoice: false,
    errorUploadedPdfInvoice: false,
    uploadingInvoice: false,
    uploadingPdfInvoice: false,
    matchInvoiceRfc: true,
  }

  onChangeInput = ({ target }) => {
    const { name } = target
    if (name === 'idProveedor') {
      const { providers } = this.props
      const curProvider = find(pipe(prop('id'), Number.parseInt, equals(+target.value)))(providers)
      this.setState({ curProvider });
    }
    const data = getDataInput(target, this.state.data)
    const field = data[name]

    this.setState({
      data,
      showErrors: false,
      showAccountErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }

  onChangeInvoice = ({ target }) => {
    const invoice = target.files[0]
    let isXml = invoice?.name?.toLowerCase().endsWith('.xml')

    if (isXml) {
      this.setState({
        invoice,
        matchInvoiceRfc: true,
      })
      return
    }

    this.setState({
      hasInvoiceError: true,
      invoiceMessageError: 'El tipo de archivo admitido es .xml'
    })
  }
  getNetoDepositar = (total, ivaR, isrR) => {
    return total - ivaR - isrR
  }
  onChangeInvoicePdf = ({ target }) => {
    const invoicePdf = target.files[0]
    let isPdf = invoicePdf.name.endsWith('.pdf')

    if (isPdf) {
      this.setState({
        invoicePdf,
        successUploadedInvoice: false,
      })
      return
    }

    this.setState({
      hasInvoicePdfError: true,
      invoiceMessageError: 'El tipo de archivo admitido es .pdf'
    })
  }

  getSubtotal = () => {
    const { cantidad: { value: qty }, valorUnitario: { value: price } } = this.state.data
    return multiply(+qty, +price)
  }

  onChangeFacturaMx = () => {
    this.removeInvoice()
    this.setState({
      matchInvoiceRfc: true,
    })
  }

  updateCalculations = () => {
    const subtotal = prop('value')(this.state.data.subtotal)
    const ieps = prop('value')(this.state.data.ieps)
    const iva = prop('value')(this.state.data.iva)
    const total = +subtotal + (+ieps) + (+iva)

    const ivaRetenido = this.state.data.ivaRetenido.value
    const isrRetenido = this.state.data.isrRetenido.value
    const netoDepositar = this.getNetoDepositar(total,
      ivaRetenido,
      isrRetenido,
    )

    const data = mapPropsToState(this.state.data, {
      unitario: subtotal,
      total,
      netoDepositar,
    })
    this.setState({ data })
  }

  addConcept = () => {
    const { data, hasErrors } = validateData(this.state.data)
    if (hasErrors) {
      this.setState({
        data,
        showErrors: true,
      })
      this.props.addNotification({
        type: 'danger',
        message: '¡Favor de validar algunos campos!'
      })
      return
    }
    if (!this.state.matchInvoiceRfc) {
      return
    }

    this.props.addConcept(getValues(this.state.data))
  }

  removeInvoice = () => {
    this.setState({
      invoice: undefined,
      errorUploadedInvoice: false,
      successUploadedInvoice: false,
      data: mapPropsToState(this.state.data, {
        unitario: '',
        subtotal: '',
        iva: '',
        ieps: '',
        total: '',
        fileNameXml: '',
        noFactura: '',
        uuid: '',
        claveProdServ: '',
      }),
      matchInvoiceRfc: true,
    })
  }

  removeInvoicePdf = () => {
    this.setState({
      invoicePdf: undefined,
      successUploadedPdfInvoice: false,
      errorUploadedPdfInvoice: false,
      matchInvoiceRfc: true,
    })
  }

  uploadInvoice = () => {
    const { value: rfc } = this.state.data.rfc
    let data = new FormData()
    data.append('file', this.state.invoice, this.state.invoice.name)

    this.setState({
      uploadingInvoice: true
    }, () => {
      this.props.uploadInvoice(data)
        .then(response => {
          const xml = JSON.parse(response.dataXML)
          if (rfc && !this.validateRfc({
            issuer: getIssuingRfc(xml),
            receiver: getReceiverRfc(xml)
          })) {
            this.setState({
              matchInvoiceRfc: false,
              uploadingInvoice: false,
            })
            return false
          }
          const unitario = getSubtotalFromInvoice(xml)

          this.setState({
            data: mapPropsToState(this.state.data, {
              unitario,
              subtotal: unitario,
              iva: getTrasladadoFromInvoice(xml, IVA_CODE),
              ieps: getTrasladadoFromInvoice(xml, IEPS_CODE),
              total: unitario + +getTrasladadoFromInvoice(xml, IVA_CODE) + +getTrasladadoFromInvoice(xml, IEPS_CODE),
              fileNameXml: response.nombreArchivo,
              noFactura: getInvoiceNumber(xml),
              uuid: getUuidFromInvoice(xml),
              claveProdServ: getClaveProductoServicioFromInvoice(xml),
              netoDepositar: prop('@Total')(xml),
              isrRetenido: getRetenidoFromInvoice(xml, ISR_CODE),
              ivaRetenido: getRetenidoFromInvoice(xml, IVA_CODE),
            }),
            successUploadedInvoice: true,
            uploadingInvoice: false,
            xml,
          })
        })
        .catch(() => {
          this.setState({
            errorUploadedInvoice: true,
            uploadingInvoice: false,
          })
        })
    })
  }

  validateRfc = ({ issuer, receiver }) => {
    const { value: provider } = this.state.data.rfc
    const { partnerRfc } = this.props

    const isProviderValid = issuer === provider
    const isReceiverValid = receiver === partnerRfc || receiver === VALID_RFC

    return issuer ? isProviderValid && isReceiverValid : true
  }

  uploadInvoicePdf = () => {
    let data = new FormData()

    data.append('file', this.state.invoicePdf, this.state.invoicePdf.name)

    this.setState({
      uploadingPdfInvoice: true,
      showErrors: false,
    }, () => {
      this.props.uploadInvoice(data)
        .then(response => {
          this.setState({
            data: mapPropsToState(this.state.data, {
              fileNamePdf: response.nombreArchivo,
            }),
            successUploadedPdfInvoice: true,
            uploadingPdfInvoice: false,
            errorUploadedPdfInvoice: false,
          })
        })
        .catch(() => {
          this.setState({
            errorUploadedPdfInvoice: true,
            uploadingPdfInvoice: false,
          })
        })
    })
  }

  onChangeProvider = ({ value }) => {
    if (!value) {
      this.setState({
        data: mapPropsToState(this.state.data, {
          razonSocial: '',
          rfc: '',
        })
      })

      return
    }

    const { nombreORazonSocial: razonSocial, rfc } = this.state.curProvider

    this.setState({
      data: mapPropsToState(this.state.data, {
        razonSocial,
        rfc,
      })
    }, () => {
      if (!this.validateRfc({
        issuer: getIssuingRfc(this.state.xml),
        receiver: getReceiverRfc(this.state.xml)
      })) {
        this.setState({
          matchInvoiceRfc: false
        })
      }
    }
    )
  }

  render() {
    const { data, showErrors } = this.state
    const { closeConceptModal, providers } = this.props
    return (
      <Box>
        <Columns>
          <Column>
            <Autocomplete
              suggestions={providers}
              property="nombreORazonSocial"
              showErrors={showErrors}
              onFetchRequested={this.props.fetchProviders}
              onClearRequested={this.props.resetProviders}
              onChange={this.onChangeInput}
              {...data.idProveedor}
            />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Input
              {...data.razonSocial}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Input
              {...data.rfc}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <div className="field">
              <input id="facturaMx" type="checkbox" name="facturaMx"
                className="switch" value={data.facturaMx.value} onChange={this.onChangeInput} />
              <label htmlFor="facturaMx">{data.facturaMx.label}</label>
            </div>
          </Column>
        </Columns>
        {data.facturaMx.value && <Columns className="is-multiline">
          <Column className="is-full">
            <File
              {...data.fileNameXml}
              onChange={this.onChangeInvoice}
              fileName={this.state.invoice && this.state.invoice.name}
              file={this.state.invoice}
              removeFile={this.removeInvoice}
              onClick={this.uploadInvoice}
              isUploading={this.state.uploadingInvoice}
              showErrors={showErrors}
            />
            {
              this.state.showInvoiceError &&
              <div>
                <span className="has-text-danger help">{this.state.invoiceError}</span>
              </div>
            }
            {
              this.state.successUploadedInvoice &&
              <div>
                <span className="has-text-success help">Factura xml cargada correctamente.</span>
              </div>
            }
            {
              this.state.errorUploadedInvoice &&
              <div>
                <span className="has-text-danger help">Ocurrió un error al cargar la factura xml.</span>
              </div>
            }
            {
              this.state.matchInvoiceRfc ||
              <div>
                <span className="has-text-danger help">El rfc del emisor o receptor son diferentes a la factura.</span>
              </div>
            }
          </Column>
        </Columns>}
        <Columns>
          <Column>
            <File
              {...data.fileNamePdf}
              onChange={this.onChangeInvoicePdf}
              fileName={this.state.invoicePdf && this.state.invoicePdf.name}
              file={this.state.invoicePdf}
              removeFile={this.removeInvoicePdf}
              onClick={this.uploadInvoicePdf}
              isUploading={this.state.uploadingPdfInvoice}
              showErrors={showErrors}
            />
            {
              this.state.successUploadedPdfInvoice &&
              <div>
                <span className="has-text-success help">Factura pdf cargada correctamente.</span>
              </div>
            }
            {
              this.state.errorUploadedPdfInvoice &&
              <div>
                <span className="has-text-danger help">Ocurrió un error al cargar la factura pdf, es probable que el archivo esté corrupto.</span>
              </div>
            }
          </Column>
        </Columns>
        <Columns>
          <Column className="is-half">
            <Input
              {...data.noFactura}
              onChange={this.onChangeInput}
              showErrors={showErrors}
              disabled={data.facturaMx.value}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...data.uuid}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Input
              {...data.subtotal}
              onChange={this.onChangeInput}
              showErrors={showErrors}
              disabled={data.facturaMx.value}
            />
          </Column>
          <Column>
            <Input
              {...data.ieps}
              onChange={this.onChangeInput}
              showErrors={showErrors}
              disabled={data.facturaMx.value}
            />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Input
              {...data.iva}
              onChange={this.onChangeInput}
              disabled={data.facturaMx.value}
              showErrors={showErrors}
            />
          </Column>
          <Column>
            <Output
              {...data.total}
              onChange={this.onChangeInput}
            />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Output
              {...data.ivaRetenido}
              onChange={this.onChangeInput}
            />
          </Column>
          <Column>
            <Output
              {...data.isrRetenido}
              onChange={this.onChangeInput}
            />
          </Column>
        </Columns>
        <Columns>
          <Column className="is-half">
            <Output
              {...data.netoDepositar}
              onChange={this.onChangeInput}
            />
          </Column>
        </Columns>
        <Columns>
        </Columns>
        <Columns>
          <Column className="has-text-right">
            <Button default onClick={this.addConcept}>Agregar</Button>
            <Button default className="margin-left-sm" onClick={closeConceptModal}>
              Cancelar
            </Button>
          </Column>
        </Columns>
      </Box>
    )
  }
}

export default Concept