import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { Box, Icon, Columns, Column } from 'Components/common/bulma'
import Table from 'Components/common/table'
import { ModalRemove } from 'Components/common'
import { getList, deleteOrder } from 'Modules/paymentOrders/receiptFee'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Trans } from '@lingui/macro'
import { paymentOrderReceiptFee } from './data'
import { Button } from 'Components/common/bulma'
import { addNotification } from 'Modules/principal'

const CREATED_STATUS = '1'

export class ListaContainer extends Component {
  state = {
    isDeleting: false,
    selectedResource: {},
  }

  onEdit = ({ id }) => {
    const { push } = this.props;
    push(`/ordenes-pago/recibo-honorarios/${id}`);
  }

  deleteOrder = () => {
    this.props.deleteOrder(this.state.selectedResource.id)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'La órden de pago se eliminó correctamente'
        })
        this.setState({
          isDeleting: false,
        })
      })
  }

  onChangePaymentMethod(field) {

  }

  customColAccions = (row) => (
    <Fragment >
      <Button small primary outlined onClick={() => this.onEdit(row)}>
        <Icon>
          <FontAwesomeIcon icon={faEdit} />
        </Icon>
      </Button>
      <Button
        small
        disabled={row.estatus !== CREATED_STATUS}
        className="margin-left-xs" danger outlined onClick={() => this.toggleModal(row)}>
        <Icon>
          <FontAwesomeIcon icon={faTrash} />
        </Icon>
      </Button>
    </Fragment>
  )
  toggleModal = (row) => {
    this.setState(({ isDeleting }) => ({
      isDeleting: !isDeleting,
      selectedResource: row || {}
    }))
  }

  render() {
    const { list } = this.props

    return (
      <Box>
        <ModalRemove
          title="Eliminar orden de pago (Proveedor Con Retenciones)"
          isDeleting={this.state.isDeleting}
          confirmText="¿Esta seguro de querer eliminar la orden de pago (Proveedor Con Retenciones)?"
          toggleModal={this.toggleModal}
          resource={this.state.selectedResource.id}
          deleteResource={this.deleteOrder}
        />
        <Columns>
          <Column>
            <Link className="button is-success is-outlined is-pulled-right" to="/ordenes-pago/recibo-honorarios/nuevo">
              <Icon>
                <FontAwesomeIcon icon={faPlus} />
              </Icon>
              <Trans render="span">Agregar orden de pago (Proveedor Con Retenciones)</Trans>
            </Link>
          </Column>
        </Columns>
        <Table
          filterable
          colsetup={paymentOrderReceiptFee}
          coldata={list}
          tableClassName='table is-striped is-hoverable is-fullwidth'
          emptyTableMarkUp={<span>No hay registros</span>}
          customCol={{
            customColName: 'actions',
            renderFunc: this.customColAccions
          }}
          refresh={this.props.getList}
        />
      </Box>
    )
  }
}

const mapStateToProps = ({ paymentOrders }) => ({
  list: paymentOrders.receiptFee.list,
  isFetching: paymentOrders.receiptFee.isFetchingReceiptFee
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getList,
  push,
  deleteOrder,
  addNotification,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ListaContainer)
