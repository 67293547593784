import React from 'react';
import InputWithError from 'Components/common/form/InputWithError';
import { Box, Columns, Column, Button } from 'Components/common/bulma'
import AddressSection from './addressSection'
import Loader from 'Components/common/page/loaderFloat';
import Show from 'Components/common/Show';
import useForeignFiscalData from './useForeignFiscalData';
import ColumnSelect from 'Components/common/form/ColumnSelect';


const ForeignFiscalData = ({ refresh }) => {
  const { values, onChangeField, saveForeignFiscalData, addressValues, countries, showErrors, allTaxRegime,
    isLoading, handleOnAddressChangeField, showAddressError
  } = useForeignFiscalData({ refresh })

  console.log(addressValues)
  return (
    <Box style={{ position: 'relative' }}>
      <Show canShow={isLoading}>
        <Loader />
      </Show>
      <Columns className="is-multiline">
        <Column className="is-half">
          <InputWithError
            onChange={onChangeField}
            showErrors={showErrors}
            {...values.numregIdTrib}
          />
        </Column>
      </Columns>
      <AddressSection onChange={handleOnAddressChangeField} data={addressValues} countries={countries} showErrors={showAddressError} />
      <Columns>
        <ColumnSelect
          onChange={onChangeField}
          showErrors={showErrors}
          options={allTaxRegime}
          {...values.taxRegime}
        />
      </Columns>
      <Column className="is-full">
        <Button className="is-primary"
          onClick={saveForeignFiscalData}
        >Guardar</Button>
      </Column>
    </Box>
  );
}

export default ForeignFiscalData
