import React from 'react'
import classnames from 'classnames'

const Modal = ({ className, isActive, ...props }) => {
  const classes = classnames('modal',
    {
      'is-active': isActive,
    },
    className
  )
  return (
    <div className={classes} >
      <div className="modal-background"></div>
      <div className="modal-content" {...props} />
    </div>
  )
}

export default Modal