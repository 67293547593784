import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { PaymentOrderRetreatsForm, default as PaymentOrderRetreatsList } from 'Containers/paymentOrders/retreats'

export default () => (
  <Switch>
    <Route exact path="/ordenes-pago/retiros" component={PaymentOrderRetreatsList} />
    <Route exact path="/ordenes-pago/retiros/nuevo" component={PaymentOrderRetreatsForm} />
    <Route exact path="/ordenes-pago/retiros/:id" component={PaymentOrderRetreatsForm} />
  </Switch>
)