import React, { Component } from 'react'
import {
  Box, Columns, Column, Control, Field, SubTitle,
  Divider, Button, Modal, Message, Delete,
} from 'Components/common/bulma'
import { Input, Select, Date as DatePicker, Output } from 'Components/common/form'
import Autosuggest from 'react-autosuggest'
import {
  inventory
} from './data'
import { getDataInput, mapPropsToState, validateData, getValues } from 'App/helpers'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  getProductSat,
  getInvoiceUnits,
  clearInvoiceUnits,
  getIvaRates,
  getIepsRates,
  getProductService,
  clearProductService,
  getEstatusPersona,
} from 'Modules/catalogos'


import {
  getProduct,
  resetConcepts,
} from 'Modules/inventory'
import {
  addProduct,
  updateProduct,
  endOrderEdit,
  removeProduct,
  getProducts,
  findProducts,
  getProduct as getProductOrService,
} from 'Modules/incomeOrders/invoice'
import {
  removeInventory,
  createInventory,
  updateInventory,
  getIncomeConcepts,
  getPaymentConcepts,
} from 'Modules/inventory'
import { push } from 'connected-react-router'
import { addNotification } from 'Modules/principal'
import { prop } from 'ramda'
import { ActionBar, ModalRemove } from 'Components/common'


/* eslint-disable */
function debounce(a, b, c) { var d, e; return function () { function h() { d = null, c || (e = a.apply(f, g)) } var f = this, g = arguments; return clearTimeout(d), d = setTimeout(h, b), c && !d && (e = a.apply(f, g)), e } }
/* eslint-enable */

const renderSuggestionProduct = suggestion => (
  <div>
    {`${suggestion.identificador} - ${suggestion.concepto}`}
  </div>
)

class ProductService extends Component {
  state = {
    value: '',
    valueProduct: '',
    data: inventory,
    showErrors: false,
    query: '',
    isLoading: false,
    isLoadedFromOrder: false,
    isRemoving: false,
  }

  componentDidMount() {
    const {
      getInvoiceUnits,
      getIvaRates,
      getIepsRates,
      getProduct,
    } = this.props

    getInvoiceUnits()
    getIvaRates()
    getIepsRates()
    getEstatusPersona()

    const { id } = this.props.match.params

    if (id) {
      getProduct(id)
    }

  }

  componentWillReceiveProps(nextProps) {
    let data;
    if (this.props.product !== nextProps.product) {
      data = mapPropsToState(this.state.data, {
        ...nextProps.product,
        tasaIva: nextProps.product.tasaIVA,
        tasaIeps: nextProps.product.tasaIEPS,
        inventoryType: +nextProps.product.entrada > 0 ? 'Entrada' : 'Salida'
      })
      this.setState({ data }, () => {
          this.props.getProductOrService(nextProps.product.idProductoServicio)
      })
    }

    if (this.props.productOrService !== nextProps.productOrService) {
      const {estatus, ...productOrService} =nextProps.productOrService
      this.setState({
        data: mapPropsToState(this.state.data, productOrService),
        valueProduct: `${nextProps.productOrService.identificador} - ${nextProps.productOrService.concepto}`
      })
    }
  }

  componentWillUnmount() {
    this.props.endOrderEdit()
  }

  getPermissions = () => {
    return {
      canAuthorize: false,
      canDelete: (this.props.product && this.props.product.id && this.props.product.estatus === '1'),
      canSave: true,
    }
  }

  getProductOrService = ({ value }) => {
    if (value) {
      this.props.getProductOrService(value)
    }
  }

  getSuggestionValueProduct = suggestion => {
    this.onChangeInput({
      target: {
        name: 'idProductoServicio',
        value: suggestion.id,
      }
    })
    return `${suggestion.identificador} - ${suggestion.concepto}`;
  }

  onChangeInput = ({ target }) => {
    const { name } = target
    const data = getDataInput(target, this.state.data)
    const field = data[name]

    this.setState({
      data,
      showErrors: false,
      showAccountErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }

  onChangeClave = (event, { newValue, method }) => {
    this.setState({
      valueProduct: newValue
    })
    if (method === 'type') {
      this.onChangeInput({
        target: {
          name: 'idProductoServicio',
          value: '',
        }
      })
    }
  }

  onCancel = () => {
    this.props.push('/inventarios')
  }

  onSuggestionsFetchRequestedProduct = debounce(({ value }) => {
    this.props.findProducts(value, true)
  }, 200)


  onSuggestionsClearRequestedProduct = () => {
    this.props.clearProductService()
  }

  onSuggestionSelected(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  addProduct = () => {
    const { data, hasErrors } = validateData(this.state.data)
    if (hasErrors) {
      this.setState({
        data,
        showErrors: true,
      })
      this.props.addNotification({
        type: 'danger',
        message: '¡Favor de validar algunos campos!'
      })
      return
    }

    const { saldo, ...product } = getValues(this.state.data)
    const id = prop('id')(this.props.match.params)

    if (!id) {
      this.props.createInventory(product)
        .then(data => {
          this.props.addNotification({
            type: 'success',
            message: 'El concepto se agregó/actualizó exitosamente al catálogo'
          })
          this.props.push(`/inventarios/${data.id}`)
          this.setState({
            data: mapPropsToState(this.state.data, data)
          })
        })
      return
    }

    this.props.updateInventory({ id, ...product })
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'El inventario se agregó/actualizó exitosamente al catálogo'
        })
      })
      .catch(({ response: { data } }) => {
        this.props.addNotification({
          type: 'danger',
          message: data.message,
        })
      })
  }

  delete = () => {
    const { id } = this.props.match.params
    this.props.removeInventory(id)
      .then(data => {
        this.setState({
          isRemoving: false,
          // data: mapPropsToState(this.state.data, data)
        })
        this.props.addNotification({
          type: 'success',
          message: 'El inventario se eliminó correctamente'
        })
        // this.props.push(`/inventarios`)
      })
  }

  openConfirmRemoveModal = () => this.setState({
    isRemoving: true
  })

  closeConfirmRemoveModal = () => this.setState({
    isRemoving: false
  })

  loadFromOrder = () => {
    this.setState({
      isLoading: true,
      isLoadedFromOrder: false,
    })
  }

  closeModal = () => {
    this.setState({
      isLoading: false,
      finder: ''
    })
    this.props.resetConcepts()
  }

  acceptConcept = concept => {
    const { value: type } = this.state.data.inventoryType
    const order = {};

    if (type === 'Entrada') {
      order['ordenPago'] = concept.id;
      order['entrada'] = concept.cantidad
    }

    if (type === 'Salida') {
      order['ordenIngreso'] = concept.id;
      order['salida'] = concept.cantidad

    }

    this.setState({
      data: mapPropsToState(this.state.data, { ...concept, divisa: concept.divisaMoneda, ...order }),
      isLoadedFromOrder: true,
    })
    this.closeModal()
  }

  onChangeFinder = ({ target: { value } }) => {
    const { value: type } = this.state.data.inventoryType

    this.setState({
      finder: value,
    })

    if (type === 'Entrada') {
      this.props.getPaymentConcepts({ query: value })
    }

    if (type === 'Salida') {
      this.props.getIncomeConcepts({ query: value })
    }

  }

  updateSubtotal = () => {
    const {
      inventoryType: { value: type },
      entrada: { value: entrada },
      salida: { value: salida },
      unitario: { value: unitario },
      iva: { value: iva },
      ieps: { value: ieps },
    } = this.state.data

    let subtotal = 0
    let total = 0

    if (type === 'Entrada') {
      subtotal = entrada * unitario
    }

    if (type === 'Salida') {
      subtotal = salida * unitario
    }
    total = subtotal + (+iva) + (+ieps)
    this.setState({ data: mapPropsToState(this.state.data, { subtotal, total }) })
  }

  render() {
    const { data, showErrors, isLoading } = this.state
    const {
      estatusPersona,
      products,
      concepts,
    } = this.props
    return (
      <Box>
        <ModalRemove
          isDeleting={this.state.isRemoving}
          title="Eliminar inventario"
          toggleModal={this.closeConfirmRemoveModal}
          confirmText="¿Esta seguro de querer el inventario?"
          resource={this.props.product.id}
          deleteResource={this.delete}
        />

        <ActionBar
          permissions={this.getPermissions()}
          onSave={this.addProduct}
          onCancel={this.onCancel}
          onDelete={this.openConfirmRemoveModal}
          onAuthorize={this.onAuthorize}
          basicRole={['Cinventarios']}
        />

        <Modal isActive={isLoading}>
          <Message default>
            <Message.Header>
              <p>Buscar orden</p>
              <Delete onClick={this.closeModal} />
            </Message.Header>
            <Message.Body>
              <div>
                <Input
                  label="Buscar concepto"
                  value={this.state.finder}
                  onChange={this.onChangeFinder} />
                <div style={{
                  height: '200px',
                  maxHeight: '200px',
                  overflow: 'scroll',
                  width: '100%',
                  textAlign: 'left',
                }}>
                  {concepts.map(it => <div
                    className="finder-items"
                    style={{
                      border: 'solid 1px lightgray',
                      width: '100%',
                      padding: '5px 0 5px 20px',
                    }} onClick={() => this.acceptConcept(it)}>
                    <p><strong>Order: </strong> {it.id}</p>
                    <p><strong>Concepto: </strong> {it.idConcepto} - {it.concepto}</p>
                    <p><strong>Divisa: </strong> {it.divisaMoneda}</p>
                    <p><strong>Total Concepto: </strong> {it.totalConcepto}</p>
                    <p><strong>Total Orden: {it.total}</strong> </p>
                  </div>)}
                  {
                    !!concepts.length || <p>No se encontraron conceptos</p>
                  }
                </div>
                <Button default className="margin-left-sm" onClick={this.closeModal}>
                  Cancelar
                </Button>
              </div>
            </Message.Body>
          </Message>
        </Modal>
        <SubTitle className="has-text-centered" is="3">Inventario</SubTitle>
        <Columns>
          <Column>
            <Select
              options={estatusPersona}
              showErrors={showErrors}
              {...data.estatus}
            />
          </Column>
          <Column>
            <DatePicker
              {...data.fecha}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
        </Columns>
        <Divider
          content="BUSQUEDA DE PRODUCTO"
        />
        <Columns>
          <Column>
            <Field>
              <label className="label">{data.idProductoServicio.label}</label>
              <Control>
                <Autosuggest
                  suggestions={products}
                  inputProps={{
                    value: this.state.valueProduct,
                    onChange: this.onChangeClave,
                    className: 'input',
                    placeholder: data.idProductoServicio.placeholder,
                  }}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequestedProduct}
                  getSuggestionValue={this.getSuggestionValueProduct}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedProduct}
                  renderSuggestion={renderSuggestionProduct}
                  onSuggestionSelected={this.onSuggestionSelected}
                />
              </Control>
              {(data.idProductoServicio.errors && !!data.idProductoServicio.errors.length && this.state.showErrors) &&
                <p className="has-text-danger help">{data.idProductoServicio.errors[0].message}</p>}
            </Field>
          </Column>
          <Column className="is-half">
            <Input
              {...data.identificador}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
        </Columns>
        <Divider
          content="SALDOS Y MOVIMIENTOS"
        />
        <Columns className="is-multiline">
          <Column className="is-half">
            <Select
              {...data.inventoryType}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          {this.state.data.inventoryType.value === 'Entrada' && <Column>
            <Output {...data.ordenPago} />
            <Button onClick={this.loadFromOrder}> Cargar </Button>
          </Column>}
          {this.state.data.inventoryType.value === 'Salida' && <Column>
            <Output {...data.ordenIngreso} />
            <Button onClick={this.loadFromOrder}> Cargar </Button>
          </Column>}
        </Columns>
        <Columns className="is-multiline">
          {this.state.data.inventoryType.value === 'Entrada' && <Column className="is-half">
            <Input
              {...data.entrada}
              onChange={this.onChangeInput}
              showErrors={showErrors}
              disabled={this.state.isLoadedFromOrder}
            />
          </Column>}
          {this.state.data.inventoryType.value === 'Salida' &&
            <Column className="is-half">
              <Input
                {...data.salida}
                onChange={this.onChangeInput}
                showErrors={showErrors}
                disabled={this.state.isLoadedFromOrder}
              />
            </Column>}
          <Column className="is-half">
            <Input
              {...data.unitario}
              onChange={this.onChangeInput}
              showErrors={showErrors}
              disabled={this.state.isLoadedFromOrder}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...data.subtotal}
              onChange={this.onChangeInput}
              showErrors={showErrors}
              // disabled={this.state.isLoadedFromOrder}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...data.ieps}
              onChange={this.onChangeInput}
              showErrors={showErrors}
              disabled={this.state.isLoadedFromOrder}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...data.iva}
              onChange={this.onChangeInput}
              showErrors={showErrors}
              disabled={this.state.isLoadedFromOrder}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...data.total}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column className="is-half">
            <Select
              {...data.divisa}
              onChange={this.onChangeInput}
              showErrors={showErrors}
              disabled={this.state.isLoadedFromOrder}
            />
          </Column>
          {this.props.match.params.id && <Column className="is-half">
            <Output
              {...data.saldo}
            />
          </Column>}
        </Columns>
      </Box>
    )
  }
}

const mapStateToProps = ({ catalogos, incomeOrders, principal, inventory, router }) => ({
  productSat: catalogos.productSat,
  invoiceUnits: catalogos.invoiceUnits,
  ivaRates: catalogos.ivaRates,
  iepsRates: catalogos.iepsRates,
  productService: catalogos.productService,
  product: inventory.product,
  estatusPersona: catalogos.estatusPersona,
  permissions: principal.inventoryPermissions,
  products: incomeOrders.invoice.products,
  productOrService: incomeOrders.invoice.product,
  concepts: inventory.concepts,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getProductSat,
  getInvoiceUnits,
  clearInvoiceUnits,
  getIvaRates,
  getIepsRates,
  getProductService,
  clearProductService,
  getProduct,
  addProduct,
  push,
  addNotification,
  updateProduct,
  endOrderEdit,
  removeProduct,
  getEstatusPersona,
  removeInventory,
  createInventory,
  getProducts,
  findProducts,
  updateInventory,
  getProductOrService,
  getIncomeConcepts,
  getPaymentConcepts,
  resetConcepts,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ProductService)
