import React from 'react';
import { Box, Columns, Column, Button } from 'Components/common/bulma'
import useGeneralData from './useGeneralData';
import CommonForm from 'Components/common/form/CommonForm';
import ColumnInput from 'Components/common/form/ColumnInput';
import ColumnSelect from 'Components/common/form/ColumnSelect';
import Loader from 'Components/common/page/loaderFloat';
import Show from 'Components/common/Show';

const GeneralData = ({ refresh }) => {
  const {
    phoneCodes,
    values,
    onChangeField,
    showErrors,
    saveGeneralData,
    isLoading,
  } = useGeneralData({ refresh })

  const handleOnSubmit = () => {
    saveGeneralData()
  }

  return (
    <Box style={{ position: 'relative' }}>
      <Show canShow={isLoading}>
        <Loader />
      </Show>
      <CommonForm fields={values} onChangeField={onChangeField} showErrors={showErrors} >
        <ColumnInput name="name" className="is-half" />
        <ColumnInput name="description" className="is-half" />
        <ColumnInput name="web" className="is-half" />
        <ColumnSelect
          className="is-one-fifth"
          options={phoneCodes}
          name="phonecode"
        />
        <ColumnInput
          className="is-quarter"
          name="telephone"
        />
      </CommonForm>
      <Columns>
        <Column className="is-full">
          <Button type="button" className="is-primary"
            onClick={handleOnSubmit}
          >Guardar</Button>
        </Column>
      </Columns>
    </Box>
  );
}

export default GeneralData