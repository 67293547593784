import { cond, always, T, F, } from 'ramda';
import { isEmpty } from 'App/helpers'

export const fields = {
  rfc: {
    input: {
      value: '',
      name: 'rfc',
      disabled: true,
    },
    label: 'RFC',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  idCif: {
    input: {
      value: '',
      name: 'idCif',
      disabled: true,
    },
    label: 'Id CIF',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  taxRegime: {
    input: {
      value: '',
      name: 'taxRegime',
    },
    label: 'Regimen Fiscal',
    valueLabel: 'descripcionCif',
    valueProperty: 'id',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
};

export const addressDefinition = {
  country: {
    input: {
      name: 'country',
      disabled: true,
    },
    label: 'País',
    valueProperty: 'id',
    valueLabel: 'nombre',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  zipCode: {
    input: {
      value: '',
      name: 'zipCode',
      disabled: true,
    },
    label: 'Código Postal',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  state: {
    input: {
      value: '',
      name: 'state',
      disabled: true,
    },
    label: 'Entidad Federativa',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  municipality: {
    input: {
      value: '',
      name: 'municipality',
      disabled: true,
    },
    label: 'Municipio',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  neighborhood: {
    input: {
      value: '',
      name: 'neighborhood',
      disabled: true,
    },
    label: 'Colonia',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  street: {
    input: {
      value: '',
      name: 'street',
      disabled: true,
    },
    label: 'Calle',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  interiorNumber: {
    input: {
      value: '',
      name: 'interiorNumber',
      disabled: true,
    },
    label: 'Número interior',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  number: {
    input: {
      value: '',
      name: 'number',
      disabled: true,
    },
    label: 'Número exterior',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
}