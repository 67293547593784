import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AuthorizeComponent from 'Components/auth/authorize'
import { Modal } from 'Components/common/bulma'
import { dataAuthorize } from './dataAuthorize'
import { getRandomKey } from 'Modules/auth'
import { getDataInput, getValues, validateData } from 'App/helpers'

export class Authorize extends Component {
  state = {
    data: dataAuthorize,
    completions: 0,
    showServerMessage: false,
    showErrors: false,
    serverMessage: '',
    authorizing: false,
  }
  componentDidMount() {
    this.props.getRandomKey()
  }
  refreshRandomKey = () => {
    this.props.getRandomKey()
      .then(() => {
        this.setState(({ completions }) => ({
          completions: completions + 1
        }))
      })
  }
  onChangeInput = ({ target }) => {
    const data = getDataInput(target, this.state.data)

    this.setState({
      data,
      showErrors: false,
      showServerError: false,
    })
  }
  authorize = () => {
    const { data, hasErrors } = validateData(this.state.data)

    if (hasErrors) {
      this.setState({
        showErrors: true,
        data,
      })
      return
    }

    this.setState({ authorizing: true })

    this.props.authorize({ ...getValues(data), tokenRandom: this.props.randomKey.tokenRandom })
      .catch(({ response: { data } }) => {
        console.log(data)
        this.setState({
          showServerError: true,
          serverMessage: data.message,
        })
      })
      .finally(() => {
        this.setState({
          authorizing: false
        })
      })
  }
  hideServerError = () => {
    this.setState({
      showServerError: false
    })
  }

  render() {
    const { isOpen, randomKey } = this.props
    return (
      <Modal isActive={isOpen}>
        <AuthorizeComponent
          data={this.state.data}
          refreshRandomKey={this.refreshRandomKey}
          randomKey={randomKey}
          completions={this.state.completions}
          authorize={this.authorize}
          showErrors={this.state.showErrors}
          showServerError={this.state.showServerError}
          hideServerError={this.hideServerError}
          cancel={this.props.cancel}
          onChange={this.onChangeInput}
          serverMessage={this.state.serverMessage}
          isAuthorizing={this.state.authorizing}
        />
      </Modal>
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  randomKey: auth.randomKey,

})

const mapDispatchToProps = dispatch => bindActionCreators({
  getRandomKey,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Authorize)
