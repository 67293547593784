import React, { useState, useEffect } from 'react'
import { Button, Control, Field } from 'Components/common/bulma'
import { Input } from 'Components/common/form'
import { ModalRemove, InformationModal } from 'Components/common'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createCreditNotePartialAmountAction } from 'Modules/incomeOrders/creditNote'
import numeral from 'numeral'
import { addNotification as addNotificationAction } from 'Modules/principal';
import { validator } from '../../../helpers';


function CancelOrder({
  createCreditNotePartialAmount,
  order,
  options,
  addNotification,
  onClose,
}) {
  const [amount, setAmount] = useState(0)
  const [limit, setLimit] = useState(0)
  const [amountErrors, setAmountErrors] = useState([])
  const [isConfirming, setIsConfirming] = useState(false)
  const [isCanceled, setIsCanceled] = useState(false)
  const [showErrors, setShowErrors] = useState(false)

  useEffect(() => {
    setLimit(+options?.Generar_Nota_de_Credito_por_el_Monto_Especificado?.Monto_Por_Cobrar)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const confirmCancel = () => {
    setShowErrors(true)
    if (isNaN(amount) || !+amount) {
      setAmountErrors([{
        message: 'Monto debe ser mayor a cero'
      }])
      return
    }
    if (+amount > +limit) {
      setAmountErrors([{
        message: 'El monto debe ser menor o igual al monto por cobrar de la factura.'
      }])
      return
    }
    setIsConfirming(true)
  }

  const closeConfirm = () => {
    setIsConfirming(false)
  }

  const handleOnCancel = () => {
    setIsConfirming(false)

    createCreditNotePartialAmount({ monto: +amount }).then(() => {
      setIsCanceled(true)
    }).catch(({ response: { data } }) => {
      addNotification({
        type: 'danger',
        message: data.message,
      })
    })
  }

  const closeCanceledModal = () => {
    setIsCanceled(false)
    onClose(true)
  }

  const handleOnChange = ({ target: { value = '' } }) => {
    setShowErrors(false)
    const validation = validator(value, 'number')

    if (validation.valid) {
      setAmount(value)
    } else {
      setAmountErrors(validation.errors)
    }
  }

  return (
    <>
      <InformationModal
        isOpen={isCanceled}
        closeModal={closeCanceledModal}
        title="Orden cancelada"
        content="Petición recibida, en las próximas horas se emitirá la nota de crédito."
      />
      <ModalRemove
        isDeleting={isConfirming}
        title="Emitir nota de crédito"
        toggleModal={closeConfirm}
        confirmText="Se procederá a crear la nota de crédito por "
        resource={numeral(amount).format('$ 0,0.00')}
        deleteResource={handleOnCancel}
        actionButtonLabel="Emitir"
        cancelButtonLabel="Regresar"
      />
      <div>
        <div>Se Emitirá la nota de crédito por el monto con impuestos incluidos especificado el monto deberá ser menor al monto por cobrar de la factura de
          {` ${numeral(limit).format('$ 0,0.00')}`}</div>
        <div>
          <Field grouped>
            <Control className="is-half">
              <Input
                label="Monto Impuestos Incluidos"
                value={amount}
                onChange={handleOnChange}
                errors={amountErrors}
                showErrors={showErrors}
              />
            </Control>
          </Field>
          <Field className="is-horizontal">
            <Control>
              <Button onClick={confirmCancel}>
                Generar nota de crédito
              </Button>
            </Control>
          </Field>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ incomeOrders: { invoice } }) => ({
  order: invoice.order,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  createCreditNotePartialAmount: createCreditNotePartialAmountAction,
  addNotification: addNotificationAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CancelOrder)
