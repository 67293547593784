import React from 'react';
import ColumnInput from 'Components/common/form/ColumnInput';
import { Box, Columns, Column, Button } from 'Components/common/bulma'
import AddressSection from './addressSection'
import CifLoader from 'Containers/catalogs/components/cifLoader'

import useFiscalData from './useFiscalData';
import ColumnSelect from 'Components/common/form/ColumnSelect';
import Loader from 'Components/common/page/loaderFloat';
import Show from 'Components/common/Show';

const FiscalData = ({ refresh }) => {
  const { values, onChangeField, onCifLoaded, saveFiscalData, addressValues, countries, states, showErrors, allTaxRegime,
    isLoading,
  } = useFiscalData({ refresh })

  return (
    <Box style={{ position: 'relative' }}>
      <Show canShow={isLoading}>
        <Loader />
      </Show>
      <Columns className="is-multiline">
        <Column >
          <CifLoader
            onCifLoaded={onCifLoaded}
          />
        </Column>
        <ColumnInput
          onChange={onChangeField}
          showErrors={showErrors}
          {...values.rfc}
        />
        <ColumnInput
          onChange={onChangeField}
          showErrors={showErrors}
          {...values.idCif}
        />
      </Columns>
      <AddressSection data={addressValues} countries={countries} states={states} />
      <Columns>
        <ColumnSelect
          onChange={onChangeField}
          showErrors={showErrors}
          options={allTaxRegime}
          {...values.taxRegime}
        />
      </Columns>
      <Column className="is-full">
        <Button className="is-primary"
          onClick={saveFiscalData}
        >Guardar</Button>
      </Column>
    </Box>
  );
}

export default FiscalData