import React from 'react'
import { Field, Control } from 'Components/common/bulma'
import classnames from 'classnames'


export default ({
  label,
  onChange,
  disabled = false,
  className = '',
  showFieldLabel = true,
  controlClass = '',
  ...input
}) => {
  const classes = classnames('switch', {}, className);

  return (
    <Field>
      <label className="label">{showFieldLabel && label}</label>
      <Control className={controlClass}>
        <input
          className={classes}
          id={input.name}
          type="checkbox"
          onChange={onChange}
          disabled={disabled}
          checked={input.value === true}
          {...input}
        />
        <label htmlFor={input.name}>{label}</label>
      </Control>
    </Field>)
}