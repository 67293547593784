import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { Box, Icon, Column, Columns, Button } from 'Components/common/bulma'
import { default as Table } from 'Components/common/table'
import {
  getResources as getLenders,
  deleteResource as deleteLender,
} from 'Modules/catalogs/lenders'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'
import { Trans } from '@lingui/macro'
import { colDef } from './lendersConfig'
import { ModalRemove } from 'Components/common'

export class List extends Component {
  state = {
    showModal: false,
    resource: {}
  }

  onEdit = ({ id }) => {
    this.props.push(`/catalogos/prestamistas/${id}`)
  }

  toggleModal = (resource) => {
    this.setState(state => ({
      showModal: !state.showModal,
      resource,
    }))
  }

  deleteResource = () => {
    this.props.deleteLender(this.state.resource.id)
      .then(() => {
        this.setState(() => ({
          showModal: false
        }))
      })
  }

  customColActions = (row) => (
    <div>
      <Button small primary outlined onClick={() => this.onEdit(row)}>
        <Icon>
          <FontAwesomeIcon icon={faEdit} />
        </Icon>
      </Button>
      <Button small danger outlined onClick={() => this.toggleModal(row)} className="margin-left-xs">
        <Icon>
          <FontAwesomeIcon icon={faTrash} />
        </Icon>
      </Button>
    </div>
  )
  render() {
    const { lenders } = this.props

    return (
      <Box>
        <ModalRemove
          isDeleting={this.state.showModal}
          title="Eliminar prestamista"
          toggleModal={this.toggleModal}
          confirmText="¿Esta seguro de querer eliminar el prestamista?"
          resource={this.state.resource.name}
          deleteResource={this.deleteResource}
        />
        <Columns>
          <Column className="has-text-right">
            <Link className="button is-primary is-outlined" to="/catalogos/prestamistas/nuevo">
              <Icon>
                <FontAwesomeIcon icon={faPlus} />
              </Icon>
              <Trans render="span">Agregar Prestamista</Trans>
            </Link>
          </Column>
        </Columns>
        <Table
          filterable
          colsetup={colDef}
          coldata={lenders}
          tableClassName='table is-striped is-hoverable is-fullwidth'
          emptyTableMarkUp={(<span>No se encontraron prestamistas</span>)}
          customCol={{
            customColName: 'actions',
            renderFunc: this.customColActions
          }}
          refresh={this.props.getLenders}
        />
      </Box>
    )
  }
}

const mapStateToProps = ({ catalogs }) => ({
  lenders: catalogs.lenders.resources,
  isFetching: catalogs.lenders.isFetchingLenders
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getLenders,
  push,
  deleteLender,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(List)
