import { flattenProp } from 'ramda-adjunct'
import { map } from 'ramda'
export default class ShippingService {
  constructor(httpService) {
    this.http = httpService;
  }

  async getNoteCategories() {
    try {
      const { data } = await this.http.get('/shipping/note-categories');

      return map(flattenProp('attributes'))(data);
    } catch (error) {
      return [];
    }
  }

  async getNoteSubCategories(categoryId) {
    try {
      const { data } = await this.http.get(`/shipping/note-subcategories/${categoryId}`);

      return map(flattenProp('attributes'))(data);
    } catch (error) {
      return [];
    }
  }

  async getNoteClasses(subCategoryId) {
    try {
      const { data } = await this.http.get(`/shipping/note-classes/${subCategoryId}`);

      return map(flattenProp('attributes'))(data);
    } catch (error) {
      return [];
    }
  }

  async getPackagings() {
    try {
      const { data } = await this.http.get('/shipping/note-packagings');

      return map(flattenProp('attributes'))(data);
    } catch (error) {
      return [];
    }
  }

  async createShipment(shipment) {
    try {
      const { data } = await this.http.post('/shipping/shipment', shipment);

      return data
    } catch (error) {
      return [];
    }
  }

  async createShippingOrder(shipment) {
    try {
      const { data } = await this.http.post('/shipping/shipping-order', shipment);

      return data;
    } catch (error) {
      return [];
    }
  }

  async getShippingOrder(orderId) {
    try {
      const { data } = await this.http.get(`/shipping/shipping-order/${orderId}`);

      return data;
    } catch (error) {
      return [];
    }
  }

  async getShippingOrders(params) {
    try {
      const { data } = await this.http.get('/shipping/shipping-order', { params });

      return { data: data.data, ...data.meta, ...data.links };
    } catch (error) {
      return [];
    }
  }

  async authorizeOrder({ id, ...body }) {
    return await this.http.post(`/shipping/approve-order/${id}`, body);
  }

  async cancelShipping(orderId, params) {
    return await this.http.delete(`/shipping/shipping-order/${orderId}`, { data: params });
  }
}
