import { cond, always, T, F, } from 'ramda';
import { isEmpty, isCurpInvalid, isRfcInvalid } from 'App/helpers'

export const fields = {
  id_legal: {
    input: {
      value: '',
      name: 'id_legal',
    },
    label: 'id_legal',
  },
  name: {
    input: {
      value: '',
      name: 'name',
    },
    label: 'Nombre',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  lastName: {
    input: {
      value: '',
      name: 'lastName',
    },
    label: 'Apellido Paterno',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  motherLastName: {
    input: {
      value: '',
      name: 'motherLastName',
    },
    label: 'Apellido Materno',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  gender: {
    input: {
      value: '',
      name: 'gender',
    },
    label: 'Género',
    options: [{ label: 'Femenino', value: 'Femenino' }, { label: 'Masculino', value: 'Masculino' }],
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  rfc: {
    input: {
      value: '',
      name: 'rfc',
      maxLength: 13,
    },
    label: 'RFC',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [isRfcInvalid, always({ error: 'El formato del RFC es incorrecto' })],
      [T, F]
    ]),
  },
  curp: {
    input: {
      value: '',
      name: 'curp',
      maxLength: 18,
    },
    label: 'CURP',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [isCurpInvalid, always({ error: 'El formato de la CURP es inválido' })],
      [T, F]
    ]),
  },
  email: {
    input: {
      value: '',
      name: 'email',
    },
    label: 'Correo Electrónico',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  phone: {
    input: {
      value: '',
      name: 'phone',
    },
    label: 'Teléfono',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  idDocument: {
    input: {
      value: '',
      name: 'idDocument',
      accept: 'application/pdf',
    },
    label: 'Identificación Oficial (INE o Pasaporte)',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  taxSituation: {
    input: {
      value: '',
      name: 'taxSituation',
      accept: 'application/pdf',
    },
    label: 'Constancia de Situación Fiscal ',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  document_curp: {
    input: {
      value: '',
      name: 'document_curp',
      accept: 'application/pdf',
    },
    label: 'CURP',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  attorneyPower: {
    input: {
      value: '',
      name: 'attorneyPower',
      accept: 'application/pdf',
    },
    label: 'Poder Notarial con Actos de Dominio',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },

};
