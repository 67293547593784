import React from 'react'
import {
  Field, Control, Notification, Delete, Box,
  Column,
  Columns,
  Button,
} from 'Components/common/bulma'
import { Input } from 'Components/common/form'

const Authorize = ({
  data,
  showErrors,
  showServerError,
  onChange,
  hideServerError,
  authorize,
  cancel,
  serverMessage,
  isAuthorizing,
}) => {
  return (
    <Columns>
      <Column className="is-half is-offset-3">
        <Box>
            <Input
              {...data.usuario}
              onChange={onChange}
              showErrors={showErrors}
            />
            <Input
              {...data.password}
              onChange={onChange}
              showErrors={showErrors}
            />
            <Field className="is-horizontal">
              <div className="field-body">
                <Input
                  {...data.token}
                  onChange={onChange}
                  showErrors={showErrors}
                />
              </div>
            </Field>
            { showServerError && (<Notification danger>
              <Delete onClick={hideServerError} />
              {serverMessage || 'Tu usuario y/o contraseña no son válidos, ó el token ha expirado.'}
            </Notification>)}
          <Control className="has-text-centered">
            <Button
              className={`${isAuthorizing ? 'is-loading' : ''}`}
              danger
              onClick={authorize}
              disabled={isAuthorizing}
            >
              Autorizar
             </Button>
            <Button
              disabled={isAuthorizing}
              onClick={cancel}
              className="margin-left-sm"
              type="button"
             >
            Cancelar
            </Button>
          </Control>
        </Box>

      </Column>
    </Columns>
  )
}

export default Authorize
