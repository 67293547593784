import {
  clone, range, is, map, gt, lt, or, pipe, length, equals,
  test, trim, not, split, any, identity, lte, gte, curry,
  mapObjIndexed, ifElse, applyTo, flip, prop, replace,
  endsWith, toUpper, subtract, add,
} from 'ramda'
import { TIPO_PERSONA_MORAL } from './constants'
import moment from 'moment'
import { isFunction, toNumber, inRange } from 'ramda-adjunct'
import { isNilOrEmptyString, isNumeric } from 'ramda-extension'

export const validateData = (data) => {
  const fields = Object.keys(data);
  const dataWithErrors = {};
  const result = fields.map(field => {
    if (data[field].validation) {
      const valid = validator(data[field].value, data[field].validation, data);
      dataWithErrors[field] = { ...data[field], ...valid }
      return valid
    }
    dataWithErrors[field] = { ...data[field], valid: true }
    return { valid: true }
  })

  return { data: dataWithErrors, hasErrors: !result.every(it => it.valid) }
}

export const validator = (value, rules, data) => {
  const rulesArr = rules.split('|')
  const errors = rulesArr.map(rule => {
    const ruleWithParams = rule.split(':')
    return {
      rule: ruleWithParams[0],
      ...rulesDef[ruleWithParams[0]](value, ruleWithParams.length ?
        ruleWithParams[1] : undefined, data)
    }
  })

  return { valid: errors.every(it => it.valid), errors: errors.filter(it => !it.valid) }
}

const rulesDef = {
  number: (value) => {

    return { valid: isNumeric(value), message: '¡El valor no és un número!' }
  },
  required: value => {

    return { valid: !isNilOrEmptyString(value), message: '¡El valor es requerido!' }
  },
  upload: value => {

    return { valid: !!value, message: '¡Favor de confirmar que el archivo haya sido cargado!' }
  },
  isLength: (value, param) => {
    if (!param) {
      return true
    }

    return {
      valid: value ? (value.length === +param) : true,
      message: `¡El texto debe ser de ${param} caracteres!`
    }
  },
  minLength: (value, param) => {
    if (!param) {
      return true
    }

    return {
      valid: value ? (value.length >= +param) : true,
      message: `¡El texto debe tener una longitud mínima de ${param} caracteres!`
    }
  },
  match: (value, param, data) => {

    return { valid: value === data[param].value, message: `¡Los valores ingresados no coinciden!` }
  },
  requiredIfCustom: (value, param, data) => {
    const { depends, dependsValue } = data[param]
    const { value: toValidate } = data[depends]
    const valid = dependsValue === toValidate ? !!value : true

    return { valid, message: '¡El valor es requerido!' }
  },
  requiredIfNotCustom: (value, param, data) => {
    const { depends, dependsValue } = data[param]
    const { value: toValidate } = data[depends]
    const valid = dependsValue !== toValidate ? !!value : true

    return { valid, message: '¡El valor es requerido!' }
  },
  requiredIfNotCustomNumber: (value, param, data) => {
    const { depends, dependsValue } = data[param]
    const { value: toValidate } = data[depends]
    const valid = dependsValue !== +toValidate ? !!value : true

    return { valid, message: '¡El valor es requerido!' }
  },
  requiredIf: (value, param, data) => {

    return { valid: data[param].value ? !!value : true, message: 'El valor es requerido' }
  },
  uploadedIf: (value, param, data) => {

    return { valid: data[param].value ? !!value : true, message: '¡Favor de confirmar que el archivo haya sido cargado!' }
  },
  email: (value) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return { valid: emailRegex.test(value), message: '¡El valor debe ser un correo electrónico válido!' }
  },
  emails: (values, param) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid = values.split(param).every(it => {
      return !!it ? emailRegex.test(it) : true;
    })

    return { valid: isValid, message: '¡Los valores deben ser un correos electrónicos válidos!' }
  },
  rfc: (value = '', param, data) => {
    let letters, digits
    const regexLetters = /^[a-zA-Z]*$/
    let validLength = false;
    if (param && +data[param].value === TIPO_PERSONA_MORAL) {
      letters = value.substr(0, 3)
      digits = value.substr(3, 6)
      validLength = value.length === 12
    } else {
      letters = value.substr(0, 4)
      digits = value.substr(4, 6)
      validLength = value.length === 13

    }

    const isValid = regexLetters.test(letters) && !isNaN(digits) && validLength

    return { valid: isValid, message: '¡El valor del rfc es incorrecto!' }
  },
  clabe: (value) => {
    const digits = Array.from(value)
    const checkDigit = digits.pop()

    const checkSum = (10 - (digits.reduce((acc, cur, idx) => {
      return acc + (([3, 7, 1][idx % 3] * +cur) % 10)
    }, 0) % 10)) % 10
    const isValid = +checkDigit === checkSum

    return { valid: value ? isValid : true, message: '¡La clabe interbancaria es incorrecta!' }
  },
  curp: (value) => {
    const curpReg = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/
    const isValid = value ? curpReg.test(value.toUpperCase()) : true;

    return { valid: value ? isValid : true, message: '¡El curp introducido no es válido!' }
  },
  greaterThan: (value, param) => {
    return { valid: gt(+value, +param), message: `El valor debe ser mayor que ${param}` }
  },
  greaterOrEqualThan: (value, param) => {
    return { valid: gte(+value, +param), message: `El valor debe ser mayor que ${param}` }
  },
  lessThan: (value, param) => {
    return { valid: lt(+value, +param), message: `El valor debe ser menor que ${param}` }
  },
  lessThanField: (value, param, data) => {
    return { valid: lt(+value, +data[param].value), message: `El valor debe ser menor que ${+data[param].value}` }
  },
  greaterThanDate: (value, param) => {
    return { valid: gt(value, param), message: `La fecha debe ser mayor a ${moment(param).format('DD/MM/YYYY')}` }
  },
  gtOrEqDate: (value, param) => {
    return { valid: or(gt(moment(moment(value).format('YYYY-MM-DD')), moment(param)), param === moment(value).format('YYYY-MM-DD')), message: `La fecha debe ser mayor o igual a ${moment(param).format('DD/MM/YYYY')}` }
  },
  aduana: (value) => {
    const aduanaReg = /^\d{2}\s{2}\d{2}\s{2}\d{4}\s{2}\d{6,7}$/
    const isValid = aduanaReg.test(value)

    return { valid: value ? isValid : true, message: '¡El formato es invalido usar “00  00  0000  0000000” o “00 00 0000 000000”!' }
  },
  isValidName: (value) => {

    return { valid: validateName(value), message: 'La Razon Social no debe contener el Régimen Societario' }
  },
  isBetween70: (value, param) => {

    const toCompare = toNumber(param)
    const valueToValidate = toNumber(value)
    return { valid: pipe(inRange(subtract(toCompare, .7), add(toCompare, .7)))(valueToValidate), message: `El tipo de cambio no debe ser muy mayor o muy menor al valor de referencia ${toCompare}` }
  },
  orIsrRetenido: (value, param, data) => {
    const tasaIva = data['isrRetenido'].value ? +data['isrRetenido'].value : 0
    console.log(tasaIva, value, data)

    return { valid: (tasaIva || (value ? +value : 0)), message: `Debes capturar IVA Retenido o ISR Retenido` }
  },
  orIvaRetenido: (value, param, data) => {
    const tasaIva = data['ivaRetenido'].value ? +data['ivaRetenido'].value : 0

    return {
      valid: (tasaIva || (value ? +value : 0)), message: `Debes capturar IVA Retenido o ISR Retenido`
    }
  },
}

const transformDef = {
  stringToBool: value => {

    return value === 'true' ? true : false;
  },
  stringToNumber: value => {

    return !value ? 0 : +value
  },
}

export const getValues = (data) => {
  let extractedValues = {}

  Object.keys(data).forEach(it => {
    if (data[it].same) {
      extractedValues[it] = data[data[it].same].value
    } else if (data[it].dataMarshaller) {
      extractedValues[it] = data[it].dataMarshaller(data[it].value)
    } else {
      extractedValues[it] = data[it].value
    }
  })
  return extractedValues
}

export const getDataInput = ({ name, value, type, checked, ...rest }, data) => {
  let validateField = true
  let values = clone(data)
  let transformed;
  const fieldValue = (type === 'checkbox') ? checked : value

  validateField = data[name].pattern ? validator(fieldValue, data[name].pattern).valid : true
  transformed = data[name].transform ? transformDef[data[name].transform](fieldValue) : fieldValue
  values[name].value = validateField ? transformed : data[name].value

  return values
}

export const mapPropsToState = (state, ownProps) => {
  const fields = Object.keys(state)
  const data = clone(state)

  fields.forEach(it => {
    if (ownProps.hasOwnProperty(it)) {
      data[it].value = data[it].dataUnmarshaller ? data[it].dataUnmarshaller(ownProps[it]) : ownProps[it]
    }
  })

  return data
}



export const getLastyears = (lastYears) => {
  const currYear = new Date().getFullYear();
  const rangeYears = range((currYear - lastYears), currYear);

  return rangeYears.map(year => ({ value: year, label: year }));
}

export const getClabeData = (clabe = '') => {
  const bank = clabe.substr(1, 3)
  const place = clabe.substr(4, 3)
  const account = clabe.substr(6, 11)
  return { bank, place, account }
}

export const transformData = (cmd, data) => {
  return map(transformer => {
    return is(Function, transformer) ? transformer(data) : transformer
  })(cmd)
}

export const isEmpty = isNilOrEmptyString

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isNotEmail = pipe(trim, test(emailRegex), not)

export const isNotEmails = pipe(split(';'), map(isNotEmail), any(identity))

export const isMinLength = stringLength => pipe(String, trim, length, lte(stringLength), not)

export const isLength = stringLength => pipe(String, trim, length, equals(stringLength), not)

export const isLenEquals = stringLength => pipe(String, trim, length, equals(stringLength))

export const isString = number => isNaN(+number)

export const lessThan = value => pipe(toNumber, gte(value), not)


export const isCurpInvalid = (value) => {
  const curpReg = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/
  const isValid = value ? curpReg.test(value.toUpperCase()) : true;

  return !isValid
}

export const isRfcInvalid = (value = '', param, data) => {
  let letters, digits
  const regexLetters = /^[a-zA-Z]*$/
  const validLength = value.length === 13;
  letters = value.substr(0, 4)
  digits = value.substr(4, 6)

  const isValid = regexLetters.test(letters) && !isNaN(digits) && validLength

  return !isValid
}

export const isInvalidClabe = (value) => {
  const digits = Array.from(value)
  const checkDigit = digits.pop()

  const checkSum = (10 - (digits.reduce((acc, cur, idx) => {
    return acc + (([3, 7, 1][idx % 3] * +cur) % 10)
  }, 0) % 10)) % 10
  const isValid = +checkDigit === checkSum

  return !isValid
}

const avoidNames = [
  ' ABP',
  ' AC',
  ' AR',
  ' ARIC DE RL DE CV',
  ' IAP',
  ' S DE RL',
  ' S DE RL DE CV',
  ' S DE RL MI',
  ' S EN NC',
  ' SA',
  ' SA DE CV',
  ' SA DE CV SOFOM ENR',
  ' SA DE CV SOFOM ER',
  ' SA DE RL DE CV',
  ' SA INSTITUCIÓN DE BANCA MÚLTIPLE',
  ' SAB DE CV',
  ' SAPI DE CV',
  ' SAPI DE CV SOFOM ENR',
  ' SAS',
  ' SAS DE CV',
  ' SC',
  ' SC DE AP DE RL',
  ' SC DE AP DE RL DE CV',
  ' SC DE RL',
  ' SC DE RL DE CV',
  ' SPR DE RL',
  ' SPR DE RL DE CV'
];

const flipedAny = flip(any)
const flipedEndsWith = flip(endsWith)
export const validateName = pipe(
  replace(/[.,]/g, ''),
  toUpper,
  flipedEndsWith,
  flipedAny(avoidNames),
  not,
)

export const evolveCustom = curry((spec, source) => mapObjIndexed((value, key) => ifElse(
  isFunction,
  applyTo(source),
  flip(evolveCustom)(prop(key, source)),
)(value))(spec));

export const makeString = (cadenas, ...claves) => ((...valores) => {
  const diccio = valores[valores.length - 1] || {};
  const resultado = [cadenas[0]];
  claves.forEach((clave, i) => {
    const valor = Number.isInteger(clave) ? valores[clave] : diccio[clave];
    resultado.push(valor, cadenas[i + 1]);
  });
  return resultado.join('');
});
