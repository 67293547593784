import { useState, useMemo } from 'react'
import {
  ifElse, propEq, prop, lensPath,
  set, map, path, propOr,
  converge, call, always, mergeDeepRight, identity,
  pipe, any, values, omit, mapObjIndexed, curryN, __, T, evolve, flatten, reject, isNil, pathOr,
} from 'ramda'
import { toNumber } from 'ramda-adjunct'
import { v4 as uuid } from 'uuid'

const getValue = (target) => {
  const getValueFromInputType = ifElse(propEq('type', 'checkbox'), prop('checked'), prop('value'))
  return getValueFromInputType(target)
}

const getIndex = pipe(prop('id'), toNumber)
const getName = prop('name')

const useArrayForm = ({ fields, fieldsDefinition }) => {
  const [fieldsValue, setFieldsValue] = useState(fields);
  const [showErrors, setShowErrors] = useState(false);

  const handleOnChangeField = ({ target }) => {
    console.log(target, 'array form')
    const valueLens = lensPath([getIndex(target), getName(target), 'input', 'value'])
    const getPattern = pipe(prop(getIndex(target)), prop(getName(target)), propOr(T, 'pattern'))

    const newValue = getValue(target)
    const pattern = getPattern(fieldsValue)
    const isMatch = pattern(newValue);

    if (isMatch) {
      const setNewFieldsValue = set(valueLens, newValue)
      setFieldsValue(val => {
        const nuevosvalores = setNewFieldsValue(val)
        return nuevosvalores
      })
    }
    setShowErrors(false)
  }

  const getValues = () => {
    return map(evolve({ rol: path(['input', 'value']), correo: path(['input', 'value']) }))(fieldsValue)
  }

  const getFormValues = () => {
    const marshall = converge(call, [propOr(identity, 'marshall'), pathOr('', ['input', 'value'])]);
    return map(map(marshall))(fieldsValue)
  }

  const isValid = () => {
    const getValidator = (field, key, form) => {
      const validator = propOr(always(always({ error: undefined })), 'validate')(field);
      const curryValidator = curryN(3, validator)

      return curryValidator(__, key, form)
    }
    const getFieldValue = path(['input', 'value'])
    const validate = converge(call, [getValidator, getFieldValue])
    const mergeWithErrors = converge(mergeDeepRight, [omit(['error']), validate])
    const fieldIsValid = pipe(map(pipe(prop('error'), val => !!val)), values)
    const mapErrors = pipe(map(fieldIsValid), flatten)

    let hasErrors = false;

    const newItemValues = mapObjIndexed(mergeWithErrors)
    const newValues = map(newItemValues)(fieldsValue)
    const mappedErrors = mapErrors(newValues)

    hasErrors = any(identity, values(mappedErrors))

    setShowErrors(hasErrors)
    setFieldsValue(newValues)

    return !hasErrors
  }

  const mapFields = (values) => {
    const valueLens = lensPath(['input', 'value'])
    const getUnmarshaller = propOr(identity, 'unmarshaller')
    const setNewFieldsValue = set(valueLens)

    const unmarshall = (field, key) => {
      const getValue = propOr(undefined, key)
      const unmarshaller = getUnmarshaller(field)
      const value = getValue(values)
      let newFieldValue = field;

      if (isNil(value)) {
        return field
      }

      if (unmarshaller) {
        newFieldValue = setNewFieldsValue(unmarshaller(value), field)
      }

      return newFieldValue
    }
    const temp = mapObjIndexed(unmarshall, { ...fieldsDefinition, id: uuid() })

    return temp
  }
  const mapNewFields = (values) => {
    const valueLens = lensPath(['input', 'value'])
    const getUnmarshaller = propOr(identity, 'unmarshaller')
    const setNewFieldsValue = set(valueLens)

    const unmarshall = (field, key) => {
      const getValue = propOr(undefined, key)
      const unmarshaller = getUnmarshaller(field)
      const value = getValue(values)
      let newFieldValue = field;

      if (isNil(value)) {
        return field
      }

      if (unmarshaller) {
        newFieldValue = setNewFieldsValue(unmarshaller(value), field)
      }

      return newFieldValue
    }
    const temp = mapObjIndexed(unmarshall, fieldsDefinition)

    return temp
  }

  const eachFields = (values) => {
    setFieldsValue(map(mapFields, values))
  }
  const eachNewFields = (values) => {
    setFieldsValue(map(mapNewFields, values))
  }

  const setFields = (values) => {
    setFieldsValue(values)
  }

  const onRemove = (row) => {
    const getId = propOr('', 'id')
    const isIdEquals = propEq('id', getId(row))
    const rejectId = reject(isIdEquals)

    setFieldsValue(rejectId(fieldsValue))
  }

  return [
    fieldsValue,
    setFields,
    // eslint-disable-next-line
    useMemo(() => handleOnChangeField, []),
    isValid,
    getValues,
    showErrors,
    onRemove,
    eachFields,
    getFormValues,
    eachNewFields,
  ];
}

export default useArrayForm
