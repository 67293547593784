import React, { Component } from 'react'
import { Box, Columns, Column, Button, Field } from 'Components/common/bulma'
import { Output, Input, Select } from 'Components/common/form'
import { getDataInput, validateData, getValues, mapPropsToState } from 'App/helpers'
import { installment } from 'Containers/paymentOrders/walletGasoline/data'
import DatePicker from 'react-datepicker'
import moment from 'moment'

const INSTALLMENT_PARTIAL_AUTHORIZED = 9
const ESTATUS_CREATED = 1

export default class Installment extends Component {
  state = {
    data: installment,
    showErrors: false,
  }

  componentDidMount() {
    this.setState({
      data: mapPropsToState(this.state.data, this.props.orderData)
    }, () => {
      this.updateClabe()
    })
  }

  onChangeInput = ({ target }) => {
    const { name } = target 
    const data = getDataInput(target, this.state.data)
    const field = data[name]

    this.setState({
      data,
      showErrors: false,
      showAccountErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }

  updateClabe = () => {
    const { integrado, idOrden } = this.props.orderData
    const transferKey = `Int${integrado}IdOpago${idOrden}P1T${Number.parseInt(this.state.data.cargo.value, 10)}`

    this.onChangeInput({ target: { name: 'claveRastreo', value: transferKey.substr(0, 30) } })

  }
  addInstallment = () => {
    const { data, hasErrors } = validateData(this.state.data)
    if (hasErrors) {
      this.setState({
        data,
        showErrors: true,
      })
      this.props.addNotification({
        type: 'danger',
        message: '¡Favor de validar algunos campos!'
      })
      return
    }
    this.props.addInstallment(getValues(this.state.data))
  }

  render() {
    const { data } = this.state
    const { closeInstallmentModal, orderData, paymentEstatus, realPaymentMethod, providerAccounts } = this.props
    return (
      <Box>
        <h7>La comisión del proveedor se verá reflejada una vez que se
transaccione la operación, tiene un costo aproximado del 0.58%
del total de la operación. Al autorizar estarás aceptando dicho
cargo a tu saldo.</h7>
        <Columns>
          <Column>
            <Output
              {...data.divisaMoneda}
            />
          </Column>
          <Column>
            <Select
              options={paymentEstatus}
              {...data.estatus}
            />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Field>
              <label className="label">{data.fechaTransaccion.label}</label>
              <div className="control">
                <DatePicker
                  disabled={!!orderData.id}
                  className="input"
                  dateFormat="dd/MM/yyyy"
                  minDate={orderData.fechaTransaccion}
                  selected={moment(data.fechaTransaccion.value).toDate()}
                  onChange={(date) => this.onChangeInput({ target: { value: date, name: 'fechaTransaccion' } })}
                />
                {(data.fechaTransaccion.errors && !!data.fechaTransaccion.errors.length && this.state.showErrors) &&
                  <p className="has-text-danger help">{data.fechaTransaccion.errors[0].message}</p>}
              </div>
            </Field>
          </Column>

          <Column>
            <Output
              {...data.idOrden}
            />
          </Column>
        </Columns>
        <Columns>
          <Column>
            {(!!orderData.id) || (<Input
              {...data.cargo}
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
            />)}
            {(!!orderData.id) && (
              <Output
                {...data.cargo}
              />
            )}
          </Column>
          <Column>
            <Select
              options={realPaymentMethod}
              {...data.metodoPago}
            />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Select
              options={providerAccounts }
              {...data.cuentaMxn}
              showErrors={this.state.showErrors}
            />
          </Column>
          <Column>
            <Output
              {...data.claveRastreo}
            />
          </Column>
        </Columns>
        <Columns>
          <Column className="has-text-right">
            {(!!orderData.id && (+orderData.status === ESTATUS_CREATED || +orderData.status === INSTALLMENT_PARTIAL_AUTHORIZED)) &&
               (<Button primary onClick={() => this.props.authorize(orderData)}>Autorizar</Button>)}
            {(!!orderData.id) || (<Button default onClick={this.addInstallment}>Agregar</Button>)}
            <Button default className="margin-left-sm" onClick={closeInstallmentModal}>
              Cancelar
                </Button>
          </Column>
        </Columns>
      </Box>
    )
  }
}
