import React from 'react';
import { Column } from 'Components/common/bulma';
import InputWithError from 'Components/common/form/InputWithError';


const ColumnInput = ({ className = 'is-half', ...props }) => {
  return (
    <Column className={className}>
      <InputWithError
        {...props}
      />
    </Column>
  );
};

export default ColumnInput;
