import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import { clone, concat, reject, propEq, prop, add, reduce, find, equals } from 'ramda'
import { Modal, Message, Button, Delete, Icon } from 'Components/common/bulma'
import { v4 as uuid } from 'uuid'
import moment from 'moment'
import {
  Box,
  Divider,
  Columns,
  Column,
} from 'Components/common/bulma'
import {
  Select,
  Output,
  Input,
} from 'Components/common/form'
import {
  expenseOrder,
  addressData,
  paymentTable,
  concept,
  conceptColumns,
  totals,
} from './data'
import { AddressSection } from 'Components/catalogs/providers'
import {
  getProviderGasoline,
  getProviderAccounts,
} from 'Modules/catalogs/providers'
import {
  getPaises,
  getEntidades,
  getRealPaymentMethod,
  getPaymentEstatus,
  getTipoPersona,
  getProviderInvoiceStatus,
} from 'Modules/catalogos'
import {
  getWallets
} from 'Modules/catalogs/walletExpense'
import {
  createOrder,
  getOrder,
  addInstallment,
  getInstallments,
  authorizeInstallment,
  endOrderEdit,
  deleteOrder,
  deleteInstallment,
  updateOrder,
} from 'Modules/paymentOrders/walletExpense'
import { cleanOrigin } from 'Modules/paymentOrders/providers'
import { mapPropsToState, getDataInput, validateData, getValues } from 'App/helpers'
import { default as ActionBar } from 'Components/common/actionBar'
import { Table, ModalRemove } from 'Components/common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrash,
  faEdit,
  faKey,
} from '@fortawesome/free-solid-svg-icons'
import Installment from 'Components/paymentOrders/walletExpense/Installment'
import Totals from 'Components/paymentOrders/walletGasoline/Totals'
import { addNotification } from 'Modules/principal'
import { Authorize, Can } from 'Containers/auth';

const AUTHORIZE_PERMISSION = ['Apago de proveedores']
const STATUS_CREATED = '1'
const STATUS_CANCELED = '3'
const STATUS_REFUSED = '4'
const STATUS_PARTIAL_AUTHORIZED = '9'

export class Form extends Component {
  state = {
    expenseOrder,
    addressData,
    concept,
    showConceptModal: false,
    concepts: { data: [] },
    payments: { data: [] },
    installments: { data: [] },
    totals,
  }

  componentDidMount() {
    const {
      getProviderGasoline,
      getPaises,
      getEntidades,
      getRealPaymentMethod,
      getProviderAccounts,
      getPaymentEstatus,
      getTipoPersona,
      getProviderInvoiceStatus,
      getInstallments,
      getWallets,
    } = this.props

    getProviderGasoline(52)
    getProviderAccounts(52)
    getPaises()
    getEntidades()
    getRealPaymentMethod()
    getPaymentEstatus()
    getTipoPersona()
    getProviderInvoiceStatus()
    getWallets(1, 1000)

    const id = this.props.match.params.id
    if (id && (id !== this.state.expenseOrder)) {
      this.props.getOrder(id)
      getInstallments(id)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.provider !== this.props.provider) {
      const expenseOrder = mapPropsToState(this.state.expenseOrder, nextProps.provider)
      const addressData = mapPropsToState(this.state.addressData, nextProps.provider)

      this.setState({
        expenseOrder,
        addressData,
      })
    }

    if (nextProps.expenseOrder !== this.props.expenseOrder) {
      this.setState({
        expenseOrder: mapPropsToState(this.state.expenseOrder, nextProps.expenseOrder),
        concepts: { data: nextProps.expenseOrder.conceptos },
        totals: mapPropsToState(this.state.totals, nextProps.expenseOrder)
      })
    }
  }

  componentWillUnmount() {
    this.props.endOrderEdit()
    this.props.cleanOrigin()
  }

  onChangeInput = ({ target }, section) => {
    const { name } = target
    const data = getDataInput(target, this.state[section])
    const field = data[name]

    this.setState({
      [section]: data,
      showErrors: false,
      showAccountErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }

  getPermissions = () => {
    const { estatusOrden } = this.props.expenseOrder
    return {
      canDelete: estatusOrden === STATUS_CREATED,
      canSave: true,
    }
  }

  addConcept = () => {
    this.setState({
      showConceptModal: true,
      concept: clone(concept),
    })
  }
  openInstallmentModal = ({ id, fechaTransaccion }) => {
    const limitHour = moment({ hour: 16, minute: 5 })
    const minDate = moment() < limitHour ? moment({ hour: 0 }) : moment({ hour: 0 }).add(1, 'day')
    const idOrden = prop('id')(this.props.expenseOrder)
    const integrado = prop('integrado')(this.props.expenseOrder)

    if (this.props.expenseOrder.id) {
      this.setState({
        isAddingInstallment: true,
        orderData: {
          integrado,
          cargo: this.state.totals.total.value,
          idOrden,
          fechaTransaccion: id ? new Date(fechaTransaccion) : minDate.toDate(),
          metodoPago: '1',
          cuentaMxn: '21',
        }
      })
    } else {
      this.createOrder(() => this.openInstallmentModal({}))
    }
  }

  addInstallment = (installment) => {

    this.props.addInstallment(this.props.expenseOrder.id, {
      ...installment,
      fechaTransaccion: moment(installment.fechaTransaccion).format('YYYY-MM-DD')
    })
      .then(() => {
        this.closeInstallmentModal();
        this.props.addNotification({
          type: 'success',
          message: '¡El pago se agregó correctamente!'
        })
      })
      .catch(({ response: { data } }) => {
        this.props.addNotification({
          type: 'danger',
          message: data.message
        })
      })
  }

  closeConceptModal = () => {
    this.setState({
      concept: clone(concept),
      showConceptModal: false,
    })
  }

  closeInstallmentModal = () => {
    this.setState({
      isAddingInstallment: false,
    })
  }

  saveConcept = () => {
    const { data, hasErrors } = validateData(this.state.concept)

    if (hasErrors) {
      this.setState({
        data,
        showConceptErrors: true,
      })
      return
    }

    const concepto = getValues(this.state.concept)
    const wallet = find(propEq('id', concepto.monederoGasolina))(this.props.wallets.data)

    this.setState(({ concepts }) => ({
      concepts: { data: concat(concepts.data, [{ ...concepto, id: uuid(), tarjeta: wallet.noTarjeta }]) },
      concept: clone(concept),
      showConceptModal: false,
    }), () => this.updateTotals())
  }

  updateTotals = () => {
    const totals = reduce((acc, cur) => {
      return {
        total: add(prop('total')(acc), prop('subtotal')(cur)),
        montoPagar: add(prop('total')(acc), prop('subtotal')(cur)),
        subtotal: add(prop('subtotal')(acc), prop('subtotal')(cur)),
      }
    },
      {
        montoPagar: 0,
        total: 0,
        subtotal: 0,
        IVA: 0,
        ieps: 0,
      }
    )(this.state.concepts.data)
    this.setState(({ totals: totalsState }) => ({ totals: mapPropsToState(totalsState, totals) }))
  }

  removeConcept = () => {
    const id = this.state.removingConcept.id
    const filterId = reject(propEq(id ? 'id' : 'idConcepto', this.state.removingConcept[id ? 'id' : 'idConcepto']))

    this.setState(({ concepts }) => ({
      concepts: { data: filterId(concepts.data) },
      concept: clone(concept),
      isRemovingConcept: false,
      removingConcept: undefined,
    }), this.updateTotals)
  }

  conceptActions = (row) => (
    <Button danger outlined
      disabled={this.props.installments.data.length}
      onClick={() => this.openConfirmRemoveConcept(row)}>
      <Icon>
        <FontAwesomeIcon icon={faTrash} />
      </Icon>
    </Button>
  )

  openConfirmRemoveConcept = concept => this.setState({
    isRemovingConcept: true,
    removingConcept: concept,
  })

  closeConfirmRemoveConcept = () => this.setState({
    isRemovingConcept: false,
    removingConcept: undefined,
  })

  createOrder = cb => {
    const { data, hasErrors } = validateData(this.state.expenseOrder)

    if (hasErrors || this.state.concepts.data.length === 0) {
      this.setState({
        expenseOrder: data,
        showErrors: true,
      })
      this.props.addNotification({
        type: 'danger',
        message: '¡Favor de validar algunos campos y que tengas conceptos!'
      })
      return false
    }
    const orderData = getValues(this.state.expenseOrder)
    const totalData = getValues(this.state.totals)
    const id = this.props.expenseOrder.id

    if (id) {
      this.props.updateOrder({ id, ...orderData, ...totalData, conceptos: this.state.concepts.data })
        .then(data => {
          this.props.addNotification({
            type: 'success',
            message: '¡La orden se actualizó correctamente!'
          })

          const expenseOrder = mapPropsToState(this.state.expenseOrder, data)
          const totals = mapPropsToState(this.state.totals, data)

          this.setState({
            expenseOrder,
            totals,
          })
        })
        .catch(({ response: { data } }) => {
          this.props.addNotification({
            type: 'danger',
            message: data.message
          })
        })
    } else {
      this.props.createOrder({ ...orderData, ...totalData, conceptos: this.state.concepts.data })
        .then(({ id }) => {
          this.props.addNotification({
            type: 'success',
            message: '¡Orden creada exitosamente!'
          })
          this.props.push(`/ordenes-pago/monederos-gastos/${id}`);
          cb && cb();
        });
    }
  }

  onCancel = () => {
    const { push, origin } = this.props
    const isFromPending = equals('pending')(origin)
    const isFromPaid = equals('paidWalletExpense')
    const isFromProcesing = equals('procesingWalletExpense')
    const isFromRejected = equals('rejectedWalletExpense')

    if (isFromPending) {
      push('/ordenes-pago/pendientes')
      return
    }
    if (isFromPaid(origin)) {
      push('/ordenes-pago/monederos-gastos/pagadas')
      return
    }
    if (isFromProcesing(origin)) {
      push('/ordenes-pago/monederos-gastos/en-proceso')
      return
    }
    if (isFromRejected(origin)) {
      push('/ordenes-pago/monederos-gastos/rechazadas')
      return
    }
    push('/ordenes-pago/monederos-gastos')
  }

  installmentActions = (row, idx) => (
    <div>
      <Button outlined
        primary
        onClick={() => this.openInstallmentModal(row)}>
        <Icon>
          <FontAwesomeIcon icon={faEdit} />
        </Icon>
      </Button>
      <Can validate={AUTHORIZE_PERMISSION}>
        <Button danger outlined className="margin-left-xs"
          disabled={!(row.estatus === STATUS_CREATED || row.estatus === STATUS_PARTIAL_AUTHORIZED)}
          onClick={() => this.onAuthorize(row)}>
          <Icon>
            <FontAwesomeIcon icon={faKey} />
          </Icon>
        </Button>
      </Can>
      <Button danger outlined className="margin-left-xs"
        disabled={(row.estatus !== STATUS_CREATED)}
        onClick={() => this.openConfirmRemoveInstallment(row)}>
        <Icon>
          <FontAwesomeIcon icon={faTrash} />
        </Icon>
      </Button>
    </div>
  )

  onAuthorize = ({ id }) => {
    this.setState({
      isAddingInstallment: false,
      showAuth: true,
      installment: id,
    })
  }

  cancelAuthorize = () => {
    this.setState({
      showAuth: false,
    })
  }

  authorizeInstallment = props => {
    return this.props.authorizeInstallment({
      idOrder: this.props.expenseOrder.id,
      idInstallment: this.state.installment,
      ...props,
    }).then(data => {
      this.props.addNotification({
        type: 'success',
        message: 'El pago se autorizó correctamente'
      })
      this.setState({
        showAuth: false,
        totals: mapPropsToState(this.state.totals, data),
        expenseOrder: mapPropsToState(this.state.expenseOrder, data),
      })
    })
  }

  closeConfirmRemoveOrder = () => this.setState({
    isRemovingOrder: false
  })

  openConfirmRemoveOrder = () => this.setState({
    isRemovingOrder: true,
  })

  removeOrder = () => {
    this.props.deleteOrder(this.props.expenseOrder.id)
      .then(data => {
        this.props.addNotification({
          type: 'success',
          message: 'La órden de pago se eliminó correctamente'
        })
        this.setState({
          isRemovingOrder: false,
          expenseOrder: mapPropsToState(this.state.expenseOrder, data),
        })
        this.props.getInstallments(this.props.expenseOrder.id)
      })
      .catch(data => {
        this.props.addNotification({
          type: 'danger',
          message: data.message
        })
      })
  }

  openConfirmRemoveInstallment = ({ id }) => this.setState({
    isRemovingInstallment: true,
    installment: id,
  })

  closeConfirmRemoveInstallment = () => this.setState({
    isRemovingInstallment: false,
  })

  removeInstallment = () => {
    this.props.deleteInstallment(this.props.expenseOrder.id, this.state.installment)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'El pago se eliminó correctamente'
        })
        this.setState({
          isRemovingInstallment: false,
        })
      })
      .catch(({ response: { data } }) => {
        this.props.addNotification({
          type: 'danger',
          message: data.message
        })
      })
  }

  render() {
    const {
      paises,
      entidades,
      realPaymentMethod,
      providerAccounts,
      paymentEstatus,
      tipoPersona,
      invoiceProviderStatus,
      wallets,
    } = this.props
    const {
      estatusOrden,
      metodoPago,
      cuentaRetiro,
      divisaMoneda,
      cuentaClabeBeneficiario,
      referencia,
      tipoDePersona,
      nombreORazonSocial,
      correoElectronico,
      rfc,
    } = this.state.expenseOrder
    return (
      <Box>
        <Modal isActive={this.state.isRemovingOrder}>
          <Message danger>
            <Message.Header>
              <p>Eliminar Orden de pago</p>
              <Delete onClick={this.closeConfirmRemoveOrder} />
            </Message.Header>
            <Message.Body className="has-text-centered">
              ¿Esta seguro de querer eliminar la orden de pago?
              <div className="margin-top-lg">
                <Button danger onClick={this.removeOrder}>Eliminar</Button>
                <Button default className="margin-left-sm" onClick={this.closeConfirmRemoveOrder}>
                  Cancelar
              </Button>
              </div>
            </Message.Body>
          </Message>
        </Modal>
        <ModalRemove
          title="Eliminar pago"
          isDeleting={this.state.isRemovingInstallment}
          confirmText="¿Esta seguro de querer eliminar el pago?"
          toggleModal={this.closeConfirmRemoveInstallment}
          resource={this.state.installment}
          deleteResource={this.removeInstallment}
        />
        {this.state.showAuth && (<Authorize
          isOpen={this.state.showAuth}
          authorize={this.authorizeInstallment}
          cancel={this.cancelAuthorize}
        />)}
        <Modal isActive={this.state.isRemovingConcept}>
          <Message danger>
            <Message.Header>
              <p>Eliminar Concepto</p>
              <Delete onClick={this.closeConfirmRemoveConcept} />
            </Message.Header>
            <Message.Body className="has-text-centered">
              ¿Esta seguro de querer eliminar el concepto?
              <div className="margin-top-lg">
                <Button danger onClick={this.removeConcept}>Eliminar</Button>
                <Button default className="margin-left-sm" onClick={this.closeConfirmRemoveConcept}>
                  Cancelar
              </Button>
              </div>
            </Message.Body>
          </Message>
        </Modal>
        <ActionBar
          permissions={this.getPermissions()}
          onSave={() => this.createOrder()}
          onDelete={this.openConfirmRemoveOrder}
          onCancel={this.onCancel}
          onAuthorize={this.onAuthorize}
          basicRole={['Opago de proveedores']}
          authorizeRole={['Apago de proveedores']}
        />
        <Columns>
          <Column>
          </Column>
          <Column>
            <Select
              options={paymentEstatus}
              {...estatusOrden}
              onChange={e => this.onChangeInput(e, 'expenseOrder')}
            />
          </Column>
        </Columns>
        <Divider
          content="GENERALES DE LA FACTURA"
        />
        <Columns className="is-multiline" >
          <Column className="is-half">
            <Select
              options={tipoPersona}
              {...tipoDePersona}
              onChange={e => this.onChangeInput(e, 'expenseOrder')}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...nombreORazonSocial}
              onChange={e => this.onChangeInput(e, 'expenseOrder')}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...rfc}
              onChange={e => this.onChangeInput(e, 'expenseOrder')}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...correoElectronico}
              onChange={e => this.onChangeInput(e, 'expenseOrder')}
            />
          </Column>
        </Columns>
        <AddressSection
          entidades={entidades}
          paises={paises}
          data={this.state.addressData}
          onChange={e => this.onChangeInput(e, 'addressData')}
        />
        <Divider
          content="DATOS PARA PAGO"
        />
        <Columns className="is-multiline">
          <Column className="is-half">
            <Select
              options={realPaymentMethod}
              {...metodoPago}
              onChange={e => this.onChangeInput(e, 'expenseOrder')}
              showErrors={this.state.showErrors}
            />
          </Column>
          <Column className="is-half">
            <Output
              {...cuentaRetiro}
              onChange={e => this.onChangeInput(e, 'expenseOrder')}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...referencia}
              onChange={e => this.onChangeInput(e, 'expenseOrder')}
            />
          </Column>
          <Column className="is-half">
            <Output
              {...divisaMoneda}
              onChange={e => this.onChangeInput(e, 'expenseOrder')}
            />
          </Column>
          <Column className="is-half">
            <Select
              options={providerAccounts.data}
              {...cuentaClabeBeneficiario}
              onChange={e => this.onChangeInput(e, 'expenseOrder')}
              showErrors={this.state.showErrors}
            />
          </Column>
        </Columns>
        <Columns>
          <Column >
            <Button
              disabled={this.props.installments.data.length || estatusOrden.value === STATUS_CANCELED || estatusOrden.value === STATUS_REFUSED}
              className="is-pulled-right" onClick={this.addConcept}>Agregar concepto</Button>
          </Column>
        </Columns>
        <Columns>
          <Column >
            <Table
              colsetup={conceptColumns}
              coldata={this.state.concepts}
              emptyTableMarkUp={(<span>Aun no hay conceptos</span>)}
              customCol={{
                customColName: 'acciones',
                renderFunc: this.conceptActions
              }}
            />
            <Modal isActive={this.state.showConceptModal}>
              <Message>
                <Message.Header>
                  Agregar concepto
                  <Delete onClick={this.closeConceptModal} />
                </Message.Header>
                <Message.Body>
                  <Select
                    options={wallets.data}
                    {...this.state.concept.monederoGasolina}
                    onChange={e => this.onChangeInput(e, 'concept')}
                    showErrors={this.state.showConceptErrors}
                  />
                  <Input
                    {...this.state.concept.subtotal}
                    onChange={e => this.onChangeInput(e, 'concept')}
                    showErrors={this.state.showConceptErrors}
                  />
                  <Button onClick={this.saveConcept}>Guardar</Button>
                  <Button onClick={this.closeConceptModal}>Cancelar</Button>
                </Message.Body>
              </Message>
            </Modal>
          </Column>
        </Columns>
        <Divider
          content="PAGOS"
        />
        <Columns>
          <Column >
            <Button
              disabled={this.state.totals.montoPagar.value < 1 || estatusOrden.value === STATUS_CANCELED || estatusOrden.value === STATUS_REFUSED}
              className="is-pulled-right" onClick={() => this.openInstallmentModal({})}>Agregar Pago</Button>
          </Column>
        </Columns>
        <Column>
          <Modal isActive={this.state.isAddingInstallment}>
            <Message>
              <Message.Header>
                Agregar pago
                <Delete onClick={this.closeInstallmentModal} />
              </Message.Header>
              <Message.Body>
                {this.state.isAddingInstallment && <Installment
                  orderData={this.state.orderData}
                  addNotification={addNotification}
                  addInstallment={this.addInstallment}
                  closeInstallmentModal={this.closeInstallmentModal}
                  paymentEstatus={paymentEstatus}
                  authorize={this.onAuthorize}
                  realPaymentMethod={realPaymentMethod}
                  providerAccounts={providerAccounts.data}
                />}
              </Message.Body>
            </Message>
          </Modal>
        </Column>
        <Columns>
          <Column>
            <Table
              colsetup={paymentTable}
              coldata={this.props.installments}
              emptyTableMarkUp={(<span>Aun no hay pagos</span>)}
              customCol={{
                customColName: 'acciones',
                renderFunc: this.installmentActions
              }}
            />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Totals
              data={this.state.totals}
              providerInvoiceStatus={invoiceProviderStatus}
            />
          </Column>
        </Columns>
      </Box >
    )
  }
}

const mapStateToProps = ({ catalogs, catalogos, paymentOrders }) => ({
  provider: catalogs.providers.provider,
  paises: catalogos.paises,
  entidades: catalogos.entidades,
  realPaymentMethod: catalogos.realPaymentMethod,
  providerAccounts: catalogs.providers.accounts,
  expenseOrder: paymentOrders.walletExpense.expenseOrder,
  paymentEstatus: catalogos.paymentEstatus,
  tipoPersona: catalogos.tipoPersona,
  invoiceProviderStatus: catalogos.providerInvoiceStatus,
  installments: paymentOrders.walletExpense.installments,
  wallets: catalogs.walletExpense.wallets,
  origin: paymentOrders.providers.origin,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getProviderGasoline,
  getPaises,
  getEntidades,
  getRealPaymentMethod,
  getProviderAccounts,
  getPaymentEstatus,
  getTipoPersona,
  createOrder,
  addNotification,
  push,
  getProviderInvoiceStatus,
  getOrder,
  addInstallment,
  getInstallments,
  authorizeInstallment,
  endOrderEdit,
  deleteOrder,
  deleteInstallment,
  updateOrder,
  getWallets,
  cleanOrigin,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Form)
