import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { Can } from 'Containers/auth'
import FacturaImg from '../../styles/images/Factura.png';
import DepositosImg from '../../styles/images/Depositos.png';
import CobrosTarjetaImg from '../../styles/images/CobrosTarjeta.png';
import CfdiImg from '../../styles/images/CFDI.png';

export class Catalogs extends Component {

  render() {
    return (
      <section className="info-tiles">
        <div className="tile is-ancestor has-text-centered">
          <Can partnerPermission={['facturacion']} validate={['Ofacturación', 'Afacturación']}>
            <div className="tile is-parent">
              <Link className="tile is-child box" to="/ordenes-ingreso/facturas">
                <p className="title has-text-grey-dark">
                  <img className="icons" src={FacturaImg} alt="catalogo de proveedores" />
                </p>
                <p className="subtitle">Facturas</p>
              </Link>
            </div>
          </Can>
          <Can partnerPermission={['depositos']} validate={['Odepósitos', 'Adepósitos']}>
            <div className="tile is-parent">
              <Link className="tile is-child box" to="/ordenes-ingreso/depositos">
                <p className="title has-text-grey-dark">
                  <img className="icons" src={DepositosImg} alt="catalogo de proveedores" />
                </p>
                <p className="subtitle">Depósitos</p>
              </Link>
            </div>
          </Can>
        </div>
        <div className="tile is-ancestor has-text-centered">
          <Can partnerPermission={['facturacion']} validate={['Ofacturación', 'Afacturación']}>
            <div className="tile is-parent">
              <Link className="tile is-child box" to="/ordenes-ingreso/anticipo-venta">
                <p className="title has-text-grey-dark">
                  <img className="icons" src={CfdiImg} alt="catalogo de proveedores" />
                </p>
                <p className="subtitle">Anticipos de venta</p>
                <p>(CFDI Anticipo)</p>
              </Link>
            </div>
          </Can>
          <Can partnerPermission={['facturacion']} validate={['Ofacturación', 'Afacturación']}>
            <div className="tile is-parent">
              <Link className="tile is-child box" to="/ordenes-ingreso/cobro-tarjetas">
                <p className="title has-text-grey-dark">
                  <img className="icons" src={CobrosTarjetaImg} alt="catalogo de proveedores" />
                </p>
                <p className="subtitle">Cobros con tarjetas</p>
              </Link>
            </div>
          </Can>
        </div>
      </section>
    )
  }
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Catalogs)
export { default as IncomeOrdersInvoice } from './invoice'
export { default as IncomeOrdersDeposits } from './deposits'
export { default as IncomeOrdersInvoiceForm } from './invoice/form'
export { default as IncomeOrdersDepositsForm } from './deposits/form'
export { default as IncomeOrdersAdvancePayment } from './advancePayment'
export { default as IncomeOrdersCreditNote } from './creditNote'
export { default as IncomeOrdersCreditNoteDetail } from './creditNote/detail'
