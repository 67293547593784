import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { addNotification } from 'Modules/principal'
import { Link } from 'react-router-dom'
import {
  Box,
  Icon,
  Message,
  Button,
  Modal,
  Delete,
  Columns,
  Column,
} from 'Components/common/bulma'
import { default as Table } from 'Components/common/table'
import { getProviders, deleteProvider } from 'Modules/catalogs/providers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Trans } from '@lingui/macro'
import { colDef } from './providerData'
import { ActionMenu } from 'Components/common'

export class ListaContainer extends Component {
  state = {
    showModal: false,
    provider: {},
  }

  onEdit = (provider) => {
    this.props.push(`/catalogos/proveedores/${provider.id}`)
  }

  toggleModal = (provider) => {
    this.setState(state => ({
      showModal: !state.showModal,
      provider: provider
    }))
  }

  deleteProvider = () => {
    this.setState(state => ({
      showModal: !state.showModal,
      provider: {}
    }))
    this.props.deleteProvider(this.state.provider.id)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'El proveedor se eliminó correctamente'
        })
      })
  }

  customColActions = (row) => (
    <div style={{ width: '78px' }}>
      <ActionMenu>
        <Button small primary outlined onClick={() => this.onEdit(row)}>
          <Icon>
            <FontAwesomeIcon icon={faEdit} />
          </Icon>
        </Button>
        <Button small className="margin-left-xs" danger outlined onClick={() => this.toggleModal(row)}>
          <Icon>
            <FontAwesomeIcon icon={faTrash} />
          </Icon>
        </Button>
      </ActionMenu>
    </div>
  )

  render() {
    const { providers } = this.props;

    return (
      <Box>
        <Modal isActive={this.state.showModal}>
          <Message danger>
            <Message.Header>
              <p>Eliminar proveedor</p>
              <Delete onClick={this.toggleModal} />
            </Message.Header>
            <Message.Body className="has-text-centered">
              ¿Esta seguro de querer eliminar el proveedor?
            <br />
              <strong>{this.state.provider.nombreORazonSocial}</strong>
              <div className="margin-top-lg">
                <Button danger onClick={this.deleteProvider}>Eliminar</Button>
                <Button default className="margin-left-sm" onClick={this.toggleModal}>
                  Cancelar
              </Button>
              </div>
            </Message.Body>
          </Message>
        </Modal>
        <Columns>
          <Column>
            <Link className="button is-success is-outlined is-pulled-right" to="/catalogos/proveedores/nuevo">
              <Icon>
                <FontAwesomeIcon icon={faPlus} />
              </Icon>
              <Trans render="span">Agregar Proveedor</Trans>
            </Link>
          </Column>
        </Columns>
        <Table
          filterable={true}
          colsetup={colDef}
          coldata={providers}
          tableClassName='table is-striped is-hoverable is-fullwidth'
          emptyTableMarkUp={(<span>No se encontraron proveedores</span>)}
          customCol={{
            customColName: 'actions',
            renderFunc: this.customColActions
          }}
          refresh={this.props.getProviders}
        />
      </Box>
    )
  }
}

const mapStateToProps = ({ catalogs: { providers: { providers, isFetchingProviders } } }) => ({
  providers,
  isFetchingProviders
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getProviders,
  push,
  deleteProvider,
  addNotification,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ListaContainer)
