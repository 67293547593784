import { useRef, useCallback, useState } from 'react';
import useForm from 'Hooks/useForm';
import { createLegalEntity } from 'App/hexmodules/legalEntity/application/create/createLegalEntity'
import { updateLegalEntity } from 'App/hexmodules/legalEntity/application/update/updateLegalEntity'
import { getLegalEntity } from 'App/hexmodules/legalEntity/application/get/getLegalEntity'
import { useDependencies } from 'App/DependencyContext';
import { fields } from './definition'
import { addNotification } from 'Modules/principal'
import { useDispatch } from 'react-redux'

const STATE = {
  INITIAL: 'initial',
  FETCHING_LEGAL_ENTITY: 'fetchingLegalEntity',
  FETCHED_LEGAL_ENTITY: 'fetchedLegalEntity',
  ERROR_FETCHING_LEGAL_ENTITY: 'errorFetchingLegalEntity',
  SAVING_LEGAL_ENTITY: 'savingLegalEntity',
  SAVED_LEGAL_ENTITY: 'savedLegalEntity',
  ERROR_SAVING_LEGAL_ENTITY: 'errorSavingLegalEntity',
};

function useLegalEntity({ refresh }) {
  const state = useRef(STATE.INITIAL);
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  const [formValues, setFormValues, handleOnChangeField, isValid, getValues, showErrors] = useForm({
    fields,
  });


  const { legalEntityRepository } = useDependencies()

  const initialFetch = async () => {
    if (state.current === STATE.INITIAL) {
      setIsLoading(true)
      state.current = STATE.FETCHING_LEGAL_ENTITY;
      try {
        const legalEntity = await getLegalEntity(legalEntityRepository);
        setFormValues(legalEntity);
        if (legalEntity.certificateNumber) {
          setIsUpdating(true)
        }
        state.current = STATE.FETCHED_LEGAL_ENTITY;
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    }
  };

  initialFetch();

  const saveLegalEntity = async () => {
    isValid()
    if (isValid()) {
      try {
        setIsLoading(true)
        state.current = STATE.SAVING_LEGAL_ENTITY;
        const legalEntity = getValues();
        if (isUpdating) {
          await updateLegalEntity(legalEntityRepository, legalEntity)
        } else {
          await createLegalEntity(legalEntityRepository, legalEntity)
        }
        state.current = STATE.SAVED_LEGAL_ENTITY;
        setIsLoading(false)
        await refresh()
        dispatch(addNotification({
          type: 'success',
          message: 'Infomación guardada correctamente',
        }))
      } catch ({ response: { data } }) {
        dispatch(addNotification({
          type: 'danger',
          message: data?.error,
        }))
        setIsLoading(false)
      }

    }
  };

  const getCurrentState = useCallback(() => {
    return state.current;
  }, []);


  return {
    values: formValues,
    getCurrentState,
    onChangeField: handleOnChangeField,
    showErrors,
    saveLegalEntity,
    isLoading,
  };
}

export default useLegalEntity;
