import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { default as InventoryList, InventoryForm } from 'Containers/inventory'

export default () => (
  <Switch>
    <Route exact path="/inventarios" component={InventoryList} />
    <Route exact path="/inventarios/nuevo" component={InventoryForm} />
    <Route exact path="/inventarios/:id" component={InventoryForm} />
  </Switch>
)