import React from 'react'
import {
  Field, Control, Notification, Delete, Box,
  Column,
  Columns,
  Button,
} from 'Components/common/bulma'
import { Input } from 'Components/common/form'
import CountDown from 'react-countdown-clock'

const Authorize = ({
  data,
  showErrors,
  showServerError,
  onChange,
  randomKey,
  completions,
  refreshRandomKey,
  hideServerError,
  authorize,
  cancel,
  serverMessage,
  isAuthorizing,
}) => {
  return (
    <Columns>
      <Column className="is-half is-offset-3">
        <Box>
            <Input
              {...data.usuario}
              onChange={onChange}
              showErrors={showErrors}
            />
            <Input
              {...data.password}
              onChange={onChange}
              showErrors={showErrors}
            />
            <Field>
              <Control>
                <label >Acesso seguro <strong><em>{randomKey.randomCard}:</em></strong></label>
              </Control>
            </Field>
            <Field className="is-horizontal">
              <div className="field-body">
                <Field className="padding-left-md margin-top-sm">
                  <Control>
                    <CountDown
                      key={completions}
                      seconds={60}
                      color="red"
                      alpha={0.9}
                      size={35}
                      onComplete={refreshRandomKey} />
                  </Control>
                </Field>
                <Input
                  {...data.card}
                  onChange={onChange}
                  showErrors={showErrors}
                />
              </div>
            </Field>
            { showServerError && (<Notification danger>
              <Delete onClick={hideServerError} />
              {serverMessage || 'Tu usuario y/o contraseña no son válidos, ó el token ha expirado.'}
            </Notification>)}
          <Control className="has-text-centered">
            <Button
              className={`${isAuthorizing ? 'is-loading' : ''}`}
              danger
              onClick={authorize}
              disabled={isAuthorizing}
            >
              Autorizar
             </Button>
            <Button
              disabled={isAuthorizing}
              onClick={cancel}
              className="margin-left-sm"
              type="button"
             >
            Cancelar
            </Button>
          </Control>
        </Box>

      </Column>
    </Columns>
  )
}

export default Authorize
