import React, { Fragment } from 'react'
import { Columns, Column } from 'Components/common/bulma'
import { Input, Select } from 'Components/common/form'
import CifLoader from 'Containers/catalogs/components/cifLoader'

export default ({
  data,
  onChange,
  showErrors,
  estatusPersona,
  onCifLoaded,
}) => {
  return (
    <Fragment>
      <Columns>
        <Column>
          <CifLoader
            onCifLoaded={onCifLoaded}
          />
        </Column>
        <Column>
          <Select
            onChange={onChange}
            options={estatusPersona}
            showErrors={showErrors}
            valueProperty="id"
            valueLabel="estatus"
            {...data.estatus}
          />
        </Column>
      </Columns>
      <Columns className="is-multiline">
        <Column className="is-half">
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...data.apellidoPaterno}
          />
        </Column>
        <Column className="is-half">
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...data.apellidoMaterno}
          />
        </Column>
        <Column className="is-half">
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...data.nombres}
          />
        </Column>
        <Column className="is-half">
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...data.correoElectronico}
          />
        </Column>
        <Column className="is-half">
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...data.rfc}
            maxLength="13"
            className="is-uppercase"
          />
        </Column>
        <Column className="is-half">

          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...data.curp}
          />
        </Column>
      </Columns>
    </Fragment>
  )
}
