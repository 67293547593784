import React from 'react'
import { Output, Check } from 'Components/common/form'
import { Columns, Column, Box } from 'Components/common/bulma'


const Perfil = ({data}) => {
    return (
        <Box>
            <Columns>
                <Column>
                    <Output
                        {...data.id}
                    />
                </Column>
                <Column>
                    <Output
                        {...data.nombreRazonSocial }
                    />
                </Column>

            </Columns>
            <Columns>
                <Column>
                    <Check
                        {...data.identificacion_oficial}
                    />
                </Column>
                <Column>
                    <Check
                        {...data.actaConstitutiva}
                    />
                </Column>
            </Columns>
            <Columns>
                <Column>
                    <Check
                        {...data.inscripcionRFC}
                    />
                </Column>
                <Column>
                    <Check
                        {...data.contratoFirmado}
                    />
                </Column>
            </Columns>
            <Columns>
                <Column>
                    <Check
                        {...data.comprobanteDomicilio}
                    />
                </Column>
                <Column>
                    <Check
                        {...data.entregaTituloSocios}
                    />
                </Column>
            </Columns>
            <Columns>
                <Column>
                <Output
                        {...data.fechaAdmision}
                    />
                </Column>
                <Column>
                <Output
                        {...data.fechaContrato}
                    />
                </Column>
            </Columns>
        </Box>
    );
};

export default Perfil;