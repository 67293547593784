import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { addNotification, getAccountsInfo, getNoInterestConfiguration } from 'Modules/principal'
import { push } from 'connected-react-router'
import { v4 as uuid } from 'uuid'
import {
  reject, propEq, reduce, add, prop, omit, clone, filter, equals, keys, join,
  head, range, objOf, map, find, includes, flip, cond, always, T, pipe, propSatisfies,
} from 'ramda'
import { isNilOrEmptyString } from 'ramda-extension'
import { toNumber } from 'ramda-adjunct'

import Concept from 'Components/incomeOrders/invoice/Concept'
import Totals from 'Components/incomeOrders/invoice/Totals'
import { AddressSection } from 'Components/incomeOrders/invoice/AddressSection'
import {
  generalData,
  addressData,
  conceptsCol,
  paymentCol,
  complementaryPaymentCol,
  totals,
  creditNotesCol,
} from './data'
import {
  getDataInput, mapPropsToState, getValues, validateData,
} from 'App/helpers'
import {
  Box,
  SubTitle,
  Divider,
  Columns,
  Column,
  Button,
  Modal,
  Message,
  Delete,
  Icon,
  Control,
} from 'Components/common/bulma'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import {
  Select,
  Input,
  Date as DateInput,
  Check,
} from 'Components/common/form'
import SwitchOld from 'Components/common/form/SwitchOld'
import {
  getOrder,
  createOrder,
  endOrderEdit,
  getRelatedUiid,
  getInstallments,
  getComplementaryInstallments,
  authorizeOrder,
  updateOrder,
  deleteOrder,
  findProducts,
  clearFinder,
  getProduct,
  addProduct,
  getAttachments,
  getMitLink,
  getStripeLink,
  cleanOrigin,
  getOptions,
  createSubscription,
  createOxxoLink,
} from 'Modules/incomeOrders/invoice'
import {
  resetAdvance,
} from 'Modules/incomeOrders/advancePayment'
import {
  getClients,
  getClient,
} from 'Modules/catalogs/clients'
import { ActionBar, Table, } from 'Components/common'
import Show from 'Components/common/Show'
import { ModalRemove } from 'Components/common'
import {
  getInvoiceStatus,
  getPaises,
  getEntidades,
  getTipoPersona,
  getPaymentMethod,
  getCurrencies,
  getUsoCfdi,
  getInvoiceUnits,
  clearInvoiceUnits,
  getIvaRates,
  getIepsRates,
  getProductService,
  clearProductService,
  getTipoRelacion,
  getProductSat,
  getExportationAction,
  getRegimenFiscalAction,
  getPeriodicidadAction,
  getMesesAction,
} from 'Modules/catalogos'
import { Authorize } from 'Containers/auth/'
import numeral from 'numeral'
import Attachments from 'Containers/paymentOrders/attachments'
import CreditNoteForm from '../creditNote/creditNoteForm'
import CifDiffService from 'App/services/cifDiffService'
import UpdateClientService from 'App/services/updateClientService'
import GetExchangeRateService from 'App/services/getExchangeRateService'
import http from 'App/services/httpService';


const AUTHORIZE_PERMISSION = ['Afacturación']
const STATUS_CREATED = '1'
const PERSON_TYPE_FOREIGN = '4'
const STATUS_PARTIAL_AUTHORIZED = '8'
const PARTIAL_PAYMENTS = 'Pago en Parcialidades'
const CURRENCY_TYPE_MXN = 'MXN'
const COLLECTION_ACCOUNT_TYPE_MXN = 'MXN'
const COLLECTION_ACCOUNT_TYPE_USD = 'USD'

const cifDiffService = new CifDiffService(http)
const updateClientService = new UpdateClientService(http)
const getExchangeRateService = new GetExchangeRateService(http)

export class Form extends Component {
  state = {
    showErrors: false,
    generalData,
    addressData,
    order: {},
    conceptos: {
      data: []
    },
    isAddingConcept: false,
    totals,
    installments: {
      data: []
    },
    noInterest: [],
    selectedNoInterest: {},
    isCanceling: false,
    subscription: {
      interval: 'month',
      intervalCount: '',
    },
    hasDiff: false,
  }
  componentDidMount() {
    let generalData
    const {
      getOrder,
      getClients,
      getTipoPersona,
      getPaymentMethod,
      getCurrencies,
      getUsoCfdi,
      getPaises,
      getEntidades,
      getInvoiceStatus,
      getTipoRelacion,
      getIvaRates,
      getIepsRates,
      getInstallments,
      getComplementaryInstallments,
      getAccountsInfo,
      advance,
      getAttachments,
      getNoInterestConfiguration,
      getOptions,
      getExportation,
      getRegimenFiscal,
      getPeriodicidad,
      getMeses,
    } = this.props
    const { id } = this.props.match.params

    getClients({ size: 1000 })
    getTipoPersona()
    getPaymentMethod()
    getCurrencies()
    getUsoCfdi()
    getPaises()
    getEntidades()
    getInvoiceStatus()
    getTipoRelacion()
    getIvaRates()
    getIepsRates()
    getAccountsInfo()
    getNoInterestConfiguration()
    getExportation()
    getRegimenFiscal()
    getPeriodicidad()
    getMeses()

    if (id) {
      getOptions(id)
      getOrder(id)
      getInstallments(id)
      getComplementaryInstallments(id)
      getAttachments(id)
    } else {
      if (advance) {
        generalData = clone(this.state.generalData);
        generalData.tipoRelacion.value = 7;
        generalData.tipoRelacion.disabled = true;
        generalData.uuidRelacionado.value = advance.uuid;
        generalData.uuidRelacionado.disabled = true;

        this.setState({
          generalData
        })
      }
    }
  }

  componentWillUnmount() {
    const { endOrderEdit, resetAdvance, cleanOrigin } = this.props
    endOrderEdit()
    cleanOrigin()
    resetAdvance()
  }

  updateClientState = (clientData) => {
    const tipoPersona = +clientData.tipoPersona
    const rfc = clientData.rfc;
    let regimenFiscalReceptor = clientData.regimenFiscalReceptor ?? '';
    let codigoPostal = clientData.codigoPostal;
    let UsoCFDISAT = clientData.UsoCFDISAT;
    if (includes(rfc, ['XEXX010101000', 'XAXX010101000'])) {
      regimenFiscalReceptor = 12
      UsoCFDISAT = 23
    }
    if (rfc === 'XAXX010101000' || tipoPersona === 6) {
      codigoPostal = '03100'
    }

    if ([1, 2].includes(tipoPersona)) {
      const isValidCp = toNumber(codigoPostal) > 0
      if (!isValidCp) {
        codigoPostal = ''
      }
    }

    this.setState({
      generalData: mapPropsToState(this.state.generalData, {
        ...clientData, regimenFiscalReceptor: regimenFiscalReceptor, codigoPostal, UsoCFDISAT,
      }),
      addressData: mapPropsToState(this.state.addressData, clientData),
    })
  }

  updateClientDiff = () => {
    const cif = this.state.diff.cif;
    const { client } = this.props
    const regimen = find(propEq('descripcionCif', cif.regimenFiscalReceptor))(this.props.regimenFiscal)

    updateClientService.execute(client.id, { ...client, ...cif, regimenFiscalReceptor: regimen.id, direccion: true })
      .then(({ data }) => {
        this.updateClientState(data)
      }).catch((error) => {
        this.updateClientState(client)
      })
    this.setState({ hasDiff: false })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { client, order, noInterest } = nextProps
    const { client: curClient, order: curOrder, noInterest: curNoInterest } = this.props
    const { id, ...dataClient } = client
    if (client !== curClient) {
      if (!isNilOrEmptyString(client.uriCsf)) {
        cifDiffService.execute(client.id)
          .then(({ data }) => {
            if (data.diff) {
              this.setState({ hasDiff: true, diff: data.data })
            } else {
              this.updateClientState(dataClient)
            }
          }).catch(() => {
            this.updateClientState(dataClient)
          })
      } else {
        this.updateClientState(dataClient)
      }
    }

    if (order !== curOrder) {
      if (order.uuidRelacionado) {
        this.props.getRelatedUiid(order.cliente, order.tipoRelacion)
      }
      this.setState({
        generalData: mapPropsToState(this.state.generalData, order),
        addressData: mapPropsToState(this.state.addressData, order),
        totals: mapPropsToState(this.state.totals, order),
        conceptos: {
          data: order.conceptos
        },
        order,
      }, () => {
        this.onChangePaymentMethod({ value: this.state.generalData.formaPago.value })
      })
    }
    if (noInterest !== curNoInterest) {
      this.setState({
        noInterest: noInterest.filter(it => it.plazo !== 'C').map(it => ({ ...it, allow: true }))
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.totals.total.value !== this.state.totals.total.value) {
      this.setState({ selectedNoInterest: {} })
    }
  }
  openConceptModal = () => this.setState({ isAddingConcept: true })
  closeConceptModal = () => this.setState({ isAddingConcept: false })
  getPermissions = () => {
    return {
      canAuthorize: (this.state.order.estatusOrden === STATUS_CREATED || this.state.order.estatusOrden === STATUS_PARTIAL_AUTHORIZED),
      canDelete: (this.state.order && this.state.order.id && this.state.order.estatusOrden === STATUS_CREATED),
      canSave: true,
    }
  }

  onChangeInput = ({ target }, section) => {
    const { name } = target
    const data = getDataInput(target, this.state[section])
    const field = data[name]

    this.setState({
      [section]: data,
      showErrors: false,
      showAccountErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }

  onChangeSubscription = ({ target }, section) => {
    const { name, value } = target

    this.setState({
      subscription: {
        ...this.state.subscription,
        [name]: value,
      }
    })
  }

  getClient = ({ value }) => {
    if (value) {
      this.props.getClient(value)
    }
  }

  onChangeCurrency = ({ value }) => {
    const generalData = this.state.generalData

    if (value === CURRENCY_TYPE_MXN) {
      generalData.tipoCambio.value = 1
      generalData.tipoCambio.disabled = true
      generalData.tipoCambio.validation = 'required'
      this.setState({
        generalData
      })
    } else {

      getExchangeRateService.execute()
        .then(({ data }) => {
          generalData.tipoCambio.value = data?.fix
          generalData.tipoCambio.disabled = false
          generalData.tipoCambio.validation = `required|isBetween70:${data?.fix}`
          this.setState({
            generalData
          })
        })

    }

  }

  onChangePaymentMethod = ({ value }) => {
    let generalData;
    if (value === PARTIAL_PAYMENTS) {
      generalData = mapPropsToState(this.state.generalData, { metodoPago: '10' })
      generalData.metodoPago.disabled = true
    } else {
      generalData = this.state.generalData
      generalData.metodoPago.disabled = false
    }
    this.setState({
      generalData
    })
  }

  onChangeRelationshipType = ({ value }) => {
    const { cliente: { value: clientId } } = this.state.generalData

    this.setState({ generalData: mapPropsToState(this.state.generalData, { uuidRelacionado: '' }) })
    if (clientId && value) {
      this.props.getRelatedUiid(clientId, value)
    }
  }

  createOrder = (event, cb) => {
    const { data: generalData, hasErrors: hasErrorsGeneralData } = validateData(this.state.generalData)
    const { data: addressData, hasErrors: hasErrorsAddressData } = validateData(this.state.addressData)
    const { advance, updateOrder, addNotification, createOrder, push } = this.props

    if (hasErrorsGeneralData || !this.state.conceptos.data.length || hasErrorsAddressData) {
      this.setState({
        generalData,
        addressData,
        showErrors: true,
      })
      this.props.addNotification({
        type: 'danger',
        message: '¡Favor de validar algunos campos y que tengas conceptos!'
      })
      return
    }

    if (!!advance && (Math.round(+this.state.totals.total.value) !== Math.round(+advance.total))) {
      this.props.addNotification({
        type: 'danger',
        message: `¡El total del anticipo de pago ${numeral(advance.total).format('$ 0,0.00')} es diferente al total de la factura ${numeral(this.state.totals.total.value).format('$ 0,0.00')}!`
      })
      return
    }

    const payload = {
      id: this.state.order.id,
      ...getValues(this.state.generalData),
      ...getValues(this.state.addressData),
      conceptos: this.state.conceptos.data,
      idAdelanto: advance ? advance.id : undefined,
    }
    if (this.state.order.id) {
      updateOrder(payload)
        .then((data) => {
          addNotification({
            type: 'success',
            message: '¡La orden se actualizó correctamente!'
          })
          const generalData = mapPropsToState(this.state.generalData, data)
          const addressData = mapPropsToState(this.state.addressData, data)

          this.setState({
            order: data,
            generalData,
            addressData,
          })
        })
        .catch(({ response: { data } }) => {
          addNotification({
            type: 'danger',
            message: JSON.stringify(data.message)
          })
        })
    } else {
      createOrder(payload)
        .then(data => {
          addNotification({
            type: 'success',
            message: '¡La orden se creó correctamente!'
          })
          push(`/ordenes-ingreso/facturas/${data.id}`)
          cb && cb()
        })
        .catch(({ response: { data } }) => {
          addNotification({
            type: 'danger',
            message: JSON.stringify(data.message)
          })
        })
    }
  }

  onCancel = () => {
    const { push, origin } = this.props
    const isFromPending = equals('pending')(origin)
    const isFromPendingCollection = equals('pendingCollection')

    if (isFromPending) {
      push('/ordenes-ingreso/pendientes')
      return
    }
    if (isFromPendingCollection(origin)) {
      push('/ordenes-ingreso/pendientes-cobro')
      return
    }
    push('/ordenes-ingreso/facturas')
  }

  onRemove = () => {

  }

  addConcept = (concept, addProduct) => {
    const conceptos = this.state.conceptos;
    conceptos.data.push({ ...concept, id: uuid() })
    this.setState({
      conceptos,
    }, () => {
      this.closeConceptModal()
      this.updateTotals()
    })
    if (addProduct) {
      this.props.addProduct(concept)
        .then(() => this.props.addNotification({
          type: 'success',
          message: 'El concepto se agregó exitosamente al catálogo'
        }))
    }
  }

  conceptActions = (row) => (
    <Button danger outlined
      onClick={() => this.openConfirmRemoveConceptModal(row)}
      disabled={this.state.order.id && this.state.order.estatusOrder === STATUS_CREATED}
    >
      <Icon>
        <FontAwesomeIcon icon={faTrash} />
      </Icon>
    </Button>
  )

  openConfirmRemoveConceptModal = (conceptRemoving) => this.setState({
    conceptRemoving,
    isRemovingConcept: true
  })

  closeConfirmRemoveConceptModal = () => this.setState({
    conceptRemoving: undefined,
    isRemovingConcept: false
  })

  removeConcept = () => {
    const { conceptos } = this.state
    if (this.state.conceptRemoving.id) {
      conceptos.data = reject(propEq('id', this.state.conceptRemoving.id))(conceptos.data)
    } else {
      conceptos.data = reject(propEq('idConcepto', this.state.conceptRemoving.idConcepto))(conceptos.data)
    }
    this.setState({
      conceptos,
      isRemovingConcept: false,
    }, this.updateTotals)
  }

  updateTotals = () => {
    const totals = reduce((acc, cur) => {
      const retenciones = prop('retISR')(cur) + prop('retIEPS')(cur) + prop('retIVA')(cur)

      return {
        total: add(prop('total')(acc), prop('total')(cur)),
        montoPorCobrar: add(prop('montoPorCobrar')(acc), prop('total')(cur)),
        subtotal: add(prop('subtotal')(acc), prop('subtotal')(cur)),
        iva: add(prop('iva')(acc), prop('iva')(cur)),
        ieps: add(prop('ieps')(acc), prop('ieps')(cur)),
        totalDescuentos: add(prop('totalDescuentos')(acc), prop('importeDescuento')(cur)),
        totalRetenciones: add(prop('totalRetenciones')(acc), retenciones),
      }
    },
      {
        total: 0,
        subtotal: 0,
        iva: 0,
        ieps: 0,
        montoPorCobrar: 0,
        totalDescuentos: 0,
        totalRetenciones: 0,
      }
    )(this.state.conceptos.data)
    this.setState(({ totals: totalsState }) => ({ totals: mapPropsToState(totalsState, totals) }))
  }

  onAuthorize = () => {
    this.setState({
      showAuth: true,
    })
  }

  cancelAuthorize = () => {
    this.setState({
      showAuth: false,
    })
  }

  authorizeOrder = (data) => {
    return this.props.authorizeOrder(
      this.state.order.id,
      data
    ).then(data => {
      this.props.addNotification({
        type: 'success',
        message: 'La órden se autorizó correctamente'
      })
      this.setState({
        showAuth: false,
        generalData: mapPropsToState(this.state.generalData, data)
      })
    })
  }

  removeOrder = () => {
    this.props.deleteOrder(this.state.order.id)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'La órden de ingreso se eliminó correctamente'
        })
        this.props.push('/ordenes-ingreso/facturas')
      })
      .catch(({ response: { data } }) => {
        this.props.addNotification({
          type: 'danger',
          message: data.message
        })
      })
  }

  openConfirmRemoveOrder = () => this.setState({
    isRemovingOrder: true,
  })

  closeConfirmRemoveOrder = () => this.setState({
    isRemovingOrder: false
  })

  getPaymentLink = () => {
    const selected = filter(equals(true))(this.state.selectedNoInterest)
    const promotions = keys(selected)
    this.props.getMitLink({
      reference: this.props.order.clavePagoReferencia,
      monto: this.props.order.total,
      orderId: this.props.order.id,
      clientId: this.props.order.cliente,
      promotions: join(',')(promotions),
    })
  }

  createSubscription = () => {

    this.props.createSubscription({
      reference: this.props.order.clavePagoReferencia,
      amount: this.props.order.total,
      orderId: this.props.order.id,
      clientId: this.props.order.cliente,
      currency: this.props.order.divisaMoneda,
      interval: this.state.subscription.interval,
      intervalCount: this.state.subscription.intervalCount,
      description: this.state.conceptos.data[0].concepto
    })
  }

  createOxxoLink = () => {

    this.props.createOxxoLink({
      reference: this.props.order.clavePagoReferencia,
      amount: this.props.order.total,
      orderId: this.props.order.id,
      clientId: this.props.order.cliente,
      currency: this.props.order.divisaMoneda,
      description: this.state.conceptos.data[0].concepto
    })
  }

  getStripeLink = () => {
    this.props.getStripeLink({
      reference: this.props.order.clavePagoReferencia,
      amount: this.props.order.total,
      orderId: this.props.order.id,
      clientId: this.props.order.cliente,
      currency: this.props.order.divisaMoneda,
    })

  }

  handleNoInterest = ({ target: { name, checked } }) => {
    this.setState(({ selectedNoInterest }) => ({
      selectedNoInterest: { ...selectedNoInterest, [name]: checked }
    }))
  }

  handleOnCancelInvoice = () => {
    this.setState({
      isCanceling: true,
    })
  }

  handleOnClose = (refresh) => {
    this.setState({
      isCanceling: false,
    })
    if (refresh) {
      this.props.getOrder(this.state.order.id)
    }
  }

  getAnios = () => {
    const currentDate = new Date();

    const years = range(2020, currentDate.getFullYear() + 1)

    return map(objOf('id'))(years)
  }

  closeDiffModal = () => {
    this.setState({ hasDiff: false })
    this.updateClientState(this.props.client)
  }

  getCfdiUseType = (tipoPersona, cfdiUseType = [], rfc) => {
    const flipIncludes = flip(includes)
    const campo = cond([
      [equals(1), always('aplicaTipoPersonaMoral')],
      [equals(2), always('aplicaTipoPersonaFisica')],
      [T, always('')],
    ])(tipoPersona)

    if (includes(rfc, ['XEXX010101000', 'XAXX010101000']) || includes(tipoPersona, [4, 6, 7])) {
      return pipe(filter(pipe(prop('id'), toNumber, equals(23))))(cfdiUseType)
    } else {
      return pipe(reject(propSatisfies(pipe(toNumber, flipIncludes([16, 17, 18, 21, 24, 25])), 'id')), filter(pipe(prop(campo), equals('Sí'))))(cfdiUseType)
    }
  }

  render() {
    const { clients, paises, entidades, invoiceStatus,
      tipoPersona, paymentMethod,
      currencies,
      ivaRates,
      iepsRates,
      invoiceUnits,
      getInvoiceUnits,
      clearInvoiceUnits,
      getProductService,
      clearProductService,
      productService,
      tipoRelacion,
      relatedUiid,
      installments,
      complementaryInstallments,
      accountsInfo,
      findProducts,
      products,
      clearFinder,
      getProduct,
      product,
      productSat,
      getProductSat,
      order,
      exportation,
      regimenFiscal,
      periodicidad,
      meses,
    } = this.props
    const { showErrors, generalData, totals } = this.state
    const isOrderCreated = !!this.props.order.id
    const cash = head(this.props.noInterest)
    const isAmountChanged = +this.props.order.total !== +totals.total.value

    return (
      <Box style={{ position: 'relative' }}>
        {this.state.isCanceling && (<CreditNoteForm
          options={this.props.options}
          onClose={this.handleOnClose}
        />)}
        <ModalRemove
          type="warning"
          title="Diferencia en datos"
          isDeleting={this.state.hasDiff}
          confirmText=
          {this.state.hasDiff && <>
            Hemos detectado estas diferencias entre la constancia de situación fiscal y los datos previamente guardados en el catalogo.
            <table style={{ color: 'black' }}>
              <tbody>

                <tr>
                  <td><strong>Datos Constancia:</strong></td>
                  <td><strong>Datos Catálogo:</strong></td>
                </tr>
                <tr>
                  <td>Nombre: {this.state.diff.cif.nombreORazonSocial}</td>
                  <td>Nombre: {this.state.diff.cliente.nombreORazonSocial}</td>
                </tr>
                <tr>
                  <td>Calle: {this.state.diff.cif.calle}</td>
                  <td>Calle: {this.state.diff.cliente.calle}</td>
                </tr>
                <tr>
                  <td>Exterior: {this.state.diff.cif.exterior}</td>
                  <td>Exterior: {this.state.diff.cliente.exterior}</td>
                </tr>
                <tr>
                  <td>Interior: {this.state.diff.cif.interior}</td>
                  <td>Interior: {this.state.diff.cliente.interior}</td>
                </tr>
                <tr>
                  <td>Colonia: {this.state.diff.cif.colonia}</td>
                  <td>Colonia: {this.state.diff.cliente.colonia}</td>
                </tr>
                <tr>
                  <td>Municipio: {this.state.diff.cif.municipio}</td>
                  <td>Municipio: {this.state.diff.cliente.municipio}</td>
                </tr>
                <tr>
                  <td>Entidad: {this.state.diff.cif.entidadFederativa}</td>
                  <td>Entidad: {this.state.diff.cliente.entidadFederativa}</td>
                </tr>
                <tr>
                  <td>Codigo Postal: {this.state.diff.cif.codigoPostal}</td>
                  <td>Codigo Postal: {this.state.diff.cliente.codigoPostal}</td>
                </tr>
                <tr>
                  <td>Regimen Fiscal: {this.state.diff.cif.regimenFiscalReceptor}</td>
                  <td>Regimen Fiscal: {this.state.diff.cliente.regimenFiscalReceptor}</td>
                </tr>
              </tbody>
            </table>
            <br />
            ¿Deseas actualizar el catálogo con los nuevos datos? </>}
          toggleModal={this.closeDiffModal}
          deleteResource={this.updateClientDiff}
          actionButtonLabel="Actualizar"
          cancelButtonLabel="Continuar"
        />
        <Modal isActive={this.state.isRemovingOrder}>
          <Message danger>
            <Message.Header>
              <p>Eliminar Orden de ingreso</p>
              <Delete onClick={this.closeConfirmRemoveOrder} />
            </Message.Header>
            <Message.Body className="has-text-centered">
              ¿Esta seguro de querer eliminar la orden de ingreso?
              <div className="margin-top-lg">
                <Button danger onClick={this.removeOrder}>Eliminar</Button>
                <Button default className="margin-left-sm" onClick={this.closeConfirmRemoveOrder}>
                  Cancelar
                </Button>
              </div>
            </Message.Body>
          </Message>
        </Modal>
        {
          this.state.showAuth && <Authorize
            isOpen={this.state.showAuth}
            authorize={this.authorizeOrder}
            cancel={this.cancelAuthorize}
          />
        }
        <Modal isActive={this.state.isRemovingConcept}>
          <Message danger>
            <Message.Header>
              <p>Eliminar Concepto</p>
              <Delete onClick={this.closeConfirmRemoveConceptModal} />
            </Message.Header>
            <Message.Body className="has-text-centered">
              ¿Esta seguro de querer eliminar el concepto?
              <div className="margin-top-lg">
                <Button danger onClick={this.removeConcept}>Eliminar</Button>
                <Button default className="margin-left-sm" onClick={this.closeConfirmRemoveConceptModal}>
                  Cancelar
                </Button>
              </div>
            </Message.Body>
          </Message>
        </Modal>
        <Modal isActive={this.state.isAddingConcept}>
          <Message default>
            <Message.Header>
              <p>Agregar concepto</p>
              <Delete onClick={this.closeConceptModal} />
            </Message.Header>
            <Message.Body>
              {this.state.isAddingConcept && <Concept
                getUnits={getInvoiceUnits}
                units={invoiceUnits}
                ivaRates={ivaRates}
                iepsRates={iepsRates}
                addNotification={addNotification}
                addConcept={this.addConcept}
                closeConceptModal={this.closeConceptModal}
                clearUnits={clearInvoiceUnits}
                getProductService={getProductService}
                clearProductService={clearProductService}
                productService={productService}
                findProducts={findProducts}
                products={products}
                clearFinder={clearFinder}
                getProduct={getProduct}
                product={product}
                productSat={productSat}
                getProductSat={getProductSat}
                generalData={this.state.generalData}
              />}
            </Message.Body>
          </Message>
        </Modal>
        <SubTitle className="has-text-centered" is='3'>Facturas</SubTitle>
        <ActionBar
          customButton={(order.uuid && !+order.estatusCancelacion && [4, 6, 7].includes(+order.estatusOrden))
            && <Button onClick={this.handleOnCancelInvoice}>Cancelar Facturas</Button>}
          permissions={this.getPermissions()}
          onSave={this.createOrder}
          onCancel={this.onCancel}
          onDelete={this.openConfirmRemoveOrder}
          onAuthorize={this.onAuthorize}
          basicRole={['Ofacturación']}
          authorizeRole={AUTHORIZE_PERMISSION}
        />
        <Divider content="DATOS GENERALES" />
        <Columns className="is-multiline">
          {(!generalData.estatusOrden.value || +generalData.estatusOrden.value === 1) && <Column className="is-half">
            <Select
              onChange={e => this.onChangeInput(e, 'generalData')}
              options={clients.data}
              showErrors={showErrors}
              {...generalData.cliente}
            />
          </Column>
          }
          <Column className="is-half">
            <Select
              onChange={e => this.onChangeInput(e, 'generalData')}
              options={invoiceStatus}
              showErrors={showErrors}
              {...generalData.estatusOrden}
            />
          </Column>
          <Column className="is-half">
            <DateInput
              {...generalData.fechaProgramacionEmisionFactura}
              onChange={e => this.onChangeInput(e, 'generalData')}
            />
          </Column>
          <Column className="is-half">
            <Select
              {...generalData.exportacion}
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              options={exportation}
            />
          </Column>
          <Column className="is-half">
            <Show canShow={this.state.generalData.exportacion.value === '02'}>
              <span>
                La emisión de la presente factura deberá contener un complemento de exportación,
                favor de contactar a la integradora una vez guardada y autorizada la orden para
                la inclusión del complemento a la factura, antes de su emisión.
              </span>
            </Show>
          </Column>
        </Columns>
        <Divider content="GENERALES DE LA FACTURA" />
        <Columns className="is-multiline">
          <Column className="is-6">
            <Select
              onChange={e => this.onChangeInput(e, 'generalData')}
              options={tipoPersona}
              showErrors={showErrors}
              valueProperty="id"
              valueLabel="tipoPersona"
              {...generalData.tipoDePersona}
            />
          </Column>
          <Column className="is-6">
            <Select
              onChange={e => this.onChangeInput(e, 'generalData')}
              options={regimenFiscal}
              showErrors={showErrors}
              {...generalData.regimenFiscalReceptor}
            />
          </Column>
          <Column className="is-6">
            <Input
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.nombreORazonSocial}
            />
          </Column>
        </Columns>
        <Columns>
          <Column className="is-6">
            <Input
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.rfc}
            />
          </Column>
          <Column className="is-6">
            <Input
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.correoElectronico}
            />
          </Column>
        </Columns>
        <AddressSection
          data={this.state.addressData}
          onChange={e => this.onChangeInput(e, 'addressData')}
          paises={paises}
          entidades={entidades}
          extranjero={false}
          showErrors={this.state.showErrors}
        />
        <Columns>
          <Column className="is-6">
            {this.state.generalData.tipoDePersona === PERSON_TYPE_FOREIGN && <Input
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.numRegIdTrib}
            />}
          </Column>
          <Column className="is-6">
          </Column>
        </Columns>
        <Columns>
          <Column className="is-6">
            <Select
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.formaPago}
              tooltip="Por disposición Oficial, ‘Pago en Parcialidades o Diferido’, se utiliza cuando el cliente a la fecha de emisión de la factura no ha realizado el pago, ’ Pago en Una sola Exhibición’ se utiliza cuando el cliente ya ha realizado el pago antes de la emisión de la factura."
            />
          </Column>
          <Column className="is-6">
            <Select
              options={paymentMethod}
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.metodoPago}
              tooltip="Por disposición oficial si el Método de Pago es ‘Pago en Parcialidades’, la Forma de Pago deberá ser ‘Por definir’"
            />
          </Column>
        </Columns>
        {
          (+this.state.generalData.metodoPago.value > 1 && +this.state.generalData.metodoPago.value < 10) &&
          <Columns>
            <Column className="is-6">
              <Input
                onChange={e => this.onChangeInput(e, 'generalData')}
                showErrors={showErrors}
                {...generalData.ultimos4Digitos}
              />
            </Column>
            <Column className="is-6">
            </Column>
          </Columns>
        }
        <Columns>
          <Column className="is-6">
            <Select
              options={this.getCfdiUseType(+this.state.generalData.tipoDePersona.value, this.props.usoCfdi, this.state.generalData.rfc.value)}
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.UsoCFDISAT}
              tooltip="Este campo deberá ser preguntado al cliente, en caso de no saber deberá de poner ‘Por definir’"
            />
          </Column>
          <Column className="is-6">
            <Select
              options={currencies}
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.divisaMoneda}
              tooltip="Moneda en la que se emitirá la factura."
            />
          </Column>
        </Columns>
        <Columns>
          <Column className="is-6">
            <Input
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              validation="required"
              {...generalData.tipoCambio}
              tooltip="Si la moneda es diferente a MXN, deberán especificar el tipo de cambio de la moneda especificada vs MXN, al tipo de cambio del día."
            />
          </Column>
          <Column className="is-6">
            <Select
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.cuentaCobranza}
              tooltip="En qué cuenta quiere recibir el cobro de la factura, se sugiere que si la Moneda Divisa de la Factura es MXN la cuenta de cobranza sea MXN, si la Moneda Divisa es otra la cuenta de cobranza sea USD."
            />
          </Column>
        </Columns>
        <Columns className="is-multiline">
          <Column className="is-6">
            <Select
              options={tipoRelacion}
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.tipoRelacion}
              tooltip="Se ocupa cuando la factura a realizar esté relacionada con facturas, anticipos o notas de crédito previamente timbradas, se necesitará el número de UUID o Folio Fiscal del documento previo."
            />
          </Column>
          <Column className="is-6">
            {!!generalData.uuidRelacionado.value && <Input
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...omit(['valueProperty', 'valueLabel'], generalData.uuidRelacionado)}
              tooltip="Favor de Poner el UUID o Folio de la factura fiscal anterior, se mostrarán las posibles relaciones a la factura actual, según la opción elegida."
            />}
            {!!generalData.uuidRelacionado.value || <Select
              options={relatedUiid}
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.uuidRelacionado}
              tooltip="Favor de Poner el UUID o Folio de la factura fiscal anterior, se mostrarán las posibles relaciones a la factura actual, según la opción elegida."
            />}
          </Column>
          <Show canShow={this.state.generalData.rfc.value === 'XAXX010101000'}>
            <Column className="is-6">
              <SwitchOld
                onChange={e => this.onChangeInput(e, 'generalData')}
                {...generalData.ventaGlobal}
              />
            </Column>
            <Show canShow={this.state.generalData.ventaGlobal.value === true}>
              <Column className="is-6">
                <Select
                  onChange={e => this.onChangeInput(e, 'generalData')}
                  options={periodicidad}
                  {...generalData.periodicidad}
                />
              </Column>
              <Column className="is-6">
                <Select
                  onChange={e => this.onChangeInput(e, 'generalData')}
                  options={meses}
                  {...generalData.meses}
                />
              </Column>
              <Column className="is-6">
                <Select
                  onChange={e => this.onChangeInput(e, 'generalData')}
                  options={this.getAnios()}
                  {...generalData.anio}
                />
              </Column>
            </Show>
          </Show>
        </Columns>

        <Divider content="CONCEPTOS" />
        <Columns>
          <Column>
            <Button
              style={{ float: 'right' }}
              onClick={this.openConceptModal}
            >
              Agregar Concepto
            </Button>
          </Column>
        </Columns>
        <Table
          colsetup={conceptsCol}
          coldata={this.state.conceptos}
          tableClassName='table is-striped is-hoverable is-fullwidth'
          emptyTableMarkUp={(<span>Aun no hay conceptos</span>)}
          customCol={{
            customColName: 'acciones',
            renderFunc: this.conceptActions
          }}
        />
        <Totals
          data={this.state.totals}
        />
        <Divider content="PAGOS" />
        <Table
          colsetup={paymentCol}
          coldata={installments}
          tableClassName='table is-striped is-hoverable is-fullwidth'
          emptyTableMarkUp={(<span>Aun no hay pagos</span>)}
        />
        <Divider content="COMPLEMENTO DE PAGOS" />
        <Table
          colsetup={complementaryPaymentCol}
          coldata={complementaryInstallments}
          tableClassName='table is-striped is-hoverable is-fullwidth'
          emptyTableMarkUp={(<span>Aun no hay pagos</span>)}
        />
        <Columns>
          {generalData.cuentaCobranza.value === COLLECTION_ACCOUNT_TYPE_MXN && <Column className="is-half">
            <SubTitle className="is-4">Para depósitos vía transferencia Electrónica:</SubTitle>
            <p><strong>Beneficiario:</strong> {accountsInfo.beneficiario}</p>
            <p><strong>Banco:</strong> STP (Sistema de Transferencias y Pagos)</p>
            <p><strong>Clabe: </strong> {this.state.generalData.cuentaClabeSTPAsignada.value}</p>
            <p><strong>Referencia: </strong>{order.clavePagoReferencia}</p>
            <p>&nbsp;</p>
            <SubTitle className="is-4">Para Depósitos en efectivo o Cheque:</SubTitle>
            <p><strong>Beneficiario:</strong> {accountsInfo.beneficiario}</p>
            <p>{accountsInfo.convenioCIE}</p>
            <p><strong>Referencia: </strong>{order.clavePagoReferencia}</p>
            {
              isOrderCreated &&
              (<div>
                <p>&nbsp;</p>
                <SubTitle className="is-4">Para Pagos con Tarjeta de Crédito o Débito:</SubTitle>
                <p><strong>Proveedor:</strong> MIT-Santander</p>
                {!this.props.order.nb_url &&
                  <Fragment>
                    <Box>
                      <h3>Meses sin intereses disponibles</h3>
                      {isAmountChanged &&
                        <small className="has-text-danger">Se detectó cambio en el total, es requerido guardar los cambios</small>}
                      {this.state.noInterest.map(it => <Check
                        onChange={this.handleNoInterest}
                        showErrors={showErrors}
                        name={it.plazo}
                        label={`${it.plazo} meses sin intereses`}
                        showFieldLabel={false}
                        value={this.state.selectedNoInterest[it.plazo]}
                        disabled={!(+this.state.totals.total.value >= it.montoMinimo &&
                          +this.state.totals.total.value <= it.montoMaximo)
                        }
                      />
                      )}
                      <Button
                        disabled={(+cash?.montoMinimo >= +this.state.totals.total.value
                          && +cash.montoMaximo >= +this.state.totals.total.value) || isAmountChanged}
                        onClick={this.getPaymentLink}>Crear link de pago</Button>
                    </Box>
                  </Fragment>
                }
                {!!this.props.order.nb_url &&
                  <div><a target="_blank" rel="noopener noreferrer" href={this.props.order.nb_url}>{this.props.order.nb_url}</a></div>}
                <p><small>*Aplica comisión por Pago con Tarjeta de Crédito</small></p>
              </div>)
            }
            {
              isOrderCreated &&
              (<div>
                <p>&nbsp;</p>
                <p><strong>Proveedor:</strong> STRIPE</p>
                {!this.props.order.stripeLink &&
                  <Fragment>
                    <Box>
                      {isAmountChanged &&
                        <small className="has-text-danger">Se detectó cambio en el total, es requerido guardar los cambios</small>}
                      <Button
                        disabled={(+cash?.montoMinimo >= +this.state.totals.total.value
                          && +cash.montoMaximo >= +this.state.totals.total.value) || isAmountChanged}
                        onClick={this.getStripeLink}>Crear link de pago
                      </Button>
                    </Box>
                  </Fragment>
                }
                {!!this.props.order.stripeLink &&
                  <div><a target="_blank" rel="noopener noreferrer" href={this.props.order.stripeLink}>{this.props.order.stripeLink}</a></div>}
                <p><small>*Aplica comisión por Pago con Tarjeta de Crédito</small></p>
                {!this.props.order.stripeSubscriptionLink && this.state.conceptos.data.length === 1 && <Box>
                  <SubTitle className="is-4">Crear una subscripción</SubTitle>
                  <div className="control">
                    <h1 className="title is-6">¡Seleccione el intervalo!</h1>
                    <div className="select" >
                      <select value={this.state.subscription.interval} name="interval" onChange={this.onChangeSubscription}>
                        <option value="">Seleccione el intervalo</option>
                        <option value="month">Mes</option>
                        <option value="year">Año</option>
                      </select>
                    </div>
                  </div>
                  {this.state.subscription.interval === 'month' && <div className="control">
                    <h1 className="title is-7">¡Seleccione la frecuencia de cobro!</h1>
                    <div className="select" >
                      <select value={this.state.subscription.intervalCount} name="intervalCount" onChange={this.onChangeSubscription}>
                        <option value="">Seleccione la frecuencia</option>
                        <option value="1">Cada mes</option>
                        <option value="3">Cada 3 meses</option>
                        <option value="6">Cada 6 meses</option>
                      </select>
                    </div>
                  </div>}
                  <Control>
                    <Button
                      onClick={this.createSubscription}>Crear subscripción</Button>
                  </Control>
                  <p><small>*Aplica comisión por Pago con Tarjeta de Crédito</small></p>
                </Box>}
                {!!this.props.order.stripeSubscriptionLink &&
                  <div>
                    <p>&nbsp;</p>
                    <h5>Link para pago generar una subscripción</h5>
                    <a target="_blank" rel="noopener noreferrer" href={this.props.order.stripeSubscriptionLink}>{this.props.order.stripeSubscriptionLink}</a></div>}
                {!this.props.order.stripeOxxoLink && <Box>
                  <SubTitle className="is-7">Pagos en Oxxo</SubTitle>
                  <Button
                    onClick={this.createOxxoLink}>Crear referencia para pagar en Oxxo</Button>
                  <p><small>*Aplica comisión por Pago en Oxxo</small></p>
                </Box>}
                {!!this.props.order.stripeOxxoLink &&
                  <div>
                    <p>&nbsp;</p>
                    <h5>Link para pago en oxxo</h5>
                    <a target="_blank" rel="noopener noreferrer" href={this.props.order.stripeOxxoLink}>
                      {this.props.order.stripeOxxoLink}
                    </a>
                  </div>}
              </div>)
            }
          </Column>}
          {generalData.cuentaCobranza.value === COLLECTION_ACCOUNT_TYPE_USD &&
            <Column className="is-half">
              <SubTitle className="is-4">Para Transferencias Internacionales:</SubTitle>
              <SubTitle className="is-5">Transferencias Directas:</SubTitle>
              {accountsInfo.directo && accountsInfo.directo.split('\n').map(it => <p>{it}</p>)}
              <p>&nbsp;</p>
              <SubTitle className="is-5">Transferencias con Banco Intermediario:</SubTitle>
              {accountsInfo.intermediario && accountsInfo.intermediario.split('\n').map(it => <p>{it}</p>)}
            </Column>
          }
          <Column>
            <SubTitle className="is-4">
              Cancelación Facturas / Notas de crédito
            </SubTitle>
            <Table
              colsetup={creditNotesCol}
              coldata={{ data: order.cancelaciones || [] }}
              tableClassName='table is-striped is-hoverable is-fullwidth'
              emptyTableMarkUp={(<span>Sin datos</span>)}
            />
          </Column>
        </Columns>
        <Attachments
          files={this.props.attachments}
        />
      </Box >
    )
  }
}

const mapStateToProps = ({ catalogs, catalogos, incomeOrders, principal }) => ({
  clients: catalogs.clients.clients,
  client: catalogs.clients.client,
  paises: catalogos.paises,
  entidades: catalogos.entidades,
  invoiceStatus: catalogos.invoiceStatus,
  tipoPersona: catalogos.tipoPersona,
  paymentMethod: catalogos.paymentMethod,
  order: incomeOrders.invoice.order,
  currencies: catalogos.currencies,
  usoCfdi: catalogos.usoCfdi,
  invoiceUnits: catalogos.invoiceUnits,
  productService: catalogos.productService,
  ivaRates: reject(propEq('id', '4'))(catalogos.ivaRates),
  iepsRates: catalogos.iepsRates,
  tipoRelacion: catalogos.tipoRelacion,
  relatedUiid: incomeOrders.invoice.relatedUiid,
  installments: incomeOrders.invoice.installments,
  complementaryInstallments: incomeOrders.invoice.complementaryInstallments,
  accountsInfo: principal.accountsInfo,
  products: incomeOrders.invoice.products,
  product: incomeOrders.invoice.product,
  productSat: catalogos.productSat,
  reference: incomeOrders.invoice.reference,
  advance: incomeOrders.advancePayment.advance,
  attachments: incomeOrders.invoice.attachments,
  isFetching: incomeOrders.invoice.isFetching,
  mitLink: incomeOrders.invoice.mitLink,
  noInterest: principal.noInterest,
  origin: incomeOrders.invoice.origin,
  options: incomeOrders.invoice.options,
  exportation: catalogos.exportation,
  regimenFiscal: catalogos.regimenFiscal,
  periodicidad: catalogos.periodicidad,
  meses: catalogos.meses,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getOrder,
  getClients,
  getClient,
  getEntidades,
  getPaises,
  getInvoiceStatus,
  addNotification,
  getTipoPersona,
  getPaymentMethod,
  createOrder,
  getCurrencies,
  getUsoCfdi,
  push,
  getInvoiceUnits,
  getIvaRates,
  getIepsRates,
  clearInvoiceUnits,
  getProductService,
  clearProductService,
  getTipoRelacion,
  endOrderEdit,
  getRelatedUiid,
  getInstallments,
  getComplementaryInstallments,
  authorizeOrder,
  updateOrder,
  deleteOrder,
  getAccountsInfo,
  findProducts,
  clearFinder,
  getProduct,
  getProductSat,
  addProduct,
  resetAdvance,
  getAttachments,
  getMitLink,
  getNoInterestConfiguration,
  getStripeLink,
  cleanOrigin,
  getOptions,
  createSubscription,
  createOxxoLink,
  getExportation: getExportationAction,
  getRegimenFiscal: getRegimenFiscalAction,
  getPeriodicidad: getPeriodicidadAction,
  getMeses: getMesesAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Form)