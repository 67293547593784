import axios from 'axios'
import { getHeaders } from 'Modules/util'

const url = process.env.REACT_APP_API

const GET_PROVIDERS = 'catalogs/provider/GET_PROVIDERS'
const GET_PROVIDER = 'catalogs/provider/GET_PROVIDER'
const CREATE_PROVIDER = 'catalogs/provider/CREATE_PROVIDER'
const UPDATE_PROVIDER = 'catalogs/provider/UPDATE_PROVIDER'
const DELETE_PROVIDER = 'catalogs/provider/DELETE_PROVIDER'
const END_EDIT_PROVIDER = 'catalogs/provider/END_EDIT_PROVIDER'
const GET_ACCOUNTS = 'catalogs/provider/GET_ACCOUNTS'
const CREATE_ACCOUNT = 'catalogs/provider/CREATE_ACCOUNT'
const IS_FETCHING_PROVIDERS = 'catalogs/provider/IS_FETCHING_PROVIDERS'
const IS_FETCHING_PROVIDERS_COMPLETE = 'catalogs/provider/IS_FETCHING_PROVIDERS_COMPLETE'
export const DELETE_ACCOUNT = 'catalogs/provider/DELETE_ACCOUNT'
export const CLEAR_PROVIDER_LIST = 'catalogs/provider/CLEAR_PROVIDER_LIST'
export const CLEAR_PROVIDER = 'catalogs/provider/CLEAR_PROVIDER'

const initialState = {
  providers: {
    data: []
  },
  provider: {},
  accounts: {
    data: []
  },
  isFetchingProviders: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PROVIDERS:
    case GET_PROVIDER:
    case UPDATE_PROVIDER:
    case CREATE_PROVIDER:
    case END_EDIT_PROVIDER:
    case GET_ACCOUNTS:
      return { ...state, ...action.payload }
    case DELETE_PROVIDER:
      const { data: dataProv, ...metaProv } = state.providers
      return { ...state, providers: { data: dataProv.filter(it => it.id !== action.payload), ...metaProv, last_page: Math.ceil(dataProv.length / metaProv.per_page) } }
    case DELETE_ACCOUNT:
      const { data, ...meta } = state.accounts
      return { ...state, accounts: { data: data.filter(it => it.id !== action.payload), ...meta, last_page: Math.ceil(data.length / meta.per_page) } }
    case IS_FETCHING_PROVIDERS:
      return { ...state, isFetchingProviders: true }
    case IS_FETCHING_PROVIDERS_COMPLETE:
      return { ...state, isFetchingProviders: false }
    case CLEAR_PROVIDER:
      return { ...state, provider: {} }
    case CLEAR_PROVIDER_LIST:
      return { ...state, providers: initialState.providers }
    default:
      return state
  }
}

export const isFetchingProviders = () => ({
  type: IS_FETCHING_PROVIDERS
})

export const isFetchingProvidersComplete = () => ({
  type: IS_FETCHING_PROVIDERS_COMPLETE
})

export const getProviders = (params) => {
  const headers = getHeaders();
  return dispatch => {
    dispatch(isFetchingProviders());
    return axios.post(`${url}/provider/search`, params, { headers })
      .then(({ data }) => {
        dispatch(isFetchingProvidersComplete());
        dispatch({
          type: GET_PROVIDERS,
          payload: { providers: data }
        })
      })
  }
}

export const getProvider = (id) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/provider/${id}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_PROVIDER,
          payload: { provider: data }
        })
        return data
      })
  }
}

export const getProviderGasoline = (id) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/provider/gasoline/${id}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_PROVIDER,
          payload: { provider: data }
        })
        return data
      })
  }
}

export const endEditProvider = () => ({
  type: END_EDIT_PROVIDER,
  payload: { provider: {}, accounts: { data: [] } },
})

export const createProvider = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingProviders());
    return axios.post(`${url}/provider`, params, { headers })
      .then(response => {
        dispatch(isFetchingProvidersComplete());
        dispatch({
          type: CREATE_PROVIDER,
          payload: { provider: response.data }
        })
        return response
      })
  }
}
export const updateProvider = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingProviders());
    return axios.put(`${url}/provider/${params.id}`, params, { headers })
      .then(response => {
        dispatch(isFetchingProvidersComplete());
        dispatch({
          type: UPDATE_PROVIDER
        })
        return response
      })
  }
}

export const getProviderAccounts = (idProvider, params) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/bank/${idProvider}`, { headers, params })
      .then(({ data }) => {
        dispatch({
          type: GET_ACCOUNTS,
          payload: { accounts: data }
        })
      })
  }
}

export const getProviderAccount = (idProvider, accoun) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/bank/${idProvider}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_ACCOUNTS,
          payload: { accounts: data }
        })
      })
  }
}

export const createAccount = (providerId, account) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/bank/${providerId}`, account, { headers })
      .then(({ data }) => {
        dispatch({
          type: CREATE_ACCOUNT
        })
      })
  }
}

export const deleteProvider = (providerId) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/provider/${providerId}`, { headers })
      .then(() => {
        dispatch({
          type: DELETE_PROVIDER,
          payload: providerId,
        })
      })
  }
}

export const deleteAccount = (providerId, accountId) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/bank/${providerId}/${accountId}`, { headers })
      .then(() => {
        dispatch({
          type: DELETE_ACCOUNT,
          payload: +accountId,
        })
      })
  }
}

export const clearProvidersList = () => ({
  type: CLEAR_PROVIDER_LIST,
})

export const clearProvider = () => ({
  type: CLEAR_PROVIDER,
})