import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { TransferForm, default as TransferList, PendingToAuthorize } from 'Containers/transferOrders'


export default () => (
  <Switch>
    <Route exact path="/ordenes-traspaso" component={TransferList} />
    <Route exact path="/ordenes-traspaso/pendientes" component={PendingToAuthorize} />
    <Route exact path="/ordenes-traspaso/nuevo" component={TransferForm} />
    <Route exact path="/ordenes-traspaso/:id" component={TransferForm} />
  </Switch>
)
