import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Box, Columns, Column } from 'Components/common/bulma'
import Table from 'Components/common/table'
import { getList } from 'Modules/incomeOrders/collectionCard'
import { colData } from './data'


export class ListaContainer extends Component {

  render() {
    const { list } = this.props

    return (
      <Box>
        <Columns>
          <Column>
            <Table
              filterable
              style={{width: '150%'}}
              className="is-size-7"
              colsetup={colData}
              coldata={list}
              tableClassName="table is-striped is-hoverable is-fullwidth"
              emptyTableMarkUp={<span>No se encontraron registros</span>}
              customCol={{
                customColName: 'actions',
                renderFunc: () => {}
              }}
              refresh={this.props.getList}
            />
          </Column>
        </Columns>
      </Box>
    )
  }
}

const mapStateToProps = ({ incomeOrders }) => ({
  list: incomeOrders.collectionCard.list,
  isFetching: incomeOrders.collectionCard.isFetching
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getList,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ListaContainer)
