import axios from 'axios'
import { getHeaders } from './util'

const url = process.env.REACT_APP_API

export const GET_CONTRACTS = 'CONTRACTS/GET_CONTRACTS';
export const GET_CONTRACT = 'CONTRACTS/GET_CONTRACT';
export const GET_SIGNATURE_CATALOG = 'CONTRACTS/GET_SIGNATURE_CATALOG';
export const GET_CONTRACT_STATUS = 'CONTRACTS/GET_CONTRACT_STATUS';
export const CREATE_CONTRACT = 'CONTRACTS/CREATE_CONTRACT';
export const UPDATE_CONTRACT = 'CONTRACTS/UPDATE_CONTRACT';
export const AUTHORIZE = 'CONTRACTS/AUTHORIZE';
export const AUTHORIZE_TFA = 'CONTRACTS/AUTHORIZE_TFA';
export const RESET_CONTRACT = 'CONTRACTS/RESET_CONTRACT';
export const DELETE_CONTRACT = 'CONTRACTS/DELETE_CONTRACT';
export const GET_CONTRACT_FILES = 'CONTRACTS/GET_CONTRACT_FILES';

const initialState = {
  contracts: { data: []},
  contract: undefined,
  signatureType: [],
  contractStatus: [],
  files: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_CONTRACT:
    case GET_SIGNATURE_CATALOG:
    case GET_CONTRACTS:
    case GET_CONTRACT:
    case AUTHORIZE_TFA:
    case GET_CONTRACT_STATUS:
    case GET_CONTRACT_FILES:
    case UPDATE_CONTRACT:
      return { ...state, ...payload };
    case AUTHORIZE:
          return { ...state, contract: {...state.contract, estatus: payload.estatus} };
    case RESET_CONTRACT:
      return initialState;
    default:
      return state;
  }
};


export const getContractsAction = (params) => {
  return dispatch => {
    return axios.get(`${url}/contracts`, { headers: getHeaders(), params })
      .then(({ data }) => {
        dispatch({
          type: GET_CONTRACTS,
          payload: { contracts: data }
        })
      })
  }
}
export const getContractAction = (contractId) => {
  return dispatch => {
    return axios.get(`${url}/contracts/${contractId}`, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: GET_CONTRACT,
          payload: { contract: data }
        })
      })
  }
}

export const deleteContractAction = (contractId) => {
  return dispatch => {
    return axios.delete(`${url}/contracts/${contractId}`, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: DELETE_CONTRACT,
        })
      })
  }
}

export const getSignatureCatalogAction = () => {
  return dispatch => {
    return axios.get(`${url}/tipoFirma`, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: GET_SIGNATURE_CATALOG,
          payload: { signatureType: data }
        })
      })
  }
}

export const getContractStatusAction = () => {
  return dispatch => {
    return axios.get(`${url}/estatusContrato`, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: GET_CONTRACT_STATUS,
          payload: { contractStatus: data }
        })
      })
  }
}

export const createContractAction = (contract) => {
  return dispatch => {
    return axios.post(`${url}/contracts`, contract,{ headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: CREATE_CONTRACT,
          payload: { contract: data }
        })
        return data
      })
  }
}

export const updateContractAction = ({id, ...contract}) => {
  return dispatch => {
    return axios.put(`${url}/contracts/${id}`, contract,{ headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: UPDATE_CONTRACT,
          payload: { contract: data }
        })
        return data
      })
  }
}

export const authorizeAction = (payload) => {
  return dispatch => {
    return axios.post(`${url}/contracts/authorize/${payload.id}`, payload,{ headers: getHeaders() })
      .then((data) => {
        dispatch({
          type: AUTHORIZE,
          payload: data.data,
        })
        return data
      })
  }
}

export const uploadContract = (payload) => {
  return axios.post(`${url}/contracts/upload`, payload, { headers: getHeaders() } )
}

export const resetContractAction = () => ({
  type: RESET_CONTRACT,
})

export const getContractFilesAction = (payload) => {
  return dispatch => {
    return axios.get(`${url}/contracts/documents/${payload.id}`,{ headers: getHeaders() })
      .then((data) => {
        dispatch({
          type: GET_CONTRACT_FILES,
          payload: {files: data.data},
        })
        return data
      })
  }
}