
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import {
  getList,
  setCurrentTransaction,
} from 'Modules/conciliations'
import {
  List
} from './data'
import { Table } from 'Components/common'
import {
  Box,
  Columns,
  Column,
  Button,
} from 'Components/common/bulma'
import { Select } from 'Components/common/form'
export {default as TransactionForm} from './TransactionForm'
export {default as ConciliateForm } from './conciliateForm'

class ConciliationsList extends Component {
  state = {
    moneda:''
  }
  componentDidMount() {
    const { getList } = this.props
    const query = new URLSearchParams(this.props.location.search)

    getList({query: query.get('moneda') })
    this.setState({
      moneda: query.get('moneda'),
    })
  }

  customColAccions = row => (
    <Fragment>
      <Button small
        onClick={() => this.onConciliate(row)}
      >Conciliar</Button>
      <Button small primary onClick={() => this.onEdit(row)}>Detalle</Button>
    </Fragment>
  )

  onEdit = ({ idAgregador}) => {
    this.props.push(`/conciliaciones/${idAgregador}`)
  }

  onConciliate = transaction => {
    this.props.setCurrentTransaction(transaction)
    this.props.push(`/conciliaciones/conciliar/${transaction.idConciliacion}/${transaction.idAgregador}`)
  }

  changeFilter = ({target: {value}}) => {
    this.setState({
      moneda: value
    }, () => {
      this.props.getList({query: value})
    })
  }

  render() {
    const { list } = this.props
    return (
      <Box>
        <Columns className="is-multiline">
          <Column className="is-offset-8">
            <Select
              onChange={this.changeFilter}
              name="moneda"
              id="moneda"
              label="Moneda"
              options={[
                {value: 'MXN', label: 'MXN'},
                {value: 'USD', label: 'USD'},
              ]}
              value={this.state.moneda}
            />
          </Column>
          <Column className="is-full">
            <Table
              colsetup={List}
              coldata={list}
              tableClassName='table is-striped is-hoverable is-fullwidth'
              emptyTableMarkUp={<span>No hay registros</span>}
              customCol={{
                customColName: 'actions',
                renderFunc: this.customColAccions
              }}
              refresh={this.filterData}
            />
          </Column>
        </Columns>
      </Box>
    );
  }
}

function mapStateToProps({ conciliations }) {
  return {
    list: conciliations.list,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getList,
    push,
    setCurrentTransaction,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConciliationsList);
