import React from 'react'

function Show({
  canShow = false,
  children,
}) {
  return (<>{canShow ? children : ''}</>)
}

export default Show
