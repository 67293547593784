import React, { useState } from 'react'
import useForm from 'Hooks/useForm'
import { Box, Divider, Modal, Message, Delete, Columns, Column, Button } from 'Components/common/bulma'
import Address from 'Components/paymentOrders/shipping/address'
import Reference from 'Components/paymentOrders/shipping/reference'
import Package from 'Components/paymentOrders/shipping/package'
import Notes from 'Components/paymentOrders/shipping/notes'
import { address, reference, parcels, notes } from './shippingDefinition'
import { isLenEquals, evolveCustom } from 'App/helpers'
import {
  getDireccion,
} from 'Modules/catalogos'
import http from 'App/services/httpService';
import AddressService from 'App/services/addressService';
import ShippingService from 'App/services/shippingService';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { head, propOr, allPass, of } from 'ramda'
import { push as pushAction } from 'connected-react-router';
import Loader from 'Components/common/page/loaderFloat';
import Show from 'Components/common/Show'
import ActionBar from 'Components/common/actionBar';


const addressService = new AddressService(http);
const shippingService = new ShippingService(http);

const completeAddress = (zipCode, stateSetter) => {
  addressService.getAddressesByZipCode(zipCode).then(({ data: adresses }) => {
    const address = head(adresses)
    if (address) {
      const addressCompletion = evolveCustom({
        address2: propOr('', 'asentamiento'),
        city: propOr('', 'municipio'),
        province: propOr('', 'estado')
      })

      stateSetter(addressCompletion(address))
    }
  })
}

const isRequiredZipLen = isLenEquals(5)

const zipUpdate = (zip, setFields) => {
  if (isRequiredZipLen(zip)) {
    completeAddress(zip, setFields)
  }
}


function ShippingQuoteForm({
  push,
}) {
  const [isLoading, setIsLoading] = useState(true)
  const [isSelectingPackage, setIsSelectingPackage] = useState(false)
  const [rates, setRates] = useState({ rates: [] })
  const [senderFields, setSenderFields, onChangeSenderFields, isValidSenderForm, getSenderValues, showSenderErrors,
  ] = useForm({
    fields: address,
    callbacks: {
      zip: (zip) => zipUpdate(zip, setSenderFields),
    }
  })
  const [receiverFields, setReceiverFields, onChangeReceiverFields, isValidReceiverForm, getReceiverValues, showReceiverErrors,
  ] = useForm({
    fields: address,
    callbacks: {
      zip: (zip) => zipUpdate(zip, setReceiverFields),
    },
  })
  const [
    referenceFields, , onChangeReferenceFields, isValidReferenceForm, getReferenceValues, showReferenceErrors
  ] = useForm({ fields: reference })
  const [
    packageFields, , onChangePackageFields, isValidPackageForm, getPackageValues, showPackageErrors
  ] = useForm({ fields: parcels })
  const [
    noteFields, , onChangeNoteFields, isValidNoteForm, getNoteValues, showNoteErrors
  ] = useForm({ fields: notes })



  const handleOnCreate = async () => {

    const isFormsValid = allPass([
      isValidSenderForm,
      isValidReceiverForm,
      isValidReferenceForm,
      isValidNoteForm,
      isValidPackageForm,
    ])()

    if (isFormsValid) {
      const shipment = {
        address_from: getSenderValues(),
        address_to: { ...getReceiverValues(), ...getReferenceValues() },
        parcels: of(getPackageValues()),
        ...getNoteValues(),
      }
      try {
        setIsLoading(true)
        const rates = await shippingService.createShipment(shipment)
        setRates(rates)
        setIsLoading(false)
        setIsSelectingPackage(true)
      } catch (error) {

      }
    }

  }

  const onCancelPackage = () => {
    setRates([])
    setIsSelectingPackage(false)
  }

  const onSelectProvider = async (rate) => {
    try {
      setIsLoading(true)
      const order = await shippingService.createShippingOrder({
        receiver: getReceiverValues(),
        rate_id: rate.id,
        provider: rate.provider,
        service_level_name: rate.service_level_name,
        total_pricing: rate.total_pricing,
        contents: referenceFields.contents.input.value,
        shipment_id: rates.id,
      })
      setIsLoading(false)
      push(`/ordenes-pago/paqueteria/${order.id}`)
    } catch (error) {
      console.log(error)
    }
  }

  const handleOnReturn = () => {
    push(`/ordenes-pago/paqueteria`)
  }

  return (
    <Box style={{ position: 'relative' }}>
      <Show canShow={isLoading}>
        <Loader />
      </Show>
      <ActionBar
        onCancel={handleOnReturn}
        labels={{ delete: 'Elimnar', cancel: 'Regresar' }}
      />
      <Modal isActive={isSelectingPackage}>
        <Message default>
          <Message.Header>
            <p>Seleccionar paqueteria</p>
            <Delete onClick={onCancelPackage} />
          </Message.Header>
          <Message.Body>
            <Columns>
              <Column>
                <h5 className="title is-3">Seleccione una paqueteria</h5>
                <div>
                  <p>Para estas especificaciones del paquete</p>
                  <ul>
                    <li>
                      <span>Peso: <strong>{packageFields.weight.input.value}kg</strong></span>{'    '}<span>Largo: <strong> {packageFields.length.input.value}cm</strong></span>
                    </li>
                    <li>
                      <span>Ancho: <strong>{packageFields.width.input.value}cm</strong></span>{'    '}<span>Alto: <strong>{packageFields.height.input.value}cm</strong></span>
                    </li>

                  </ul>
                </div>
                <br />
                {
                  rates.rates.map(it => (
                    <Box key={it.id} onClick={() => onSelectProvider(it)}>
                      <Columns>
                        <Column>
                          <h5 className="title is-5">{it.provider}</h5>
                          <h5 className="subtitle is-6">{it.service_level_name}</h5>
                        </Column>
                        <Column>
                          <h5 className="title is-4">$ {it.total_pricing}</h5>
                        </Column>
                      </Columns>
                    </Box>
                  ))
                }
              </Column>
            </Columns>
          </Message.Body>
        </Message>
      </Modal>
      <Divider content="Datos de la persona que envía" />
      <Address
        addressFields={senderFields}
        onChange={onChangeSenderFields}
        showErrors={showSenderErrors}
      />
      <Divider content="Datos de la persona que recibe" />
      <Address
        addressFields={receiverFields}
        onChange={onChangeReceiverFields}
        showErrors={showReceiverErrors}
      />
      <Reference
        referenceFields={referenceFields}
        onChange={onChangeReferenceFields}
        showErrors={showReferenceErrors}
      />
      <Notes
        fields={noteFields}
        onChange={onChangeNoteFields}
        showErrors={showNoteErrors}
        setIsLoading={setIsLoading}
      />
      <Divider content="Dimensiones del paquete" />
      <Package
        packageFields={packageFields}
        onChange={onChangePackageFields}
        showErrors={showPackageErrors}
      />
      <Columns>
        <Column>
          <Button
            onClick={handleOnCreate}
            className="is-large is-info is-outlined is-pulled-right">
            Cotizar
          </Button>
        </Column>
      </Columns>

    </Box>
  )
}

const mapStateToProps = ({ users }) => ({
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getAddressByZipCode: getDireccion,
  push: pushAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ShippingQuoteForm)
