import React from 'react'
import classnames from 'classnames'

const Column = ({ className, ...props }) => {
  const classes = classnames('column', className)
  return (
    <div className={classes} {...props} />
  )
}

export default Column
