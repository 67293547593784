import { format, parseISO } from 'date-fns/fp'
import { cond, T, F, always, propOr, equals, pipe } from 'ramda'
import { isEmpty, isNotEmail } from 'App/helpers'

const toCheck = pipe(Number, equals(1))

export const usersColumn = [
  {
    title: 'Id',
    datakey: 'id',
    width: '5%',
  },
  {
    title: 'Estatus',
    datakey: 'status',
    width: '8%',
  },
  {
    title: 'Apellido Paterno',
    datakey: 'lastName',
    width: '15%',
  },
  {
    title: 'Apellido Materno',
    datakey: 'secondLastName',
    width: '15%',
  },
  {
    title: 'Nombre',
    datakey: 'name',
    width: '15%',
  },
  {
    title: 'Correo',
    datakey: 'email',
    width: '15%',
  },
  {
    title: 'Acciones',
    custom: 'actions',
    width: '8%',
  },
];

export const userFieldsDefinition = {
  id: {
    input: {
      value: '',
      name: 'id',
    },
    label: 'Id',
  },
  creationDate: {
    input: {
      value: '',
      name: 'creationDate',
    },
    label: 'Fecha Alta',
    disabled: true,
    unmarshaller: pipe(parseISO, format('dd/MM/yyyy')),
  },
  lastName: {
    input: {
      value: '',
      name: 'lastName',
    },
    label: 'Apellido Paterno',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  secondLastName: {
    input: {
      value: '',
      name: 'secondLastName',
    },
    label: 'Apellido Materno',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  name: {
    input: {
      value: '',
      name: 'name',
    },
    label: 'Nombre(s)',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  email: {
    input: {
      value: '',
      name: 'email',
    },
    label: 'Correo Electrónico',
    validate: cond([
      [isEmpty, always({ error: '¡Este campo es requerido!' })],
      [isNotEmail, always({ error: '¡El valor debe ser un correo electrónico válido!' })],
      [T, F]
    ]),
  },
  statusId: {
    input: {
      value: '',
      name: 'statusId',
    },
    label: 'Estatus',
    disabled: true,
    validation: 'required',
    valueProperty: 'id',
    valueLabel: 'estatus',
  },
  user: {
    input: {
      value: '',
      name: 'user',
      disabled: true,
    },
    label: 'Tarjeta Acceso Seguro',
  },
  oFacturacion: {
    input: {
      value: false,
      name: 'oFacturacion',
    },
    label: '',
    showFieldLabel: false,
    unmarshaller: toCheck,
  },
  aFacturacion: {
    input: {
      value: false,
      name: 'aFacturacion',
    },
    label: '',
    showFieldLabel: false,
    unmarshaller: toCheck,
  },
  oDepositos: {
    input: {
      value: false,
      name: 'oDepositos',
    },
    label: '',
    showFieldLabel: false,
    unmarshaller: toCheck,
  },
  aDepositos: {
    input: {
      value: false,
      name: 'aDepositos',
    },
    label: '',
    showFieldLabel: false,
    unmarshaller: toCheck,
  },
  oPagoProveedores: {
    input: {
      value: false,
      name: 'oPagoProveedores',
    },
    label: '',
    showFieldLabel: false,
    unmarshaller: toCheck,
  },
  aPagoProveedores: {
    input: {
      value: false,
      name: 'aPagoProveedores',
    },
    label: '',
    showFieldLabel: false,
    unmarshaller: toCheck,
  },
  oRetiros: {
    input: {
      value: false,
      name: 'oRetiros',
    },
    label: '',
    showFieldLabel: false,
    unmarshaller: toCheck,
  },
  aRetiros: {
    input: {
      value: false,
      name: 'aRetiros',
    },
    label: '',
    showFieldLabel: false,
    unmarshaller: toCheck,
  },
  oTraspaso: {
    input: {
      value: false,
      name: 'oTraspaso',
    },
    label: '',
    showFieldLabel: false,
    unmarshaller: toCheck,
  },
  aTraspaso: {
    input: {
      value: false,
      name: 'aTraspaso',
    },
    label: '',
    showFieldLabel: false,
    unmarshaller: toCheck,
  },
  oPresAcreedoras: {
    input: {
      value: false,
      name: 'oPresAcreedoras',
    },
    label: '',
    showFieldLabel: false,
    unmarshaller: toCheck,
  },
  aPresAcreedoras: {
    input: {
      value: false,
      name: 'aPresAcreedoras',
    },
    label: '',
    showFieldLabel: false,
    unmarshaller: toCheck,
  },
  oPresDeudoras: {
    input: {
      value: false,
      name: 'oPresDeudoras',
    },
    label: '',
    showFieldLabel: false,
    unmarshaller: toCheck,
  },
  aPresDeudoras: {
    input: {
      value: false,
      name: 'aPresDeudoras',
    },
    label: '',
    showFieldLabel: false,
    unmarshaller: toCheck,
  },
  oPresAmortizacionCobroPrestamo: {
    input: {
      value: false,
      name: 'oPresAmortizacionCobroPrestamo',
    },
    label: '',
    showFieldLabel: false,
    unmarshaller: toCheck,
  },
  aPresAmortizacionCobroPrestamo: {
    input: {
      value: false,
      name: 'aPresAmortizacionCobroPrestamo',
    },
    label: '',
    showFieldLabel: false,
    unmarshaller: toCheck,
  },
  oPresAmortizacionPagoPrestamo: {
    input: {
      value: false,
      name: 'oPresAmortizacionPagoPrestamo',
    },
    label: '',
    showFieldLabel: false,
    unmarshaller: toCheck,
  },
  aPresAmortizacionPagoPrestamo: {
    input: {
      value: false,
      name: 'aPresAmortizacionPagoPrestamo',
    },
    label: '',
    showFieldLabel: false,
    unmarshaller: toCheck,
  },
  oAlta: {
    input: {
      value: false,
      name: 'oAlta',
    },
    label: '',
    showFieldLabel: false,
    unmarshaller: toCheck,
  },
  oConciliaciones: {
    input: {
      value: false,
      name: 'oConciliaciones',
    },
    label: '',
    showFieldLabel: false,
    unmarshaller: toCheck,
  },
  cInventarios: {
    input: {
      value: false,
      name: 'cInventarios',
    },
    label: '',
    showFieldLabel: false,
    unmarshaller: toCheck,
  },
  aUsuario: {
    input: {
      value: false,
      name: 'aUsuario',
    },
    label: '',
    showFieldLabel: false,
    unmarshaller: toCheck,
  },
  cReportes: {
    input: {
      value: false,
      name: 'cReportes',
    },
    label: '',
    showFieldLabel: false,
    unmarshaller: toCheck,
  },
  aContratos: {
    input: {
      value: false,
      name: 'aContratos',
    },
    label: '',
    showFieldLabel: false,
    unmarshaller: toCheck,
  },
  oContratos: {
    input: {
      value: false,
      name: 'oContratos',
    },
    label: '',
    showFieldLabel: false,
    unmarshaller: toCheck,
  },

}

export const initialAssigmentFields = {
  asignarTarjeta: {
    input: {
      value: true,
      name: 'asignarTarjeta',
    },
    label: 'Asignar tarjeta',
  },
  assignmentReason: {
    input: {
      value: '',
      name: 'assignmentReason',
    },
    options: [
      { key: 'Alta Usuario Nuevo', label: 'Alta Usuario Nuevo' },
      { key: 'Reposición Tarjeta', label: 'Reposición Tarjeta' },
    ],
    label: 'Motivo Asignación Tarjeta',
    validate: (value, key, form) => {
      const assign = propOr(false, 'asignarTarjeta')(form)

      if (assign.input.value) {
        return cond([
          [isEmpty, always({ error: '¡Este campo es requerido!' })],
          [T, F]
        ])(value)
      }
      return false
    },
    //(a, b, c) => {console.log(a, b, c); return {}},
  },
}

export const initialRevokmentFields = {
  revokmentReason: {
    input: {
      value: '',
      name: 'revokmentReason',
    },
    options: [
      { key: 'Robo de Tarjeta', label: 'Robo de Tarjeta' },
      { key: 'Extravió Tarjeta', label: 'Extravió Tarjeta' },
    ],
    label: 'Motivo Revocación Tarjeta',
    validate: cond([
      [isEmpty, always({ error: '¡Este campo es requerido!' })],
      [T, F]
    ]),
  },
}