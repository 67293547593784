import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Box,
  Columns,
  Column,
  Divider,
  Button,
  Modal,
  Message,
  Delete,
  Icon,
} from 'Components/common/bulma'
import {
  ActionBar,
  Table,
  ModalRemove,
} from 'Components/common'
import {
  Select,
  Input,
} from 'Components/common/form'
import { bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import { retreat, addressData, paymentTable, installment, totals } from './data'
import {
  getPaymentEstatus,
  getTipoPersona,
  getPaises,
  getEntidades,
  getRealPaymentMethod,
} from 'Modules/catalogos'
import { mapPropsToState, getDataInput, getValues, validateData } from 'App/helpers'
import { AddressSection, } from 'Components/catalogs/providers'
import { propEq, prop, find, reduce, add, equals } from 'ramda'
import {
  updateOrder,
  createOrder,
  getOrder,
  getInstallments,
  endOrderEdit,
  authorizeInstallment,
  addInstallment,
  deleteInstallment,
  deleteOrder,
} from 'Modules/paymentOrders/retreats'
import { cleanOrigin } from 'Modules/paymentOrders/providers'
import { addNotification } from 'Modules/principal'
import Installment from 'Components/paymentOrders/retreats/Installment'
import Totals from 'Components/paymentOrders/retreats/Totals'
import moment from 'moment'
import {
  faTrash,
  faEdit,
  faKey,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Can, Authorize } from 'Containers/auth';

const AUTHORIZE_PERMISSION = ['Aretiros']
const STATUS_PARTIAL_AUTHORIZED = '9'

// const STATUS_REFUSED = '4'
const STATUS_CREATED = '1'
export class Form extends Component {
  state = {
    order: retreat,
    addressData,
    totals,
  }

  componentDidMount() {
    const {
      getPaymentEstatus,
      getTipoPersona,
      getPaises,
      getEntidades,
      getRealPaymentMethod,
      match,
      getOrder,
      getInstallments,
    } = this.props

    getPaymentEstatus()
    getTipoPersona()
    getPaises()
    getEntidades()
    getRealPaymentMethod()

    const id = match.params.id

    if (id) {
      getOrder(id)
      getInstallments(id)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.order.tipoDePersona.value && nextProps.profile.idIntegrado) {
      this.setState({
        order: mapPropsToState(this.state.order, {
          ...nextProps.profile,
          tipoDePersona: nextProps.profile.tipoPersona.id,
          nombreRazonSocial: nextProps.profile.nombreIntegrado,
        }),
        addressData: mapPropsToState(this.state.addressData, nextProps.profile.address),
      })
    }

    if (nextProps.order.id && nextProps.order.id !== this.props.order.id) {
      const order = mapPropsToState(this.state.order, { ...nextProps.order, montoRetira: nextProps.order.total })
      const totals = mapPropsToState(this.state.totals, nextProps.order)

      this.getAccounts()

      this.setState({
        order,
        totals,
      })
    }
  }

  componentWillUnmount() {
    const { cleanOrigin } = this.props;
    this.props.endOrderEdit()
    cleanOrigin();
  }
  onCancel = () => {
    const { push, origin } = this.props
    const isFromPending = equals('pending')(origin)
    const isFromPendingToPay = equals('pendingToPay')

    if (isFromPending) {
      push('/ordenes-pago/pendientes')
      return
    }
    if (isFromPendingToPay(origin)) {
      push('/ordenes-pago/pendientes-pago')
      return
    }
    push('/ordenes-pago/retiros')
  }

  getPermissions = () => ({
    canSave: true,
    canDelete: !!this.props.order.id && this.props.order.estatusOrden === STATUS_CREATED,
  })

  onChangeInput = ({ target }, section) => {
    const { name } = target
    const data = getDataInput(target, this.state[section])
    const field = data[name]

    this.setState({
      [section]: data,
      showErrors: false,
      showAccountErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }

  getAccounts = () => {
    const { value } = this.state.order.cuentaRetiro
    const { accounts } = this.props

    if (!value) {
      return []
    }

    if (value === 'MXN') {
      return accounts.filter(propEq('tipoCuenta', 'Nacional (MX)')).map(it => {
        return { value: it.idCuentaBancaria, label: it.clabe }
      })
    }

    if (value === 'USD') {
      return accounts.filter(propEq('tipoCuenta', 'Extranjera')).map(it => {
        if (it.pagoDirectoOConBancoIntermediario === 'Intermedio') {
          return { value: it.idCuentaBancaria, label: it.intermediarioSwiftCode }
        } else {
          return { value: it.idCuentaBancaria, label: it.directoSwiftCode }
        }
      })
    }

  }

  getAccount = () => {
    const { cuentaRetiro: value, cuentaClabeBeneficiario: id } = this.props.order
    const { accounts } = this.props

    if (!value || !id) {
      return {}
    }

    const account = accounts.find(propEq('idCuentaBancaria', +id));

    if (value === 'MXN') {
      return account.clabe
    }

    if (value === 'USD') {
      if (account.pagoDirectoOConBancoIntermediario === 'Intermedio') {
        return account.intermediarioSwiftCode
      } else {
        return account.directoSwiftCode
      }
    }

  }

  createOrder = cb => {
    const { data, hasErrors } = validateData(this.state.order)

    if (hasErrors) {
      this.setState({
        order: data,
        showErrors: true,
      })
      this.props.addNotification({
        type: 'danger',
        message: '¡Favor de validar algunos campos!'
      })
      return false
    }
    const orderData = getValues(this.state.order)
    const id = this.props.order.id

    if (id) {
      this.props.updateOrder({ id, ...orderData })
        .then(data => {
          this.props.addNotification({
            type: 'success',
            message: '¡La orden se actualizó correctamente!'
          })

          this.setState({
            order: mapPropsToState(this.state.order, data),
            totals: mapPropsToState(this.state.totals, data)
          })
        })
        .catch(({ response: { data } }) => {
          this.props.addNotification({
            type: 'danger',
            message: data.message
          })
        })
    } else {
      this.props.createOrder({ ...orderData, total: orderData.montoRetira })
        .then(({ id }) => {
          this.props.addNotification({
            type: 'success',
            message: '¡Orden creada exitosamente!'
          })
          this.props.push(`/ordenes-pago/retiros/${id}`);
          cb && cb();
        })
        .catch(({ response: { data } }) => {
          this.props.addNotification({
            type: 'danger',
            message: data.message
          })
        })
    }
  }

  removeOrder = () => {
    this.props.deleteOrder(this.props.order.id)
      .then((response) => {
        this.props.addNotification({
          type: 'success',
          message: 'La órden de pago se eliminó correctamente'
        })
        this.setState({
          isRemovingOrder: false,
        })
        this.setState({
          order: mapPropsToState(this.state.order, { estatusOrden: response.estatusId })
        })
      })
      .catch(({ response: { data } }) => {
        this.props.addNotification({
          type: 'danger',
          message: data.message
        })
      })
  }

  closeConfirmRemoveOrder = () => this.setState({
    isRemovingOrder: false
  })

  openConfirmRemoveOrder = () => this.setState({
    isRemovingOrder: true,
  })

  openInstallmentModal = ({ id, estatus, estatusTexto, cargo, fechaTransaccion } = {}) => {
    const getValue = prop('value')
    const getId = prop('id')
    const limitHour = moment({ hour: 16, minute: 5 })
    const minDate = moment() < limitHour ? moment({ hour: 0 }) : moment({ hour: 0 }).add(1, 'day')
    const method = find(propEq('id', this.props.order.metodoPago))(this.props.realPaymentMethod)
    // const clabe = find(propEq('id', this.props.order.cuentaClabeColaborador))(this.props.collaboratorAccounts.data)
    // console.log('clabe: ', this.props.order.cuentaClabeColaborador, this.props.collaboratorAccounts.data);

    if (this.props.order.id) {
      this.setState({
        isAddingInstallment: true,
        installment: id,
        orderData: {
          id,
          divisaMoneda: getValue(this.state.order.divisaMoneda),
          status: estatus,
          estatus: estatusTexto,
          cargo: id ? cargo : getValue(this.state.order.montoRetira),
          idOrden: getId(this.props.order),
          referencia: getValue(this.state.order.referencia),
          fechaTransaccion: id ? new Date(fechaTransaccion) : minDate.toDate(),
          metodoPago: prop('metodo')(method),
          integrado: this.props.profile.idIntegrado,
          cuentaMXN: this.getAccount(),
        }
      })
    } else {
      this.createOrder(undefined, () => {
        this.setState({
          isAddingInstallment: true,
          installment: id,
          orderData: {
            id,
            divisaMoneda: getValue(this.state.order.divisaMoneda),
            status: estatus,
            estatus: estatusTexto,
            cargo: id ? cargo : getValue(this.state.order.montoRetira),
            idOrden: getId(this.props.order),
            referencia: getValue(this.state.order.referencia),
            fechaTransaccion: id ? new Date(fechaTransaccion) : minDate.toDate(),
            metodoPago: prop('metodo')(method),
            integrado: this.props.profile.idIntegrado,
            cuentaMXN: this.getAccount(),
          }
        })
      })
    }
  }

  closeInstallmentModal = () => this.setState({
    isAddingInstallment: false,
    installment: undefined
  })

  openConfirmRemoveInstallment = ({ id }) => this.setState({
    isRemovingInstallment: true,
    installment: id,
  })

  closeConfirmRemoveInstallment = () => this.setState({
    isRemovingInstallment: false,
  })

  addInstallment = installment => {
    this.props.addInstallment(this.props.order.id, {
      ...installment,
      fechaTransaccion: moment(installment.fechaTransaccion).format('YYYY-MM-DD')
    })
      .then(() => {
        this.closeInstallmentModal();
        this.props.addNotification({
          type: 'success',
          message: '¡El pago se agregó correctamente!'
        })
      })
      .catch(({ response: { data } }) => {
        this.props.addNotification({
          type: 'danger',
          message: data.message
        })
      })
  }

  onAuthorize = ({ id }) => {
    this.setState({
      isAddingInstallment: false,
      showAuth: true,
      installment: id,
    })
  }

  cancelAuthorize = () => {
    this.setState({
      showAuth: false,
    })
  }

  authorizeInstallment = props => {
    return this.props.authorizeInstallment({
      idOrder: this.state.order.id,
      idInstallment: this.state.installment,
      ...props,
    }).then(data => {
      this.props.addNotification({
        type: 'success',
        message: 'El pago se autorizó correctamente'
      })
      this.setState({
        showAuth: false,
        totals: mapPropsToState(this.state.totals, data),
      })
    })

  }

  removeInstallment = () => {
    this.props.deleteInstallment(this.props.order.id, this.state.installment)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'El pago se eliminó correctamente'
        })
        this.setState({
          isRemovingInstallment: false,
        })
      })
      .catch(({ response: { data } }) => {
        this.props.addNotification({
          type: 'danger',
          message: data.message
        })
      })
  }

  authorizeInstallment = props => {
    return this.props.authorizeInstallment({
      idOrder: this.props.order.id,
      idInstallment: this.state.installment,
      ...props,
    }).then(data => {
      this.props.addNotification({
        type: 'success',
        message: 'El pago se autorizó correctamente'
      })
      this.setState({
        showAuth: false,
        totals: mapPropsToState(this.state.totals, data),
        order: mapPropsToState(this.state.order, data),
      })
    })
  }

  updateTotals = () => {
    const totals = reduce((acc, cur) => {
      return {
        total: add(prop('total')(acc), prop('total')(cur)),
        subtotal: add(prop('subtotal')(acc), prop('subtotal')(cur)),
        IVA: add(prop('IVA')(acc), prop('iva')(cur)),
        ieps: add(prop('ieps')(acc), prop('ieps')(cur)),
      }
    },
      {
        total: 0,
        subtotal: 0,
        IVA: 0,
        ieps: 0,
      }
    )(this.state.conceptos.data)
    this.setState(({ totals: totalsState }) => ({ totals: mapPropsToState(totalsState, { ...totals, montoPagar: totals.total }) }))
  }

  installmentActions = (row, idx) => (
    <div>
      <Button outlined
        primary
        onClick={() => this.openInstallmentModal(row)}>
        <Icon>
          <FontAwesomeIcon icon={faEdit} />
        </Icon>
      </Button>
      <Can validate={AUTHORIZE_PERMISSION}>
        <Button danger outlined className="margin-left-xs"
          disabled={!(row.estatus === STATUS_CREATED || row.estatus === STATUS_PARTIAL_AUTHORIZED)}
          onClick={() => this.onAuthorize(row)}>
          <Icon>
            <FontAwesomeIcon icon={faKey} />
          </Icon>
        </Button>
      </Can>
      <Button danger outlined className="margin-left-xs"
        disabled={(row.estatus !== STATUS_CREATED)}
        onClick={() => this.openConfirmRemoveInstallment(row)}>
        <Icon>
          <FontAwesomeIcon icon={faTrash} />
        </Icon>
      </Button>
    </div>
  )

  render() {
    const {
      paymentEstatus,
      tipoPersona,
      paises,
      entidades,
      realPaymentMethod,
    } = this.props
    const {
      estatusOrden,
      tipoDePersona,
      nombreRazonSocial,
      cuentaRetiro,
      metodoPago,
      referencia,
      cuentaClabeBeneficiario,
      montoRetira,
    } = this.state.order
    return (
      <Box>
        <ModalRemove
          title="Eliminar pago"
          isDeleting={this.state.isRemovingInstallment}
          confirmText="¿Esta seguro de querer eliminar el pago?"
          toggleModal={this.closeConfirmRemoveInstallment}
          resource={this.state.installment}
          deleteResource={this.removeInstallment}
        />
        <ModalRemove
          title="Eliminar Orden de pago"
          isDeleting={this.state.isRemovingOrder}
          confirmText="¿Esta seguro de querer eliminar la orden de pago?"
          toggleModal={this.closeConfirmRemoveOrder}
          deleteResource={this.removeOrder}
        />
        <ActionBar
          onDelete={this.openConfirmRemoveOrder}
          onSave={() => this.createOrder()}
          onCancel={this.onCancel}
          basicRole={['Oretiros']}
          permissions={this.getPermissions()}
          authorizeRole={['Aretiros']}
        />
        {this.state.showAuth && (<Authorize
          isOpen={this.state.showAuth}
          authorize={this.authorizeInstallment}
          cancel={this.cancelAuthorize}
        />)}
        <Columns className="is-multiline">
          <Column className="is-offset-half is-half">
            <Select
              options={paymentEstatus}
              {...estatusOrden}
              onChange={e => this.onChangeInput(e, 'order')}
              showErrors={this.state.showErrors}
            />
          </Column>
        </Columns>
        <Divider
          content="DATOS DEL INTEGRADO"
        />
        <Columns>
          <Column className="is-half">
            <Select
              options={tipoPersona}
              {...tipoDePersona}
              onChange={e => this.onChangeInput(e, 'order')}
              showErrors={this.state.showErrors}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...nombreRazonSocial}
              onChange={e => this.onChangeInput(e, 'order')}
              showErrors={this.state.showErrors}
            />
          </Column>
        </Columns>
        <AddressSection
          data={this.state.addressData}
          onChange={e => this.onChangeInput(e, 'addressData')}
          paises={paises}
          entidades={entidades}
          extranjero={false}
          showErrors={this.state.showErrors}
        />
        <Divider
          content="DATOS PARA PAGO"
        />
        <Columns className="is-multiline">
          <Column className="is-half">
            <Select
              {...cuentaRetiro}
              onChange={e => this.onChangeInput(e, 'order')}
              showErrors={this.state.showErrors}
            />
          </Column>
          <Column className="is-half">
            <Select
              options={realPaymentMethod}
              {...metodoPago}
              onChange={e => this.onChangeInput(e, 'order')}
              showErrors={this.state.showErrors}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...referencia}
              onChange={e => this.onChangeInput(e, 'order')}
              showErrors={this.state.showErrors}
            />
          </Column>
          <Column className="is-half">
            <Select
              {...cuentaClabeBeneficiario}
              options={this.getAccounts()}
              onChange={e => this.onChangeInput(e, 'order')}
              showErrors={this.state.showErrors}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...montoRetira}
              onChange={e => this.onChangeInput(e, 'order')}
              showErrors={this.state.showErrors}
            />
          </Column>
        </Columns>
        <Divider
          content="PAGOS"
        />
        <Columns>
          <Column >
            <Button
              // disabled={this.state.totals.montoPagar.value < 1 || estatusOrden.value === STATUS_CANCELED || estatusOrden.value === STATUS_REFUSED}
              className="is-pulled-right" onClick={() => this.openInstallmentModal({})}>Agregar Pago</Button>
          </Column>
        </Columns>
        <Column>
          <Modal isActive={this.state.isAddingInstallment}>
            <Message>
              <Message.Header>
                Agregar pago
                <Delete onClick={this.closeInstallmentModal} />
              </Message.Header>
              <Message.Body>
                {this.state.isAddingInstallment && <Installment
                  orderData={this.state.orderData}
                  addNotification={addNotification}
                  addInstallment={this.addInstallment}
                  closeInstallmentModal={this.closeInstallmentModal}
                  paymentEstatus={paymentEstatus}
                  authorize={this.onAuthorize}
                  realPaymentMethod={realPaymentMethod}
                  installment={installment}
                />}
              </Message.Body>
            </Message>
          </Modal>
        </Column>
        <Columns>
          <Column>
            {<Table
              colsetup={paymentTable}
              coldata={this.props.installments}
              emptyTableMarkUp={(<span>Aun no hay pagos</span>)}
              customCol={{
                customColName: 'acciones',
                renderFunc: this.installmentActions
              }}
            />}
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Totals
              data={this.state.totals}
            // providerInvoiceStatus={invoiceProviderStatus}
            />
          </Column>
        </Columns>
      </Box>
    )
  }
}

const mapStateToProps = ({ paymentOrders: { retreats, providers }, catalogos, principal }) => ({
  paymentEstatus: catalogos.paymentEstatus,
  tipoPersona: catalogos.tipoPersona,
  profile: { ...principal.profile, ...principal.integrated },
  accounts: principal.integrated.accounts || [],
  paises: catalogos.paises,
  entidades: catalogos.entidades,
  realPaymentMethod: catalogos.realPaymentMethod,
  order: retreats.order,
  installments: retreats.installments,
  origin: providers.origin,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  push,
  getPaymentEstatus,
  getTipoPersona,
  getPaises,
  getEntidades,
  getRealPaymentMethod,
  createOrder,
  addNotification,
  getOrder,
  getInstallments,
  endOrderEdit,
  authorizeInstallment,
  addInstallment,
  updateOrder,
  deleteInstallment,
  deleteOrder,
  cleanOrigin,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Form)
