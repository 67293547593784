import React, { useState } from 'react';
import { ModalRemove } from 'Components/common'

const useRemove = ({
  title = '',
  message = '',
  handleOnRemove= () => {},
  type,
  actionButtonLabel,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [resource, setResource] = useState(false);

  const Modal = () => <ModalRemove
    isDeleting={showModal}
    title={title}
    toggleModal={() => setShowModal(false)}
    confirmText={message}
    resource=""
    type={type}
    actionButtonLabel={actionButtonLabel}
    deleteResource={() => {
      setShowModal(false)
      handleOnRemove(resource);
    }}
  />;

  return [
    Modal,
    (resource) => {
      setShowModal(true);
      setResource(resource);
    }
    ];
}

export default useRemove