import React from 'react'
import { Columns, Column } from 'Components/common/bulma'
import { Input, Select } from 'Components/common/form'

const DireccionSection = ({
  data,
  onChange,
  paises,
  showErrors,
  entidades,
  extranjero,
}) => {
  return (
    <section>
      <Columns>
        <Column>
          <Input
            onChange={onChange}
            {...data.cp}
            maxLength="5"
            showErrors={showErrors}
          />
          <Select
            onChange={onChange}
            options={paises}
            showErrors={showErrors}
            {...data.pais}
            valueProperty="id"
            valueLabel="nombre"
          />
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...data.colonia}
          />
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...data.calle}
          />
        </Column>
        <Column>
          {extranjero}
          {extranjero && (
            <Input
              onChange={onChange}
              showErrors={showErrors}
              {...data.entidadFederativa}
            />)}
          {extranjero || (<Select
            onChange={onChange}
            options={entidades}
            showErrors={showErrors}
            {...data.entidadFederativa}
            valueProperty="entidadFederativa"
            valueLabel="entidadFederativa"
          />)}
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...data.municipio}
          />
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...data.exterior}
          />
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...data.interior}
          />
        </Column>
      </Columns>
    </section>
  )
}

export default DireccionSection
