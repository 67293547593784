import React from 'react'
import { Columns, Column } from 'Components/common/bulma'
import { InputWithError } from 'Components/common/form'

export default function Package({ packageFields, onChange, showErrors }) {
  return (
    <Columns className="is-multiline">
      <Column className="is-half">
        <InputWithError
          {...packageFields.weight}
          onChange={onChange}
          showErrors={showErrors}
        />
      </Column>
      <Column className="is-half">
        <InputWithError
          {...packageFields.length}
          onChange={onChange}
          showErrors={showErrors}
        />
      </Column>
      <Column className="is-half">
        <InputWithError
          {...packageFields.width}
          onChange={onChange}
          showErrors={showErrors}
        />
      </Column>
      <Column className="is-half">
        <InputWithError
          {...packageFields.height}
          onChange={onChange}
          showErrors={showErrors}
        />
      </Column>
    </Columns>
  )
}
