import React from 'react'
import { Columns, Column } from 'Components/common/bulma'
import { Input, Select } from 'Components/common/form'
import {
  MXN, TRANSFER_PAYMENT_METHOD, CIE_PAYMENT_METHOD,
  NACIONAL,
  CONVENIO_CIE_BBVA,
  USD,
  EXTRANJERO,
} from 'App/constants'
import { filter, propEq } from 'ramda'

function PaymentData({
  data,
  onChange,
  showErrors,
  paymentMethod,
  accounts,
  disable,
}) {
  const filterByDivisa = method => {
    return filter(it => {
      if (data.divisaMoneda.value === USD &&
        +it.id === CIE_PAYMENT_METHOD
      ) {
        return false
      }
      return true
    })(method)
  }
  return (
    <section>
      <Columns>
        <Column className="is-half">
          <Select
            disabled={disable}
            onChange={onChange}
            {...data.divisaMoneda}
            showErrors={showErrors}
          />
        </Column>
      </Columns>
      <Columns>
        <Column className="is-half">
          <Select
            disabled={disable}
            onChange={onChange}
            {...data.metodoPago}
            showErrors={showErrors}
            options={filterByDivisa(paymentMethod)}
          />
        </Column>
      </Columns>
      <Columns>
        <Column className="is-half">
          <Input
            disabled={disable}
            onChange={onChange}
            {...data.referencia}
            showErrors={showErrors}
          />
        </Column>
        <Column className="is-half">
          {(MXN === data.divisaMoneda.value &&
            +data.metodoPago.value === TRANSFER_PAYMENT_METHOD
          ) &&
            <Select
              disabled={disable}
              onChange={onChange}
              {...data.cuentaClabeBeneficiario}
              showErrors={showErrors}
              options={filter(propEq('tipoCuenta', NACIONAL))(accounts)}
              valueLabel="clabe"
            />
          }
          {(MXN === data.divisaMoneda.value &&
            +data.metodoPago.value === CIE_PAYMENT_METHOD
          ) &&
            <Select
              disabled={disable}
              onChange={onChange}
              {...data.cuentaClabeBeneficiario}
              label={'Convenio CIE'}
              showErrors={showErrors}
              options={filter(propEq('tipoCuenta', CONVENIO_CIE_BBVA))(accounts)}
              valueLabel="convenioBBVA"
            />
          }
          {(USD === data.divisaMoneda.value &&
            +data.metodoPago.value === TRANSFER_PAYMENT_METHOD
          ) &&
            <Select
              disabled={disable}
              onChange={onChange}
              {...data.cuentaClabeBeneficiario}
              label={'SWIFT CODE'}
              showErrors={showErrors}
              options={filter(propEq('tipoCuenta', EXTRANJERO))(accounts)}
              valueLabel="directoSwiftCode"
            />
          }
        </Column>
      </Columns>
    </section>
  )
}

export default PaymentData
