import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { Box, Icon, Column, Columns, Modal, Button, Message, Delete } from 'Components/common/bulma'
import { default as Table } from 'Components/common/table'
import {
  getClients,
  deleteClient,
} from 'Modules/catalogs/clients'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'
import { Trans } from '@lingui/macro'
import { colDef } from './clientConfig'

export class List extends Component {
  state = {
    showModal: false,
    resource: {}
  }

  onEdit = ({ id }) => {
    this.props.push(`/catalogos/clientes/${id}`)
  }

  toggleModal = (resource) => {
    this.setState(state => ({
      showModal: !state.showModal,
      resource,
    }))
  }

  deleteResource = () => {
    this.props.deleteClient(this.state.resource.id)
      .then(() => {
        this.setState(() => ({
          showModal: false
        }))
      })
  }

  customColActions = (row) => (
    <div>
      <Button small primary outlined onClick={() => this.onEdit(row)}>
        <Icon>
          <FontAwesomeIcon icon={faEdit} />
        </Icon>
      </Button>
      <Button small danger outlined onClick={() => this.toggleModal(row)} className="margin-left-xs">
        <Icon>
          <FontAwesomeIcon icon={faTrash} />
        </Icon>
      </Button>
    </div>
  )

  render() {
    const { clients } = this.props

    return (
      <Box>
        <Modal isActive={this.state.showModal}>
          <Message danger>
            <Message.Header>
              <p>Eliminar cliente</p>
              <Delete onClick={this.toggleModal} />
            </Message.Header>
            <Message.Body className="has-text-centered">
              ¿Esta seguro de querer eliminar el cliente?
            <br />
              <strong>{this.state.resource.nombre}</strong>
              <div className="margin-top-lg">
                <Button danger onClick={this.deleteResource}>Eliminar</Button>
                <Button default className="margin-left-sm" onClick={this.toggleModal}>
                  Cancelar
              </Button>
              </div>
            </Message.Body>
          </Message>
        </Modal>
        <Columns>
          <Column className="has-text-right">
            <Link className="button is-primary is-outlined" to="/catalogos/clientes/nuevo">
              <Icon>
                <FontAwesomeIcon icon={faPlus} />
              </Icon>
              <Trans render="span">Agregar Cliente</Trans>
            </Link>
          </Column>
        </Columns>
        <Table
          filterable
          colsetup={colDef}
          coldata={clients}
          tableClassName='table is-striped is-hoverable is-fullwidth'
          emptyTableMarkUp={(<span>No se encontraron clientes</span>)}
          customCol={{
            customColName: 'actions',
            renderFunc: this.customColActions
          }}
          refresh={this.props.getClients}
        />
      </Box>
    )
  }
}

const mapStateToProps = ({ catalogs }) => ({
  clients: catalogs.clients.clients,
  isFetching: catalogs.clients.isFetchingClient
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getClients,
  push,
  deleteClient,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(List)
