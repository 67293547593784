import React from 'react'
import { Output } from 'Components/common/form'
import { Columns, Column, Box } from 'Components/common/bulma'


const Perfil = ({data}) => {
    return (
        <Box>
            <Columns>
                <Column>
                    <Output
                        {...data.comisionFacturaCobranza}
                    />
                </Column>
                <Column>
                    <Output
                        {...data.comisionDeposito}
                    />
                </Column>
            </Columns>
            <Columns>
                <Column>
                    <Output
                        {...data.comisionPrestamo}
                    />
                </Column>
                <Column>
                    <Output
                        {...data.comisionSPEI}
                    />
                </Column>
            </Columns>
            <Columns>
                <Column>
                    <Output
                        {...data.comisionCheque}
                    />
                </Column>
                <Column>
                    <Output
                        {...data.comisionEmisionFacturaTimbre}
                    />
                </Column>
            </Columns>
            <Columns>
                <Column>
                    <Output
                        {...data.comisionIAS}
                    />
                </Column>
                <Column>
                    <Output
                        {...data.comisionTransferenciaUSD}
                    />
                </Column>
            </Columns>
            <Columns>
                <Column>
                <Output
                        {...data.comisionEmisionMonederoGasolina}
                    />
                </Column>
                <Column>
                <Output
                        {...data.comisionPorEmisionTarjetas}
                    />
                </Column>
            </Columns>
            <Columns>
                <Column>
                <Output
                        {...data.comisionCIEInternetPracticaja}
                    />
                </Column>
                <Column>
                <Output
                        {...data.comisionCIECaja}
                    />
                </Column>
            </Columns>
        </Box>
    );
};

export default Perfil;