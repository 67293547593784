import axios from 'axios';

const url = process.env.REACT_APP_API


const axiosInstance = axios.create({
  baseURL: url,
});

axiosInstance.interceptors.request.use(function (config) {
  const { jwt } = JSON.parse(localStorage.getItem('auth'))
  config.headers.Authorization = `Bearer ${jwt}`;
  return config
},)
export default axiosInstance;
