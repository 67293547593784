import React, { Component } from 'react'
import { Box, Columns, Column, Control, Field, Button, Divider } from 'Components/common/bulma'
import { Input, Select, Output, Autocomplete } from 'Components/common/form'
import Switch from 'Components/common/form/SwitchOld'
import Autosuggest from 'react-autosuggest'
import { conceptData } from 'Containers/incomeOrders/invoice/data'
import { getDataInput, mapPropsToState, validateData, getValues } from 'App/helpers'
import { multiply, find, propEq, propOr, pipe, equals, prop, } from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getTaxObjectAction } from 'Modules/catalogos'
import { getStudentsAction, resetStudentsAction } from 'Modules/catalogs/clients'
import { toNumber } from 'ramda-adjunct'
import Show from 'Components/common/Show'


/* eslint-disable */
function debounce(a, b, c) { var d, e; return function () { function h() { d = null, c || (e = a.apply(f, g)) } var f = this, g = arguments; return clearTimeout(d), d = setTimeout(h, b), c && !d && (e = a.apply(f, g)), e } }
/* eslint-enable */

const renderSuggestion = suggestion => (
  <div>
    {suggestion.nombre}
  </div>
)
const renderSuggestionProduct = suggestion => (
  <div>
    {suggestion.concatenado}
  </div>
)
const renderSuggestionFinder = suggestion => (
  <div>
    {propOr('', 'identificador')(suggestion)} - {propOr('', 'concepto')(suggestion)}
  </div>
)

class Concept extends Component {
  state = {
    value: '',
    valueProduct: '',
    data: conceptData,
    showErrors: false,
    query: '',
  }

  componentDidMount() {
    this.props.getUnits()
    this.props.getTaxObject()
  }

  componentWillReceiveProps(nextProps) {
    let data;
    if (this.props.product !== nextProps.product) {
      const { claveProductoServicioSAT, unidad } = nextProps.product
      data = mapPropsToState(this.state.data, { ...nextProps.product, tasaIva: nextProps.product.tasaIVA, tasaIeps: nextProps.product.tasaIEPS })
      this.setState({ data, value: unidad || '' })
      this.props.getProductSat(claveProductoServicioSAT)
    }

    if (this.props.productSat !== nextProps.productSat) {
      const { concatenado } = nextProps.productSat
      this.setState({
        valueProduct: concatenado
      })
    }
  }

  getSuggestionValue = suggestion => {
    this.onChangeInput({
      target: {
        name: 'unidad',
        value: suggestion.nombre,
      }
    })
    return suggestion.nombre;
  }

  getSuggestionValueProduct = suggestion => {
    this.onChangeInput({
      target: {
        name: 'claveProductoServicioSAT',
        value: suggestion.id,
      }
    })
    return suggestion.concatenado;
  }

  getSuggestionValueFinder = suggestion => {
    console.log('suggestion: ', suggestion.id);
    this.onChangeInput({
      target: {
        name: 'idProductoServicio',
        value: suggestion.id,
      }
    })
    return `${propOr('', 'identificador')(suggestion)} - ${propOr('', 'concepto')(suggestion)}`;
  }

  onChangeInput = ({ target }) => {
    const { name } = target
    const data = getDataInput(target, this.state.data)
    const field = data[name]

    this.setState({
      data,
      showErrors: false,
      showAccountErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }

  onChangeUnit = (event, { newValue, method }) => {

    this.setState({
      value: newValue
    })
    if (method === 'type') {
      this.onChangeInput({
        target: {
          name: 'unidad',
          value: '',
        }
      })
    }
  }
  onChangeClave = (event, { newValue, method }) => {

    this.setState({
      valueProduct: newValue
    })
    if (method === 'type') {
      this.onChangeInput({
        target: {
          name: 'claveProductoServicioSAT',
          value: '',
        }
      })
    }
  }

  onChangeQuery = (event, { newValue, method }) => {
    this.setState({
      query: newValue,
    })
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.props.getUnits(value)
  }

  onSuggestionsClearRequested = () => {
    this.props.clearUnits()
  }

  onSuggestionsClearFinder = () => {
    this.props.clearFinder()
  }

  onSuggestionsFetchRequestedProduct = debounce(({ value }) => {
    this.props.getProductService(value)
  }, 200)

  onSuggestionsFetchRequestedFinder = debounce(({ value }) => {
    this.props.findProducts(value)
  }, 200)

  onSuggestionsClearRequestedProduct = () => {
    this.props.clearProductService()
  }

  onSuggestionSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) {
    event.stopPropagation();
    event.preventDefault();
  }

  onSuggestionProductSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    this.props.getProduct(suggestion.id)
    event.stopPropagation();
    event.preventDefault();
  }

  getSubtotal = () => {
    const { cantidad: { value: qty }, unitario: { value: price } } = this.state.data
    return multiply(+qty, +price)
  }

  getDiscount = () => {
    const { cantidad: { value: qty }, unitarioDescuento: { value: discount } } = this.state.data
    return multiply(+qty, +discount)
  }

  getIepsCalculado = (subtotal, discount) => {
    const { tasaIeps: { value: iepsId } } = this.state.data
    const { iepsRates } = this.props
    const ieps = find(pipe(prop('id'), toNumber, equals(+iepsId)))(iepsRates)

    if (!ieps) {
      return 0
    }
    return (subtotal - discount) * ieps.IEPS
  }

  getIvaCalculado = (subtotal, iepsC, discount) => {
    const { tasaIva: { value: ivaId }, tipoCalculo: { value: calculo } } = this.state.data
    const { ivaRates } = this.props
    const iva = find(propEq('id', ivaId))(ivaRates)
    const subtotalDiscount = subtotal - discount

    const amount = calculo === 1 ? subtotalDiscount : (subtotalDiscount + iepsC)

    if (!iva) {
      return 0
    }

    return multiply(amount, +iva.IVA)
  }

  getTotalCalculado = (subtotal, iepsC, ivaC, discount, retISR, retIEPS, retIVA) => {

    return subtotal  + iepsC + ivaC - discount - retISR - retIEPS - retIVA
  }

  getRetIsr = (subtotal, discount) => {
    const tasaRetIsr = +this.state.data.tasaRetISR.value

    return multiply((subtotal - discount), tasaRetIsr)
  }

  getRetIeps = (subtotal, discount) => {
    const tasaRetIsr = +this.state.data.tasaRetIEPS.value

    return multiply((subtotal - discount), tasaRetIsr)
  }

  getRetIva = (subtotal, discount, iepsC) => {
    const tasaRetIva = +this.state.data.tasaRetIVA.value

    const { tipoCalculo: { value: calculo } } = this.state.data
    const subtotalDiscount = subtotal - discount

    const amount = calculo === 1 ? subtotalDiscount : (subtotalDiscount + iepsC)

    return multiply(amount, tasaRetIva)
  }

  updateCalculations = () => {
    const subtotal = this.getSubtotal()
    const discount = this.getDiscount()
    const ieps = this.getIepsCalculado(subtotal, discount)
    const iva = this.getIvaCalculado(subtotal, ieps, discount)
    const retISR = this.getRetIsr(subtotal, discount)
    const retIEPS = this.getRetIeps(subtotal, discount)
    const retIVA = this.getRetIva(subtotal, discount, ieps)
    const total = this.getTotalCalculado(subtotal,
      ieps,
      iva,
      discount,
      retISR,
      retIEPS,
      retIVA,
    )
    const data = mapPropsToState(this.state.data, {
      subtotal,
      ieps,
      iva,
      total,
      importeDescuento: discount,
      retISR,
      retIEPS,
      retIVA,
    })
    this.setState({ data })
  }

  addConcept = (addProduct) => {
    const { data, hasErrors } = validateData(this.state.data)
    if (hasErrors) {
      this.setState({
        data,
        showErrors: true,
      })
      this.props.addNotification({
        type: 'danger',
        message: '¡Favor de validar algunos campos!'
      })
      return
    }
    this.props.addConcept(getValues(this.state.data), addProduct)
  }

  onChangeFinder = ({target:{suggestion}}) => {

    const data = mapPropsToState(this.state.data, suggestion)

    this.setState({data})
  }

  render() {
    const { data, showErrors } = this.state
    const { units, ivaRates, iepsRates, closeConceptModal,
      productService, products } = this.props
    return (
      <Box>

        <Divider content="BUSQUEDA DE CONCEPTO" />
        <Columns>
          <Column>
            <Field>
              <label className="label">{data.query.label}</label>
              <Control>
                <Autosuggest
                  suggestions={products}
                  inputProps={{
                    value: this.state.query,
                    onChange: this.onChangeQuery,
                    className: 'input',
                    placeholder: 'Buscar productos',
                  }}
                  onSuggestionsClearRequested={this.onSuggestionsClearFinder}
                  getSuggestionValue={this.getSuggestionValueFinder}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedFinder}
                  renderSuggestion={renderSuggestionFinder}
                  onSuggestionSelected={this.onSuggestionProductSelected}
                />
              </Control>
              {(data.unidad.errors && !!data.unidad.errors.length && this.state.showErrors) &&
                <p className="has-text-danger help">{data.unidad.errors[0].message}</p>}
            </Field>
          </Column>
        </Columns>
        <Divider content="CONCEPTO" />
        <Columns>
          <Column className="is-one-quarter">
            <Input
              {...data.cantidad}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column>
            <Input
              {...data.concepto}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
        </Columns>
        <Columns>
          <Column className="is-half">
            <Input
              {...data.identificador}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...data.pedimentoAduana}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Field>
              <label className="label">{data.unidad.label}</label>
              <Control>
                <Autosuggest
                  suggestions={units}
                  inputProps={{
                    value: this.state.value,
                    onChange: this.onChangeUnit,
                    className: 'input',
                    placeholder: data.unidad.placeholder,
                  }}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  getSuggestionValue={this.getSuggestionValue}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  renderSuggestion={renderSuggestion}
                  onSuggestionSelected={this.onSuggestionSelected}
                />
              </Control>
              {(data.unidad.errors && !!data.unidad.errors.length && this.state.showErrors) &&
                <p className="has-text-danger help">{data.unidad.errors[0].message}</p>}
            </Field>
          </Column>
        </Columns>
        <Columns className="is-multiline">
          <Column>
            <Field>
              <label className="label">{data.claveProductoServicioSAT.label}</label>
              <Control>
                <Autosuggest
                  suggestions={productService}
                  inputProps={{
                    value: this.state.valueProduct,
                    onChange: this.onChangeClave,
                    className: 'input',
                    placeholder: data.claveProductoServicioSAT.placeholder,
                  }}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequestedProduct}
                  getSuggestionValue={this.getSuggestionValueProduct}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedProduct}
                  renderSuggestion={renderSuggestionProduct}
                  onSuggestionSelected={this.onSuggestionSelected}
                />
              </Control>
              {(data.unidad.errors && !!data.unidad.errors.length && this.state.showErrors) &&
                <p className="has-text-danger help">{data.unidad.errors[0].message}</p>}
            </Field>
          </Column>
          <Column className="is-full">
            <Select
              options={this.props.taxObject}
              {...data.objetoImp}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Show canShow={+this.props.permissions.escuela === 1}>
            <Column className="is-full">
              <Switch
                {...data.banderaEscuela}
                onChange={this.onChangeInput}
                showErrors={showErrors}
              />
            </Column>

          </Show>
          <Show canShow={data.banderaEscuela.value === true && this.props.generalData.rfc.value !== 'XAXX010101000'}>
          <Column className="is-full">
            <Autocomplete
              suggestions={this.props.students.data}
              property="nombreAlumno"
              showErrors={showErrors}
              onFetchRequested={this.props.getStudents}
              onClearRequested={this.props.resetStudents}
              onChange={this.onChangeFinder}
              {...data.studentFinder}
            />
            </Column>
          <Column className="is-half">
            <Input
              {...data.nombreAlumno}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...data.curp}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...data.nivelEducativo}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...data.autRVOE}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          </Show>
        </Columns>
        <Columns className="is-multiline">
          <Column className="is-half">
            <Input
              {...data.unitario}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column className="is-half">
            <Output
              {...data.subtotal}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...data.unitarioDescuento}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column className="is-half">
            <Output
              {...data.importeDescuento}
            />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Select
              options={iepsRates}
              {...data.tasaIeps}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column>
            <Output
              {...data.ieps}
            />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Select
              options={ivaRates}
              {...data.tasaIva}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column>
            <Output
              {...data.iva}
            />
          </Column>
        </Columns>
        <Columns className="is-multiline">
          <Column className="is-full">
            <Switch
              {...data.retenciones}
              onChange={this.onChangeInput}
            />
          </Column>
          <Show canShow={data.retenciones.value === true}>
            <Column className="is-half">
              <Input
                {...data.tasaRetISR}
                onChange={this.onChangeInput}
                showErrors={showErrors}
              />
            </Column>
            <Column className="is-half">
              <Output
                {...data.retISR}
              />
            </Column>
            <Column className="is-half">
              <Input
                {...data.tasaRetIEPS}
                onChange={this.onChangeInput}
                showErrors={showErrors}
              />
            </Column>
            <Column className="is-half">
              <Output
                {...data.retIEPS}
              />
            </Column>
            <Column className="is-half">
              <Input
                {...data.tasaRetIVA}
                onChange={this.onChangeInput}
                showErrors={showErrors}
              />
            </Column>
            <Column className="is-half">
              <Output
                {...data.retIVA}
              />
            </Column>
          </Show>
        </Columns>
        <Columns>
          <Column>
            <Output
              {...data.total}
              onChange={this.onChangeInput}
            />
          </Column>
          <Column>
            <Select
              hideEmpty={true}
              {...data.tipoCalculo}
              onChange={this.onChangeInput}
            />
          </Column>
        </Columns>
        <Columns>
          <Column className="has-text-right">
            <Button default onClick={() => this.addConcept(true)}>Agregar e insertar en catálogo</Button>
            <Button default className="margin-left-sm" onClick={() => this.addConcept()}>Agregar</Button>
            <Button default className="margin-left-sm" onClick={closeConceptModal}>
              Cancelar
            </Button>
          </Column>
        </Columns>
      </Box>
    )

  }
}
const mapStateToProps = ({ catalogos, principal, catalogs }) => ({
  taxObject: catalogos.taxObject,
  permissions: principal.inventoryPermission,
  students: catalogs.clients.students,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getTaxObject: getTaxObjectAction,
  getStudents: getStudentsAction,
  resetStudents: resetStudentsAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Concept)
