import axios from 'axios'
import { getHeaders } from 'Modules/util'

const url = process.env.REACT_APP_API

export const GET_LIST = 'incomeOrders/deposits/GET_LIST'
export const DELETE_ORDER = 'incomeOrders/deposits/DELETE_ORDER'
export const GET_ORDER = 'incomeOrders/deposits/GET_ORDER'
export const CREATE_ORDER = 'incomeOrders/deposits/CREATE_ORDER'
export const UPDATE_ORDER = 'incomeOrders/deposits/UPDATE_ORDER'
export const END_ORDER_EDIT = 'incomeOrders/deposits/END_ORDER_EDIT'
export const GET_INSTALLMENTS = 'incomeOrders/deposits/GET_INSTALLMENTS'
export const AUTHORIZE_ORDER = 'incomeOrders/deposits/AUTHORIZE_ORDER'
const IS_FETCHING_DEPOSITS = 'incomeOrders/deposits/IS_FETCHING_DEPOSITS'
const IS_FETCHING_DEPOSITS_COMPLETE = 'incomeOrders/deposits/IS_FETCHING_DEPOSITS_COMPLETE'

const initialState = {
  list: {
    data: []
  },
  order: {},
  installments: {
    data: [],
  },
  isFetchingDeposits: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTHORIZE_ORDER:
    case GET_INSTALLMENTS:
    case UPDATE_ORDER:
    case CREATE_ORDER:
    case GET_ORDER:
    case GET_LIST:
      return { ...state, ...action.payload}
    case DELETE_ORDER:
    const { data, ...meta } = state.list
    return {
      ...state, list: {
        data: data.map(it => {
          it.estatus = 'Cancelada'
          return it
        }), ...meta
      }
    }
    case END_ORDER_EDIT:
      return initialState
    case IS_FETCHING_DEPOSITS:
      return { ...state, isFetchingDeposits: true }
    case IS_FETCHING_DEPOSITS_COMPLETE:
      return { ...state, isFetchingDeposits: false }
    default:
      return state
  }
}

export const isFetchingDeposits= () => ({
  type: IS_FETCHING_DEPOSITS
})

export const isFetchingDepositsComplete = () => ({
  type: IS_FETCHING_DEPOSITS_COMPLETE
})


export const getList = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingDeposits())
    return axios.post(`${url}/entry-invoice-deposit/search`, params, { headers })
      .then(({ data }) => {
        dispatch(isFetchingDepositsComplete())
        dispatch({
          type: GET_LIST,
          payload: {list: data}
        })
      })
  }
}
export const deleteOrder = (orderId) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/entry-invoice-deposit/${orderId}`, { headers })
      .then(() => {
        dispatch({
          type: DELETE_ORDER,
          payload: orderId,
        })
      })
  }
}

export const getOrder = (id) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/entry-invoice-deposit/${id}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_ORDER,
          payload: { order: data }
        })
      })
  }
}

export const createOrder = (order) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingDeposits())
    return axios.post(`${url}/entry-invoice-deposit`, order, { headers })
      .then(({ data }) => {
        dispatch(isFetchingDepositsComplete())
        dispatch({
          type: CREATE_ORDER,
          payload: { order: data },
        })
        return data
      })
  }
}

export const updateOrder = ({ id, ...order }) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingDeposits())
    return axios.put(`${url}/entry-invoice-deposit/${id}`, order, { headers })
      .then(({ data }) => {
        dispatch(isFetchingDepositsComplete())
        dispatch({
          type: UPDATE_ORDER,
          payload: { order: data },
        })
        return data
      })
  }
}

export const endOrderEdit = () => ({type: END_ORDER_EDIT})

export const getInstallments = order => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/entry-invoice/deferred/${order}`, {}, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_INSTALLMENTS,
          payload: { installments: { data } },
        })
      })
  }
}

export const authorizeOrder = (idOrder, data) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/authorize-order-entry/${idOrder}`, data, { headers })
      .then(({ data }) => {
        dispatch({
          type: AUTHORIZE_ORDER,
          payload: { order: data },
        })
        return data
      })
  }
}