import { useRef, useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid'
import { append, isEmpty, nth, pathOr, propOr } from 'ramda'

import useArrayForm from 'Hooks/useArrayForm';

import { createBankAccount } from 'App/hexmodules/bankAccount/application/create/createBankAccount'
import { getBankAccount } from 'App/hexmodules/bankAccount/application/get/getBankAccount'
import { updateBankAccount } from 'App/hexmodules/bankAccount/application/update/updateBankAccount'
import { deleteBankAccount } from 'App/hexmodules/bankAccount/application/delete/deleteBankAccount'
import { useDependencies } from 'App/DependencyContext';
import { fields } from './definition'
import { addNotification } from 'Modules/principal'
import { useDispatch } from 'react-redux'

const STATE = {
  INITIAL: 'initial',
  FETCHING_BANK_ACCOUNT: 'fetchingBankAccount',
  FETCHED_BANK_ACCOUNT: 'fetchedBankAccount',
  ERROR_FETCHING_BANK_ACCOUNT: 'errorFetchingBankAccount',
  SAVING_BANK_ACCOUNT: 'savingBankAccount',
  SAVED_BANK_ACCOUNT: 'savedBankAccount',
  ERROR_SAVING_BANK_ACCOUNT: 'errorSavingBankAccount',
};

function useBankAccount({ refresh }) {
  const state = useRef(STATE.INITIAL);
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const [formValues, setFormValues, handleOnChangeField, isValid, , showErrors, onRemove, , getFormValues, mapNewFields] = useArrayForm(
    {
      fields: [{ ...fields, id: uuid() }],
      fieldsDefinition: fields,
    });

  const { bankAccountRepository } = useDependencies()

  const initialFetch = async () => {
    if (state.current === STATE.INITIAL) {
      setIsLoading(true)
      state.current = STATE.FETCHING_BANK_ACCOUNT;
      const bankAccount = await getBankAccount(bankAccountRepository);
      if (!isEmpty(bankAccount)) {
        mapNewFields(bankAccount)
      }
      state.current = STATE.FETCHED_BANK_ACCOUNT;
      setIsLoading(false)
    }
  };

  initialFetch();
  const saveBankAccount = async (idx) => {
    if (isValid()) {
      try {
        state.current = STATE.SAVING_BANK_ACCOUNT;
        setIsLoading(true)
        const bankAccounts = getFormValues();
        const currentBankAccount = nth(idx)(bankAccounts)
        const bankAccountId = propOr('', 'id', currentBankAccount)

        if (bankAccountId) {
          await updateBankAccount(bankAccountRepository, currentBankAccount, bankAccountId)
        } else {
          await createBankAccount(bankAccountRepository, currentBankAccount)
        }
        await refresh();
        state.current = STATE.SAVED_BANK_ACCOUNT;
        setIsLoading(false)
        dispatch(addNotification({
          type: 'success',
          message: 'Infomación guardada correctamente',
        }))
      } catch ({ response: { data } }) {
        setIsLoading(false)
        dispatch(addNotification({
          type: 'danger',
          message: data?.error,
        }))
      }

    }
  };

  const getCurrentState = useCallback(() => {
    return state.current;
  }, []);

  const addAccount = () => {
    setFormValues(append({ ...fields, id: uuid() }, formValues))
  }

  const onRemoveItem = async (item) => {
    const bankAccountId = pathOr('', ['id', 'input', 'value'], item)
    if (bankAccountId) {
      await deleteBankAccount(bankAccountRepository, bankAccountId)
      onRemove(item)
    } else {
      onRemove(item)
    }
  }

  return {
    values: formValues,
    getCurrentState,
    onChangeField: handleOnChangeField,
    showErrors,
    addAccount,
    saveBankAccount,
    onRemove: onRemoveItem,
    isLoading,
  };
}

export default useBankAccount;
