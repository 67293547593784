import React, { useCallback, useEffect, useState } from 'react';
import { Container, Title, Box, Column,
  Columns, Button, Divider, Notification,
} from 'Components/common/bulma'
import {
  InputWithError,
  SelectWithError,
  CheckWithError,
} from 'Components/common/form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getTipoPersona as getTipoPersonaAction } from 'Modules/catalogos'
import { useMount } from 'ahooks'
import fieldDefinitions  from './fieldDefinitions';
import useForm from 'Hooks/useForm'
import { createUserAction } from 'Modules/trial'
import Show from 'Components/common/Show'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { push } from 'connected-react-router'


function Trial({
  getTipoPersona,
  tipoPersona,
  createUser,
  match,
  push,
}) {
  const [
    fields, setValues, onFieldChange,
    isValidForm, getValues, showErrors
  ] = useForm({ fields: fieldDefinitions, initialValid: true })
  const [type, setType] = useState('')
  const [message, setMessage] = useState('')
  const [showNot, setShowNot] = useState(false)

  useMount(() => {
    getTipoPersona()
    // getReferral(match.params.token)
  })

  const handleOnSave = () => {
    if (isValidForm()) {
      createUser({...getValues(), idComisionista: match.params.token})
        .then(() => {
          push('/preregistro/exitoso')
        })
        .catch(({response: { data }}) => {
          setType('danger')
          setShowNot(true)
          setMessage('Existe un problema para generar tu pre-registro, por favor escribenos a hola@administratunegocio.com o a los teléfonos de atención y con gusto te apoyaremos.')
        })
    }
  }

  const showRazonSocial = useCallback(() => {
    return [1, 7].includes(+fields.tipoPersona.input.value)
  }, [fields.tipoPersona.input.value])

  const getRazonLabel = useCallback(() => {
   if (+fields.tipoPersona.input.value === 1) {
    return 'Razón Social'
   }
   return 'Nombre Legal'
  }, [fields.tipoPersona.input.value])

  const getTipoPersonaOptions = useCallback(() => {
    return tipoPersona.filter(it => +it.id !== 6)
  }, [tipoPersona])


  useEffect(() => {
    const tipoPersona = +fields.tipoPersona.input.value;
    const isDisabled = [4, 7].includes(tipoPersona)
    if (isDisabled) {
      setValues({rfc: 'XEXX010101000'})
    } else {
      setValues({rfc: ''})
    }
    // eslint-disable-next-line
  }, [fields.tipoPersona.input.value])


  const isRfcEnabled = useCallback(() => {
    const isDisabled = [4, 7].includes(+fields.tipoPersona.input.value)
    return isDisabled
  }, [fields.tipoPersona.input.value])

  const canShowRegId = useCallback(() => {
    const show = [4, 7].includes(+fields.tipoPersona.input.value)
    return show
  }, [fields.tipoPersona.input.value])

  const getDividerLabel = useCallback(() => {
    const tipoPersona = +fields.tipoPersona.input.value;

    if ([1, 7].includes(tipoPersona)) {
      return 'Datos del Representante Legal'
    }

    if ([2, 4].includes(tipoPersona)) {
      return 'Datos de la Persona Física'
    }

  }, [fields.tipoPersona.input.value])

  const closeNot = () => {
    setShowNot(false)
  }
  return (
      <Container>
        {showNot && (<Notification  danger={type === 'danger'} success={type === 'success'}  className="mensaje-notificacion" >
          <button class="delete" onClick={closeNot}></button>
            <Columns>
              <Column className="is-3">
                {type === 'success' && (<FontAwesomeIcon icon={faCheck} size="3x"/>)}
                {type === 'danger' && (<FontAwesomeIcon icon={faTimes} size="3x"/>)}
              </Column>
              <Column>
                {message}
              </Column>
            </Columns>
          </Notification>)}
          <Box>
          <Title is="5" style={{fontWeight: '700'}} className="has-text-centered">
            Bienvenido a pre-registro de Socios de administratunegocio.com, favor de capturar los siguientes datos.
          </Title>
            <Columns className="is-multiline">
              <Column className="is-half">
                <SelectWithError
                  options={getTipoPersonaOptions()}
                  showErrors={showErrors}
                  onChange={onFieldChange}
                  {...fields.tipoPersona}
                />
              </Column>
              <Column className="is-half">
                <InputWithError
                  showErrors={showErrors}
                  onChange={onFieldChange}
                  {...fields.rfc}
                  disabled={isRfcEnabled()}
                />
              </Column>
              <Show canShow={canShowRegId()} >
                <Column className="is-half">
                  <InputWithError
                    showErrors={showErrors}
                    onChange={onFieldChange}
                    {...fields.numRegIdTrib}
                  />
                </Column>
              </Show>
            </Columns>
            <Divider content={getDividerLabel()} />
            <Columns className="is-multiline">
              <Column className="is-half">
                <InputWithError
                  showErrors={showErrors}
                  onChange={onFieldChange}
                  {...fields.nombre}
                />
              </Column>
              <Column className="is-half">
                <InputWithError
                  showErrors={showErrors}
                  onChange={onFieldChange}
                  {...fields.paterno}
                />
              </Column>
              <Column className="is-half">
                <InputWithError
                  showErrors={showErrors}
                  onChange={onFieldChange}
                  {...fields.materno}
                />
              </Column>
              <Column className="is-half">
                <InputWithError
                  showErrors={showErrors}
                  onChange={onFieldChange}
                  {...fields.correo}
                />
              </Column>
              <Column className="is-half">
                <InputWithError
                  showErrors={showErrors}
                  onChange={onFieldChange}
                  {...fields.telefono}
                />
              </Column>
              <Column className="is-half">
                <Show canShow={showRazonSocial()}>
                  <InputWithError
                    showErrors={showErrors}
                    onChange={onFieldChange}
                    {...fields.nombreORazonSocial}
                    label={getRazonLabel()}
                  />
                </Show>
              </Column>
              <Column className="is-full">
                <CheckWithError
                  showErrors={showErrors}
                  onChange={onFieldChange}
                  {...fields.terminosCondiciones}
                />
              </Column>
            </Columns>
            <Columns>
              <Column>
                <Button
                className="is-black"
                style={{width: '100%'}} onClick={handleOnSave}>Crear usuario</Button>
              </Column>
            </Columns>
          </Box>
      </Container>
  );
}

const mapStateToProps = ({ catalogos }) => ({
  tipoPersona: catalogos.tipoPersona,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getTipoPersona: getTipoPersonaAction,
  createUser: createUserAction,
  push,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Trial)