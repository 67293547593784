import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faTrash } from '@fortawesome/free-solid-svg-icons'

import { Button, Input, Field, Control } from 'Components/common/bulma'


import React from 'react'

export default function TableFilter({
    handleInput,
    queryInput,
    filter,
    cleanQuery,
}) {
    return (
        <Field hasAddons>
        <Control>
          <Input onChange={handleInput} type="text" placeholder="Filtrar..." value={queryInput}/>
        </Control>
        <Control>
          <Button onClick={filter}>
            <FontAwesomeIcon icon={faFilter}/>
          </Button>
        </Control>
        <Control>
          <Button onClick={cleanQuery} className="has-text-danger">
            <FontAwesomeIcon icon={faTrash}/>
          </Button>
        </Control>
      </Field>
    )
}
