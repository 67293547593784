import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ProductServicesForm, ProductServicesList } from 'Containers/catalogs/productServices'

export default () => (
  <Switch>
    <Route exact path="/catalogos/productos-servicios" component={ProductServicesList} />
    <Route exact path="/catalogos/productos-servicios/nuevo" component={ProductServicesForm} />
    <Route exact path="/catalogos/productos-servicios/:id" component={ProductServicesForm} />
  </Switch>
)