import React, { Component } from 'react'
import { Box, Columns, Column, Control, Field, SubTitle } from 'Components/common/bulma'
import { Input, Select } from 'Components/common/form'
import Autosuggest from 'react-autosuggest'
import { product } from './data'
import { getDataInput, mapPropsToState, validateData, getValues } from 'App/helpers'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  getProductSat,
  getInvoiceUnits,
  clearInvoiceUnits,
  getIvaRates,
  getIepsRates,
  getProductService,
  clearProductService,
  getEstatusPersona,
  getTaxObjectAction,
} from 'Modules/catalogos'
import {
  getProduct,
  addProduct,
  updateProduct,
  endOrderEdit,
  removeProduct,
} from 'Modules/incomeOrders/invoice'
import { push } from 'connected-react-router'
import { addNotification } from 'Modules/principal'
import { prop, propOr, pipe, ifElse, equals, identity, T, F } from 'ramda'
import { ActionBar, ModalRemove } from 'Components/common'


/* eslint-disable */
function debounce(a, b, c) { var d, e; return function () { function h() { d = null, c || (e = a.apply(f, g)) } var f = this, g = arguments; return clearTimeout(d), d = setTimeout(h, b), c && !d && (e = a.apply(f, g)), e } }
/* eslint-enable */

const renderSuggestion = suggestion => (
  <div>
    {suggestion.nombre}
  </div>
)

const renderSuggestionProduct = suggestion => (
  <div>
    {suggestion.concatenado}
  </div>
)

const AUTHORIZE_PERMISSION = ['Oalta']

class ProductService extends Component {
  state = {
    value: '',
    valueProduct: '',
    data: product,
    showErrors: false,
    query: '',
  }

  componentDidMount() {
    const {
      getInvoiceUnits,
      getIvaRates,
      getIepsRates,
      getProduct,
      getTaxObject,
    } = this.props

    getInvoiceUnits()
    getIvaRates()
    getIepsRates()
    getEstatusPersona()
    getTaxObject()

    const { id } = this.props.match.params

    if (id) {
      getProduct(id)
    }

  }

  componentWillReceiveProps(nextProps) {
    let data;
    if (this.props.product !== nextProps.product) {
      const { claveProductoServicioSAT, unidad } = nextProps.product
      data = mapPropsToState(this.state.data, { ...nextProps.product, tasaIva: nextProps.product.tasaIVA, tasaIeps: nextProps.product.tasaIEPS,
      })
      console.log('data: ', data);
      this.setState({ data, value: unidad || '' })
      this.props.getProductSat(claveProductoServicioSAT)
    }

    if (this.props.productSat !== nextProps.productSat) {
      const { concatenado } = nextProps.productSat
      this.setState({
        valueProduct: concatenado
      })
    }
  }

  componentWillUnmount() {
    this.props.endOrderEdit()
  }

  getPermissions = () => {
    return {
      canAuthorize: false,
      canDelete: (this.props.product && this.props.product.id ),
      canSave: true,
    }
  }

  getSuggestionValue = suggestion => {
    this.onChangeInput({
      target: {
        name: 'unidad',
        value: suggestion.nombre,
      }
    })
    return suggestion.nombre;
  }

  getSuggestionValueProduct = suggestion => {
    this.onChangeInput({
      target: {
        name: 'claveProductoServicioSAT',
        value: suggestion.id,
      }
    })
    return suggestion.concatenado;
  }

  onChangeInput = ({ target }) => {
    const { name } = target
    const data = getDataInput(target, this.state.data)
    const field = data[name]

    this.setState({
      data,
      showErrors: false,
      showAccountErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }

  onChangeUnit = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    })
    if (method === 'type') {
      this.onChangeInput({
        target: {
          name: 'unidad',
          value: '',
        }
      })
    }
  }

  onChangeClave = (event, { newValue, method }) => {
    this.setState({
      valueProduct: newValue
    })
    if (method === 'type') {
      this.onChangeInput({
        target: {
          name: 'claveProductoServicioSAT',
          value: '',
        }
      })
    }
  }

  onCancel = () => {
    this.props.push('/catalogos/productos-servicios')
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.props.getInvoiceUnits(value)
  }

  onSuggestionsClearRequested = () => {
    this.props.clearInvoiceUnits()
  }

  onSuggestionsFetchRequestedProduct = debounce(({ value }) => {
    this.props.getProductService(value)
  }, 200)


  onSuggestionsClearRequestedProduct = () => {
    this.props.clearProductService()
  }

  onSuggestionSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) {
    event.stopPropagation();
    event.preventDefault();
  }

  addProduct = () => {
    const { data, hasErrors } = validateData(this.state.data)
    const isSchoolEnabled = pipe(propOr(false, 'escuela'), ifElse(equals(false), identity, T))
    const hasTaxObjectValue = !!this.state.data.objetoImp.value;

    const hasSchoolError = isSchoolEnabled(this.props.permissions) ? !hasTaxObjectValue : F();

    if (hasErrors || hasSchoolError) {
      this.setState({
        data: { ...data, objetoImp: { ...data.objetoImp, errors: hasSchoolError ? [{ valid: hasSchoolError, message: 'El valor es requerido'}] : undefined}},
        showErrors: true,
      })
      this.props.addNotification({
        type: 'danger',
        message: '¡Favor de validar algunos campos!'
      })
      return
    }

    const product = getValues(this.state.data)
    const id = prop('id')(product)

    if (!id) {
      this.props.addProduct(product)
        .then((data) => {
          this.props.addNotification({
            type: 'success',
            message: 'El concepto se agregó/actualizó exitosamente al catálogo'
          })
          this.props.push(`/catalogos/productos-servicios/${data.id}`)
          this.setState({
            data: mapPropsToState(this.state.data, data)
          })
        })
      return
    }

    this.props.updateProduct(product)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'El concepto se agregó/actualizó exitosamente al catálogo'
        })
      })
      .catch(({response:{data}}) =>{
        console.log(data)
        this.props.addNotification({
          type: 'danger',
          message: JSON.stringify(data.message),
        })
      })
  }

  delete = () => {
    const { id } = this.props.product
    this.props.removeProduct(id)
      .then(data => {
        this.setState({
          isRemoving: false,
          data: mapPropsToState(this.state.data, data)
        })
        this.props.addNotification({
          type: 'success',
          message: 'El producto se eliminó correctamente'
        })
        this.props.push('/catalogos/productos-servicios')

      })
  }

  openConfirmRemoveModal = () => this.setState({
    isRemoving: true
  })

  closeConfirmRemoveModal = () => this.setState({
    isRemoving: false
  })

  render() {
    const { data, showErrors } = this.state
    const {
      invoiceUnits,
      ivaRates,
      iepsRates,
      productService,
      estatusPersona,
      permissions,
      taxObject,
    } = this.props
    return (
      <Box>
        <ModalRemove
          isDeleting={this.state.isRemoving}
          title="Eliminar producto o servicio"
          toggleModal={this.toggleModal}
          confirmText="¿Esta seguro de querer eliminar el producto o servicio?"
          resource=""
          deleteResource={this.delete}
        />
        <ActionBar
          permissions={this.getPermissions()}
          onSave={this.addProduct}
          onCancel={this.onCancel}
          onDelete={this.openConfirmRemoveModal}
          onAuthorize={this.onAuthorize}
          basicRole={['Oalta']}
          authorizeRole={AUTHORIZE_PERMISSION}
        />
        <SubTitle className="has-text-centered" is='3'>Producto o Servicio</SubTitle>
        <Columns>
          <Column>
          <Select
            options={estatusPersona}
            showErrors={showErrors}
            valueProperty="id"
            valueLabel="estatus"
            {...data.estatus}
          />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Input
              {...data.concepto}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
        </Columns>
        <Columns>
          <Column className="is-half">
            <Input
              {...data.identificador}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Field>
              <label className="label">{data.unidad.label}</label>
              <Control>
                <Autosuggest
                  suggestions={invoiceUnits}
                  inputProps={{
                    value: this.state.value,
                    onChange: this.onChangeUnit,
                    className: 'input',
                    placeholder: data.unidad.placeholder,
                  }}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  getSuggestionValue={this.getSuggestionValue}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  renderSuggestion={renderSuggestion}
                  onSuggestionSelected={this.onSuggestionSelected}
                />
              </Control>
              {(data.unidad.errors && !!data.unidad.errors.length && this.state.showErrors) &&
                <p className="has-text-danger help">{data.unidad.errors[0].message}</p>}
            </Field>
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Field>
              <label className="label">{data.claveProductoServicioSAT.label}</label>
              <Control>
                <Autosuggest
                  suggestions={productService}
                  inputProps={{
                    value: this.state.valueProduct,
                    onChange: this.onChangeClave,
                    className: 'input',
                    placeholder: data.claveProductoServicioSAT.placeholder,
                  }}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequestedProduct}
                  getSuggestionValue={this.getSuggestionValueProduct}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedProduct}
                  renderSuggestion={renderSuggestionProduct}
                  onSuggestionSelected={this.onSuggestionSelected}
                />
              </Control>
              {(data.unidad.errors && !!data.unidad.errors.length && this.state.showErrors) &&
                <p className="has-text-danger help">{data.unidad.errors[0].message}</p>}
            </Field>
          </Column>
        </Columns>
        <Columns className="is-multiline">
          <Column className="is-half">
            <Select
              options={taxObject}
              {...data.objetoImp}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...data.pedimentoAduana}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...data.unitario}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...data.unitarioDescuento}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
        </Columns>
        <Columns className="is-multiline">
          <Column className="is-half">
            <Select
              options={iepsRates}
              {...data.tasaIeps}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column className="is-half">
            <Select
              options={ivaRates}
              {...data.tasaIva}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...data.tasaRetIEPS}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...data.tasaRetIVA}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...data.tasaRetISR}
              onChange={this.onChangeInput}
              showErrors={showErrors}
            />
          </Column>
        </Columns>
        {permissions.inventarios === '1' && <Columns>
          <Column>
            <div className="field">
              <input id="manejaInventario" type="checkbox" name="manejaInventario"
                className="switch" value={data.manejaInventario.value} checked={data.manejaInventario.value} onChange={this.onChangeInput} />
              <label htmlFor="manejaInventario">{data.manejaInventario.label}</label>
            </div>
          </Column>
        </Columns>}
      </Box>
    )
  }
}

const mapStateToProps = ({ catalogos, incomeOrders, principal }) => ({
  productSat: catalogos.productSat,
  invoiceUnits: catalogos.invoiceUnits,
  ivaRates: catalogos.ivaRates,
  iepsRates: catalogos.iepsRates,
  productService: catalogos.productService,
  product: incomeOrders.invoice.product,
  estatusPersona: catalogos.estatusPersona,
  permissions: principal.inventoryPermission,
  products: incomeOrders.invoice.products,
  taxObject: catalogos.taxObject,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getProductSat,
  getInvoiceUnits,
  clearInvoiceUnits,
  getIvaRates,
  getIepsRates,
  getProductService,
  clearProductService,
  getProduct,
  addProduct,
  push,
  addNotification,
  updateProduct,
  endOrderEdit,
  removeProduct,
  getEstatusPersona,
  getTaxObject: getTaxObjectAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ProductService)
