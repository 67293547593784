import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Control, Field } from 'Components/common/bulma'
import Autosuggest from 'react-autosuggest'
import { pipe, filter, prop, includes, toLower } from 'ramda'

export default function AutoCompleteLocal({
  label,
  placeholder,
  error,
  showErrors,
  initialInputValue = '',
  listKey = "id",
  suggestions,
  property,
  onChange,
  disabled,
  input,
}) {
  const [localValue, setLocalValue] = useState('');
  const timer = useRef(null);

  useEffect(() => {
    setLocalValue(initialInputValue)
  }, [initialInputValue])

  const onLocalChange = (event, { newValue, method }) => {
    setLocalValue(newValue)

    if (!newValue) {
      onChange({
        target: {
          name: input.name,
          value: ''
        }
      })
    }
  }

  const renderSuggestion = suggestion => {
    return (
      <div>
        {suggestion[property]}
      </div>
    )
  }

  const fetchRequested = ({ value }) => {
    clearTimeout(timer.current);
  }

  const getSuggestionValue = suggestion => suggestion[property];

  const onSuggestionSelect = (e, { suggestion }) => {
    onChange({
      target: {
        name: input.name,
        value: suggestion[listKey],
        suggestion,
      }
    })
  }

  const getSuggestions = useCallback((suggestions) => filter(pipe(prop('name'), toLower, includes(localValue.toLowerCase())))(suggestions), [localValue])


  return (
    <Field>
      <label className="label">{label}</label>
      <Control>
        <Autosuggest
          suggestions={getSuggestions(suggestions || [])}
          inputProps={{
            value: localValue,
            onChange: onLocalChange,
            className: 'input',
            placeholder,
            disabled,
          }}
          onSuggestionsClearRequested={() => { }}
          getSuggestionValue={getSuggestionValue}
          onSuggestionsFetchRequested={fetchRequested}
          renderSuggestion={renderSuggestion}
          onSuggestionSelected={onSuggestionSelect}
        />
      </Control>
      {(error && showErrors) &&
        <p className="has-text-danger help">{error}</p>}
    </Field>
  )
}
