import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { Box, Icon, Columns, Column } from 'Components/common/bulma'
import Table from 'Components/common/table'
import { getRejectedInvoiceList as getList, setOrigin } from 'Modules/paymentOrders/providers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { rejectedInvoicePaymentOrders } from './colSetup'
import { Button } from 'Components/common/bulma'
import { addNotification } from 'Modules/principal'
import { equals, always, cond } from 'ramda'

export class ListaContainer extends Component {
  state = {
    showModal: false,
    order: {},
  }

  onEdit = ({ id, tipoPago }) => {
    const { push, setOrigin } = this.props;

    const isRefund = equals('Reembolsos')
    const isReceipt = equals('Recibos de honorarios')
    const isProvider = equals('Proveedores')

    const link = cond([
      [isRefund, always(`/ordenes-pago/reembolsos/${id}`)],
      [isReceipt, always(`/ordenes-pago/recibo-honorarios/${id}`)],
      [isProvider, always(`/ordenes-pago/proveedores/${id}`)],
    ])(tipoPago)

    setOrigin('rejectedInvoice')
    push(link)
  }

  customColAccions = (row) => (
    <Button small primary outlined onClick={() => this.onEdit(row)}>
      <Icon>
        <FontAwesomeIcon icon={faEdit} />
      </Icon>
    </Button>
  )

  render() {
    const { list } = this.props

    return (
      <Box>
        <Columns>
          <Column>
            <Table
              filterable
              colsetup={rejectedInvoicePaymentOrders}
              coldata={list}
              tableClassName='table is-striped is-hoverable is-fullwidth'
              emptyTableMarkUp={<span>No hay registros</span>}
              customCol={{
                customColName: 'actions',
                renderFunc: this.customColAccions
              }}
              refresh={this.props.getList}
            />
          </Column>
        </Columns>
      </Box>
    )
  }
}

const mapStateToProps = ({ paymentOrders }) => ({
  list: paymentOrders.providers.rejectedInvoiceList,
  isFetching: paymentOrders.providers.isFetching
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getList,
  push,
  addNotification,
  setOrigin,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ListaContainer)
