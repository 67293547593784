import React from 'react'
import Table from 'Components/common/table'

const listaCuentaBancarias = (props) => {
  return (
    <Table
      {...props}
      tableClassName='table is-striped is-hoverable is-fullwidth'
    />
  )
}

export default listaCuentaBancarias;