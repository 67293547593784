import axios from "axios"
import { getHeaders } from '../util'
import { addNotification } from 'Modules/principal'

const url = process.env.REACT_APP_API
const resourceUri = 'money-lender'
const accountUri = 'bank-lender'
const resource = 'LENDERS'
const seccion = 'lenders'

export const GET_RESOURCES = `${seccion}/GET_${resource}S`
export const CLEAR_RESOURCES = `${seccion}/CLEAR_LENDERS`
export const GET_RESOURCE = `${seccion}/GET_${resource}`
export const CREATE_RESOURCE = `${seccion}/CREATE_${resource}`
export const UPDATE_RESOURCE = `${seccion}/UPDATE_${resource}`
export const DELETE_RESOURCE = `${seccion}/DELETE_${resource}`
export const END_EDIT_RESOURCE = `${seccion}/END_EDIT_${resource}`
export const GET_ACCOUNTS = `${seccion}/GET_${resource}_ACCOUNTS`
export const CREATE_ACCOUNT = `${seccion}/CREATE_${resource}_ACCOUNT`
export const DELETE_ACCOUNT = `${seccion}/DELETE_${resource}_ACCOUNT`
export const IS_FETCHING_LENDERS = 'lenders/IS_FETCHING_LENDERS'
export const IS_FETCHING_LENDERS_COMPLETE = 'lenders/IS_FETCHING_LENDERS_COMPLETE'
export const CLEAR_LENDER = 'lenders/CLEAR_LENDER'

const initialState = {
  resources: {
    data: []
  },
  resource: {},
  accounts: {
    data: []
  },
  isFetchingLenders: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_RESOURCE:
    case CREATE_ACCOUNT:
    case GET_ACCOUNTS:
    case GET_RESOURCES:
    case GET_RESOURCE:
    case CLEAR_RESOURCES:
    case CLEAR_LENDER:
      return { ...state, ...action.payload }
    case END_EDIT_RESOURCE:
      return { ...state, resource: {}, accounts: { data: [] } }
    case DELETE_RESOURCE:
      const { data, ...meta } = state.resources
      return {
        ...state, resources: {
          data: data.filter(it => it.id !== action.payload), ...meta,
          last_page: Math.ceil(data.length / meta.per_page)
        }
      }
    case DELETE_ACCOUNT:
      const { data: dataAcc, ...metaAcc } = state.accounts
      return { ...state, accounts: { data: dataAcc.filter(it => it.id !== action.payload), ...metaAcc, last_page: Math.ceil(dataAcc.length / metaAcc.per_page) } }
    case IS_FETCHING_LENDERS:
      return { ...state, isFetchingLenders: true }
    case IS_FETCHING_LENDERS_COMPLETE:
      return { ...state, isFetchingLenders: false }
    default:
      return state
  }
}

export const isFetchingLenders = () => ({
  type: IS_FETCHING_LENDERS
})

export const isFetchingLendersComplete = () => ({
  type: IS_FETCHING_LENDERS_COMPLETE
})


export const getResources = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingLenders())
    return axios.post(`${url}/${resourceUri}/search`, params, { headers })
      .then(({ data }) => {
        dispatch(isFetchingLendersComplete())
        dispatch({
          type: GET_RESOURCES,
          payload: { resources: data }
        })

      })
  }
}

export const clearResources = () => ({
  type: CLEAR_RESOURCES,
  payload: {
    resources: { data: [] }
  },
});

export const clearLender = () => ({
  type: CLEAR_LENDER,
  payload: {
    resource: {}
  },
});

export const createResource = (resource) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingLenders())
    return axios.post(`${url}/${resourceUri}`, resource, { headers })
      .then(({ data }) => {
        dispatch(isFetchingLendersComplete())
        dispatch({
          type: CREATE_RESOURCE,
          payload: { resource: data }
        })
        return data
      })
  }
}

export const getResource = (resourceId) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/${resourceUri}/${resourceId}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_RESOURCE,
          payload: { resource: data }
        })

      })
  }
}
export const updateResource = ({ id, ...resource }) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingLenders())
    return axios.put(`${url}/${resourceUri}/${id}`, resource, { headers })
      .then(({ data }) => {
        dispatch(isFetchingLendersComplete())
        dispatch({
          type: UPDATE_RESOURCE,
        })
      })
  }
}
export const deleteResource = (resourceId) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/${resourceUri}/${resourceId}`, { headers })
      .then(() => {
        dispatch({
          type: DELETE_RESOURCE,
          payload: resourceId,
        })
      })
  }
}
export const endEditResource = () => ({
  type: END_EDIT_RESOURCE
})

export const getAccounts = (resourceId, params) => {
  const headers = getHeaders()

  return dispatch => {
    return axios.post(`${url}/${accountUri}/search/${resourceId}`, params, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_ACCOUNTS,
          payload: { accounts: data }
        })
        return data
      })
  }
}

export const createAccount = (resourceId, account) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/${accountUri}/${resourceId}`, account, { headers })
      .then(({ data }) => {
        dispatch({
          type: CREATE_ACCOUNT
        })
        dispatch(addNotification({
          type: 'success',
          message: 'La cuenta se agregó correctamente'
        }))
      })
      .catch(() => {
        addNotification({
          type: 'danger',
          message: 'Ocurrió un error al tratar de crear la cuenta'
        })
      })
  }
}
export const deleteAccount = (resourceId, accountId) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/${accountUri}/${resourceId}/${accountId}`, { headers })
      .then(() => {
        dispatch({
          type: DELETE_ACCOUNT,
          payload: +accountId,
        })
        dispatch(addNotification({
          type: 'success',
          message: 'La cuenta se eliminó correctamente'
        }))
      })
  }
}
