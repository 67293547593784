import {
  NACIONAL,
  EXTRANJERO,
  DIRECTO,
  INTERMEDIARIO,
  CONVENIO_CIE_BBVA,
} from 'App/constants'
export const generalData = {
  prestamistaNacionalOExtranjeros: {
    value: '',
    validation: 'required',
    trigger: 'onChangeNacionalExtranjero',
    name: 'prestamistaNacionalOExtranjeros',
    label: 'Prestamista Nacional o Extranjero',
  },
  estatus: {
    value: '',
    validation: 'required',
    name: 'estatus',
    label: 'Estatus',
  },
  tipoDePersona: {
    value: '',
    validation: 'required',
    trigger: 'onChangeTipoPersona',
    transform: 'stringToNumber',
    name: 'tipoDePersona',
    label: 'Tipo de persona',
  },
  rfc: {
    value: '',
    validation: 'required|rfc:tipoDePersona',
    name: 'rfc',
    label: 'RFC',
  },
  nombreORazonSocial: {
    value: '',
    validation: 'required',
    name: 'nombreORazonSocial',
    label: 'Nombre o Razón Social'
  },
}
export const addressData = {
  direccion: {
    value: '',
    transform: 'stringToBool',
    trigger: 'onChangeDireccion',
    name: 'direccion',
    label: 'Dirección',
  },
  pais: {
    name: 'pais',
    value: '',
    validation: 'requiredIf:direccion',
    label: 'País',
  },
  codigoPostal: {
    value: '',
    trigger: 'onChangeCp',
    pattern: 'number',
    name: 'codigoPostal',
    label: 'Código postal',
  },
  entidadFederativa: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'entidadFederativa',
    label: 'Entidad Federativa/Estado/Provincia',
  },
  municipio: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'municipio',
    label: 'Municipio',
  },
  colonia: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'colonia',
    label: 'Colonia',
  },
  calle: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'calle',
    label: 'Calle',
  },
  exterior: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'exterior',
    label: 'Exterior',
  },
  interior: {
    value: '',
    name: 'interior',
    label: 'Interior',
  },
}
export const contactData = {
  telefono: {
    value: '',
    pattern: 'number',
    name: 'telefono',
    label: 'Telefóno',
    validation: 'isLength:10'
  },
  extension: {
    value: '',
    pattern: 'number',
    name: 'extension',
    label: 'Extensión',
  },
  correoElectronico: {
    value: '',
    validation: 'required|emails:;',
    name: 'correoElectronico',
    label: 'Correo Electrónico',
  },
  identificador: {
    value: '',
    name: 'identificador',
    label: 'Identificador',
  }
}

export const accountData = {
  tipoCuenta: {
    value: '',
    name: 'tipoCuenta',
    label: 'Tipo de Cuenta',
    options: [
      { value: 'Nacional (MX)', label: 'Nacional (MX)' },
      { value: 'Extranjero', label: 'Extranjero' },
      { value: 'Convenio CIE BBVA Bancomer', label: 'Convenio CIE BBVA Bancomer' },
    ]
  },
  clabe: {
    value: '',
    name: 'clabe',
    label: 'Clabe',
    pattern: 'number',
    trigger: 'onChangeClabe',
    validation: 'requiredIfCustom:clabe|isLength:18|clabe',
    depends: 'tipoCuenta',
    dependsValue: NACIONAL,
    maxLength: 18,
  },
  cuentaBancaria: {
    value: '',
    name: 'cuentaBancaria',
    label: 'Cuenta Bancaria',
    disabled: true,
  },
  banco: {
    value: '',
    name: 'banco',
    label: 'Banco',
    disabled: true,
  },
  numeroSucursal: {
    value: '',
    name: 'numeroSucursal',
    label: 'Número Sucursal',
    pattern: 'number',
  },
  plaza: {
    value: '',
    name: 'plaza',
    label: 'Plaza',
    disabled: true,
  },
  pagoDirectoOconBancoIntermediario: {
    value: '',
    name: 'pagoDirectoOconBancoIntermediario',
    label: 'Pago directo o intermediario',
    validation: 'requiredIfCustom:pagoDirectoOconBancoIntermediario',
    depends: 'tipoCuenta',
    dependsValue: EXTRANJERO,
  },
  directoBeneficiario: {
    value: '',
    name: 'directoBeneficiario',
    label: 'Beneficiario',
    validation: 'requiredIfCustom:directoBeneficiario',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: DIRECTO,
  },
  directoDireccion: {
    value: '',
    name: 'directoDireccion',
    label: 'Direccion',
    validation: 'requiredIfCustom:directoDireccion',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: DIRECTO,
  },
  directoIBAN: {
    value: '',
    name: 'directoIBAN',
    label: 'IBAN',
    validation: 'requiredIfCustom:directoIBAN',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: DIRECTO,
  },
  directoSwiftCode: {
    value: '',
    name: 'directoSwiftCode',
    label: 'Swift Code',
    validation: 'requiredIfCustom:directoSwiftCode',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: DIRECTO,
  },
  directoVATID: {
    value: '',
    name: 'directoVATID',
    label: 'VAT ID',
  },
  directoBanco: {
    value: '',
    name: 'directoBanco',
    label: 'Banco',
  },
  interNombreBancoIntermediario: {
    value: '',
    name: 'interNombreBancoIntermediario',
    label: 'Nombre del Banco Intermediario',
    validation: 'requiredIfCustom:interNombreBancoIntermediario',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: INTERMEDIARIO,
  },
  interPais: {
    value: '',
    name: 'interPais',
    label: 'País',
    validation: 'requiredIfCustom:interPais',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: INTERMEDIARIO,
  },
  interSwiftCode: {
    value: '',
    name: 'interSwiftCode',
    label: 'Swift Code del Banco Intermediario',
    validation: 'requiredIfCustom:interPais',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: INTERMEDIARIO,
  },
  interSortCode: {
    value: '',
    name: 'interSortCode',
    label: 'Sort Code',
    validation: 'requiredIfCustom:interSortCode',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: INTERMEDIARIO,
  },
  interNombreBancoReceptor: {
    value: '',
    name: 'interNombreBancoReceptor',
    label: 'Nombre del Banco Receptor',
    validation: 'requiredIfCustom:interNombreBancoReceptor',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: INTERMEDIARIO,
  },
  interPaisEstado: {
    value: '',
    name: 'interPaisEstado',
    label: 'País y Estado',
    validation: 'requiredIfCustom:interPaisEstado',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: INTERMEDIARIO,
  },
  interSwiftCodeBanco: {
    value: '',
    name: 'interSwiftCodeBanco',
    label: 'Swift Code Banco Receptor',
    validation: 'requiredIfCustom:interSwiftCodeBanco',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: INTERMEDIARIO,
  },
  interNombreCuentaBancoReceptor: {
    value: '',
    name: 'interNombreCuentaBancoReceptor',
    label: 'Nombre de la Cuenta Banco Receptor',
    validation: 'requiredIfCustom:interNombreCuentaBancoReceptor',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: INTERMEDIARIO,
  },
  interNumeroCuentaBancoReceptor: {
    value: '',
    name: 'interNumeroCuentaBancoReceptor',
    label: 'Número de cuenta de Banco Receptor',
    pattern: 'number',
    validation: 'requiredIfCustom:interNumeroCuentaBancoReceptor',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: INTERMEDIARIO,
  },
  interBankInstructions: {
    value: '',
    name: 'interBankInstructions',
    label: 'Intermediary Bank Instructions (Fo...',
  },
  interNombreBeneficiario: {
    value: '',
    name: 'interNombreBeneficiario',
    label: 'Nombre del beneficiario',
    validation: 'requiredIfCustom:interNombreBeneficiario',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: INTERMEDIARIO,
  },
  interCiudadPaisBeneficiario: {
    value: '',
    name: 'interCiudadPaisBeneficiario',
    label: 'Ciudad y País del Beneficiario',
    validation: 'requiredIfCustom:interCiudadPaisBeneficiario',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: INTERMEDIARIO,
  },
  interCuentaBeneficiario: {
    value: '',
    name: 'interCuentaBeneficiario',
    label: 'Número de Cuenta del Beneficiario',
    validation: 'requiredIfCustom:interCuentaBeneficiario',
    depends: 'pagoDirectoOconBancoIntermediario',
    dependsValue: INTERMEDIARIO,
  },
  encabezadoEstadoCuenta: {
    value: false,
    name: 'encabezadoEstadoCuenta',
    label: 'Encabezado Estado de Cuenta',
    radioValue: 'encabezadoEstadoCuenta',
  },
  estatus: {
    value: '',
    name: 'estatus',
    label: 'Estatus',
    validation: 'required',
  },
  concatenado: {
    value: '',
    name: 'concatenado',
    label: '',
  },
  convenioBBVA: {
    value: '',
    name: 'convenioBBVA',
    label: 'Convenio CIE BBVA Bancomer',
    validation: 'requiredIfCustom:convenioBBVA',
    depends: 'tipoCuenta',
    dependsValue: CONVENIO_CIE_BBVA,
  },
  referencia: {
    value: '',
    name: 'referencia',
    label: 'Referencia',
    validation: 'requiredIfCustom:referencia',
    depends: 'tipoCuenta',
    dependsValue: CONVENIO_CIE_BBVA,
  },
  concepto: {
    value: '',
    name: 'concepto',
    label: 'Concepto',
  },
  concatenado2: {
    value: '',
    name: 'concatenado2',
    label: '',
  },
  comisionista: {
    value: '',
    name: 'comisionista',
    label: '',
  },
}

export const accountColDef = [
  {
    title: 'Estatus',
    datakey: 'estatus'
  },
  {
    title: 'Tipo Cuenta',
    datakey: 'tipoCuenta',
  },
  {
    title: 'Clabe',
    datakey: 'clabe'
  },
  {
    title: 'Banco',
    datakey: 'banco',
  },
  {
    title: 'Acciones',
    custom: 'acciones'
  }
]

export const colDef = [
  {
    title: 'Estatus',
    datakey: 'estatus',
    sortable: true,
    width: '10%',
  },
  {
    title: 'Prestamista Nacional o Extranjero',
    datakey: 'prestamistaNacionalOExtranjeros',
    sortable: true,
    width: '20%',
  },
  {
    title: 'RFC',
    datakey: 'rfc',
    sortable: true,
    width: '20%',
  },
  {
    title: 'Nombre o Razón social',
    datakey: 'nombreORazonSocial',
    sortable: true,
    width: '40%',
  },
  {
    title: 'Acciones',
    custom: 'actions',
    width: '10%',
  },
]

export const metodoPago = {
  metodoPago: {
    value: '',
    name: 'metodoPago',
    label: 'Método de pago',
    valueProperty: 'id',
    valueLabel: 'metodo',
  },
  cuatroDigitosCuentaBancaria: {
    value: '',
    name: 'cuatroDigitosCuentaBancaria',
    label: 'Últimos 4 digitos de la cuenta bancaria',
  },
  tipoUsoCFDI: {
    value: '',
    name: 'tipoUsoCFDI',
    label: 'Tipo de uso CFDI SAT',
    valueProperty: 'id',
    valueLabel: 'descripcion',
  }
}
export const clabeAccount = {
  cuentaClabeSTPAsignada: {
    value: '',
    name: 'cuentaClabeSTPAsignada',
    label: 'Cuenta Clabe asignada para pago',
    disabled: true
  },
  banco: {
    value: '',
    name: 'banco',
    label: 'Banco',
    disabled: true
  },
  convenioCIEBancomer: {
    value: '',
    name: 'convenioCIEBancomer',
    label: 'Convenio CIE Bancomer Número 1372726 Referencia',
    disabled: true,
  },
  selectPermitirDepositosSinOrden: {
    value: 0,
    name: 'selectPermitirDepositosSinOrden',
    label: '',
  },
  conceptoFactura: {
    value: 0,
    name: 'conceptoFactura',
    label: '',
  },
  IVAFactura: {
    value: 0,
    name: 'IVAFactura',
    label: '',
  },
  usuario: {
    value: 0,
    name: 'usuario',
    label: '',
  },
  claveSAT: {
    value: 0,
    name: 'claveSAT',
    label: '',
  }
}
