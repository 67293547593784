import React from 'react';
import { Column } from 'Components/common/bulma';
import SelectWithError from 'Components/common/form/SelectWithError';


const ColumnSelect = ({ className = 'is-half', ...props }) => {
  return (
    <Column className={className}>
      <SelectWithError
        {...props}
      />
    </Column>
  );
};

export default ColumnSelect;
