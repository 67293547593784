import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { Box, Icon, Columns, Column, Button } from 'Components/common/bulma'
import { Table, ModalRemove } from 'Components/common'
import { getList, deleteOrder } from 'Modules/paymentOrders/retreats'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons'
import { Trans } from '@lingui/macro'
import { retreatsList } from './data'
import { addNotification } from 'Modules/principal'
import { default as PaymentOrderRetreatsForm } from './form'

const STATUS_CREATED = 'Creada'

class ListaContainer extends Component {
  state = {
    showModal: false,
    order: {},
  }

  onEdit = ({ id }) => {
    const { push } = this.props;
    push(`/ordenes-pago/retiros/${id}`);
  }

  toggleModal = order => {
    this.setState(state => ({
      showModal: !state.showModal,
      order,
    }))
  }

  deleteOrder = () => {
    this.props.deleteOrder(this.state.order.id)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'La orden se eliminó correctamente'
        })
        this.setState({
          showModal: false,
        })
      })
  }

  customColAccions = (row) => (
    <Fragment >
      <Button small className="margin-left-xs" primary outlined onClick={() => this.onEdit(row)}>
        <Icon>
          <FontAwesomeIcon icon={faEdit} />
        </Icon>
      </Button>
      <Button small className="margin-left-xs" 
        danger
        outlined
        onClick={() => this.toggleModal(row)}
        disabled={row.estatus !== STATUS_CREATED}
        >
        <Icon>
          <FontAwesomeIcon icon={faTrash} />
        </Icon>
      </Button>
    </Fragment>
  )

  render() {
    const { list } = this.props

    return (
      <Box>
        <ModalRemove
          isDeleting={this.state.showModal}
          title="Eliminar orden de pago (Retiros)"
          toggleModal={this.toggleModal}
          confirmText="¿Esta seguro de querer eliminar la orden de pago (Retiros)?"
          resource={this.state.order.id}
          deleteResource={this.deleteOrder}
        />
        <Columns>
          <Column>
            <Link className="button is-success is-outlined is-pulled-right" to="/ordenes-pago/retiros/nuevo">
              <Icon>
                <FontAwesomeIcon icon={faPlus} />
              </Icon>
              <Trans render="span">Agregar Orden de Pago Retiros</Trans>
            </Link>
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Table
              filterable
              colsetup={retreatsList}
              coldata={list}
              tableClassName='table is-striped is-hoverable is-fullwidth'
              emptyTableMarkUp={<span>No se encontraron registros</span>}
              customCol={{
                customColName: 'actions',
                renderFunc: this.customColAccions
              }}
              refresh={this.props.getList}
            />
          </Column>
        </Columns>
      </Box>
    )
  }
}

const mapStateToProps = ({ paymentOrders }) => ({
  list: paymentOrders.retreats.list,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getList,
  push,
  deleteOrder,
  addNotification,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ListaContainer)
export {
  PaymentOrderRetreatsForm
}