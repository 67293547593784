import { cond, T, F, always, propOr, anyPass, pipe, path, equals } from 'ramda'
import { isNumeric, isNilOrEmptyString } from 'ramda-extension'
import { isEmpty, isNotEmail, isMinLength } from 'App/helpers'
import { toNumber } from 'ramda-adjunct';


export default [
  {
    title: 'Id',
    datakey: 'id',
    width: '10%',
  },
  {
    title: 'Estatus',
    datakey: 'estatus_texto',
    width: '15%',
  },
  {
    title: 'Asunto',
    datakey: 'asunto',
    width: '65%',
  },
  {
    title: 'Acciones',
    datakey: 'actions',
    custom: 'actions',
    width: '10%',
  },
];
export const signatoriesColDef = [
  {
    title: 'Correo',
    datakey: 'email',
    width: '50%',
  },
  {
    title: 'Firmado',
    datakey: 'signed',
    width: '50%',
    format: val => val ? 'Firmado' : 'Pendiente',
    align: 'left',
  },
];

export const contractFieldsDefinition = {
  id: {
    input: {
      value: '',
      name: 'id',
    },
    label: 'Id',
  },
  estatus: {
    input: {
      value: '',
      name: 'estatus',
    },
    label: 'Estatus',
    valueProperty: 'id',
    valueLabel: 'estatus',
    disabled: true,
  },
  asunto: {
    input: {
      value: '',
      name: 'asunto',
    },
    label: 'Asunto',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  descripcion: {
    input: {
      value: '',
      name: 'descripcion',
    },
    label: 'Descripción',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  cuentaIntegradora: {
    input: {
      value: false,
      name: 'cuentaIntegradora',
    },
    label: '¿Requerirá que la Integradora firme?',
  },
  firmaElectronica: {
    input: {
      value: '',
      name: 'firmaElectronica',
    },
    label: 'Tipo de firma',
    valueProperty: 'id',
    valueLabel: 'firma',
    validate: cond([
      [isEmpty, always({ error: '¡Este campo es requerido!' })],
      [T, F]
    ]),
  },
  numeroFirmantes: {
    input: {
      value: '',
      name: 'numeroFirmantes',
      debounceTimeout: 800,
    },
    label: 'Número de firmantes',
    validate: (value, key, form) => {
      const assign = propOr(false, 'firmaElectronica')(form)

      if (+assign.input.value !== 1) {
        return cond([
          [isEmpty, always({ error: '¡Este campo es requerido!' })],
          [T, F]
        ])(value)
      }
      return false
    },
    pattern: anyPass([isNumeric, isNilOrEmptyString]),
  },
  correoObservadores: {
    input: {
      type: 'tags',
      value: '',
      name: 'correoObservadores',
    },
    label: 'Correo observadores',
  },
  contactoEnvio: {
    input: {
      value: '',
      name: 'contactoEnvio',
      minLength: 4,
    },
    label: 'Contacto envío',
    validate: (value, key, form) => {
      const isAutografa = pipe(path(['firmaElectronica', 'input', 'value']), toNumber, equals(1))(form)

      if (isAutografa) {
        return cond([
          [isMinLength(4), always({ error: '¡Este campo requiere minimo 4 caracteres!' })],
          [isEmpty, always({ error: '¡Este campo es requerido!' })],
          [T, F]
        ])(value)
      }
      return false
    },
  },
  direccionEnvio: {
    input: {
      value: '',
      name: 'direccionEnvio',
      minLength: 4,
    },
    label: 'Dirección envío',
    validate: (value, key, form) => {
      const isAutografa = pipe(path(['firmaElectronica', 'input', 'value']), toNumber, equals(1))(form)

      if (isAutografa) {
        return cond([
          [isMinLength(4), always({ error: '¡Este campo requiere minimo 4 caracteres!' })],
          [isEmpty, always({ error: '¡Este campo es requerido!' })],
          [T, F]
        ])(value)
      }
      return false
    },
  },
  telefonoEnvio: {
    input: {
      value: '',
      name: 'telefonoEnvio',
      minLength: 4,
      maxLength: 10,
    },
    label: 'Telefono envío',
    validate: (value, key, form) => {
      const isAutografa = pipe(path(['firmaElectronica', 'input', 'value']), toNumber, equals(1))(form)

      if (isAutografa) {
        return cond([
          [isMinLength(10), always({ error: '¡Este campo requiere minimo 4 caracteres!' })],
          [isEmpty, always({ error: '¡Este campo es requerido!' })],
          [T, F]
        ])(value)
      }
      return false
    },
  },
  urlContrato: {
    input: {
      value: '',
      name: 'urlContrato',
    },
    validate: cond([
      [isEmpty, always({ error: '¡Este campo es requerido, asegurarse de subir el archivo!' })],
      [T, F]
    ]),
  }
}

export const signatoriesFieldDefinition = {
  rol: {
    input: {
      value: '',
      name: 'rol',
    },
    label: 'Rol',
    validate: cond([
      [isEmpty, always({ error: '¡Este campo es requerido!' })],
      [T, F]
    ]),
  },
  correo: {
    input: {
      value: '',
      name: 'correo'
    },
    label: 'Correo',
    validate: cond([
      [isEmpty, always({ error: '¡Este campo es requerido!' })],
      [isNotEmail, always({ error: '¡El formato de correo es incorrecto!' })],
      [T, F]
    ]),
  },
}



export const initialAssigmentFields = {
  asignarTarjeta: {
    input: {
      value: true,
      name: 'asignarTarjeta',
    },
    label: 'Asignar tarjeta',
  },
  assignmentReason: {
    input: {
      value: '',
      name: 'assignmentReason',
    },
    options: [
      { key: 'Alta Usuario Nuevo', label: 'Alta Usuario Nuevo' },
      { key: 'Reposición Tarjeta', label: 'Reposición Tarjeta' },
    ],
    label: 'Motivo Asignación Tarjeta',
    validate: (value, key, form) => {
      const assign = propOr(false, 'asignarTarjeta')(form)

      if (assign.input.value) {
        return cond([
          [isEmpty, always({ error: '¡Este campo es requerido!' })],
          [T, F]
        ])(value)
      }
      return false
    },
    //(a, b, c) => {console.log(a, b, c); return {}},
  },
}

export const initialRevokmentFields = {
  revokmentReason: {
    input: {
      value: '',
      name: 'revokmentReason',
    },
    options: [
      { key: 'Robo de Tarjeta', label: 'Robo de Tarjeta' },
      { key: 'Extravió Tarjeta', label: 'Extravió Tarjeta' },
    ],
    label: 'Motivo Revocación Tarjeta',
    validate: cond([
      [isEmpty, always({ error: '¡Este campo es requerido!' })],
      [T, F]
    ]),
  },
}