import React from 'react'
import { Field, Control } from 'Components/common/bulma'
import classnames from 'classnames'

export default ({
  label,
  name,
  value,
  radioValue,
  onChange,
  disabled = false,
  className = 'is-info',
  ...props
}) => {
  const classes = classnames('is-checkradio', {}, className);

  return (
    <Field>
      <Control>
        <input
          className={classes}
          id={radioValue}
          type="radio"
          name={name}
          value={radioValue}
          onChange={onChange}
          disabled={disabled}
          {...props}
          checked={value === radioValue}
        />
        <label htmlFor={radioValue} className="label">{label}</label>
      </Control>
    </Field>
  )
}