import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Box, Columns, Column, Divider } from 'Components/common/bulma'
import { bindActionCreators } from 'redux'
import { ActionBar, ModalRemove } from 'Components/common'
import { Input, Date as Datepicker, Select, Output } from 'Components/common/form'
import { transfer } from './colSetup'
import { getDataInput, validateData, getValues, mapPropsToState } from 'App/helpers'
import { addNotification } from 'Modules/principal'
import { getBalance } from 'Modules/home'
import { push } from 'connected-react-router'
import { Authorize } from 'Containers/auth/'
import { equals } from 'ramda'
import {
  createOrder,
  deleteOrder,
  getOrder,
  endOrderEdit,
  updateOrder,
  authorizeOrder,
  cleanOrigin,
} from 'Modules/transferOrders'
import {
  getExchangeRate,
} from 'Modules/catalogos'

const AUTHORIZE_PERMISSION = ['Atraspaso']
const STATUS_CREATED = '1'
export class Form extends Component {
  state = {
    order: transfer,
    showErrors: false,
    showAuth: false,
    isRemoving: false,
  }

  onCancel = () => {
    const { push, origin } = this.props
    const isFromPending = equals('authorize')(origin)

    if (isFromPending) {
      push('/ordenes-traspaso/pendientes');
      return
    }
    push('/ordenes-traspaso');
  }

  componentDidMount() {
    const { params: { id } } = this.props.match
    const { getOrder, getExchangeRate, getBalance } = this.props

    getExchangeRate()
    getBalance()

    if (id) {
      getOrder(id)
    }
  }

  componentWillUnmount() {
    this.props.endOrderEdit();
    this.props.cleanOrigin();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.order !== nextProps.order) {
      this.setState({
        order: mapPropsToState(this.state.order, nextProps.order)
      })
    }

    if (this.props.exchangeRate !== nextProps.exchangeRate) {

      if (!this.props.order.estatus || (this.props.order.estatus === STATUS_CREATED)) {
        const { compra: tipoCambioCompra, venta: tipoCambioVenta } = nextProps.exchangeRate
        this.setState({
          order: mapPropsToState(this.state.order, {
            tipoCambioCompra: +tipoCambioCompra,
            tipoCambioVenta: +tipoCambioVenta,
            tipoCambio: +tipoCambioCompra,
          })
        })
      }
    }
    if (this.props.balance !== nextProps.balance) {

      this.setState({
        order: mapPropsToState(this.state.order, {
          saldoDisponibleMxn: nextProps.balance.MXN.disponible,
          saldoDisponibleUsd: nextProps.balance.USD.disponible
        })
      })
    }

  }

  createOrder = () => {
    const { data, hasErrors } = validateData(this.state.order)
    const { id } = this.props.order

    if (hasErrors) {
      this.setState({
        order: data,
        showErrors: true,
      })
      this.props.addNotification({
        type: 'danger',
        message: '¡Favor de validar algunos campos!'
      })
      return false
    }
    if (!id) {
      this.props.createOrder(getValues(data))
        .then(({ id }) => {
          this.props.addNotification({
            type: 'success',
            message: '¡La órden se creó exitosamente!'
          })
          this.props.push(`/ordenes-traspaso/${id}`)
        })
      return
    }

    if (id) {
      this.props.updateOrder(getValues(data))
        .then(() => {
          this.props.addNotification({
            type: 'success',
            message: '¡La órden se actualizó exitosamente!'
          })
        })
      return
    }
  }

  onChangeInput = ({ target }) => {
    const { name } = target;
    const order = getDataInput(target, this.state.order)
    const field = order[name]

    this.setState({
      order,
      showErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }

  getPermissions = () => {
    const { params: { id } } = this.props.match
    return {
      canSave: true,
      canDelete: !!id && this.props.order.estatus === '1',
      canAuthorize: !!id && (+this.props.order.estatus === 1 || +this.props.order.estatus === 9),
    }
  }

  onAuthorize = () => {
    this.setState({
      showAuth: true,
    })
  }

  authorizeOrder = (data) => {
    return this.props.authorizeOrder(
      this.props.order.id,
      data
    ).then(data => {
      this.props.addNotification({
        type: 'success',
        message: 'La órden se autorizó correctamente'
      })
      this.setState({
        showAuth: false,
        // order: mapPropsToState(this.state.order, data)
      })
    })
  }

  cancelAuthorize = () => {
    this.setState({
      showAuth: false,
    })
  }

  onChangeDivisa = ({ value }) => {
    if (!value) return
    const {
      tipoCambioCompra: { value: compra },
      tipoCambioVenta: { value: venta },
    } = this.state.order
    const values = { MXN: 'USD', USD: 'MXN' };
    const tipoCambio = value === 'MXN' ? compra : venta

    this.setState({
      order: mapPropsToState(this.state.order, {
        monedaOrigen: values[value],
        tipoCambio,
      })
    }, this.updateMonto)
  }

  updateMonto = () => {
    const {
      tipoCambio: { value: tipoCambio },
      monedaDivisa: { value: monedaDivisa },
      montoAbonoCuentaDestino: { value: montoAbonoCuentaDestino },
    } = this.state.order
    if (!montoAbonoCuentaDestino) return
    const cargoCuentaDestino = monedaDivisa === 'MXN' ? montoAbonoCuentaDestino / tipoCambio : montoAbonoCuentaDestino * tipoCambio
    this.setState({
      order: mapPropsToState(this.state.order, {
        cargoCuentaDestino
      })
    })
  }

  onRemove = () => {
    this.setState({
      isRemoving: true,
    })
  }

  endRemove = () => {
    this.setState({
      isRemoving: false,
    })
  }

  removeOrder = () => {
    this.props.deleteOrder(this.props.order.id)
      .then(data => {
        this.setState({
          isRemoving: false,
        })
      })
  }
  render() {
    const {
      estatusTexto,
      tipoCambioVenta,
      tipoCambioCompra,
      saldoDisponibleMxn,
      saldoDisponibleUsd,
      fechaTransaccion,
      referenciaBanco,
      monedaDivisa,
      montoAbonoCuentaDestino,
      monedaOrigen,
      cargoCuentaDestino,
      tipoCambio,
    } = this.state.order

    return (
      <Box>
        <ModalRemove
          isDeleting={this.state.isRemoving}
          title="Eliminar inventario"
          toggleModal={this.endRemove}
          confirmText="¿Esta seguro de querer eliminar el inventario?"
          resource={this.props.order.id}
          deleteResource={this.removeOrder}
        />
        {this.state.showAuth && <Authorize
          isOpen={this.state.showAuth}
          authorize={this.authorizeOrder}
          cancel={this.cancelAuthorize}
        />}
        <ActionBar
          permissions={this.getPermissions()}
          onSave={this.createOrder}
          onCancel={this.onCancel}
          onDelete={this.onRemove}
          onAuthorize={this.onAuthorize}
          basicRole={['Otraspaso']}
          authorizeRole={AUTHORIZE_PERMISSION}
        />
        <Columns className="is-multiline">
          <Column className="is-offset-6 is-half">
            <Input
              {...estatusTexto}
              onChange={this.onChangeInput}
              showErrors={this.state.showErrors}
            />
          </Column>
        </Columns>
        <Divider
          content="Información Financiera"
        />
        <Columns className="is-multiline">
          <Column className="is-half">
            <Output
              {...tipoCambioVenta}
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
            />
          </Column>
          <Column className="is-half">
            <Output
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
              {...tipoCambioCompra}
            />
          </Column>
          <Column className="is-half">
            <Output
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
              {...saldoDisponibleMxn}
            />
          </Column>
          <Column className="is-half">
            <Output
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
              {...saldoDisponibleUsd}
            />
          </Column>
        </Columns>
        <Divider
          content="Configuración del Cambio de Divisas"
        />
        <Columns className="is-multiline">
          <Column className="is-half">
            <Datepicker
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
              {...fechaTransaccion}
            />
          </Column>
          <Column className="is-half">
            <Input
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
              {...referenciaBanco}
            />
          </Column>
          <Column className="is-half">
            <Select
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
              {...monedaDivisa}
            />
          </Column>
          <Column className="is-half">
            <Input
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
              {...montoAbonoCuentaDestino}
            />
          </Column>
          <Column className="is-half">
            <Select
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
              {...monedaOrigen}
            />
          </Column>
          <Column className="is-half">
            <Output
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
              {...cargoCuentaDestino}
            />
          </Column>
          <Column className="is-half">
            <Input
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
              {...tipoCambio}
            />
          </Column>
        </Columns>
      </Box>
    )
  }
}

const mapStateToProps = ({ transferOrders, catalogos, home }) => ({
  order: transferOrders.order,
  exchangeRate: catalogos.exchangeRate,
  balance: home.balance,
  origin: transferOrders.origin,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  deleteOrder,
  createOrder,
  addNotification,
  push,
  getOrder,
  endOrderEdit,
  updateOrder,
  authorizeOrder,
  getExchangeRate,
  getBalance,
  cleanOrigin,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Form)
