import axios from 'axios'
import { getHeaders } from 'Modules/util'
import { map, reject, propEq} from 'ramda'

const url = process.env.REACT_APP_API

export const GET_LIST = 'paymentOrders/receiptFee/GET_LIST'
export const DELETE_ORDER = 'paymentOrders/receiptFee/DELETE_ORDER'
export const CREATE_ORDER = 'paymentOrders/receiptFee/CREATE_ORDER'
export const UPDATE_ORDER = 'paymentOrders/receiptFee/UPDATE_ORDER'
export const UPLOAD_INVOICE = 'paymentOrders/receiptFee/UPLOAD_INVOICE'
export const GET_ORDER = 'paymentOrders/receiptFee/GET_ORDER'
export const GET_INSTALLMENTS = 'paymentOrders/receiptFee/GET_INSTALLMENTS'
export const ADD_INSTALLMENT = 'paymentOrders/receiptFee/ADD_INSTALLMENT'
export const END_ORDER_EDIT = 'paymentOrders/receiptFee/END_ORDER_EDIT'
export const AUTHORIZE_INSTALLMENT = 'paymentOrders/receiptFee/AUTHORIZE_INSTALLMENT'
export const DELETE_INSTALLMENT = 'paymentOrders/receiptFee/DELETE_INSTALLMENT'
export const GET_ATTACHMENTS = 'paymentOrders/receiptFee/GET_ATTACHMENTS'
export const IS_FETCHING_RECEIPT_FEE = 'paymentOrders/receiptFee/IS_FETCHING_RECEIPT_FEE'
export const IS_FETCHING_RECEIPT_FEE_COMPLETE = 'paymentOrders/receiptFee/IS_FETCHING_RECEIPT_FEE_COMPLETE'

const initialState = {
  list: {
    data: []
  },
  invoice: {},
  order: {},
  installments: {
    data: []
  },
  attachments: [],
  isFetchingReceiptFee: false
}

export default (state = initialState, action) => {
  switch (action.type) {

    case GET_LIST:
    case UPLOAD_INVOICE:
    case CREATE_ORDER:
    case GET_ATTACHMENTS:
    case GET_ORDER:
      return { ...state, ...action.payload }
    case DELETE_ORDER:
      const { data, ...meta } = state.list
      return {
        ...state, list: {
          data: data.map(it => {
            if (it.id === action.payload) {
              it.estatus = '3'
              it.estatusTexto = 'Cancelada'
            }
            return it
          }), ...meta, last_page: Math.ceil(data.length / meta.per_page)
        }
      }
    case GET_INSTALLMENTS:
      return {
        ...state,
        installments: { data: action.payload },
      }
    case ADD_INSTALLMENT:
      return {
        ...state,
        installments: {data: state.installments.data.concat(action.payload)}
      }
    case END_ORDER_EDIT:
      return initialState
    case AUTHORIZE_INSTALLMENT:
      let mapper = map(it => {
        if(it.id === action.payload.idInstallment ){
          it.estatus = action.payload.data.estatus
          it.estatusTexto = action.payload.data.estatusTexto
        }
        return it
      })

      return {
        ...state,
        installments: { data: mapper(state.installments.data)}}
    case DELETE_INSTALLMENT:
      return {
        ...state,
        installments: { data: reject(propEq('id', action.payload))(state.installments.data)}}
    case IS_FETCHING_RECEIPT_FEE:
      return { ...state, isFetchingReceiptFee: true }
    case IS_FETCHING_RECEIPT_FEE_COMPLETE:
      return { ...state, isFetchingReceiptFee: false }
    default:
      return state
  }
}

export const isFetchingReceiptFee = () => ({
  type: IS_FETCHING_RECEIPT_FEE
})

export const isFetchingReceiptFeeComplete = () => ({
  type: IS_FETCHING_RECEIPT_FEE_COMPLETE
})


export const getList = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingReceiptFee())
    return axios.post(`${url}/payment-honorarium/search`, params, { headers })
      .then(({ data }) => {
        dispatch(isFetchingReceiptFeeComplete())
        dispatch({
          type: GET_LIST,
          payload: { list: data }
        })
      })
  }
}
export const deleteOrder = (orderId) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/payment-honorarium/${orderId}`, { headers })
      .then(() => {
        dispatch({
          type: DELETE_ORDER,
          payload: orderId,
        })
      })
  }
}
export const createOrder = (order) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingReceiptFee())
    return axios.post(`${url}/payment-honorarium`, order, { headers })
      .then(({data}) => {
        dispatch(isFetchingReceiptFeeComplete())
        dispatch({
          type: CREATE_ORDER,
          payload: {order: {...data, flagXML: false, flagPDF: false}},
        })
        return {...data, flagXML: false, flagPDF: false}
      })
  }
}
export const updateOrder = ({id, ...order}) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingReceiptFee())
    return axios.post(`${url}/payment-honorarium/${id}`, order, { headers })
      .then(({data}) => {
        dispatch(isFetchingReceiptFeeComplete())
        dispatch({
          type: UPDATE_ORDER,
          payload: {order: {...data, flagXML: false, flagPDF: false}},
        })
        return {...data, flagXML: false, flagPDF: false}
      })
  }
}
export const uploadInvoice = (data) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/storage/upload/`, data, { headers })
      .then(({ data }) => {
        dispatch({
          type: UPLOAD_INVOICE,
          payload: { invoice: data }
        })
        return data
      })
  }
}

export const getOrder = (id) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/payment-honorarium/${id}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_ORDER,
          payload: { order: data }
        })
      })
  }
}

export const getInstallments = (order) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/payment-honorarium/deferred/${order}`, {}, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_INSTALLMENTS,
          payload: data,
        })
      })
  }
}

export const addInstallment = (order, data) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/payment-honorarium/registerPayment/${order}`, data, { headers })
      .then(({ data }) => {
        dispatch({
          type: ADD_INSTALLMENT,
          payload: data,
        })
      })
  }
}

export const endOrderEdit = () => {
  return {
    type: END_ORDER_EDIT
  }
}

export const authorizeInstallment = ({ idOrder, idInstallment, ...data }) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/authorize-payment/${idOrder}/${idInstallment}`, data, { headers })
      .then(({ data }) => {
        dispatch({
          type: AUTHORIZE_INSTALLMENT,
          payload: {order: data, idInstallment, data},
        })
        return data
      })
  }
}

export const deleteInstallment = (idOrder, idInstallment) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/payment-honorarium/deletePayment/${idOrder}/${idInstallment}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: DELETE_INSTALLMENT,
          payload: idInstallment,
        })
      })
  }
}

export const getAttachments = idOrder => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/storage/listContents/${idOrder}/pago`,  { headers })
      .then(({data}) => {
        dispatch({
          type: GET_ATTACHMENTS,
          payload: {attachments: data}
        })
      })
  }
}