import { cond, always, T, F, } from 'ramda';
import { isEmpty } from 'App/helpers'

export const fields = {
  taxSituation: {
    input: {
      value: '',
      name: 'taxSituation',
      accept: 'application/pdf',
    },
    label: 'Constancia de Situación Fiscal',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  addressProof: {
    input: {
      value: '',
      name: 'addressProof',
      accept: 'application/pdf',
    },
    label: 'Comprobante de Domicilio',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  id: {
    input: {
      value: '',
      name: 'id',
      accept: 'application/pdf',
    },
    label: 'Identificación Oficial (INE o Pasaporte)',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  curp: {
    input: {
      value: '',
      name: 'curp',
      accept: 'application/pdf',
    },
    label: 'CURP',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  id_personal_data: {
    input: {
      value: '',
      name: 'id_personal_data',
    },

  }

};
