import React from 'react';
import { Columns } from 'Components/common/bulma';

const CommonForm = ({ children, fields, onChangeField, showErrors, className = 'is-multiline' }) => {
  // Aquí va la lógica y el contenido del componente
  return (
    <Columns className={className}>
      {children.map((child, index) => React.cloneElement(child, { key: index, onChange: onChangeField, showErrors, ...fields[child.props.name] }))}
    </Columns>
  );
};

export default CommonForm;
