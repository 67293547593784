import React from 'react'
import { Link } from 'react-router-dom';
import { Box, Table } from 'Components/common/bulma'

const Operaciones = ({ operaciones, labels }) => {
  return (
    <Box>
      <h1 className="title is-3">Resumen de operaciones</h1>
      <Table striped className="is-hoverable is-fullwidth" style={{ marginBottom: 0 }}>
        <Table.Body>
          {Object.keys(operaciones).map((it, key) => {
            return (<Table.Tr key={key}>
              <Table.Td>
                <Link to={labels[it].link}>{labels[it]?.label || it} </Link>
              </Table.Td>
              <Table.Td className="has-text-right has-text-weight-bold">
                {operaciones[it]}
              </Table.Td>

            </Table.Tr>)
          })}
        </Table.Body>
      </Table>
    </Box>
  )
}

export default Operaciones