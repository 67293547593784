import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import { Box, Columns, Column, Divider, Modal, Message, Card, Media, Title, Button } from 'Components/common/bulma'
import { ActionBar, Table, ModalRemove } from 'Components/common'
import { addNotification } from 'Modules/principal'
import {
  amortization,
  addressData,
  // amortizationCols,
  paymentCols,
} from './data'
import { AddressSection, } from 'Components/catalogs/providers'
import {
  getResources,
  getResource,
  getAccounts,
} from 'Modules/catalogs/lenders'
import {
  getPaises,
  getEntidades,
  getTipoPersona,
  getRealPaymentMethod,
} from 'Modules/catalogos'
import { mapPropsToState, getDataInput, getValues, validateData } from 'App/helpers'
import { Select, Output, Input } from 'Components/common/form'
import {
  getPaymentEstatus,
} from 'Modules/catalogos'
import {
  createOrder,
  updateOrder,
  getOrder,
  authorizeOrder,
  endOrderEdit,
  deleteOrder,
  getRelatedOrder,
  getCreditorOrder,
} from 'Modules/loanOrders/chargeAmortization'
import { cleanOrigin } from 'Modules/loanOrders/creditor'
import { Authorize } from 'Containers/auth'
import numeral from 'numeral'
import { omit, equals } from 'ramda'

export class Form extends Component {
  state = {
    addressData,
    order: amortization,
    contratoPrestamo: undefined,
    mutuo: undefined,
    showErrors: false,
    showModal: false,
  }

  componentDidMount() {
    const { getPaises, getEntidades, getResources,
      getPaymentEstatus,
      getTipoPersona,
      getRealPaymentMethod,
      getRelatedOrder,
    } = this.props

    getPaises()
    getEntidades()
    getResources({ page: 1, size: 1000 })
    getPaymentEstatus()
    getTipoPersona()
    getRealPaymentMethod()

    const id = this.props.match.params.id

    if (!id) {
      this.setState({ showModal: true })
      getRelatedOrder().then(data => {
        if (!data.length) {
          this.setState({
            hasOrders: false
          })
        }
      })
    }
    if (id && (id !== this.state.order.id.value)) {
      this.props.getOrder(id)
    }
  }

  componentWillReceiveProps(nextProps) {

    if (this.props.lender !== nextProps.lender) {
      this.setState({
        order: mapPropsToState(this.state.order, nextProps.lender),
        addressData: mapPropsToState(this.state.addressData, nextProps.lender)
      })
    }
    if (this.props.order !== nextProps.order) {
      this.setState({
        order: mapPropsToState(this.state.order, nextProps.order),
        addressData: mapPropsToState(this.state.addressData, nextProps.order)
      })

      if (nextProps.order.prestamista && !nextProps.order.lenderAccounts) {
        this.props.getAccounts(nextProps.order.prestamista, 1, 1000)
      }
    }
  }

  componentWillUnmount() {
    this.props.endOrderEdit()
    this.props.cleanOrigin();
  }

  onChangeInput = ({ target }) => {
    const { name } = target;
    const order = getDataInput(target, this.state.order)
    const field = order[name]

    this.setState({
      order,
      showErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }

  onChangeLender = ({ value }) => {
    if (value) {
      this.props.getResource(value)
    }
  }

  getPermissions = () => {
    return ({
      canSave: true,
      canDelete: !!(this.props.order && this.props.order.id && this.props.order.estatusOrden === '1'),
      canAuthorize: !!(this.props.order && this.props.order.id && (+this.props.order.estatusOrden === 1 || +this.props.order.estatusOrden === 8))
    })
  }

  onCancel = () => {
    const { push, origin } = this.props
    const isFromPending = equals('authorize')(origin)

    if (isFromPending) {
      push('/ordenes-prestamo/autorizar');
      return
    }
    push('/ordenes-prestamo/amortizacion-cobro');
  }

  openAuthizeOrderModal = () => {
    this.setState({
      showAuth: true
    })
  }

  closeAuthorizeOrderModal = () => {
    this.setState({
      showAuth: false
    })
  }

  onAuthorizeOrder = props => {
    const { id } = this.props.order
    return this.props.authorizeOrder({
      id,
      ...props,
    })
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: '¡Orden autorizada correctamente!'
        })
        this.setState({
          showAuth: false
        })
      })
  }

  createOrder = () => {
    const { data, hasErrors } = validateData(this.state.order)
    if (hasErrors) {
      this.setState({
        order: data,
        showErrors: true,
      })
      this.props.addNotification({
        type: 'danger',
        message: '¡Favor de validar algunos campos!'
      })
      return
    }

    const order = getValues(this.state.order)
    const { order: orderProp } = this.props

    if (orderProp && orderProp.id) {
      this.props.updateOrder(order)
        .then(() => {
          this.props.addNotification({
            type: 'success',
            message: '¡Orden actualizada con éxito!'
          })
        })
        .catch(({ response: { data } }) => {
          this.props.addNotification({
            type: 'danger',
            message: JSON.stringify(data.message)
          })
        })
    } else {
      this.props.createOrder(order)
        .then((data) => {
          this.props.addNotification({
            type: 'success',
            message: '¡Orden creada con éxito!'
          })
          this.props.push(`/ordenes-prestamo/amortizacion-cobro/${data.id}`)
        })
        .catch(({ response: { data } }) => {
          this.props.addNotification({
            type: 'danger',
            message: JSON.stringify(data.message)
          })
        })
    }
  }

  onChangeMetodoPago = ({ value }) => {

    if (+value === 2) {
      this.setState({
        order: mapPropsToState(this.state.order, { cuentaMXN: undefined })
      })
      return
    }
  }

  removeOrder = () => {
    this.props.deleteOrder(this.props.order.id)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'La órden de prestamo se eliminó correctamente'
        })
        this.props.push('/ordenes-prestamo/amortizacion-cobro')
      })
      .catch(({ response: { data } }) => {
        this.props.addNotification({
          type: 'danger',
          message: data.message
        })
      })
  }

  onRemoveOrder = () => {
    this.setState({
      showConfirmRemoveModal: true
    })
  }

  closeModal = () => {
    this.setState({
      showModal: false
    })
  }

  selectOrder = order => {
    this.setState({
      showModal: false,
      orderSelected: order,
    })
    this.props.getCreditorOrder(order.id)
      .then(data => {
        this.setState({
          order: mapPropsToState(this.state.order, {
            ...omit(['monto', 'id', 'estatusOrden'], data),
            pagoRelacionadoMutuo: order.id
          }),
          addressData: mapPropsToState(this.state.addressData, data)
        })
      })
  }

  render() {
    const { paises, entidades,
      lenders,
      paymentEstatus,
      tipoPersona,
    } = this.props
    const {
      estatusOrden,
      prestamista,
      tipoDePersona,
      nombreORazonSocial,
      rfc,
      correoElectronico,
      cuentaParaAbonoORetiro,
      monto,
      montoDepositar,
      montoDepositarTransito,
      montoDepositado,
      claveDePagoReferenciado,
      cuentaClabeSTPAsignada,
      montoAmortizar,
    } = this.state.order
    return (
      <Box>
        <Modal isActive={this.state.showModal}>
          <Message primary>
            <Message.Header>
              <p>Seleccionar orden</p>
            </Message.Header>
            <Message.Body>
              <Columns className="is-multiline">
                {this.state.hasOrders === false && 'No existen ordenes a relacionar'}
                {this.props.relatedOrders.map(it =>
                  <Column className="is-half">
                    <Card style={{ maxHeight: '400px' }}>
                      <Card.Content>
                        <div>Orden: # {it.id}</div>
                        <Media>
                          <Media.Left >
                            <div style={{
                              minHeight: '50px', minWidth: '50px',
                              backgroundColor: 'black',
                              color: 'white',
                              fontWeight: 'bolder',
                              borderRadius: '50%',
                              fontSize: '30px',
                              textAlign: 'center'
                            }}>$</div>
                          </Media.Left>
                          <Media.Content>
                            <Title is="3">{numeral(+it.monto).format('0,0.00')}</Title>
                            <Button className="is-black is-pulled-right"
                              onClick={() => this.selectOrder(it)}
                            >Seleccionar</Button>
                          </Media.Content>
                        </Media>
                      </Card.Content>
                    </Card>
                  </Column>
                )}
              </Columns>
              <Columns>
                <Column>
                  <Button
                    className="is-pulled-right"
                    onClick={this.onCancel}>Cancelar</Button>
                </Column>
              </Columns>
            </Message.Body>
          </Message>
        </Modal>
        <ModalRemove
          isDeleting={this.state.showConfirmRemoveModal}
          title="Eliminar orden de préstamo"
          toggleModal={this.hideConfirmRemoveModal}
          confirmText="¿Esta seguro de querer la orden de préstamo?"
          resource=""
          deleteResource={this.removeOrder}
        />
        {this.state.showAuth && (<Authorize
          isOpen={this.state.showAuth}
          authorize={this.onAuthorizeOrder}
          cancel={this.closeAuthorizeOrderModal}
        />)}
        <ActionBar
          basicRole={['OpresAmortizacionCobroPrestamo']}
          onCancel={this.onCancel}
          onSave={this.createOrder}
          permissions={this.getPermissions()}
          authorizeRole={['ApresAmortizacionCobroPrestamo']}
          onAuthorize={this.openAuthizeOrderModal}
          onDelete={this.onRemoveOrder}
        />
        <Columns>
          <Column className="is-half">
            <Input
              {...montoAmortizar}
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
            />
          </Column>
        </Columns>
        <Divider content="GENERALES DE LA FACTURA" />
        <Columns className="is-multiline">
          <Column className="is-half">
            <Select
              {...estatusOrden}
              onChange={this.onChangeInput}
              options={paymentEstatus}
              showErrors={this.state.showErrors}
            />
          </Column>
          <Column className="is-half">
            <Select
              options={lenders}
              {...prestamista}
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
            />
          </Column>
        </Columns>
        <Divider content="PRESTAMISTA" />
        <Columns className="is-multiline">
          <Column className="is-half">
            <Select
              options={tipoPersona}
              showErrors={this.state.showErrors}
              {...tipoDePersona}
              onChange={this.onChangeInput}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...nombreORazonSocial}
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...rfc}
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...correoElectronico}
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
            />
          </Column>
        </Columns>
        <AddressSection
          data={this.state.addressData}
          onChange={e => this.onChangeInput(e, 'addressData')}
          paises={paises}
          entidades={entidades}
          extranjero={false}
          showErrors={this.state.showErrors}
        />
        <Divider content="DATOS FINANCIEROS DEL PRÉSTAMO" />
        <Columns className="is-multiline">
          <Column className="is-half">
            <Select
              {...cuentaParaAbonoORetiro}
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
            />
          </Column>
          <Column className="is-half">
            <Input
              {...monto}
              showErrors={this.state.showErrors}
              onChange={this.onChangeInput}
            />
          </Column>
        </Columns>
        <Divider content="PAGOS" />
        <Table
          colsetup={paymentCols}
          coldata={{ data: this.props.order ? this.props.order.pagos : [] }}
        />
        <Divider content="RESUMEN DEL PAGO" />
        <Columns className="is-multiline">
          <Column className="is-half">
            <Output
              {...montoDepositar}
            />
          </Column>
          <Column className="is-half">
            <Output
              {...montoDepositarTransito}
            />
          </Column>
          <Column className="is-half">
            <Output
              {...montoDepositado}
            />
          </Column>
        </Columns>
        <Divider content="DATOS PARA EFECTUAR DEPOSITO" />
        <Columns>
          <Column className="is-half">
            <Output
              {...cuentaClabeSTPAsignada}
            />
          </Column>
          <Column className="is-half">
            <Output
              {...claveDePagoReferenciado}
            />
          </Column>
        </Columns>
      </Box>
    )
  }
}

const mapStateToProps = ({ catalogos: {
  paises,
  entidades,
  paymentEstatus,
  tipoPersona,
  realPaymentMethod,
}, catalogs: { lenders }, loanOrders }) => ({
  paises,
  entidades,
  paymentEstatus,
  lenders: lenders.resources.data,
  lender: lenders.resource,
  lenderAccounts: lenders.accounts,
  tipoPersona,
  realPaymentMethod,
  relatedOrders: loanOrders.chargeAmortization.relatedOrders,
  order: loanOrders.chargeAmortization.order,
  origin: loanOrders.creditor.origin,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  push,
  getPaises,
  getEntidades,
  getResources,
  getResource,
  getPaymentEstatus,
  getTipoPersona,
  createOrder,
  addNotification,
  getRealPaymentMethod,
  updateOrder,
  getOrder,
  authorizeOrder,
  endOrderEdit,
  getAccounts,
  deleteOrder,
  getRelatedOrder,
  getCreditorOrder,
  cleanOrigin,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Form)
