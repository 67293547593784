import axios from 'axios'
import { getHeaders } from '../util'

const url = process.env.REACT_APP_API

const GET_COLLABORATORS = 'catalogs/GET_COLLABORATORS'
const CREATE_COLLABORATOR = 'catalogs/CREATE_COLLABORATOR'
const GET_COLLABORATOR = 'catalogs/colaborators/GET_COLLABORATOR'
const DELETE_COLLABORATOR = 'catalogs/colaborators/DELETE_COLLABORATOR'
const END_EDIT_COLLABORATOR = 'catalogs/colaborators/END_EDIT_COLLABORATOR'
const UPDATE_COLLABORATOR = 'catalogs/colaborators/UPDATE_COLLABORATOR'
const GET_ACCOUNTS = 'catalogs/colaborators/GET_ACCOUNTS'
const DELETE_ACCOUNT = 'catalogs/colaborators/DELETE_ACCOUNT'
const CREATE_ACCOUNT = 'catalogs/colaborators/CREATE_ACCOUNT'
const RESET_COLLABORATOR = 'catalogs/colaborators/RESET_COLLABORATOR'
const IS_FETCHING_COLLABORATORS = 'catalogs/colaborators/IS_FETCHING_COLLABORATORS'
const IS_FETCHING_COLLABORATORS_COMPLETE = 'catalogs/colaborators/IS_FETCHING_COLLABORATORS_COMPLETE'

const initialState = {
  collaborators: {
    data: []
  },
  collaborator: {},
  accounts: {
    data: []
  },
  isFetchingCollaborators: false
}

export default (state = initialState, action) => {
  switch (action.type) {

    case GET_COLLABORATORS:
    case GET_COLLABORATOR:
    case CREATE_COLLABORATOR:
    case GET_ACCOUNTS:
      return { ...state, ...action.payload }
    case DELETE_COLLABORATOR:
      const {data: dataColl, ...metaColl} = state.collaborators
      return { ...state, collaborators: {data: dataColl.filter(it => it.id !== action.payload), ...metaColl, last_page: Math.ceil(dataColl.length/metaColl.per_page)}}
    case DELETE_ACCOUNT:
      const {data, ...meta} = state.accounts
      return { ...state, accounts: {data: data.filter(it => it.id !== action.payload), ...meta, last_page: Math.ceil(data.length/meta.per_page)}}
    case END_EDIT_COLLABORATOR:
      return { ...state, collaborator: {}, accounts: { data: [] } }
    case IS_FETCHING_COLLABORATORS:
      return { ...state, isFetchingCollaborators: true }
    case IS_FETCHING_COLLABORATORS_COMPLETE:
      return { ...state, isFetchingCollaborators: false }
    case RESET_COLLABORATOR:
      return { ...state, collaborator: {}, accounts: { data: []} }
    default:
      return state
  }
}
export const isFetchingCollaborators = () => ({
  type: IS_FETCHING_COLLABORATORS
})

export const isFetchingCollaboratorsComplete = () => ({
  type: IS_FETCHING_COLLABORATORS_COMPLETE
})

export const getCollaborators = (params) => {
  const headers = getHeaders();
  return dispatch => {
    dispatch(isFetchingCollaborators());
    return axios.post(`${url}/collaborator/search`, params , { headers })
      .then(({ data }) => {
        dispatch(isFetchingCollaboratorsComplete())
        dispatch({
          type: GET_COLLABORATORS,
          payload: { collaborators: data }
        })
      })
  }
}
export const createCollaborator = (collaborator) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingCollaborators());
    return axios.post(`${url}/collaborator`, collaborator, { headers })
      .then(({ data }) => {
        dispatch(isFetchingCollaboratorsComplete())
        dispatch({
          type: CREATE_COLLABORATOR,
          payload: { collaborator: data }
        })
        return data
      })
  }
}
export const updateCollaborator = ({id, ...collaborator}) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingCollaborators());
    return axios.put(`${url}/collaborator/${id}`, collaborator, { headers })
      .then(({ data }) => {
        dispatch(isFetchingCollaboratorsComplete())
        dispatch({
          type: UPDATE_COLLABORATOR,
        })
        return data
      })
  }
}
export const getCollaborator = (collaboratorId) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/collaborator/${collaboratorId}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_COLLABORATOR,
          payload: { collaborator: data }
        })
      })
  }
}
export const deleteCollaborator = (collaboratorId) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/collaborator/${collaboratorId}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: DELETE_COLLABORATOR,
          payload: collaboratorId
        })
      })
  }
}
export const getAccounts = (collaboratorId, page) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/bank-collaborator/${collaboratorId}`, { headers, params:{ page: page} })
      .then(({ data }) => {
        dispatch({
          type: GET_ACCOUNTS,
          payload: { accounts: data}
        })
      })
  }
}
export const deleteAccount = (collaboratorId, accountId) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/bank-collaborator/${collaboratorId}/${accountId}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: DELETE_ACCOUNT,
          payload: accountId,
        })
      })
  }
}
export const createAccount = (collaboratorId, account) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/bank-collaborator/${collaboratorId}`, account, { headers })
      .then(({ data }) => {
        dispatch({
          type: CREATE_ACCOUNT,
        })
      })
  }
}

export const endEditCollaborator = () => ({
  type: END_EDIT_COLLABORATOR
})

export const resetCollaborator = () => ({
  type: RESET_COLLABORATOR
})
