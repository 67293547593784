export const monederoGasolinaColData =
  [
    {
      title: 'ID',
      datakey: 'id',
      sortable: true,
      width: '10%',
    },
    {
      title: 'EstatusRED',
      datakey: 'estatusEdenred',
      sortable: true,
      width: '40%',
    },
    {
      title: 'Identificador',
      datakey: 'identificador',
      sortable: true,
      width: '40%',
    },
    {
      title: 'Acciones',
      custom: 'acciones',
      width: '10%',
    }
  ];