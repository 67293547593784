import moment from 'moment'
import numeral from 'numeral'

const limitHour = moment({ hour: 13, minute: 5 })
const minDate = moment() < limitHour ? moment({ hour: 0 }) : moment({ hour: 0 }).add(1, 'day')

export const transferList =
  [
    {
      title: 'Id',
      datakey: 'id',
      sortable: true,
      width: '20%',
    },
    {
      title: 'Estatus',
      datakey: 'estatusTexto',
      sortable: true,
      width: '20%',
    },
    {
      title: 'Cargos',
      datakey: 'cargos',
      format: '$ 0,0.00',
      sortable: true,
      width: '20%',
    },
    {
      title: 'Moneda Divisa',
      datakey: 'monedaDivisa',
      sortable: true,
      width: '20%',
    },
    {
      title: 'Acciones',
      datakey: 'actions',
      custom: 'actions',
      width: '20%',
    }
  ]

export const transfer = {
  id: {
    value: '',
    name: 'id',
  },
  estatusTexto: {
    name: 'estatusTexto',
    label: 'Estatus',
    value: 'Creada',
    disabled: true,
  },
  tipoCambioVenta: {
    name: 'tipoCambioVenta',
    label: 'Tipo de Cambio Venta',
    value: '',
    format: val => Number(val)
  },
  tipoCambioCompra: {
    name: 'tipoCambioCompra',
    label: 'Tipo de Cambio Compra',
    value: '',
    format: val => Number(val)
  },
  saldoDisponibleMxn: {
    name: 'saldoDisponibleMxn',
    label: 'Saldo Disponible en MXN',
    value: '',
    format: val => numeral(val).format('$ 0,0.00')
  },
  saldoDisponibleUsd: {
    name: 'saldoDisponibleUsd',
    label: 'Saldo Disponible en USD',
    value: '',
    format: val => numeral(val).format('$ 0,0.00')
  },
  fechaTransaccion: {
    name: 'fechaTransaccion',
    label: 'Fecha Programación Cambio',
    value: minDate.toDate(),
    minDate: minDate.toDate(),
    validation: 'required',
    dataMarshaller: val => moment(val).format('YYYY-MM-DD'),
  },
  referenciaBanco: {
    name: 'referenciaBanco',
    label: 'Referencia',
    value: moment().format('DDMMYY'),
    validation: 'required',
  },
  monedaDivisa: {
    value: 'MXN',
    name: 'monedaDivisa',
    label: 'Divisa',
    options: [
      { id: 'MXN', label: 'MXN' },
      { id: 'USD', label: 'USD' }
    ],
    valueProperty: 'id',
    valueLabel: 'label',
    validation: 'required',
    tooltip: 'Es la Cuenta donde se abonará el resultado del Cambio.',
    trigger: 'onChangeDivisa',
  },
  montoAbonoCuentaDestino: {
    value: '',
    name: 'montoAbonoCuentaDestino',
    label: 'Monto Abono Cuenta Destino',
    tooltip: 'Es la cantidad que se abonara en la Cuenta Destino.',
    validation: 'required',
    trigger: 'updateMonto'
  },
  monedaOrigen: {
    value: 'USD',
    name: 'monedaOrigen',
    label: 'Cuenta Origen',
    validation: 'required',
    tooltip: 'Es la cuenta donde se descontarán los recursos a ser cambiados.',
    options: [
      { id: 'MXN', label: 'MXN' },
      { id: 'USD', label: 'USD' },
    ],
    valueProperty: 'id',
    valueLabel: 'label',
    disabled: true,
  },
  cargoCuentaDestino: {
    value: '',
    name: 'cargoCuentaDestino',
    label: 'Monto retiro Cuenta Origen',
    tooltip: 'Es la cantidad que se abonara en la Cuenta Destino.',
    validation: 'required',
    disabled: true,
    format: val => numeral(val).format('0.00')
  },
  tipoCambio: {
    value: '',
    name: 'tipoCambio',
    label: 'Tipo de Cambio',
    validation: 'required',
    disabled: true,
  },
}