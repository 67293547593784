import axios from 'axios'
import { getHeaders } from './util'

const url = process.env.REACT_APP_API

const initialState = {
  list: {
    data: []
  },
  conciliation: {},
  clientOrders: [],
}
const GET_LIST = 'conciliations/GET_LIST'
const GET_TRANSACTION = 'conciliations/GET_TRANSACTION'
const GET_CONCILIATION = 'conciliations/GET_CONCILIATION'
const GET_CLIENT_ORDERS = 'conciliations/GET_CLIENT_ORDERS'
const GET_MONEY_LENDER_ORDERS = 'conciliations/GET_MONEY_LENDER_ORDERS'
const GET_INTEGRATE_ORDERS = 'conciliations/GET_INTEGRATE_ORDERS'
const SET_CURRENT_TRANSACTION = 'conciliations/SET_CURRENT_TRANSACTION'
const CONCILIATE = 'conciliations/CONCILIATE'

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case GET_LIST:
    case GET_TRANSACTION:
    case GET_CONCILIATION:
    case GET_CLIENT_ORDERS:
    case GET_MONEY_LENDER_ORDERS:
    case GET_INTEGRATE_ORDERS:
    case SET_CURRENT_TRANSACTION:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const getList = params => {
  return dispatch => {
    return axios.post(`${url}/conciliation/search`, params, { headers: getHeaders() })
      .then(({ data }) => {
        console.log('data: ', data);
        dispatch({
          type: GET_LIST,
          payload: { list: data }
        })
      })
  }
}

export const getConciliation = id => {
  return dispatch => {
    return axios.get(`${url}/conciliation/${id}`, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: GET_CONCILIATION,
          payload: { conciliation: data }
        })
      })
  }
}

export const getTransaction = id => {
  return dispatch => {
    return axios.get(`${url}/conciliation/aggregator/${id}`, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: GET_TRANSACTION,
          payload: { transaction: data }
        })
      })
  }
}

export const getClientOrders = id => {
  return dispatch => {
    return axios.get(`${url}/conciliation/customersCurrency/${id}`, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: GET_CLIENT_ORDERS,
          payload: { clientOrders: data.map(it => ({...it, type: 'factura'})) }
        })
      })
  }
}

export const getMoneyLenderOrders = id => {
  return dispatch => {
    return axios.get(`${url}/conciliation/moneylender/${id}`, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: GET_MONEY_LENDER_ORDERS,
          payload: { clientOrders: data.map(it => ({...it, id: it.idPrestamo, type: 'prestamo'})) }
        })
      })
  }
}

export const getOrders = id => {
  return dispatch => {
    return axios.get(`${url}/conciliation/integrated/${id}`, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: GET_INTEGRATE_ORDERS,
          payload: { clientOrders: data.map(it => ({...it,
            id: it.idPrestamo ? it.idPrestamo : it.idFacturacion,
            type: it.idPrestamo ? 'prestamo' : 'factura'
          }))  }
        })
      })
  }
}

export const conciliate = (conciliation, id) => {
  return dispatch => {
    return axios.post(`${url}/conciliation/conciliate/${id}`, conciliation, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: CONCILIATE,
        })
      })
  }
}

export const setCurrentTransaction = currentTransaction => {
  return {
    type: SET_CURRENT_TRANSACTION,
    payload: { currentTransaction }
  }
}