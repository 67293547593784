import React from 'react'
import { Field, Control } from 'Components/common/bulma'
import classnames from 'classnames'


export default ({
  label,
  name,
  radioValue,
  onChange,
  disabled = false,
  className = 'is-info',
  showErrors,
  errors,
  type = 'checkbox',
  showFieldLabel = true,
  controlClass = '',
  ...props
}) => {
  const classes = classnames('is-checkradio', {}, className);

  return (
    <Field>
      <label className="label">{showFieldLabel && label}</label>
      <Control className={controlClass}>
        <input
          className={classes}
          id={name}
          type={type}
          name={name}
          onChange={onChange}
          disabled={disabled}
          checked={props.value === true}
          {...props}
        />
        <label htmlFor={name}>{label}</label>
      </Control>
    </Field>
  )
}