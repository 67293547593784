import axios from 'axios'
import { getHeaders } from './util'



const url = process.env.REACT_APP_API
export const GET_INVENTORY = 'inventory/GET_INVENTORY'
export const GET_UNIQUE_PRODUCTS = 'inventory/GET_UNIQUE_PRODUCTS'
export const GET_PRODUCT = 'inventory/GET_PRODUCT'
export const REMOVE_INVENTORY = 'inventory/REMOVE_INVENTORY'
export const CREATE_INVENTORY = 'inventory/CREATE_INVENTORY'
export const UPDATE_INVENTORY = 'inventory/UPDATE_INVENTORY'
export const END_ORDER_EDIT = 'inventory/END_ORDER_EDIT'
export const GET_CONCEPTS = 'inventory/GET_CONCEPTS'
export const RESET_CONCEPTS = 'inventory/RESET_CONCEPTS'
export const UDPATE_FILTERS = 'inventory/UDPATE_FILTERS'

const initialState = {
  inventory: {
    data: [],
  },
  uniqueProducts: [],
  product: {},
  concepts: [],
  filters: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UDPATE_FILTERS:
    case GET_CONCEPTS:
    case CREATE_INVENTORY:
    case REMOVE_INVENTORY:
    case GET_PRODUCT:
    case GET_INVENTORY:
    case GET_UNIQUE_PRODUCTS:
    case UPDATE_INVENTORY:
    case RESET_CONCEPTS:
        return { ...state, ...action.payload }
    case END_ORDER_EDIT:
         return initialState
    default:
      return state
  }
}

export const getInventory = params => {
  return dispatch => {
    return axios.post(`${url}/inventory/search`, params, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: GET_INVENTORY,
          payload: { inventory: data }
        })
        return data
      })
  }
}

export const getProduct = id => {
  return dispatch => {
    return axios.get(`${url}/inventory/${id}`, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: GET_PRODUCT,
          payload: { product: data }
        })
      })
  }
}

export const getUniqueProducts= () => {
  return dispatch => {
    return axios.get(`${url}/inventory/getCbo`, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: GET_UNIQUE_PRODUCTS,
          payload: { uniqueProducts: data.map(it => ({ ...it,label: `${it.identificador}-${it.concepto}`})) }
        })
      })
  }
}

export const removeInventory = id => {
  return dispatch => {
    return axios.delete(`${url}/inventory/${id}`, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: REMOVE_INVENTORY,
          payload: { product: data}
        })
        return data
      })
  }
}

export const createInventory = inventory => {
  return dispatch => {
    return axios.post(`${url}/inventory`, inventory, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: CREATE_INVENTORY,
          payload: { product: data}
        })
        return data
      })
  }
}

export const updateInventory = ({id, ...inventory}) => {
  return dispatch => {
    return axios.put(`${url}/inventory/${id}`, inventory, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: UPDATE_INVENTORY,
          payload: { product: data}
        })
        return data
      })
  }
}

export const endOrderEdit = () => ({type: END_ORDER_EDIT})
export const resetConcepts = () => ({type: RESET_CONCEPTS, payload: {concepts: []}})

export const getIncomeConcepts = params => {
  return dispatch => {
    return axios.post(`${url}/inventory/related-orders/entry-order`, params, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: GET_CONCEPTS,
          payload: {  concepts: data}
        })
        return data
      })
  }
}

export const getPaymentConcepts = params => {
  return dispatch => {
    return axios.post(`${url}/inventory/related-orders/pay-order`, params, { headers: getHeaders() })
      .then(({ data }) => {
        dispatch({
          type: GET_CONCEPTS,
          payload: { concepts: data}
        })
        return data
      })
  }
}
 
export const updateFilters =  filters => ({
  type: UDPATE_FILTERS,
  payload: {filters}
})