import axios from 'axios'
import { getHeaders } from 'Modules/util'

const url = process.env.REACT_APP_API

export const CANCEL_ORDER = 'incomeOrders/creditNote/CANCEL_ORDER'
export const CANCEL_ORDER_NO_RELATIONSHIP = 'incomeOrders/creditNote/CANCEL_ORDER_NO_RELATIONSHIP'
export const CANCEL_ORDER_GLOBAL_INVOICE = 'incomeOrders/creditNote/CANCEL_ORDER_GLOBAL_INVOICE'
export const CANCEL_ORDER_TOTAL_AMOUNT = 'incomeOrders/creditNote/CANCEL_ORDER_TOTAL_AMOUNT'
export const CREATE_CREDIT_NOTE = 'incomeOrders/creditNote/CREATE_CREDIT_NOTE'

const initialState = {
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CANCEL_ORDER_TOTAL_AMOUNT:
    case CANCEL_ORDER:
    case CANCEL_ORDER_NO_RELATIONSHIP:
    case CANCEL_ORDER_GLOBAL_INVOICE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export const cancelInvoiceOrderAction = () => {
  const headers = getHeaders()
  return (dispatch, getState) => {
    const { incomeOrders: { invoice } } = getState();
    const { order } = invoice
    return axios.get(`${url}/invoice/${order.id}/options/cancelar_orden_de_ingreso_motivo_03_no_se_llevo_a_cabo_la_operacion`, { headers })
      .then(({ data }) => {
        dispatch({
          type: CANCEL_ORDER,
          payload: {}
        })
      })
  }
}

export const cancelInvoiceOrderNoRelationshipAction = () => {
  const headers = getHeaders()
  return (dispatch, getState) => {
    const { incomeOrders: { invoice } } = getState();
    const { order } = invoice
    return axios.get(`${url}/invoice/${order.id}/options/Cancelar_Orden_de_Ingreso_Motivo_02_Comprobante_emitido_con_errores_sin_relacion`, { headers })
      .then(({ data }) => {
        dispatch({
          type: CANCEL_ORDER_NO_RELATIONSHIP,
          payload: {}
        })
      })
  }
}
export const cancelInvoiceOrderGlobalInvoiceAction = () => {
  const headers = getHeaders()
  return (dispatch, getState) => {
    const { incomeOrders: { invoice } } = getState();
    const { order } = invoice
    return axios.get(`${url}/invoice/${order.id}/options/Cancelar_Orden_de_Ingreso_Motivo_04_Operacion_nominativa_relacionada_en_una_factura_global`, { headers })
      .then(({ data }) => {
        dispatch({
          type: CANCEL_ORDER_GLOBAL_INVOICE,
          payload: {}
        })
      })
  }
}

export const cancelOrderTotalAmountAction = (newOrder) => {
  const headers = getHeaders()
  return (dispatch, getState) => {
    const { incomeOrders: { invoice } } = getState();
    const { order } = invoice
    return axios.post(`${url}invoice/${order.id}/options/Cancelar_Orden_de_Ingreso_Motivo_01_Comprobante_emitido_con_errores_con_relacion`, newOrder, { headers })
      .then(({ data }) => {
        dispatch({
          type: CANCEL_ORDER,
          payload: {}
        })
      })
  }
}
export const cancelOrderPartialAmountAction = (newOrder) => {
  const headers = getHeaders()
  return (dispatch, getState) => {
    const { incomeOrders: { invoice } } = getState();
    const { order } = invoice
    return axios.post(`${url}invoice/${order.id}/options/Cancelar_Orden_de_Ingreso_Monto_Cobrado_Parcial`, newOrder, { headers })
      .then(({ data }) => {
        dispatch({
          type: CANCEL_ORDER,
          payload: {}
        })
      })
  }
}
export const createCreditNoteAction = () => {
  const headers = getHeaders()
  return (dispatch, getState) => {
    const { incomeOrders: { invoice } } = getState();
    const { order } = invoice
    return axios.get(`${url}invoice/${order.id}/options/Generar_Nota_de_Credito_para_cancelar_Monto_por_cobrar`, { headers })
      .then(({ data }) => {
        dispatch({
          type: CREATE_CREDIT_NOTE,
          payload: {}
        })
      })
  }
}
export const createCreditNoteTotalAmountAction = () => {
  const headers = getHeaders()
  return (dispatch, getState) => {
    const { incomeOrders: { invoice } } = getState();
    const { order } = invoice
    return axios.get(`${url}invoice/${order.id}/options/Generar_Nota_de_Credito_por_el_Monto_Total`, { headers })
      .then(({ data }) => {
        dispatch({
          type: CREATE_CREDIT_NOTE,
          payload: {}
        })
      })
  }
}
export const createCreditNotePartialAmountAction = (data) => {
  const headers = getHeaders()
  return (dispatch, getState) => {
    const { incomeOrders: { invoice } } = getState();
    const { order } = invoice
    return axios.post(`${url}invoice/${order.id}/options/Generar_Nota_de_Credito_por_el_Monto_Especificado`,
      { idOrder: order.id, ...data }, { headers })
      .then(({ data }) => {
        dispatch({
          type: CREATE_CREDIT_NOTE,
          payload: {}
        })
      })
  }
}
export const createCreditAndRefundAction = (data) => {
  const headers = getHeaders()
  return (dispatch, getState) => {
    const { incomeOrders: { invoice } } = getState();
    const { order } = invoice
    return axios.post(`${url}invoice/${order.id}/options/Generar_Nota_de_Credito_y_Devolver_Fondos_al_Cliente_monto_cobrado_total`,
      { idOrder: order.id, ...data }, { headers })
      .then(({ data }) => {
        dispatch({
          type: CREATE_CREDIT_NOTE,
          payload: {}
        })
      })
  }
}
export const createCreditAndPartialRefundAction = (data) => {
  const headers = getHeaders()
  return (dispatch, getState) => {
    const { incomeOrders: { invoice } } = getState();
    const { order } = invoice
    return axios.post(`${url}invoice/${order.id}/options/Generar_Nota_de_Credito_y_Devolver_Fondos_al_Cliente_monto_cobrado_parcial`,
      { idOrder: order.id, ...data }, { headers })
      .then(({ data }) => {
        dispatch({
          type: CREATE_CREDIT_NOTE,
          payload: {}
        })
      })
  }
}
