import axios from 'axios'
import { map } from 'ramda'
import { getHeaders } from './util'
import numeral from 'numeral'
const url = process.env.REACT_APP_API


export const GET_CATALOG_WAYTOPAYMENT = 'widget/GET_CATALOG_WAYTOPAYMENT'
export const GET_WIDGET_USER = 'widget/GET_WIDGET_USER'
export const SAVE_WIDGET = 'widget/SAVE_WIDGET'
export const ADD_WIDGET_USER = 'widget/ADD_WIDGET_USER'
export const GET_WIDGET_LIST = 'widget/GET_WIDGET_LIST'
export const DELETE_WIDGET = 'widget/GET_WIDGET_LIST'
export const CLEAN_WIDGET = 'widget/CLEAN_WIDGET'

const initialState = {
  wayToPayment: [],
  widget: {},
  widgetUser: undefined,
  widgetList: {
    data: []
  },
}

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case GET_CATALOG_WAYTOPAYMENT:
    case ADD_WIDGET_USER:
    case SAVE_WIDGET:
    case GET_WIDGET_LIST:
    case CLEAN_WIDGET:
    case GET_WIDGET_USER:
      return { ...state, ...payload }

    default:
      return state
  }
}

export const getWayToPayment = () => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/pagos/catalogos/way-to-payment`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_CATALOG_WAYTOPAYMENT,
          payload: { wayToPayment: data.data }
        })
      })
  }
}

export const saveWidget = data => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/widget`, data, { headers })
      .then(({ data }) => {
        dispatch({
          type: SAVE_WIDGET,
          payload: { widget: data }
        })
      })
  }
}

export const getWidgetUser = data => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/widget/user`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_WIDGET_USER,
          payload: { widgetUser: data }
        })
      })
  }
}

export const addWidgetUser = data => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/widget/user`, data, { headers })
      .then(({ data }) => {
        dispatch({
          type: ADD_WIDGET_USER,
          payload: { widgetUser: data }
        })
      })
  }
}

const mapGenerateOrder = it => {
  return {
    ...it, generateOrder: +it.generate_order_invoice === 0 ? 'No' : 'Si',
    formatedAmount:  `${numeral(it.amount).format('0,0.00')} ${+it.currency === 1 ? 'MXN' : 'USD'}`,
  }
}

export const getWidgetList = params => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/widget/list`, params, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_WIDGET_LIST,
          payload: { widgetList: {...data, data: map(mapGenerateOrder)(data.data)} }
        })
      })
  }
}

export const deleteWidget = id => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/widget/${id}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: DELETE_WIDGET,
        })
        dispatch(getWidgetList())
        return data
      })
  }
}

export const cleanWidget = () => ({type: CLEAN_WIDGET, payload: { widget:{} }})
