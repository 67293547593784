import axios from 'axios'
import { getHeaders } from 'Modules/util'
import { map, reject, propEq } from 'ramda'

const url = process.env.REACT_APP_API

export const GET_LIST = 'paymentOrders/providers/GET_LIST'
export const GET_PENDING_LIST = 'paymentOrders/providers/GET_PENDING_LIST'
export const GET_REJECTED_INVOICE_LIST = 'paymentOrders/providers/GET_REJECTED_INVOICE_LIST'
export const GET_PENDING_INVOICE_LIST = 'paymentOrders/providers/GET_REJECTED_INVOICE_LIST'
export const GET_PENDING_PAYMENT_LIST = 'paymentOrders/providers/GET_PENDING_PAYMENT_LIST'
export const DELETE_ORDER = 'paymentOrders/providers/DELETE_ORDER'
export const CREATE_ORDER = 'paymentOrders/providers/CREATE_ORDER'
export const UPDATE_ORDER = 'paymentOrders/providers/UPDATE_ORDER'
export const UPLOAD_INVOICE = 'paymentOrders/providers/UPLOAD_INVOICE'
export const GET_ORDER = 'paymentOrders/providers/GET_ORDER'
export const GET_INSTALLMENTS = 'paymentOrders/providers/GET_INSTALLMENTS'
export const ADD_INSTALLMENT = 'paymentOrders/providers/ADD_INSTALLMENT'
export const END_ORDER_EDIT = 'paymentOrders/providers/END_ORDER_EDIT'
export const AUTHORIZE_INSTALLMENT = 'paymentOrders/providers/AUTHORIZE_INSTALLMENT'
export const DELETE_INSTALLMENT = 'paymentOrders/providers/DELETE_INSTALLMENT'
export const GET_ATTACHMENTS = 'paymentOrders/providers/GET_ATTACHMENTS'
export const IS_FETCHING_PROVIDERS = 'paymentOrders/providers/IS_FETCHING_PROVIDERS'
export const IS_FETCHING_PROVIDERS_COMPLETE = 'paymentOrders/providers/IS_FETCHING_PROVIDERS_COMPLETE'
export const SET_ORIGIN = 'paymentOrders/SET_ORIGIN'
export const CLEAN_ORIGIN = 'paymentOrders/CLEAN_ORIGIN'

const initialState = {
  list: {
    data: []
  },
  pendingList: {
    data: []
  },
  rejectedInvoiceList: {
    data: []
  },
  pendingInvoiceList: {
    data: []
  },
  pendingPaymentList: {
    data: []
  },
  invoice: {},
  order: {},
  installments: {
    data: []
  },
  attachments: [],
  isFetchingProviders: false,
  origin: 'normal',
}

export default (state = initialState, action) => {
  switch (action.type) {

    case GET_LIST:
    case GET_PENDING_LIST:
    case GET_REJECTED_INVOICE_LIST:
    case GET_PENDING_INVOICE_LIST:
    case GET_PENDING_PAYMENT_LIST:
    case UPLOAD_INVOICE:
    case CREATE_ORDER:
    case GET_ATTACHMENTS:
    case GET_ORDER:
    case SET_ORIGIN:
    case CLEAN_ORIGIN:
      return { ...state, ...action.payload }
    case DELETE_ORDER:
      const { data, ...meta } = state.list
      return {
        ...state, list: {
          data: data.map(it => {
            if (it.id === action.payload) {
              it.estatus = '3'
              it.estatusTexto = 'Cancelada'
            }
            return it
          }), ...meta, last_page: Math.ceil(data.length / meta.per_page)
        }
      }
    case GET_INSTALLMENTS:
      return {
        ...state,
        installments: { data: action.payload },
      }
    case ADD_INSTALLMENT:
      return {
        ...state,
        installments: { data: state.installments.data.concat(action.payload) }
      }
    case END_ORDER_EDIT:
      return initialState
    case AUTHORIZE_INSTALLMENT:
      let mapper = map(it => {
        if (it.id === action.payload.idInstallment) {
          it.estatus = action.payload.data.estatus
          it.estatusTexto = action.payload.data.estatusTexto
        }
        return it
      })

      return {
        ...state,
        installments: { data: mapper(state.installments.data) }
      }
    case DELETE_INSTALLMENT:
      return {
        ...state,
        installments: { data: reject(propEq('id', action.payload))(state.installments.data) }
      }
    case IS_FETCHING_PROVIDERS:
      return { ...state, isFetchingProviders: true }
    case IS_FETCHING_PROVIDERS_COMPLETE:
      return { ...state, isFetchingProviders: false }
    default:
      return state
  }
}

export const isFetchingProviders = () => ({
  type: IS_FETCHING_PROVIDERS
})

export const isFetchingProvidersComplete = () => ({
  type: IS_FETCHING_PROVIDERS_COMPLETE
})


export const getList = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingProviders());
    return axios.post(`${url}/payment-order-provider/search`, params, { headers })
      .then(({ data }) => {
        dispatch(isFetchingProvidersComplete());
        dispatch({
          type: GET_LIST,
          payload: { list: data }
        })
      })
  }
}

export const getPendingList = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingProviders());
    return axios.get(`${url}/payment-order/status/to-authorize`, { headers, params })
      .then(({ data }) => {
        dispatch(isFetchingProvidersComplete());
        dispatch({
          type: GET_PENDING_LIST,
          payload: { pendingList: data }
        })
      })
  }
}

export const getRejectedInvoiceList = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingProviders());
    return axios.get(`${url}/payment-order/status/rejected-invoices`, { headers, params })
      .then(({ data }) => {
        dispatch(isFetchingProvidersComplete());
        dispatch({
          type: GET_REJECTED_INVOICE_LIST,
          payload: { rejectedInvoiceList: data }
        })
      })
  }
}

export const getPendingInvoiceList = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingProviders());
    return axios.get(`${url}/payment-order/status/pending-invoices`, { headers, params })
      .then(({ data }) => {
        dispatch(isFetchingProvidersComplete());
        dispatch({
          type: GET_PENDING_INVOICE_LIST,
          payload: { pendingInvoiceList: data }
        })
      })
  }
}

export const getPendingPaymentList = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingProviders());
    return axios.get(`${url}/payment-order/status/pending-payment`, { headers, params })
      .then(({ data }) => {
        dispatch(isFetchingProvidersComplete());
        dispatch({
          type: GET_PENDING_PAYMENT_LIST,
          payload: { pendingPaymentList: data }
        })
      })
  }
}

export const deleteOrder = (orderId) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/payment-order-provider/${orderId}`, { headers })
      .then(() => {
        dispatch({
          type: DELETE_ORDER,
          payload: orderId,
        })
      })
  }
}
export const createOrder = (order) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingProviders());
    return axios.post(`${url}/payment-order-provider`, order, { headers })
      .then(({ data }) => {
        dispatch(isFetchingProvidersComplete());
        dispatch({
          type: CREATE_ORDER,
          payload: { order: { ...data, flagXML: false, flagPDF: false } },
        })
        return { ...data, flagXML: false, flagPDF: false }
      })
  }
}
export const updateOrder = ({ id, ...order }) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingProviders());
    return axios.post(`${url}/payment-order-provider/${id}`, order, { headers })
      .then(({ data }) => {
        dispatch(isFetchingProvidersComplete());
        dispatch({
          type: UPDATE_ORDER,
          payload: { order: { ...data, flagXML: false, flagPDF: false } },
        })
        return { ...data, flagXML: false, flagPDF: false }
      })
  }
}
export const uploadInvoice = (data) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/storage/upload/`, data, { headers })
      .then(({ data }) => {
        dispatch({
          type: UPLOAD_INVOICE,
          payload: { invoice: data }
        })
        return data
      })
  }
}

export const getOrder = (id) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/payment-order-provider/${id}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_ORDER,
          payload: { order: data }
        })
      })
  }
}

export const getInstallments = (order) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/payment-order-provider/deferred/${order}`, {}, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_INSTALLMENTS,
          payload: data,
        })
      })
  }
}

export const addInstallment = (order, data) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/payment-order-provider/registerPayment/${order}`, data, { headers })
      .then(({ data }) => {
        dispatch({
          type: ADD_INSTALLMENT,
          payload: data,
        })
      })
  }
}

export const endOrderEdit = () => {
  return {
    type: END_ORDER_EDIT
  }
}

export const authorizeInstallment = ({ idOrder, idInstallment, ...data }) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/authorize-payment/${idOrder}/${idInstallment}`, data, { headers })
      .then(({ data }) => {
        dispatch({
          type: AUTHORIZE_INSTALLMENT,
          payload: { order: data, idInstallment, data },
        })
        return data
      })
  }
}

export const deleteInstallment = (idOrder, idInstallment) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/payment-order-provider/deletePayment/${idOrder}/${idInstallment}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: DELETE_INSTALLMENT,
          payload: idInstallment,
        })
      })
  }
}

export const getAttachments = idOrder => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/storage/listContents/${idOrder}/pago`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_ATTACHMENTS,
          payload: { attachments: data }
        })
      })
  }
}

export const setOrigin = (origin) => ({
  type: SET_ORIGIN,
  payload: { origin }
})

export const cleanOrigin = () => ({
  type: CLEAN_ORIGIN,
  payload: { origin: 'normal' }
})
