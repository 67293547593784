import axios from 'axios'
import { getHeaders } from 'Modules/util'
import { map, reject, propEq } from 'ramda'

const url = process.env.REACT_APP_API

export const GET_RANDOM_PROVIDER = 'paymentOrders/salaries/GET_RANDOM_PROVIDER'
export const GET_LIST = 'paymentOrders/salaries/GET_LIST'
export const GET_PROCESING_LIST = 'paymentOrders/salaries/GET_PROCESING_LIST'
export const GET_PAID_LIST = 'paymentOrders/salaries/GET_PAID_LIST'
export const GET_REJECTED_LIST = 'paymentOrders/salaries/GET_REJECTED_LIST'
export const DELETE_ORDER = 'paymentOrders/salaries/DELETE_ORDER'
export const GET_COMISSION = 'paymentOrders/salaries/GET_COMISSION'
export const CREATE_ORDER = 'paymentOrders/salaries/CREATE_ORDER'
export const UPDATE_ORDER = 'paymentOrders/salaries/UPDATE_ORDER'
export const GET_ORDER = 'paymentOrders/salaries/GET_ORDER'
export const ADD_INSTALLMENT = 'paymentOrders/salaries/ADD_INSTALLMENT'
export const DELETE_INSTALLMENT = 'paymentOrders/salaries/DELETE_INSTALLMENT'
export const GET_INSTALLMENTS = 'paymentOrders/salaries/GET_INSTALLMENTS'
export const END_ORDER_EDIT = 'paymentOrders/salaries/END_ORDER_EDIT'
export const AUTHORIZE_INSTALLMENT = 'paymentOrders/salaries/AUTHORIZE_INSTALLMENT'
export const GET_ATTACHMENTS = 'paymentOrders/salaries/GET_ATTACHMENTS'
export const IS_FETCHING_SALARIES = 'paymentOrders/salaries/IS_FETCHING_SALARIES'
export const IS_FETCHING_SALARIES_COMPLETE = 'paymentOrders/salaries/IS_FETCHING_SALARIES_COMPLETE'

const initialState = {
  list: {
    data: []
  },
  procesingList: {
    data: []
  },
  paidList: {
    data: []
  },
  rejectedList: {
    data: []
  },
  randomProvider: undefined,
  comission: {},
  order: {},
  installments: {
    data: []
  },
  attachments: [],
  isFetchingSalaries: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDER:
    case UPDATE_ORDER:
    case GET_COMISSION:
    case CREATE_ORDER:
    case GET_ATTACHMENTS:
    case GET_RANDOM_PROVIDER:
    case GET_LIST:
    case GET_PROCESING_LIST:
    case GET_PAID_LIST:
    case GET_REJECTED_LIST:
      return { ...state, ...action.payload }
    case DELETE_ORDER:
      const { data, ...meta } = state.list
      return { ...state, list: { data: data.filter(it => it.id !== action.payload), ...meta, last_page: Math.ceil(data.length / meta.per_page) } }
    case ADD_INSTALLMENT:
      return {
        ...state,
        installments: { data: state.installments.data.concat(action.payload) }
      }
    case END_ORDER_EDIT:
      return initialState
    case AUTHORIZE_INSTALLMENT:
      let mapper = map(it => {
        if (it.id === action.payload.idInstallment) {
          it.estatus = action.payload.data.estatus
          it.estatusTexto = action.payload.data.estatusTexto
        }
        return it
      })

      return {
        ...state,
        installments: { data: mapper(state.installments.data) }
      }
    case GET_INSTALLMENTS:
      return {
        ...state,
        installments: { data: action.payload },
      }
    case DELETE_INSTALLMENT:
      return {
        ...state,
        installments: { data: reject(propEq('id', action.payload))(state.installments.data) }
      }
    case IS_FETCHING_SALARIES:
      return { ...state, isFetchingSalaries: true }
    case IS_FETCHING_SALARIES_COMPLETE:
      return { ...state, isFetchingSalaries: false }
    default:
      return state
  }
}
export const isFetchingSalaries = () => ({
  type: IS_FETCHING_SALARIES
})

export const isFetchingSalariesComplete = () => ({
  type: IS_FETCHING_SALARIES_COMPLETE
})


export const getList = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingSalaries());
    return axios.post(`${url}/payment-order-ias/search/`, params, { headers })
      .then(({ data }) => {
        dispatch(isFetchingSalariesComplete());
        dispatch({
          type: GET_LIST,
          payload: { list: data }
        })
      })
  }
}

export const getProcesingList = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingSalaries());
    return axios.get(`${url}/payment-order-ias/status/in-process`, { headers, params })
      .then(({ data }) => {
        dispatch(isFetchingSalariesComplete());
        dispatch({
          type: GET_PROCESING_LIST,
          payload: { procesingList: data }
        })
      })
  }
}

export const getPaidList = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingSalaries());
    return axios.get(`${url}/payment-order-ias/status/paid`, { headers, params })
      .then(({ data }) => {
        dispatch(isFetchingSalariesComplete());
        dispatch({
          type: GET_PAID_LIST,
          payload: { paidList: data }
        })
      })
  }
}

export const getRejectedList = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingSalaries());
    return axios.get(`${url}/payment-order-ias/status/rejected`, { headers, params })
      .then(({ data }) => {
        dispatch(isFetchingSalariesComplete());
        dispatch({
          type: GET_REJECTED_LIST,
          payload: { rejectedList: data }
        })
      })
  }
}

export const deleteOrder = (orderId) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/payment-order-ias/${orderId}`, { headers })
      .then(() => {
        dispatch({
          type: DELETE_ORDER,
          payload: orderId,
        })
      })
  }
}

export const getRandomProvider = () => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/payment-order-ias/random-ias-provider/`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_RANDOM_PROVIDER,
          payload: { randomProvider: data },
        })
      })
  }
}

export const getCommission = () => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/commission-ias/`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_COMISSION,
          payload: { comission: data },
        })
      })
  }
}

export const createOrder = (order) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/payment-order-ias`, order, { headers })
      .then(({ data }) => {
        dispatch({
          type: CREATE_ORDER,
          payload: { order: data },
        })
        return data
      })
  }
}
export const getOrder = (id) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/payment-order-ias/${id}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_ORDER,
          payload: { order: data }
        })
      })
  }
}

export const addInstallment = (order, data) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/payment-order-ias/registerPayment/${order}`, data, { headers })
      .then(({ data }) => {
        dispatch({
          type: ADD_INSTALLMENT,
          payload: data,
        })
      })
  }
}

export const updateOrder = ({ id, ...order }) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingSalaries());
    return axios.put(`${url}/payment-order-ias/${id}`, order, { headers })
      .then(({ data }) => {
        dispatch(isFetchingSalariesComplete());
        dispatch({
          type: UPDATE_ORDER,
          payload: { order: data },
        })
        return data
      })
  }
}

export const getInstallments = (order) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/payment-order-provider/deferred/${order}`, {}, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_INSTALLMENTS,
          payload: data,
        })
      })
  }
}

export const endOrderEdit = () => {
  return {
    type: END_ORDER_EDIT
  }
}

export const authorizeInstallment = ({ idOrder, idInstallment, ...data }) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/authorize-payment/${idOrder}/${idInstallment}`, data, { headers })
      .then(({ data }) => {
        dispatch({
          type: AUTHORIZE_INSTALLMENT,
          payload: { order: data, idInstallment, data },
        })
        return data
      })
  }
}

export const deleteInstallment = (idOrder, idInstallment) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/payment-order-ias/deletePayment/${idOrder}/${idInstallment}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: DELETE_INSTALLMENT,
          payload: idInstallment,
        })
      })
  }
}
export const getAttachments = idOrder => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/storage/listContents/${idOrder}/pago`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_ATTACHMENTS,
          payload: { attachments: data }
        })
      })
  }
}