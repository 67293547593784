import { cond, always, T, F, } from 'ramda';
import { isEmpty } from 'App/helpers'

export const fields = {
  id: {
    input: {
      value: '',
      name: 'id',
    }
  },
  beneficiaryName: {
    input: {
      value: '',
      name: 'beneficiaryName',
    },
    label: 'Nombre del Beneficiario',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  beneficiaryAddress: {
    input: {
      value: '',
      name: 'beneficiaryAddress',
    },
    label: 'Dirección del Beneficario',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  swiftCode: {
    input: {
      value: '',
      name: 'swiftCode',
    },
    label: 'Swift Code',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  accountNumber: {
    input: {
      value: '',
      name: 'accountNumber',
    },
    label: 'Número de Cuenta',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  iban: {
    input: {
      value: '',
      name: 'iban',
    },
    label: 'IBAN o VAT ID',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  bankNameAddress: {
    input: {
      value: '',
      name: 'bankNameAddress',
    },
    label: 'Banco y Dirección del Banco.',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  accountStatement: {
    input: {
      value: '',
      name: 'accountStatement',
      accept: 'application/pdf',
    },
    label: 'Encabezado de Estado de Cuenta donde aparezca la cuenta  (Puede tachar información sensible)',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },


};
