import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import { hasPermission } from 'Modules/util'
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

export class Can extends PureComponent {
  render() {
    const { validate = [], children, permission, component = '', partnerPermissions, partnerPermission = '' } = this.props
    const canRender = hasPermission(validate, permission)
    const canRenderPartner = isEmpty(partnerPermission) ? true : !!+partnerPermissions[partnerPermission]

    return (
      <Fragment>
        {(canRenderPartner && canRender) ? children : component}
      </Fragment>
    )
  }
}

const mapStateToProps = ({ principal }) => ({
  permission: principal.permission,
  partnerPermissions: principal.inventoryPermission,
})

const mapDispatchToProps = {
}

Can.propTypes = {
  validate: PropTypes.array.isRequired
}
export default connect(mapStateToProps, mapDispatchToProps)(Can)
