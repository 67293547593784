import axios from 'axios'
import { getHeaders } from 'Modules/util'
import { map, reject, propEq } from 'ramda'

const url = process.env.REACT_APP_API

export const GET_LIST = 'paymentOrders/refund/GET_LIST'
export const DELETE_ORDER = 'paymentOrders/refund/DELETE_ORDER'
export const IS_FETCHING_REFUNDS = 'paymentOrders/refund/IS_FETCHING_REFUNDS'
export const IS_FETCHING_REFUNDS_COMPLETE = 'paymentOrders/refund/IS_FETCHING_REFUNDS_COMPLETE'
export const CREATE_ORDER = 'paymentOrders/refund/CREATE_ORDER'
export const UPDATE_ORDER = 'paymentOrders/refund/UPDATE_ORDER'
export const GET_ORDER = 'paymentOrders/refund/GET_ORDER'
export const END_ORDER_EDIT = 'paymentOrders/refund/END_ORDER_EDIT'
export const ADD_INSTALLMENT = 'paymentOrders/refund/ADD_INSTALLMENT'
export const GET_INSTALLMENTS = 'paymentOrders/refund/GET_INSTALLMENTS'
export const AUTHORIZE_INSTALLMENT = 'paymentOrders/refund/AUTHORIZE_INSTALLMENT'
export const DELETE_INSTALLMENT = 'paymentOrders/refund/DELETE_INSTALLMENT'

const initialState = {
  order: {},
  list: {
    data: []
  },
  isFetchingRefunds: false,
  installments: {
    data: [],
  }
}

export default (state = initialState, action) => {
  switch (action.type) {

  case GET_LIST:
  case CREATE_ORDER:
  case UPDATE_ORDER:
  case GET_ORDER:
    return { ...state, ...action.payload }
  case DELETE_ORDER:
    const { data } = state.list
    return { ...state, list: {data: data.map(it => {
      return it.id === action.payload.id ? {...it, estatus: 'Cancelada'}: it
    })} }
  case IS_FETCHING_REFUNDS:
    return { ...state, isFetchingRefunds: true }
  case IS_FETCHING_REFUNDS_COMPLETE:
    return { ...state, isFetchingRefunds: false }
  case END_ORDER_EDIT: 
    return initialState;
  case GET_INSTALLMENTS:
    return { ...state, ...action.payload }
  case ADD_INSTALLMENT:
    return {
      ...state,
      installments: { data: state.installments.data.concat(action.payload) }
    }
  case DELETE_INSTALLMENT:
    return {
      ...state,
      installments: { data: reject(propEq('id', action.payload))(state.installments.data)}}
  case AUTHORIZE_INSTALLMENT:
    let mapper = map(it => {
      if(it.id === action.payload.idInstallment ){
        it.estatus = action.payload.data.estatus
        it.estatusTexto = action.payload.data.estatusTexto
      }
      return it
    })
    return {
      ...state,
      installments: { data: mapper(state.installments.data)}}
  default:
    return state
  }
}

export const isFetchingRefunds = () => ({
  type: IS_FETCHING_REFUNDS
})

export const isFetchingRefundsComplete = () => ({
  type: IS_FETCHING_REFUNDS_COMPLETE
})

export const getList = (params) => {
    const headers = getHeaders()
    return dispatch => {
      dispatch(isFetchingRefunds())
      return axios.post(`${url}/payment-order-refund/search`, params, { headers })
        .then(({ data }) => {
          dispatch(isFetchingRefundsComplete())
          dispatch({
            type: GET_LIST,
            payload: { list: data }
          })
        })
    }
  }

export const deleteOrder = (orderId) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/payment-order-refund/${orderId}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: DELETE_ORDER,
          payload: data,
        })
        return data
      })
  }
}

export const createOrder = (order) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/payment-order-refund/`, order, { headers })
      .then(({ data }) => {
        dispatch({
          type: CREATE_ORDER,
          payload: { order: data }
        })
        return data
      })
  }
}

export const updateOrder = order => {
  const headers = getHeaders()
  return dispatch => {
    return axios.put(`${url}/payment-order-refund/${order.id}`, order, { headers })
      .then(({ data }) => {
        dispatch({
          type: UPDATE_ORDER,
          payload: { order: data }
        })
        return data
      })
  }
}

export const getOrder = (id) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/payment-order-refund/${id}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_ORDER,
          payload: { order: data }
        })
        return data
      })
  }
}

export const endOrderEdit = () => {
  return {
    type: END_ORDER_EDIT
  }
}

export const getInstallments = order => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/payment-order-refund/deferred/${order}`, {}, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_INSTALLMENTS,
          payload: { installments: { data } },
        })
      })
  }
}

export const authorizeInstallment = ({ idOrder, idInstallment, ...data }) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/authorize-payment/${idOrder}/${idInstallment}`, data, { headers })
      .then(({ data }) => {
        dispatch({
          type: AUTHORIZE_INSTALLMENT,
          payload: { order: data, idInstallment, data },
        })
        return data
      })
  }
}

export const deleteInstallment = (idOrder, idInstallment) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/payment-order-refund/deletePayment/${idOrder}/${idInstallment}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: DELETE_INSTALLMENT,
          payload: idInstallment,
        })
      })
  }
}

export const addInstallment = (order, data) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/payment-order-refund/registerPayment/${order}`, data, { headers })
      .then(({ data }) => {
        dispatch({
          type: ADD_INSTALLMENT,
          payload: data,
        })
      })
  }
}