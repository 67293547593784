import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Header from 'Containers/common/header';
import { AppRoutes } from 'App/routes';
import { I18nProvider } from '@lingui/react';
import { DependencyProvider } from '../DependencyContext';
import services from '../servicesProvider';

function loadLanguage(lan) {
  return require(`@lingui/loader!Language/${lan}/messages.json`);
}

const App = ({ language }) => {
  const catalog = loadLanguage(language.code);
  const catalogs = {
    [language.code]: catalog,
  };

  return (
    <DependencyProvider services={services}>
      <I18nProvider language={language.code} catalogs={catalogs}>
        <Header />
        <div className="app-padding">
          <AppRoutes />
        </div>
      </I18nProvider>
    </DependencyProvider>
  );
};

export default withRouter(connect(state => ({ language: state.i18n.language }))(App));
