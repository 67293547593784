import numeral from 'numeral'
import React, { useState } from 'react'
import { takeLast } from 'ramda'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { Icon, Button } from 'Components/common/bulma'
import { CopyToClipboard } from 'react-copy-to-clipboard';


const RenderWidgetKey = (widgetKey) => {
  const [visible, setVisible] = useState(false)

  const onCopy = () => {
    setVisible(true)

    setTimeout(() => {
      setVisible(false)
    }, 2000)
  }

  return (<div style={{ lineHeight: 1.5 }}>
    <span>...{takeLast(3, widgetKey)}</span>
    {visible && <span className='has-text-danger is-size-7'>¡Llave Copiada!</span>}
    <CopyToClipboard text={widgetKey}
      onCopy={onCopy}
    >
      <Button style={{ float: 'right' }} small className="margin-left-xs" outlined>
        <Icon>
          <FontAwesomeIcon icon={faCopy} />
        </Icon>
      </Button >
    </CopyToClipboard>
  </div>)
}

export const conceptsCol = [
  {
    title: 'Concepto',
    datakey: 'description',
  },
  {
    title: 'Subtotal',
    datakey: 'subtotal',
    format: '$ 0,0.00',
  },
  {
    title: 'IVA',
    datakey: 'iva',
    format: '$ 0,0.00',
  },
  {
    title: 'Total',
    datakey: 'total',
    format: '$ 0,0.00',
  },
  {
    title: 'Acciones',
    custom: 'actions',
    width: '7%',
    align: 'right',
  },
]

export const conceptData = {
  description: {
    name: 'description',
    label: 'Descripción',
    value: '',
    validation: 'required',
  },
  unit: {
    name: 'unit',
    label: 'Unidad',
    value: '',
    validation: 'required',
    placeholder: 'Buscar unidad...',
  },
  unitaryImport: {
    name: 'unitaryImport',
    label: 'Unitario',
    value: '',
    validation: 'required',
    pattern: 'number',
    trigger: 'updateCalculations',
  },
  total: {
    name: 'total',
    label: 'Total',
    value: '',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  iva: {
    name: 'iva',
    label: 'IVA',
    value: '',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  ieps: {
    name: 'ieps',
    label: 'IEPS',
    value: '',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  subtotal: {
    name: 'subtotal',
    label: 'Subtotal',
    value: '',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  quantity: {
    name: 'quantity',
    label: 'Cantidad',
    pattern: 'number',
    value: '',
    validation: 'required',
    trigger: 'updateCalculations',
  },
  export: {
    name: 'export',
    label: 'Exportacion',
    value: '1',
    validation: 'required',
    valueProperty: 'id',
    valueLabel: 'descripcion',
  },
  discount: {
    name: 'discount',
    label: 'Descuento',
    value: '',
    validation: 'required',
    trigger: 'updateCalculations',
  },
  taxObject: {
    name: 'taxObject',
    label: 'Objeto de impuesto',
    value: '2',
    validation: 'required',
    valueProperty: 'id',
    valueLabel: 'descripcion',
    disabled: true,
  },
  customRequestNumber: {
    name: 'customRequestNumber',
    label: 'Pedimento Aduana',
    value: '',
    validation: 'minLength:14',
    pattern: 'number',
    maxLength: 15,
    dataMarshaller: (value = '') => {
      return value.replace(/\b(\d{2})(\d{2})(\d{4})(\d{6,7})\b/, '$1  $2  $3  $4')
    },
  },
  tasaIeps: {
    name: 'tasaIeps',
    label: 'Tasa IEPS',
    value: '',
    // validation: 'required',
    valueProperty: 'id',
    valueLabel: 'tipo',
    trigger: 'updateCalculations',
  },
  tasaIva: {
    name: 'tasaIva',
    label: 'Tasa IVA',
    value: '',
    validation: 'required',
    valueProperty: 'id',
    valueLabel: 'tipo',
    trigger: 'updateCalculations',
  },
  tipoCalculo: {
    name: 'tipoCalculo',
    label: 'Tipo de Cálculo',
    value: 1,
    validation: 'required',
    transform: 'stringToNumber',
    trigger: 'updateCalculations',
    options: [
      {
        value: 1,
        label: 'El IVA Grava sobre Subtotal'
      },
      {
        value: 2,
        label: 'El IVA Grava sobre Subtotal + IEPS'
      },
    ],
  },
  claveProductoServicioSAT: {
    name: 'claveProductoServicioSAT',
    label: 'Clave Producto Servicio',
    value: '',
    validation: 'required',
    valueProperty: 'id',
    valueLabel: 'tipo',
  },
  identifier: {
    name: 'identifier',
    label: 'Identificador',
    maxLength: 11,
    value: '',
  },
  tasaIVA: {
    same: 'tasaIva',
  },
  tasaIEPS: {
    same: 'tasaIeps',
  },
  query: {
    name: 'query',
    label: 'Buscar producto o servicio por concepto o identificador',
    value: '',
  },
  idProductoServicio: {
    name: 'idProductoServicio',
    value: '',
  },
}

export const widgetListColumns = [
  {
    title: 'Descripción',
    datakey: 'description',
    width: '25%',
  },
  {
    title: 'Genera Orden',
    datakey: 'generateOrder',
    width: '10%',
  },
  {
    title: 'Key',
    datakey: 'token',
    width: '10%',
    render: RenderWidgetKey,
  },
  {
    title: 'Total',
    datakey: 'formatedAmount',
    width: '15%',
  },
  {
    title: 'Acciones',
    custom: 'actions',
    width: '10%',
  },
]

export const paymentList = {
  '1': {
    name: 'MIT - Santander',
    subtitle: 'Cobro Visa, MC y AMEX',
    nota: '*Genera comisión adicional',
  },
  '2': {
    name: 'SANTANDER CIE',
    subtitle: 'Cobro efectivo o cheque en sucursal o cajero.',
  },
  '3': {
    name: 'BBVA CIE',
    subtitle: 'Cobro efectivo o cheque en sucursal o cajero.',
  },
  '4': {
    name: 'STP Transferencias SPEI',
    subtitle: 'Desde cualquier banco',
  },
  '5': {
    name: 'BBVA USD',
    subtitle: 'Transferencias internacionales o por SPID en México',
  },
  '8': {
    name: 'Stripe',
    subtitle: 'Cobro con Visa, MC y AMEX',
    nota: '*Genera comisión adicional',
  },
  '9': {
    name: 'Stripe: Subscripciones',
    subtitle: 'Cargos automáticos a tarjetas de crédito y débito.',
    nota: '*Genera comisión adicional',
  },
  '10': {
    name: 'Stripe: Pagos en Oxxo',
    subtitle: 'Tus clientes Pagan en efectivo en tiendas Oxxo.',
    nota: '*Genera comisión adicional',
  },
};