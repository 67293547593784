import { pipe, reject, sortBy, prop, uniqBy } from 'ramda'
import { isFalsy, toNumber } from 'ramda-adjunct';
export default class CountryCatalogService {
  constructor(httpService) {
    this.http = httpService;
  }

  async getCountryCatalog() {
    const { data } = await this.http.get('/paises')
    return pipe(reject(pipe(prop('phone_code'), isFalsy)), uniqBy(prop('phone_code')), sortBy(pipe(prop('phone_code'), toNumber)))(data)
  }
}