import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { default as WidgetList, WidgetForm } from 'Containers/widget'

export default () => (
  <Switch>
    <Route exact path="/widget" component={ WidgetList } />
    <Route exact path="/widget/nuevo" component={ WidgetForm } />
    {/* <Route exact path="/widget/:id" component={TransactionForm} /> */}
  </Switch>
)
