import React from 'react'
import { Link } from 'react-router-dom'
import RocketComponent from './rocket'

const TrialSuccess = ({isLogged}) => {
    return (
        <section className="hero is-large is-default is-bold">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-vcentered">
                        <RocketComponent width="200"/>
                        <div className="column is-offset-1 landing-caption">
                            <h1 className="title is-1 is-bold is-spaced">
                                Usuario creado
                            </h1>
                            <h2 className="subtitle is-5 is-muted has-text-justify">
                            <p>Tu registro fue exitoso y hemos enviado un correo con las intrucciones y credenciales para que puedas accesar al sistema de administración.</p>
<br></br>
<p>Te recordamos que esta es una versión demostrativa que carece de facultad de autorización.</p>
<br></br>

<p>Si requieres de una capacitación en línea vía zoom, escribenos a <a href="mailto:hola@administratunegocio.com">hola@administratunegocio.com</a>  y con gusto te programaremos para una.</p></h2>
                            <Link to="/login" className="button is-danger ">Regresar al inicio</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hero-foot mb-20">
                <div className="container">
                    <div className="tabs is-centered">
                    </div>
                </div>
            </div>
        </section >
    )
}

export default TrialSuccess