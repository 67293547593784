import axios from 'axios'
import { getHeaders } from 'Modules/util'
import { map, reject, propEq } from 'ramda'

const url = process.env.REACT_APP_API

export const GET_LIST = 'paymentOrders/walletGasoline/GET_LIST'
export const GET_PAID_LIST = 'paymentOrders/walletGasoline/GET_PAID_LIST'
export const GET_PROCESING_LIST = 'paymentOrders/walletGasoline/GET_PROCESING_LIST'
export const GET_REJECTED_LIST = 'paymentOrders/walletGasoline/GET_REJECTED_LIST'
export const DELETE_ORDER = 'paymentOrders/walletGasoline/DELETE_ORDER'
export const CREATE_ORDER = 'paymentOrders/walletGasoline/CREATE_ORDER'
export const UPDATE_ORDER = 'paymentOrders/walletGasoline/UPDATE_ORDER'
export const GET_ORDER = 'paymentOrders/walletGasoline/GET_ORDER'
export const ADD_INSTALLMENT = 'paymentOrders/walletGasoline/ADD_INSTALLMENT'
export const GET_INSTALLMENTS = 'paymentOrders/walletGasoline/GET_INSTALLMENTS'
export const AUTHORIZE_INSTALLMENT = 'paymentOrders/walletGasoline/AUTHORIZE_INSTALLMENT'
export const END_ORDER_EDIT = 'paymentOrders/walletGasoline/END_ORDER_EDIT'
export const DELETE_INSTALLMENT = 'paymentOrders/walletGasoline/DELETE_INSTALLMENT'
export const IS_FETCHING_WALLET_GASOLINE = 'paymentOrders/walletGasoline/IS_FETCHING_WALLET_GASOLINE'
export const IS_FETCHING_WALLET_GASOLINE_COMPLETE = 'paymentOrders/walletGasoline/IS_FETCHING_WALLET_GASOLINE_COMPLETE'

const initialState = {
  list: {
    data: [],
  },
  paidList: {
    data: [],
  },
  procesingList: {
    data: [],
  },
  rejectedList: {
    data: [],
  },
  walletGasoline: {},
  installments: {
    data: [],
  },
  isFetchingWalletGasoline: false
}

export default (state = initialState, action) => {
  switch (action.type) {

    case CREATE_ORDER:
    case UPDATE_ORDER:
    case GET_ORDER:
    case GET_LIST:
    case GET_PAID_LIST:
    case GET_PROCESING_LIST:
    case GET_REJECTED_LIST:
    case GET_INSTALLMENTS:
      return { ...state, ...action.payload }
    case ADD_INSTALLMENT:
      return {
        ...state,
        installments: { data: state.installments.data.concat(action.payload) }
      }
    case AUTHORIZE_INSTALLMENT:
      let mapper = map(it => {
        if (it.id === action.payload.idInstallment) {
          it.estatus = action.payload.data.estatus
          it.estatusTexto = action.payload.data.estatusTexto
        }
        return it
      })

      return {
        ...state,
        installments: { data: mapper(state.installments.data) }
      }
    case DELETE_ORDER:
      const { data, ...meta } = state.list
      return {
        ...state, list: {
          data: data.map(it => {
            if (it.id === action.payload.id) {
              return action.payload
            }
            return it
          })
        },
        ...meta,
      }
    case END_ORDER_EDIT:
      return initialState
    case DELETE_INSTALLMENT:
      return {
        ...state,
        installments: { data: reject(propEq('id', action.payload))(state.installments.data) }
      }
    case IS_FETCHING_WALLET_GASOLINE:
      return { ...state, isFetchingWalletGasoline: true }
    case IS_FETCHING_WALLET_GASOLINE_COMPLETE:
      return { ...state, isFetchingWalletGasoline: false }
    default:
      return state
  }
}

export const isFetchingWalletGasoline = () => ({
  type: IS_FETCHING_WALLET_GASOLINE
})

export const isFetchingWalletGasolineComplete = () => ({
  type: IS_FETCHING_WALLET_GASOLINE_COMPLETE
})

export const getList = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingWalletGasoline())
    return axios.post(`${url}/payment-order-gasoline/search`, params, { headers })
      .then(({ data }) => {
        dispatch(isFetchingWalletGasolineComplete())
        dispatch({
          type: GET_LIST,
          payload: { list: data }
        })
      })
  }
}

export const getPaidList = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingWalletGasoline())
    return axios.get(`${url}payment-order-gasoline/status/paid`, { headers, params })
      .then(({ data }) => {
        dispatch(isFetchingWalletGasolineComplete())
        dispatch({
          type: GET_PAID_LIST,
          payload: { paidList: data }
        })
      })
  }
}

export const getProcesingList = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingWalletGasoline())
    return axios.get(`${url}/payment-order-gasoline/status/in-process`, { headers, params })
      .then(({ data }) => {
        dispatch(isFetchingWalletGasolineComplete())
        dispatch({
          type: GET_PROCESING_LIST,
          payload: { procesingList: data }
        })
      })
  }
}

export const getRejectedList = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingWalletGasoline())
    return axios.get(`${url}/payment-order-gasoline/status/rejected`, { headers, params })
      .then(({ data }) => {
        dispatch(isFetchingWalletGasolineComplete())
        dispatch({
          type: GET_REJECTED_LIST,
          payload: { rejectedList: data }
        })
      })
  }
}

export const deleteOrder = (orderId) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/payment-order-gasoline/${orderId}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: DELETE_ORDER,
          payload: data,
        })
        return data
      })
  }
}

export const createOrder = (order) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/payment-order-gasoline/`, order, { headers })
      .then(({ data }) => {
        dispatch({
          type: CREATE_ORDER,
          payload: { walletGasoline: data }
        })
        return data
      })
  }
}

export const updateOrder = order => {
  const headers = getHeaders()
  return dispatch => {
    return axios.put(`${url}/payment-order-gasoline/${order.id}`, order, { headers })
      .then(({ data }) => {
        dispatch({
          type: UPDATE_ORDER,
          payload: { walletGasoline: data }
        })
        return data
      })
  }
}

export const getOrder = (id) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/payment-order-gasoline/${id}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_ORDER,
          payload: { walletGasoline: data }
        })
        return data
      })
  }
}

export const addInstallment = (order, data) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/payment-order-gasoline/registerPayment/${order}`, data, { headers })
      .then(({ data }) => {
        dispatch({
          type: ADD_INSTALLMENT,
          payload: data,
        })
      })
  }
}

export const getInstallments = order => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/payment-order-gasoline/deferred/${order}`, {}, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_INSTALLMENTS,
          payload: { installments: { data } },
        })
      })
  }
}

export const authorizeInstallment = ({ idOrder, idInstallment, ...data }) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/authorize-payment/${idOrder}/${idInstallment}`, data, { headers })
      .then(({ data }) => {
        dispatch({
          type: AUTHORIZE_INSTALLMENT,
          payload: { order: data, idInstallment, data },
        })
        return data
      })
  }
}

export const endOrderEdit = () => {
  return {
    type: END_ORDER_EDIT
  }
}

export const deleteInstallment = (idOrder, idInstallment) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/payment-order-gasoline/deletePayment/${idOrder}/${idInstallment}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: DELETE_INSTALLMENT,
          payload: idInstallment,
        })
      })
  }
}