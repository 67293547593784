import React from 'react'
import { Output, Select } from 'Components/common/form'
import { Columns, Column, Divider } from 'Components/common/bulma'

export default function Totals({
  data,
  providerInvoiceStatus,

}) {
  return (
    <div>
      <Columns>
        <Column>
          <Divider content="RESUMEN DEL PAGO" />
          <Output
            {...data.netoDepositar}
          />
          <Output
            {...data.subtotal}
          />
          <Output
            {...data.IVA}
          />
        </Column>
        <Column>
          <Divider content="RESUMEN MONTOS PAGADOS" />
          <Output
            {...data.montoPagado}
          />
          <Output
            {...data.montoPagoTransito}
          />
          <Output
            {...data.montoPagar}
          />
        </Column>
      </Columns>
      <Columns>
        <Column>
        <Output
            {...data.total}
          />
        </Column>
        <Column>
          <Divider content="UBICACION DE LA FACTURA" />
          <Select
            {...data.estatusFacturaProveedor}
            options={providerInvoiceStatus}
          />
          <Output
            {...data.numeroFactura}
          />
          <Output
            {...data.UUID}
          />
        </Column>
      </Columns>
    </div>
  )
}
