import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { addNotification, getAccountsInfo } from 'Modules/principal'
import { push } from 'connected-react-router'
import { reduce, add, prop, equals } from 'ramda'
import Totals from 'Components/incomeOrders/deposits/Totals'
import { AddressSection } from 'Components/incomeOrders/invoice/AddressSection'
import {
  generalData,
  addressData,
  paymentCol,
  totals,
} from './data'
import {
  getDataInput, mapPropsToState, getValues, validateData,
} from 'App/helpers'
import {
  Box,
  SubTitle,
  Divider,
  Columns,
  Column,
  Button,
  Modal,
  Message,
  Delete,
} from 'Components/common/bulma'
import {
  Select,
  Input,
  Date,
} from 'Components/common/form'
import {
  deleteOrder,
  getOrder,
  createOrder,
  endOrderEdit,
  updateOrder,
  getInstallments,
  authorizeOrder,
} from 'Modules/incomeOrders/deposits'
import { cleanOrigin } from 'Modules/incomeOrders/invoice'
import { ActionBar, Table } from 'Components/common'
import {
  getPaymentEstatus,
  getPaises,
  getEntidades,
  getTipoPersona,
  getPaymentMethod,
  getCurrencies,
  getUsoCfdi,
} from 'Modules/catalogos'
import { Authorize } from 'Containers/auth/'

const AUTHORIZE_PERMISSION = ['Adepósitos']
const STATUS_CREATED = '1'
const STATUS_PARTIAL_AUTHORIZED = '8'
const COLLECTION_ACCOUNT_TYPE_MXN = 'MXN'
const COLLECTION_ACCOUNT_TYPE_USD = 'USD'

export class Form extends Component {
  state = {
    showErrors: false,
    generalData,
    addressData,
    order: {},
    totals,
    installments: {
      data: []
    }
  }
  componentDidMount() {
    const {
      getOrder,
      getTipoPersona,
      getPaymentMethod,
      getCurrencies,
      getPaises,
      getEntidades,
      getPaymentEstatus,
      getInstallments,
      getAccountsInfo,
    } = this.props
    const { id } = this.props.match.params


    getTipoPersona()
    getPaymentMethod()
    getCurrencies()
    getPaises()
    getEntidades()
    getPaymentEstatus()
    getAccountsInfo()

    if (id) {
      getOrder(id)
      getInstallments(id)
    }
  }

  componentWillUnmount() {
    const { endOrderEdit, cleanOrigin } = this.props
    endOrderEdit();
    cleanOrigin();
  }

  componentWillReceiveProps(nextProps) {
    const { order, partnerProfile } = nextProps
    const { order: curOrder, partnerProfile: curPartnerProfile } = this.props
    console.log('curPartnerProfile: ', curPartnerProfile);
    if (curPartnerProfile) {
      this.setState({
        generalData: mapPropsToState(this.state.generalData, {
          ...partnerProfile, nombreORazonSocial: partnerProfile.nombreIntegrado,
          tipoDePersona: partnerProfile.tipoPersona.id
        }),
        addressData: mapPropsToState(this.state.addressData, partnerProfile),
      })
    }

    if (order !== curOrder) {
      if (order.uuidRelacionado) {
        this.props.getRelatedUiid(order.cliente, order.tipoRelacion)
      }
      this.setState({
        generalData: mapPropsToState(this.state.generalData, order),
        addressData: mapPropsToState(this.state.addressData, order),
        totals: mapPropsToState(this.state.totals, order),
        order,
      })
    }
  }

  getPermissions = () => {
    return {
      canAuthorize: (this.state.order.estatusOrden === STATUS_CREATED || this.state.order.estatusOrden === STATUS_PARTIAL_AUTHORIZED),
      canDelete: (this.state.order && this.state.order.id && this.state.order.estatusOrden === STATUS_CREATED),
      canSave: true,
    }
  }

  onChangeInput = ({ target }, section) => {
    const { name } = target
    const data = getDataInput(target, this.state[section])
    const field = data[name]

    this.setState({
      [section]: data,
      showErrors: false,
      showAccountErrors: false,
    }, () => {
      if (field.trigger && this[field.trigger]) {
        this[field.trigger](field)
      }
    })
  }

  createOrder = (event, cb) => {
    const { data: generalData, hasErrors: hasErrorsGeneralData } = validateData(this.state.generalData)
    const { updateOrder, addNotification, createOrder, push } = this.props

    if (hasErrorsGeneralData) {
      this.setState({
        generalData,
        showErrors: true,
      })
      this.props.addNotification({
        type: 'danger',
        message: '¡Favor de validar algunos campos y que tengas conceptos!'
      })
      return
    }

    const payload = {
      id: this.state.order.id,
      ...getValues(this.state.generalData),
    }
    if (this.state.order.id) {
      updateOrder(payload)
        .then(data => {
          addNotification({
            type: 'success',
            message: '¡La orden se actualizó correctamente!'
          })
          const generalData = mapPropsToState(this.state.generalData, data)
          const addressData = mapPropsToState(this.state.addressData, data)

          this.setState({
            order: data,
            generalData,
            addressData,
          })
        })
        .catch(({ response: { data } }) => {
          addNotification({
            type: 'danger',
            message: data.message
          })
        })
    } else {
      createOrder(payload)
        .then(data => {
          addNotification({
            type: 'success',
            message: '¡La orden se creó correctamente!'
          })
          push(`/ordenes-ingreso/depositos/${data.id}`)
          cb && cb()
        })
        .catch(({ response: { data } }) => {
          addNotification({
            type: 'danger',
            message: data.message
          })
        })
    }
  }

  onCancel = () => {
    const { push, origin } = this.props
    const isFromPending = equals('pending')(origin)
    const isFromPendingCollection = equals('pendingCollection')

    if (isFromPending) {
      push('/ordenes-ingreso/pendientes')
      return
    }
    if (isFromPendingCollection(origin)) {
      push('/ordenes-ingreso/pendientes-cobro')
      return
    }
    push('/ordenes-ingreso/depositos')
  }

  onRemove = () => {

  }

  updateTotals = () => {
    const totals = reduce((acc, cur) => {
      return {
        total: add(prop('total')(acc), prop('total')(cur)),
        montoPorCobrar: add(prop('montoPorCobrar')(acc), prop('total')(cur)),
        subtotal: add(prop('subtotal')(acc), prop('subtotal')(cur)),
        iva: add(prop('iva')(acc), prop('iva')(cur)),
        ieps: add(prop('ieps')(acc), prop('ieps')(cur)),
      }
    },
      {
        total: 0,
        subtotal: 0,
        iva: 0,
        ieps: 0,
        montoPorCobrar: 0,
      }
    )(this.state.conceptos.data)
    this.setState(({ totals: totalsState }) => ({ totals: mapPropsToState(totalsState, totals) }))
  }

  onAuthorize = () => {
    this.setState({
      showAuth: true,
    })
  }

  cancelAuthorize = () => {
    this.setState({
      showAuth: false,
    })
  }

  authorizeOrder = (data) => {
    return this.props.authorizeOrder(
      this.state.order.id,
      data
    ).then(data => {
      this.props.addNotification({
        type: 'success',
        message: 'La órden se autorizó correctamente'
      })
      this.setState({
        showAuth: false,
        generalData: mapPropsToState(this.state.generalData, data)
      })
    })
  }

  removeOrder = () => {
    this.props.deleteOrder(this.state.order.id)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'La órden de ingreso se eliminó correctamente'
        })
        this.props.push('/ordenes-ingreso/depositos')
      })
      .catch(({ response: { data } }) => {
        this.props.addNotification({
          type: 'danger',
          message: data.message
        })
      })
  }

  openConfirmRemoveOrder = () => this.setState({
    isRemovingOrder: true,
  })

  closeConfirmRemoveOrder = () => this.setState({
    isRemovingOrder: false
  })

  render() {
    const {
      paises,
      entidades,
      paymentEstatus,
      tipoPersona,
      installments,
      accountsInfo,
      order,
      partnerProfile,
    } = this.props
    const { showErrors, generalData } = this.state
    return (
      <Box>
        <Modal isActive={this.state.isRemovingOrder}>
          <Message danger>
            <Message.Header>
              <p>Eliminar Orden de ingreso</p>
              <Delete onClick={this.closeConfirmRemoveOrder} />
            </Message.Header>
            <Message.Body className="has-text-centered">
              ¿Esta seguro de querer eliminar la orden de ingreso?
              <div className="margin-top-lg">
                <Button danger onClick={this.removeOrder}>Eliminar</Button>
                <Button default className="margin-left-sm" onClick={this.closeConfirmRemoveOrder}>
                  Cancelar
              </Button>
              </div>
            </Message.Body>
          </Message>
        </Modal>
        {this.state.showAuth && <Authorize
          isOpen={this.state.showAuth}
          authorize={this.authorizeOrder}
          cancel={this.cancelAuthorize}
        />}
        <SubTitle className="has-text-centered" is='3'>Depósitos</SubTitle>
        <ActionBar
          permissions={this.getPermissions()}
          onSave={this.createOrder}
          onCancel={this.onCancel}
          onDelete={this.openConfirmRemoveOrder}
          onAuthorize={this.onAuthorize}
          basicRole={['Odepósitos']}
          authorizeRole={AUTHORIZE_PERMISSION}
        />
        <Divider content="DATOS GENERALES" />
        <Columns>
          <Column className="is-6">
            <Select
              onChange={e => this.onChangeInput(e, 'generalData')}
              options={paymentEstatus}
              showErrors={showErrors}
              {...generalData.estatusOrden}
            />
          </Column>
        </Columns>
        <Columns>
          <Column className="is-6">
            <Date
              {...generalData.fechaProgramacionEmisionFactura}
              onChange={e => this.onChangeInput(e, 'generalData')}
            />
          </Column>
          <Column className="is-6">
          </Column>
        </Columns>
        <Divider content="GENERALES DE LA FACTURA" />
        <Columns>
          <Column className="is-6">
            <Select
              onChange={e => this.onChangeInput(e, 'generalData')}
              options={tipoPersona}
              showErrors={showErrors}
              valueProperty="id"
              valueLabel="tipoPersona"
              {...generalData.tipoDePersona}
            />
          </Column>
          <Column className="is-6">
            <Input
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.nombreORazonSocial}
            />
          </Column>
        </Columns>
        <Columns>
          <Column className="is-6">
            <Input
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.rfc}
            />
          </Column>
          <Column className="is-6">
            <Input
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.correoElectronico}
            />
          </Column>
        </Columns>
        <AddressSection
          data={this.state.addressData}
          onChange={e => this.onChangeInput(e, 'addressData')}
          paises={paises}
          entidades={entidades}
          extranjero={false}
          showErrors={this.state.showErrors}
        />
        <Columns>
          <Column className="is-6">
            <Input
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.netoDepositar}
            />
          </Column>
          <Column className="is-6">
            <Select
              onChange={e => this.onChangeInput(e, 'generalData')}
              showErrors={showErrors}
              {...generalData.cuentaCobranza}
              tooltip="En qué cuenta quiere recibir el cobro de la factura, se sugiere que si la Moneda Divisa de la Factura es MXN la cuenta de cobranza sea MXN, si la Moneda Divisa es otra la cuenta de cobranza sea USD."
            />
          </Column>
        </Columns>
        <Divider content="PAGOS" />
        <Table
          colsetup={paymentCol}
          coldata={installments}
          tableClassName='table is-striped is-hoverable is-fullwidth'
          emptyTableMarkUp={(<span>Aun no hay pagos</span>)}
        />
        <Totals
          data={this.state.totals}
        />
        <Columns>
          {generalData.cuentaCobranza.value === COLLECTION_ACCOUNT_TYPE_MXN && <Column>
            <SubTitle className="is-4">Para depósitos vía transferencia Electrónica:</SubTitle>
            <p><strong>Beneficiario:</strong> {accountsInfo.beneficiario}</p>
            <p><strong>Banco:</strong> STP (Sistema de Transferencias y Pagos)</p>
            <p><strong>Clabe: </strong>{partnerProfile.cuentaClabeStp} </p>
            <p><strong>Referencia: </strong>{order.clavePagoReferencia}</p>
            <p>&nbsp;</p>
            <SubTitle className="is-4">Para Depósitos en efectivo o Cheque:</SubTitle>
            <p><strong>Beneficiario:</strong> {accountsInfo.beneficiario}</p>
            <p>{accountsInfo.convenioCIE}</p>
            <p><strong>Referencia: </strong>{order.clavePagoReferencia}</p>
          </Column>}
          {generalData.cuentaCobranza.value === COLLECTION_ACCOUNT_TYPE_USD &&
            <Column>
              <SubTitle className="is-4">Para Transferencias Internacionales:</SubTitle>
              <SubTitle className="is-5">Transferencias Directas:</SubTitle>
              {accountsInfo.directo && accountsInfo.directo.split('\n').map(it => <p>{it}</p>)}
              <p>&nbsp;</p>
              <SubTitle className="is-5">Transferencias con Banco Intermediario:</SubTitle>
              {accountsInfo.intermediario && accountsInfo.intermediario.split('\n').map(it => <p>{it}</p>)}
            </Column>
          }
        </Columns>
      </Box >
    )
  }
}

const mapStateToProps = ({ catalogs, catalogos, incomeOrders, principal }) => ({
  clients: catalogs.clients.clients,
  client: catalogs.clients.client,
  paises: catalogos.paises,
  entidades: catalogos.entidades,
  paymentEstatus: catalogos.paymentEstatus,
  tipoPersona: catalogos.tipoPersona,
  paymentMethod: catalogos.paymentMethod,
  order: incomeOrders.deposits.order,
  currencies: catalogos.currencies,
  usoCfdi: catalogos.usoCfdi,
  invoiceUnits: catalogos.invoiceUnits,
  productService: catalogos.productService,
  ivaRates: catalogos.ivaRates,
  iepsRates: catalogos.iepsRates,
  tipoRelacion: catalogos.tipoRelacion,
  accountsInfo: principal.accountsInfo,
  productSat: catalogos.productSat,
  partnerProfile: { ...principal.profile, ...principal.integrated },
  installments: incomeOrders.deposits.installments,
  isFetching: incomeOrders.deposits.isFetchingDeposits,
  origin: incomeOrders.invoice.origin,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getOrder,
  getEntidades,
  getPaises,
  getPaymentEstatus,
  addNotification,
  getTipoPersona,
  getPaymentMethod,
  createOrder,
  getCurrencies,
  getUsoCfdi,
  push,
  endOrderEdit,
  getInstallments,
  authorizeOrder,
  updateOrder,
  deleteOrder,
  getAccountsInfo,
  cleanOrigin,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Form)