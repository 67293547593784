import React, { Component } from 'react'
import { Box } from 'Components/common/bulma'
import { connect } from 'react-redux'
import MenuComponent from 'Components/menu'
import { has, filter, startsWith, pipe, pathOr, equals } from 'ramda'
import { toNumber } from 'ramda-adjunct'
import { Link } from 'react-router-dom'


export class Menu extends Component {
  render() {
    const isShowingRegister = startsWith('/onboarding')(this.props.location.pathname)
    const canShowRegisterButton = this.props.isRegisterIncomplete && !isShowingRegister
    return (
      <Box className="is-hidden-mobile">
        <MenuComponent items={this.props.menu}>
          {canShowRegisterButton && (<li><Link className="button is-danger" as="a" to="/onboarding">Completar Registro</Link></li>)}
        </MenuComponent>
      </Box>
    )
  }
}

const mapStateToProps = ({ principal }) => {

  const { inventoryPermission } = principal
  const isRegisterComplete = pipe(pathOr(undefined, ['integrated', 'estatusValidado']), toNumber, equals(0))
  const getMenu = (menu) => {
    return filter(it => {
      if (has('partnerPermission', it)) {
        if (!!+inventoryPermission[it.partnerPermission]) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    })(menu);
  }

  return ({
    menu: getMenu(principal.menu),
    isRegisterIncomplete: isRegisterComplete(principal),
  })

}

export default connect(mapStateToProps, undefined)(Menu)
