import React from 'react'
import { Columns, Column } from 'Components/common/bulma'
import { Input } from 'Components/common/form'

const School = ({ data, onChange, showErrors }) => {
  return (
    <section>
      <Columns className="is-multiline">
        <Column className="is-half">
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...data.nombreAlumno}
          />
        </Column>
        <Column className="is-half">
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...data.curp}
          />
        </Column>
        <Column className="is-half">
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...data.nivelEducativo}
          />
        </Column>
        <Column className="is-half">
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...data.autRVOE}
          />
        </Column>
      </Columns>
    </section>
  );
};

export default School