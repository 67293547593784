import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import refreshToken from './middlewares/refreshToken'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import rootReducer from './modules'

export const history = createBrowserHistory();

const initialState = {};
const enhancers = [];
const middleware = [refreshToken, thunk, routerMiddleware(history), ];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

export default createStore(rootReducer(history), initialState, composedEnhancers);
