import moment from 'moment'

export const advancePaymentList =
  [
    {
      title: 'Serie y Folio Comprobante',
      datakey: 'serieFolio',
      sortable:  true,
      width: '10%',
    },
    {
      title: 'Fecha',
      datakey: 'fecha',
      format: value => moment(value).format('DD/MM/YYYY'),
      sortable:  true,
      width: '10%',
    },
    {
      title: 'RFC',
      datakey: 'rfc',
      sortable:  true,
      width: '10%',
    },
    {
      title: 'Nombre o Razón Social',
      datakey: 'nombre',
      sortable:  true,
      width: '10%',
    },
    {
      title: 'Subtotal',
      datakey: 'subtotal',
      format: '$ 0,0.00',
      sortable:  true,
      width: '10%',
    },
    {
      title: 'Impuestos Trasladados',
      datakey: 'impuestosTrasladados',
      format: '$ 0,0.00',
      sortable:  true,
      width: '10%',
    },
    {
      title: 'Total',
      datakey: 'total',
      format: '$ 0,0.00',
      sortable:  true,
      width: '10%',
    },
    {
      title: 'UUID',
      datakey: 'uuid',
      sortable:  true,
      width: '10%',
    },
    {
      title: 'Acciones',
      custom: 'actions',
      width: '10%',
    },
  ]
