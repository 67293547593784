import React from 'react'
import { Columns, Column } from 'Components/common/bulma'
import { Input } from 'Components/common/form'


const ContactoSection = ({
  data,
  onChange,
  showErrors,
 }) => {
  return (
    <section>
      <Columns>
        <Column>
          <Input
            onChange={onChange}
            {...data.telefono}
            showErrors={showErrors}
            maxLength="10"
          />
          <Input
            type="tags"
            {...data.correoElectronico}
            showErrors={showErrors}
          />
        </Column>
        <Column>
          <Input
            onChange={onChange}
            {...data.extension}
            showErrors={showErrors}
          />
          <Input
            onChange={onChange}
            {...data.identificador}
            showErrors={showErrors}
          />
        </Column>
      </Columns>
    </section>
  );
};

export default ContactoSection;