import { cond, always, T, F, } from 'ramda';
import { isEmpty, isMinLength } from 'App/helpers'

export const fields = {
  numregIdTrib: {
    input: {
      value: '',
      name: 'numregIdTrib',
    },
    label: 'Tax Id',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [isMinLength(3), always({ error: 'La longitud mínima es de 3 caracteres' })],
      [T, F]
    ]),
  },
  taxRegime: {
    input: {
      value: '12',
      name: 'taxRegime',
      disabled: true,
    },
    label: 'Regimen Fiscal',
    valueLabel: 'descripcionCif',
    valueProperty: 'id',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
    unmarshaller: val => {
      return val ? val : '12'
    }
  },
};

export const addressDefinition = {
  country: {
    input: {
      value: '141',
      name: 'country',
    },
    label: 'País',
    valueProperty: 'id',
    valueLabel: 'nombre',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
    unmarshaller: val => {
      return val ? val : '141'
    },
  },
  zipCode: {
    input: {
      value: '',
      name: 'zipCode',
      maxLength: '5',
    },
    label: 'Código Postal',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  state: {
    input: {
      value: '',
      name: 'state',
    },
    label: 'Entidad Federativa',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  municipality: {
    input: {
      value: '',
      name: 'municipality',
    },
    label: 'Municipio',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  neighborhood: {
    input: {
      value: '',
      name: 'neighborhood',
    },
    label: 'Colonia',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  street: {
    input: {
      value: '',
      name: 'street',
    },
    label: 'Calle',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  interiorNumber: {
    input: {
      value: '',
      name: 'interiorNumber',
    },
    label: 'Número interior',
  },
  number: {
    input: {
      value: '',
      name: 'number',
    },
    label: 'Número exterior',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
}