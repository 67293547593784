import React from 'react';
import { Box, Columns, Column, Button, Divider } from 'Components/common/bulma'
import File from 'Components/common/form/FileWithErrors'
import InputWithError from 'Components/common/form/InputWithError'
import DateWithError from 'Components/common/form/DateWithError'
import useLegalEntity from './useLegalEntity';
import Switch from 'Components/common/form/Switch'
import Loader from 'Components/common/page/loaderFloat';
import Show from 'Components/common/Show';

const LegalEntityInfo = ({ refresh }) => {
  const { values, showErrors, onChangeField, saveLegalEntity, isLoading } = useLegalEntity({ refresh });
  return (
    <Box style={{ position: 'relative' }}>
      <Show canShow={isLoading}>
        <Loader />
      </Show>
      <Divider content="Datos del Acta Constitutiva" />
      <Columns className="is-multiline">
        <Column className="is-half">
          <InputWithError
            onChange={onChangeField}
            showErrors={showErrors}
            {...values.certificateNumber}
          />
        </Column>
        <Column className="is-half">
          <DateWithError
            onChange={onChangeField}
            showErrors={showErrors}
            {...values.certificateDate}
          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onChangeField}
            showErrors={showErrors}
            {...values.certificateNotaryName}
          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onChangeField}
            showErrors={showErrors}
            {...values.certificateNotaryNumber}
          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onChangeField}
            showErrors={showErrors}
            {...values.certificateNotaryState}
          />
        </Column>
      </Columns>
      <Divider content="Datos del Registro Público de la Propiedad o Comercio" />
      <Columns className="is-multiline">
        <Column className="is-half">
          <Switch
            onChange={onChangeField}
            showErrors={showErrors}
            {...values.publicRegistryProcess}
          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onChangeField}
            showErrors={showErrors}
            {...{ ...values.publicRegistryNumber, disabled: values.publicRegistryProcess.input.value }}
          />
        </Column>
        <Column className="is-half">
          <DateWithError
            onChange={onChangeField}
            showErrors={showErrors}
            {...{ ...values.publicRegistryDate, disabled: values.publicRegistryProcess.input.value }}
          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onChangeField}
            showErrors={showErrors}
            {...{ ...values.publicRegistryState, disabled: values.publicRegistryProcess.input.value }}
          />
        </Column>
      </Columns>
      <Divider content="Estructura Accionaria" />
      <Columns className="is-multiline">
        <Column className="is-half">
          <InputWithError
            onChange={onChangeField}
            showErrors={showErrors}
            {...values.shareholderFirst}
          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onChangeField}
            showErrors={showErrors}
            {...values.shareholderSecond}
          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onChangeField}
            showErrors={showErrors}
            {...values.capital}
          />
        </Column>
        <Column className="is-half">
          <InputWithError
            onChange={onChangeField}
            showErrors={showErrors}
            {...values.employeeNumber}
          />
        </Column>
      </Columns>
      <Divider content="Carga de documentos" />
      <Columns className="is-multiline">
        <Column className="is-half">
          <File
            onChange={onChangeField}
            showErrors={showErrors}
            {...values.taxSituation}

          />
        </Column>
        <Column className="is-half">
          <File
            onChange={onChangeField}
            showErrors={showErrors}
            {...values.addressProof}

          />
        </Column>
        <Column className="is-half">
          <File
            onChange={onChangeField}
            showErrors={showErrors}
            {...values.constitutiveAct}

          />
        </Column>
        <Column className="is-half">
          <File
            onChange={onChangeField}
            showErrors={showErrors}
            {...values.publicRegistry}

          />
        </Column>
        <Column className="is-full">
          <Button className="is-primary"
            onClick={saveLegalEntity}
          >Guardar</Button>
        </Column>
      </Columns>

    </Box>
  );
};

export default LegalEntityInfo;
