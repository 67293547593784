import React from 'react'

const Error404 = () => {
    return (
        <section className="hero is-white">
            <div className="hero-body">
                <div className="has-text-centered">
                    <h1 className="title is-1 has-text-primary text-is-xl">404</h1>
                    <h2 className="subtitle">Sección no encontrada o no tiene privilegios</h2>
                </div>
            </div>
        </section>
    )
}

export default Error404