import React, { useState } from 'react'
import { Input } from 'Components/common/form'
import { Button, Modal, Message, Delete, Icon } from 'Components/common/bulma'
import { Table } from 'Components/common'
import Concept from 'Components/widget/Concept'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  getExportationAction,
  getTaxObjectAction,
} from 'Modules/catalogos'
import { useMount } from 'ahooks'

function Product({
  generateOrder,
  conceptsCol,
  widgetConfiguration: {
    amount,
    description,
  },
  getInvoiceUnits,
  conceptData,
  ivaRates,
  iepsRates,
  units,
  clearUnits,
  productService,
  productSat,
  getProductService,
  clearProductService,
  getProduct,
  product,
  getProductSat,
  setWidgetConfiguration,
  addConcept,
  concepts,
  addNotification,
  oneConcept = false,
  selectedPaymentWays,
  onRemoveConcept,
  getExportation,
  exportation,
  getTaxObject,
  taxObject,
}) {
  const [isAddingConcept, setIsAddingConcept] = useState(false)

  useMount(() => {
    getExportation()
    getTaxObject()
  })
  const closeConceptModal = () => setIsAddingConcept(false)

  const handleAddConcept = concept => {
    addConcept(concept)
    setIsAddingConcept(false)
  }

  const rowActions = ({ id }) => (
    <Button
      small
      className="margin-left-xs" danger outlined onClick={() => onRemoveConcept(id)}>
      <Icon>
        <FontAwesomeIcon icon={faTrash} />
      </Icon>
    </Button>
  )

  return (
    <div style={{ minHeight: '540px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
      {!!+generateOrder || <div style={{ width: '80%' }}>
        <h5>Detalles del producto</h5>
        <Input
          id="description"
          label="Descripción:"
          name="description"
          value={description}
          onChange={setWidgetConfiguration}
        />
        <Input
          id="amount"
          label="Monto:"
          name="amount"
          value={amount}
          onChange={setWidgetConfiguration}
          showErrors={!!selectedPaymentWays['10'] && +amount > 10000}
          errors={[{
            valid: !!selectedPaymentWays['10'] && +amount > 10000,
            message: 'El monto para pagos via Oxxo no debe superar los $ 10,000.00 MXN',
          }]}
        />
      </div>}
      {
        !!+generateOrder && <div style={{ width: '80%' }}>
          <Button
            primary
            onClick={() => setIsAddingConcept(true)}
            disabled={(oneConcept && concepts.length === 1) || (!!selectedPaymentWays['10'] && +amount >= 10000)}
          >
            Agregar concepto
          </Button>
          {!!selectedPaymentWays['10'] && +amount > 10000 && <p className="has-text-danger help">El monto para pagos via Oxxo no debe superar los $ 10,000.00 MXN</p>}
          <Table
            initialFetch={false}
            colsetup={conceptsCol}
            coldata={{ data: concepts }}
            tableClassName='table is-striped is-hoverable is-fullwidth'
            emptyTableMarkUp={(<span>Aun no hay conceptos</span>)}
            customCol={{
              customColName: 'actions',
              renderFunc: rowActions,
            }}
          />
          <Modal isActive={isAddingConcept}>
            <Message default>
              <Message.Header>
                <p>Agregar concepto</p>
                <Delete onClick={closeConceptModal} />
              </Message.Header>
              <Message.Body>
                {isAddingConcept && <Concept
                  getUnits={getInvoiceUnits}
                  units={units}
                  ivaRates={ivaRates}
                  iepsRates={iepsRates}
                  addNotification={addNotification}
                  addConcept={handleAddConcept}
                  closeConceptModal={closeConceptModal}
                  clearUnits={clearUnits}
                  getProductService={getProductService}
                  clearProductService={clearProductService}
                  productService={productService}
                  getProduct={getProduct}
                  product={product}
                  productSat={productSat}
                  getProductSat={getProductSat}
                  conceptData={conceptData}
                  exportation={exportation}
                  taxObject={taxObject}
                />}
              </Message.Body>
            </Message>
          </Modal>
        </div>
      }
    </div>
  )
}

const mapStateToProps = ({ catalogos }: Props) => ({
  exportation: catalogos.exportation,
  taxObject: catalogos.taxObject,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getExportation: getExportationAction,
  getTaxObject: getTaxObjectAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Product)
