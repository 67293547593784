import React from 'react'
import {
  Container,
  Button,
  Hero,
  Input,
  Control,
  Field,
  Box,
  Column,
  Columns,
  Notification,
  Delete,
} from 'Components/common/bulma'
import LogoNegro from '../../styles/images/logoNegro.png'

const Login = ({ data = {}, onChangeInput, getRecoveryToken, loginError, showErrors,
  hideLoginError,
  submitting,
}) => {
  return (
    <Hero medium>
      <Hero.Body>
        <Container className="has-text-centered">
          <Column className="is-4 is-offset-4">
            <Box>
              <h6 className="subtitle">Recuperación de contraseña</h6>
              <form>
                <Field>
                  <Control>
                    <label>No. de Tarjeta:</label>
                    <Input
                      name="tarjeta"
                      value={data.tarjeta.value}
                      onChange={onChangeInput}
                      maxLength="16"
                      type="text"
                      placeholder="Tarjeta"
                      autoFocus=""
                      disabled={submitting}
                    />
                    {(!data.tarjeta.valid && showErrors) && (
                      <p className="help is-danger">
                        {data.tarjeta.errors[0].message}
                      </p>
                    )}
                  </Control>
                </Field>
                {loginError && (
                    <Notification danger>
                    <Delete onClick={hideLoginError}/>
                    Tu usuario no es válido.
                  </Notification>
                )}
                <Button
                  type="button"
                  onClick={getRecoveryToken}
                  disabled={submitting}
                  loading={submitting}
                  className="is-block is-black is-fullwidth">
                  Recuperar contraseña
                </Button>
              </form>
              <Columns className="is-mobile">
                <Column className="margin-h-md">
                  <img alt="Logotipo maneja tu negocio" src={LogoNegro} style={{height: '50px'}} />
                </Column>
              </Columns>
              <h6 className="has-text-danger margin-h-md">Integradora Labor y Transporte, S.A de C.V</h6>
            </Box>
          </Column>
        </Container>
      </Hero.Body>
    </Hero>
  )
}

export default Login
