import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ConciliateForm, default as ConciliationList, TransactionForm } from 'Containers/conciliations'

export default () => (
  <Switch>
    <Route exact path="/conciliaciones" component={ConciliationList} />
    <Route exact path="/conciliaciones/conciliar/:id/:idAgregator" component={ConciliateForm} />
    <Route exact path="/conciliaciones/:id" component={TransactionForm} />
  </Switch>
)
