import { cond, always, T, F, or, converge } from 'ramda';
import { isEmpty, isInvalidClabe } from 'App/helpers'
import { isNumeric } from 'ramda-extension'

export const fields = {
  id: {
    input: {
      value: '',
      name: 'id',
      maxLength: 18,
    },
    label: 'Id',
  },
  clabe: {
    input: {
      value: '',
      name: 'clabe',
      maxLength: 18,
    },
    label: 'Cuenta Clabe',
    pattern: converge(or, [isNumeric, isEmpty]),
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [isInvalidClabe, always({ error: 'La Clabe interbancaria es incorrecta' })],
      [T, F]
    ]),
  },
  numeroSucursal: {
    input: {
      value: '',
      name: 'numeroSucursal',
    },
    label: 'Número de sucursal',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
    pattern: converge(or, [isNumeric, isEmpty])
  },
  accountStatement: {
    input: {
      value: '',
      name: 'accountStatement',
      accept: 'application/pdf',
    },
    label: 'Encabezado de Estado de Cuenta donde aparezca la cuenta clabe (Puede tachar información sensible)',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
};
