import React from 'react'
import { Columns, Column } from 'Components/common/bulma'
import { Input, Select } from 'Components/common/form'

const GeneralSection = ({data, onChange, showErrors, estatusPersona, tipoPersona}) => {
  return (
    <section>
      <Columns>
        <Column>
          <Select
            onChange={onChange}
            options={[
              {value: 'Nacional (MX)', label: 'Nacional (MX)'},
              {value: 'Extranjero', label: 'Extranjero'},
            ]}
            showErrors={showErrors}
            {...data.prestamistaNacionalOExtranjeros}
          />
          <Input
            onChange={onChange}
            className="is-uppercase"
            showErrors={showErrors}
            {...data.rfc}
          />
        </Column>
        <Column>
        <Select
            onChange={onChange}
            options={estatusPersona}
            showErrors={showErrors}
            valueProperty="id"
            valueLabel="estatus"
            {...data.estatus}
          />
        <Select
            onChange={onChange}
            options={tipoPersona}
            showErrors={showErrors}
            valueProperty="id"
            valueLabel="tipoPersona"
            {...data.tipoDePersona}
          />
          <Input
            onChange={onChange}
            showErrors={showErrors}
            {...data.nombreORazonSocial}
          />
        </Column>
      </Columns>
    </section>
  );
};

export default GeneralSection;