import classnames from 'classnames'
import React from 'react'

export const Nav = ({isDanger, isWhite, isLight, isTransparent, ...props}) => {
    const classes = classnames('navbar', {
      'is-danger': isDanger,
      'is-white': isWhite,
      'is-light': isLight,
      'is-transparent': isTransparent,
    })
    return <nav className={classes} {...props}/>
}