import axios from "axios"
import { getHeaders } from '../util'

const url = process.env.REACT_APP_API

export const GET_CLIENTS = 'clients/GET_CLIENTS'
export const GET_CLIENT = 'clients/GET_CLIENT'
export const CREATE_CLIENT = 'clients/CREATE_CLIENT'
export const UPDATE_CLIENT = 'clients/UPDATE_CLIENT'
export const DELETE_CLIENT = 'clients/DELETE_CLIENT'
export const END_EDIT_CLIENT = 'clients/END_EDIT_CLIENT'
export const AUTHORIZE_CLIENT = 'clients/AUTHORIZE_CLIENT'
export const IS_FETCHING_CLIENT = 'clients/IS_FETCHING_CLIENT'
export const IS_FETCHING_CLIENT_COMPLETE = 'clients/IS_FETCHING_CLIENT_COMPLETE'
export const GET_STUDENTS = 'clients/GET_STUDENTS'
export const RESET_STUDENTS = 'clients/RESET_STUDENTS'

const initialState = {
  clients: {
    data: []
  },
  client: {},
  isAllow: false,
  isFetchingClient: false,
  students: { data: []},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CLIENT:
    case GET_CLIENTS:
    case GET_CLIENT:
    case GET_STUDENTS:
      return { ...state, ...action.payload }
    case RESET_STUDENTS:
      return { ...state, students: { data: []} }
    case END_EDIT_CLIENT:
      return { ...state, client: {} }
    case DELETE_CLIENT:
      const {data, ...meta} = state.clients
      return { ...state, clients: {data: data.filter(it => it.id !== action.payload), ...meta,
        last_page: Math.ceil(data.length/meta.per_page)}}
    case IS_FETCHING_CLIENT:
      return { ...state, isFetchingClient: true }
    case IS_FETCHING_CLIENT_COMPLETE:
      return { ...state, isFetchingClient: false }
    default:
      return state
  }
}

export const isFetchingClients = () => ({
  type: IS_FETCHING_CLIENT
})

export const isFetchingClientsComplete = () => ({
  type: IS_FETCHING_CLIENT_COMPLETE
})


export const getClients = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingClients())
    return axios.post(`${url}/client/search`, params, { headers})
      .then(({ data }) => {
        dispatch(isFetchingClientsComplete())
        dispatch({
          type: GET_CLIENT,
          payload: { clients: data }
        })

      })
  }
}

export const getStudentsAction = (query) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/client/student`, {params: query, headers})
      .then(({ data }) => {
        dispatch({
          type: GET_STUDENTS,
          payload: { students: data }
        })
      })
  }
}

export const resetStudentsAction = () => ({
  type: RESET_STUDENTS,
})

export const createClient = (client) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingClients())
    return axios.post(`${url}/client`, client, { headers })
      .then(({ data }) => {
        dispatch(isFetchingClientsComplete())
        dispatch({
          type: CREATE_CLIENT,
          payload: { client: data }
        })
        return data
      })
  }
}
export const getClient = (clientId) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/client/${clientId}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_CLIENT,
          payload: { client: data }
        })

      })
  }
}
export const updateClient = ({ id, ...client }) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingClients())
    return axios.put(`${url}/client/${id}`, client, { headers })
      .then(({ data }) => {
        dispatch(isFetchingClientsComplete())
        dispatch({
          type: UPDATE_CLIENT,
        })
      })
  }
}
export const deleteClient = (clientId) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/client/${clientId}`, { headers })
      .then(() => {
        dispatch({
          type: DELETE_CLIENT,
          payload: clientId,
        })
      })
  }
}
export const endEditClient = () => ({
  type: END_EDIT_CLIENT
})

export const authorize = (id, payload) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/wallet-expenses/${id}/authorize`, payload,{ headers })
      .then(() => {
        dispatch({
          type: AUTHORIZE_CLIENT,
          payload: true
        })
      })
  }
}

