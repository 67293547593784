import React, { Component, Fragment } from 'react'
import { validator, validateData, getValues } from '../../helpers'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { Login } from 'Components/auth'
import OtpForm from 'Components/auth/otpForm'
import Enable from 'Components/auth/enable'
import LoginTas from 'Components/auth/loginTas'
import Show from 'Components/common/Show'
import { login, getRandomKey, getLegal, preLogin, sendQrEmail, loginWithTop, enableTotp } from 'Modules/auth'
import { Box, Modal, Button, Hero, Column, Container, Title, SubTitle } from 'Components/common/bulma'
import { addNotification } from 'Modules/principal'

export class LoginContainer extends Component {
  state = {
    data: {
      usuario: {
        value: '',
        validation: 'required|isLength:16',
        pattern: 'number',
      },
      password: {
        value: '',
        validation: 'required',
      },
      terminos: {
        value: false,
        validation: 'required',
      },
      // card: {
      //   value: '',
      //   validation: 'required',
      //   pattern: 'number',
      // }
    },
    dataTas: {
      card: {
        value: '',
        validation: 'required|isLength:3',
        pattern: 'number',
      },
    },
    error: {},
    openTerms: false,
    openNotice: false,
    completions: 1,
    showErrors: false,
    loginError: false,
    submitting: false,
    step: 0,
    prelogin: {},
    loginWay: undefined,
  }
  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.props.push('/')
    }
    this.props.getRandomKey();
    this.props.getLegal()
  }
  onChangeInput = ({ target }) => {
    const { value, name, checked, type } = target
    let { data } = this.state, validateField = true

    if (type === 'checkbox') {
      data[name].value = checked
    } else {
      validateField = (value && data[name].pattern) ? validator(value, data[name].pattern).valid : true
      data[name].value = validateField ? value : this.state.data[name].value
    }

    this.setState({
      data: data,
      showErrors: false,
      loginError: false
    })
  }

  login = () => {
    const { hasErrors, data } = validateData(this.state.data)
    if (hasErrors) {
      this.setState({ showErrors: true, data })
      return
    }
    this.setState({ submitting: true }, () => {
      this.props.preLogin({ ...getValues(this.state.data) })
        .then(({ data }) => {
          this.setState({ submitting: false, prelogin: data, })

          const isEnableTfa = data.enable_tfa

          if (isEnableTfa) {
            this.setState({ step: 1 })
          } else {
            this.setState({ step: 2 })
          }
        }).catch(() => this.setState({ loginError: true, submitting: false }))
    })
  }

  refreshRandomKey = () => {
    const completions = this.state.completions + 1
    this.props.getRandomKey()
      .then(() => {
        this.setState({
          completions: completions
        })
      })
  }

  openNotice = e => {
    e.preventDefault()
    this.setState({ openNotice: true })
  }

  closeNotice = () => this.setState({ openNotice: false })

  openTerms = e => {
    e.preventDefault()
    this.setState({ openTerms: true })
  }

  closeTerms = () => this.setState({ openTerms: false })
  closeServerError = () => this.setState({ loginError: false })
  setLogin = (way) => {
    this.setState({ loginWay: way })
  }

  handleOnLogin = (code) => {
    return this.props.loginWithTop(code)
  }

  handleOnEnableTop = (code) => {
    return this.props.enableTotp(code)
  }

  handleOnSendQr = () => {
    sendQrEmail({ 'verification-token': this.state.prelogin['verification-token'] })
      .then(() => {
        this.setState({ step: 1.1 })
        this.props.addNotification({
          type: 'success',
          message: '¡Se ha enviado un correo electrónico, con un link al código QR para activarlo en su app Authenticator!'
        })
      })
      .catch(() => {
        this.setState({ step: 1.1 })
        this.props.addNotification({
          type: 'danger',
          message: '¡Se ha producido un error al enviar el correo de activación!'
        })
      });
  }

  handleOnLoginTas = () => {
    this.setState({ step: 4 })
  }

  render() {
    return (
      <Fragment>
        <Modal isActive={this.state.openTerms}>
          <Box>
            <article className="media">
              <div className="media-content">
                <button className="modal-close is-large" aria-label="close" onClick={this.closeTerms}></button>
                <h1 className="has-text-center">Términos y condiciones</h1>
                <div className="has-text-justified is-size-7" dangerouslySetInnerHTML={{ __html: this.props.legal.terminosCondiciones }}></div>
                <label className="button is-danger is-pulled-right" htmlFor="cbx" onClick={this.closeTerms} >Aceptar
                </label>
              </div>
            </article>
          </Box>
        </Modal>
        <Modal isActive={this.state.openNotice}>
          <Box>
            <article className="media">
              <div className="media-content">
                <button className="modal-close is-large" aria-label="close" onClick={this.closeNotice}></button>
                <h1 className="has-text-center">Aviso de privacidad</h1>
                <div className="has-text-justified is-size-7" dangerouslySetInnerHTML={{ __html: this.props.legal.avisoPrivacidad }}></div>
                <p className="has-text-right">
                  <Button danger onClick={this.closeNotice}>Aceptar</Button>
                </p>
              </div>
            </article>
          </Box>
        </Modal>
        <Hero medium>
          <Hero.Body className="hero-body-padding-small">
            <Container className="has-text-centered">
              <Column className="is-4-desktop is-12-mobile is-offset-4">
                <Box style={{ height: '472px' }}>
                  <Show canShow={this.state.step === 0}>
                    <Login
                      completions={this.state.completions}
                      refreshRandomKey={this.refreshRandomKey}
                      randomKey={this.props.randomKey}
                      data={this.state.data}
                      login={this.login}
                      onChangeInput={this.onChangeInput}
                      openTerms={this.openTerms}
                      openNotice={this.openNotice}
                      showErrors={this.state.showErrors}
                      loginError={this.state.loginError}
                      hideLoginError={this.closeServerError}
                      submitting={this.state.submitting}
                      step={this.state.step}
                      prelogin={this.state.prelogin}
                      setLogin={this.setLogin}
                      loginWay={this.state.loginWay}
                      loginWithTas={this.props.login}
                      sendQrEmail={sendQrEmail}
                      loginTop={this.props.loginTop}
                      enableTotp={this.props.enableTotp}
                    />
                  </Show>
                  <Show canShow={this.state.step === 1}>
                    <div style={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}>
                      <SubTitle is="6">
                        Para entrar, ingresa el código generado por la aplicación (Google Authenticator, Authy, etc.)
                      </SubTitle>
                      <OtpForm
                        handleOnSubmitTotp={this.handleOnLogin}
                      />
                    </div>
                  </Show>
                  <Show canShow={this.state.step === 1.1}>
                    <div style={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}>
                      <SubTitle is="6">
                        Se ha enviado un link a tu correo electrónico. Para activar tu autenticación de dos factores
                        escanea el QR en ingresa el código generado por la aplicación (Google Authenticator, Authy, etc.)
                      </SubTitle>
                      <OtpForm
                        handleOnSubmitTotp={this.handleOnEnableTop}
                        buttonLabel="Activar acceso"
                      />
                    </div>
                  </Show>
                  <Show canShow={this.state.step === 2}>
                    <div style={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}>
                      <Title is="5">Seleccione la forma de ingresar</Title>
                      <Enable
                        handleOnLoginTas={this.handleOnLoginTas}
                        handleOnSendQr={this.handleOnSendQr}
                      />
                    </div>
                  </Show>
                  <Show canShow={this.state.step === 3}>
                    <div style={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                      <Enable
                        handleOnLoginTas={this.handleOnLoginTas}
                        handleOnSendQr={this.handleOnSendQr}
                      />
                    </div>
                  </Show>
                  <Show canShow={this.state.step === 4}>
                    <div style={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                      <LoginTas
                        completions={this.state.completions}
                        refreshRandomKey={this.refreshRandomKey}
                        randomKey={this.props.randomKey}
                        data={this.state.data}
                        showErrors={this.state.showErrors}
                        loginError={this.state.loginError}
                        hideLoginError={this.closeServerError}
                        submitting={this.state.submitting}
                        prelogin={this.state.prelogin}
                        loginWithTas={this.props.login}
                      />
                    </div>
                  </Show>
                </Box>
              </Column>
            </Container>
          </Hero.Body>
        </Hero>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.authenticated,
  randomKey: state.auth.randomKey,
  legal: state.auth.legal
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login,
      getRandomKey,
      getLegal,
      push,
      preLogin,
      loginWithTop,
      enableTotp,
      addNotification,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
