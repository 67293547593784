import React from 'react';

export default () => (
  <div style={{
    zIndex: 9999,
    position: 'absolute',
    background: '#8080802e',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}>
    <div
      className='loader-animation'
    >
      Loading...
    </div>;
  </div>
)