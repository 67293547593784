import axios from 'axios'
import { getHeaders } from 'Modules/util'

const url = process.env.REACT_APP_API

export const GET_LIST = 'loanOrders/creditor/GET_LIST'
export const DELETE_ORDER = 'loanOrders/creditor/DELETE_ORDER'
export const CREATE_ORDER = 'loanOrders/creditor/CREATE_ORDER'
export const UPDATE_ORDER = 'loanOrders/creditor/UPDATE_ORDER'
export const GET_ORDER = 'loanOrders/creditor/GET_ORDER'
export const AUTHORIZE_ORDER = 'loanOrders/creditor/AUTHORIZE_ORDER'
export const END_ORDER_EDIT = 'loanOrders/creditor/END_ORDER_EDIT'
export const UPLOAD_CONTRACT = 'loanOrders/creditor/UPLOAD_CONTRACT'
export const GET_ATTACHMENTS = 'loanOrders/creditor/GET_ATTACHMENTS'
export const IS_FETCHING_CREDITOR = 'loanOrders/creditor/IS_FETCHING_CREDITOR'
export const IS_FETCHING_CREDITOR_COMPLETE = 'loanOrders/creditor/IS_FETCHING_CREDITOR_COMPLETE'

const initialState = {
  list: {
    data: []
  },
  isFetchingCreditor: false,
  order: undefined,
  attachments: [],
}

export default (state = initialState, action) => {
  switch (action.type) {

    case GET_LIST:
    case UPDATE_ORDER:
    case GET_ORDER:
    case UPLOAD_CONTRACT:
    case CREATE_ORDER:
    case GET_ATTACHMENTS:
      return { ...state, ...action.payload }
    case AUTHORIZE_ORDER:
      return { ...state, order: { ...state.order, ...action.payload } }
    case IS_FETCHING_CREDITOR:
      return { ...state, isFetchingCreditor: true }
    case IS_FETCHING_CREDITOR_COMPLETE:
      return { ...state, isFetchingCreditor: false }
    case END_ORDER_EDIT:
      return initialState
    default:
      return state
  }
}

export const isFetchingCreditor = () => ({
  type: IS_FETCHING_CREDITOR
})

export const isFetchingCreditorComplete = () => ({
  type: IS_FETCHING_CREDITOR_COMPLETE
})

export const getList = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingCreditor())
    return axios.post(`${url}/order-loan/receive/list`, params, { headers, })
      .then(({ data }) => {
        dispatch(isFetchingCreditorComplete())
        dispatch({
          type: GET_LIST,
          payload: { list: data }
        })
      })
  }
}

export const deleteOrder = (orderId) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/order-loan/receive/${orderId}`, { headers })
      .then(() => {
        dispatch({
          type: DELETE_ORDER,
          payload: orderId,
        })
      })
  }
}

export const createOrder = order => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/order-loan/receive`, order, { headers })
      .then(({ data }) => {
        dispatch({
          type: CREATE_ORDER,
          payload: { order: data }
        })
        return data
      })
  }
}

export const updateOrder = ({ id, ...order }) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.put(`${url}/order-loan/receive/${id}`, order, { headers })
      .then(({ data }) => {
        dispatch({
          type: UPDATE_ORDER,
          payload: { order: data }
        })
      })
  }
}

export const getOrder = id => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/order-loan/receive/${id}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_ORDER,
          payload: { order: data }
        })
      })
  }
}

export const authorizeOrder = ({ id, ...authorizeData }) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/authorize-order-loan/receive/${id}`, authorizeData, { headers })
      .then(({ data }) => {
        dispatch({
          type: AUTHORIZE_ORDER,
          payload: data
        })
      })
  }
}

export const uploadContract = (data) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/storage/upload/`, data, { headers })
      .then(({ data }) => {
        dispatch({
          type: UPLOAD_CONTRACT,
        })
        return data
      })
  }
}

export const endOrderEdit = () => ({ type: END_ORDER_EDIT })


export const getAttachments = idOrder => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/storage/listContents/${idOrder}/prestamo`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_ATTACHMENTS,
          payload: { attachments: data }
        })
      })
  }
}