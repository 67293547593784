import axios from 'axios'
import { getHeaders } from 'Modules/util'
import { GET_ORDER as GET_DEBTOR_ORDER } from 'Modules/loanOrders/debtor'

const url = process.env.REACT_APP_API

export const GET_LIST = 'loanOrders/paymentAmortization/GET_LIST'
export const DELETE_ORDER = 'loanOrders/paymentAmortization/DELETE_ORDER'
export const CREATE_ORDER = 'loanOrders/paymentAmortization/CREATE_ORDER'
export const UPDATE_ORDER = 'loanOrders/paymentAmortization/UPDATE_ORDER'
export const GET_ORDER = 'loanOrders/paymentAmortization/GET_ORDER'
export const AUTHORIZE_ORDER = 'loanOrders/paymentAmortization/AUTHORIZE_ORDER'
export const GET_RELATED_ORDERS = 'loanOrders/paymentAmortization/GET_RELATED_ORDERS'
export const IS_FETCHING_PAYMENT_AMORTIZATION = 'loanOrders/paymentAmortization/IS_FETCHING_PAYMENT_AMORTIZATION'
export const IS_FETCHING_PAYMENT_AMORTIZATION_COMPLETE = 'loanOrders/paymentAmortization/IS_FETCHING_PAYMENT_AMORTIZATION_COMPLETE'
export const END_ORDER_EDIT = 'loanOrders/paymentAmortization/END_ORDER_EDIT'


const initialState = {
  list: {
    data: []
  },
  isFetchingPaymentAmortization: false,
  relatedOrders: [],
  order: undefined,
}

export default (state = initialState, action) => {
  switch (action.type) {

    case GET_LIST:
    case CREATE_ORDER:
    case UPDATE_ORDER:
    case GET_ORDER:
    case GET_RELATED_ORDERS:
      return { ...state, ...action.payload }
    case AUTHORIZE_ORDER:
      return { ...state, order: { ...state.order, ...action.payload } }
    case DELETE_ORDER:
      const { data, ...meta } = state.list
      return { ...state, list: { data: data.filter(it => it.id !== action.payload), ...meta, last_page: Math.ceil(data.length / meta.per_page) } }
    case IS_FETCHING_PAYMENT_AMORTIZATION:
      return { ...state, isFetchingPaymentAmortization: true }
    case IS_FETCHING_PAYMENT_AMORTIZATION_COMPLETE:
      return { ...state, isFetchingPaymentAmortization: false }
    case END_ORDER_EDIT:
      return initialState
    default:
      return state
  }
}

export const isFetchingPaymentAmortization = () => ({
  type: IS_FETCHING_PAYMENT_AMORTIZATION
})

export const isFetchingPaymentAmortizationComplete = () => ({
  type: IS_FETCHING_PAYMENT_AMORTIZATION_COMPLETE
})

export const getList = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetchingPaymentAmortization())
    return axios.post(`${url}/order-loan/pay/list`, params, { headers })
      .then(({ data }) => {
        dispatch(isFetchingPaymentAmortizationComplete())
        dispatch({
          type: GET_LIST,
          payload: { list: data }
        })
      })
  }
}

export const deleteOrder = (orderId) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/order-loan/pay/${orderId}`, { headers })
      .then(() => {
        dispatch({
          type: DELETE_ORDER,
        })
      })
  }
}

export const createOrder = order => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/order-loan/pay/`, order, { headers })
      .then(({ data }) => {
        dispatch({
          type: CREATE_ORDER,
          payload: { order: data },
        })
        return data
      })
  }
}

export const updateOrder = order => {
  const headers = getHeaders()
  return dispatch => {
    return axios.put(`${url}/order-loan/pay/${order.id}`, order, { headers })
      .then(({ data }) => {
        dispatch({
          type: UPDATE_ORDER,
          payload: { order: data },
        })
        return data
      })
  }
}

export const getOrder = id => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/order-loan/pay/${id}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_ORDER,
          payload: { order: data },
        })
        return data
      })
  }
}

export const getDebtorOrder = id => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/order-loan/pay/receive/${id}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_DEBTOR_ORDER,
          payload: { order: data },
        })
        return data
      })
  }
}

export const authorizeOrder = ({ id, ...body }) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/authorize-order-loan/pay/${id}`, body, { headers })
      .then(({ data }) => {
        dispatch({
          type: AUTHORIZE_ORDER,
          payload: data,
        })
        return data
      })
  }
}

export const endOrderEdit = () => ({ type: END_ORDER_EDIT })

export const getRelatedOrder = () => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/loanOrder/related/list-order-mutual/2`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_RELATED_ORDERS,
          payload: { relatedOrders: data },
        })
        return data
      })
  }
}
