import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'

const TableHeader = ({ colsetup, meta, sort }) => {

  return (
    <tr className='table-headings'>
      {colsetup.map(({ title, sortable, datakey, width = '10%', align }) => (
        <th
          key={datakey}
          onClick={sortable ? () => sort(datakey) : () => { }}
          style={sortable ? { cursor: 'pointer' } : null}
          width={width}
          align={align}
        >
          {title} {sortable ? <span className={datakey === meta.orderBy ? 'has-text-black' : 'has-text-grey-lighter'}>
            <FontAwesomeIcon icon={meta.sortType === 'ASC' ? faCaretUp : faCaretDown} />
          </span> : null}
        </th>
      ))}
    </tr>
  );
};

export default TableHeader;
