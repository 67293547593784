import React, { memo, useState } from 'react'
import { Columns, Column } from 'Components/common/bulma'
import AutocompleteLocal from 'Components/common/form/AutocompleteLocal'
import { useMount } from 'ahooks'
import ShippingService from 'App/services/shippingService';
import http from 'App/services/httpService';

const shippingService = new ShippingService(http);

function Notes({ fields, onChange, showErrors, setIsLoading }) {
  const [isLoadingCategories, setIsLoadingCategories] = useState(true)
  const [noteCategories, setNoteCategories] = useState([])
  const [noteSubCategories, setSubNoteCategories] = useState([])
  const [noteClasses, setNoteClasses] = useState([])
  const [packagins, setPackagings] = useState([])

  useMount(async () => {
    setIsLoadingCategories(true)
    const noteCategories = await shippingService.getNoteCategories();
    const packagings = await shippingService.getPackagings();
    setNoteCategories(noteCategories);
    setPackagings(packagings)
    setIsLoadingCategories(false)
    setIsLoading(false)
  })

  const onChangeCategories = async (event) => {
    const { value } = event.target;
    if (value) {
      const noteSubCategories = await shippingService.getNoteSubCategories(event.target.value);
      setSubNoteCategories(noteSubCategories);
      onChange(event)
    } else {
      setSubNoteCategories([]);
    }
  }

  const onChangeSubCategories = async (event) => {
    const { value } = event.target;
    if (value) {
      const noteClasses = await shippingService.getNoteClasses(event.target.value);
      setNoteClasses(noteClasses);
      onChange(event)
    } else {
      setNoteClasses([]);
    }
  }

  return (
    <Columns className="is-multiline">
      <Column className="is-half">
        <AutocompleteLocal
          {...fields.category}
          onChange={onChangeCategories}
          showErrors={showErrors}
          suggestions={noteCategories}
          property="name"
          listKey="id"
          disabled={isLoadingCategories}
        />
      </Column>
      <Column className="is-half">
        <AutocompleteLocal
          {...fields.subcategory}
          onChange={onChangeSubCategories}
          showErrors={showErrors}
          suggestions={noteSubCategories}
          property="name"
          listKey="id"
          disabled={isLoadingCategories}
        />
      </Column>
      <Column className="is-half">
        <AutocompleteLocal
          {...fields.consignment_note_class_code}
          onChange={onChange}
          showErrors={showErrors}
          suggestions={noteClasses}
          property="name"
          listKey="code"
          disabled={isLoadingCategories}
        />
      </Column>
      <Column className="is-half">
        <AutocompleteLocal
          {...fields.consignment_note_packaging_code}
          onChange={onChange}
          showErrors={showErrors}
          suggestions={packagins}
          property="name"
          listKey="code"
          disabled={isLoadingCategories}
        />
      </Column>
    </Columns>
  )
}

export default memo(Notes)
