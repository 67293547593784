import React from 'react';
import { Box, Columns, Column, Button } from 'Components/common/bulma'
import InputWithError from 'Components/common/form/InputWithError';
import DateWithError from 'Components/common/form/DateWithError'
import useBeneficiaries from './useBeneficiaries';
import Loader from 'Components/common/page/loaderFloat';
import Show from 'Components/common/Show';


const Beneficiaries = ({ refresh }) => {
  const { values, onChangeField, onRemove, addBeneficiary, showErrors,
    saveBeneficiaries,
    values2, onChangeField2, showErrors2, addBeneficiary2, onRemove2,
    values3, onChangeField3, showErrors3, addBeneficiary3, onRemove3, isLoading, percentageError,
  } = useBeneficiaries({ refresh })


  const onChangePercentage = (onChange, { target }) => {
    const value = target.value.replace('%', '')
    onChange({ target: { value: value, id: target.id, name: target.name } })
  }

  return (
    <Box style={{ position: 'relative' }}>
      <Show canShow={isLoading}>
        <Loader />
      </Show>
      <Columns className="is-multiline">
        <Column className="is-full">
          <p>
            En Administra tu Negocio queremos ayudarte a proteger tu patrimonio en el desafortunado caso en que tu como socio integrado, quedes impedido para seguir utilizando nuestro sistema temporal o definitivamente, por lo que de acuerdo a lo que la Ley nos indica respecto del Mandato, que es la figura a través de la cual estamos facultados para seguir tus instrucciones y actuar en tu nombre y representación, este solo puede terminar porque así lo hayan acordado las partes, porque se haya concluido el objeto del mandato o porque alguna de las partes estuviera impedida para cumplirlo y esto incluye el caso de muerte.
          </p>
          <br />
          <p>
            En expresión de mi libre y espontánea voluntad, suscribo el presente formato único de carta abierta de designación de beneficiarios a efecto de que cuando este impedido para ejercer su mandato por causa de fuerza mayor acreditable, se le entreguen las facultades de ejercicio del mandato en mi nombre y representación se le otorguen a el (los) siguiente(s) beneficiario, para que lleve a buen término aquellos negocios que se encuentren en tránsito al momento de la antes mencionada incidencia:
          </p>
        </Column>
        <Column className="is-full">
          <h3 className="title is-5">Beneficiario por causa de fuerza mayor acreditable</h3>
          <Button className="is-primary" onClick={addBeneficiary}>Agregar beneficiario</Button>
        </Column>
      </Columns>

      {values.map((beneficiary, idx) =>
        <Box>
          <Columns className="is-multiline">
            <Column className="is-half">
              <InputWithError
                onChange={onChangeField}
                showErrors={showErrors}
                {...{ ...beneficiary.beneficiary_name, input: { ...beneficiary.beneficiary_name.input, id: idx } }}
              />
            </Column>
            <Column className="is-half">
              <InputWithError
                onChange={onChangeField}
                showErrors={showErrors}
                {...{ ...beneficiary.relationship, input: { ...beneficiary.relationship.input, id: idx } }}
              />
            </Column>
            <Column className="is-half">
              <DateWithError
                onChange={onChangeField}
                showErrors={showErrors}
                {...{ ...beneficiary.date_birth, input: { ...beneficiary.date_birth.input, id: idx } }}
              />
            </Column>
            <Column className="is-half">
              <InputWithError
                onChange={event => onChangePercentage(onChangeField, event)}
                showErrors={showErrors}
                {...{ ...beneficiary.percent, input: { ...beneficiary.percent.input, value: `${beneficiary.percent.input.value}%`, id: idx } }}
              />
            </Column>
            <Column className="is-full"><Button onClick={() => onRemove(beneficiary)}>Eliminar</Button></Column>
          </Columns>
        </Box>
      )}
      {!!(percentageError && ((percentageError % 3) === 0)) && <span className="has-text-danger" style={{ position: 'absolute', marginTop: '-27px' }}>La suma de los porcentajes de los beneficiarios deben de dar 100%</span>}
      <Columns className="is-multiline">
        <Column className="is-full">
          <h3 className="title is-5">Y en caso de ausencia del Beneficiario principal:</h3>
          <Button className="is-primary" onClick={addBeneficiary2}>Agregar beneficiario</Button>
        </Column>
      </Columns>
      {values2.map((beneficiary, idx) =>
        <Box>
          <Columns className="is-multiline">
            <Column className="is-half">
              <InputWithError
                onChange={onChangeField2}
                showErrors={showErrors2}
                {...{ ...beneficiary.beneficiary_name, input: { ...beneficiary.beneficiary_name.input, id: idx } }}
              />
            </Column>
            <Column className="is-half">
              <InputWithError
                onChange={onChangeField2}
                showErrors={showErrors2}
                {...{ ...beneficiary.relationship, input: { ...beneficiary.relationship.input, id: idx } }}
              />
            </Column>
            <Column className="is-half">
              <DateWithError
                onChange={onChangeField2}
                showErrors={showErrors2}
                {...{ ...beneficiary.date_birth, input: { ...beneficiary.date_birth.input, id: idx } }}
              />
            </Column>
            <Column className="is-half">
              <InputWithError
                onChange={event => onChangePercentage(onChangeField2, event)}
                showErrors={showErrors2}
                {...{ ...beneficiary.percent, input: { ...beneficiary.percent.input, value: `${beneficiary.percent.input.value}%`, id: idx } }}
              />
            </Column>
            <Column className="is-full"><Button onClick={() => onRemove2(beneficiary)}>Eliminar</Button></Column>
          </Columns>
        </Box>
      )}
      {!!(percentageError && ((percentageError % 7) === 0)) && <span className="has-text-danger" style={{ position: 'absolute', marginTop: '-27px' }}>La suma de los porcentajes de los beneficiarios deben de dar 100%</span>}

      <Columns className="is-multiline">
        <Column className="is-full">
          <p>
            De igual forma en expresión de mi libre y espontánea voluntad designo como beneficiarios en caso de mi fallecimiento a las siguientes personas en el porcentaje que se indica:
          </p>
        </Column>
        <Column className="is-full">
          <h3 className="title is-5">Beneficiario en caso de fallecimiento</h3>
          <Button className="is-primary" onClick={addBeneficiary3}>Agregar beneficiario</Button>
        </Column>
      </Columns>
      {values3.map((beneficiary, idx) =>
        <Box>
          <Columns className="is-multiline">
            <Column className="is-half">
              <InputWithError
                onChange={onChangeField3}
                showErrors={showErrors3}
                {...{ ...beneficiary.beneficiary_name, input: { ...beneficiary.beneficiary_name.input, id: idx } }}
              />
            </Column>
            <Column className="is-half">
              <InputWithError
                onChange={onChangeField3}
                showErrors={showErrors3}
                {...{ ...beneficiary.relationship, input: { ...beneficiary.relationship.input, id: idx } }}
              />
            </Column>
            <Column className="is-half">
              <DateWithError
                onChange={onChangeField3}
                showErrors={showErrors3}
                {...{ ...beneficiary.date_birth, input: { ...beneficiary.date_birth.input, id: idx } }}
              />
            </Column>
            <Column className="is-half">
              <InputWithError
                onChange={event => onChangePercentage(onChangeField3, event)}
                showErrors={showErrors3}
                {...{ ...beneficiary.percent, input: { ...beneficiary.percent.input, value: `${beneficiary.percent.input.value}%`, id: idx } }}
              />
            </Column>
            <Column className="is-full"><Button onClick={() => onRemove3(beneficiary)}>Eliminar</Button></Column>
          </Columns>
        </Box>
      )
      }
      {!!(percentageError && ((percentageError % 11) === 0)) && <span className="has-text-danger" style={{ position: 'absolute', marginTop: '-27px' }}>La suma de los porcentajes de los beneficiarios deben de dar 100%</span>}
      <Columns className="is-multiline">
        <Column className="is-full">
          <Button className="is-primary" onClick={saveBeneficiaries}>Guardar beneficiarios</Button>
        </Column>
      </Columns>
    </Box>
  );
};

export default Beneficiaries;
