
import moment from 'moment'

export const colData =
[
  {
    title: 'Fecha',
    datakey: 'fecha',
    format: data => moment(data).format('DD-MM-YY'),
    width: '5%',
    sortable: true,
  },
  {
    title: 'Descripción',
    datakey: 'tipoPago',
    width: '10%',
    sortable: true,
  },
  {
    title: 'Descripción adicional',
    datakey: 'tipo2',
    width: '10%',
    sortable: true,
  },
  {
    title: 'Referencia',
    datakey: 'referencia',
    width: '5%',
    sortable: true,
  },
  {
    title: 'Concepto',
    datakey: 'concepto',
    width: '10%',
    sortable: true,
  },
  {
    title: 'Monto',
    datakey: 'monto',
    format: '$ 0,0.00',
    width: '10%',
    sortable: true,
  },
  {
    title: 'Procesador de tarjeta',
    datakey: 'agregador',
    width: '5%',
    sortable: true,
  },
  {
    title: 'Depositado en Bancos',
    datakey: 'deposito',
    format: data => +data === 1 ? 'Si' : 'No',
    width: '5%',
    sortable: true,
  },
  {
    title: 'Abonado en Saldo',
    datakey: 'conciliado',
    format: data => +data === 1 ? 'Si' : 'No',
    width: '5%',
    sortable: true,
  },
  {
    title: 'Comisión',
    datakey: 'comision',
    format: '$ 0,0.00',
    width: '10%',
    sortable: true,
  },
  {
    title: 'Iva Comisión',
    datakey: 'ivaComision',
    format: '$ 0,0.00',
    width: '10%',
    sortable: true,
  },
  {
    title: 'Monto Cobrado Cliente',
    datakey: 'montoExchange',
    width: '10%',
    sortable: true,
  },
  {
    title: 'Moneda Cobrado Cliente',
    datakey: 'moneda',
    width: '5%',
    sortable: true,
  },
];