import React from 'react'
import { Link } from 'react-router-dom'

const TokenInvalido = () => {
    return (
        <section className="hero is-large is-default is-bold">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-vcentered">
                        <div className="column is-offset-1 landing-caption has-text-centered">
                            <h1 className="title is-1 is-bold is-spaced">
                                Upssss... El token ha expirado
                            </h1>
                            <h2 className="subtitle is-5 is-muted has-text-justify"> Favor de solicitar el token nuevamente. </h2>
                            <Link to="/login" className="button is-danger ">Regresar al inicio</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hero-foot mb-20">
                <div className="container">
                    <div className="tabs is-centered">
                    </div>
                </div>
            </div>
        </section >
    )
}

export default TokenInvalido