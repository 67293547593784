import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { isAllow } from 'Modules/catalogs/walletExpense'
import { Can } from 'Containers/auth'
import ProveedoresImg from '../../styles/images/Proveedores.png';
import IasImg from '../../styles/images/IAS.png';
import MonederoImg from '../../styles/images/Monedero.png';
import MonederoGasolinaImg from '../../styles/images/Monedero-Gasolina.png';
import HonorariosImg from '../../styles/images/Honorarios.png';
import RetirosImg from '../../styles/images/Retiros.png';
import ReembolsosImg from '../../styles/images/Reembolsos.png';
import PaqueteriaImg from '../../styles/images/Paqueteria.png';

export class Catalogs extends Component {
  componentDidMount() {
    this.props.isAllow()
  }

  render() {
    const { walletExpenseAllow } = this.props
    return (
      <section className="info-tiles">
        <div className="tile is-ancestor has-text-centered">
          <Can partnerPermission={['pagoAproveedores']} validate={['Opago de proveedores', 'Apago de proveedores']}>
            <div className="tile is-parent">
              <Link className="tile is-child box" to="/ordenes-pago/proveedores">
                <p className="title has-text-grey-dark">
                  <img className="icons" src={ProveedoresImg} alt="catalogo de proveedores" />
                </p>
                <p className="subtitle">Proveedores</p>
              </Link>
            </div>
          </Can>
          <Can partnerPermission={['convenioIntegrados']} validate={['Opago de proveedores', 'Apago de proveedores']}>
            <div className="tile is-parent">
              <Link className="tile is-child box" to="/ordenes-pago/asimilados-salarios">
                <p className="title has-text-grey-dark">
                  <img className="icons" src={IasImg} alt="catalogo de proveedores" />
                </p>
                <p className="subtitle">Convenios Integrados</p>
              </Link>
            </div>
          </Can>
          {walletExpenseAllow && <Can partnerPermission={['monederosGastos']} validate={['Opago de proveedores', 'Apago de proveedores']}>
            <div className="tile is-parent">
              <Link className="tile is-child box" to="/ordenes-pago/monederos-gastos">
                <p className="title has-text-grey-dark">
                  <img className="icons" src={MonederoImg} alt="catalogo de proveedores" />
                </p>
                <p className="subtitle">Monedero de Gastos</p>
              </Link>
            </div>
          </Can>}
          <Can partnerPermission={['monederosGasolina']} validate={['Opago de proveedores', 'Apago de proveedores']}>
            <div className="tile is-parent">
              <Link className="tile is-child box" to="/ordenes-pago/monederos-gasolina">
                <p className="title has-text-grey-dark">
                  <img className="icons" src={MonederoGasolinaImg} alt="catalogo de proveedores" />
                </p>
                <p className="subtitle">Monederos de Gasolina</p>
              </Link>
            </div>
          </Can>
        </div>
        <div className="tile is-ancestor has-text-centered">
          <Can partnerPermission={['reciboHonorario']} validate={['Opago de proveedores', 'Apago de proveedores']}>
            <div className="tile is-parent">
              <Link className="tile is-child box" to="/ordenes-pago/recibo-honorarios">
                <p className="title has-text-grey-dark">
                  <img className="icons" src={HonorariosImg} alt="catalogo de proveedores" />
                </p>
                <p className="subtitle">Proveedor Con Retenciones</p>
              </Link>
            </div>
          </Can>
          <br />
          <Can partnerPermission={['retiros']} validate={['Oretiros', 'Aretiros']}>
            <div className="tile is-parent">
              <Link className="tile is-child box" to="/ordenes-pago/retiros">
                <p className="title has-text-grey-dark">
                  <img className="icons" src={RetirosImg} alt="catalogo de proveedores" />
                </p>
                <p className="subtitle">Retiros</p>
              </Link>
            </div>
          </Can>
          <Can partnerPermission={['reembolsos']} validate={['Opago de proveedores', 'Apago de proveedores']}>
            <div className="tile is-parent">
              <Link className="tile is-child box" to="/ordenes-pago/reembolsos">
                <p className="title has-text-grey-dark">
                  <img className="icons" src={ReembolsosImg} alt="catalogo de proveedores" />
                </p>
                <p className="subtitle">Reembolsos</p>
              </Link>
            </div>
          </Can>
          <Can partnerPermission={['paqueteria']} validate={['Opago de proveedores', 'Apago de proveedores']}>
            <div className="tile is-parent">
              <Link className="tile is-child box" to="/ordenes-pago/paqueteria">
                <p className="title has-text-grey-dark">
                  <img className="icons" src={PaqueteriaImg} alt="ordenes de pago paqueteria" />
                </p>
                <p className="subtitle">Paquetería / Mensajería</p>
              </Link>
            </div>
          </Can>
        </div>
      </section>
    )
  }
}

const mapStateToProps = ({ catalogs }) => ({
  walletExpenseAllow: catalogs.walletExpense.isAllow
})

const mapDispatchToProps = dispatch => bindActionCreators({
  isAllow,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Catalogs)
export { default as PaymentOrdersProvider } from './providers'
export { default as PaymentOrdersSalaries } from './salaries'
export { default as PaymentOrdersWalletExpense } from './walletExpense'
export { default as PaidPaymentOrdersWalletExpense } from './walletExpense/paidWalletExpense'
export { default as RejectedPaymentOrdersWalletExpense } from './walletExpense/rejectedWalletExpense'
export { default as ProcesingPaymentOrdersWalletExpense } from './walletExpense/procesingWalletExpense'
export { default as PaymentOrdersWalletGasoline } from './walletGasoline'
export { default as PaymentOrdersReceiptFee } from './receiptFee'
export { default as PaymentOrdersRetreats } from './retreats'
export { default as PaymentOrdersRefund } from './refund'
export { default as PaymentOrdersProviderForm } from './providers/form'
export { default as PaymentOrdersSalariesForm } from './salaries/form'
export { default as ProcesingPaymentOrdersSalaries } from './salaries/procesingPaymentOrderSalaries'
export { default as PaidPaymentOrdersSalaries } from './salaries/paidPaymentOrderSalaries'
export { default as RejectedPaymentOrdersSalaries } from './salaries/rejectedPaymentOrderSalaries'
export { default as PaymentOrdersWalletExpenseForm } from './walletExpense/form'
export { default as PaymentOrdersWalletGasolineForm } from './walletGasoline/form'
export { default as PaidPaymentOrdersWalletGasoline } from './walletGasoline/paidWalletGasoline'
export { default as ProcesingPaymentOrdersWalletGasoline } from './walletGasoline/procesingWalletGasoline'
export { default as RejectedPaymentOrdersWalletGasoline } from './walletGasoline/rejectedWalletGasoline'
export { default as PaymentOrdersReceiptFeeForm } from './receiptFee/form'
export { default as PaymentOrdersRetreatsForm } from './retreats/form'
export { default as PaymentOrdersRefundForm } from './refund/form'
export { default as PendingPaymentOrders } from './pendingPaymentOrders'
export { default as RejectedInvoicesPaymentOrders } from './rejectedInvoicePaymentOrders'
export { default as PendingInvoicesPaymentOrders } from './pendingInvoicePaymentOrders'
export { default as PendingToPayPaymentOrders } from './pendingToPayPaymentOrders'