import React from 'react'
import { Output } from 'Components/common/form'
import { Columns, Column, Divider } from 'Components/common/bulma'

export default function Totals({
  data,
  providerInvoiceStatus,
}) {
  return (
    <div>
      <Columns>
        <Column>
          <Divider content="RESUMEN DEL PAGO" />
          <Output
            {...data.subtotal}
          />
          <Output
            {...data.IVA}
          />
          <Output
            {...data.ieps}
          />
          <Output
            {...data.total}
          />
          <Output
            {...data.ISRRetenido}
          />
          <Output
            {...data.IVARetenido}
          />
          <Output
            {...data.netoDepositar}
          />
        </Column>
        <Column>
          <Divider content="RESUMEN MONTOS PAGADOS" />
          <Output
            {...data.montoPagado}
          />
          <Output
            {...data.montoPagoTransito}
          />
          <Output
            {...data.montoPagar}
          />
        </Column>
      </Columns>
    </div>
  )
}
