import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import auth, { LOGOUT } from 'Modules/auth'
import principal from 'Modules/principal'
import home from 'Modules/home'
import catalogos from 'Modules/catalogos'
import collaborators from 'Modules/catalogs/collaborators'
import walletExpense from 'Modules/catalogs/walletExpense'
import i18n from 'Modules/i18n'
import providers from 'Modules/catalogs/providers'
import pProviders from 'Modules/paymentOrders/providers'
import pReceiptFee from 'Modules/paymentOrders/receiptFee'
import pRefund from 'Modules/paymentOrders/refund'
import pRetreats from 'Modules/paymentOrders/retreats'
import pSalaries from 'Modules/paymentOrders/salaries'
import pWalletExpense from 'Modules/paymentOrders/walletExpense'
import pWalletGasoline from 'Modules/paymentOrders/walletGasoline'
import invoice from 'Modules/incomeOrders/invoice'
import deposits from 'Modules/incomeOrders/deposits'
import collectionCard from 'Modules/incomeOrders/collectionCard'
import creditor from 'Modules/loanOrders/creditor'
import debtor from 'Modules/loanOrders/debtor'
import chargeAmortization from 'Modules/loanOrders/chargeAmortization'
import paymentAmortization from 'Modules/loanOrders/paymentAmortization'
import transferOrders from 'Modules/transferOrders'
import clients from 'Modules/catalogs/clients'
import lenders from 'Modules/catalogs/lenders'
import reports from 'Modules/reports'
import advancePayment from 'Modules/incomeOrders/advancePayment'
import creditNote from 'Modules/incomeOrders/creditNote'
import inventory from 'Modules/inventory'
import conciliations from 'Modules/conciliations'
import widget from 'Modules/widget'
import users from 'Modules/users'
import contracts from 'Modules/contracts'
import trial from 'Modules/trial'

const appReducer = history => combineReducers({
  router: connectRouter(history),
  auth,
  principal,
  home,
  catalogos,
  i18n,
  catalogs: combineReducers({
    collaborators,
    walletExpense,
    providers,
    clients,
    lenders,
  }),
  paymentOrders: combineReducers({
    providers: pProviders,
    receiptFee: pReceiptFee,
    refund: pRefund,
    retreats: pRetreats,
    salaries: pSalaries,
    walletExpense: pWalletExpense,
    walletGasoline: pWalletGasoline,
  }),
  incomeOrders: combineReducers({
    invoice,
    deposits,
    advancePayment,
    creditNote,
    collectionCard,
  }),
  loanOrders: combineReducers({
    creditor,
    debtor,
    chargeAmortization,
    paymentAmortization,
  }),
  transferOrders,
  reports,
  inventory,
  conciliations,
  widget,
  users,
  contracts,
  trial,
})

const rootReducer = history => {
  const reducers = appReducer(history)

  return (state, action) => {
    if (action.type === LOGOUT) {
      localStorage.removeItem('auth')
      state = undefined;
    }
    return reducers(state, action)
  }
}

export default rootReducer;
