import numeral from 'numeral'
import moment from 'moment'

export const List = [
  {
    title: 'Id',
    datakey: 'idConciliacion',
  },
  {
    title: 'Referencia',
    datakey: 'referencia',
  },
  {
    title: 'Clabe',
    datakey: 'clabe',
  },
  {
    title: 'Moneda',
    datakey: 'moneda',
  },
  {
    title: 'Monto',
    datakey: 'monto',
    format: '$ 0,0.00',
  },
  {
    title: 'Acciones',
    custom: 'actions'
  }
]

export const transaction = {
  fecha: {
    name: 'fecha',
    label: 'Fecha',
    value: '',
    format: val => moment(val).format('DD/MM/YYYY')

  },
  tipoPago: {
    name: 'tipoPago',
    label: 'Tipo Pago',
    value: '',
  },
  tipoPago2: {
    name: 'tipoPago2',
    label: 'Tipo 2',
    value: '',
  },
  referencia: {
    name: 'referencia',
    label: 'Referencia',
    value: '',
  },
  concepto: {
    name: 'concepto',
    label: 'Concepto',
    value: '',
  },
  monto: {
    name: 'monto',
    label: 'Monto',
    value: '',
    format: val => numeral(val).format('$ 0,0.00')
  }
}