import { intersection } from 'ramda'
export const hasPermission = (permission, permissions) => {
    return !!intersection(permission, permissions).length
}

export const getHeaders = () => {
    const {jwt} = JSON.parse(localStorage.getItem('auth'))
    return {
        'Authorization': `Bearer ${jwt}`
    }
}