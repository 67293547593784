export const dataAuthorize = {
  usuario: {
    name: 'usuario',
    value: '',
    validation: 'required|isLength:16',
    pattern: 'number',
    label: 'No. de Tarjeta',
    maxLength: 16,
    placeholder: 'Tarjeta',
  },
  password: {
    name: 'password',
    value: '',
    validation: 'required',
    label: 'Contraseña:',
    type: 'password',
  },
  card: {
    name: 'card',
    value: '',
    validation: 'required|isLength:3',
    pattern: 'number',
    maxLength: 3,
  },
  authorize_method: {
    name: 'authorize_method',
    value: 'tas',
  },
}

export const dataAuthorizeTfa = {
  usuario: {
    name: 'usuario',
    value: '',
    validation: 'required|isLength:16',
    pattern: 'number',
    label: 'No. de Tarjeta',
    maxLength: 16,
    placeholder: 'Tarjeta',
  },
  password: {
    name: 'password',
    value: '',
    validation: 'required',
    label: 'Contraseña:',
    type: 'password',
  },
  authorize_method: {
    name: 'authorize_method',
    value: 'tfa',
  },
  token: {
    name: 'token',
    value: '',
    validation: 'required|isLength:6',
    pattern: 'number',
    label: 'Token (Google Authenticator o Authy)',
  },
}