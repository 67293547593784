import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { Box, Icon, Column, Columns, Button } from 'Components/common/bulma'
import { Table, ModalRemove } from 'Components/common'
import {
  getProducts,
  removeProduct,
} from 'Modules/incomeOrders/invoice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'
import { Trans } from '@lingui/macro'
import { productList } from './data'

export class List extends Component {
  state = {
    showModal: false,
    resource: {}
  }

  onEdit = ({ id }) => {
    this.props.push(`/catalogos/productos-servicios/${id}`)
  }

  toggleModal = (resource) => {
    this.setState(state => ({
      showModal: !state.showModal,
      resource,
    }))
  }

  deleteResource = () => {
    this.props.removeProduct(this.state.resource.id)
      .then(() => {
        this.setState(() => ({
          showModal: false
        }))

        this.props.getProducts({page: this.props.products.current_page})
      })
  }

  customColActions = (row) => (
    <div>
      <Button small primary outlined onClick={() => this.onEdit(row)}>
        <Icon>
          <FontAwesomeIcon icon={faEdit} />
        </Icon>
      </Button>
      <Button small danger outlined onClick={() => this.toggleModal(row)} className="margin-left-xs">
        <Icon>
          <FontAwesomeIcon icon={faTrash} />
        </Icon>
      </Button>
    </div>
  )
  render() {
    const { products } = this.props

    return (
      <Box>
        <ModalRemove
          isDeleting={this.state.showModal}
          title="Eliminar producto o servicio"
          toggleModal={this.toggleModal}
          confirmText="¿Esta seguro de querer eliminar el producto o servicio?"
          resource={this.state.resource.concepto}
          deleteResource={this.deleteResource}
        />
        <Columns>
          <Column className="has-text-right">
            <Link className="button is-primary is-outlined" to="/catalogos/productos-servicios/nuevo">
              <Icon>
                <FontAwesomeIcon icon={faPlus} />
              </Icon>
              <Trans render="span">Agregar Producto o Servicio</Trans>
            </Link>
          </Column>
        </Columns>
        <Table
          colsetup={productList}
          coldata={products}
          filterable
          tableClassName='table is-striped is-hoverable is-fullwidth'
          emptyTableMarkUp={(<span>No se encontraron productos o servicios</span>)}
          customCol={{
            customColName: 'actions',
            renderFunc: this.customColActions
          }}
          refresh={this.props.getProducts}
        />
      </Box>
    )
  }
}

const mapStateToProps = ({ incomeOrders }) => ({
  products: incomeOrders.invoice.productsList,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getProducts,
  removeProduct,
  push,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(List)
