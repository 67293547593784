import React, { useState } from 'react'
import { Box, Columns, Column, Icon, Button } from 'Components/common/bulma'
import { cond, isEmpty, always, T, F, } from 'ramda'
import { InputWithError } from 'Components/common/form'
import { Table } from 'Components/common'
import { shippingColumns } from './shippingDefinition'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faEdit, faTrash, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { Trans } from '@lingui/macro'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ActionMenu, ModalRemove } from 'Components/common'
import Loader from 'Components/common/page/loaderFloat';
import { push as pushAction } from 'connected-react-router';
import Show from 'Components/common/Show';
import { addNotification as addNotificationAction } from 'Modules/principal'
import http from 'App/services/httpService';
import ShippingService from 'App/services/shippingService';
import useForm from 'Hooks/useForm'


const shippingService = new ShippingService(http);

function Index({
  push,
  addNotification,
}) {
  const [shippingOrders, setShippingOrders] = useState({ data: [] })
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [shippingWillRemove, setShippingWillRemove] = useState({})
  const [
    reasonFields, , onChangeReasonFields, isValidReasonForm, getReasonValues, showReasonErrors
  ] = useForm({
    fields: {
      reason: {
        input: {
          name: 'reason',
          value: '',
          maxLength: 150,
        },
        label: 'Motivo de cancelación',
        validate: cond([
          [isEmpty, always({ error: 'Este campo es requerido' })],
          [T, F]
        ]),
      }
    }
  })

  const onEdit = ({ id }) => {
    push(`/ordenes-pago/paqueteria/${id}`)
  }

  const onRemove = (shipping) => {
    setShowModal(true)
    setShippingWillRemove(shipping)
  }

  const getShippingOrders = async (params) => {
    try {
      const shippingOrders = await shippingService.getShippingOrders(params);

      setShippingOrders(shippingOrders)
    } catch (error) {

    }
  }

  const handleOnDelete = async () => {
    if (!isValidReasonForm() && shippingWillRemove?.tracking) {
      return
    }
    const values = getReasonValues();
    setIsLoading(true)
    setShowModal(false)
    try {
      await shippingService.cancelShipping(shippingWillRemove.id, values)
      setShippingWillRemove(undefined)
      addNotification({
        type: 'success',
        message: 'Se envió la petición de cancelación de la guía, favor de revisar mas adelante el estatus de cancelación'
      })

      await getShippingOrders()
      setIsLoading(false)
    } catch (error) {
      addNotification({
        type: 'danger',
        message: 'Ocurrió un error al envíar la petición de cancelación'
      })
    }
    setIsLoading(false)
  }

  const customColActions = (row, id, fetchData) => (
    <div>
      {row.label_url && (
        <a href={row.label_url} download target="_blank" rel="noopener noreferrer">
          <Button style={{ position: 'absolute' }} small default outlined>
            <Icon>
              <FontAwesomeIcon icon={faFilePdf} />
            </Icon>
            <p>Guia</p>
          </Button>
        </a>
      )}
      <div style={{ width: '108px' }}>
        <ActionMenu>
          <Button className="margin-left-xs" small primary onClick={() => onEdit(row)}>
            <Icon>
              <FontAwesomeIcon icon={faEdit} />
            </Icon>
          </Button>
          <Button small className="margin-left-xs" danger onClick={() => onRemove(row, fetchData)}>
            <Icon>
              <FontAwesomeIcon icon={faTrash} />
            </Icon>
          </Button>
        </ActionMenu>
      </div>
    </div>
  )

  return (
    <Box style={{ position: 'relative' }}>
      <Show canShow={isLoading}>
        <Loader />
      </Show>
      <ModalRemove
        isDeleting={showModal}
        title="Eliminar guia"
        toggleModal={() => setShowModal(false)}
        confirmText={shippingWillRemove?.tracking ? `Ya existe un número guía creada, se enviará la petición con el proveedor \n
        favor de revisar el estatus de la cancelación cuando se actualice`: 'Esta seguro que desea cancelar la órden'}
        resource={`${shippingWillRemove?.receiver} - ${shippingWillRemove?.tracking ? shippingWillRemove?.tracking : shippingWillRemove?.id}`}
        deleteResource={handleOnDelete}
      >
        {shippingWillRemove?.tracking && (
          <Columns className="is-multiline">
            <Column>
              <InputWithError
                {...reasonFields.reason}
                showErrors={showReasonErrors}
                onChange={onChangeReasonFields}
              />
            </Column>
          </Columns>)}
      </ModalRemove>
      <Columns>
        <Column>
          <Link className="button is-success is-outlined is-pulled-right" to="/ordenes-pago/paqueteria/cotizar">
            <Icon>
              <FontAwesomeIcon icon={faPlus} />
            </Icon>
            <Trans render="span">Agregar paquetería</Trans>
          </Link>
        </Column>
      </Columns>
      <Table
        colsetup={shippingColumns}
        coldata={shippingOrders}
        tableClassName='table is-striped is-hoverable is-fullwidth'
        emptyTableMarkUp={<span>No hay registros</span>}
        customCol={{
          customColName: 'actions',
          renderFunc: customColActions
        }}
        initialFetch
        refresh={getShippingOrders}
      />
    </Box>
  )
}

const mapStateToProps = ({ users }) => ({
})

const mapDispatchToProps = dispatch => bindActionCreators({
  push: pushAction,
  addNotification: addNotificationAction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Index)