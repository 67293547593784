import React from 'react'
import { Link } from 'react-router-dom'
import { Nav, NavBrand, NavMenu, Button } from 'Components/common/bulma'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faSignOutAlt, faKey, faCog, faFolderOpen, faUser } from '@fortawesome/free-solid-svg-icons'
import FlagIconFactory from 'react-flag-icon-css'
import { Trans } from '@lingui/macro'
import classnames from 'classnames'
import { Can } from 'Containers/auth'
import UserIcon from '../../styles/images/user.png'

const FlagIcon = FlagIconFactory(React, { useCssModules: false })

export const Header = ({ isAuthenticated, logout, languages, changeLanguage, language, menu, toggleMenu, showMenu }) => {
  return (
    <Nav className="navbar is-black">
      <NavBrand>
        <Link className="nav-item logo" to="/">
        </Link>
        <Button role="button" className={classnames('navbar-burger is-text', { 'is-active': showMenu })} aria-label="menu" aria-expanded="false" onClick={toggleMenu}>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </Button>
      </NavBrand>
      <NavMenu className={classnames({ 'is-active': showMenu })}>
        <div className="navbar-start is-hidden-desktop">
          {menu.map((it, id) => <Link key={id} className="navbar-item" to={`/${it.module}`}>{it.label}</Link>)}
          <hr className="navbar-divider"></hr>
        </div>
        <div className="navbar-end">
          {isAuthenticated && (
            <div className="navbar-item is-hidden-mobile">
              <FontAwesomeIcon icon={faBell} />
            </div>
          )}
          <div className="navbar-item has-dropdown is-hoverable is-hidden-mobile">
            <Button className="navbar-link is-text">
              <span className="icon">
                <FlagIcon code={language.flag} />
              </span>
              <small className="margin-left-xs is-uppercase">[{language.code}]</small>
            </Button>
            <div className="navbar-dropdown is-right">
              {languages.map((it, idx) => (
                <Button key={idx} className="navbar-item is-text" onClick={() => changeLanguage(it)}>
                  <span className="icon">
                    <FlagIcon code={it.flag} />
                  </span>
                  <span className="margin-left-sm"><Trans id={it.label}></Trans></span>
                </Button>
              ))}
            </div>
          </div>
          {isAuthenticated && (
            <div className="navbar-item has-dropdown is-hoverable">
              <Button className="navbar-link is-hidden-mobile is-text">
                {/* <FontAwesomeIcon icon={faUser} /> */}
                <img alt="usuario" src={UserIcon} style={{ height: '19px', width: '19px' }} />
              </Button>
              <div className="navbar-dropdown  is-right">
                <Can partnerPermission={['usuarios']} validate={['Ausuario']}>
                  <Link to="/usuarios" className="navbar-item">
                    <span className="icon">
                      <FontAwesomeIcon icon={faUser} />
                    </span>
                    <span>{'  '} Usuarios</span>
                  </Link>
                </Can>
                <Can validate={['RepresentanteLegal']}>
                  <Link to="/integrado" className="navbar-item">
                    <span className="icon">
                      <FontAwesomeIcon icon={faCog} />
                    </span>
                    <span>{'  '} Perfil del integrado</span>
                  </Link>
                </Can>
                <Link to="/perfil/cambiar-contrasena" className="navbar-item">
                  <span className="icon">
                    <FontAwesomeIcon icon={faKey} />
                  </span>
                  <span>{'  '} Cambiar contraseña</span>
                </Link>
                <Link to="/integradora/archivos" className="navbar-item">
                  <span className="icon">
                    <FontAwesomeIcon icon={faFolderOpen} />
                  </span>
                  <span>{'  '} Documentos integradora</span>
                </Link>
                <Button className="navbar-item is-text" onClick={logout}>
                  <span className="icon">
                    <FontAwesomeIcon icon={faSignOutAlt} />
                  </span>
                  <span>{'  '} Salir</span>
                </Button>
              </div>
            </div>
          )}
        </div>
      </NavMenu>
    </Nav>
  )
}
