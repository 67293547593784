import RemoteCountryRepository from './hexmodules/country/infraestructure/remoteCountryRepository'
import RemoteStateRepository from './hexmodules/state/infraestructure/remoteStateRepository'
import RemoteOnboardingFileRepository from './hexmodules/onboardingFile/infraestructure/remoteOnboardingFileRepository'
import RemoteGeneralDataRepository from './hexmodules/generalData/infraestructure/remoteGeneralDataRepository'
import RemoteFiscalDataRepository from './hexmodules/fiscalData/infraestructure/remoteFiscalDataRepository'
import RemoteTaxRegimeRepository from './hexmodules/taxRegime/infraestructure/remoteTaxRegimeRepository'
import PersonalInfoRepository from './hexmodules/personalInfo/infraestructure/remotePersonalInfoRepository'
import BankAccountRepository from './hexmodules/bankAccount/infrastructure/remotebankAccountRepository'
import LegalRepresentativeRepository from './hexmodules/legalRepresentative/infrastructure/remoteLegalRepresentativeRepository'
import BeneficiariesRepository from './hexmodules/beneficiaries/infrastructure/remoteBeneficiariesRepository'
import RemoteLegalEntityRepository from './hexmodules/legalEntity/infrastructure/remotelegalEntityRepository'
import RemoteOnboardingFlowRepository from './hexmodules/onboardingFlow/infrastructure/remoteOnboardingFlowRepository'
import OnboardingReviewRepository from './hexmodules/onboardingReview/infrastructure/remoteOnboardingReviewRepository'
import ForeignFiscalDataRepository from './hexmodules/foreignFiscalData/infrastructure/remoteForeignFiscalDataRepository'
import ForeignPersonalInfoRepository from './hexmodules/foreignPersonalInfo/infrastructure/remoteForeignPersonalInfoRepository'
import ForeignBankAccountRepository from './hexmodules/foreignBankAccounts/infrastructure/remoteForeignBankAccountsRepository'
import ForeignLegalRepresentativeDataRepository from './hexmodules/foreignLegalRepresentativeData/infrastructure/remoteForeignLegalRepresentativeDataRepository'

export default {
  countryRepository: new RemoteCountryRepository(),
  stateRepository: new RemoteStateRepository(),
  onboardingFileRepository: new RemoteOnboardingFileRepository(),
  generalDataRepository: new RemoteGeneralDataRepository(),
  fiscalDataRepository: new RemoteFiscalDataRepository(),
  taxRegimeRepository: new RemoteTaxRegimeRepository(),
  personalInfoRepository: new PersonalInfoRepository(),
  bankAccountRepository: new BankAccountRepository(),
  legalRepresentativeRepository: new LegalRepresentativeRepository(),
  beneficiariesRepository: new BeneficiariesRepository(),
  legalEntityRepository: new RemoteLegalEntityRepository(),
  onboardingFlowRepository: new RemoteOnboardingFlowRepository(),
  onboardingReviewRepository: new OnboardingReviewRepository(),
  foreignFiscalDataRepository: new ForeignFiscalDataRepository(),
  foreignPersonalInfoRepository: new ForeignPersonalInfoRepository(),
  foreignBankAccountRepository: new ForeignBankAccountRepository(),
  foreignLegalRepresentativeDataRepository: new ForeignLegalRepresentativeDataRepository(),
}
