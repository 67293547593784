import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { validateToken } from 'Modules/auth'
import { TokenInvalido, CambiarContrasena, ContrasenaActualizada } from 'Components/auth'
import { validateData } from '../../helpers'
import { updatePassword } from 'Modules/auth'

export class ValidarToken extends Component {
  state = {
    tokenIsValid: undefined,
    data: {
      password: {
        value: '',
        validation: 'required|minLength:6'
      },
      passwordMatch: {
        value: '',
        validation: 'required|minLength:6|match:password'
      }
    },
    submitting: false,
  }
  componentDidMount() {
    const { token } = this.props.match.params
    validateToken(token).then(() => {
      this.setState({
        token,
        tokenIsValid: true
      })
    }).catch(() => {
      this.setState({
        tokenIsValid: false
      })
    })
  }
  onChangeInput = ({ target }) => {
    const { value, name } = target
    const { data } = this.state
    data[name].value = value
    this.setState({
      data,
      showErrors: false
    })
  }
  onChangePassword = () => {
    this.setState({submitting: true})
    const { password } = this.state.data
    const { data, hasErrors } = validateData(this.state.data)
    if (hasErrors) {
      this.setState({ data, showErrors: true, submitting: false })
      return
    }
    
    this.props.updatePassword({ password: password.value, token: this.state.token })
      .then(() => {
        this.setState({
          passwordUpdated: true
        })
      })
      .catch( () => {
        this.setState({
          submitError: true
        })
      })
      .finally(() => {
        this.setState({
          submitting: false
        })
      })

  }
  resetError = () => this.setState({submitError: false})

  render() {
    const { tokenIsValid, passwordUpdated } = this.state
    return (
      <Fragment>
        {tokenIsValid === false && (<TokenInvalido />)}
        {(tokenIsValid && !passwordUpdated) && (
          <CambiarContrasena
            data={this.state.data}
            onSubmit={this.onChangePassword}
            onChangeInput={this.onChangeInput}
            showErrors={this.state.showErrors}
            submitting={this.state.submitting}
            resetError={this.resetError}
            submitError={this.state.submitError}
          />)}
        {passwordUpdated && <ContrasenaActualizada isLogged={false} />}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = dispatch => bindActionCreators({
  updatePassword
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ValidarToken)
