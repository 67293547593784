import { cond, T, F, always, propOr } from 'ramda'
import { isEmpty, isNotEmail, isLength, isString } from 'App/helpers'

export default {
  tipoPersona: {
    input: {
      value: '',
      name: 'tipoPersona',
    },
    label: 'Tipo de persona',
    validation: 'required',
    valueProperty: 'id',
    valueLabel: 'tipoPersona',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  rfc: {
    input: {
      value: '',
      name: 'rfc',
    },
    label: 'RFC',
    validate: (value, key, form) => {
      const tipoPersona = propOr({}, 'tipoPersona')(form)

      if (+tipoPersona.input.value === 1) {
        return cond([
          [isEmpty, always({ error: '¡Este campo es requerido!' })],
          [isLength(12), always({ error: '¡Este campo requiere 12 caracteres!' })],
          [T, F]
        ])(value)
      } else if (+tipoPersona.input.value === 2){
        return cond([
          [isEmpty, always({ error: '¡Este campo es requerido!' })],
          [isLength(13), always({ error: '¡Este campo requiere 13 caracteres!' })],
          [T, F]
        ])(value)
      }
      return false
    },
  },
  numRegIdTrib: {
    input: {
      value: '',
      name: 'numRegIdTrib',
    },
    label: 'Tax Id',
    validate: (value, key, form) => {
      const tipoPersona = propOr({}, 'tipoPersona')(form)

      if ([4, 7].includes(+tipoPersona.input.value)) {
        return cond([
          [isEmpty, always({ error: '¡Este campo es requerido!' })],
          [T, F]
        ])(value)
      }
      return false
    },
  },
  nombre: {
    input: {
      value: '',
      name: 'nombre',
    },
    label: 'Nombres()',
    validation: 'required',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  paterno: {
    input: {
      value: '',
      name: 'paterno',
    },
    label: 'Apellido Paterno',
    validation: 'required',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  materno: {
    input: {
      value: '',
      name: 'materno',
    },
    label: 'Apellido Materno',
    validation: 'required',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
  correo: {
    input: {
      value: '',
      name: 'correo',
    },
    label: 'Correo Electrónico',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [isNotEmail, always({ error: '¡El valor debe ser un correo electrónico válido!' })],
      [T, F]
    ]),
  },
  telefono: {
    input: {
      value: '',
      name: 'telefono',
    },
    label: 'Teléfono',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [isString, always({ error: 'El formato de telefono es incorrecto' })],
      [isLength(10), always({ error: 'El telefono debe ser de 10 caracteres' })],
      [T, F]
    ]),
  },
  nombreORazonSocial: {
    input: {
      value: '',
      name: 'nombreORazonSocial',
    },
    label: 'Razón Social',
    validation: 'required',
    validate: (value, key, form) => {
      const tipoPersona = propOr({}, 'tipoPersona')(form)

      if ([1, 7].includes(+tipoPersona.input.value)) {
        return cond([
          [isEmpty, always({ error: '¡Este campo es requerido!' })],
          [T, F]
        ])(value)
      }
      return false
    },
  },
  terminosCondiciones: {
    input: {
      value: '',
      name: 'terminosCondiciones',
    },
    label: 'Acepto Términos y Condiciones',
    validation: 'required',
    validate: cond([
      [isEmpty, always({ error: 'Este campo es requerido' })],
      [T, F]
    ]),
  },
}