import React from 'react'
import numeral from 'numeral'
import { Link } from 'react-router-dom'
import { Can } from 'Containers/auth'

const Balance = ({ currency, real, disponible, porConciliar }) => {
  return (
    <div className="tile is-parent">
      <article className="tile is-child box">
        <p className="subtitle is-6 has-text-weight-bold">Saldo Real en {currency}
          <span className="is-primary is-pulled-right title is-4 has-text-primary">
            {numeral(real).format('$ 0,0.00')}
          </span>
        </p>
        <p className="subtitle is-6 has-text-weight-bold">Saldo Disponible
          <span className="is-primary is-pulled-right title is-4 has-text-primary">
            {numeral(disponible).format('$ 0,0.00')}</span></p>
        <p className="subtitle is-6 has-text-weight-bold">
          {!!porConciliar && <Link to={`/conciliaciones?moneda=${currency}`} className="has-text-primary">Saldo por Conciliar</Link>}
          {!!porConciliar || 'Saldo por Conciliar'}
          <span className="is-primary is-pulled-right title is-4 has-text-primary">
            {!!porConciliar || <span className="has-text-primary">{numeral(porConciliar).format('$ 0,0.00')}</span>}
            <Can validate={['Oconciliaicones']} component={<p></p>} >
              {!!porConciliar && <Link to={`/conciliaciones?moneda=${currency}`} className="has-text-primary">{numeral(porConciliar).format('$ 0,0.00')}</Link>}
            </Can>
          </span>
        </p>
      </article>
    </div>
  )
}

export default Balance