export default class BeneficiaryRepository {
  // Get a bank account by ID
  get() {
    // Implementation goes here
  }

  // Update a bank account
  update(beneficiaries) {
    // Implementation goes here
  }

  // Create a new bank account
  save(beneficiaries) {
    // Implementation goes here
  }

  // Delete a bank account by ID
  delete(id) {
    // Implementation goes here
  }
}