import { useRef, useCallback, useState } from 'react';
import { useDependencies } from 'App/DependencyContext';
import { getOnboardingReview } from 'App/hexmodules/onboardingReview/application/get/getOnboardingReview'
import { addNotification } from 'Modules/principal'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'


const STATE = {
  INITIAL: 'initial',
  FETCHING_ONBOARDING_REVIEW: 'fetchingOnboardingReview',
  FETCHED_ONBOARDING_REVIEW: 'fetchedOnboardingReview',
  ERROR_FETCHING_ONBOARDING_REVIEW: 'errorFetchingOnboardingReview',
  SAVING_ONBOARDING_REVIEW: 'savingOnboardingReview',
  SAVED_ONBOARDING_REVIEW: 'savedOnboardingReview',
  ERROR_SAVING_ONBOARDING_REVIEW: 'errorSavingOnboardingReview',
};


function useFinish() {
  const state = useRef(STATE.INITIAL);
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const { onboardingReviewRepository } = useDependencies()

  const sendOnboardingReview = async (idx) => {
    try {
      state.current = STATE.SAVING_ONBOARDING_REVIEW;
      setIsLoading(true)
      await getOnboardingReview(onboardingReviewRepository)
      state.current = STATE.SAVED_ONBOARDING_REVIEW;
      setIsLoading(false)
      dispatch(addNotification({
        type: 'success',
        message: 'Infomación enviada a revisión correctamente',
      }))

      setTimeout(() => {
        dispatch(push('/'))
        window.location.reload();
      }, 2000)
    } catch ({ response: { data } }) {
      setIsLoading(false)
      dispatch(addNotification({
        type: 'danger',
        message: data?.error,
      }))
    }

  };


  const getCurrentState = useCallback(() => {
    return state.current;
  }, []);


  return {
    getCurrentState,
    sendOnboardingReview,
    isLoading,
  };
}


export default useFinish;
