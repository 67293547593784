import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor/dist/i18n/es-es';
import { push } from 'connected-react-router'
import { Box, Column, Columns, Button } from 'Components/common/bulma'
import { ActionBar } from 'Components/common'
import { getWayToPayment, saveWidget } from 'Modules/widget'
import { addNotification, getNoInterestConfiguration } from 'Modules/principal'
import { Check, Input, Radio } from 'Components/common/form'
import Product from 'Components/widget/Product'
import { conceptsCol, conceptData, paymentList } from './constants'
import { filter, equals, map, keys, join, propEq, find, reject } from 'ramda'
import {
  getInvoiceUnits,
  clearInvoiceUnits,
  getIvaRates,
  getIepsRates,
  getProductService,
  clearProductService,
} from 'Modules/catalogos'
import { Icon } from 'Components/common/bulma'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import httpService from 'Services/httpService'

import { getDataInput } from 'App/helpers'

const permissions = {
  canSave: true,
  canDelete: true,
  canAuthorize: true,
}
const mapWayToPayment = map((it) => {
  return { ...it, disabled: false }
});

export default function ConfigurationWidget() {
  const [selectedPaymentWays, selectPaymentWay] = useState({})
  const [wayToPayments, setWayToPayments] = useState([])
  const [currentStep, setCurrentStep] = useState(0)
  const [widgetConfiguration, setWidgetConfiguration] = useState({
    currency: '',
    generateInvoiceOrder: '0',
    amount: '',
    interval: 'month',
    intervalCount: 1,
  })

  const [concepts, setConcepts] = useState([])
  const [selectedNoInterest, setSelectedNoInterest] = useState([])
  const [filteredNoInterest, setFilteredNoInterest] = useState([])

  const [imagePreview, setImagePreview] = useState(null);
  const editorRef = useRef();
  const [content, setContent] = useState('');
  const maxCharacters = 500; // Límite de caracteres
  const [isUpdating, setIsUpdating] = useState(false);
  const [image, setImage] = useState(null)// Bandera para controlar la actualización
  const [imageResponse, setImageResponse] = useState(null)// Bandera para controlar la actualización


  const handleChangeEditor = () => {
    if (isUpdating) {
      // Si se está actualizando, no manejar el evento
      setIsUpdating(false);
      return;
    }
    const editorInstance = editorRef.current.getInstance();
    const editorContent = editorInstance.getMarkdown();

    if (editorContent.length <= maxCharacters) {
      setContent(editorContent);
    } else {
      // Establece el contenido del editor al último contenido válido
      setIsUpdating(true);
      editorInstance.setMarkdown(content);
    }
  };



  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadImage = async () => {
    if (!image) {
      return
    }
    const data = new FormData()
    data.append('file', image, image.name)

    const response = await httpService.post('/widget/upload-logo', data)
    setImageResponse(response)
  }

  const addConcept = concept => {
    const tempConcepts = concepts.concat([concept])
    const conceptAmount = tempConcepts.reduce((prev, cur) => { return cur.total + prev }, 0)

    setConcepts(tempConcepts)
    setWidgetConfiguration({
      ...widgetConfiguration,
      amount: conceptAmount
    })
  }

  const dispatch = useDispatch()
  const onCancel = () => {
    dispatch(push('/widget'))
  }
  const { wayToPayment, widget } = useSelector(state => state.widget, shallowEqual)
  const { invoiceUnits, ivaRates, iepsRates, productService } = useSelector(state => state.catalogos, shallowEqual)
  const { noInterest } = useSelector(state => state.principal, shallowEqual)

  useEffect(() => {
    if (!wayToPayment.length) {
      dispatch(getWayToPayment())
    }
    dispatch(getIvaRates())
    dispatch(getIepsRates())
    dispatch(getNoInterestConfiguration())
  }, [dispatch, wayToPayment])


  useEffect(() => {
    if (wayToPayment.length) {
      setWayToPayments(mapWayToPayment(wayToPayment));
    }
  }, [wayToPayment])

  useEffect(() => {
    const filtered = noInterest.filter(it => it.plazo !== 'C').map(it => ({ ...it, allow: true }))
    setFilteredNoInterest(filtered)
  }, [noInterest])

  const disablePayment = (paymentWay, paymentWays) => {

    return map((it) => {
      if (paymentWay.includes(+it.id)) {
        return { ...it, disabled: true }
      } else {
        return { ...it, disabled: false }
      }
    })(paymentWays)
  }

  const handleChange = ({ target: { checked, name } }) => {
    const tempSelectPaymentWays = { ...selectedPaymentWays, [name]: checked }
    const filteredWayToPayment = filter(equals(true), tempSelectPaymentWays)
    const keysWayToPayment = Object.keys(filteredWayToPayment)

    if (+name < 5) {
      setWayToPayments(disablePayment([5, 9], wayToPayment))
    }
    if (+name === 5) {
      setWayToPayments(disablePayment([1, 2, 3, 4, 9, 10], wayToPayment))
    }
    if (+name === 8) {
      setWayToPayments(disablePayment([9], wayToPayment))
    }
    if (+name === 10) {
      setWayToPayments(disablePayment([9, 5], wayToPayment))
    }
    if (+name === 9) {
      setWayToPayments(disablePayment([1, 2, 3, 4, 5, 6, 7, 8, 10], wayToPayment))
    }
    if (!keysWayToPayment.length) {
      setWayToPayments(disablePayment([], wayToPayment))
    }

    selectPaymentWay(tempSelectPaymentWays)

  }

  const hadleCurrentStep = step => {
    if (step === 1) {
      const filteredWayToPayment = filter(equals(true), selectedPaymentWays)
      const wayToPayment = Object.keys(filteredWayToPayment)

      if (wayToPayment.length === 0) {
        return
      }
    }
    if (step === 2) {
      const editorInstance = editorRef.current.getInstance();
      const content = editorInstance.getMarkdown();
      console.log(content);
      if (!widgetConfiguration.currency) {
        return
      }
    }

    if (step === 4) {
      if (+widgetConfiguration.amount <= 0
        && +widgetConfiguration.generateInvoiceOrder === 0) {
        return
      }
      const conceptAmount = concepts.reduce((prev, cur) => { return cur.total + prev }, 0)
      if (+widgetConfiguration.generateInvoiceOrder === 1) {
        if (conceptAmount <= 0) {
          return
        }
      }

      const propFirst = propEq('plazo', '3')
      const minForNoInterest = find(propFirst)(noInterest)

      const orderMinimun = +widgetConfiguration.generateInvoiceOrder === 1 && +minForNoInterest.montoMinimo > +conceptAmount
      const noOrderMinimun = +widgetConfiguration.generateInvoiceOrder !== 1 && +minForNoInterest.montoMinimo > +widgetConfiguration.amount

      if (!selectedPaymentWays['1'] || noOrderMinimun
        || orderMinimun
      ) {
        hadleCurrentStep(5)
        return
      }
    }
    if (step === 5) {
      handleOnSaveWidget()
    }
    setCurrentStep(step)
  }

  const handleOnSaveWidget = () => {
    const filteredWayToPayment = filter(equals(true), selectedPaymentWays)
    const wayToPayment = Object.keys(filteredWayToPayment).join(',')
    const selected = filter(equals(true))(selectedNoInterest)
    const promotions = keys(selected)
    console.log(imageResponse)

    dispatch(saveWidget({
      ...widgetConfiguration,
      wayToPayment,
      concepts,
      promotions: join(',')(promotions),
      instruccion: content,
      logo: imageResponse?.data?.url
    }))
  }

  const handleInput = ({ target: { value, name } }) => {
    let inputValue = value
    if (name === 'amount') {
      const inputData = getDataInput(
        { name: 'amount', value },
        {
          amount: {
            name: 'amount',
            pattern: 'number',
            value: widgetConfiguration.amount
          }
        })
      inputValue = inputData.amount.value
    }

    setWidgetConfiguration({
      ...widgetConfiguration,
      [name]: inputValue
    })
  }

  const showMXN = useCallback(
    () => {
      const filteredWayToPayment = filter(equals(true), selectedPaymentWays)
      const wayToPayment = Object.keys(filteredWayToPayment)

      return !wayToPayment.includes('5')
    },
    [selectedPaymentWays],
  )

  const showUSD = useCallback(
    () => {
      const filteredWayToPayment = filter(equals(true), selectedPaymentWays)
      const wayToPayment = Object.keys(filteredWayToPayment)

      const includeNotAllowed = ['1', '2', '3', '4', '10'].some(it => {
        return wayToPayment.includes(it)
      })

      return (wayToPayment.includes('5') || wayToPayment.includes('8') || wayToPayment.includes('9')) && !includeNotAllowed
    },
    [selectedPaymentWays],
  )

  const handleNoInterest = ({ target: { name, checked } }) => {
    setSelectedNoInterest({ ...selectedNoInterest, [name]: checked })
  }

  const onRemoveConcept = (id) => {
    const tempConcepts = reject(propEq('id', id))(concepts)
    const conceptAmount = tempConcepts.reduce((prev, cur) => { return cur.total + prev }, 0)

    setConcepts(tempConcepts)
    setWidgetConfiguration({
      ...widgetConfiguration,
      amount: conceptAmount
    })
  }

  return (
    <Box>
      <Columns>
        <Column className="is-offset-half is-half">
          <ActionBar permissions={permissions} onCancel={onCancel} />
        </Column>
      </Columns>
      {currentStep === 0 && (<Box>
        <Columns>
          <Column className="is-three-quarters is-size-5">
            {wayToPayments.map(it => <Box key={it.id}>
              <Check
                name={it.id}
                showFieldLabel={false}
                className="is-info is-large"
                label={paymentList[`${it.id}`]?.name}
                value={selectedPaymentWays[it.id]}
                onChange={handleChange}
                disabled={it.disabled}
              />
              <p style={{ paddingLeft: '47px' }}>{paymentList[`${it.id}`]?.subtitle}</p>
              <p style={{ paddingLeft: '47px' }} className="is-size-7"><small>{paymentList[`${it.id}`]?.nota}</small></p>
            </Box>)}
          </Column>
          <Column className="is-size-4 is-vcentered" style={{ minHeight: '540px', fontFamily: "Indie Flower", display: 'flex' }}>
            <div
              style={{ alignSelf: 'center' }}>Seleccione las formas de pago</div>
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Button
              onClick={() => hadleCurrentStep(1)}
              className="is-large is-info is-outlined is-pulled-right">
              Siguiente
            </Button>
          </Column>
        </Columns>
      </Box>)}
      {currentStep === 1 && (
        <Columns>
          <Column className="is-half is-offset-2">
            <Box>
              <Columns>
                <Column className="is-size-3 is-full" style={{
                  fontFamily: "Indie Flower",
                }}>
                  <div className="field">
                    <div className="label">
                      Seleccione la moneda:
                    </div>
                    <div className="control">
                      <div className="select" >
                        <select value={widgetConfiguration.currency} name="currency" onChange={handleInput}>
                          <option value="">Seleccione moneda</option>
                          {showMXN() && <option value="1">MXN</option>}
                          {showUSD() && <option value="2">USD</option>}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <div className="label">
                      Seleccione una imagen que desee mostrar en el widget (opcional):
                    </div>
                  </div>
                  <div>

                    <label htmlFor="image-upload" style={{ border: '2px dashed #ccc', display: 'inline-block', padding: '10px', cursor: 'pointer', fontSize: '16px', minHeight: '100px' }}>
                      {imagePreview ? (
                        <img src={imagePreview} alt="preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                      ) : (
                        'Haga clic o arrastre para seleccionar una imagen'
                      )}
                    </label>
                    <input
                      id="image-upload"
                      type="file"
                      accept="image/png, image/jpeg, image/jpg"
                      onChange={handleImageChange}
                      style={{ display: 'none' }}
                    />
                  </div>
                  <div className="mt-1">
                    <Button className="is-small" onClick={uploadImage}>
                      {imageResponse ? 'Imagen cargada exitosamente  ' : 'Cargar imagen'}
                      {imageResponse && <Icon style={{ marginLeft: '6px' }}>
                        <FontAwesomeIcon icon={faCheck} />
                      </Icon>}
                    </Button>
                  </div>

                  <div className="field">
                    <div className="label">
                      Escriba las instrucciones que desee que se muestre en el widget (opcional):
                    </div>
                  </div>
                  <div>
                    <Editor
                      ref={editorRef}
                      initialValue=""
                      height="400px"
                      initialEditType="wysiwyg"
                      useCommandShortcut={true}
                      width="100%"
                      hideModeSwitch={true}
                      language="es"
                      onChange={handleChangeEditor}
                    />
                  </div>
                  {widgetConfiguration.currency === '2' && <Input
                    name="changeType"
                    label="Tipo de cambio:"
                    value={widgetConfiguration.changeType}
                    onChange={handleInput}
                  />}
                </Column>
              </Columns>
              <Columns>
                <Column>
                  <Button
                    onClick={() => hadleCurrentStep(2)}
                    className="is-info is-outlined is-pulled-right">
                    Siguiente
                  </Button>
                </Column>
              </Columns>
            </Box>
          </Column>
        </Columns>)}
      {currentStep === 2 && (<Box>
        <Columns>
          <Column className="is-size-1 is-vcentered" style={{
            minHeight: '540px', fontFamily: "Indie Flower",
            display: 'flex', alignItems: 'center',
            justifyContent: 'center', flexDirection: 'column'
          }}>
            <div>Generar Orden de Ingreso Factura</div>
            <Radio
              key="true"
              name="generateInvoiceOrder"
              className="is-info is-large"
              label="Si"
              radioValue="1"
              value={`${widgetConfiguration.generateInvoiceOrder}`}
              onChange={handleInput}
              type="radio"
            />
            {!selectedPaymentWays['9'] && <Radio
              key="false"
              name="generateInvoiceOrder"
              className="is-info is-large"
              label="No"
              radioValue="0"
              value={`${widgetConfiguration.generateInvoiceOrder}`}
              onChange={handleInput}
              type="radio"
            />}
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Button
              onClick={() => hadleCurrentStep(3)}
              className="is-large is-info is-outlined is-pulled-right">
              Continuar
            </Button>
          </Column>
        </Columns>
      </Box>)}
      {currentStep === 3 && <Box>
        <Product
          conceptData={conceptData}
          getInvoiceUnits={(query) => dispatch(getInvoiceUnits(query))}
          generateOrder={widgetConfiguration.generateInvoiceOrder}
          widgetConfiguration={widgetConfiguration}
          setWidgetConfiguration={handleInput}
          conceptsCol={conceptsCol}
          ivaRates={ivaRates}
          iepsRates={iepsRates}
          units={invoiceUnits}
          clearUnits={() => dispatch(clearInvoiceUnits())}
          getProductService={q => dispatch(getProductService(q))}
          clearProductService={() => dispatch(clearProductService())}
          productService={productService}
          concepts={concepts}
          addConcept={addConcept}
          addNotification={notification => dispatch(addNotification(notification))}
          oneConcept={!!selectedPaymentWays['9']}
          selectedPaymentWays={selectedPaymentWays}
          onRemoveConcept={onRemoveConcept}
        />
        <Columns>
          <Column>
            <div className="is-pulled-right">
              <Button
                onClick={() => !!selectedPaymentWays['9'] ? hadleCurrentStep(4.1) : hadleCurrentStep(4)}
                className="is-large is-info is-outlined"
                disabled={+widgetConfiguration.amount > 10000 && !!selectedPaymentWays['10']}
              >
                Continuar
              </Button>
            </div>
          </Column>
        </Columns>
      </Box>
      }
      {
        currentStep === 4 &&
        <Box>
          <h1 className="title is-3">¡Meses sin intereses disponibles!</h1>
          Los meses sin intereses aplica para MIT-Santander, aplican sobretasas y solo para tarjetahabientes de bancos participantes en México.
          {filteredNoInterest.map(it => <Check
            onChange={handleNoInterest}
            name={it.plazo}
            label={`${it.plazo} meses sin intereses`}
            showFieldLabel={false}
            value={selectedNoInterest[it.plazo]}
            disabled={!(+widgetConfiguration.amount >= it.montoMinimo &&
              +widgetConfiguration.amount <= it.montoMaximo)
            }
          />
          )}

          <Columns>
            <Column>
              <div className="is-pulled-right">
                <Button
                  onClick={() => hadleCurrentStep(5)}
                  className="is-large is-info is-outlined">
                  Continuar
                </Button>
              </div>
            </Column>
          </Columns>
        </Box>
      }
      {
        currentStep === 4.1 &&
        <Box>
          <div className="control has-text-centered">
            <h1 className="title is-3">¡Seleccione el intervalo!</h1>
            <div className="select is-large" >
              <select value={widgetConfiguration.interval} name="interval" onChange={handleInput}>
                <option value="">Seleccione el intervalo</option>
                <option value="month">Mes</option>
                <option value="year">Año</option>
              </select>
            </div>
          </div>
          {widgetConfiguration.interval === 'month' && <div className="control has-text-centered">
            <h1 className="title is-3">¡Seleccione la frecuencia de cobro!</h1>
            <div className="select is-large" >
              <select value={widgetConfiguration.intervalCount} name="intervalCount" onChange={handleInput}>
                <option value="">Seleccione la frecuencia</option>
                <option value="1">Cada mes</option>
                <option value="3">Cada 3 meses</option>
                <option value="6">Cada 6 meses</option>
              </select>
            </div>
          </div>}
          <Columns>
            <Column>
              <div className="is-pulled-right">
                <Button
                  onClick={() => hadleCurrentStep(5)}
                  className="is-large is-info is-outlined">
                  Continuar
                </Button>
              </div>
            </Column>
          </Columns>
        </Box>
      }
      {currentStep === 5 && (<Box>
        <Columns>
          <Column className="is-size-5 is-vcentered" style={{
            minHeight: '540px',
            display: 'flex', alignItems: 'center',
            justifyContent: 'center', flexDirection: 'column'
          }}>
            <div>Widget</div>
            <div className="notification">
              <strong>Favor de insertar los siguientes scripts antes de la etiqueta {'</body>'}</strong>
              <pre>
                {`<script src="${window.location.origin}/manejatunegocio.js"></script>`}
              </pre>
              <pre>
                {'<script>'}
                <br />
                {`ManejaTuNegocio.createButton({
                      containerId: 'payment-button',
                      key: '${widget.url ? widget.url : '... generando token...'}',
                      label: 'Pagar'
                    })`}
                <br />
                {'</script>'}
              </pre>
            </div>
          </Column>
        </Columns>
      </Box>)}
    </Box>
  )
}
