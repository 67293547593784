import React from 'react';
import { Box, Columns, Column, Button } from 'Components/common/bulma'
import File from 'Components/common/form/FileWithErrors'
import InputWithError from 'Components/common/form/InputWithError'
import useBankAccount from './useBankAccounts';
import Loader from 'Components/common/page/loaderFloat';
import Show from 'Components/common/Show';


const BankAccount = ({ refresh }) => {
  const { values, onChangeField, saveBankAccount, onRemove, addAccount, showErrors, isLoading } = useBankAccount({ refresh })

  return (
    <Box style={{ position: 'relative' }}>
      <Show canShow={isLoading}>
        <Loader />
      </Show>
      <Columns className="is-multiline">
        <Column className="is-half is-offset-half has-text-right">
          <Button className="is-primary" onClick={addAccount}>Agregar cuenta</Button>
        </Column>
      </Columns>
      {values.map((item, idx) => {
        return (
          <Box key={idx}>
            <Columns className="is-multiline">
              <Column className="is-half">
                <InputWithError
                  onChange={onChangeField}
                  showErrors={showErrors}
                  {...{ ...item.clabe, input: { ...item.clabe.input, id: idx } }}
                />
              </Column>
              <Column className="is-half">
                <InputWithError
                  onChange={onChangeField}
                  showErrors={showErrors}
                  {...{ ...item.numeroSucursal, input: { ...item.numeroSucursal.input, id: idx } }}
                />
              </Column>
              <Column className="is-half">
                <File
                  onChange={onChangeField}
                  showErrors={showErrors}
                  {...{ ...item.accountStatement, input: { ...item.accountStatement.input, id: idx } }}
                />
              </Column>
              <Column className="is-full has-text-right">
                <Button className="is-primary mr-4" onClick={() => saveBankAccount(idx)}>Guardar</Button>
                <Button className="is-warning" onClick={() => onRemove(item)}>Eliminar Cuenta</Button>
              </Column>
            </Columns>
          </Box>
        )
      })}
    </Box>
  );
};

export default BankAccount;
