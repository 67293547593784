import PersonalInfoRepository from "../domain/personalInfoRepository";
import httpService from '../../../services/httpService';


export default class RemotePersonalInfoRepository extends PersonalInfoRepository {
  async get() {
    const { data } = await httpService.get('onboarding/personal-data')
    return data.data
  }
  async save(personalInfo) {
    return await httpService.post('onboarding/personal-data', personalInfo)
  }

  async update(personalInfo) {
    return await httpService.put('onboarding/personal-data', personalInfo)
  }
}


