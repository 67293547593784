import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {
  getProfile,
  getDocuments,
  getInventoryPermission,
} from 'Modules/principal'
import {
  getEstatusPersona,
} from 'Modules/catalogos'
import { getBreadCrumb } from '../util'
import Notificaciones from 'Components/principal/notificaciones'
import classnames from 'classnames'
import { Columns, Column } from 'Components/common/bulma'

export class Principal extends Component {
  state = {
    currentModule: [],
  }
  componentDidMount() {
    this.props.getProfile()
    this.props.getEstatusPersona()
    this.props.getDocuments()
    this.props.getInventoryPermission()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentModule = getBreadCrumb(nextProps.path, nextProps.menu)
    this.setState({ currentModule })

  }

  cerrarNotificacion = (uuid) => {
    this.props.cerrarNotificacion(uuid)
  }

  render() {
    const { nombre, nombreIntegrado, idIntegrado } = this.props.profile
    const { currentModule } = this.state
    return (
      <Fragment>
        <Columns>
          <Column>
            <nav className="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
              <ul>
                <li><Link to="/" className="margin-left-sm">Inicio</Link></li>
                {currentModule.map((it, idx) => {
                  return (<li key={idx} className={classnames({ 'is-active': it.isLast })}><Link to={`${it.route}`} aria-current="page">{it.label}</Link></li>)
                })}
              </ul>
            </nav>
          </Column>
          <Column>
            <div className="is-pulled-right has-text-left">
              <strong>#{idIntegrado} - {nombreIntegrado}</strong><br />
              <span className="is-pulled-right"> {nombre} </span>
            </div>
          </Column>
        </Columns>
        <Notificaciones notifications={this.props.notifications}
          cerrarNotificacion={this.cerrarNotificacion} />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ principal, router }) => {
  const { profile, notifications, menu } = principal
  return {
    profile,
    notifications,
    path: router.location.pathname,
    menu,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getProfile,
  getEstatusPersona,
  // getPartnerProfile,
  getDocuments,
  getInventoryPermission,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Principal)
