import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { Box, Icon, Columns, Column } from 'Components/common/bulma'
import Table from 'Components/common/table'
import { getAuthorizeList as getList } from 'Modules/loanOrders/creditor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { authorizeLoanOrders } from './colSetup'
import { Button } from 'Components/common/bulma'
import { addNotification } from 'Modules/principal'
import { setOrigin } from 'Modules/loanOrders/creditor'
import { equals, cond } from 'ramda'

export class ListaContainer extends Component {
  state = {
    showModal: false,
    order: {},
  }

  onEdit = ({ id, tipoPrestamo }) => {
    const { push, setOrigin } = this.props;

    const isCreditor = equals('Quiero Prestar (Acreedor)');
    const idDebtor = equals('He Recibido un Préstamo (Deudor)');
    const paymentLoan = equals('Pago Préstamo');
    const collectLoan = equals('Cobro Préstamo');

    setOrigin('authorize');

    cond([
      [isCreditor, () => push(`/ordenes-prestamo/acreedoras/${id}`)],
      [idDebtor, () => push(`/ordenes-prestamo/deudoras/${id}`)],
      [paymentLoan, () => push(`/ordenes-prestamo/amortizacion-pago/${id}`)],
      [collectLoan, () => push(`/ordenes-prestamo/amortizacion-cobro/${id}`)],
    ])(tipoPrestamo);
  };

  toggleModal = (order) => {
    this.setState(state => ({
      showModal: !state.showModal,
      order,
    }))
  };

  customColAccions = row => (
    <Fragment >
      <Button small className="margin-left-xs" primary outlined onClick={() => this.onEdit(row)}>
        <Icon>
          <FontAwesomeIcon icon={faEdit} />
        </Icon>
      </Button>
    </Fragment>
  );

  render() {
    const { list } = this.props

    return (
      <Box>
        <Columns>
          <Column>
            <Table
              filterable
              colsetup={authorizeLoanOrders}
              coldata={list}
              tableClassName='table is-striped is-hoverable is-fullwidth'
              emptyTableMarkUp={<span>No se encontraron registros</span>}
              customCol={{
                customColName: 'actions',
                renderFunc: this.customColAccions
              }}
              refresh={this.props.getList}
            />
          </Column>
        </Columns>

      </Box>
    )
  }
}

const mapStateToProps = ({ loanOrders }) => ({
  list: loanOrders.creditor.authorizeList,
  isFetching: loanOrders.creditor.isFetchingCreditor
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getList,
  push,
  addNotification,
  setOrigin,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ListaContainer)
