import moment from 'moment'
import numeral from 'numeral'

const limitHour = moment({ hour: 16, minute: 5 })
const minDate = moment() < limitHour ? moment({ hour: 0 }) : moment({ hour: 0 }).add(1, 'day')

export const generalData = {
  cliente: {
    name: 'cliente',
    label: 'Cliente',
    valueProperty: 'id',
    valueLabel: 'nombreORazonSocial',
    validation: 'required',
    trigger: 'getClient',
  },
  id: {
    name: 'id',
    label: 'Id',
    disabled: true,
  },
  estatusOrden: {
    value: '',
    name: 'estatusOrden',
    label: 'Estatus Orden',
    valueProperty: 'id',
    valueLabel: 'estatus',
    disabled: true,
  },
  fechaProgramacionEmisionFactura: {
    name: 'fechaProgramacionEmisionFactura',
    value: minDate.toDate(),
    minDate: minDate.toDate(),
    label: 'Fecha emisión Factura',
    validation: 'required',
    format: val => console.log(val),
    dataMarshaller: (value) => moment(value).format('YYYY-MM-DD'),
    dataUnmarshaller: val => moment(val).toDate(),
  },
  tipoDePersona: {
    value: '',
    trigger: 'onChangeTipoPersona',
    transform: 'stringToNumber',
    name: 'tipoPersona',
    label: 'Tipo de persona',
  },
  rfc: {
    value: '',
    name: 'rfc',
    label: 'RFC',
  },
  correoElectronico: {
    value: '',
    name: 'correoElectronico',
    label: 'Correo Electrónico',
  },
  nombreORazonSocial: {
    value: '',
    name: 'nombreRazonSocial',
    label: 'Nombre o Razón Social',
  },
  numRegIdTrib: {
    value: '',
    name: 'numRegIdTrib',
    label: 'Tax Id Number (Sólo para extranjeros)',
    disabled: true,
  },
  formaPago: {
    name: 'formaPago',
    label: 'Método de Pago',
    value: '',
    validation: 'required',
    options: [
      {
        label: 'Pago en Parcialidades',
        value: 'Pago en Parcialidades'
      },
      {
        label: 'Pago en Una sola Exhibición',
        value: 'Pago en Una sola Exhibición'
      },
    ],
    trigger: 'onChangePaymentMethod',
  },
  metodoPago: {
    name: 'metodoPago',
    label: 'Forma de Pago',
    value: '',
    valueProperty: 'id',
    valueLabel: 'metodoPago',
    validation: 'required',
  },
  ultimos4Digitos: {
    value: '',
    name: 'ultimos4Digitos',
    label: 'Últimos 4 dígitos Cuenta Bancaria',
    pattern: 'number',
  },
  UsoCFDISAT: {
    value: '',
    name: 'UsoCFDISAT',
    label: 'Uso CFDI SAT',
    validation: 'required',
    valueLabel: 'descripcion',
    valueProperty: 'id',
  },
  divisaMoneda: {
    value: '',
    name: 'divisaMoneda',
    label: 'Divisa Moneda',
    validation: 'required',
    valueLabel: 'codigo',
    valueProperty: 'codigo',
    trigger: 'onChangeCurrency',
  },
  tipoCambio: {
    value: '',
    name: 'tipoCambio',
    label: 'Tipo de Cambio',
    validation: 'required',
  },
  cuentaCobranza: {
    value: '',
    name: 'cuentaCobranza',
    label: 'Cuenta para Cobranza',
    validation: 'required',
    options: [
      {
        label: 'MXN',
        value: 'MXN'
      },
      {
        label: 'USD',
        value: 'USD'
      }
    ]
  },
  tipoRelacion: {
    value: '',
    name: 'tipoRelacion',
    label: 'Tipo de Relación',
    valueProperty: 'id',
    valueLabel: 'Descripcion',
    trigger: 'onChangeRelationshipType',
    disabled: true,
  },
  uuidRelacionado: {
    value: '',
    name: 'uuidRelacionado',
    label: 'UUID Relacionado',
    valueProperty: 'uuid',
    valueLabel: 'label',
    validation: 'requiredIf:tipoRelacion',
    disabled: true,
  },
  cuentaClabeSTPAsignada: {
    name: 'cuentaClabeSTPAsignada',
    value: 'cuentaClabeSTPAsignada',
  },

}
export const incomeInvoiceList =
  [
    {
      title: 'ID',
      datakey: 'id',
      sortable: true,
      width: '10%',
    },
    {
      title: 'Estatus Orden',
      datakey: 'estatusTexto',
      sortable: true,
      width: '20%',
    },
    {
      title: 'Nombre o Razón Social',
      datakey: 'nombreORazonSocial',
      sortable: true,
      width: '40%',
    },
    {
      title: 'Total',
      datakey: 'Total',
      format: '$ 0,0.00',
      sortable: true,
      width: '20%',
    },
    {
      title: 'Acciones',
      custom: 'actions',
      width: '10%',
    },
  ]

export const addressData = {
  direccion: {
    value: '',
    transform: 'stringToBool',
    trigger: 'onChangeDireccion',
    name: 'direccion',
    label: 'Dirección',

  },
  pais: {
    name: 'pais',
    value: '',
    validation: 'requiredIf:direccion',
    label: 'País',

  },
  codigoPostal: {
    value: '',
    trigger: 'onChangeCp',
    pattern: 'number',
    name: 'codigoPostal',
    label: 'Código postal',

  },
  entidadFederativa: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'entidadFederativa',
    label: 'Entidad Federativa/Estado/Provincia',

  },
  municipio: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'municipio',
    label: 'Municipio',

  },
  colonia: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'colonia',
    label: 'Colonia',

  },
  calle: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'calle',
    label: 'Calle',

  },
  exterior: {
    value: '',
    validation: 'requiredIf:direccion',
    name: 'exterior',
    label: 'Exterior',

  },
  interior: {
    value: '',
    name: 'interior',
    label: 'Interior',

  },
}
export const conceptData = {
  idConcepto: {
    name: 'idConcepto',
    value: '',
  },
  concepto: {
    name: 'concepto',
    label: 'Concepto',
    value: '',
    validation: 'required',
  },
  unidad: {
    name: 'unidad',
    label: 'Unidad',
    value: '',
    validation: 'required',
    placeholder: 'Buscar unidad...',
  },
  unitario: {
    name: 'unitario',
    label: 'Unitario',
    value: '',
    validation: 'required',
    pattern: 'number',
    trigger: 'updateCalculations',
  },
  total: {
    name: 'total',
    label: 'Total',
    value: '',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  iva: {
    name: 'iva',
    label: 'IVA',
    value: '',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  ieps: {
    name: 'ieps',
    label: 'IEPS',
    value: '',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  subtotal: {
    name: 'subtotal',
    label: 'Subtotal',
    value: '',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  cantidad: {
    name: 'cantidad',
    label: 'Cantidad',
    pattern: 'number',
    value: '',
    validation: 'required',
    trigger: 'updateCalculations',
    disabled: true,
  },
  tasaIEPS: {
    name: 'tasaIEPS',
    label: 'Tasa IEPS',
    value: '',
    // validation: 'required',
    valueProperty: 'id',
    valueLabel: 'tipo',
    trigger: 'updateCalculations',
    dataUnmarshaller: val => {
      console.log(val)
      return +val
    },
  },
  tasaIVA: {
    name: 'tasaIVA',
    label: 'Tasa IVA',
    value: '',
    validation: 'required',
    valueProperty: 'id',
    valueLabel: 'tipo',
    trigger: 'updateCalculations',
    dataUnmarshaller: val => {
      console.log(val)
      return +val
    },
  },
  tipoCalculo: {
    name: 'tipoCalculo',
    label: 'Tipo de Cálculo',
    value: 1,
    validation: 'required',
    transform: 'stringToNumber',
    trigger: 'updateCalculations',
    options: [
      {
        value: 1,
        label: 'El IVA Grava sobre Subtotal'
      },
      {
        value: 2,
        label: 'El IVA Grava sobre Subtotal + IEPS'
      },
    ],
  },
  claveProductoServicioSAT: {
    name: 'claveProductoServicioSAT',
    label: 'Clave Producto Servicio',
    value: '',
    validation: 'required',
    valueProperty: 'id',
    valueLabel: 'tipo',
  },
  identificador: {
    name: 'identificador',
    label: 'Identificador',
    maxLength: 11,
    value: '',
  },
  query: {
    name: 'query',
    label: 'Buscar producto o servicio por concepto o identificador',
    value: '',
  },
  idProductoServicio: {
    name: 'idProductoServicio',
    value: '',
  },
}
export const conceptsCol = [
  {
    title: 'Concepto',
    datakey: 'concepto',
  },
  {
    title: 'Subtotal',
    datakey: 'subtotal',
    format: '$ 0,0.00',
  },
  {
    title: 'IVA',
    datakey: 'iva',
    format: '$ 0,0.00',
  },
  {
    title: 'Total',
    datakey: 'total',
    format: '$ 0,0.00',
  },
  {
    title: 'Acciones',
    custom: 'acciones',
  }
]
export const paymentCol = [
  {
    title: 'Id',
    datakey: 'id',
  },
  {
    title: 'Fecha de transacción',
    datakey: 'fechaTransaccion',
    format: (value) => moment(value).format('DD/MM/YYYY')
  },
  {
    title: 'Estatus',
    datakey: 'estatusTexto',
  },
  {
    title: 'Abono',
    datakey: 'abonos',
    format: '$ 0,0.00',
  },
  {
    title: 'Moneda',
    datakey: 'monedaDivisa',
  },
  {
    title: 'Referencia del banco',
    datakey: 'referenciaBanco',
  },
]
export const complementaryPaymentCol = [
  {
    title: 'Fecha Uiid',
    datakey: 'FechaUUID',
    format: (value) => moment(value).format('DD/MM/YYYY'),
  },
  {
    title: 'Uuid',
    datakey: 'uuid',
  },
  {
    title: 'Serie & Folio',
    datakey: 'serieFolio',
  },
  {
    title: 'Importe Pagado',
    datakey: 'impPagado',
    format: '$ 0,0.00',
  },
  {
    title: 'Forma de Pago',
    datakey: 'formaDePagoTxt',
  },
]

export const creditNotesCol = [
  {
    title: 'Id',
    datakey: 'idCancelacion',
    width: '5%',
  },
  {
    title: 'Fecha petición',
    datakey: 'fecha',
    format: (value) => moment(value).format('DD/MM/YYYY'),
    width: '10%',
  },
  {
    title: 'Petición',
    datakey: 'accionTomar',
    width: '20%'
  },
  {
    title: 'Estatus',
    datakey: 'estatus',
    width: '8%',
  },
]

export const totals = {
  subtotal: {
    name: 'subtotal',
    label: 'Subtotal',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  iva: {
    name: 'iva',
    label: 'IVA',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  ieps: {
    name: 'ieps',
    label: 'IEPS',
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  montoCobrado: {
    name: 'montoCobrado',
    label: 'Monto Cobrado',
    value: 0,
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
  montoPorCobrar: {
    name: 'montoPorCobrar',
    label: 'Monto Por Cobrar',
    value: 0,
    format: (value) => numeral(Math.round(+value * 100) / 100).format('$ 0,0.00'),
    align: 'right',
  },
  numeroFactura: {
    name: 'numeroFactura',
    label: 'Numero de factura',
  },
  uuid: {
    name: 'uuid',
    label: 'UUID',
    disabled: true,
  },
  total: {
    name: 'total',
    label: 'Total',
    value: 0,
    format: (value) => numeral(value).format('$ 0,0.00'),
    align: 'right',
  },
}