import React from 'react'
import { Tabs, Box, Table, Button } from 'Components/common/bulma'
import classnames from 'classnames'
import numeral from 'numeral'

const Movimientos = ({ tabActive, movimientos, selectTab }) => {

  const records = movimientos[tabActive];
  return (
    <Box>
      <Tabs boxed>
        <ul>
          <li onClick={() => selectTab('MXN')} className={classnames({ 'is-active': tabActive === 'MXN' })}>
            <Button className="has-text-info is-text">MOVIMIENTOS MXN</Button>
          </li>
          <li onClick={() => selectTab('USD')} className={classnames({ 'is-active': tabActive === 'USD' })}>
            <Button className="has-text-info is-text">MOVIMIENTOS USD</Button>
          </li>
        </ul>
      </Tabs>
      <Table striped className="is-hoverable is-fullwidth" style={{marginBottom: 0}}>
        <Table.Head>
          <Table.Tr>
            <Table.Th>Fecha</Table.Th>
            <Table.Th className="has-text-right">Abono</Table.Th>
            <Table.Th className="has-text-right">Cargo</Table.Th>
            <Table.Th className="has-text-right">Saldo</Table.Th>
          </Table.Tr>
        </Table.Head>
      </Table>
      <div style={{maxHeight: '200px', overflowY: 'auto'}}>
      <Table striped className="is-hoverable is-fullwidth">
        <Table.Body>
          {records.map((it, key) => {
            return (<Table.Tr key={key}>
              <Table.Td>
                {it.Fecha}
              </Table.Td>
              <Table.Td className="has-text-right has-text-weight-bold">
                {numeral(Number(it.Abono).toFixed(6)).format('$ 0,000.00')}
              </Table.Td>
              <Table.Td className="has-text-right has-text-weight-bold">
                {numeral(Number(it.Cargo).toFixed(6)).format('$ 0,000.00')}
              </Table.Td>
              <Table.Td className="has-text-right has-text-weight-bold">
                {numeral(Number(it.Saldo).toFixed(6)).format('$ 0,000.00')}
              </Table.Td>
            </Table.Tr>)

          })}
        </Table.Body>
      </Table>

      </div>
    </Box>
  )
}

export default Movimientos