import axios from 'axios'
import { getHeaders } from 'Modules/util'
import { reject, propEq, map } from 'ramda'

const url = process.env.REACT_APP_API

export const GET_LIST = 'paymentOrders/retreats/GET_LIST'
export const DELETE_ORDER = 'paymentOrders/retreats/DELETE_ORDER'
export const GET_ORDER = 'paymentOrders/retreats/GET_ORDER'
export const CREATE_ORDER = 'paymentOrders/retreats/CREATE_ORDER'
export const UPDATE_ORDER = 'paymentOrders/retreats/UPDATE_ORDER'
export const END_ORDER_EDIT = 'paymentOrders/retreats/END_ORDER_EDIT'
export const ADD_INSTALLMENT = 'paymentOrders/retreats/ADD_INSTALLMENT'
export const GET_INSTALLMENTS = 'paymentOrders/retreats/GET_INSTALLMENTS'
export const AUTHORIZE_INSTALLMENT = 'paymentOrders/retreats/AUTHORIZE_INSTALLMENT'
export const DELETE_INSTALLMENT = 'paymentOrders/retreats/DELETE_INSTALLMENT'

const initialState = {
  list: {
    data: []
  },
  order: {},
  installments: {
    data: [],
  },
}

export default (state = initialState, action) => {
  switch (action.type) {

    case GET_LIST:
    case CREATE_ORDER:
    case UPDATE_ORDER:
    case GET_ORDER:
      return { ...state, ...action.payload }
    case DELETE_ORDER:
      const { data, ...meta } = state.list
      return {
        ...state, list: {
          data: data.map(it => {
            console.log('it: ', it);
            if (it.id === action.payload.id) {
              return action.payload
            }
            return it
          }
          ), ...meta, last_page: Math.ceil(data.length / meta.per_page)
        }
      }
    case END_ORDER_EDIT:
      return initialState;
    case GET_INSTALLMENTS:
      return { ...state, ...action.payload }
    case ADD_INSTALLMENT:
      return {
        ...state,
        installments: { data: state.installments.data.concat(action.payload) }
      }
    case DELETE_INSTALLMENT:
      return {
        ...state,
        installments: { data: reject(propEq('id', action.payload))(state.installments.data) }
      }
    case AUTHORIZE_INSTALLMENT:
      let mapper = map(it => {
        if (it.id === action.payload.idInstallment) {
          it.estatus = action.payload.data.estatus
          it.estatusTexto = action.payload.data.estatusTexto
        }
        return it
      })
      return {
        ...state,
        installments: { data: mapper(state.installments.data) }
      }
    default:
      return state
  }
}

export const getList = params => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/payment-order-retirement/search`, params, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_LIST,
          payload: { list: data }
        })
      })
  }
}

export const deleteOrder = (orderId) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/payment-order-retirement/${orderId}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: DELETE_ORDER,
          payload: data,
        })
        return data
      })
  }
}

export const createOrder = order => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/payment-order-retirement`, order, { headers })
      .then(({ data }) => {
        dispatch({
          type: CREATE_ORDER,
          payload: { order: data },
        })
        return data
      })
  }
}

export const updateOrder = ({ id, ...order }) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.put(`${url}/payment-order-retirement/${id}`, order, { headers })
      .then(({ data }) => {
        dispatch({
          type: UPDATE_ORDER,
          payload: { order: data },
        })
        return data
      })
  }
}

export const getOrder = id => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/payment-order-retirement/${id}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_ORDER,
          payload: { order: data },
        })
        return data
      })
  }
}

export const endOrderEdit = () => {
  return {
    type: END_ORDER_EDIT
  }
}

export const getInstallments = order => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/payment-order-retirement/deferred/${order}`, {}, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_INSTALLMENTS,
          payload: { installments: { data } },
        })
      })
  }
}

export const authorizeInstallment = ({ idOrder, idInstallment, ...data }) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/authorize-payment/${idOrder}/${idInstallment}`, data, { headers })
      .then(({ data }) => {
        dispatch({
          type: AUTHORIZE_INSTALLMENT,
          payload: { order: data, idInstallment, data },
        })
        return data
      })
  }
}

export const deleteInstallment = (idOrder, idInstallment) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/payment-order-retirement/deletePayment/${idOrder}/${idInstallment}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: DELETE_INSTALLMENT,
          payload: idInstallment,
        })
      })
  }
}

export const addInstallment = (order, data) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/payment-order-retirement/registerPayment/${order}`, data, { headers })
      .then(({ data }) => {
        dispatch({
          type: ADD_INSTALLMENT,
          payload: data,
        })
      })
  }
}