export const pendingIncomeOrders =
  [
    {
      title: 'Id',
      datakey: 'id',
      width: '5%',
    },
    {
      title: 'Tipo de Ingreso',
      datakey: 'tipoIngreso',
      width: '10%',
    },
    {
      title: 'Estatus Orden',
      datakey: 'estatus',
      width: '10%',
    },
    {
      title: 'Nombre o Razón Social',
      datakey: 'nombreRazonSocial',
      width: '10%',
    },
    {
      title: 'Total',
      datakey: 'total',
      format: '$ 0,0.00',
      width: '10%',
    },
    {
      title: 'Acciones',
      custom: 'actions',
      width: '5%',
      align: 'center',
    }
  ];

export const pendingCollectionIncomeOrders =
  [
    {
      title: 'Id',
      datakey: 'id',
      width: '5%',
    },
    {
      title: 'Tipo de Ingreso',
      datakey: 'tipoIngreso',
      width: '10%',
    },
    {
      title: 'Estatus Orden',
      datakey: 'estatus',
      width: '10%',
    },
    {
      title: 'Nombre o Razón Social',
      datakey: 'nombreRazonSocial',
      width: '10%',
    },
    {
      title: 'Total',
      datakey: 'total',
      format: '$ 0,0.00',
      width: '10%',
    },
    {
      title: 'Acciones',
      custom: 'actions',
      width: '5%',
      align: 'center',
    }
  ];