import React, { Component } from 'react'
import { validator, validateData } from '../../helpers'
import { bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { CambiarContrasena as CambiarContrasenaComponent } from 'Components/auth'
import { changePassword } from 'Modules/auth'

export class CambiarContrasena extends Component {
  state = {
    data: {
      password: {
        value: '',
        validation: 'required|minLength:6'
      },
      passwordMatch: {
        value: '',
        validation: 'required|minLength:6|match:password'
      }
    },
    showErrors: false,
  }

  onChangeInput = ({ target }) => {
    const { value, name } = target
    let { data } = this.state, validateField = true

    validateField = data[name].pattern ? validator(value, data[name].pattern).valid: true
    data[name].value = validateField ? value: this.state.data[name].value

    this.setState({
      data: data,
      showErrors: false,
    })
  }

  updatePassword= () => {
    const isFirst = this.props.isFirst
    const { data, hasErrors } = validateData(this.state.data)
    const { password:{ value } } = this.state.data
    if(hasErrors) {
      this.setState({
        data,
        showErrors: true,
      })
      return
    }
    this.props.changePassword({ password: value })
      .then( () => {
        if(isFirst) {
          this.props.push('/')
        } else {
          this.props.push('/')
        }
      }).catch((error) => {
        this.setState({
          submitError: error,
        })
      })
  }

  render() {
    return (
      <CambiarContrasenaComponent
        onChangeInput={ this.onChangeInput }
        data={ this.state.data }
        onSubmit={ this.updatePassword }
        showErrors={this.state.showErrors}
        submitError={this.state.submitError}
        />
    )
  }
}

const mapStateToProps = ({principal}) => ({
  isFirst: principal.changePassword
})

const mapDispatchToProps = dispatch => bindActionCreators({
  changePassword,
  push
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CambiarContrasena)
