export default {
  estatus: {
    value: '',
    name: 'estatus',
    label: 'Estatus',
    validation: 'required',
  },
  estatusEdenred: {
    value: '',
    name: 'estatusEdenred',
    label: 'Estatus Toka',
    disabled: true,
    options: [
      {
        value: 1,
        label: 'No Emitido'
      },
      {
        value: 2,
        label: 'Emitido'
      },
      {
        value: 3,
        label: 'Entregado'
      }
    ]
  },
  marca: {
    value: '',
    validation: 'required',
    name: 'marca',
    label: 'Marca',
  },
  modelo: {
    value: '',
    name: 'modelo',
    label: 'Modelo',
  },
  version: {
    value: '',
    name: 'version',
    label: 'Version',
  },
  color: {
    value: '',
    name: 'color',
    label: 'Color',
  },
  identificador: {
    value: '',
    name: 'identificador',
    label: 'Identificador',
    disabled: true,
  },
  noTarjeta: {
    value: '',
    name: 'noTarjeta',
    label: 'Tarjeta',
    disabled: true,
  },
  claveCentroCostos: {
    value: '',
    name: 'claveCentroCostos',
    label: 'Centro de Costo',
    disabled: true,
  },
  descripcionCentroCosto: {
    value: '',
    name: 'descripcionCentroCosto',
    label: 'Descripcion CC',
    disabled: true,
  },
  combustible: {
    value: '',
    name: 'combustible',
    validation: 'required',
    multi: [
      { label: 'Magna', radioValue: 'Magna' },
      { label: 'Premium', radioValue: 'premium' },
      { label: 'Diesel', radioValue: 'diesel' }
    ] 
  },
  reposicion: {
    value: false,
    radioValue: 'true',
    name:'reposicion',
    label:'Reposicion',
    // validation: 'required',

  }
}