import axios from 'axios'
import { getHeaders } from 'Modules/util'

const url = process.env.REACT_APP_API

export const GET_LIST = 'transferOrders/GET_LIST'
export const GET_PENDING_LIST = 'transferOrders/GET_PENDING_LIST'
export const DELETE_ORDER = 'transferOrders/DELETE_ORDER'
export const CREATE_ORDER = 'transferOrders/CREATE_ORDER'
export const UPDATE_ORDER = 'transferOrders/UPDATE_ORDER'
export const GET_ORDER = 'transferOrders/GET_ORDER'
export const END_ORDER_EDIT = 'transferOrders/END_ORDER_EDIT'
export const AUTHORIZE_ORDER = 'transferOrders/AUTHORIZE_ORDER'
export const SET_ORIGIN = 'transferOrders/SET_ORIGIN'
export const CLEAN_ORIGIN = 'transferOrders/CLEAN_ORIGIN'

const initialState = {
  list: {
    data: []
  },
  pendingList: {
    data: []
  },
  order: {},
  origin: 'normal',
}

export default (state = initialState, action) => {
  switch (action.type) {

    case GET_ORDER:
    case CREATE_ORDER:
    case UPDATE_ORDER:
    case AUTHORIZE_ORDER:
    case GET_LIST:
    case SET_ORIGIN:
    case CLEAN_ORIGIN:
    case GET_PENDING_LIST:
      return { ...state, ...action.payload }
    case DELETE_ORDER:
      return { ...state, order: { ...state.order, ...action.payload } }
    case END_ORDER_EDIT:
      return initialState
    default:
      return state
  }
}

export const getList = (params) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/transferOrder/search`, params, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_LIST,
          payload: { list: data }
        })
      })
  }
}

export const getPendingList = (params) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/transferOrder/status/to-authorize`, { headers, params })
      .then(({ data }) => {
        dispatch({
          type: GET_PENDING_LIST,
          payload: { pendingList: data }
        })
      })
  }
}

export const deleteOrder = orderId => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/transferOrder/${orderId}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: DELETE_ORDER,
          payload: data,
        })
        return data
      })
  }
}

export const createOrder = order => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/transferOrder`, order, { headers })
      .then(({ data }) => {
        dispatch({
          type: CREATE_ORDER,
          payload: { order: data },
        })
        return data
      })
  }
}

export const updateOrder = ({ id, ...order }) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.put(`${url}/transferOrder/${id}`, order, { headers })
      .then(({ data }) => {
        dispatch({
          type: UPDATE_ORDER,
          // payload: { order: data}
        })
      })
  }
}

export const getOrder = id => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/transferOrder/${id}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_ORDER,
          payload: { order: data }
        })
      })
  }
}

export const authorizeOrder = (idOrder, auth) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/authorize-order-transfer/${idOrder}`, auth, { headers })
      .then(({ data }) => {
        dispatch({
          type: AUTHORIZE_ORDER,
          payload: { order: data },
        })
        return data
      })
  }
}

export const endOrderEdit = () => ({
  type: END_ORDER_EDIT
})

export const setOrigin = (origin) => ({
  type: SET_ORIGIN,
  payload: { origin }
});

export const cleanOrigin = () => ({
  type: CLEAN_ORIGIN,
  payload: { origin: 'normal' },
});
