import axios from 'axios'
import { getHeaders } from 'Modules/util'

const url = process.env.REACT_APP_API

export const GET_LIST = 'incomeOrders/collectionCard/GET_LIST'
export const IS_FETCHING_COLLECTION_CARD= 'incomeOrders/collectionCard/IS_FETCHING_COLLECTION_CARD'
export const IS_FETCHING_COLLECTION_CARD_COMPLETE= 'incomeOrders/collectionCard/IS_FETCHING_COLLECTION_CARD_COMPLETE'

const initialState = {
  list: {
    data: [],
  },
  isFetching: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST:
      return { ...state, ...action.payload }
    case IS_FETCHING_COLLECTION_CARD:
      return { ...state, isFetching: true }
    case IS_FETCHING_COLLECTION_CARD_COMPLETE:
      return { ...state, isFetching: false }
    default:
      return state
  }
}
export const isFetching= () => ({
  type: IS_FETCHING_COLLECTION_CARD
})

export const isFetchingComplete = () => ({
  type: IS_FETCHING_COLLECTION_CARD_COMPLETE
})

export const getList = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetching())
    return axios.post(`${url}/cards/collection-transaction`, params, { headers})
      .then(({ data }) => {
        dispatch(isFetchingComplete())
        dispatch({
          type: GET_LIST,
          payload: { list: data }
        })
      })
  }
}
