import React from 'react'
import { Field, Control, Icon } from 'Components/common/bulma'
import { Trans } from '@lingui/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types';

const Select = ({
  label,
  onChange,
  disabled = false,
  error,
  options = [],
  optionDefault = label,
  valueProperty = 'value',
  valueLabel = 'label',
  showErrors,
  hideEmpty = false,
  className,
  tooltip,
  input,
}) => {
  return (
    <Field>
      <label className="label" >
        <Trans id={label}></Trans>
        {tooltip && <Icon className="tooltip is-tooltip-multiline is-tooltip-info has-text-danger" data-tooltip={tooltip} >
          <FontAwesomeIcon icon={faInfoCircle} />
        </Icon>}
      </label>
      <Control>
        <div className="select is-fullwidth">
          <select
            className={className}
            disabled={disabled}
            {...input}
            onChange={onChange}
          >
            {hideEmpty || (<option value="">
              -- {optionDefault} --
            </option>)}
            {options.map((it, idx) =>
            (<option key={idx} value={it[valueProperty]}>
              {it[valueLabel]}
            </option>)
            )}
          </select>
        </div>
      </Control>
      {(error && showErrors) && <p className="has-text-danger help">{error}</p>}
    </Field>
  )
}

Select.propTypes = {
  hideEmpty: PropTypes.bool
}

export default Select