import React from 'react'
import { Field, Control, Icon } from 'Components/common/bulma'
import { Trans } from '@lingui/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'
import {  DebounceInput } from 'react-debounce-input'

const DebounceInputWithErrorComponent = ({
  label,
  onChange,
  disabled = false,
  error,
  showErrors,
  className= '',
  tooltip,
  input = {},
}) => {
  return (
    <Field>
      <label className="label"><Trans id={label}></Trans>
        {tooltip && <Icon className="tooltip is-tooltip-multiline is-tooltip-info has-text-danger" data-tooltip={tooltip} >
          <FontAwesomeIcon icon={faInfoCircle} />
        </Icon>}
      </label>
      <Control>
        <DebounceInput
          className={`input ${className}`}
          onChange={onChange}
          disabled={disabled}
          {...input}
          placeholder={input.placeholder || label}
          />
      </Control>
      {(error && showErrors) && <p className="has-text-danger help">{error}</p>}
    </Field>
  )
}

export default React.memo(DebounceInputWithErrorComponent);
