import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ContractList from 'Containers/contracts'
import ContractForm from 'Containers/contracts/contractForm'

export default () => (
  <Switch>
    <Route exact path="/contratos" component={ContractList} />
    <Route exact path="/contratos/nuevo" component={ContractForm} />
    <Route exact path="/contratos/:id" component={ContractForm} />
  </Switch>
) 