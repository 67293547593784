export const authorizeLoanOrders =
  [
    {
      title: 'Id',
      datakey: 'id',
      width: '10%',
    },
    {
      title: 'Tipo de préstamo',
      datakey: 'tipoPrestamo',
      width: '10%',
    },
    {
      title: 'Estatus',
      datakey: 'estatus',
      width: '10%',
    },
    {
      title: 'Nombre o Razón Social',
      datakey: 'nombreRazonSocial',
      width: '20%'
    },
    {
      title: 'Monto a Pagar',
      datakey: 'cargo',
      format: '$ 0,0.00',
      width: '10%',
    },
    {
      title: 'Acciones',
      custom: 'actions',
      width: '10%',
      align: 'center',
    }
  ];