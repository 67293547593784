import axios from 'axios'
import { getHeaders } from './util'

const url = process.env.REACT_APP_API

export const GET_BALANCE = 'principal/GET_BALANCE'
export const GET_MOVIMIENTOS = 'principal/GET_MOVIMIENTOS'
export const GET_LOGS = 'principal/GET_LOGS'
export const GET_OPERATIONS = 'principal/GET_OPERATIONS'

const initialState = {
  balance: {
    'MXN': undefined,
    'USD': undefined,
  },
  movimientos: {'MXN': [], 'USD': []},
  logs: [],
  operaciones: [],
}
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BALANCE:
      return { ...state, balance: action.payload }

    case GET_MOVIMIENTOS:
      return { ...state, movimientos: action.payload }

    case GET_LOGS:
      return { ...state, logs: action.payload }

    case GET_OPERATIONS:
      return { ...state, operaciones: action.payload }

    default:
      return state;
  }
}

export const getBalance = () => {
  return dispatch => {
    return axios.get(`${url}/balances`, {headers: getHeaders(), params:{Saldo: ['MXN','USD']}})
      .then(({data})=>{
        dispatch({
          type: GET_BALANCE,
          payload: data
        })
      })
  }
}

export const getMovements = () => {
  return dispatch => {
    return axios.get(`${url}/movementsReports`, {headers: getHeaders(), params:{Movimientos: ['MXN','USD']}})
      .then(({data}) => {
        dispatch({
          type: GET_MOVIMIENTOS,
          payload: data
        })
      })
  }
}
export const getLogs = () => {
  return dispatch => {
    return axios.get(`${url}/logs`, {headers: getHeaders(), params:{Movimientos: ['MXN','USD']}})
      .then(({data}) => {
        dispatch({
          type: GET_LOGS,
          payload: data
        })
      })
  }
}
export const getOperaciones = () => {
  return dispatch => {
    return axios.get(`${url}/summaryOperations`, {headers: getHeaders()})
      .then(({data}) => {
        dispatch({
          type: GET_OPERATIONS,
            payload: data
          })
        })
    }
  }
