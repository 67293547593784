import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { Box, Icon, Modal, Message, Delete, Columns, Column } from 'Components/common/bulma'
import Table from 'Components/common/table'
import { getList, deleteOrder } from 'Modules/paymentOrders/walletGasoline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Trans } from '@lingui/macro'
import { monederoGasolinaColData } from './data'
import { Button } from 'Components/common/bulma'
import { addNotification } from 'Modules/principal'

const CREATED_STATUS = '1'
export class ListaContainer extends Component {
  state = {
    showModal: false,
    order: {},
  }

  onEdit = ({id}) => {
    const { push } = this.props;
    push(`/ordenes-pago/monederos-gasolina/${id}`);
  }

  toggleModal = (order) => {
    this.setState(state => ({
      showModal: !state.showModal,
      order,
    }))
  }

  deleteOrder = () => {
    this.props.deleteOrder(this.state.order.id)
      .then(() => {
        this.props.addNotification({
          type: 'success',
          message: 'El monedero se eliminó correctamente'
        })
        this.setState({
          showModal: false,
        })
      })
      .catch(() => {
        this.props.addNotification({
          type: 'danger',
          message: 'Ocurrió un error al eliminar el monedero'
        })
        this.setState({
          showModal: false,
        })
      })
  }

  customColAccions = (row) => (
    <Fragment >
      <Button small className="margin-left-xs" primary outlined onClick={() => this.onEdit(row)}>
        <Icon>
          <FontAwesomeIcon icon={faEdit} />
        </Icon>
      </Button>
      <Button small disabled={row.estatus !== CREATED_STATUS} className="margin-left-xs" danger outlined onClick={() => this.toggleModal(row)}>
        <Icon>
          <FontAwesomeIcon icon={faTrash} />
        </Icon>
      </Button>
    </Fragment>
  )

  render() {
    const { list } = this.props

    return (
      <Box>
        <Modal isActive={this.state.showModal}>
          <Message danger>
            <Message.Header>
              <p>Eliminar orden de pago (Monedero de gastos)</p>
              <Delete onClick={this.toggleModal} />
            </Message.Header>
            <Message.Body className="has-text-centered">
              ¿Esta seguro de querer eliminar la orden de pago (Monedero de gasolina)?
            <br />
              <strong>{this.state.order.id}</strong>
              <div className="margin-top-lg">
                <Button danger onClick={this.deleteOrder}>Eliminar</Button>
                <Button default className="margin-left-sm" onClick={this.toggleModal}>
                  Cancelar
              </Button>
              </div>
            </Message.Body>
          </Message>
        </Modal>
        <Columns>
          <Column>
            <Link className="button is-success is-outlined is-pulled-right" to="/ordenes-pago/monederos-gasolina/nuevo">
              <Icon>
                <FontAwesomeIcon icon={faPlus} />
              </Icon>
              <Trans render="span">Agregar Orden de pago (Monedero de gasolina)</Trans>
            </Link>
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Table
              filterable
              colsetup={monederoGasolinaColData}
              coldata={list}
              tableClassName='table is-striped is-hoverable is-fullwidth'
              emptyTableMarkUp={<span>No hay registros</span>}
              customCol={{
                customColName: 'actions',
                renderFunc: this.customColAccions
              }}
              refresh={this.props.getList}
            />
          </Column>
        </Columns>
      </Box>
    )
  }
}

const mapStateToProps = ({ paymentOrders }) => ({
  list: paymentOrders.walletGasoline.list,
  isFetching: paymentOrders.walletGasoline.isFetchingWalletGasoline
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getList,
  push,
  deleteOrder,
  addNotification,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ListaContainer)
