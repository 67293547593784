import http from '../../../services/httpService';

export default class RemoteLegalEntityRepository {
  async save(legalEntity) {
    return http.post('/onboarding/company-data', legalEntity)
  }
  async update(legalEntity) {
    return http.put('/onboarding/company-data', legalEntity)
  }
  async get() {
    const { data: { data } } = await http.get('/onboarding/company-data')
    return data
  }
}
