import React, { useState } from 'react'
import { Field, Control, Button, Title, Notification, Delete } from 'Components/common/bulma'
import { InputWithError } from 'Components/common/form'
import useForm from 'App/hooks/useForm'
import { anyPass } from 'ramda'
import { isNumeric, isNilOrEmptyString} from 'ramda-extension'

function OtpForm({
  handleOnSubmitTotp,
  buttonLabel = 'Entrar',
}) {
  const initialTotpFields = {
    totp: {
      input: {
        value: '',
        name: 'totp',
        maxLength: 6,
        placeholder: 'Código',
        autocomplete: 'off',
      },
      label: '',
      validation: 'required|isLength:3',
      pattern: anyPass([isNumeric, isNilOrEmptyString]),
    },
  };

  const [
    // eslint-disable-next-line
    totpFields, setTotpFields, onToptFieldChange,
    isValidToptForm, getToptValues, showToptErrors
  ] = useForm({ fields: initialTotpFields });
  const [hasEnableError, setHasEnableError] = useState(false)

  const handleOnSubmit = () => {
    if (isValidToptForm()) {
      handleOnSubmitTotp(getToptValues())
        .catch(() => {
          setHasEnableError(true);
        });
    }
  }

  const handleOnCloseEnableError = () => {
    setHasEnableError(false)
  }

  return (
    <div>
      <Title is="5" >Ingrese el código</Title>
      <Field>
        <Control>
          <InputWithError
            className="is-large has-text-centered"
            onChange={onToptFieldChange}
            showErrors={showToptErrors}
            {...totpFields.totp}
          />
        </Control>
      </Field>
      {hasEnableError && (
          <Notification danger>
            <Delete onClick={handleOnCloseEnableError} />
            El token es inválido o ha expirado, favor de intentar nuevamente.
          </Notification>
        )}
      <Button className="is-fullwidth" black medium type="button" onClick={handleOnSubmit}>{buttonLabel}</Button>
    </div>
  )
}

export default OtpForm
