import React from 'react';
import { Button } from 'Components/common/bulma'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation, faCheck } from '@fortawesome/free-solid-svg-icons'
import useFinish from './useFinish'
import Loader from 'Components/common/page/loaderFloat';
import Show from 'Components/common/Show';

const Finish = ({ onboardingFlow }) => {
  const { sendOnboardingReview, isLoading } = useFinish()

  return (
    <div className="has-text-centered" style={{ margin: 'auto', height: '100%', marginTop: '15%', position: 'relative' }}>
      <Show canShow={isLoading}>
        <Loader />
      </Show>
      <Button
        onClick={sendOnboardingReview}
        disabled={!onboardingFlow?.formulariosCompletos}
        className={`${onboardingFlow?.formulariosCompletos ? 'is-primary' : 'is-warning'} is-large`}>
        <span style={{ marginRight: '10px' }}>Enviar a revisión</span>
        {onboardingFlow?.formulariosCompletos ?
          <FontAwesomeIcon icon={faCheck} /> :
          <FontAwesomeIcon icon={faExclamation} />
        }
      </Button>
      {onboardingFlow?.formulariosCompletos ?
        <div>Si los datos capturados son correctos favor de enviar a revisión</div>
        :
        <div>Favor de revisar que todos los formularios hayan sido capturados</div>
      }
    </div>
  );
}

export default Finish;
