import axios from 'axios'
import { getHeaders } from 'Modules/util'
import numeral from 'numeral'
import { map, converge, propOr } from 'ramda'

const url = process.env.REACT_APP_API

export const GET_LIST = 'incomeOrders/invoice/GET_LIST'
export const GET_PENDING_LIST = 'incomeOrders/invoice/GET_PENDING_LIST'
export const GET_PENDING_COLLECTION_LIST = 'incomeOrders/invoice/GET_PENDING_COLLECTION_LIST'
export const DELETE_ORDER = 'incomeOrders/invoice/DELETE_ORDER'
export const GET_ORDER = 'incomeOrders/invoice/GET_ORDER'
export const CREATE_ORDER = 'incomeOrders/invoice/CREATE_ORDER'
export const END_ORDER_EDIT = 'incomeOrders/invoice/END_ORDER_EDIT'
export const GET_RELATED_UIID = 'incomeOrders/invoice/GET_RELATED_UIID'
export const GET_INSTALLMENTS = 'incomeOrders/invoice/GET_INSTALLMENTS'
export const GET_COMPLEMENTARY_INSTALLMENTS = 'incomeOrders/invoice/GET_COMPLEMENTARY_INSTALLMENTS'
export const AUTHORIZE_ORDER = 'incomeOrders/invoice/AUTHORIZE_ORDER'
export const UPDATE_ORDER = 'incomeOrders/invoice/UPDATE_ORDER'
export const FIND_PRODUCTS = 'incomeOrders/invoice/FIND_PRODUCTS'
export const GET_PRODUCTS = 'incomeOrders/invoice/GET_PRODUCTS'
export const CLEAR_FINDER = 'incomeOrders/invoice/CLEAR_FINDER'
export const GET_PRODUCT = 'incomeOrders/invoice/GET_PRODUCT'
export const ADD_PRODUCT = 'incomeOrders/invoice/ADD_PRODUCT'
export const DELETE_PRODUCT = 'incomeOrders/invoice/DELETE_PRODUCT'
export const UPDATE_PRODUCT = 'incomeOrders/invoice/UPDATE_PRODUCT'
export const ASIGNE_REFERENCE = 'incomeOrders/invoice/ASIGNE_REFERENCE'
export const GET_MERCADOPAGO_LINK = 'incomeOrders/invoice/GET_MERCADOPAGO_LINK'
export const GET_MIT_LINK = 'incomeOrders/invoice/GET_MIT_LINK'
export const GET_STRIPE_LINK = 'incomeOrders/invoice/GET_STRIPE_LINK'
export const GET_STRIPE_SUBSCRIPTION = 'incomeOrders/invoice/GET_STRIPE_SUBSCRIPTION'
export const GET_STRIPE_OXXO = 'incomeOrders/invoice/GET_STRIPE_OXXO'
export const GET_ATTACHMENTS = 'incomeOrders/invoice/GET_ATTACHMENTS'
export const IS_FETCHING_INVOICE = 'incomeOrders/invoice/IS_FETCHING_INVOICE'
export const IS_FETCHING_INVOICE_COMPLETE = 'incomeOrders/invoice/IS_FETCHING_INVOICE_COMPLETE'
export const SET_ORIGIN = 'incomeOrders/SET_ORIGIN'
export const CLEAN_ORIGIN = 'incomeOrders/CLEAN_ORIGIN'
export const GET_OPTIONS = 'incomeOrders/invoice/GET_OPTIONS'

const initialState = {
  list: {
    data: [],
  },
  pendingList: {
    data: [],
  },
  pendingCollectionList: {
    data: [],
  },
  order: {},
  relatedUiid: [],
  installments: {
    data: [],
  },
  complementaryInstallments: {
    data: [],
  },
  products: [],
  productsList: {
    data: []
  },
  product: {},
  reference: '',
  mercadoPagoLink: {},
  attachments: [],
  isFetching: false,
  mitLink: {},
  origin: 'normal',
  options: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_FINDER:
      return { ...state, products: [] }
    case ASIGNE_REFERENCE:
    case GET_MERCADOPAGO_LINK:
    case GET_COMPLEMENTARY_INSTALLMENTS:
    case UPDATE_ORDER:
    case GET_INSTALLMENTS:
    case GET_RELATED_UIID:
    case CREATE_ORDER:
    case GET_ORDER:
    case GET_PRODUCT:
    case GET_LIST:
    case GET_PENDING_LIST:
    case GET_PENDING_COLLECTION_LIST:
    case FIND_PRODUCTS:
    case GET_PRODUCTS:
    case GET_ATTACHMENTS:
    case DELETE_ORDER:
    case SET_ORIGIN:
    case CLEAN_ORIGIN:
    case GET_OPTIONS:
      return { ...state, ...action.payload }
    case GET_STRIPE_LINK:
    case GET_STRIPE_SUBSCRIPTION:
    case GET_STRIPE_OXXO:
    case AUTHORIZE_ORDER:
      return { ...state, order: { ...state.order, ...action.payload } }
    case GET_MIT_LINK:
      return { ...state, order: { ...state.order, ...action.payload } }
    case END_ORDER_EDIT:
      return initialState
    case IS_FETCHING_INVOICE:
      return { ...state, isFetching: true }
    case IS_FETCHING_INVOICE_COMPLETE:
      return { ...state, isFetching: false }
    default:
      return state
  }
}
export const isFetching = () => ({
  type: IS_FETCHING_INVOICE
})

export const setOrigin = (origin) => ({
  type: SET_ORIGIN,
  payload: { origin },
});

export const cleanOrigin = () => ({
  type: CLEAN_ORIGIN,
  payload: { origin: 'normal' }
})

export const isFetchingComplete = () => ({
  type: IS_FETCHING_INVOICE_COMPLETE
})

export const getList = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetching())
    return axios.post(`${url}/entry-invoice/search`, params, { headers })
      .then(({ data }) => {
        dispatch(isFetchingComplete())
        dispatch({
          type: GET_LIST,
          payload: { list: data }
        })
      })
  }
}

export const getPendingList = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetching())
    return axios.get(`${url}/IncomeOrder/status/to-authorize`, { headers, params })
      .then(({ data }) => {
        dispatch(isFetchingComplete())
        dispatch({
          type: GET_PENDING_LIST,
          payload: { pendingList: data }
        })
      })
  }
}

export const getPendingCollectionList = (params) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetching())
    return axios.get(`${url}/IncomeOrder/status/pending-collection`, { headers, params })
      .then(({ data }) => {
        dispatch(isFetchingComplete())
        dispatch({
          type: GET_PENDING_COLLECTION_LIST,
          payload: { pendingCollectionList: data }
        })
      })
  }
}

export const deleteOrder = (orderId) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/entry-invoice/${orderId}`, { headers })
      .then(() => {
        dispatch({
          type: DELETE_ORDER,
          payload: orderId,
        })
      })
  }
}
export const getOrder = (id) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/entry-invoice/${id}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_ORDER,
          payload: { order: data }
        })
      })
  }
}

export const createOrder = (order) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetching())
    return axios.post(`${url}/entry-invoice`, order, { headers })
      .then(({ data }) => {
        dispatch(isFetchingComplete())
        dispatch({
          type: CREATE_ORDER,
          payload: { order: data },
        })
        return data
      })
  }
}
export const getRelatedUiid = (client, relationshipType) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/entry-invoice/uuid-relacionado/${client}/${relationshipType}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_RELATED_UIID,
          payload: {
            relatedUiid: data.map(it => {
              it.label = `Uuid: ${it.uuid} - Rfc: ${it.rfc} - Total comprobante: ${numeral(it.totalcomprobante).format('$ 0,0.00')}`
              return it
            })
          },
        })
        return data
      })
  }
}
export const endOrderEdit = () => ({
  type: END_ORDER_EDIT
})

export const getInstallments = (order) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/entry-invoice/deferred/${order}`, {}, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_INSTALLMENTS,
          payload: { installments: { data } },
        })
      })
  }
}

export const getComplementaryInstallments = (order) => {
  const headers = getHeaders()
  const concatenateFolio = (serie, folio) => `${serie}-${folio}`
  const getConcatenatedFolio = converge(concatenateFolio, [propOr('-', 'serie'), propOr('-', 'folio')])

  return dispatch => {
    return axios.get(`${url}/entry-invoice/complements/${order}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_COMPLEMENTARY_INSTALLMENTS,
          payload: { complementaryInstallments: { data: map(it => ({ ...it, serieFolio: getConcatenatedFolio(it) }))(data) } },
        })
      })
  }
}

export const authorizeOrder = (idOrder, data) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/authorize-order-entry/${idOrder}`, data, { headers })
      .then(({ data }) => {
        dispatch({
          type: AUTHORIZE_ORDER,
          payload: data,
        })
        return data
      })
  }
}

export const updateOrder = ({ id, ...order }) => {
  const headers = getHeaders()
  return dispatch => {
    dispatch(isFetching())
    return axios.put(`${url}/entry-invoice/${id}`, order, { headers })
      .then(({ data }) => {
        dispatch(isFetchingComplete())
        dispatch({
          type: UPDATE_ORDER,
          payload: { order: data },
        })
        return data
      })
  }
}

export const findProducts = (query, inventario) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/products-or-services/search`, { query, inventario }, { headers })
      .then(({ data }) => {
        dispatch({
          type: FIND_PRODUCTS,
          payload: { products: data },
        })
        return data
      })
  }
}

export const getProducts = parameters => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/products-or-services/list`, parameters, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_PRODUCTS,
          payload: { productsList: data },
        })
        return data
      })
  }
}

export const clearFinder = () => ({
  type: CLEAR_FINDER,
})

export const getProduct = (id) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/products-or-services/${id}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_PRODUCT,
          payload: { product: data },
        })
        return data
      })
  }
}

export const addProduct = (data) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/products-or-services`, data, { headers })
      .then(({ data }) => {
        dispatch({
          type: ADD_PRODUCT,
        })
        return data
      })
  }
}

export const removeProduct = id => {
  const headers = getHeaders()
  return dispatch => {
    return axios.delete(`${url}/products-or-services/${id}`, { headers })
      .then(({ data }) => {
        dispatch({
          type: DELETE_PRODUCT,
        })
        return data
      })
  }
}

export const updateProduct = ({ id, ...data }) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.put(`${url}/products-or-services/${id}`, data, { headers })
      .then(({ data }) => {
        dispatch({
          type: UPDATE_PRODUCT,
        })
        return data
      })
  }
}

export const asigneReference = () => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/agreement-CIE/`, { headers })
      .then(({ data }) => {
        dispatch({
          type: ASIGNE_REFERENCE,
          payload: data
        })
        return data
      })
  }
}

export const getMercadoPagoLink = () => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/payment-market`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_MERCADOPAGO_LINK,
          payload: { mercadoPagoLink: data }
        })
        return data
      })
  }
}

export const getMitLink = (payload) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/entry-invoice/payment-link`, payload, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_MIT_LINK,
          payload: data
        })
        return data
      })
  }
}

export const getStripeLink = (payload) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/pagos/payment-way`, { paymentWay: '8', ...payload }, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_STRIPE_LINK,
          payload: { stripeLink: data['8'] }
        })
        return data
      })
  }
}
export const createSubscription = (payload) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/pagos/payment-way`, { paymentWay: '9', ...payload }, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_STRIPE_SUBSCRIPTION,
          payload: { stripeSubscriptionLink: data['9']['uri'] }
        })
        return data
      })
  }
}
export const createOxxoLink = (payload) => {
  const headers = getHeaders()
  return dispatch => {
    return axios.post(`${url}/pagos/payment-way`, { paymentWay: '10', ...payload }, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_STRIPE_OXXO,
          payload: { stripeOxxoLink: data['10']['uri'] }
        })
        return data
      })
  }
}

export const getAttachments = idOrder => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/storage/listContents/${idOrder}/ingreso`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_ATTACHMENTS,
          payload: { attachments: data }
        })
      })
  }
}

export const getOptions = idOrder => {
  const headers = getHeaders()
  return dispatch => {
    return axios.get(`${url}/invoice/${idOrder}/options`, { headers })
      .then(({ data }) => {
        dispatch({
          type: GET_OPTIONS,
          payload: { options: data }
        })
      })
  }
}
